<template>
  <div>
    <ListView
      :isLoading="isLoading"
      :items="getLayouts"
      :search="true"
      :sortName="true"
      :sortId="false"
      filterKey="enabled"
      :filterOptions="[
        { id: true, label: 'Enabled' },
        { id: false, label: 'Disabled' },
      ]"
      @onNewItem="handleAddNewItem"
    >
      <!-- template for item title prefix-->
      <template #itemPrefix="{ item: layout }">
        <div class="mr-4 flex items-start pt-2">
          <i v-if="!layout.enabled" class="material-icons-outlined"
            >radio_button_unchecked</i
          >
          <i v-else class="material-icons-outlined text-green-500">task_alt</i>
        </div>
      </template>
      <!-- end of item title prefix template-->

      <!-- template for tags which position under title -->
      <template #tags="{ item: layout }">
        <div
          v-if="layout.type == 'fixed'"
          class="rounded bg-grey-500 px-1 text-xs uppercase text-white"
        >
          System Default
        </div>
      </template>
      <!-- end of tags template -->

      <!-- template for dropdown menu -->
      <!-- manage custom function in current component -->
      <template #item="{ item: layout }">
        <RouterLink
          :to="'/settings/layouts/' + layout.type + '/' + layout.id"
          class="block cursor-pointer rounded p-2 hover:bg-grey-100"
          >{{ layout.type == 'fixed' ? 'View' : 'Edit' }}
        </RouterLink>

        <RouterLink
          :to="
            '/settings/layouts/' + layout.type + '/' + layout.id + '?clone=true'
          "
          class="block cursor-pointer rounded p-2 hover:bg-grey-100"
          >Clone
        </RouterLink>

        <div
          @click="toggleLayout(layout)"
          class="block flex cursor-pointer items-center justify-between rounded p-2 hover:bg-grey-100"
        >
          {{ layout.enabled ? 'Disable' : 'Enable' }}
        </div>

        <DropdownInlineConfirmButton
          v-if="layout.type != 'fixed'"
          @callback="handleDeleteItem(layout)"
          confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
        >
          Delete
          <i class="material-icons-outlined pl-1 text-grey-400">delete</i>
        </DropdownInlineConfirmButton>
      </template>
      <!-- end of dropdown menu template-->
    </ListView>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';

import ListView from '@/components/ui/ListView';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';

export default {
  mixins: [errors],

  name: 'LayoutsPage',

  components: {
    ListView,
    DropdownInlineConfirmButton,
  },

  data: () => ({
    isLoading: false,
    layouts: [],
    hideDisabled: false,
  }),

  computed: {
    getLayouts() {
      return this.layouts.filter((layout) => {
        if (this.hideDisabled) {
          return layout.enabled;
        } else {
          return true;
        }
      });
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.isLoading = true;

      const res = await api.get('/account/modes');
      if (res.status >= 200 && res.status < 300) {
        this.isLoading = false;
        this.layouts = res.data;
      } else {
        this.isLoading = false;
        this.layouts = [];
      }
    },
    handleAddNewItem() {
      this.$router.push('/settings/layouts/custom');
    },
    async handleDeleteItem(layout) {
      //TODO
      //force dropdown close which is not ideal
      this.$store.commit('dropdownOpen', false);

      const res = await api.delete(`/account/modes/${layout.id}`);

      if (res.status >= 200 && res.status < 300) {
        const cIndex = this.layouts.findIndex(
          (row) => row.id == layout.id && row.type == 'custom'
        );
        this.layouts.splice(cIndex, 1);

        this.$store.commit(
          'MESSAGE',
          `Layout ${layout.name} has been deleted.`
        );
      }
    },
    toggleDisabledLayouts() {
      this.hideDisabled = !this.hideDisabled;
    },
    async toggleLayout(layout) {
      const res = await api.post(
        `/account/modes/${layout.type}/${layout.id}/toggle`,
        {
          enabled: !layout.enabled,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        const currentLayout = this.layouts.find(
          (row) => row.id == layout.id && row.type == layout.type
        );
        currentLayout.enabled = !layout.enabled;

        this.$store.commit(
          'MESSAGE',
          `${currentLayout.name} has been updated.`
        );
      }
    },
  },
};
</script>
