<template>
  <div class="aspect-ratio-box">
    <div class="aspect-ratio-box-content">
      <video ref="video" playsinline autoplay muted />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    stream: {
      type: MediaStream,
    },
    audioMeter: {
      type: Boolean,
      default: false,
    },
    toggleVideo: {
      type: Boolean,
      default: true,
    },
    toggleAudio: {
      type: Boolean,
      default: true,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    volume: {
      type: Number,
      default: 1,
    },
    threshold: {
      type: Number,
      default: 20,
    },
  },
  data: () => ({
    defaultVolume: 1,
  }),
  mounted() {
    this.$refs.video.muted = this.muted;
    this.$refs.video.volume = this.muted ? 0 : this.volume;
    this.$refs.video.srcObject = this.stream;

    this.$refs.video.onloadedmetadata = () => {
      this.$refs.video.play();
    };
  },
  watch: {
    stream: function (newStream) {
      //start it when stream change
      if (newStream != null) {
        this.$refs.video.muted = this.muted;
        this.$refs.video.volume = this.muted ? 0 : this.volume;
        this.$refs.video.srcObject = this.stream;

        this.$refs.video.onloadedmetadata = () => {
          this.$refs.video.play();
        };
      } else {
        this.stream.getTracks().forEach((track) => track.stop());

        this.$refs.video.srcObject = null;
        return false;
      }
    },
    toggleVideo: function () {
      this.stream.getTracks().forEach((track) => {
        if (track.kind == 'video') {
          track.enabled = this.toggleVideo;
        }
      });
    },
    toggleAudio: function () {
      this.stream.getTracks().forEach((track) => {
        if (track.kind == 'audio') {
          track.enabled = this.toggleAudio;
        }
      });
    },
    muted: function (val) {
      this.$refs.video.muted = this.muted;

      if (!val) {
        this.$refs.video.volume = this.volume;
      } else {
        this.$refs.video.volume = 0;
      }
    },
    volume: function () {
      if (!this.muted) {
        this.$refs.video.volume = this.volume;
      }
    },
  },
};
</script>

<style scoped>
.aspect-ratio-box {
  --size: 100%;
  height: 0;
  padding-top: calc(var(--size) / (16 / 9));
  position: relative;
}
.aspect-ratio-box-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #333;
}
.audio_indicator {
  background: #fff;
  width: 20px;
}

@media (max-width: 768px) {
  .plyr--video .plyr__controls {
    padding-left: 10%;
    padding-right: 10%;
  }
}
</style>
