<template>
  <div
    v-if="errors.has(name)"
    class="rounded-b bg-red-100 px-4 py-2 text-sm text-red-500"
  >
    <ul class="mb-0 mt-1 text-left">
      <li v-for="(error, index) in allErrors" :key="index">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['errors', 'name'],

  computed: {
    allErrors() {
      return this.errors.all(this.name);
    },
  },
};
</script>
