<template>
  <div class="flex min-h-screen flex-col items-center py-8 md:py-24">
    <img alt="Joinin logo" class="mb-12 w-72" src="../assets/logo-blue.png" />

    <div class="w-96 p-8">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
