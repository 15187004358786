var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.can('settings-ondemand'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):(
      _vm.event &&
      !['complete', 'vod_open', 'vod_closed'].includes(this.event.eventState)
    )?_c('div',{staticClass:"text-grey-400"},[_vm._v(" The event must be complete before On Demand settings can be accessed. ")]):(_vm.event)?_c('div',[_c('form',{staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticClass:"mb-6 text-lg font-medium"},[_vm._v("On demand access")]),(_vm.form.vodMode == 'manual')?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.vodEnabled),callback:function ($$v) {_vm.$set(_vm.form, "vodEnabled", $$v)},expression:"form.vodEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to access an on-demand recording if your event once complete. Attendees will be able to access the portal and see the embed URL video content. On-demand access can be opened/closed from the Studio once the event is complete. ")])],2)],1):_vm._e(),(_vm.form.vodMode == 'manual' && _vm.form.vodEnabled)?_c('MdTextField',{attrs:{"name":"embed","label":"Embed URL","required":_vm.form.vodEnabled == true,"tooltip":"URL link of the content you want to show in the on-demand portal. e.g.: https://player.vimeo.com/video/576334329","errors":_vm.errors},model:{value:(_vm.form.streamOverrideUrl),callback:function ($$v) {_vm.$set(_vm.form, "streamOverrideUrl", $$v)},expression:"form.streamOverrideUrl"}}):_vm._e(),(
          ['automatic_registration', 'manual_registration'].includes(
            this.event.registrationType
          )
        )?_c('div',[_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Registration")]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Allow new registrations","errors":_vm.errors,"options":[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]},model:{value:(_vm.form.vodAllowRegistration),callback:function ($$v) {_vm.$set(_vm.form, "vodAllowRegistration", $$v)},expression:"form.vodAllowRegistration"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees who missed the live session to register for this event during On Demand. Enabling this setting will override any configured registration cut off time. All attendees who registered prior to the live session will retain access. ")])],2)],1)]):_vm._e(),(_vm.event.chatEnabled || _vm.event.qaEnabled || _vm.event.pollEnabled)?_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v(" On demand engagement ")]):_vm._e(),(_vm.event.chatEnabled)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Display chat content","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.vodShowChat),callback:function ($$v) {_vm.$set(_vm.form, "vodShowChat", $$v)},expression:"form.vodShowChat"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees view chat messages submitted and approved during the live session. Submission is disabled. ")])],2)],1):_vm._e(),(_vm.event.qaEnabled)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Display Q&A content","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.vodShowQa),callback:function ($$v) {_vm.$set(_vm.form, "vodShowQa", $$v)},expression:"form.vodShowQa"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees view questions submitted and approved during the live session. Submission is disabled. ")])],2)],1):_vm._e(),(_vm.event.pollEnabled)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Display poll content","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.vodShowPoll),callback:function ($$v) {_vm.$set(_vm.form, "vodShowPoll", $$v)},expression:"form.vodShowPoll"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to view poll results submitted and approved during the live session. Submission is disabled. ")])],2)],1):_vm._e(),(_vm.event.surveyEnabled)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Display survey","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.vodShowSurvey),callback:function ($$v) {_vm.$set(_vm.form, "vodShowSurvey", $$v)},expression:"form.vodShowSurvey"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit a survey response. ")])],2)],1):_vm._e(),_c('SubmitButton',{attrs:{"disabled":!_vm.isValidtoSubmit,"saving":_vm.saving}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }