<template>
  <div>
    <form class="mt-8" @submit.prevent="save()">
      <MdTextField
        name="name"
        label="Account name"
        v-model="form.name"
        :errors="errors"
      />

      <MdTextField
        name="address"
        label="Address"
        v-model="form.address"
        :errors="errors"
      />

      <MdTextField
        name="city"
        label="City"
        v-model="form.city"
        :errors="errors"
      />

      <CountrySelect
        v-model="form.country"
        :country="form.country"
        placeholder="Select country"
        topCountry="AU"
        disablePlaceholder
        class="mb-6 w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500"
      />

      <RegionSelect
        v-model="form.state"
        :country="form.country"
        :region="form.state"
        placeholder="Select state"
        :class="{ 'text-grey-400': !form.state }"
        class="mb-6 w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500"
      />

      <MdEmailField
        name="billing_email"
        label="Billing email address"
        v-model="form.billingEmail"
        :errors="errors"
        tooltip="The billing email address for billing notifications and invoices."
      />

      <SubmitButton :saving="saving" />
    </form>

    <!-- Delete confirmation -->
    <Modal width="96" ref="confirmDelete">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Close account</h1>
        <div class="mb-6">
          Are you sure you want to close your account? All event and account
          information will be deleted and cannot be recovered. Your account
          cannot be recovered after closure.
        </div>

        <div class="pb-6">
          <MaterialIconsCheckbox
            v-model="accountCloseCheckbox"
            :label="'I understand'"
          />
        </div>

        <button
          class="btn-red"
          :disabled="accountCloseCheckbox == false"
          @click="closeAccount"
        >
          Close account
        </button>

        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmDelete.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import pick from 'lodash/pick';
import { CountrySelect, RegionSelect } from 'vue-country-region-select';

import api from '@/services/api';
import { EventBus } from '@/services/event-bus';
import errors from '@/mixins/errors';
import SubmitButton from '@/components/SubmitButton';
import MdTextField from '@/components/MdTextField';
import MdEmailField from '@/components/MdEmailField';
import Modal from '@/components/Modal';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';

export default {
  mixins: [errors],

  components: {
    CountrySelect,
    SubmitButton,
    MdTextField,
    MdEmailField,
    Modal,
    MaterialIconsCheckbox,
    RegionSelect,
  },

  props: {
    loading: Boolean,
    account: Object,
    reloadAccount: Function,
  },

  watch: {
    /*account: {
            handler: function(acc) {

                this.form = extend({}, pick(acc, [
                    'name', 'address', 'city', 'country', 'state', 'billingEmail'
                ]));
            },
            deep: true,
        },*/
    account: function (val) {
      if (val != null) {
        this.setupForm();
      }
    },
  },

  data() {
    return {
      saving: false,
      form: {
        name: null,
        address: null,
        city: null,
        country: null,
        state: null,
        billingEmail: null,
      },
      accountCloseCheckbox: false,
    };
  },

  mounted() {
    EventBus.$on('payment-method-updated', this.onPaymentMethodUpdatedEvent);

    this.setupForm();
  },

  beforeDestroy() {
    EventBus.$off('payment-method-updated', this.onPaymentMethodUpdatedEvent);
  },

  methods: {
    onPaymentMethodUpdatedEvent() {
      this.reloadAccount();
    },
    setupForm() {
      if (this.account != null) {
        this.form = {
          ...pick(this.account, [
            'name',
            'address',
            'city',
            'country',
            'billingEmail',
          ]),
        };

        //hack on vue-country-region component.
        //country select first which then trigger region v-model to set null
        //use nextTick to assing state to region to overcome the bug
        this.$nextTick(() => {
          this.form.state = this.account.state;
        });
      }
    },
    save() {
      this.saving = true;

      api.put('/account', this.form).then(() => {
        this.saving = false;

        this.notify('success', 'Details updated');
      });
    },
    closeAccount() {
      // TODO: JOININ-287 delete account
      console.log('deleting');
    },
  },
};
</script>
