<template>
  <MdSelectField
    :name="name"
    :value="value"
    :errors="errors"
    :options="sortedOptions"
    :tooltip="tooltip"
    :label="label"
    @input="handleInput"
  >
    <!-- an alterate render so we can set the font-family per option -->
    <template v-slot:default="slotProps">
      <div v-for="(option, index) in slotProps.options" :key="option.id">
        <button
          type="button"
          @click="slotProps.selectOption(option)"
          class="select-menu-option block cursor-pointer px-4 py-3 hover:bg-grey-100"
          :class="
            index == slotProps.selectedIndex
              ? 'bg-grey-200'
              : 'hover:bg-grey-50'
          "
          :style="'font-family:' + option.id"
        >
          {{ option.label }}
        </button>
      </div>
    </template>
  </MdSelectField>
</template>

<script>
/* global WebFont:readonly */
import axios from 'axios';
import each from 'lodash/each';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import MdSelectField from '@/components/MdSelectField';

export default {
  name: 'MdFontField',

  components: {
    MdSelectField,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    label: { type: String },
    tooltip: { type: String },
    value: { type: String },
  },

  data() {
    return {
      options: [],
    };
  },

  computed: {
    sortedOptions() {
      return sortBy(this.options, 'id');
    },
  },

  mounted() {
    this.initGoogleFontLoader();
  },

  beforeDestroy() {
    this._destroying = true;
  },

  methods: {
    initGoogleFontLoader() {
      if (!window.webFontLoadedPromise) {
        const script = document.createElement('script');
        script.async = false;
        script.src =
          'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        window.webFontLoadedPromise = new Promise((resolve) => {
          script.addEventListener('load', resolve);
        });
        document.head.appendChild(script);
      }
      window.webFontLoadedPromise.then(() => {
        if (this._destroying) return;
        this.load();
      });
    },

    load() {
      axios
        .get('https://www.googleapis.com/webfonts/v1/webfonts', {
          params: {
            key: process.env.VUE_APP_GOOGLE_API_KEY,
            fields: 'items.family,items.files',
            sort: 'popularity',
          },
        })
        .then((response) => {
          if (this._destroying) return;
          this.options = [];

          const fonts = response.data.items.slice(0, 70);

          each(fonts, (item) => {
            this.options.push({
              id: item.family,
              label: item.family,
              source: item.files.regular,
            });
          });

          // Load font
          WebFont.load({
            google: {
              families: map(this.options, (option) => option.id),
            },
          });
        });
    },

    handleInput(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>
