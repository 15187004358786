<template>
  <div>
    <div v-if="!can('settings-pages')" class="mb-6">
      <UpgradePlanLink />
    </div>

    <div v-else>
      <div class="flex pb-4">
        <div class="flex-1 text-grey-400">
          Add custom content to your portal
        </div>
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/settings/pages/new'"
          class="flex items-center font-bold text-blue-500 hover:text-blue-800"
        >
          <i class="material-icons-outlined pr-1 text-icon-sm text-blue-500"
            >add_box</i
          >
          Add page
        </RouterLink>
      </div>

      <!-- reorder controls -->
      <div class="relative flex items-center justify-end py-3">
        <button
          v-if="!isBlockReorder"
          @click="toggleBlockReorder"
          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
        >
          <i class="material-icons-outlined text-3xl">toc</i>
        </button>
        <div
          v-else-if="isBlockReorderSaving"
          class="flex h-10 w-10 items-center justify-center"
        >
          <i class="material-icons-outlined spin text-3xl">sync</i>
        </div>
        <div
          v-else-if="isBlockReorder && !isBlockReorderSaving"
          class="flex items-center justify-between"
        >
          <button
            @click="isBlockReorder = false"
            class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">close</i>
          </button>
          <button
            v-if="!isBlockReorderSaving"
            @click="toggleBlockReorder"
            class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">check</i>
          </button>
        </div>
      </div>

      <div class="flex flex-1 flex-col space-y-4">
        <div
          v-if="!pagesLoaded"
          class="flex items-center justify-center pt-8 text-center text-grey-400"
        >
          <i class="material-icons-outlined mr-1 animate-spin text-icon-sm"
            >sync</i
          >Loading
        </div>
        <div
          v-else-if="!getBlocks.length"
          class="pt-6 text-center text-grey-400"
        >
          No pages have been created
        </div>

        <div
          v-for="block in getBlocks"
          :key="block.id"
          :id="block.id"
          :draggable="isBlockReorder"
          @dragstart="handleStartDragBlock(block)"
          @dragover="handleDragOverBlock"
          @dragend="handleStopDragBlock"
          class="flex flex-col border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
        >
          <!-- show drop target -->
          <div
            v-if="
              isBlockReorder &&
              isBlockDragging &&
              selectedBlock &&
              selectedBlock.id == block.id
            "
            class="flex flex-1 items-center justify-center rounded border border-dashed border-grey-400 py-4"
          >
            <i class="material-icons-outlined mx-2 text-grey-400"
              >vertical_align_bottom</i
            >
            Drop here
          </div>

          <!-- show block -->
          <div v-else class="flex items-center">
            <div class="flex-1 space-y-2">
              <div class="text-xl font-semibold">{{ block.title }}</div>

              <div class="flex flex-row pt-1">
                <button
                  @click.prevent="showPage(block.id)"
                  class="pr-4 text-grey-400 hover:text-green-800"
                  :class="{ 'text-green-500': !block.isHidden }"
                >
                  Show
                </button>
                <button
                  @click.prevent="hidePage(block.id)"
                  class="pr-4 text-grey-400 hover:text-red-700"
                  :class="{ 'text-red-500': block.isHidden }"
                >
                  Hide
                </button>
              </div>
            </div>

            <!-- show action menu when not in reorder mode -->
            <DropdownMenu v-if="!isBlockReorder">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.prevent="slotProps.toggle()"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined text-md">more_vert</i>
                </button>
              </template>

              <template slot="menu">
                <RouterLink
                  :to="
                    '/events/' +
                    event.eventShortcode +
                    '/settings/pages/' +
                    block.id
                  "
                  class="dropdownListItem"
                  >Edit
                </RouterLink>

                <DropdownInlineConfirmButton
                  @callback="deletePage(block)"
                  confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
                >
                  Delete
                  <i class="material-icons-outlined pl-1 text-grey-400"
                    >delete</i
                  >
                </DropdownInlineConfirmButton>
              </template>
            </DropdownMenu>

            <!-- show reorder controls -->
            <div v-else class="flex h-10 w-10 items-center rounded-full">
              <i class="material-icons-outlined text-blue-500">unfold_more</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownMenu from '@/components/DropdownMenu';
import UpgradePlanLink from '@/components/UpgradePlanLink';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';

export default {
  components: {
    DropdownMenu,
    UpgradePlanLink,
    DropdownInlineConfirmButton,
  },

  props: ['event'],

  data() {
    return {
      jobId: (this.event || {}).id,
      isBlockReorder: false, // reorder mode initialized...
      isBlockReorderSaving: false, // reorder mode savng...
      isBlockDragging: false,
      reorderBlocks: [],
      selectedBlock: null,
      selectedBlockIndex: null,
    };
  },

  computed: {
    ...mapState(['pages', 'pagesLoaded']),

    getBlocks() {
      if (this.isBlockReorder) {
        return this.reorderBlocks;
      } else {
        return this.pages;
      }
    },
  },

  methods: {
    deletePage(page) {
      const { jobId } = this;
      const pageId = page.id;
      this.$store.dispatch('REMOVE_PAGE', { pageId, jobId });
    },
    showPage(pageId) {
      const { jobId } = this;
      this.$store.dispatch('SHOW_PAGE', { pageId, jobId });
    },
    hidePage(pageId) {
      const { jobId } = this;
      this.$store.dispatch('HIDE_PAGE', { pageId, jobId });
    },

    handleStartDragBlock(block) {
      this.selectedBlock = block;

      setTimeout(() => {
        this.isBlockDragging = true;
      }, 200);
    },

    handleDragOverBlock(e) {
      e.preventDefault();

      if (
        e.target &&
        e.target.id &&
        e.target.id !== '' &&
        +e.target.id != this.selectedBlock.id &&
        +e.target.id != this.currentOrderBlockTargetId
      ) {
        this.currentOrderBlockTargetId = +e.target.id;

        const cIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.selectedBlock.id
        );
        const tIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.currentOrderBlockTargetId
        );

        this.reorderBlocks.splice(
          tIndex,
          0,
          this.reorderBlocks.splice(cIndex, 1)[0]
        );
      }
    },

    handleStopDragBlock() {
      this.isBlockDragging = false;
    },

    toggleBlockReorder() {
      this.isBlockReorder = !this.isBlockReorder;

      if (this.isBlockReorder) {
        // load blocks to reorder array
        this.reorderBlocks = [...this.pages];
      } else {
        // save order
        const list = this.reorderBlocks.map((b) => b.id);

        this.$store.commit('SET_PAGES', this.reorderBlocks);
        this.$store.dispatch('REORDER_PAGES', list);
      }
    },
  },

  async beforeRouteEnter(_to, _from, next) {
    next((vm) => {
      vm.$store.dispatch('LOAD_PAGES', { jobId: vm.jobId });
    });
  },
};
</script>
