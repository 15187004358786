<template>
  <div class="w-auto bg-white p-8 shadow-lg sm:w-96">
    <div
      v-if="!isLoaded"
      class="flex flex-row items-center justify-center text-grey-400"
    >
      Loading
    </div>
    <div
      v-else-if="!isValidated"
      class="flex flex-row items-center justify-center text-grey-400"
    >
      Access denied
    </div>
    <div v-else>
      <h1 class="mb-6 text-center text-3xl font-bold">Set new password</h1>

      <div v-if="!saving">
        <form @submit.prevent="save()">
          <PasswordField
            name="password"
            class="py-3"
            label="New password"
            v-model="form.password"
            :errors="errors"
          />

          <div
            v-if="submitError != null"
            class="mb-1 mt-3 flex flex-row items-center px-2 py-1 text-red-500"
          >
            <i class="material-icons-outlined mr-2 text-red-500">error</i>
            {{ submitError }}
          </div>

          <SubmitButton :saving="saving" label="Save" />
        </form>
      </div>
      <div v-else class="text-center">
        <p class="mb-6 py-3">Your password has been reset.</p>
        <RouterLink
          to="/login"
          class="btn-blue flex items-center justify-center"
          >Ok</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';
import PasswordField from '@/components/PasswordField';
import SubmitButton from '@/components/SubmitButton';

export default {
  mixins: [errors],

  components: {
    PasswordField,
    SubmitButton,
  },

  data() {
    return {
      isLoaded: false,
      isValidated: false,
      form: {
        password: null,
      },
      saving: false,
    };
  },

  mounted() {
    api
      .post('/account/validatePasswordReset', {
        _t: this.$route.query._t,
      })
      .then((response) => {
        this.isLoaded = true;

        if (
          response.status >= 200 &&
          response.status < 300 &&
          response.data.validated == true
        ) {
          this.isValidated = true;
        }
      })
      .catch(() => {
        this.isLoaded = true;
        this.isValidated = false;
      });
  },

  methods: {
    save() {
      this.saving = true;

      api
        .post('/account/resetPassword', {
          _t: this.$route.query._t,
          password: this.form.password,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
