<template>
  <div class="h-full w-full">
    <div
      v-if="!clickedStart"
      class="mt-48 flex flex-col items-center justify-center"
    >
      <img class="w-36" src="../assets/logo-icon-blue.svg" />
      <div class="font-xl mb-2 mt-6 font-bold text-grey-800">
        Router Multiview
      </div>
      <button
        @click="initAudio"
        class="mt-12 cursor-pointer rounded bg-blue-500 px-4 py-1 text-white hover:bg-blue-800"
      >
        Start
      </button>
    </div>
    <div
      v-else-if="!janusLoaded"
      class="flex h-full items-center justify-center"
    >
      <LoadingIcon />
    </div>
    <div
      v-else
      class="grid h-full grid-flow-row auto-rows-fr gap-1"
      :class="gridLayout"
    >
      <PresenterPlayer
        v-for="(presenter, index) in getPresenters"
        :key="presenter.id"
        :presenter="presenter"
        :janus="janus"
        :janusVideoRoomId="event.videoRoomId"
        :janusPin="event.janusPin"
        :name="presenter.name"
        :index="index + 1"
        :allowStatus="allowStatus"
        :videoElementMuted="getVideoElementMuted(presenter.id)"
        :audioContext="audioContext"
        @update:videoElementMuted="handleVideoMutedChange"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PresenterPlayer from '@/components/PresenterPlayer.vue';
import LoadingIcon from '@/components/ui/LoadingIcon.vue';
import {
  loadJanusJs,
  trackJanusPublishers,
  setupTextroomReceiveStats,
} from '@/helper/janus.js';
import api from '@/services/api.js';

function hOP(obj, p) {
  return Object.prototype.hasOwnProperty.call(obj, p);
}

export default {
  components: {
    PresenterPlayer,
    LoadingIcon,
  },
  data: () => ({
    clickedStart: false,
    janusLoaded: false,
    janus: null,
    janusPublishers: {},
    ioClients: [],
    videoElementMuted: {},
    clientWebrtcStats: [],
    audioContext: null,
  }),
  computed: {
    ...mapState(['event']),
    gridLayout() {
      const filteredClients = this.ioClients.filter((c) =>
        ['input', 'both'].includes(c.ioDirection)
      );
      const total = filteredClients.length;

      if (total == 1) {
        return 'grid-cols-1';
      } else if (total == 2) {
        return 'grid-cols-2';
      } else if (total <= 4) {
        return 'grid-cols-2';
      } else if (total <= 6) {
        return 'grid-cols-3';
      } else if (total <= 9) {
        return 'grid-cols-3';
      } else if (total <= 12) {
        return 'grid-cols-4';
      } else if (total <= 16) {
        return 'grid-cols-4';
      } else if (total <= 25) {
        return 'grid-cols-5';
      } else {
        return 'grid-cols-6';
      }
    },
    getPresenters() {
      const publishers = Object.values(this.janusPublishers);
      return this.ioClients
        .filter((c) => ['input', 'both'].includes(c.ioDirection))
        .map((c) => {
          const pub = publishers.find((p) => p.id.startsWith(`IoClient-${c.id}-`));
          return Object.assign({}, c, {
            isOnline: pub != null,
            webrtcStats:
              this.clientWebrtcStats
                .find((s) => c.id == s.id)
                ?.stats.sort((a, b) => a.width - b.width) ?? [],
            pid: pub?.id,
          });
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    this.allowStatus = !hOP(this.$route.query, 'cleanScreen');
    this.fetchClients().then(() => {
      this.initMuteStateForPresenters();
    });
    this.janusInit();
    this.janusVideoroom();
    this._destroying = { value: false };
    this.janusConnectedPromise.then(() => {
      if (this._destroying.value) return;
      setupTextroomReceiveStats({
        janus: this.janus,
        janusPin: this.event.janusPin,
        clientWebrtcStats: this.clientWebrtcStats,
        destroying: this._destroying,
      });
    });
    this.clientWebrtcStatsExpireInterval = setInterval(() => {
      for (const stats of this.clientWebrtcStats) {
        for (let i = stats.stats.length - 1; i >= 0; i--) {
          const stat = stats.stats[i];
          if (Date.now() > stat.expiry) {
            stats.stats.splice(i, 1);
          }
        }
      }
    }, 1000);
  },
  beforeDestroy() {
    this._destroying.value = true;
    clearInterval(this.clientWebrtcStatsExpireInterval);
    if (this.janus) {
      this.janus._destroying = true;
      this.janus.destroy();
    }
  },
  methods: {
    async fetchClients() {
      await api.get(`/io/${this.event.jobId}/clients`).then((res) => {
        this.ioClients = res.data;
      });
    },
    janusInit() {
      loadJanusJs(this.event.janusHost);
      this.janusConnectedPromise = Promise.all([
        window.adapterLoadedPromise,
        window.janusLoadedPromise,
      ]).then(
        () =>
          new Promise((resolve) => {
            if (this._destroying.value) {
              return void resolve();
            }
            this.janus = new Janus({
              server: this.event.janusHost + '/janus',
              success: resolve,
              error: console.error.bind(console),
              destroyed: () => {
                console.log('destroyed');
              },
            });
          })
      );

      this.janusConnectedPromise.then(() => {
        this.janusLoaded = true;
      });
    },
    async janusVideoroom() {
      await this.janusConnectedPromise;
      if (this._destroying.value) return;
      this.janus.attach({
        plugin: 'janus.plugin.videoroom',
        success: (handle) => {
          handle.send({
            message: {
              request: 'join',
              ptype: 'publisher',
              room: String(this.event.videoRoomId),
              pin: this.event.janusPin,
              display: 'IoRouterMultiview',
            },
          });
        },
        error: (err) => {
          console.log(err);
        },
        onmessage: (msg) => {
          //console.log(msg);
          const { removedId, added } = trackJanusPublishers({
            janusPublishers: this.janusPublishers,
            janusMsg: msg,
          });
          if (removedId) {
            const [pType, pTypeId] = removedId.split('-');
            if (pType === 'IoClient') {
              const client = this.ioClients.find((c) => pTypeId == c.id);
              if (client) {
                this.$store.commit(
                  'ERROR',
                  `IoClient ${client.name} is offline.`
                );
              }
              const index = this.clientWebrtcStats.findIndex(
                (c) => c.id == pTypeId
              );
              if (index != -1) {
                this.clientWebrtcStats.splice(index, 1);
              }
            }
          }
          if (added.length > 0) {
            for (const newPub of added) {
              const [pType, pTypeId] = newPub.id.split('-');
              if (pType === 'IoClient') {
                const client = this.ioClients.find((c) => pTypeId == c.id);
                if (client) {
                  this.$store.commit(
                    'MESSAGE',
                    `Presenter ${client.name} is online.`
                  );
                }
              }
            }
          }
        },
      });
    },
    initMuteStateForPresenters() {
      this.ioClients.forEach((presenter) => {
        this.$set(this.videoElementMuted, presenter.id, true);
      });
    },
    handleVideoMutedChange(value, presenterId) {
      this.$set(this.videoElementMuted, presenterId, value);
    },
    getVideoElementMuted(presenterId) {
      return this.videoElementMuted[presenterId] || false;
    },
    initAudio() {
      this.audioContext = new AudioContext();
      this.clickedStart = true;
    },
  },
};
</script>
