/**
 * Functionality related to handling of form validation errors.
 */
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';

import FormErrors from '@/classes/FormErrors';

export default {
  data() {
    return {
      errors: new FormErrors(),
      submitError: null,
    };
  },
  methods: {
    clearErrors() {
      this.errors.forget();
    },

    /**
     * Displays error messages in corebuild responses with an error status.
     */
    handleErrorStatus(error) {
      if (error.response.status < 400) {
        return;
      }

      if (error.response.data.display) {
        this.notify('error', error.response.data.error);
      }
    },

    /**
     * Handles any errors encountered in the resource save process.
     */
    handleSaveError(error) {
      this.saving = false;
      this.errors.forget();
      this.submitError = null;

      if (error.response.status == 422 || error.response.status == 423) {
        this.errors.set(error.response.data);

        // Set focus to the first error key that matches a field.
        forEach(keys(this.errors.errors), function (key) {
          let field = document.getElementById(key);

          if (field) {
            field.focus();
            return false;
          }
        });
      } else if (error.response.status >= 400) {
        this.submitError = error.response.data?.error;
      }
    },
  },
};
