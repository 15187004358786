<template>
  <div>
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full w-full justify-start overflow-x-auto border-b border-grey-100 md:relative"
      >
        <button
          v-if="event.chatEnabled == true"
          @click.prevent="activeTab = 'chat'"
          class="relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          :class="{
            'border-b border-blue-800 bg-grey-100': activeTab == 'chat',
          }"
        >
          Chat
          <div
            v-if="unreadEngagementChatMessagesCount > 0"
            class="badge right-2 top-2"
          >
            {{ unreadEngagementChatMessagesCount }}
          </div>
        </button>
        <button
          v-if="event.qaEnabled == true"
          @click.prevent="activeTab = 'qa'"
          class="relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          :class="{
            'border-b border-blue-800 bg-grey-100': activeTab == 'qa',
          }"
        >
          Q&A
          <div
            v-if="unreadEngagementQAMessagesCount > 0"
            class="badge right-2 top-2"
          >
            {{ unreadEngagementQAMessagesCount }}
          </div>
        </button>
        <button
          v-if="event.pollEnabled == true"
          @click.prevent="activeTab = 'poll'"
          class="relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          :class="{
            'border-b border-blue-800 bg-grey-100': activeTab == 'poll',
          }"
        >
          Poll
        </button>
        <button
          v-if="event.quizEnabled == true"
          @click.prevent="activeTab = 'quiz'"
          class="relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          :class="{
            'border-b border-blue-800 bg-grey-100': activeTab == 'quiz',
          }"
        >
          Quiz
        </button>
        <button
          v-if="event.surveyEnabled == true"
          @click.prevent="activeTab = 'survey'"
          class="relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          :class="{
            'border-b border-blue-800 bg-grey-100': activeTab == 'survey',
          }"
        >
          Survey
        </button>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <div v-show="activeTab == 'chat'" class="flex h-full min-h-0">
        <StudioPanelChat
          v-if="event.chatEnabled == true"
          :isFocus="activeTab == 'chat'"
          @newMessagesNotification="handleEngagementChatNewMessagesNotification"
        />
      </div>

      <div v-show="activeTab == 'qa'" class="flex h-full min-h-0">
        <StudioPanelQA
          v-if="event.qaEnabled == true"
          :isFocus="activeTab == 'qa'"
          @newMessagesNotification="handleEngagementQANewMessagesNotification"
        />
      </div>

      <div v-show="activeTab == 'poll'" class="flex h-full min-h-0">
        <StudioPanelPoll v-if="event.pollEnabled == true" />
      </div>

      <div v-show="activeTab == 'quiz'" class="flex h-full min-h-0">
        <StudioPanelQuiz v-if="event.quizEnabled == true" />
      </div>

      <div v-show="activeTab == 'survey'" class="flex h-full min-h-0">
        <StudioPanelSurvey v-if="event.surveyEnabled == true" />
      </div>
    </div>
  </div>
</template>

<script>
import StudioPanelChat from '@/components/StudioPanelChat';
import StudioPanelQA from '@/components/StudioPanelQA';
import StudioPanelQuiz from '@/components/StudioPanelQuiz';
import StudioPanelPoll from '@/components/StudioPanelPoll';
import StudioPanelSurvey from '@/components/StudioPanelSurvey';

export default {
  name: 'EventEngagement',

  props: ['event'],

  components: {
    StudioPanelChat,
    StudioPanelQA,
    StudioPanelQuiz,
    StudioPanelPoll,
    StudioPanelSurvey,
  },

  data: () => ({
    activeTab: 'chat',
    unreadEngagementChatMessagesCount: 0,
    unreadEngagementQAMessagesCount: 0,
  }),

  async mounted() {
    await this.loadData();
    this.controlInit();
  },

  methods: {
    controlInit() {
      this.$store.dispatch('CONTROL_INIT');
    },

    async loadData() {
      await this.$store.dispatch('GET_JOB');
    },

    handleEngagementChatNewMessagesNotification(payload) {
      this.unreadEngagementChatMessagesCount = payload;
    },

    handleEngagementQANewMessagesNotification(payload) {
      this.unreadEngagementQAMessagesCount = payload;
    },
  },
};
</script>
