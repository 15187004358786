<template>
  <div class="relative">
    <a
      class="focus:outline-none tooltip-trigger cursor-pointer transition hover:text-blue-500"
      @click.prevent="toggle()"
      @mouseover="triggerType == 'hover' && show()"
      @mouseleave="triggerType == 'hover' && close()"
    >
      <slot name="control"></slot>
    </a>

    <transition name="fade">
      <div
        v-if="visible"
        class="tooltip-modal absolute z-50 mt-4 bg-white p-4 text-grey-1000 shadow-lg lg:rounded-lg"
        :class="[widthClass, textAlignClass, xAlignClass]"
        :style="{
          bottom,
        }"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/services/event-bus';

export default {
  data() {
    return {
      visible: false,
    };
  },

  props: {
    yAlign: {
      default: 'below',
    },
    xAlign: {
      default: 'right',
    },
    textAlign: {
      default: 'left',
    },
    triggerType: {
      default: 'click',
    },
    width: {
      default: 96,
    },
  },

  computed: {
    bottom() {
      return this.yAlign == 'above' ? '2rem' : '';
    },
    widthClass() {
      return 'w-' + this.width;
    },
    textAlignClass() {
      return 'text-' + this.textAlign;
    },
    xAlignClass() {
      return this.xAlign + '-0';
    },
  },

  watch: {
    visible() {
      this.$store.commit('tooltipModalOpen', this.visible);
    },
  },

  mounted() {
    EventBus.$on('close-modal', this.onCloseModalEvent);
  },

  beforeDestroy() {
    EventBus.$off('close-modal', this.onCloseModalEvent);
    window.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('mousedown', this.onMouseDown);
  },

  methods: {
    onCloseModalEvent() {
      this.close();
    },

    /**
     * Listens for global events that should close the modal.
     */
    registerCloseListeners() {
      // escape key closes modal
      window.addEventListener('keydown', this.onKeyDown);

      // clicking outside the modal closes modal
      document.addEventListener('mousedown', this.onMouseDown);
    },
    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.close();
      }
    },
    onMouseDown(event) {
      const el = event.target;
      if (
        el.matches('.tooltip-modal') &&
        el.closest('.tooltip-trigger') != null
      ) {
        this.close();
      }
    },

    toggle() {
      if (this.visible) {
        this.close();
        return;
      }

      this.show();
    },

    show() {
      this.visible = true;
      this.registerCloseListeners();
    },

    close() {
      this.visible = false;
    },
  },
};
</script>
