import indexOf from 'lodash/indexOf';
import keys from 'lodash/keys';
import isArray from 'lodash/isArray';

class FormErrors {
  errors = {};

  /**
   * Determine if the collection has errors for a given field.
   */
  has = function (field) {
    return indexOf(keys(this.errors), field) > -1;
  };

  /**
   * Get all messages for a given field.
   */
  all = function (field) {
    if (this.has(field)) {
      if (isArray(this.errors[field])) {
        return this.errors[field];
      }

      return [this.errors[field]];
    }
  };

  /**
   * Set the raw errors for the collection.
   */
  set = function (errors) {
    this.errors = errors.errors;
  };

  /**
   * Remove errors from the collection.
   */
  forget = function (field) {
    if (field) {
      delete this.errors[field];
      return;
    }

    this.errors = {};
  };
}

export default FormErrors;
