<template>
  <div>
    <div class="flex justify-between space-x-4">
      <div class="flex flex-col justify-center">
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/settings/pages'"
          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </RouterLink>
      </div>
      <div class="flex-1">
        <div class="text-2xl font-medium">
          {{ page.title ? page.title : 'Add new page' }}
        </div>
      </div>
    </div>

    <div class="flex-1 pt-8">
      <form @submit.prevent="save()">
        <MdTextField
          name="title"
          label="Page title"
          v-model="form.title"
          :errors="errors"
        />
        <!-- As per the docs, v-model and wysiwyg do not play nice: -->
        <Editor
          :initialValue="form.content"
          :options="editorOptions"
          height="auto"
          placeholder="Add your page content..."
          initialEditType="wysiwyg"
          previewStyle="tab"
          ref="toastuiEditor"
        />
        <SubmitButton :saving="saving" />
      </form>
    </div>
  </div>
</template>

<script>
import errors from '@/mixins/errors';
import SubmitButton from '@/components/SubmitButton';
import MdTextField from '@/components/MdTextField';
import { find } from 'lodash';

import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import { Editor } from '@toast-ui/vue-editor';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    SubmitButton,
    MdTextField,
    Editor,
  },

  data() {
    return {
      page: { title: '', content: '' },
      pageId: null,
      isNew: true,
      jobId: (this.event || {}).id,
      loading: false,
      saving: false,
      editorOptions: {
        hideModeSwitch: false,
        usageStatistics: false,
        plugins: [colorSyntax],
        toolbarItems: [
          'heading',
          'bold',
          'italic',
          'strike',
          'divider',
          'hr',
          'divider',
          'ul',
          'ol',
          'divider',
          'table',
          'image',
          'link',
        ],
      },
      form: {
        title: '',
        content: '',
      },
    };
  },

  mounted() {
    // TODO: Redirect

    // TODO: Put this somewhere else?
    this.pageId = this.$route.params.pageId;
    this.isNew = this.pageId == 'new';

    this.page = this.isNew
      ? { title: '', content: '' }
      : find(this.$store.state.pages, (p) => p.id == this.pageId);
    if (!this.page) {
      console.error(`Page ${this.pageId} not found!`);
      this.$router.replace(
        `/events/${this.event.eventShortcode}/settings/pages`
      );
    }

    this.form.title = this.page.title;
    this.form.content = this.page.content;
    this.$refs.toastuiEditor.invoke('setMarkdown', this.page.content);
  },

  methods: {
    async save() {
      this.saving = true;
      const content = this.$refs.toastuiEditor.invoke('getMarkdown');
      const data = {
        jobId: this.jobId,
        title: this.form.title,
        content,
      };
      if (!this.isNew) {
        data.pageId = parseInt(this.pageId, 10);
        await this.$store.dispatch('UPDATE_PAGE', data);
      } else {
        await this.$store.dispatch('CREATE_PAGE', data);
      }
      this.saving = false;
      this.$router.push(`/events/${this.event.eventShortcode}/settings/pages`);
    },
  },
};
</script>

<style>
.tui-editor-contents {
  font-family: 'Circular Std', 'Arial';
  font-size: 16px;
}
.tui-editor-contents img {
  margin: auto;
}
</style>
