<template>
  <div>
    <div v-if="isTrialAccount" class="flex justify-center py-4">
      <div
        v-if="isTrialValid"
        class="flex items-center rounded-full border border-blue-500 px-2 py-1 text-blue-500"
      >
        <i class="material-icons-outlined mr-1 text-blue-500">new_releases</i>
        Free access until {{ trialExpiry }}
      </div>
      <div
        v-else
        class="flex items-center rounded-full border border-grey-400 px-2 py-1 text-sm text-grey-400"
      >
        <i class="material-icons-outlined mr-1 text-grey-400">new_releases</i>
        <p>
          Free access until {{ trialExpiry }}.
          <RouterLink
            :to="'/billing/plan'"
            class="text-blue-500 hover:text-blue-800"
            >Upgrade your account</RouterLink
          >
        </p>
      </div>
    </div>

    <div
      v-if="subscriptionStatus == 'past_due'"
      class="flex justify-center py-4"
    >
      <RouterLink
        v-if="allow('billing-details')"
        to="/billing/plan"
        class="flex flex-col items-center space-x-2 rounded-3xl border border-orange-500 px-3 py-1 text-sm text-orange-500 hover:border-orange-800 hover:text-orange-800 lg:flex-row"
      >
        <i class="material-icons-outlined text-orange-500">report_problem</i>
        <div class="text-center">
          Urgent billing issue. Please update your payment information now.
        </div>
      </RouterLink>
    </div>

    <ListView
      :isLoading="isLoading"
      :items="filteredEvents"
      :search="true"
      sortName
      filterKey="registrationType"
      :filterOptions="registrationTypeOptions"
      :allowNew="false"
    >
      <!-- template for main -->
      <template #main="{ item: event }">
        <EventSummary :event="event" />
      </template>
      <!-- end of main template -->
    </ListView>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EventSummary from '@/components/EventSummary';
import ListView from '@/components/ui/ListView';

import dayjs from 'dayjs';

export default {
  components: {
    EventSummary,
    ListView,
  },

  props: {
    events: Array,
    loading: Boolean,
  },

  data() {
    return {
      s: '',
      isLoading: false,
      registrationTypeOptions: [
        { id: 'anyone', label: 'Anyone' },
        { id: 'automatic_registration', label: 'Automatic registration' },
        { id: 'manual_registration', label: 'Manual registration' },
        { id: 'password', label: 'Password' },
        { id: 'pre_registration', label: 'Pre registration' },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'isTrialAccount',
      'trialExpiry',
      'isTrialValid',
      'subscriptionStatus',
    ]),
    filteredEvents() {
      return this.events
        .filter((event) =>
          ['complete', 'vod_open', 'vod_closed'].includes(event.eventState)
        )
        .sort((a, b) => {
          return dayjs(a.startTime).diff(b.startTime);
        });
    },
  },
};
</script>
