<template>
  <div class="relative mb-6">
    <input
      type="text"
      ref="input"
      class="input bg-transparent"
      :class="{ 'is-invalid rounded-b-none': errors.has(name) }"
      :id="name"
      :name="name"
      autocomplete="off"
      :placeholder="label"
      v-model="localValue"
      :autofocus="autofocus"
      :required="required"
      :disabled="disabled"
      @keypress="handleInput"
    />

    <button
      v-if="clearable && value != null"
      @click="handleClearEvent"
      class="input-shadow absolute right-1 top-1 flex h-12 w-12 items-center justify-center rounded-full bg-white hover:bg-grey-200"
    >
      <i class="material-icons-outlined">close</i>
    </button>

    <ValidationErrors :errors="errors" :name="name"></ValidationErrors>

    <div v-if="help" class="mt-2 text-grey-600" v-html="help"></div>
  </div>
</template>

<script>
import ValidationErrors from '@/components/ValidationErrors';

export default {
  components: {
    ValidationErrors,
  },

  props: {
    errors: {},
    help: null,
    id: null,
    name: null,
    placeholder: null,
    value: null,
    label: {
      default: String,
    },
    autofocus: {
      default: false,
    },
    required: {
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.$refs.input.focus());
    }
  },
  data() {
    return {
      localValue: this.value,
    };
  },

  watch: {
    value() {
      this.localValue = this.value;
    },

    localValue() {
      this.$emit('input', this.localValue);
    },
  },

  methods: {
    handleInput(e) {
      if (this.disableInput) {
        e.preventDefault();
      } else if (e.key == 'Enter') {
        this.$emit('keypress-enter', e);
      } else {
        this.$emit('keypress', e);
      }
    },
    handleClearEvent() {
      this.localValue = null;
      this.$emit('onclear', this.id || this.name);
    },
  },
};
</script>
