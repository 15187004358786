<template>
  <div class="layout_wrap">
    <div ref="layout" class="layout">
      <div>
        <div v-if="!isLoaded">Loading...</div>
        <div
          v-else
          v-for="slot in layout.slots"
          :key="slot.slotNo"
          class="slot"
          :class="{
            selected: selectedSlot == slot,
            done: slot.source && slot.source != null ? true : false,
          }"
          :style="{
            zIndex: slot.slotNo,
            width: width(slot.width, offsetWidth) + 'px',
            height: height(slot.height, offsetHeight) + 'px',
            left: posLeft(slot.x, offsetWidth) + 'px',
            top: posTop(slot.y, offsetHeight) + 'px',
          }"
          @click="handleSelectSlot(slot)"
        >
          {{ slot.slotNo }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StudioPanelLayout',
  props: {
    layout: {},
    isSelectable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoaded: false,
    selectedSlot: null,
    completedSlots: [],
  }),
  mounted() {
    this.isLoaded = true;

    //pre-select first one
    this.$nextTick(() => {
      if (this.layout.slots.length > 0) {
        this.handleSelectSlot(this.layout.slots[0]);
      }
    });
  },
  computed: {
    ...mapGetters(['width', 'height', 'posLeft', 'posTop']),
    offsetWidth() {
      return this.$refs.layout.offsetWidth;
    },
    offsetHeight() {
      return this.$refs.layout.offsetHeight;
    },
  },
  methods: {
    handleSelectSlot(slot) {
      if (!this.isSelectable) return;

      if (this.selectedSlot != null && this.selectedSlot == slot) {
        this.selectedSlot = null;
      } else {
        this.selectedSlot = slot;
      }

      this.$emit('onselect', this.selectedSlot);
    },
  },
};
</script>
<style scoped>
.layout_wrap:hover {
  opacity: 0.75;
  transition: all 0.25s;
  cursor: pointer;
}
.layout {
  --width: 100%;
  width: var(--width);
  padding-top: calc(9 / 16 * var(--width));
  position: relative;
  background: #f8f8f8;
  overflow: hidden;
}
.layout > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.slot {
  position: absolute;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #e0e0e0;
  font-size: 1.2em;
  color: #fff;
}
.selected {
  background: #6edc9d !important;
}
.done {
  background: #219653;
}
h4 {
  text-align: left;
  margin-top: 5px;
}
</style>
