<template>
  <div>
    <div class="pb-8 text-grey-400">
      Configure engagement options for your event.
    </div>

    <form v-show="event" class="mt-4" @submit.prevent="save()">
      <div v-if="event.eventState != 'complete'">
        <div class="mb-6 pt-6 text-lg font-medium">Chat</div>

        <div v-if="!can('event-chat')" class="mb-6">
          <UpgradePlanLink />
        </div>

        <div v-else>
          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                name="enabled"
                label="Enabled"
                v-model="form.chatEnabled"
                :errors="errors"
                :options="[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Allow attendees to submit and read chat messages.
              </template>
            </TooltipModal>
          </div>

          <div v-if="form.chatEnabled">
            <MdTextField
              name="chatTabTitle"
              label="Tab title"
              v-model="form.chatTabTitle"
              :errors="errors"
              tooltip="Set the title of the tab housing the Chat function."
            />

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="moderation_type"
                  label="Moderation type"
                  v-model="form.chatModerationType"
                  :errors="errors"
                  :options="[
                    {
                      id: 'auto_approve_new_messages',
                      label: 'Automatically show new messages',
                    },
                    {
                      id: 'manual_approve_new_messages',
                      label: 'Manually show new messages',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div>
                      <span class="font-bold">Automatically:</span>
                      chat messages will be approved immediately once submitted.
                      Chat messages can be manually removed.
                    </div>
                    <div>
                      <span class="font-bold">Manually:</span>
                      chat messages must be approved by an operator.
                    </div>
                  </div>
                </template>
              </TooltipModal>
            </div>

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="image_submission"
                  label="Allow image submissions"
                  v-model="form.chatImageSubmissionEnabled"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to submit images from this tab.
                </template>
              </TooltipModal>
            </div>

            <MdTextField
              name="chatTabTitle"
              label="Call to action message"
              v-model="form.chatCallToAction"
              :errors="errors"
              tooltip="Set the Call To Action text that will appear in the submission box of this tab."
            />
          </div>
        </div>

        <div class="mb-6 pt-6 text-lg font-medium">Q&A</div>

        <div v-if="!can('event-qa')" class="mb-6">
          <UpgradePlanLink />
        </div>

        <div v-else>
          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                name="enabled"
                label="Enabled"
                v-model="form.qaEnabled"
                :errors="errors"
                :options="[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Allow attendees to submit questions.
              </template>
            </TooltipModal>
          </div>

          <div v-if="form.qaEnabled">
            <MdTextField
              name="qaTabTitle"
              label="Tab title"
              v-model="form.qaTabTitle"
              :errors="errors"
              tooltip="Set the title of the tab housing the Q&A function."
            />

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="moderation_type"
                  label="Moderation type"
                  v-model="form.qaModerationType"
                  :errors="errors"
                  :options="[
                    {
                      id: 'auto_approve_new_messages',
                      label: 'Automatically approve new messages',
                    },
                    {
                      id: 'manual_approve_new_messages',
                      label: 'Manually approve new messages',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div>
                      <span class="font-bold">Automatically:</span>
                      questions will be approved immediately once submitted.
                      Questions can be manually removed.
                    </div>
                    <div>
                      <span class="font-bold">Manually:</span>
                      questions must be approved by an operator.
                    </div>
                  </div>
                </template>
              </TooltipModal>
            </div>

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="attendee_approved_visible"
                  label="Show approved"
                  v-model="form.qaAttendeeApprovedVisible"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to see questions after they have been
                  approved. If set to 'No' your Q&A will be entirely private.
                </template>
              </TooltipModal>
            </div>

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="allow_anonymous_submissions"
                  label="Allow anonymous"
                  v-model="form.qaAnonymousSubmissions"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to submit anonymous questions. Identifying
                  information is removed from anonymous questions.
                </template>
              </TooltipModal>
            </div>

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="allow_for_presenter_submissions"
                  label="Allow questions directed at presenters"
                  v-model="form.qaForPresenterSubmissions"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to submit questions directed at a specific
                  Presenter. Attendees will be able to see and select from all
                  presenters in the event.
                </template>
              </TooltipModal>
            </div>

            <div v-if="form.qaAttendeeApprovedVisible" class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="allow_attendee_upvoting"
                  label="Allow attendee voting"
                  v-model="form.qaAttendeeUpvoting"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to upvote questions so presenters can see the
                  most popular questions.
                </template>
              </TooltipModal>
            </div>
          </div>
        </div>

        <div class="mb-6 pt-6 text-lg font-medium">Polling</div>
        <div v-if="!can('event-poll')" class="mb-6">
          <UpgradePlanLink />
        </div>

        <div v-else>
          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                name="enabled"
                label="Enabled"
                v-model="form.pollEnabled"
                :errors="errors"
                :options="[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Allow attendees to vote on polls.
              </template>
            </TooltipModal>
          </div>

          <div v-if="form.pollEnabled">
            <MdTextField
              name="pollTabTitle"
              label="Tab title"
              v-model="form.pollTabTitle"
              :errors="errors"
              tooltip="Set the title of the tab housing the Polling function."
            />

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  name="results_visible"
                  label="Show results after vote"
                  v-model="form.pollResultsVisible"
                  :errors="errors"
                  :options="[
                    {
                      id: true,
                      label: 'Yes',
                    },
                    {
                      id: false,
                      label: 'No',
                    },
                  ]"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  Allow attendees to see the results of polls after submitting
                  their answer. To keep poll results confidential set to 'No'.
                </template>
              </TooltipModal>
            </div>
          </div>
        </div>

        <div class="mb-6 pt-6 text-lg font-medium">Quiz</div>
        <div v-if="!can('event-quiz')" class="mb-6">
          <UpgradePlanLink />
        </div>

        <div v-else>
          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                name="enabled"
                label="Enabled"
                v-model="form.quizEnabled"
                :errors="errors"
                :options="[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Allow attendees to participate in a quiz.
              </template>
            </TooltipModal>
          </div>

          <div v-if="form.quizEnabled">
            <MdTextField
              name="quizTabTitle"
              label="Tab title"
              v-model="form.quizTabTitle"
              :errors="errors"
              tooltip="Set the title of the tab housing the Quiz function."
            />
          </div>
        </div>
      </div>

      <div class="mb-6 pt-6 text-lg font-medium">Survey</div>
      <div v-if="!can('event-survey')" class="mb-6">
        <UpgradePlanLink />
      </div>

      <div v-else>
        <div class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Enabled"
              v-model="form.surveyEnabled"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees to participate in a survey.
            </template>
          </TooltipModal>
        </div>

        <div v-if="form.surveyEnabled">
          <MdTextField
            name="quizTabTitle"
            label="Tab title"
            v-model="form.surveyTabTitle"
            :errors="errors"
            tooltip="Set the title of the tab housing the Survey function."
          />
        </div>
      </div>

      <SubmitButton :saving="saving" />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import errors from '@/mixins/errors';
import MdTextField from '@/components/MdTextField';
import MdSelectField from '@/components/MdSelectField';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    MdTextField,
    MdSelectField,
    TooltipModal,
    SubmitButton,
    UpgradePlanLink,
  },

  data() {
    return {
      form: {
        chatEnabled: true,
        chatTabTitle: null,
        chatModerationType: 'auto_approve_new_messages',
        chatImageSubmissionEnabled: true,
        chatCallToAction: 'Post a chat message',
        qaEnabled: true,
        qaTabTitle: null,
        qaModerationType: 'manual_approve_new_messages',
        qaAnonymousSubmissions: false,
        qaForPresenterSubmissions: false,
        qaAttendeeUpvoting: false,
        qaAttendeeApprovedVisible: true,
        pollEnabled: true,
        pollTabTitle: null,
        pollResultsVisible: true,
        quizEnabled: false,
        quizTabTitle: null,
        surveyEnabled: false,
        surveyTabTitle: null,
      },
      saving: false,
    };
  },

  computed: {
    ...mapState(['sessionData']),
  },

  async mounted() {
    if (this.event) {
      this.setupForm();
    }
  },

  methods: {
    setupForm() {
      assign(this.form, pick(this.event, keys(this.form)));
    },

    save() {
      this.saving = true;

      // TODO: Use the Vuex store for this!
      api
        .put('/jobs/' + this.event.id, {
          ...this.form,
        })
        .then((response) => {
          this.saving = false;

          EventBus.$emit('event-updated', response.data);
          this.notify('success', 'Event updated');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
