<template>
  <div class="flex flex-grow flex-col">
    <!-- main content -->
    <template
      v-if="
        ['rehearsal', 'live'].includes(event.eventState) && !coreGstMediaSynced
      "
    >
      <div
        class="mt-12 flex h-full flex-col items-center justify-start text-grey-400"
      >
        <div class="flex flex-row items-center pb-1">
          <i class="material-icons-outlined animate-spin">sync</i>
          <div class="pl-1">Syncing library media</div>
        </div>
        <div class="text-xs text-grey-400">
          This won't take long. You will be notified when complete.
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="section == null">
        <div class="flex h-full flex-col">
          <!-- search filter -->
          <div class="my-3 flex items-center justify-between space-x-2">
            <SelectFilter
              v-if="!isSearch"
              class="flex-1"
              v-model="filter"
              :options="filterOptions"
            />

            <input
              v-if="isSearch"
              type="text"
              v-model="search"
              placeholder="Search file..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button @click.prevent="isSearch = !isSearch">
              <i
                v-if="!isSearch"
                class="material-icons-outlined flex justify-center"
                >search</i
              >
              <i
                v-if="isSearch"
                class="material-icons-outlined flex justify-center"
                >sort</i
              >
            </button>
          </div>

          <!-- file list -->
          <div class="flex flex-col overflow-auto lg:flex-grow">
            <div
              v-if="getFiles.length == 0"
              class="flex flex-1 justify-center text-grey-400"
            >
              <div v-if="search">No search results found</div>
              <div v-else>Upload media using the button below</div>
            </div>

            <div
              v-for="(file, index) in getFiles"
              :key="index"
              :id="'file_' + index"
              @click.self="handleSelectFile(file)"
              @dblclick.prevent="handleAddMediaToSource(file)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              :class="{ 'bg-grey-100': selectedFile == file }"
            >
              <div class="flex flex-shrink-0 justify-center">
                <i v-if="file.type == 'image'" class="material-icons-outlined"
                  >photo</i
                >

                <i
                  v-else-if="file.type == 'video'"
                  class="material-icons-outlined"
                  >videocam</i
                >

                <i
                  v-else-if="file.type == 'presentation'"
                  class="material-icons-outlined"
                  >auto_awesome_motion</i
                >

                <i v-else class="material-icons-outlined">insert_drive_file</i>
              </div>
              <div
                class="relative flex flex-1 items-center"
                @click="handleSelectFile(file)"
              >
                <div
                  class="break-all font-medium"
                  :class="{
                    'text-grey-400':
                      file.uploadState && file.uploadState != 'ready',
                  }"
                >
                  {{ file.title }}
                </div>
                <div
                  v-if="file.uploadState && file.uploadState != 'ready'"
                  class="absolute -bottom-2 flex items-center"
                >
                  <div
                    class="mr-2 h-1 w-1 animate-pulse rounded-full bg-green-500"
                  ></div>
                  <div
                    class="mr-2 h-1 w-1 animate-pulse rounded-full bg-green-500"
                    style="animation-delay: 0.3s"
                  ></div>
                  <div
                    class="h-1 w-1 animate-pulse rounded-full bg-green-500"
                    style="animation-delay: 0.6s"
                  ></div>
                </div>
              </div>

              <div class="items-right flex space-x-2">
                <div
                  v-if="file.isActive"
                  class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
                >
                  Active
                </div>

                <div
                  v-if="file.mediaRole == 'Background'"
                  class="rounded-full bg-green-500 px-2 py-1 text-xs font-bold text-white"
                >
                  Background
                </div>

                <div
                  v-if="file.mediaRole == 'PresenterFallback'"
                  class="rounded-full bg-green-500 px-2 py-1 text-xs font-bold text-white"
                >
                  Fallback
                </div>
              </div>

              <DropdownMenu>
                <template slot="control" slot-scope="slotProps">
                  <button
                    :disabled="file.uploadState && file.uploadState != 'ready'"
                    @click.stop="handleDropdown(slotProps)"
                    class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">more_vert</i>
                  </button>
                </template>

                <template slot="menu">
                  <button
                    @click.prevent="navigateToRenameFile(file)"
                    class="dropdownListItem"
                  >
                    Rename
                  </button>

                  <button
                    v-if="['rehearsal', 'live'].includes(event.eventState)"
                    @click.prevent="handleAddMediaToSource(file)"
                    :disabled="!isAllowAddSource"
                    class="dropdownListItem"
                  >
                    {{
                      isAllowAddSource
                        ? 'Add to sources'
                        : 'Max sources reached'
                    }}
                  </button>

                  <button
                    v-if="
                      ['rehearsal', 'live'].includes(event.eventState) &&
                      file.type != 'presentation'
                    "
                    @click.prevent="handleSetBackground(file)"
                    class="dropdownListItem"
                  >
                    Set as background
                  </button>

                  <button
                    v-if="
                      ['rehearsal', 'live'].includes(event.eventState) &&
                      file.type != 'presentation'
                    "
                    @click.prevent="handleSetPresenterFallback(file)"
                    class="dropdownListItem"
                  >
                    Set as fallback source
                  </button>

                  <button
                    @click.prevent="navigateToFileUpload(file)"
                    :disabled="file.isActive"
                    class="dropdownListItem"
                  >
                    Replace file
                  </button>

                  <button
                    @click.prevent="navigateToFileRemove(file)"
                    :disabled="file.isActive"
                    class="dropdownListItem"
                  >
                    Delete
                  </button>
                </template>
              </DropdownMenu>
            </div>
          </div>

          <!-- selected file preview -->
          <div
            class="flex flex-shrink-0 justify-center border-t border-grey-200 pt-3"
          >
            <div v-if="selectedFile != null" class="flex-1">
              <div class="flex py-3">
                <h3 class="flex w-full items-center text-xl font-bold">
                  Preview: {{ selectedFile.title }}
                </h3>
                <button
                  class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  @click.prevent="selectedFile = null"
                >
                  <i class="material-icons-outlined">close</i>
                </button>
              </div>

              <div class="flex justify-center">
                <img
                  class="max-h-60 max-w-full"
                  v-if="selectedFile.type == 'image'"
                  :src="selectedFile.url"
                />
                <video
                  class="max-h-60 w-full max-w-full"
                  v-else-if="selectedFile.type == 'video'"
                  controls
                  playsinline
                  muted
                >
                  <source :src="selectedFile.url" type="video/mp4" />
                </video>
                <div v-else class="flex flex-col items-center justify-center">
                  <i
                    class="material-icons-outlined rounded-full bg-grey-200 px-3 py-3"
                    >insert_drive_file</i
                  >
                  <span class="mt-3 text-sm text-grey-500"
                    >Preview not available</span
                  >
                </div>
              </div>
              <button
                class="btn-blue mt-10"
                v-if="['rehearsal', 'live'].includes(event.eventState)"
                :disabled="!isAllowAddSource"
                @click.prevent="handleAddMediaToSource(selectedFile)"
                id="btn_add_to_sources"
              >
                {{
                  isAllowAddSource ? 'Add to sources' : 'Max sources reached'
                }}
              </button>
            </div>

            <button
              v-if="!selectedFile"
              class="btn-blue"
              @click.prevent="navigateToFileUpload(null)"
              id="btn_upload_media"
            >
              Upload media
            </button>
          </div>
        </div>
      </template>
      <!-- end of main -->

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="flex py-6">
          <button
            class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3
            class="-ml-10 flex w-full items-center justify-center text-xl font-bold"
          >
            {{ upperFirst(section) }}
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- file upload -->
        <template v-if="section == 'file_upload'">
          <form class="flex flex-col px-3" @submit.prevent="handleFileUpload">
            <div v-if="!isFileUploading">
              <div v-if="replaceFile" class="pb-3">
                Replace media <strong>{{ replaceFile.title }}</strong> with a
                new version. This will update any scenes using the media and
                cannot be undone.
              </div>
              <a
                href="https://support.joinin.live/portal/en/kb/articles/file-upload"
                target="_blank"
                class="flex justify-end"
              >
                <p class="text-right text-sm text-blue-500">
                  Recommended file settings
                </p>
                <i
                  class="material-icons-outlined text pl-1 text-sm text-blue-500"
                  >help_outline</i
                >
              </a>

              <FileInput
                name="file"
                v-model="file"
                class="mb-6"
                :supports="getSupportedFileTypes()"
                :fileSizeLimit="5242880"
                :required="true"
                :errors="errors"
              />

              <TextField
                v-if="!replaceFile"
                name="file_name"
                :label="file ? 'Add file name' : 'Select file for upload'"
                @keypress-enter="handleFileUpload"
                v-model="filename"
                :required="true"
                :disabled="!file"
                :errors="errors"
                autofocus="true"
              />

              <button
                type="submit"
                class="btn-blue"
                :disabled="!isFileUploadAllow"
                id="btn_upload_file"
              >
                {{ replaceFile ? 'Replace file' : 'Upload file' }}
              </button>
            </div>
            <div class="px-12 text-center" v-else>
              <p>{{ filename }}</p>
              <p class="mt-6 text-xs">{{ progress }}</p>
              <div class="relative rounded bg-grey-200">
                <div
                  class="mb-4 flex h-2 overflow-hidden rounded bg-blue-500 text-xs"
                  :style="`width: ${progress}`"
                ></div>
              </div>
            </div>
          </form>
        </template>
        <!-- end of file upload -->

        <!-- file rename -->
        <template v-if="section == 'rename_file'">
          <form class="flex flex-col px-3" @submit.prevent="handleRenameFile">
            <TextField
              name="presenter_name"
              label="Rename file"
              v-model="name"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" class="btn-blue">Rename file</button>
          </form>
        </template>
        <!-- end of file rename -->

        <!-- file remove -->
        <template v-if="section == 'remove_file'">
          <div class="px-6 py-3">
            <div class="flex justify-center">
              <img
                class="max-h-80 max-w-full"
                v-if="deleteFile.type == 'image'"
                :src="deleteFile.url"
              />
              <video
                class="max-h-80 w-full max-w-full"
                v-if="deleteFile.type == 'video'"
                controls
                playsinline
                muted
              >
                <source :src="deleteFile.url" type="video/mp4" />
              </video>
            </div>
            <p class="mt-10 text-center">
              Deleting a file will remove it from any scenes and cannot be
              undone. Are you sure you want to delete this file?
            </p>
            <button class="btn-red mt-10" @click.prevent="handleRemoveFile">
              Delete
            </button>
          </div>
        </template>
        <!-- end of file remove -->
      </template>
      <!-- end of sub content -->
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownMenu from '@/components/DropdownMenu';
import FileInput from '@/components/FileInput';
import SelectFilter from '@/components/SelectFilter';
import TextField from '@/components/TextField';
import errors from '@/mixins/errors';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'StudioPanelFiles',
  mixins: [errors],
  components: {
    DropdownMenu,
    FileInput,
    SelectFilter,
    TextField,
  },
  data: () => ({
    search: null,
    selectedFile: null,
    removeFileDialog: false,
    replaceFile: null,
    deleteFile: null,
    isSearch: false,
    filter: 'all',
    filterOptions: [
      {
        id: 'all',
        label: 'All',
      },
      {
        id: 'image',
        label: 'Image',
      },
      {
        id: 'video',
        label: 'Video',
      },
      {
        id: 'presentation',
        label: 'Presentation',
      },
    ],
    section: null,
    file: null,
    filename: null,
    isFileUploading: false,
    progress: 0,
    prevDropdown: null,
    isFileUploadAllow: true,
    name: null,
  }),
  computed: {
    ...mapState([
      'coreGstMediaSynced',
      'files',
      'sources',
      'event',
      'apiOrigin',
      'isAllowAddSource',
      'playlists',
    ]),
    mediaSources() {
      return this.sources.filter((source) => source.type == 'Media');
    },
    getFiles() {
      let files = [];
      for (const p of this.playlists) {
        if (p.type != 'fixed') continue;
        files.push({
          fid: 'p' + p.id,
          id: p.id,
          title: p.name,
          filePath: null,
          imageUrl: null,
          url: null,
          type: 'presentation',
          mimeType: 'presentation',
          videoLengthMs: null,
          height: null,
          volume: 1,
          section: p.type,
          uploadState: p.uploadState,
          isActive: this.sources.some((s) => s.playlistId == p.id),
        });
      }
      for (const f of this.files) {
        if (f.section == 'fixed_playlist') continue;
        files.push({
          ...f,
          imageUrl: f.filePath,
          isActive: this.sources.some((s) => s.mediaId == f.fid),
        });
      }

      if (this.isSearch && this.search) {
        files = files.filter((file) => {
          return (
            file.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      if (!this.isSearch && this.filter !== 'all') {
        files = files.filter((file) => file.type == this.filter);
      }

      return files.sort((a, b) => a.title.localeCompare(b.title));
    },
  },
  watch: {
    file: function (val) {
      //auto insert filename
      if (val) {
        var filename_array = this.file.name.split('.');
        filename_array.pop();

        this.filename = filename_array.join(' ');
      } else {
        this.filename = null;
      }
    },
    isSearch: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
  },

  methods: {
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    handleSelectFile(file) {
      if (file.uploadState && file.uploadState != 'ready') return false;

      if (this.selectedFile == file) {
        this.selectedFile = null;
      } else {
        this.selectedFile = null;

        //make sure timeout is happening
        //to avoid render conflict
        setTimeout(() => {
          this.selectedFile = file;

          /*if(this.selectedFile.mimeType !== 'video/mp4' && this.selectedFile.mimeType !== 'video/quicktime') {
                        this.$store.commit('ERROR', `Video format may not be supported.`);
                    }*/
        }, 100);
      }
    },
    handleAddMediaToSource(file) {
      if (this.isAllowAddSource) {
        if (file.type == 'presentation') {
          this.$store.dispatch('ADD_SOURCE', {
            name: file.title,
            type: 'Playlist',
            hasPreview: true,
            playlistId: file.id,
          });
        } else {
          this.$store.dispatch('ADD_SOURCE', {
            name: file.title,
            type: 'Media',
            hasPreview: true,
            mediaId: file.fid,
          });
        }
        this.selectedFile = null;
      }
    },
    handleSetBackground(file) {
      this.$store.dispatch('ADD_SOURCE', {
        name: 'Background',
        type: 'Media',
        hasPreview: false,
        mediaId: file.fid,
        role: 'Background',
      });
    },
    handleSetPresenterFallback(file) {
      this.$store.dispatch('ADD_SOURCE', {
        name: 'PresenterFallback',
        type: 'Media',
        hasPreview: false,
        mediaId: file.fid,
        role: 'PresenterFallback',
      });
    },
    navigateToFileUpload(replaceFile) {
      this.replaceFile = replaceFile;
      this.section = 'file_upload';
    },
    navigateToFileRemove(file) {
      this.deleteFile = file;
      this.confirm = null;

      this.section = 'remove_file';
    },
    handleRemoveFile() {
      if (this.deleteFile.section && this.deleteFile.section == 'fixed') {
        this.$store.dispatch('REMOVE_PRESENTATION_FILE', {
          id: this.deleteFile.id,
        });
      } else {
        this.$store.dispatch('REMOVE_FILE', { id: this.deleteFile.fid });
      }

      this.section = null;
      this.confirm = null;
      this.deleteFile = null;
      this.selectedFile = null;
    },
    navigateToRenameFile(file) {
      this.selectedFile = file;
      this.name = file.title;

      this.section = 'rename_file';
    },
    handleRenameFile() {
      if (this.selectedFile.type == 'presentation') {
        this.$store.dispatch('RENAME_PLAYLIST', {
          id: this.selectedFile.id,
          name: this.name,
        });
      } else {
        this.$store.dispatch('RENAME_MEDIA', {
          type: 'Media',
          id: this.selectedFile.fid,
          name: this.name,
        });
      }

      this.section = null;
      this.selectedFile = null;
      this.name = null;
    },
    async handleFileUpload() {
      this.$store.commit('ADD_FILE_TO_UPLOAD', {
        file: this.file,
        filename: this.filename,
      });

      this.filename = null;
      this.file = null;

      this.section = null;

      /*const isPlaylist = this.file.name.match(/\.key/g) || [
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.ms-powerpoint',
                'application/pdf',
                'application/vnd.apple.keynote',
            ].includes(this.file.type);

            if(this.replaceFile) {
                let abort = false;
                if (this.replaceFile.type == 'presentation' && !isPlaylist) {
                    this.$store.commit('ERROR',
                        'Can only replace a playlist with another playlist');
                    abort = true;
                }
                if (this.replaceFile.type != 'presentation' && isPlaylist) {
                    this.$store.commit('ERROR',
                        'Cannot replace a media file with a playlist');
                    abort = true;
                }
                if (abort) {
                    this.filename = null;
                    this.file = null;
                    this.section = null;
                    this.replaceFile = null;
                    return;
                }
            }

            this.isFileUploading = true;

            try {
                // create ppt playlist when uploading powerpoint, ppt or keynote.
                // keynote doesn't return mimeType so match on extension
                if(isPlaylist) {
                    var formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('name', this.filename);

                    let url = `${this.apiOrigin}/mixer/${this.event.jobId}/createPPTPlaylist`;
                    if (this.replaceFile) {
                        url += '?replaceId=' + this.replaceFile.id;
                    }
                    let res = await axios({
                        url,
                        method: 'POST',
                        headers: { 'content-type': 'multipart/form-data' },
                        withCredentials: true,
                        data: formData,
                        onUploadProgress: (e) => {
                            this.progress = Math.floor(e.loaded / e.total * 100) + '%';
                        }
                    });

                    this.isFileUploading = false;

                    if(res && res.status >= 200 && res.status < 300) {
                        if (!this.replaceFile) {
                            this.$store.commit('ADD_PRESENTATION_FILE', {
                                id: res.data.playlistId,
                                name: this.filename,
                                subtype: 'fixed',
                                uploadState: 'new'
                            });
                        }
                        this.section = null;
                    } else {
                        this.$store.commit('ERROR', 'Failed to upload file.');
                    }

                } else {
                    let url = `${this.apiOrigin}/mixer/${this.event.jobId}/startUpload`;
                    if (this.replaceFile) {
                        url += `?replaceId=${this.replaceFile.fid}`;
                    }
                    let res = await axios({
                        url: url,
                        method: 'POST',
                        data: {
                            name: this.file.name,
                            displayName: this.filename,
                            mimeType: this.file.type,
                        },
                        withCredentials: true,
                    });
                    const { mediaId, uploadUrl } = res.data;
                    res = await axios({
                        url: uploadUrl,
                        method: 'PUT',
                        headers: { 'content-type': this.file.type },
                        data: this.file,
                        onUploadProgress: (e) => {
                            this.progress = Math.floor(e.loaded / e.total * 100) + '%';
                        }
                    });
                    res = await axios({
                        url: `${this.apiOrigin}/mixer/${this.event.jobId}/finishUpload`,
                        method: 'POST',
                        data: { mediaId },
                        withCredentials: true,
                    });

                    this.isFileUploading = false;

                    if(res && res.status >= 200 && res.status < 300) {
                        this.$store.commit('ADD_FILE', { newFile: res.data, replaceFile: this.replaceFile != null });
                        this.section = null;
                    } else {
                        this.$store.commit('ERROR', 'Failed to upload file.');
                    }
                }
            } catch(e) {
                if(e.response.data.errorCode == 'pg23505') {
                    this.$store.commit('ERROR', `File ${this.filename} already exists. Delete the original file and try again`);
                } else {
                    this.$store.commit('ERROR', e.response.data);
                }
                this.section = null;
            }

            // reset field
            this.isFileUploading = false;
            this.filename = null;
            this.file = null;
            this.replaceFile = null;*/
    },
    upperFirst(value) {
      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },
    getSupportedFileTypes() {
      const mediaTypes = ['png', 'jpg', 'jpeg', 'mp4', 'mov'];
      const presentationTypes = ['pptx', 'ppt', 'pdf', 'key'];

      if (this.replaceFile) {
        if (this.replaceFile.type == 'presentation') {
          return presentationTypes;
        } else {
          return mediaTypes;
        }
      } else {
        return mediaTypes.concat(presentationTypes);
      }
    },
  },
};
</script>
