var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-start"},[_c('div',{staticClass:"relative mb-8 flex items-center"},[(_vm.label)?_c('label',{staticClass:"float-label absolute text-grey-400",class:_vm.floatLabel ? 'float-label-active top-0' : 'cursor-text',attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"flex w-full flex-col pt-3"},[_c('div',{staticClass:"flex w-full flex-row flex-wrap"},_vm._l((_vm.list),function(item){return _c('div',{key:item.index,staticClass:"mx-1 my-1 flex flex-row items-center justify-between whitespace-nowrap rounded-full border border-grey-200 px-2",class:{ 'border-red-500 text-red-500': item.duplicated }},[_vm._v(" "+_vm._s(item.val)+" "),_c('i',{staticClass:"material-icons-outlined ml-1 cursor-pointer border-l border-grey-200 pl-1 text-sm hover:text-black",class:{ 'border-red-500 text-red-500': item.duplicated },on:{"click":function($event){return _vm.handleRemoveItem(item.index)}}},[_vm._v(" close ")])])}),0),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500",class:{
          'border-red-500 text-red-500 focus:border-red-500': _vm.isError,
        },attrs:{"id":_vm.name,"name":_vm.name,"autocomplete":"off","autofocus":_vm.autofocus,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.process.apply(null, arguments)},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500",class:{
          'border-red-500 text-red-500 focus:border-red-500': _vm.isError,
        },attrs:{"id":_vm.name,"name":_vm.name,"autocomplete":"off","autofocus":_vm.autofocus,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.process.apply(null, arguments)},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500",class:{
          'border-red-500 text-red-500 focus:border-red-500': _vm.isError,
        },attrs:{"id":_vm.name,"name":_vm.name,"autocomplete":"off","autofocus":_vm.autofocus,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.process.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}}),_c('i',{staticClass:"material-icons-outlined absolute bottom-2 right-6 cursor-pointer hover:text-black",on:{"click":function($event){$event.preventDefault();return _vm.process.apply(null, arguments)}}},[_vm._v("add")])]),(_vm.tooltip)?_c('TooltipModal',[_c('template',{slot:"control"},[_c('div',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])],2):_vm._e()],1),_c('div',[_c('ValidationErrors',{attrs:{"errors":_vm.errors,"name":_vm.name}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }