var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pb-8 text-grey-400"},[_vm._v(" Configure engagement options for your event. ")]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.event),expression:"event"}],staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[(_vm.event.eventState != 'complete')?_c('div',[_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Chat")]),(!_vm.can('event-chat'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):_c('div',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]},model:{value:(_vm.form.chatEnabled),callback:function ($$v) {_vm.$set(_vm.form, "chatEnabled", $$v)},expression:"form.chatEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit and read chat messages. ")])],2)],1),(_vm.form.chatEnabled)?_c('div',[_c('MdTextField',{attrs:{"name":"chatTabTitle","label":"Tab title","errors":_vm.errors,"tooltip":"Set the title of the tab housing the Chat function."},model:{value:(_vm.form.chatTabTitle),callback:function ($$v) {_vm.$set(_vm.form, "chatTabTitle", $$v)},expression:"form.chatTabTitle"}}),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"moderation_type","label":"Moderation type","errors":_vm.errors,"options":[
                  {
                    id: 'auto_approve_new_messages',
                    label: 'Automatically show new messages',
                  },
                  {
                    id: 'manual_approve_new_messages',
                    label: 'Manually show new messages',
                  },
                ]},model:{value:(_vm.form.chatModerationType),callback:function ($$v) {_vm.$set(_vm.form, "chatModerationType", $$v)},expression:"form.chatModerationType"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Automatically:")]),_vm._v(" chat messages will be approved immediately once submitted. Chat messages can be manually removed. ")]),_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Manually:")]),_vm._v(" chat messages must be approved by an operator. ")])])])],2)],1),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"image_submission","label":"Allow image submissions","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.chatImageSubmissionEnabled),callback:function ($$v) {_vm.$set(_vm.form, "chatImageSubmissionEnabled", $$v)},expression:"form.chatImageSubmissionEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit images from this tab. ")])],2)],1),_c('MdTextField',{attrs:{"name":"chatTabTitle","label":"Call to action message","errors":_vm.errors,"tooltip":"Set the Call To Action text that will appear in the submission box of this tab."},model:{value:(_vm.form.chatCallToAction),callback:function ($$v) {_vm.$set(_vm.form, "chatCallToAction", $$v)},expression:"form.chatCallToAction"}})],1):_vm._e()]),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Q&A")]),(!_vm.can('event-qa'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):_c('div',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]},model:{value:(_vm.form.qaEnabled),callback:function ($$v) {_vm.$set(_vm.form, "qaEnabled", $$v)},expression:"form.qaEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit questions. ")])],2)],1),(_vm.form.qaEnabled)?_c('div',[_c('MdTextField',{attrs:{"name":"qaTabTitle","label":"Tab title","errors":_vm.errors,"tooltip":"Set the title of the tab housing the Q&A function."},model:{value:(_vm.form.qaTabTitle),callback:function ($$v) {_vm.$set(_vm.form, "qaTabTitle", $$v)},expression:"form.qaTabTitle"}}),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"moderation_type","label":"Moderation type","errors":_vm.errors,"options":[
                  {
                    id: 'auto_approve_new_messages',
                    label: 'Automatically approve new messages',
                  },
                  {
                    id: 'manual_approve_new_messages',
                    label: 'Manually approve new messages',
                  },
                ]},model:{value:(_vm.form.qaModerationType),callback:function ($$v) {_vm.$set(_vm.form, "qaModerationType", $$v)},expression:"form.qaModerationType"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Automatically:")]),_vm._v(" questions will be approved immediately once submitted. Questions can be manually removed. ")]),_c('div',[_c('span',{staticClass:"font-bold"},[_vm._v("Manually:")]),_vm._v(" questions must be approved by an operator. ")])])])],2)],1),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"attendee_approved_visible","label":"Show approved","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.qaAttendeeApprovedVisible),callback:function ($$v) {_vm.$set(_vm.form, "qaAttendeeApprovedVisible", $$v)},expression:"form.qaAttendeeApprovedVisible"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to see questions after they have been approved. If set to 'No' your Q&A will be entirely private. ")])],2)],1),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"allow_anonymous_submissions","label":"Allow anonymous","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.qaAnonymousSubmissions),callback:function ($$v) {_vm.$set(_vm.form, "qaAnonymousSubmissions", $$v)},expression:"form.qaAnonymousSubmissions"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit anonymous questions. Identifying information is removed from anonymous questions. ")])],2)],1),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"allow_for_presenter_submissions","label":"Allow questions directed at presenters","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.qaForPresenterSubmissions),callback:function ($$v) {_vm.$set(_vm.form, "qaForPresenterSubmissions", $$v)},expression:"form.qaForPresenterSubmissions"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to submit questions directed at a specific Presenter. Attendees will be able to see and select from all presenters in the event. ")])],2)],1),(_vm.form.qaAttendeeApprovedVisible)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"allow_attendee_upvoting","label":"Allow attendee voting","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.qaAttendeeUpvoting),callback:function ($$v) {_vm.$set(_vm.form, "qaAttendeeUpvoting", $$v)},expression:"form.qaAttendeeUpvoting"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to upvote questions so presenters can see the most popular questions. ")])],2)],1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Polling")]),(!_vm.can('event-poll'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):_c('div',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]},model:{value:(_vm.form.pollEnabled),callback:function ($$v) {_vm.$set(_vm.form, "pollEnabled", $$v)},expression:"form.pollEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to vote on polls. ")])],2)],1),(_vm.form.pollEnabled)?_c('div',[_c('MdTextField',{attrs:{"name":"pollTabTitle","label":"Tab title","errors":_vm.errors,"tooltip":"Set the title of the tab housing the Polling function."},model:{value:(_vm.form.pollTabTitle),callback:function ($$v) {_vm.$set(_vm.form, "pollTabTitle", $$v)},expression:"form.pollTabTitle"}}),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"results_visible","label":"Show results after vote","errors":_vm.errors,"options":[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]},model:{value:(_vm.form.pollResultsVisible),callback:function ($$v) {_vm.$set(_vm.form, "pollResultsVisible", $$v)},expression:"form.pollResultsVisible"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to see the results of polls after submitting their answer. To keep poll results confidential set to 'No'. ")])],2)],1)],1):_vm._e()]),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Quiz")]),(!_vm.can('event-quiz'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):_c('div',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]},model:{value:(_vm.form.quizEnabled),callback:function ($$v) {_vm.$set(_vm.form, "quizEnabled", $$v)},expression:"form.quizEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to participate in a quiz. ")])],2)],1),(_vm.form.quizEnabled)?_c('div',[_c('MdTextField',{attrs:{"name":"quizTabTitle","label":"Tab title","errors":_vm.errors,"tooltip":"Set the title of the tab housing the Quiz function."},model:{value:(_vm.form.quizTabTitle),callback:function ($$v) {_vm.$set(_vm.form, "quizTabTitle", $$v)},expression:"form.quizTabTitle"}})],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v("Survey")]),(!_vm.can('event-survey'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):_c('div',[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{attrs:{"name":"enabled","label":"Enabled","errors":_vm.errors,"options":[
              {
                id: true,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ]},model:{value:(_vm.form.surveyEnabled),callback:function ($$v) {_vm.$set(_vm.form, "surveyEnabled", $$v)},expression:"form.surveyEnabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Allow attendees to participate in a survey. ")])],2)],1),(_vm.form.surveyEnabled)?_c('div',[_c('MdTextField',{attrs:{"name":"quizTabTitle","label":"Tab title","errors":_vm.errors,"tooltip":"Set the title of the tab housing the Survey function."},model:{value:(_vm.form.surveyTabTitle),callback:function ($$v) {_vm.$set(_vm.form, "surveyTabTitle", $$v)},expression:"form.surveyTabTitle"}})],1):_vm._e()]),_c('SubmitButton',{attrs:{"saving":_vm.saving}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }