<template>
  <div
    class="ml-2 flex w-64 justify-between border border-grey-200 bg-white py-4 shadow"
  >
    <div class="flex flex-1 flex-col px-4">
      <div class="mb-2 flex items-center justify-between text-sm">
        <div class="flex flex-1 items-center">
          <i class="material-icons-outlined mr-1">upload_file</i>
          <div class="w-32 truncate">
            {{ file.name }}
          </div>
        </div>
        <div class="text-xs text-black">{{ progress }}</div>
      </div>
      <div class="h-4">
        <div
          v-if="!isFileUploadCompleted"
          class="relative h-2 rounded bg-grey-200"
        >
          <div
            class="mb-4 flex h-full overflow-hidden rounded bg-blue-500 text-xs"
            :style="`width: ${progress}`"
          ></div>
        </div>
        <div v-else class="flex items-center text-xs text-grey-400">
          <i class="material-icons-outlined mr-2 text-icon-sm text-green-500"
            >check</i
          >
          Upload complete
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center pr-2">
      <button @click="handleCancelFileUpload">
        <i class="material-icons-outlined hover:text-grey-800">close</i>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'FileUploadModal',

  props: {
    file: {
      required: true,
    },
    filename: {
      required: true,
    },
    index: {
      required: true,
    },
  },

  data: () => ({
    isFileUploading: false,
    isFileUploadCompleted: false,
    replaceFile: null,
    progress: 0,
    fileUploadId: null,
  }),

  computed: {
    ...mapState([
      'coreGstMediaSynced',
      'files',
      'sources',
      'event',
      'apiOrigin',
      'isAllowAddSource',
      'playlists',
    ]),
  },

  async mounted() {
    await this.handleFileUpload();
  },

  methods: {
    async handleFileUpload() {
      const isPlaylist =
        this.file.name.match(/\.key/g) ||
        [
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.ms-powerpoint',
          'application/pdf',
          'application/vnd.apple.keynote',
        ].includes(this.file.type);

      if (this.replaceFile) {
        let abort = false;
        if (this.replaceFile.type == 'presentation' && !isPlaylist) {
          this.$store.commit(
            'ERROR',
            'Can only replace a playlist with another playlist'
          );
          abort = true;
        }
        if (this.replaceFile.type != 'presentation' && isPlaylist) {
          this.$store.commit(
            'ERROR',
            'Cannot replace a media file with a playlist'
          );
          abort = true;
        }
        if (abort) {
          this.filename = null;
          this.file = null;
          this.section = null;
          this.replaceFile = null;
          return;
        }
      }

      this.isFileUploading = true;

      try {
        // create ppt playlist when uploading powerpoint, ppt or keynote.
        // keynote doesn't return mimeType so match on extension
        if (isPlaylist) {
          var formData = new FormData();
          formData.append('file', this.file);
          formData.append('name', this.filename);

          let url = `${this.apiOrigin}/mixer/${this.event.jobId}/createPPTPlaylist`;
          if (this.replaceFile) {
            url += '?replaceId=' + this.replaceFile.id;
          }
          let res = await axios({
            url,
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data' },
            withCredentials: true,
            data: formData,
            onUploadProgress: (e) => {
              this.progress = Math.floor((e.loaded / e.total) * 100) + '%';
            },
          });

          this.isFileUploading = false;

          if (res && res.status >= 200 && res.status < 300) {
            if (!this.replaceFile) {
              this.$store.commit('ADD_PRESENTATION_FILE', {
                id: res.data.playlistId,
                name: this.filename,
                subtype: 'fixed',
                uploadState: 'new',
              });
            }
            this.section = null;
          } else {
            this.$store.commit('ERROR', 'Failed to upload file.');
          }
        } else {
          let url = `${this.apiOrigin}/mixer/${this.event.jobId}/startUpload`;
          if (this.replaceFile) {
            url += `?replaceId=${this.replaceFile.fid}`;
          }
          let res = await axios({
            url: url,
            method: 'POST',
            data: {
              name: this.file.name,
              displayName: this.filename,
              mimeType: this.file.type,
            },
            withCredentials: true,
          });

          const { mediaId, uploadUrl } = res.data;
          this.fileUploadId = mediaId;

          res = await axios({
            url: uploadUrl,
            method: 'PUT',
            headers: { 'content-type': this.file.type },
            data: this.file,
            onUploadProgress: (e) => {
              this.progress = Math.floor((e.loaded / e.total) * 100) + '%';
            },
          });
          res = await axios({
            url: `${this.apiOrigin}/mixer/${this.event.jobId}/finishUpload`,
            method: 'POST',
            data: { mediaId },
            withCredentials: true,
          });

          this.isFileUploading = false;

          if (res && res.status >= 200 && res.status < 300) {
            this.$store.commit('ADD_FILE', {
              newFile: res.data,
              replaceFile: this.replaceFile != null,
            });
            this.isFileUploadCompleted = true;
          } else {
            this.$store.commit('ERROR', 'Failed to upload file.');
          }

          setTimeout(() => {
            this.$store.commit('REMOVE_FILE_FROM_UPLOAD', this.index);
          }, 2000);
        }
      } catch (e) {
        if (e.response.data.errorCode == 'pg23505') {
          this.$store.commit(
            'ERROR',
            `File ${this.filename} already exists. Delete the original file and try again`
          );
        } else {
          this.$store.commit('ERROR', e.response.data);
        }

        this.$store.commit('REMOVE_FILE_FROM_UPLOAD', this.index);
      }

      //clean up
    },

    handleCancelFileUpload() {
      this.$store.commit('REMOVE_FILE_FROM_UPLOAD', this.index);
      this.$store.dispatch('REMOVE_FILE', { id: this.fileUploadId });
    },
  },
};
</script>
