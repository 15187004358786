<template>
  <div class="mb-6">
    <div class="relative flex flex-row items-center">
      <input
        :type="type"
        class="input"
        :class="{ 'is-invalid rounded-b-none': errors.has(name) }"
        :id="name"
        :name="name"
        autocomplete="off"
        autocapitalize="off"
        autocorrect="off"
        :placeholder="label"
        :value="value"
        :autofocus="autofocus"
        :required="required"
        @input="handleInput"
      />

      <span
        class="absolute right-3 cursor-pointer text-blue-500 hover:text-blue-400"
        @click="togglePassword"
        >{{ type == 'password' ? 'Show' : 'Hide' }}</span
      >
    </div>

    <ValidationErrors :errors="errors" :name="name" />
  </div>
</template>

<script>
import ValidationErrors from '@/components/ValidationErrors';

export default {
  name: 'PasswordField',

  components: {
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    label: { type: String },
    value: {},
    autofocus: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
  },

  data() {
    return {
      type: 'password',
    };
  },

  methods: {
    togglePassword() {
      if (this.type == 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    handleInput(e) {
      this.$emit('input', e.currentTarget.value);
    },
  },
};
</script>
