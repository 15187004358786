<template>
  <form @submit.prevent="save()" class="mt-4">
    <MdTextField
      name="first_name"
      label="First name"
      v-model="form.firstName"
      :disabled="isNotEditable"
      autofocus
      :errors="errors"
    />

    <MdTextField
      name="last_name"
      label="Last name"
      v-model="form.lastName"
      :disabled="isNotEditable"
      autofocus
      :errors="errors"
    />

    <MdTextField
      name="email"
      label="Email address"
      v-model="form.email"
      :disabled="isNotEditable"
      :errors="errors"
    />

    <div class="flex items-center space-x-4">
      <div class="flex-1">
        <MdSelectField
          name="userType"
          label="Role"
          v-model="form.userType"
          :disabled="isNotDowngradable"
          :errors="errors"
          :options="userTypeOptions"
        />
      </div>

      <div class="mb-6">
        <TooltipModal>
          <template slot="control">
            <button class="flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              <div>
                <span class="font-bold">Administrator:</span> full access to
                your account including billing, users and all features.
              </div>
              <div>
                <span class="font-bold">Manager:</span> full access to all
                events including the Studio. Cannot access billing or users.
              </div>
              <div>
                <span class="font-bold">Assistant:</span> partial access to all
                events. Can access Assistant view, analytics and registration.
              </div>
            </div>
          </template>
        </TooltipModal>
      </div>
    </div>

    <div class="flex-1 text-grey-400">
      The new user will immediately be sent login instructions via email.
    </div>

    <SubmitButton :saving="saving" :disabled="!saveable" :label="actionLabel" />
  </form>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';
import MdSelectField from '@/components/MdSelectField';
import MdTextField from '@/components/MdTextField';

import { extend, isEmpty, omit } from 'lodash';

// TODO: Better handling of super admin users

export default {
  mixins: [errors],

  components: {
    TooltipModal,
    SubmitButton,
    MdSelectField,
    MdTextField,
  },

  data() {
    return {
      loading: false,
      saving: false,
      form: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        userType: 'admin',
      },
      isSuperAdmin: false,
    };
  },

  mounted() {
    // TODO: Put this somewhere else?
    this.form.id = this.$route.params.userId;
    if (!this.isNew) {
      this.load();
    }
  },

  computed: {
    saveable() {
      // TODO: correct validation. All fields required
      return (
        !isEmpty(this.form.email) &&
        !isEmpty(this.form.firstName) &&
        !isEmpty(this.form.lastName)
      );
    },

    isNew() {
      return this.form.id == 'new';
    },

    title() {
      return this.isNew ? 'New user' : 'Update user';
    },

    actionLabel() {
      return this.isNew ? 'Add new user' : 'Update user';
    },

    userTypeOptions() {
      const res = [
        { id: 'admin', label: 'Administrator' },
        { id: 'manager', label: 'Manager' },
        { id: 'assistant', label: 'Assistant' },
      ];
      if (this.isSuperAdmin) {
        res.unshift({ id: 'super', label: 'Super Admin' });
      }
      return res;
    },

    // TODO: Allow editing self?
    isNotEditable() {
      return this.isSuperAdmin;
    },

    // TODO: Also do not allow downgrading self (already done in backend)
    isNotDowngradable() {
      return this.isSuperAdmin;
    },
  },

  methods: {
    async load() {
      // TODO: Navigate back and/or show an error if the load fails?
      const url = `/account/users/${this.form.id}`;
      const { data } = await api.get(url);
      extend(this.form, data);
      this.isSuperAdmin = data.userType == 'super';
    },

    async save() {
      this.saving = true;
      const data = omit(this.form, 'id');

      if (this.isNew) {
        this.create(data);
        return;
      }

      this.update(data);
    },

    create(data) {
      api
        .post('/account/users', data)
        .then(() => {
          this.saving = false;
          this.notify('success', 'User created');
          this.$router.push(`/settings/users`);
        })
        .catch((error) => {
          this.saving = false;
          this.handleErrorStatus(error);
        });
    },

    update(data) {
      const { id } = this.form;

      api
        .put(`/account/users/${id}`, data)
        .then(() => {
          this.saving = false;
          this.notify('success', 'User updated');
          this.$router.push(`/settings/users`);
        })
        .catch((error) => {
          this.saving = false;
          this.handleErrorStatus(error);
        });
    },
  },
};
</script>
