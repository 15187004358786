<template>
  <div class="h-full w-full">
    <LoadingIcon v-if="isLoading" />
    <template v-else>
      <div class="flex flex-col items-center justify-between pb-8 md:flex-row">
        <div class="flex w-full flex-col items-center md:flex-row md:space-x-4">
          <div
            v-if="search"
            class="relative mb-4 flex w-full flex-1 items-center md:mb-auto md:w-auto"
          >
            <i class="material-icons-outlined mr-4">search</i>
            <input
              type="text"
              class="w-full rounded-full border border-grey-200 px-4 py-2 focus:ring-1 focus:ring-blue-500"
              v-model="s"
              placeholder="Search..."
            />
            <i
              v-if="s != ''"
              class="material-icons-outlined absolute right-6 cursor-pointer"
              @click="s = ''"
              >close</i
            >
          </div>

          <div class="flex w-full flex-1 items-center justify-start">
            <DropdownMenu v-if="enableFilter" ref="filterDropdownMenu">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.prevent="slotProps.toggle()"
                  class="dropdownListItemWithIcon text-grey-800"
                >
                  <i class="material-icons-outlined mr-2">sort</i>
                  {{ selectedOption && selectedOption.label }}
                </button>
              </template>

              <template slot="menu">
                <button
                  v-for="option in options"
                  :key="option.id"
                  class="dropdownListItem"
                  @click="selectOption(option)"
                >
                  {{ option.label }}
                </button>
              </template>
            </DropdownMenu>

            <div
              v-if="enableFilter"
              class="mx-2 h-4 border-l border-grey-400"
            ></div>

            <TooltipModal
              v-if="sortId"
              triggerType="hover"
              :width="48"
              xAlign="left"
            >
              <template slot="control">
                <button
                  @click.prevent="sortByTime"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i
                    class="material-icons-outlined"
                    :class="{ 'text-blue-500': sortMethod == 'desc' }"
                    >sort</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Sort by descending order
                </div>
              </template>
            </TooltipModal>

            <TooltipModal
              v-if="sortName"
              triggerType="hover"
              :width="48"
              xAlign="left"
            >
              <template slot="control">
                <button
                  @click.prevent="sortByAlpha"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i
                    class="material-icons-outlined"
                    :class="{ 'text-blue-500': sortMethod == 'alpha' }"
                    >sort_by_alpha</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Sort by alphabetical ascending order
                </div>
              </template>
            </TooltipModal>

            <template v-if="can('create-event')">
              <button
                v-if="allowNew"
                @click="handleAddNewItem"
                class="group ml-auto flex items-center justify-center space-x-1 rounded-full border border-grey-200 px-4 py-2 hover:border-blue-500"
              >
                <i class="material-icons-outlined group-hover:text-blue-500"
                  >add</i
                >
                <span class="text-sm text-grey-800 group-hover:text-blue-500"
                  >New</span
                >
              </button>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="items.length == 0"
        class="flex items-center justify-center py-8 text-grey-400"
      >
        Nothing to display.
      </div>
      <div class="flex flex-col items-start">
        <div
          v-for="(item, index) in getItems"
          :key="index"
          class="flex w-full flex-col border-b border-grey-100 px-1 py-2 hover:bg-grey-100"
          :class="{ 'bg-grey-50': item.enabled == false }"
        >
          <slot v-if="hasMainSlot" name="main" :item="item"></slot>
          <div v-else class="flex-start flex justify-between">
            <slot name="itemPrefix" :item="item"></slot>
            <div class="flex flex-1 flex-col items-start">
              <div class="flex w-full items-center justify-between">
                <RouterLink
                  v-if="item.url"
                  :to="item.url"
                  class="cursor-pointer text-blue-500 hover:text-blue-800"
                  :class="{ 'opacity-50': item.enabled == false }"
                >
                  {{ item.name }}
                </RouterLink>
                <div
                  v-else
                  class="text-lg font-semibold text-grey-1000"
                  :class="{ 'opacity-50': item.enabled == false }"
                >
                  {{ item.name }}
                </div>
                <DropdownMenu>
                  <template slot="control" slot-scope="slotProps">
                    <button
                      @click.prevent="slotProps.toggle()"
                      class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                    >
                      <i class="material-icons-outlined">more_vert</i>
                    </button>
                  </template>

                  <template slot="menu">
                    <slot name="item" :item="item"> </slot>
                  </template>
                </DropdownMenu>
              </div>
              <slot name="tags" :item="item"></slot>
              <div
                v-if="item.description"
                class="mr-12 py-2 text-sm text-grey-400"
              >
                {{ item.description }}
              </div>
              <slot name="body" :item="item"></slot>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingIcon from '@/components/ui/LoadingIcon';
import DropdownMenu from '@/components/DropdownMenu';
import TooltipModal from '@/components/TooltipModal';

export default {
  name: 'ListView',

  components: {
    LoadingIcon,
    DropdownMenu,
    TooltipModal,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    sortName: {
      type: Boolean,
      default: false,
    },
    sortId: {
      type: Boolean,
      default: false,
    },
    filterKey: {
      type: String,
    },
    filterOptions: {
      type: Array,
    },
    allowNew: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    getItems() {
      return this.items
        .filter((item) => {
          if (this.s) {
            if (item.name) {
              return item.name.toLowerCase().match(this.s.toLowerCase());
            } else if (item.title) {
              return item.title.toLowerCase().match(this.s.toLowerCase());
            }
          } else {
            return item;
          }
        })
        .filter((item) => {
          if (this.enableFilter) {
            if (this.selectedOption.id == 'all') {
              return true;
            } else {
              return this.selectedOption.id == item[this.filterKey || 'type'];
            }
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          if (!this.sortMethod) return true;
          else if (this.sortMethod == 'desc') {
            if (a.key && b.key) {
              return a.key < b.key ? 1 : -1;
            } else {
              return +a.id < +b.id ? 1 : -1;
            }
          } else if (this.sortMethod == 'alpha') {
            if (a.name) {
              return a.name.localeCompare(b.name);
            } else if (a.title) {
              return a.title.localeCompare(b.title);
            }
          }
        });
    },
    enableFilter() {
      return this.filterOptions && this.filterOptions.length > 0;
    },
    hasMainSlot() {
      return !!this.$scopedSlots.main;
    },
  },

  data: () => ({
    s: '',
    sortMethod: null,
    selectedOption: null,
    options: [{ id: 'all', label: 'All' }],
  }),

  beforeMount() {
    if (this.filterOptions && this.filterOptions.length > 0) {
      this.options = [...this.options, ...this.filterOptions];
      this.selectedOption = this.options[0];
    }
  },

  methods: {
    handleAddNewItem() {
      this.$emit('onNewItem');
    },
    sortByTime() {
      if (this.sortMethod == 'desc') {
        this.sortMethod = null;
      } else {
        this.sortMethod = 'desc';
      }
    },
    sortByAlpha() {
      if (this.sortMethod == 'alpha') {
        this.sortMethod = null;
      } else {
        this.sortMethod = 'alpha';
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.$refs.filterDropdownMenu.close();
    },
  },
};
</script>
