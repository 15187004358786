<template>
  <div class="relative">
    <slot
      name="control"
      :visible="visible"
      :toggle="toggle"
      :close="close"
    ></slot>

    <Animation>
      <div
        class="absolute z-40 block flex w-full"
        :class="alignDropdown"
        :style="myStyle"
      >
        <div
          v-if="visible"
          class="mt-2 rounded border border-grey-100 bg-white p-2 shadow-md"
          :style="{
            top: '100%',
            float: 'left',
            backgroundClip: 'padding-box',
          }"
        >
          <div class="space-y-1" :class="[widthClass]">
            <slot name="menu" :close="close"> </slot>
          </div>
        </div>
      </div>
    </Animation>
  </div>
</template>

<script>
import Animation from '@/components/Animation';
import { mapState } from 'vuex';

export default {
  components: {
    Animation,
  },

  props: {
    align: {
      default: 'right',
    },
    top: {
      default: '100%',
    },
    bottom: {
      default: 'auto',
    },
    left: {
      default: 'auto',
    },
    right: {
      default: 'auto',
    },
    width: {
      default: 60,
    },
  },

  data() {
    return {
      visible: false,
      listener: null,
    };
  },

  computed: {
    ...mapState(['dropdownOpen']),
    alignDropdown() {
      switch (this.align) {
        case 'left':
          return 'justify-start';
        case 'center':
          return 'justify-center';
        case 'right':
        default:
          return 'justify-end';
      }
    },
    myStyle() {
      return {
        top: this.top,
        bottom: this.bottom,
        left: this.left,
        right: this.right,
      };
    },
    widthClass() {
      return 'w-' + this.width;
    },
  },

  watch: {
    visible(visible) {
      this.$emit('visibility-changed', visible);

      if (visible) {
        this.addEventListeners();
        return;
      }

      this.removeEventListeners();
    },
    dropdownOpen: function (val) {
      if (val == false) {
        this.close();
      }
    },
  },

  mounted() {
    this.listener = (e) => {
      if (e.target !== this.$el && !this.$el.contains(e.target)) {
        this.visible = false;
      }
      //why this ?
      //which can cause open and close when slot template is <a>
      if (e.target.tagName.toLowerCase() === 'a') {
        this.visible = false;
      }
    };
  },

  beforeDestroy() {
    this.removeEventListeners();
  },

  methods: {
    addEventListeners() {
      window.addEventListener('click', this.listener);
      window.addEventListener('keydown', this.handleEscapeKey);
    },

    removeEventListeners() {
      window.removeEventListener('click', this.listener);
      window.removeEventListener('keydown', this.handleEscapeKey);
    },

    open() {
      this.visible = true;
      this.$store.commit('dropdownOpen', this.visible);
    },

    close() {
      this.visible = false;
    },

    toggle() {
      this.visible = !this.visible;
      this.$store.commit('dropdownOpen', this.visible);
    },

    handleEscapeKey(event) {
      if (event.keyCode == 27) {
        this.close();
      }
    },
  },
};
</script>
