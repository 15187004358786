<template>
  <div class="flex flex-grow flex-col">
    <div v-if="!can('studio-source-html')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="flex h-full flex-col">
          <!-- list -->
          <div class="flex flex-grow flex-col overflow-auto pt-6">
            <div
              v-if="getHtmlSources.length == 0"
              class="flex flex-1 justify-center text-grey-400"
            >
              Add webpage sources using the button below
            </div>

            <div
              v-for="(record, index) in getHtmlSources"
              :key="index"
              @click.self="selectHtml(record)"
              @dblclick.prevent="addHtmlToSource(record)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              :class="{ 'bg-grey-100': selectedHtml == record }"
            >
              <div class="flex flex-1 items-center space-x-2">
                <div class="font-medium">{{ record.name }}</div>
              </div>

              <div
                v-if="record.isActive == true"
                class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
              >
                Active
              </div>

              <DropdownMenu>
                <template slot="control" slot-scope="slotProps">
                  <button
                    @click.stop="handleDropdown(slotProps)"
                    class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">more_vert</i>
                  </button>
                </template>

                <template slot="menu">
                  <button
                    v-if="['rehearsal', 'live'].includes(event.eventState)"
                    :disabled="!isAllowAddSource"
                    @click.prevent="addHtmlToSource(record)"
                    class="dropdownListItem"
                  >
                    {{
                      isAllowAddSource
                        ? 'Add to sources'
                        : 'Max sources reached'
                    }}
                  </button>
                  <button
                    @click="navigateToRenameHtml(record)"
                    class="dropdownListItem"
                  >
                    Rename
                  </button>

                  <button
                    @click="navigateToRemoveHtml(record)"
                    :disabled="record.isActive"
                    class="dropdownListItem"
                  >
                    Delete
                  </button>
                </template>
              </DropdownMenu>
            </div>
          </div>

          <!-- selected preview -->
          <div
            class="flex flex-col justify-center border-t border-grey-200 pt-3"
          >
            <div v-if="selectedHtml != null" class="flex-1">
              <div class="flex py-3">
                <h3 class="flex w-full items-center text-xl font-bold">
                  {{ selectedHtml.name }}
                </h3>
                <button
                  class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  @click.prevent="selectedHtml = null"
                >
                  <i class="material-icons-outlined">close</i>
                </button>
              </div>

              <div class="flex flex-col justify-center">
                <div>Webpage details</div>
                <div class="break-all text-grey-400">
                  URL: {{ selectedHtml.uri }}
                </div>
              </div>
              <button
                class="btn-blue mt-10"
                v-if="['rehearsal', 'live'].includes(event.eventState)"
                :disabled="!isAllowAddSource"
                @click.prevent="addHtmlToSource(selectedHtml)"
              >
                {{
                  isAllowAddSource ? 'Add to sources' : 'Max sources reached'
                }}
              </button>
            </div>

            <button v-else class="btn-blue" @click.prevent="navigateToAddHtml">
              Add webpage
            </button>
          </div>
        </div>
      </template>

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="py-6">
          <button
            class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3 class="-ml-10 w-full text-center text-2xl font-bold">
            {{ upperFirst(section) }}
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add html -->
        <template v-if="section == 'add_webpage'">
          <form class="flex flex-col px-3" @submit.prevent="addHtml">
            <TextField
              name="new_html_name"
              label="Website name"
              v-model="newHtmlName"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <TextField
              name="new_html_uri"
              label="Website URL"
              v-model="newHtmlUri"
              :required="true"
              :errors="errors"
            />

            <button type="submit" class="btn-blue" id="btn_add_webpage">
              Add webpage
            </button>
          </form>
        </template>
        <!-- end of add html -->

        <!-- delete html -->
        <template v-if="section == 'delete_webpage'">
          <div class="px-6">
            <h4 class="text-center text-lg font-bold">
              {{ selectedHtml.name }}
            </h4>
            <div class="mt-10 text-center">
              Are you sure you want to delete this webpage?
            </div>

            <button class="btn-red mt-10" @click.prevent="removeHtml">
              Delete
            </button>
          </div>
        </template>
        <!-- end of delete html -->

        <!-- rename html -->
        <template v-if="section == 'rename_webpage'">
          <form
            class="flex flex-col px-3"
            @submit.prevent="handleRenameWebpage"
          >
            <TextField
              name="webpage_name"
              label="Name"
              v-model="name"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" class="btn-blue">Rename webpage</button>
          </form>
        </template>
        <!-- end of rename html -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import DropdownMenu from '@/components/DropdownMenu';
import TextField from '@/components/TextField';
import errors from '@/mixins/errors';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  name: 'StudioPanelHtmls',
  mixins: [errors],
  components: {
    DropdownMenu,
    TextField,
    UpgradePlanLink,
  },
  data: () => ({
    selectedHtml: null,
    section: null,
    newHtmlName: '',
    newHtmlUri: '',
    prevDropdown: null,
  }),
  computed: {
    ...mapState(['htmlSources', 'sources', 'event', 'isAllowAddSource']),
    ...mapGetters(['upperFirst']),
    getHtmlSources() {
      return this.htmlSources
        .map((html) => {
          html.isActive = false;

          var source = this.sources.find((h) => h.htmlId == html.id);
          if (source) {
            html.isActive = true;
          }

          return html;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  },
  watch: {
    newHtmlUri: function (newVal, oldVal) {
      if (oldVal == null || oldVal == '') {
        if (newVal.match(/^http/g) == null) {
          this.newHtmlUri = 'http://' + newVal;
        }
      }
    },
  },
  methods: {
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    navigateToAddHtml() {
      this.section = 'add_webpage';
    },
    navigateToRemoveHtml(webpage) {
      this.selectedHtml = webpage;
      this.section = 'delete_webpage';
    },
    navigateToRenameHtml(webpage) {
      this.selectedHtml = webpage;
      this.name = webpage.name;

      this.section = 'rename_webpage';
    },
    handleRenameWebpage() {
      this.$store.dispatch('RENAME_MEDIA', {
        type: 'HTML',
        id: this.selectedHtml.id,
        name: this.name,
      });

      this.section = null;
      this.selectedHtml = null;
      this.name = null;
    },
    addHtml() {
      this.$store.dispatch('ADD_HTML_SOURCE', {
        name: this.newHtmlName,
        uri: this.newHtmlUri,
      });

      this.newHtmlName = '';
      this.newHtmlUri = '';
      this.section = null;
    },
    removeHtml() {
      this.$store.dispatch('REMOVE_HTML_SOURCE', {
        id: this.selectedHtml.id,
      });

      this.selectedHtml = null;
      this.section = null;
    },
    selectHtml(webpage) {
      this.$nextTick(() => {
        this.selectedHtml = webpage;
      });
    },
    addHtmlToSource(html) {
      if (this.isAllowAddSource) {
        this.$store.dispatch('ADD_SOURCE', {
          name: html.name,
          type: 'HTML',
          hasPreview: true,
          htmlId: html.id,
        });
        this.selectedHtml = null;
      }
    },
  },
};
</script>
