<template>
  <div class="flex flex-col rounded-lg bg-white px-4 py-2">
    <div :key="'audio container'">
      <!-- place audio player outside of template to avoid re-render, audio needs to be exist all the time -->
      <AudioPlayer
        v-for="member in commsMembers"
        :key="member.id"
        :publisherId="member.id"
        :janus="janus"
        :isUnmuted="member.isUnmuted"
      />
    </div>

    <div
      class="mb-1 flex items-center justify-between border-b border-grey-100 pb-1"
    >
      <div class="py-2 text-sm text-grey-400">Comms/talkback</div>

      <div
        v-if="commsConnected === true"
        class="flex h-8 items-center space-x-2"
      >
        <button
          @click.prevent="disconnectComms"
          class="flex items-center justify-center rounded-full border border-grey-200 border-red-500 bg-red-500 px-4 py-1 text-white hover:border-red-700"
        >
          <div class="text-sm">Disconnect</div>
        </button>
        <button
          @click.prevent="toggleAudioSetting"
          class="flex h-8 w-8 items-center justify-center rounded-full border border-grey-200 bg-white text-white hover:bg-grey-100"
        >
          <i class="material-icons-outlined">settings</i>
        </button>
      </div>
    </div>

    <div
      v-if="commsConnected !== true"
      class="flex flex-1 items-center justify-center"
    >
      <button
        @click.prevent="connectComms"
        :disabled="commsConnected == 'connecting'"
        class="flex items-center justify-center rounded-full border border-grey-200 px-4 py-1"
        :class="{ 'hover:bg-grey-100': commsConnected === false }"
      >
        <div v-if="commsConnected === false" class="text-sm">Connect</div>
        <span v-else class="flex justify-center text-sm text-grey-400">
          <i class="material-icons-outlined spin mr-1 text-sm">autorenew</i>
          Connecting...
        </span>
      </button>
      <button
        @click.prevent="toggleAudioSetting"
        class="ml-1 flex h-8 w-8 items-center justify-center rounded-full border border-grey-200 bg-white text-white hover:bg-grey-100"
      >
        <i class="material-icons-outlined">settings</i>
      </button>
    </div>

    <div v-if="commsConnected === true" class="flex space-x-2 py-1">
      <div class="flex flex-1 flex-col items-center space-y-1">
        <div class="mb-1 flex text-xs text-grey-500">
          Waiting room
          <TooltipModal
            class="pl-1"
            :class="{
              'text-blue-400':
                waitingRoomPresenters.length + otherOperators.waiting.length >
                0,
            }"
            triggerType="hover"
            textAlign="center"
          >
            <template slot="control">
              ({{
                waitingRoomPresenters.length + otherOperators.waiting.length
              }})
            </template>

            <template slot="content">
              <div class="text-base">
                <div class="font-bold">Connected to Waiting room</div>
                <div
                  v-if="
                    waitingRoomPresenters.length +
                      otherOperators.waiting.length ==
                    0
                  "
                  class="text-grey-400"
                >
                  None
                </div>
                <div
                  v-for="presenter in waitingRoomPresenters"
                  :key="presenter.pid"
                  class="flex items-center justify-center"
                >
                  <i class="material-icons-outlined pr-1">person</i>
                  {{ presenter.name }}
                </div>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <div
                  v-for="op in otherOperators.waiting"
                  class="flex items-center justify-center"
                >
                  <i class="material-icons-outlined pr-1">support_agent</i>
                  {{ op.name }}
                </div>
              </div>
            </template>
          </TooltipModal>
        </div>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-blue-800"
              :class="{ 'border-blue-500 text-blue-500': isWaitingListening }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @click.prevent="
                $emit('set-comms-room', isWaitingListening ? null : 'waiting')
              "
            >
              <span v-if="isWaitingListening" class="flex items-center"
                ><i class="material-icons-outlined text-blue-500">volume_up</i>
                Listening</span
              >
              <span v-else>Listen</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-blue-500">Listen</span> to all
              presenters in the Waiting room.
            </div>
          </template>
        </TooltipModal>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-green-800"
              :class="{ 'border-green-800 text-green-500': isWaitingTalking }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @mousedown.prevent="startTalkWaiting"
              @mouseup="stopTalkWaiting"
              @mouseout="stopTalkWaiting"
            >
              <span v-if="isWaitingTalking" class="flex items-center"
                >Talking...</span
              >
              <span v-else>Talk</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-green-500"
                >Push and hold to talk</span
              >
              to all presenters in the Waiting room. Double click to latch.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div class="flex flex-1 flex-col items-center space-y-1">
        <div class="mb-1 flex text-xs text-grey-500">
          Production
          <TooltipModal
            class="pl-1"
            :class="{ 'text-blue-400': otherOperators.production.length > 0 }"
            triggerType="hover"
            textAlign="center"
          >
            <template slot="control">
              ({{ otherOperators.production.length }})
            </template>

            <template slot="content">
              <div class="text-base">
                <div class="font-bold">Connected to Production room</div>
                <div
                  v-if="otherOperators.production.length == 0"
                  class="text-grey-400"
                >
                  None
                </div>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <div
                  v-for="op in otherOperators.production"
                  class="flex items-center justify-center"
                >
                  <i class="material-icons-outlined pr-1">support_agent</i>
                  {{ op.name }}
                </div>
              </div>
            </template>
          </TooltipModal>
        </div>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-blue-800"
              :class="{
                'border-blue-500 text-blue-500': isProductionListening,
              }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @click.prevent="
                $emit(
                  'set-comms-room',
                  isProductionListening ? null : 'production'
                )
              "
            >
              <span v-if="isProductionListening" class="flex items-center"
                ><i class="material-icons-outlined text-blue-500">volume_up</i>
                Listening</span
              >
              <span v-else>Listen</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-blue-500">Listen</span> to all
              operators.
            </div>
          </template>
        </TooltipModal>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-green-800"
              :class="{
                'border-green-800 text-green-500': isProductionTalking,
              }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @mousedown.prevent="startTalkProduction"
              @mouseup="stopTalkProduction"
              @mouseout="stopTalkProduction"
            >
              <span v-if="isProductionTalking" class="flex items-center"
                >Talking...</span
              >
              <span v-else>Talk</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-green-500"
                >Push and hold to talk</span
              >
              to all operators. Double click to latch.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div class="flex flex-1 flex-col items-center space-y-1">
        <div class="mb-1 flex text-xs text-grey-500">
          Live room
          <TooltipModal
            class="pl-1"
            :class="{
              'text-blue-400':
                liveRoomPresenters.filter((p) => p.isOnline).length +
                  otherOperators.live.length >
                0,
            }"
            triggerType="hover"
            textAlign="center"
          >
            <template slot="control">
              ({{
                liveRoomPresenters.filter((p) => p.isOnline).length +
                otherOperators.live.length
              }})
            </template>

            <template slot="content">
              <div class="text-base">
                <div class="font-bold">Connected to Live room</div>
                <div
                  v-if="
                    liveRoomPresenters.filter((p) => p.isOnline).length +
                      otherOperators.live.length ==
                    0
                  "
                  class="text-grey-400"
                >
                  None
                </div>
                <div
                  v-for="presenter in liveRoomPresenters.filter(
                    (p) => p.isOnline
                  )"
                  :key="presenter.pid"
                  class="flex items-center justify-center"
                >
                  <i class="material-icons-outlined pr-1">person</i>
                  {{ presenter.name }}
                </div>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <div
                  v-for="op in otherOperators.live"
                  class="flex items-center justify-center"
                >
                  <i class="material-icons-outlined pr-1">support_agent</i>
                  {{ op.name }}
                </div>
              </div>
            </template>
          </TooltipModal>
        </div>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-blue-800"
              :class="{ 'border-blue-500 text-blue-500': isLiveListening }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @click.prevent="
                $emit('set-comms-room', isLiveListening ? null : 'live')
              "
            >
              <span v-if="isLiveListening" class="flex items-center"
                ><i class="material-icons-outlined text-blue-500">volume_up</i>
                Listening</span
              >
              <span v-else>Listen</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-blue-500">Listen</span> to all
              presenters in the Live room.
            </div>
          </template>
        </TooltipModal>

        <TooltipModal class="w-full" triggerType="hover" textAlign="center">
          <template slot="control">
            <button
              class="flex w-full items-center justify-center rounded-full border-2 border-grey-200 px-2 text-grey-400 hover:border-green-800"
              :class="{ 'border-green-800 text-green-500': isLiveTalking }"
              :disabled="!['rehearsal', 'live'].includes(event.eventState)"
              @mousedown.prevent="startTalkLive"
              @mouseup="stopTalkLive"
              @mouseout="stopTalkLive"
            >
              <span v-if="isLiveTalking" class="flex items-center"
                >Talking...</span
              >
              <span v-else>Talk</span>
            </button>
          </template>

          <template slot="content">
            <div class="text-sm">
              <span class="font-bold text-green-500"
                >Push and hold to talk</span
              >
              to all presenters in the Live room. Double click to latch.
            </div>
          </template>
        </TooltipModal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TooltipModal from '@/components/TooltipModal';
import AudioPlayer from '@/components/AudioPlayer';

import { getAudioDevices } from '@/helper/devices.js';

export default {
  name: 'OperatorComm',
  components: {
    TooltipModal,
    AudioPlayer,
  },
  props: {
    janus: { type: Object },
    janusPublishers: { type: Object, required: true },
    publishHandle: { type: Object },
    publisherId: { type: [Number, String] },
    commsRoom: { type: String },
    isCommsTalking: { type: Boolean, required: true },
    commsConnected: { type: [Boolean, String] },
  },
  data: () => ({
    talkWaitingClicks: 0,
    talkWaitingInterval: null,
    talkWaitingLock: false,
    talkLiveClicks: 0,
    talkLiveInterval: null,
    talkLiveLock: false,
    talkProductionClicks: 0,
    talkProductionInterval: null,
    talkProductionLock: false,
    audioInputDevices: [],
    audioOutputDevices: [],
    isSettingMenu: false,
  }),
  computed: {
    ...mapState([
      'event',
      'sltAudioInputDeviceId',
      'presenters',
      'sources',
      'sltAudioOutputDeviceId',
      'shortcut',
    ]),
    commsMembers() {
      if (this.commsConnected !== true) return [];

      const members = [];
      for (const jp of Object.values(this.janusPublishers)) {
        if (jp.operator) {
          if (jp.id == this.publisherId) continue;
          members.push({
            id: jp.id,
            isUnmuted:
              this.commsRoom != null &&
              jp.operator.commsRoom == this.commsRoom &&
              jp.operator.isCommsTalking,
          });
        } else {
          const presenter = this.presenters.find((p) => p.pid == jp.id);
          if (!presenter) continue;
          const isPromoted = this.sources.some(
            (src) => presenter.pid == src.presenterId
          );
          members.push({
            id: presenter.pid,
            isUnmuted: this.commsRoom == (isPromoted ? 'live' : 'waiting'),
          });
        }
      }
      return members;
    },
    liveRoomPresenters() {
      // promoted. either online or offline
      return this.presenters
        .filter((presenter) =>
          this.sources.find((src) => presenter.pid == src.presenterId)
        )
        .map((p) =>
          Object.assign({}, p, { isOnline: !!this.janusPublishers[p.pid] })
        );
    },
    waitingRoomPresenters() {
      // online and not promoted
      return this.presenters.filter((presenter) => {
        // Online
        if (!this.janusPublishers[presenter.pid]) {
          return false;
        }
        // Not promoted
        return !this.sources.find((src) => presenter.pid == src.presenterId);
      });
    },
    isWaitingListening() {
      return this.commsRoom == 'waiting';
    },
    isWaitingTalking() {
      return this.commsRoom == 'waiting' && this.isCommsTalking;
    },
    isLiveListening() {
      return this.commsRoom == 'live';
    },
    isLiveTalking() {
      return this.commsRoom == 'live' && this.isCommsTalking;
    },
    isProductionListening() {
      return this.commsRoom == 'production';
    },
    isProductionTalking() {
      return this.commsRoom == 'production' && this.isCommsTalking;
    },
    otherOperators() {
      const operators = { waiting: [], live: [], production: [] };
      if (this.commsConnected !== true) {
        return operators;
      }

      for (const jp of Object.values(this.janusPublishers)) {
        if (!jp.operator || jp.operator.commsRoom == null) continue;
        if (jp.id == this.publisherId) continue;
        operators[jp.operator.commsRoom].push({ name: jp.display });
      }
      return operators;
    },
  },
  watch: {
    'shortcut.talk': function (val) {
      if (this.commsConnected !== true) return;
      const room = this.commsRoom || 'live';

      const funcs = {
        waiting: { start: this.startTalkWaiting, stop: this.stopTalkWaiting },
        live: { start: this.startTalkLive, stop: this.stopTalkLive },
        production: {
          start: this.startTalkProduction,
          stop: this.stopTalkProduction,
        },
      };
      funcs[room][val ? 'start' : 'stop']();
    },
  },
  created() {
    getAudioDevices().then(({ aInput, aOutput }) => {
      this.audioInputDevices = Array.from(aInput.values());
      this.audioOutputDevices = Array.from(aOutput.values());
    });
  },
  beforeDestroy() {
    if (this.commsConnected !== false) {
      this.disconnectComms();
    }
  },
  methods: {
    startTalkWaiting() {
      if (this.commsRoom != 'waiting') {
        this.$emit('set-comms-room', 'waiting', true);
      }
      this.talkWaitingClicks++;

      // unlock on single click after double
      if (this.isCommsTalking) {
        this.talkWaitingLock = !this.talkWaitingLock;
      }
      this.$emit('set-comms-talking', true);

      // set lock on double click
      if (this.talkWaitingClicks > 1) {
        this.talkWaitingLock = !this.talkWaitingLock;
      }

      clearTimeout(this.talkWaitingInterval);
      this.talkWaitingInterval = setTimeout(() => {
        this.talkWaitingClicks = 0;
      }, 700);
    },
    stopTalkWaiting(event) {
      if (!this.isWaitingTalking || this.talkWaitingLock) return;
      if (event && event.type == 'mouseout') {
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }
      }

      this.$emit('set-comms-talking', false);
    },
    startTalkLive() {
      if (this.commsRoom != 'live') {
        this.$emit('set-comms-room', 'live', true);
      }
      this.talkLiveClicks++;

      // unlock on single click after double
      if (this.isCommsTalking) {
        this.talkLiveLock = !this.talkLiveLock;
      }
      this.$emit('set-comms-talking', true);

      // set lock on double click
      if (this.talkLiveClicks > 1) {
        this.talkLiveLock = !this.talkLiveLock;
      }

      clearTimeout(this.talkLiveInterval);
      this.talkLiveInterval = setTimeout(() => {
        this.talkLiveClicks = 0;
      }, 700);
    },
    stopTalkLive(event) {
      if (!this.isLiveTalking || this.talkLiveLock) return;
      if (event && event.type == 'mouseout') {
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }
      }

      this.$emit('set-comms-talking', false);
    },
    startTalkProduction() {
      if (this.commsRoom != 'production') {
        this.$emit('set-comms-room', 'production', true);
      }
      this.talkProductionClicks++;

      // unlock on single click after double
      if (this.isCommsTalking) {
        this.talkProductionLock = !this.talkProductionLock;
      }
      this.$emit('set-comms-talking', true);

      // set lock on double click
      if (this.talkProductionClicks > 1) {
        this.talkProductionLock = !this.talkProductionLock;
      }

      clearTimeout(this.talkProductionInterval);
      this.talkProductionInterval = setTimeout(() => {
        this.talkProductionClicks = 0;
      }, 700);
    },
    stopTalkProduction(event) {
      if (!this.isProductionTalking || this.talkProductionLock) return;
      if (event && event.type == 'mouseout') {
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }
      }

      this.$emit('set-comms-talking', false);
    },
    disconnectComms() {
      this.publishHandle.send({
        message: { request: 'unpublish' },
      });
      this.$emit('set-comms-connected', false);
      this.$emit('set-comms-room', null);
    },
    async connectComms() {
      if (this.commsConnected == 'connecting') return;
      this.$emit('set-comms-connected', 'connecting');

      try {
        await this.requestDevicePermission();
        this.publishHandle.createOffer({
          media: {
            video: false,
            audio: this.sltAudioInputDeviceId
              ? { deviceId: this.sltAudioInputDeviceId }
              : true,
            data: false,
            videoSend: false,
            audioSend: true,
            videoRecv: false,
            audioRecv: false,
          },
          simulcast: true,
          success: (jsep) => {
            this.publishHandle.send({
              message: { request: 'publish' },
              jsep,
            });
            this.$emit('set-comms-connected', true);
          },
          error: (err) => {
            console.error('webrtc', err);
            this.commit('ERROR', err.message || err.name);
            this.$emit('set-comms-connected', false);
          },
        });
      } catch (e) {
        console.error(e);
        this.$emit('set-comms-connected', false);
      }
    },
    async requestDevicePermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        for (const track of stream.getAudioTracks()) {
          track.stop();
        }
      } catch (e) {
        if (['NotAllowedError', 'SecurityError'].includes(e.name)) {
          this.$store.commit(
            'ERROR',
            'You must grant permission to use your microphone. Refresh and try again.'
          );
        } else {
          this.$store.commit(
            'ERROR',
            'Audio comm is not ready, Please try it again later'
          );
        }
        throw e;
      }
    },
    toggleAudioSetting() {
      this.isSettingMenu = !this.isSettingMenu;
      this.$emit('onToggleSettingMenu', this.isSettingMenu);
    },
  },
};
</script>
