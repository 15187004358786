<template>
  <form @submit.prevent="save()">
    <div class="pb-8 text-grey-400">
      Configure additional optional branding settings to further customise your
      portal.
    </div>

    <div class="mb-2 mt-6 text-lg font-medium">Session background</div>
    <FileInput
      name="secondary_page_background_image"
      id="file_upload_page_background"
      label="Page background image"
      class="mb-8"
      :supports="['png', 'jpg', 'jpeg']"
      :fileSizeLimit="2048"
      v-model="form.secondaryPageBackgroundImage"
      tooltip="Set an optional background image for the session pages. This image is displayed after attendee login whilst in a Live event session. If not set, the Basic background image is displayed. Recommended 1920 (w) x 1080 (h) pixel JPG."
      :required="false"
      :errors="errors"
    />

    <div class="mb-2 mt-12 text-lg font-medium">Session logos</div>
    <FileInput
      name="secondary_logo"
      id="file_upload_secondary_logo"
      label="Primary logo"
      class="mb-8"
      :supports="['png', 'jpg', 'jpeg']"
      :fileSizeLimit="2048"
      v-model="form.secondaryLogo"
      tooltip="Set an optional logo image for the session pages. This image is displayed after attendee login whilst in a Live event session. If not set, the Basic logo image is displayed. Recommended 600 (w) x 200 (h) transparent PNG."
      :required="false"
      :errors="errors"
    />

    <div v-if="!can('event-registration')" class="mb-6">
      <UpgradePlanLink />
    </div>
    <div v-else>
      <FileInput
        name="secondary_sponsor_image"
        id="file_upload_secondary_sponsor"
        label="Sponsor logo"
        class="mb-8"
        :supports="['png', 'jpg', 'jpeg']"
        :fileSizeLimit="2048"
        v-model="form.secondarySponsorImage"
        tooltip="Set an optional sponsor logo image for the session pages. This image is displayed after attendee login whilst in a Live event session. If not set, the Basic sponsor image is displayed. Recommended 600 (w) x 200 (h) transparent PNG."
        :required="false"
        :errors="errors"
      />
    </div>

    <div class="mb-2 mt-12 text-lg font-medium">Messaging</div>
    <div class="pb-8 text-grey-400">
      Customise messaging for the attendee portal
    </div>

    <MdTextField
      name="portal_heading"
      label="Portal heading"
      v-model="form.portalHeading"
      :required="false"
      tooltip="Add an optional heading to your portal. Displayed below the event logo."
      :errors="errors"
    />

    <MdTextareaField
      name="description"
      label="Event description"
      v-model="form.description"
      :required="false"
      tooltip="Set an optional event description for display on the dashboard and calendar invitations."
      :errors="errors"
    />

    <MdTextareaField
      name="tagline"
      label="Tagline"
      v-model="form.tagline"
      :required="false"
      tooltip="Displayed below the event logo and header as an additional branding element."
      :errors="errors"
    />

    <MdTextareaField
      name="event_finished_message"
      label="Finish message"
      v-model="form.eventFinishedText"
      :required="false"
      tooltip="Displayed after the event has completed and attendees are logged out of the portal."
      :errors="errors"
    />

    <MdTextareaField
      name="support_message"
      label="Support message"
      v-model="form.supportText"
      :required="false"
      tooltip="Displayed to attendees when they request help. It is recommended you include additional contact details in this message."
      :errors="errors"
    />

    <!-- additional options when registration is enabled -->
    <div class="mb-2 mt-6 text-lg font-medium">Registration</div>
    <div v-if="!can('event-registration')" class="mb-6">
      <UpgradePlanLink />
    </div>

    <div v-else>
      <div
        v-if="
          ['automatic_registration', 'manual_registration'].includes(
            this.event.registrationType
          )
        "
      >
        <div class="pb-8 text-grey-400">
          Customise messaging related to attendee registration
        </div>

        <MdTextField
          name="call_to_action_message"
          label="Call to action message"
          v-model="form.registrationCallToActionText"
          :required="false"
          tooltip="Displayed on the login page prompting the attendee to register."
          :errors="errors"
        />

        <MdTextareaField
          name="registration_complete_message"
          label="Registration submitted message"
          v-model="form.registrationCompleteText"
          :required="false"
          tooltip="Displayed after the attendee completes the registration form."
          :errors="errors"
        />
      </div>
      <div v-else>
        <div class="pb-6 text-grey-400">
          Select Access type 'Automatic registration' or 'Manual registration'
          from
          <RouterLink
            :to="'/events/' + event.id + '/settings'"
            class="text-blue-500"
          >
            General Settings
          </RouterLink>
          to change attendee registration settings.
        </div>
      </div>
    </div>

    <SubmitButton :saving="saving" />
  </form>
</template>

<script>
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import includes from 'lodash/includes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import api from '@/services/api';
import errors from '@/mixins/errors';

import SubmitButton from '@/components/SubmitButton';
import FileInput from '@/components/FileInput';
import MdTextareaField from '@/components/MdTextareaField';
import MdTextField from '@/components/MdTextField';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    SubmitButton,
    FileInput,
    MdTextareaField,
    MdTextField,
    UpgradePlanLink,
  },

  data: () => ({
    form: {
      pageBackgroundImage: null,
      pageBackgroundColor: '#00b4f1',
      font: 'Lato',
      logo: null,
      sponsorImage: null,
      contentBackgroundColor: '#ffffff',
      textColor: '#212121',
      buttonColor: '#00b4f1',
      buttonTextColor: '#ffffff',
      portalHeading: null,
      description: null,
      tagline: null,
      eventFinishedText: null,
      supportText: null,
      registrationCallToActionText: null,
      registrationCompleteText: null,
      secondaryLogo: null,
      secondaryPageBackgroundImage: null,
      secondarySponsorImage: null,
    },
    saving: false,
    isFormChanged: false,
    sendEmail: false,
  }),

  mounted() {
    if (this.event) {
      this.setupForm();
    }
  },

  methods: {
    setupForm() {
      assign(this.form, pick(this.event, keys(this.form)));

      this.$nextTick(() => {
        this.isFormChanged = false;
      });
    },

    save() {
      this.saving = true;

      // Manually create a form data object so we can mix a file upload with other fields
      let data = new FormData();

      data.append('secondaryLogo', this.form.secondaryLogo);
      data.append(
        'secondaryPageBackgroundImage',
        this.form.secondaryPageBackgroundImage
      );
      data.append('secondarySponsorImage', this.form.secondarySponsorImage);

      // FormData cannot send literal null, so we only append the fields with a value.
      for (const property in this.form) {
        if (
          includes(
            [
              'logo',
              'pageBackgroundImage',
              'sponsorImage',
              'secondaryLogo',
              'secondaryPageBackgroundImage',
              'secondarySponsorImage',
            ],
            property
          )
        ) {
          continue;
        }

        const value = this.form[property];

        if (value) {
          data.append(property, value);
        }
      }

      // TODO: Use the Vuex store for this!
      api
        .put('/jobs/' + this.event.id + '/branding', data)
        .then((response) => {
          this.saving = false;

          this.notify('success', 'Event updated');
          this.$emit('event-updated', response.data);
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
