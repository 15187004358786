<template>
  <div class="h-full w-full">
    <i v-if="!jobLoaded" class="material-icons-outlined animate-spin"> sync </i>
    <RouterView v-else />
  </div>
</template>

<script>
export default {
  nane: 'EventOutput',
  data: () => ({
    jobLoaded: false,
  }),
  async mounted() {
    await this.$store.dispatch('GET_JOB');
    this.jobLoaded = true;
    await this.$store.dispatch('CONTROL_INIT');
  },

  beforeDestroy() {
    this.$store.dispatch('CONTROL_LEAVE');
  },
};
</script>
