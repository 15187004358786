<template>
  <div class="no-gutters">
    <div cols="12">
      <label
        v-if="label"
        class="label flew-row flex items-center justify-between text-left text-grey-500"
      >
        {{ label }}
        <TooltipModal v-if="tooltip">
          <template slot="control">
            <div class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </div>
          </template>

          <template slot="content">
            {{ tooltip }}
          </template>
        </TooltipModal>
      </label>
      <div
        class="relative pt-3"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
      >
        <div
          ref="fileUpload"
          class="file_upload"
          v-if="file == null && isPreview == false"
          @click="handleTrigger"
        >
          <template v-if="isFileDrop">
            <i v-if="isFileDropError" class="material-icons-outlined"
              >file_download_off</i
            >
            <i v-else class="material-icons-outlined">file_download</i>
            <span class="file_drop_message text-xs text-red-500">{{
              fileDropMessage
            }}</span>
          </template>
          <template v-else>
            <i class="material-icons-outlined">add_photo_alternate</i>
            <span
              class="my-1 flex items-center justify-center px-2 text-sm text-blue-500"
              >Add File
            </span>
          </template>

          <p class="px-2 text-center text-sm text-grey-500">
            {{ getSupportsString }} Accepted
          </p>
          <p
            class="text-sm text-grey-400"
            :class="{ 'text-red-500': isFileOverSize }"
          >
            Maximum file size {{ getFileSize }}
          </p>
        </div>

        <div class="preview" v-else>
          <button
            class="btn_file_remove flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
            @click="handleRemove"
          >
            <i class="material-icons-outlined">close</i>
          </button>
          <div
            v-show="format == 'image'"
            ref="previewImage"
            class="preview_image"
          ></div>
          <video
            v-show="format == 'video'"
            ref="previewVideo"
            class="preview_video"
            muted
            playsinline
            controls
          ></video>
          <div
            v-if="format == 'other'"
            class="preview_other flex-col items-center justify-center"
          >
            <i
              class="material-icons-outlined rounded-full bg-grey-200 px-3 py-3"
              >insert_drive_file</i
            >
            <span class="mt-3">{{ file.name }}</span>
          </div>
        </div>

        <input
          ref="file_input"
          type="file"
          :name="name"
          @change="handleAddFile"
          class="backlayer"
          :required="getRequired"
          :autofocus="autofocus"
          @focus="focused = true"
          @blur="focused = false"
          :accept="getAccepts"
        />
      </div>

      <div v-if="getRequired && errors">
        <ValidationErrors :errors="errors" :name="name"></ValidationErrors>

        <div v-if="help" class="mt-2 text-grey-600" v-html="help"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipModal from '@/components/TooltipModal';
import ValidationErrors from '@/components/ValidationErrors';

export default {
  name: 'FileInput',
  props: {
    supports: {
      type: Array,
      default: () => ['png', 'jpeg', 'jpg,', 'mp4', 'mov'],
    },
    label: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    value: {},
    name: null,
    required: {
      type: Boolean,
      default: false,
    },
    help: null,
    errors: {},
    autofocus: {
      default: false,
    },
    fileSizeLimit: {
      type: Number,
      default: 2048,
    },
  },
  components: {
    TooltipModal,
    ValidationErrors,
  },
  data: () => ({
    file: null,
    progress: 0,
    format: null,
    isFileDrop: false,
    isFileDropError: false,
    fileDropMessage: null,
    isPreview: false,
    focused: false,
    isPreviewLoading: false,
    mimeTypes: {
      png: 'image/png',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      mp4: 'video/mp4',
      mov: 'video/quicktime',
      csv: 'text/csv',
      pdf: 'application/pdf',
      ppt: 'application/vnd.ms-powerpoint',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      key: 'application/vnd.apple.keynote',
    },
    isFileOverSize: false,
    setBackgroundTimeout: null,
  }),
  computed: {
    getSupports() {
      return this.supports.map((support) => {
        return this.mimeTypes[support];
      });
    },
    getSupportsString() {
      var output_array = this.supports.map((support) => {
        //return this.mimeTypes[support].split('/').pop().toUpperCase();
        return support.toUpperCase();
      });

      return output_array.join(', ');
    },
    getAccepts() {
      var output_array = this.supports.map((support) => {
        return '.' + support.split('/').pop();
      });

      return output_array.join(', ');
    },
    getRequired() {
      return this.value ? false : this.required;
    },
    getFileSize() {
      var units = ['kB', 'MB', 'GB'];
      var i = 0;
      var result = this.fileSizeLimit;

      while (result / 1024 >= 1 && i < 3) {
        i++;
        result = result / 1024;
      }

      return result.toFixed(2) + units[i];
    },
  },
  watch: {
    file: function () {
      if (this.file) {
        var reader = new FileReader();

        this.isPreviewLoading = true;
        this.previewProgress = 0;

        var chunkSize = 500000000; //500mb chunk for preview
        var chunk = null;
        if (this.file.size > chunkSize) {
          chunk = this.file.slice(0, chunkSize, this.file.type);
        } else {
          chunk = this.file;
        }

        reader.onload = (e) => {
          if (this.file.type.match(/video/g)) {
            this.format = 'video';

            var blob = new Blob([e.target.result], { type: this.file.type });
            var url = URL.createObjectURL(blob);

            this.$refs.previewVideo.preload = 'metadata';
            this.$refs.previewVideo.src = url;
          } else if (this.file.type.match(/image/g)) {
            this.format = 'image';
            this.$refs.previewImage.style = `background-image: url(${e.target.result})`;
          } else {
            this.format = 'other';
          }
        };

        reader.onprogress = (e) => {
          this.previewProgress = Math.round((e.loaded / e.total) * 100) + '%';

          if (this.previewProgress == '100%') {
            this.isPreviewLoading = false;
          }
        };

        if (this.file.type.match(/video/g)) {
          reader.readAsArrayBuffer(chunk);
        } else {
          reader.readAsDataURL(chunk);
        }
      }
    },
    value: function () {
      this.loadPreview();
    },
  },
  mounted() {
    this.loadPreview();
  },
  beforeDestroy() {
    clearTimeout(this.setBackgroundTimeout);
  },
  methods: {
    loadPreview() {
      if (!this.file) {
        if (this.value != null) {
          this.isPreview = true;
          this.isImage = true;
          var url =
            process.env.VUE_APP_API_URL +
            '/uploads/' +
            encodeURIComponent(this.value);

          this.setBackgroundTimeout = setTimeout(() => {
            this.$refs.previewImage.style = `background-image: url("${url}")`;
          }, 50);
        } else {
          this.isPreview = false;
          this.isImage = false;
        }
      }
    },
    loadFile() {
      var reader = new FileReader();

      this.isPreviewLoading = true;
      this.previewProgress = 0;

      reader.onload = (e) => {
        console.log('E', e.target.result);
        if (this.file.type.match(/video/g)) {
          this.format = 'video';

          console.log('start blob');
          var blob = new Blob([e.target.result], { type: this.file.type });
          var url = URL.createObjectURL(blob);

          console.log('assign url');
          this.$refs.previewVideo.preload = 'metadata';
          this.$refs.previewVideo.src = url;
          console.log('blob loaded');
        } else if (this.file.type.match(/image/g)) {
          this.format = 'image';
          this.$refs.previewImage.style = `background-image: url(${e.target.result})`;
        } else {
          this.format = 'other';
        }
      };

      reader.onprogress = (e) => {
        this.previewProgress = Math.round((e.loaded / e.total) * 100) + '%';

        console.log(this.previewProgress);

        if (this.previewProgress == '100%') {
          this.isPreviewLoading = false;
        }
      };

      /*if(this.file.type.match(/video/g)) {
                reader.readAsArrayBuffer(this.file);
            } else {
                reader.readAsDataURL(this.file);
            }*/

      reader.readAsArrayBuffer(this.file);
    },
    handleTrigger() {
      this.isFileOverSize = false;
      this.$refs.file_input.click();
    },
    handleAddFile(e) {
      this.file = this.checkFileSize(e.target.files[0]);

      this.$emit('input', this.file);
    },
    handleDragOver(e) {
      e.preventDefault();

      this.isFileOverSize = false;

      if (!this.$refs['fileUpload'].classList.contains('active')) {
        this.$refs['fileUpload'].classList.add('active');

        this.fileDropMessage = 'Drop a file to upload.';
        this.isFileDrop = true;
        this.isFileDropError = false;
      }
    },
    handleDragLeave(e) {
      e.preventDefault();
      this.$refs.fileUpload.classList.remove('active');

      this.isFileDrop = false;
      this.isFileDropError = false;
    },
    handleDrop(e) {
      e.preventDefault();

      this.$refs.fileUpload.classList.remove('active');
      this.isFileDrop = false;

      if (this.getSupports.indexOf(e.dataTransfer.files[0].type) !== -1) {
        this.file = this.checkFileSize(e.dataTransfer.files[0]);

        this.$emit('input', this.file);
      } else {
        this.isFileDrop = true;
        this.isFileDropError = true;
        this.fileDropMessage = `Format ${e.dataTransfer.files[0].type} is not allowed. Please choose another file.`;
      }
    },
    handleRemove() {
      this.file = {};
      this.file = null;

      this.progress = 0;
      this.isImage = null;
      this.isFileDrop = false;
      this.isFileDropError = false;
      this.fileDropMessage = null;
      this.isPreview = false;
      this.focused = false;
      this.isPreviewLoading = false;

      //reset input file to allow trigger change event later
      //when select the same file again
      this.$refs.file_input.value = '';

      this.$emit('input', null);
    },
    checkFileSize(file) {
      if (file.size / 1024 > this.fileSizeLimit) {
        this.isFileOverSize = true;
        this.$emit('onError', 'Oversized file not accepted.');
        return null;
      }

      this.isFileOverSize = false;
      return file;
    },
  },
};
</script>

<style scoped>
.backlayer {
  z-index: -1;
  position: absolute;
  top: 0;
}
.file_upload {
  background: #eee;
  height: 169px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  cursor: pointer;
}
.preview {
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
}
.preview_image {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
}
.preview_video {
  width: 100%;
  height: 100%;
}
.preview_other {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
}
.active {
  border: 2px dashed #a0a0a0 !important;
  background: #f8f8f8 !important;
}
.file_drop_message {
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.btn_file_remove {
  position: absolute !important;
  right: 5px;
  top: 5px;
  z-index: 99;
}
.file_progress {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
}
</style>
