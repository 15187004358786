<template>
  <div class="flex flex-grow flex-col">
    <!-- main content -->
    <div v-show="section == null" class="flex h-full flex-col">
      <div class="flex flex-col overflow-auto lg:flex-grow">
        <div class="flex items-center justify-between pt-6">
          <h4 class="text-center text-lg font-bold">
            Live room ({{ liveRoomPresenters.length }})
          </h4>
        </div>

        <div
          v-if="liveRoomPresenters.length == 0"
          class="p-3 text-center text-grey-300"
          id="live_list_empty"
        >
          No presenters in the live room
        </div>
        <ul class="w-full">
          <StudioPanelPresenter
            v-for="presenter in liveRoomPresenters"
            :key="presenter.pid"
            :presenter="presenter"
            :actionControl="actionControl"
            :type="'active'"
            :isPromoted="true"
            :isOnline="presenter.isOnline"
            @onremove="navigateToRemovePresenter"
            @onrename="navigateToRenamePresenter"
            @onkick="navigateToKickPresenter"
            @onlock="navigateToLockPresenterBitrate"
          />
        </ul>

        <div class="flex items-center justify-between pt-6">
          <h4 class="text-lg font-bold">
            Waiting room ({{ waitingRoomPresenters.length }})
          </h4>
        </div>

        <div
          v-if="waitingRoomPresenters.length == 0"
          class="p-3 text-center text-grey-300"
          id="waiting_list_empty"
        >
          No presenters in the waiting room
        </div>

        <ul class="w-full" id="waiting_list">
          <StudioPanelPresenter
            v-for="presenter in waitingRoomPresenters"
            :key="presenter.pid"
            :presenter="presenter"
            :actionControl="actionControl"
            :type="'inactive'"
            :isPromoted="false"
            :isOnline="true"
            @onremove="navigateToRemovePresenter"
            @onrename="navigateToRenamePresenter"
            @onkick="navigateToKickPresenter"
            @onlock="navigateToLockPresenterBitrate"
          />
        </ul>

        <h4 class="pt-6 text-lg font-bold">
          Offline ({{ offlinePresenters.length }})
        </h4>
        <div
          v-if="offlinePresenters.length == 0"
          class="p-3 text-center text-grey-300"
        >
          No offline presenters
        </div>

        <ul class="w-full">
          <StudioPanelPresenter
            v-for="presenter in offlinePresenters"
            :key="presenter.pid"
            :presenter="presenter"
            :actionControl="actionControl"
            :type="'inactive'"
            :isPromoted="false"
            :isOnline="false"
            @onremove="navigateToRemovePresenter"
            @onrename="navigateToRenamePresenter"
            @onkick="navigateToKickPresenter"
            @onlock="navigateToLockPresenterBitrate"
          />
        </ul>
      </div>
      <div
        class="flex flex-shrink-0 justify-center border-t border-grey-200 pt-3"
      >
        <button
          class="btn-blue"
          @click.prevent="handlePresenterInv"
          id="btn_invite_presenter"
        >
          Invite presenter
        </button>
      </div>
    </div>
    <!-- end of main content -->

    <!-- sub content -->
    <template v-if="section != null">
      <!-- sub content navigation -->
      <div class="py-6">
        <button
          class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          @click.prevent="section = null"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </button>
        <h3 class="-ml-10 w-full text-center text-2xl font-bold">
          {{ upperFirst(section) }}
        </h3>
      </div>
      <!-- sub content navigation -->

      <!-- invite presenter -->
      <template v-if="section == 'invite_presenter'">
        <form class="flex flex-col px-3" @submit.prevent="handleAddPresenter">
          <TextField
            name="presenter_name"
            label="Name"
            v-model="presenterName"
            :required="true"
            :errors="errors"
            :autofocus="true"
          />

          <EmailField
            name="presenter_email"
            label="Email"
            v-model="presenterEmail"
            :required="true"
            :errors="errors"
          />

          <div class="flex justify-center">
            <MaterialIconsCheckbox
              name="send_email_invite"
              class="mb-6 text-center"
              label="Send email invitation"
              v-model="presenterSendEmail"
            />
          </div>

          <div v-if="!presenterSendEmail" class="pb-6 text-center">
            This presenter will not receive an invitation email. You must share
            their presentation link manually.
          </div>

          <button type="submit" class="btn-blue" id="btn_invite_presenter_form">
            Invite presenter
          </button>
        </form>
      </template>
      <!-- end of invite presenter -->

      <!-- kick presenter -->
      <template v-if="section == 'kick_presenter'">
        <div class="overflow-auto px-3">
          <h4 class="text-center text-lg font-bold">
            {{ selectedPresenter.name }}
          </h4>
          <div class="mt-10 text-center">
            Are you sure you want to log out this presenter? They will be logged
            out of their current session in the Presenter Hub but can log in
            again if required.
          </div>
          <button class="btn-red mt-10" @click.prevent="handleKickPresenter">
            Log out presenter
          </button>
        </div>
      </template>
      <!-- end of kick presenter -->

      <!-- delete presenter -->
      <template v-if="section == 'delete_presenter'">
        <div class="overflow-auto px-3">
          <h4 class="text-center text-lg font-bold">
            {{ selectedPresenter.name }}
          </h4>
          <div class="mt-10 text-center">
            Are you sure you want to delete this presenter? They will be removed
            from your event and unable to present unless invited again.
          </div>

          <button class="btn-red mt-10" @click.prevent="handleRemovePresenter">
            Delete
          </button>
        </div>
      </template>
      <!-- end of delete presenter -->

      <!-- rename presenter -->
      <template v-if="section == 'rename_presenter'">
        <form
          class="flex flex-col px-3"
          @submit.prevent="handleRenamePresenter"
        >
          <TextField
            name="presenter_name"
            label="Name"
            v-model="name"
            :required="true"
            :errors="errors"
            :autofocus="true"
          />

          <button type="submit" class="btn-blue">Rename presenter</button>
        </form>
      </template>
      <!-- end of rename presenter -->

      <!-- lock presenter bitrate -->
      <template v-if="section == 'lock_presenter_bitrate'">
        <div class="overflow-auto px-3">
          <h4 class="text-center text-lg font-bold">
            {{ selectedPresenter.name }}
          </h4>
          <p class="py-3 text-sm text-grey-500">
            Locking a Presenter's stream bitrate will disable automatic quality
            scaling. This may provide a more consistent image quality when a
            Presenter's connection is fluctuating often. Warning: locking to a
            bitrate that the Presenter's internet connection cannot maintain
            will result in dropouts.
          </p>
          <button
            @click="handleLockBitrate(128000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 128000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Very low - 128 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(512000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 512000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Low - 512 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(1024000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 1024000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Moderate - 1024 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(1500000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 1500000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              High - 1500 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(3000000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 3000000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Very high - 3000 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(5000000)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 5000000"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Extremely high - 5000 kbit/s maximum
            </div>
          </button>
          <button
            @click="handleLockBitrate(0)"
            class="block flex w-full cursor-pointer flex-row items-center rounded p-3 hover:bg-grey-100"
          >
            <div class="w-5 text-left">
              <i
                v-if="selectedPresenter.forceBitrate == 0"
                class="material-icons-outlined text-sm text-green-500"
                >check</i
              >
            </div>
            <div class="whitespace-nowrap text-left">
              Unlocked - auto scaling
            </div>
          </button>
        </div>
      </template>
      <!-- end of lock presenter bitrate -->
    </template>
    <!-- end of sub content -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import StudioPanelPresenter from '@/components/StudioPanelPresenter';
import TextField from '@/components/TextField';
import EmailField from '@/components/EmailField';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';

import errors from '@/mixins/errors';
import upperFirst from 'lodash/upperFirst';
import api from '@/services/api';

export default {
  name: 'StudioPanelPresenters',

  mixins: [errors],

  components: {
    StudioPanelPresenter,
    TextField,
    EmailField,
    MaterialIconsCheckbox,
  },
  props: {
    actionControl: { type: Boolean, required: true },
    isActive: { type: Boolean, required: true },
    janusPublishers: { type: Object, required: true },
  },
  data: () => ({
    section: null,
    presenterName: null,
    presenterEmail: null,
    presenterSendEmail: true,
    selectedPresenter: null,
    name: null,
  }),
  watch: {
    isActive: function (val) {
      if (val) {
        this.section = null;
      }
    },
  },
  computed: {
    ...mapState(['event', 'sources', 'presenters']),
    liveRoomPresenters() {
      const onlinePids = [];
      for (const pid of Object.keys(this.janusPublishers)) {
        const [pType, pTypeId] = pid.split('-');
        if (pType === 'Presenter') onlinePids.push(+pTypeId);
      }
      // promoted. either online or offline
      const presenters = this.presenters
        .filter((presenter) =>
          this.sources.find((src) => presenter.pid == src.presenterId)
        )
        .map((p) => Object.assign({}, p, { isOnline: onlinePids.includes(p.pid) }));

      return presenters.sort((a, b) => {
        if (a.isOnline != b.isOnline) {
          return +a.isOnline < +b.isOnline ? 1 : -1;
        }
        return a.name.localeCompare(b.name);
      });
    },
    waitingRoomPresenters() {
      const onlinePids = [];
      for (const pid of Object.keys(this.janusPublishers)) {
        const [pType, pTypeId] = pid.split('-');
        if (pType === 'Presenter') onlinePids.push(+pTypeId);
      }
      // online and not promoted
      const presenters = this.presenters.filter((presenter) => {
        // Online
        if (!onlinePids.includes(presenter.pid)) {
          return false;
        }
        // Not promoted
        return !this.sources.find((src) => presenter.pid == src.presenterId);
      });
      return presenters.sort((a, b) => a.name.localeCompare(b.name));
    },
    offlinePresenters() {
      const onlinePids = [];
      for (const pid of Object.keys(this.janusPublishers)) {
        const [pType, pTypeId] = pid.split('-');
        if (pType === 'Presenter') onlinePids.push(+pTypeId);
      }
      // offline and not promoted
      const presenters = this.presenters.filter((presenter) => {
        // Offline
        if (onlinePids.includes(presenter.pid)) {
          return false;
        }
        // Not promoted
        return !this.sources.find((src) => presenter.pid == src.presenterId);
      });
      return presenters.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    handlePresenterInv() {
      this.section = 'invite_presenter';
    },
    handleAddPresenter() {
      var checkEmail = this.presenters.find(
        (p) => p.email == this.presenterEmail
      );
      if (checkEmail) {
        this.$store.commit(
          'ERROR',
          'Email is in use. Presenter emails must be unique.'
        );
      } else {
        this.$store.dispatch('ADD_PRESENTER', {
          email: this.presenterEmail,
          name: this.presenterName,
          sendInv: this.presenterSendEmail,
        });

        this.section = null;

        this.presenterEmail = null;
        this.presenterName = null;

        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
      }
    },
    navigateToRemovePresenter(presenter) {
      this.selectedPresenter = presenter;

      this.section = 'delete_presenter';
    },
    handleRemovePresenter() {
      this.$store.dispatch('REMOVE_PRESENTER', {
        pid: this.selectedPresenter.pid,
      });

      this.section = null;
    },
    navigateToRenamePresenter(presenter) {
      this.selectedPresenter = presenter;
      this.name = presenter.name;

      this.section = 'rename_presenter';
    },
    handleRenamePresenter() {
      this.$store.dispatch('RENAME_MEDIA', {
        type: 'Presenter',
        id: this.selectedPresenter.pid,
        name: this.name,
      });

      this.section = null;
    },
    navigateToKickPresenter(presenter) {
      this.selectedPresenter = presenter;

      this.section = 'kick_presenter';
    },
    navigateToLockPresenterBitrate(presenter) {
      this.selectedPresenter = presenter;

      this.section = 'lock_presenter_bitrate';
    },
    handleKickPresenter() {
      this.$store.dispatch('CONTROL', {
        mode: 'log_presenter_out',
        payload: {
          id: this.selectedPresenter.pid,
        },
      });

      this.selectedPresenter = null;
      this.section = null;
    },
    handleLockBitrate(bitrate) {
      this.$store.commit('UPDATE_PRESENTER', {
        id: this.selectedPresenter.pid,
        key: 'forceBitrate',
        value: bitrate,
      });
      const url = `/mixer/${this.event.jobId}/editMedia`;
      const _payload = {
        type: 'Presenter',
        srcObjId: this.selectedPresenter.pid,
        fields: { forceBitrate: bitrate },
      };
      api
        .post(url, _payload)
        .catch((e) => this.$store.commit('ERROR', e?.message || e));

      this.selectedPresenter = null;
      this.section = null;
    },
    upperFirst(value) {
      if (!value) return;

      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },
  },
};
</script>
