<template>
  <div class="mt-8 flex flex-grow justify-center" @mouseup="unsetSlotDrag">
    <div class="w-full bg-white">
      <div
        v-if="error"
        class="flex items-center justify-center py-8 text-grey-400"
      >
        {{ error }}
      </div>
      <div v-else>
        <form @submit.prevent="save">
          <div class="flex items-start">
            <div class="flex-1">
              <MdTextField
                class="flex-1"
                label="Name"
                name="name"
                :required="true"
                v-model="form.name"
                :errors="errors"
                :disabled="isSystemDefault"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Provide a name for this custom layout. This will be shown on
                  the Layouts screen in the Studio.
                </div>
              </template>
            </TooltipModal>
          </div>

          <div class="flex items-start">
            <div class="flex-1">
              <MdTextareaField
                class="flex-1"
                label="Description"
                name="description"
                :required="false"
                :rows="2"
                v-model="form.description"
                :errors="errors"
                :disabled="isSystemDefault"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Provide a description for this custom layout. This will be
                  shown on the Layouts screen in the Studio.
                </div>
              </template>
            </TooltipModal>
          </div>

          <div class="flex flex-1 justify-center">
            <div class="relative w-3/4 overflow-hidden border border-grey-400">
              <div class="layout_wrap">
                <div v-if="!isEdit" ref="layout" class="layout">
                  <div
                    v-for="(slot, index) in form.slots"
                    :key="slot.slotNo"
                    ref="slot"
                    class="slot cursor-pointer select-none opacity-50"
                    :class="{
                      'cursor-move border-2 border-blue-500 opacity-100':
                        selectedSlot && selectedSlot.slotNo == slot.slotNo,
                    }"
                    :style="{
                      zIndex: slot.slotNo,
                      width: getWidth(slot),
                      height: getHeight(slot),
                      left: slot.previewX + 'px',
                      top: slot.previewY + 'px',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${require('../assets/test-pattern.jpg')})`,
                      backgroundPosition: getSlotBackgroundSizePosition(slot),
                      backgroundSize: getSlotBackgroundSize(slot),
                    }"
                    @click.prevent="selectSlot(slot)"
                    @mousedown="setSlotDrag($event, slot, index)"
                    @mousemove="slotDrag"
                  >
                    <span
                      class="flex h-8 w-8 items-center justify-center rounded border border-white bg-black text-white"
                      >{{ slot.slotNo }}</span
                    >
                  </div>
                </div>
                <div v-else class="layout">
                  <img
                    ref="image"
                    class="absolute left-0 top-0 z-0 h-full w-full"
                    src="../assets/test-pattern.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="selectedSlot"
            class="pt-2 text-center text-sm text-grey-400"
          >
            Use drag and drop or keyboard arrow keys to adjust slot position.
          </div>

          <div class="flex items-center justify-between py-4">
            <div class="">Slots</div>
            <button
              @click.prevent="addNewSlot"
              :disabled="isSystemDefault || isReachMaxSlots"
              class="group flex items-center justify-center space-x-1 rounded-full border border-grey-200 px-3 py-1 hover:border-blue-500"
            >
              <i class="material-icons-outlined group-hover:text-blue-500"
                >add</i
              >
              <span class="text-sm text-grey-800 group-hover:text-blue-500"
                >New slot</span
              >
            </button>
          </div>
          <div
            class="flex flex-col items-start justify-center border-t border-grey-200 pt-6"
          >
            <div
              v-for="(slot, i) in form.slots"
              :key="slot.slotNo"
              class="item-center flex w-full justify-between space-x-4"
            >
              <div
                v-if="selectedSlot && selectedSlot == slot && isEdit"
                class="flex w-full justify-between space-x-4"
              >
                <MdTextField
                  class="mr-1 flex-1"
                  label="Width"
                  name="cropwidth"
                  type="number"
                  min="0"
                  max="1920"
                  v-model="form.slots[i].cropWidth"
                  :errors="errors"
                  @keyup="updateCrop($event, 'width')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Height"
                  name="cropheight"
                  type="number"
                  min="0"
                  max="1080"
                  v-model="form.slots[i].cropHeight"
                  :errors="errors"
                  @keyup="updateCrop($event, 'height')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Left"
                  name="cropleft"
                  type="number"
                  min="0"
                  max="1920"
                  v-model="form.slots[i].cropLeft"
                  :errors="errors"
                  @keyup="updateCrop($event, 'left')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Right"
                  name="cropright"
                  type="number"
                  min="0"
                  max="1920"
                  v-model="form.slots[i].cropRight"
                  :errors="errors"
                  @keyup="updateCrop($event, 'right')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Top"
                  name="croptop"
                  type="number"
                  min="0"
                  max="1080"
                  v-model="form.slots[i].cropTop"
                  :errors="errors"
                  @keyup="updateCrop($event, 'top')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Bottom"
                  name="cropbottom"
                  type="number"
                  min="0"
                  max="1080"
                  v-model="form.slots[i].cropBottom"
                  :errors="errors"
                  @keyup="updateCrop($event, 'bottom')"
                />
                <div class="flex items-start justify-between space-x-2">
                  <button
                    @click.prevent="editSlot(slot)"
                    class="group flex items-center justify-center rounded-full border border-grey-200 px-3 py-1 hover:border-blue-500"
                    :class="{
                      'border-blue-500':
                        isEdit && selectedSlot && selectedSlot == slot,
                    }"
                    :disabled="
                      selectedSlot &&
                      selectedSlot == slot &&
                      (selectedSlot.cropWidth === 0 ||
                        selectedSlot.cropHeight === 0)
                    "
                  >
                    <i
                      class="material-icons-outlined group-hover:text-blue-500"
                      :class="{
                        'text-blue-500':
                          isEdit && selectedSlot && selectedSlot == slot,
                      }"
                    >
                      check
                    </i>
                  </button>
                </div>
              </div>
              <div v-else class="flex w-full justify-between space-x-4">
                <MdTextField
                  class="mr-4 w-8"
                  label="Slot"
                  name="slotNo"
                  type="number"
                  min="1"
                  v-model="form.slots[i].slotNo"
                  :errors="errors"
                  :disabled="isSystemDefault"
                />

                <button
                  @click.prevent="toggleSlotRatioLock(i)"
                  class="-mt-3 flex flex-col items-center self-start justify-self-center"
                >
                  <div class="text-xs text-grey-400">Ratio</div>
                  <i
                    v-if="form.slots[i].ratio !== null"
                    class="material-icons-outlined pt-3 text-icon-sm text-black"
                    >lock</i
                  >
                  <i
                    v-else
                    class="material-icons-outlined pt-3 text-icon-sm text-grey-400"
                    >lock_open</i
                  >
                </button>

                <MdTextField
                  class="mr-1 flex-1"
                  label="Width"
                  name="width"
                  type="number"
                  min="0"
                  max="1920"
                  v-model="form.slots[i].width"
                  :errors="errors"
                  @keyup="updateSize(slot, 'width')"
                />
                <MdTextField
                  class="mr-1 flex-1"
                  label="Height"
                  name="height"
                  type="number"
                  min="0"
                  max="1080"
                  v-model="form.slots[i].height"
                  :errors="errors"
                  @keyup="updateSize(slot, 'height')"
                />
                <MdTextField
                  class="flex-1"
                  label="Position X"
                  name="posx"
                  type="number"
                  min="0"
                  max="1920"
                  v-model="form.slots[i].x"
                  :errors="errors"
                  :disabled="isSystemDefault"
                  @keyup="updatePositionX(slot)"
                />
                <MdTextField
                  class="flex-1"
                  label="Positon Y"
                  name="posy"
                  type="number"
                  min="0"
                  max="1080"
                  v-model="form.slots[i].y"
                  :errors="errors"
                  :disabled="isSystemDefault"
                  @keyup="updatePositionY(slot)"
                />
                <div class="flex items-start justify-between space-x-2">
                  <button
                    @click.prevent="editSlot(slot)"
                    :disabled="isSystemDefault"
                    class="group flex items-center justify-center rounded-full border border-grey-200 px-3 py-1 hover:border-blue-500"
                    :class="{
                      'border-blue-500':
                        isEdit && selectedSlot && selectedSlot == slot,
                    }"
                  >
                    <i
                      class="material-icons-outlined group-hover:text-blue-500"
                      :class="{
                        'text-blue-500':
                          isEdit && selectedSlot && selectedSlot == slot,
                      }"
                    >
                      picture_in_picture
                    </i>
                  </button>
                  <button
                    @click.prevent="removeSlot(i)"
                    :disabled="isSystemDefault"
                    class="group flex items-center justify-center rounded-full border border-grey-200 px-3 py-1 hover:border-red-500"
                  >
                    <i class="material-icons-outlined group-hover:text-red-500"
                      >delete</i
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isSystemDefault">
            <SubmitButton :disabled="!saveable" :saving="saving" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapState } from 'vuex';
import errors from '@/mixins/errors';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';

import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
  mixins: [errors],

  name: 'LayoutPage',

  components: {
    MdTextField,
    MdTextareaField,
    TooltipModal,
    SubmitButton,
  },

  data: () => ({
    saving: false,
    error: null,
    isEdit: false,
    isDraggable: false,
    bounds: null,
    form: {
      name: null,
      description: null,
      slots: [],
    },
    defaultSlot: {
      cropWidth: 1920,
      cropHeight: 1080,
      cropBottom: 0,
      cropLeft: 0,
      cropRight: 0,
      cropTop: 0,
      opacity: 1,
      slotNo: 0,
      width: 1280,
      height: 720,
      x: 0,
      y: 0,
      previewX: 0,
      previewY: 0,
      ratio: 16 / 9,
      xScaleRatio: 1920 / 1280,
      yScaleRatio: 1080 / 720,
    },
    selectedSlot: null,
    cropper: null,
    isReadyToModify: false,
  }),

  computed: {
    ...mapState(['windowWidth', 'windowHeight']),
    isNewLayout() {
      return this.$route.params?.id ? false : true;
    },
    saveable() {
      return (
        this.isEdit == false && this.form.name && this.form.slots.length > 0
      );
    },
    offsetWidth() {
      return this.$refs.layout.offsetWidth;
    },
    offsetHeight() {
      return this.$refs.layout.offsetHeight;
    },
    isSystemDefault() {
      return this.form.type == 'fixed';
    },
    title() {
      return this.isNewLayout
        ? 'Create new layout'
        : this.$route.query.clone
        ? 'Clone layout'
        : 'Edit layout';
    },
    isReachMaxSlots() {
      const { account, accountTypes } = this.$store.state.sessionData;
      const accountType = accountTypes.find((at) => at.id == account.type);
      return this.form.slots.length >= accountType.maxLiveSources;
    },
  },

  async mounted() {
    await this.init();

    window.addEventListener('keydown', this.adjustSelectedSlotPosition);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.adjustSelectedSlotPosition);
  },

  methods: {
    async init() {
      if (this.$route.params.id) {
        try {
          const res = await api.get(
            `/account/modes/${this.$route.params.type}/${this.$route.params.id}`
          );
          if (res.status >= 200 && res.status < 300) {
            this.form = res.data;

            if (this.$route.query.clone) {
              delete this.form['id'];

              this.form.name += ' copy';
              this.form.type = null;

              if (this.$route.params.type == 'fixed') {
                //remove description
                this.form.description = null;
              }
            }

            //update with slot preview x, y
            //this.form.previewX = this.form.x;
            if (this.form.slots !== null) {
              for (var i = 0; i < this.form.slots.length; i++) {
                const cropWidth =
                  this.windowWidth -
                  this.form.slots[i].cropLeft -
                  this.form.slots[i].cropRight;
                const cropHeight =
                  this.windowHeight -
                  this.form.slots[i].cropTop -
                  this.form.slots[i].cropBottom;
                this.form.slots[i] = {
                  ...this.form.slots[i],
                  previewX: Math.floor(
                    (+this.form.slots[i].x * this.offsetWidth) /
                      this.windowWidth
                  ),
                  previewY: Math.floor(
                    (+this.form.slots[i].y * this.offsetHeight) /
                      this.windowHeight
                  ),
                  cropWidth: cropWidth,
                  cropHeight: cropHeight,
                  ratio: this.form.slots[i].width / this.form.slots[i].height,
                  xScaleRatio: cropWidth / this.form.slots[i].width,
                  yScaleRatio: cropHeight / this.form.slots[i].height,
                };
              }
            } else {
              this.form.slots = [];
            }
          }
        } catch (e) {
          this.error = e.message || e;
          console.error(e);
        }
      }
    },
    initCropper() {
      this.isReadyToModify = false;

      if (this.cropper) {
        this.cropper.destroy();
      }

      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: this.selectedSlot.width / this.selectedSlot.height,
        zoomable: false,
        crop: (event) => {
          // TBC
          // I suspect it is the border. which caused +/-2px offset
          // force crop to 0 when over the boundary.
          //there should be better solution for it.
          if (this.isReadyToModify == false) return;

          this.selectedSlot.cropLeft =
            this.windowWidth == event.detail.width || event.detail.x < 0
              ? 0
              : Math.round(event.detail.x);
          this.selectedSlot.cropTop =
            this.windowHeight == event.detail.height || event.detail.y < 0
              ? 0
              : Math.round(event.detail.y);

          this.selectedSlot.cropRight = Math.round(
            this.windowWidth <= event.detail.width + this.selectedSlot.cropLeft
              ? 0
              : Math.round(
                  this.windowWidth -
                    event.detail.width -
                    this.selectedSlot.cropLeft
                )
          );
          this.selectedSlot.cropBottom = Math.round(
            this.windowHeight <= event.detail.height + this.selectedSlot.cropTop
              ? 0
              : Math.round(
                  this.windowHeight -
                    event.detail.height -
                    this.selectedSlot.cropTop
                )
          );

          this.selectedSlot.cropWidth = Math.floor(event.detail.width);
          this.selectedSlot.cropHeight = Math.floor(event.detail.height);
        },
        ready: () => {
          this.selectedSlot.cropWidth =
            this.windowWidth -
            this.selectedSlot.cropLeft -
            this.selectedSlot.cropRight;
          this.selectedSlot.cropHeight =
            this.windowHeight -
            this.selectedSlot.cropTop -
            this.selectedSlot.cropBottom;
          this.cropper.setData({
            width: this.selectedSlot.cropWidth,
            height: this.selectedSlot.cropHeight,
            x: +this.selectedSlot.cropLeft,
            y: +this.selectedSlot.cropTop,
          });

          this.isReadyToModify = true;
        },
      });
    },
    addNewSlot() {
      if (!this.isReachMaxSlots) {
        const slot = { ...this.defaultSlot };

        //find the highest slotNo then add 1
        slot.slotNo =
          (Math.max(...this.form.slots.map((slot) => +slot.slotNo), 0) || 0) +
          1;
        this.form.slots.push(slot);
      }
    },
    editSlot(slot) {
      if (this.isEdit) {
        //confirm
        if (
          this.selectedSlot.cropWidth === 0 ||
          this.selectedSlot.cropHeight === 0
        ) {
          return;
        }
      }

      this.isEdit = !this.isEdit;

      if (this.isEdit) {
        this.selectedSlot = slot;

        this.$nextTick(() => {
          this.initCropper();
        });
      } else {
        this.selectedSlot.xScaleRatio =
          Math.round(
            (this.selectedSlot.cropWidth / this.selectedSlot.width) * 100
          ) / 100;
        this.selectedSlot.yScaleRatio =
          Math.round(
            (this.selectedSlot.cropHeight / this.selectedSlot.height) * 100
          ) / 100;
        this.selectedSlot.ratio =
          Math.round(
            (this.selectedSlot.width / this.selectedSlot.height) * 100
          ) / 100;

        this.cropper.destroy();

        this.selectedSlot = null;
      }
    },
    removeSlot(index) {
      this.form.slots.splice(index, 1);

      //reset slot z-index after remove slot
      if (this.form.slots.length > 0) {
        this.form.slots.map((slot, index) => {
          slot.slotNo = index + 1;
          return slot;
        });
      }
    },
    selectSlot(slot) {
      if (this.isSystemDefault) return;

      this.selectedSlot = slot;
    },
    setSlotDrag(e, slot, i) {
      if (this.isSystemDefault) return;

      this.isDraggable = true;
      if (this.selectedSlot != slot) {
        this.selectedSlot = slot;
      }

      this.bounds = {
        x: this.$refs.slot[i].offsetLeft - e.clientX,
        y: this.$refs.slot[i].offsetTop - e.clientY,
        width: this.$refs.slot[i].offsetWidth,
        height: this.$refs.slot[i].offsetHeight,
      };
    },
    unsetSlotDrag() {
      this.isDraggable = false;
    },
    slotDrag(e) {
      e.preventDefault();

      if (this.isDraggable) {
        const posX = e.clientX + this.bounds.x;
        const posY = e.clientY + this.bounds.y;

        if (posX < 0) {
          this.selectedSlot.previewX = 0;
        } else if (posX > this.offsetWidth - this.bounds.width) {
          this.selectedSlot.previewX = this.offsetWidth - this.bounds.width;
        } else {
          this.selectedSlot.previewX = posX;
        }

        if (posY < 0) {
          this.selectedSlot.previewY = 0;
        } else if (posY > this.offsetHeight - this.bounds.height) {
          this.selectedSlot.previewY = this.offsetHeight - this.bounds.height;
        } else {
          this.selectedSlot.previewY = posY;
        }

        const x = Math.floor(
          (this.windowWidth / this.offsetWidth) * this.selectedSlot.previewX
        );
        const y = Math.floor(
          (this.windowHeight / this.offsetHeight) * this.selectedSlot.previewY
        );

        this.selectedSlot.x = x;
        this.selectedSlot.y = y;
      }
    },
    toggleSlotRatioLock(i) {
      //TODO
      //leave this updateSize function here to activate slot
      //which assign vue observer to object attribute
      //this is just a work around.

      //vue observer was not assigned to this slot attribute for some reason
      //even slot had been clean re-assigned with spread operator

      this.updateSize(this.form.slots[i], 'width');

      if (this.form.slots[i].ratio !== null) {
        this.form.slots[i].ratio = null;
      } else {
        this.form.slots[i].ratio =
          Math.round(
            (this.form.slots[i].width / this.form.slots[i].height) * 100
          ) / 100;
      }
    },
    updateSize(slot, type) {
      //lock with aspect ratio
      if (this.selectedSlot != slot) {
        this.selectedSlot = slot;
      }

      if (this.selectedSlot.ratio) {
        if (type == 'width') {
          this.selectedSlot.height = Math.floor(
            this.selectedSlot.width / this.selectedSlot.ratio
          );
        } else if (type == 'height') {
          this.selectedSlot.width = Math.floor(
            this.selectedSlot.height * this.selectedSlot.ratio
          );
        }
      }

      this.selectedSlot.cropWidth = Math.floor(
        this.selectedSlot.xScaleRatio * this.selectedSlot.width
      );
      this.selectedSlot.cropRight = Math.floor(
        this.windowWidth -
          this.selectedSlot.cropLeft -
          this.selectedSlot.cropWidth
      );
      this.selectedSlot.cropHeight = Math.floor(
        this.selectedSlot.yScaleRatio * this.selectedSlot.height
      );
      this.selectedSlot.cropBottom = Math.floor(
        this.windowHeight -
          this.selectedSlot.cropTop -
          this.selectedSlot.cropHeight
      );
    },
    updatePositionX(slot) {
      if (this.selectedSlot != slot) {
        this.selectedSlot = slot;
      }

      this.selectedSlot.previewX = Math.floor(
        (+this.selectedSlot.x * this.offsetWidth) / this.windowWidth
      );
    },
    updatePositionY(slot) {
      if (this.selectedSlot != slot) {
        this.selectedSlot = slot;
      }

      this.selectedSlot.previewY = Math.floor(
        (+this.selectedSlot.y * this.offsetHeight) / this.windowHeight
      );
    },
    updateCrop(e, type) {
      //assumption
      //update width will fix left. and update right
      //update height will fix top and update bottom
      //update crop left/right will keep the width but move right/left accordingly
      //update crop top/bottom will keep the height but move bottom/top accordingly

      //enable/disable "Enter" to update?
      //if(e.keyCode == 13) {
      if (type == 'width') {
        this.cropper.setData({
          width: +this.selectedSlot.cropWidth,
        });
      } else if (type == 'height') {
        this.cropper.setData({
          height: +this.selectedSlot.cropHeight,
        });
      } else if (type == 'right') {
        this.selectedSlot.cropLeft =
          this.windowWidth -
          this.selectedSlot.cropWidth -
          this.selectedSlot.cropRight;
        this.cropper.setData({
          x: +this.selectedSlot.cropLeft,
        });
      } else if (type == 'bottom') {
        this.selectedSlot.cropTop =
          this.windowHeight -
          this.selectedSlot.cropHeight -
          this.selectedSlot.cropBottom;
        this.cropper.setData({
          y: +this.selectedSlot.cropTop,
        });
      }
      //}
    },
    getWidth(slot) {
      return slot.width / (this.windowWidth / this.offsetWidth) + 'px';
    },
    getHeight(slot) {
      return slot.height / (this.windowHeight / this.offsetHeight) + 'px';
    },
    getSlotBackgroundSizePosition(slot) {
      const width =
        (((-slot.cropLeft * slot.width) /
          (this.windowWidth - slot.cropLeft - slot.cropRight)) *
          this.offsetWidth) /
        this.windowWidth;
      const height =
        (((-slot.cropTop * slot.height) /
          (this.windowHeight - slot.cropTop - slot.cropBottom)) *
          this.offsetHeight) /
        this.windowHeight;

      return `${width}px ${height}px`;
    },
    getSlotBackgroundSize(slot) {
      const width =
        (slot.width / (this.windowWidth - slot.cropLeft - slot.cropRight)) *
        this.offsetWidth;
      const height =
        (slot.height / (this.windowHeight - slot.cropTop - slot.cropBottom)) *
        this.offsetHeight;

      return `${width}px ${height}px`;
    },
    async save() {
      this.saving = true;

      const res = await api.put(
        '/account/modes' + (this.form.id ? '/' + this.form.id : ''),
        this.form
      );
      if (res.status >= 200 && res.status < 300) {
        this.saving = false;
        this.$store.commit('MESSAGE', `${this.form.name} has been added.`);
        this.$router.replace('/settings/layouts');
      }
    },
    adjustSelectedSlotPosition(e) {
      if (
        ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.code)
      ) {
        e.preventDefault();
      } else {
        return;
      }

      if (!this.selectedSlot) return;

      if (!this.isEdit) {
        if (e.code == 'ArrowLeft' && this.selectedSlot.previewX > 0) {
          this.selectedSlot.previewX -= 1;
        } else if (
          e.code == 'ArrowRight' &&
          this.selectedSlot.previewX < this.offsetWidth - this.bounds.width
        ) {
          this.selectedSlot.previewX += 1;
        } else if (e.code == 'ArrowUp' && this.selectedSlot.previewY > 0) {
          this.selectedSlot.previewY -= 1;
        } else if (
          e.code == 'ArrowDown' &&
          this.selectedSlot.previewY < this.offsetHeight - this.bounds.height
        ) {
          this.selectedSlot.previewY += 1;
        }

        this.selectedSlot.x = Math.floor(
          (this.windowWidth / this.offsetWidth) * this.selectedSlot.previewX
        );
        this.selectedSlot.y = Math.floor(
          (this.windowHeight / this.offsetHeight) * this.selectedSlot.previewY
        );
      } else {
        if (e.code == 'ArrowLeft') {
          this.selectedSlot.cropLeft -= 1;
          this.selectedSlot.cropRight += 1;
        } else if (e.code == 'ArrowRight') {
          this.selectedSlot.cropLeft += 1;
          this.selectedSlot.cropRight -= 1;
        } else if (e.code == 'ArrowUp') {
          this.selectedSlot.cropTop -= 1;
          this.selectedSlot.cropBottom += 1;
        } else if (e.code == 'ArrowDown') {
          this.selectedSlot.cropTop += 1;
          this.selectedSlot.cropBottom -= 1;
        }

        this.cropper.setData({
          width: +this.selectedSlot.cropWidth,
          height: +this.selectedSlot.cropHeight,
          x: +this.selectedSlot.cropLeft,
          y: +this.selectedSlot.cropTop,
        });
      }
    },
  },
};
</script>

<style>
.layout {
  --width: 100%;
  width: var(--width);
  padding-top: calc(9 / 16 * var(--width));
  position: relative;
  background: #f8f8f8;
}
.layout > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.slot {
  position: absolute;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #e0e0e0;
  font-size: 1.2em;
  color: rgb(255, 255, 255);
}
.selected {
  background: #6edc9d !important;
}
.done {
  background: #219653;
}
h4 {
  text-align: left;
  margin-top: 5px;
}

.cropper-container {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
