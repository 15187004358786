<template>
  <div>
    <div class="pb-8 text-grey-400">
      Configure email notifications sent for your event.
    </div>

    <form v-show="event" class="mt-8" @submit.prevent="save()">
      <div class="mb-6 text-lg font-medium">Presenter invitation</div>

      <div class="flex flex-auto">
        <div class="flex-1">
          <MdTextareaField
            name="presenter_invitation_email"
            label="Email text"
            v-model="form.presenterInvitationEmailText"
            :required="false"
            tooltip="This text will be included in the email sent to presenters when they are invited to present during this event."
            :errors="errors"
          />
        </div>
      </div>

      <div class="mb-6 pt-6 text-lg font-medium">
        Attendee registration confirmation
      </div>
      <div v-if="!can('event-registration')" class="mb-6">
        <UpgradePlanLink />
      </div>

      <div
        v-else-if="
          [
            'automatic_registration',
            'manual_registration',
            'pre_registration',
          ].includes(this.event.registrationType)
        "
      >
        <div class="flex flex-auto">
          <div class="flex-1">
            <MdTextField
              name="registration_confirmation_email_subject"
              label="Subject line"
              v-model="form.registrationConfirmationEmailSubject"
              :required="true"
              tooltip="The subject line for the attendee registration confirmation email."
              :errors="errors"
            />
          </div>
        </div>
        <div class="flex flex-auto">
          <div class="flex-1">
            <MdTextareaField
              name="registration_confirmation_email_text"
              label="Email text"
              v-model="form.registrationConfirmationEmailText"
              :required="true"
              tooltip="The body text for the second email reminder. This text will be included in the email sent to attendee when they complete registration for this event. The event schedule details and calendar invitations will be included automatically below this text."
              :errors="errors"
            />
          </div>
        </div>
      </div>
      <div v-else class="mb-6 text-grey-400">
        Attendee notifications are available when event access type is
        'Automatic registration', 'Manual registration' or 'Pre registration'.
        Change access type on the
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/settings'"
          class="text-blue-500"
        >
          General settings
        </RouterLink>
        page.
      </div>

      <div v-if="!can('event-registration')" class="mb-6">
        <UpgradePlanLink />
      </div>

      <div
        v-else-if="
          [
            'automatic_registration',
            'manual_registration',
            'pre_registration',
          ].includes(this.event.registrationType)
        "
      >
        <div class="mb-6 pt-6 text-lg font-medium">
          Attendee event reminder 1
        </div>
        <div class="flex flex-auto">
          <div class="flex-1">
            <MdSelectField
              class="flex-1"
              label="Enabled"
              name="reminder_email_1"
              v-model="form.reminderEmail1Enabled"
              :errors="errors"
              :options="[
                { id: true, label: 'Enabled' },
                { id: false, label: 'Disabled' },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Send a first reminder email to attendees in the days prior to the
              event. This email includes a calendar invitation.
            </template>
          </TooltipModal>
        </div>
        <div v-if="form.reminderEmail1Enabled">
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdTextField
                name="reminder_email_1_subject"
                label="Subject line"
                v-model="form.reminderEmail1Subject"
                :required="true"
                tooltip="The subject line for the first email reminder."
                :errors="errors"
              />
            </div>
          </div>
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdTextareaField
                name="reminder_email_1_text"
                label="Email text"
                v-model="form.reminderEmail1Text"
                :required="true"
                tooltip="The body text for the first email reminder. The event schedule details and calendar invitations will be included automatically below this text."
                :errors="errors"
              />
            </div>
          </div>
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdSelectField
                class="flex-1"
                label="Email schedule"
                name="reminder_email_1_interval"
                v-model="form.reminderEmail1Interval"
                :errors="errors"
                :options="[
                  { id: 24, label: '1 day before scheduled start' },
                  { id: 48, label: '2 days before scheduled start' },
                  { id: 72, label: '3 days before scheduled start' },
                  { id: 96, label: '4 days before scheduled start' },
                  { id: 120, label: '5 days before scheduled start' },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Select when this reminder email should be sent.
              </template>
            </TooltipModal>
          </div>
        </div>

        <div class="mb-6 pt-6 text-lg font-medium">
          Attendee event reminder 2
        </div>
        <div class="flex flex-auto">
          <div class="flex-1">
            <MdSelectField
              class="flex-1"
              label="Enabled"
              name="reminder_email_2"
              v-model="form.reminderEmail2Enabled"
              :errors="errors"
              :options="[
                { id: true, label: 'Enabled' },
                { id: false, label: 'Disabled' },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Send a second reminder email to attendees the day of the event.
              This email includes a calendar invitation.
            </template>
          </TooltipModal>
        </div>

        <div v-if="form.reminderEmail2Enabled">
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdTextField
                name="reminder_email_2_subject"
                label="Subject line"
                v-model="form.reminderEmail2Subject"
                :required="true"
                tooltip="The subject line for the second email reminder."
                :errors="errors"
              />
            </div>
          </div>
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdTextareaField
                name="reminder_email_2_text"
                label="Email text"
                v-model="form.reminderEmail2Text"
                :required="true"
                tooltip="The body text for the second email reminder. The event schedule details and calendar invitations will be included automatically below this text."
                :errors="errors"
              />
            </div>
          </div>
          <div class="flex flex-auto">
            <div class="flex-1">
              <MdSelectField
                class="flex-1"
                label="Email schedule"
                name="reminder_email_2_interval"
                v-model="form.reminderEmail2Interval"
                :errors="errors"
                :options="[
                  { id: 1, label: '1 hour before scheduled start' },
                  { id: 2, label: '2 hours before scheduled start' },
                  { id: 3, label: '3 hours before scheduled start' },
                  { id: 4, label: '4 hours before scheduled start' },
                  { id: 5, label: '5 hours before scheduled start' },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Select when this reminder email should be sent.
              </template>
            </TooltipModal>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-6 pt-6 text-lg font-medium">
          Attendee event reminders
        </div>
        <div class="mb-6 text-grey-400">
          Attendee notifications are available when event access type is
          'Automatic registration', 'Manual registration' or 'Pre registration'.
          Change access type on the
          <RouterLink
            :to="'/events/' + event.eventShortcode + '/settings'"
            class="text-blue-500"
          >
            General settings
          </RouterLink>
          page.
        </div>
      </div>

      <SubmitButton :disabled="!saveable" :saving="saving" />
    </form>
  </div>
</template>

<script>
import { pick, keys, assign } from 'lodash';
import { EventBus } from '@/services/event-bus';
import api from '@/services/api';
import errors from '@/mixins/errors';

import MdSelectField from '@/components/MdSelectField';
import TooltipModal from '@/components/TooltipModal';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';
import SubmitButton from '@/components/SubmitButton';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  mixins: [errors],

  components: {
    TooltipModal,
    MdSelectField,
    MdTextField,
    MdTextareaField,
    SubmitButton,
    UpgradePlanLink,
  },

  props: ['event'],

  data: () => ({
    form: {
      registrationConfirmationEmailSubject: null,
      registrationConfirmationEmailText: null,
      presenterInvitationEmailText: null,
      reminderEmail1Enabled: null,
      reminderEmail2Enabled: null,
      reminderEmail1Interval: null,
      reminderEmail2Interval: null,
      reminderEmail1Text: null,
      reminderEmail2Text: null,
      reminderEmail1Subject: null,
      reminderEmail2Subject: null,
    },
    saveable: false,
    saving: false,
  }),

  computed: {},

  mounted() {
    if (this.event) {
      this.setupForm();
    }
  },

  watch: {
    form: {
      handler() {
        this.saveable = true;
      },
      deep: true,
    },
  },

  methods: {
    setupForm() {
      assign(this.form, pick(this.event, keys(this.form)));
    },
    save() {
      this.saving = true;

      // TODO: Use the Vuex store for this!
      api
        .post(`/jobs/${this.event.id}/notifications`, this.form)
        .then((response) => {
          this.saving = false;
          EventBus.$emit('event-updated', response.data);
          this.notify('success', 'Event updated');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
