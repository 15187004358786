<template>
  <div class="flex flex-grow flex-col">
    <div v-if="!can('studio-source-stream')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="flex h-full flex-col">
          <!-- list -->
          <div class="flex flex-grow flex-col overflow-auto pt-6">
            <div
              v-if="getSrtEndpoints.length == 0"
              class="flex flex-1 justify-center text-grey-400"
            >
              Add SRT input streams using the button below
            </div>
            <div
              v-for="(record, index) in getSrtEndpoints"
              :key="index"
              @click="handleSelectStream(record)"
              @dblclick.prevent="handleAddStreamToSource(record)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              :class="{ 'bg-grey-100': selectedStream == record }"
            >
              <div class="flex flex-1 items-center space-x-2">
                <div class="font-medium">{{ record.name }}</div>
              </div>

              <div
                v-if="record.isActive == true"
                class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
              >
                Active
              </div>

              <DropdownMenu>
                <template slot="control" slot-scope="slotProps">
                  <button
                    @click.stop="handleDropdown(slotProps)"
                    class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">more_vert</i>
                  </button>
                </template>

                <template slot="menu">
                  <button
                    @click.prevent="handleCopyLink(record)"
                    class="dropdownListItem"
                  >
                    Copy link
                  </button>

                  <button
                    v-if="['rehearsal', 'live'].includes(event.eventState)"
                    @click.prevent="handleAddStreamToSource(record)"
                    :disabled="!isAllowAddSource"
                    class="dropdownListItem"
                  >
                    {{
                      isAllowAddSource
                        ? 'Add to sources'
                        : 'Max sources reached'
                    }}
                  </button>

                  <button
                    @click="navigateToRenameStream(record)"
                    class="dropdownListItem"
                  >
                    Rename
                  </button>

                  <button
                    @click="navigateToRemoveStream(record)"
                    :disabled="record.isActive"
                    class="dropdownListItem"
                  >
                    Delete
                  </button>
                </template>
              </DropdownMenu>
            </div>
          </div>

          <!-- selected preview -->
          <div
            class="flex flex-col justify-center border-t border-grey-200 pt-3"
          >
            <div v-if="selectedStream != null" class="flex-1 select-text">
              <div class="flex py-1">
                <h3 class="flex w-full items-center text-xl font-bold">
                  {{ selectedStream.name }}
                </h3>
                <button
                  class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  @click.prevent="selectedStream = null"
                >
                  <i class="material-icons-outlined">close</i>
                </button>
              </div>
              <div class="pb-5 text-sm text-grey-400">
                Stream details change each time you start rehearsal.
              </div>

              <div class="flex flex-col justify-center">
                <div>Stream details</div>
                <div v-if="['rehearsal', 'live'].includes(event.eventState)">
                  <div class="text-grey-400">Host: {{ coreGstSrtHost() }}</div>
                  <div class="text-grey-400">
                    Port: {{ selectedStream.port }}
                  </div>
                  <div class="text-grey-400">
                    Passphrase: {{ selectedStream.passphrase }}
                  </div>
                  <div class="text-grey-400">Key length: 32bit</div>
                  <div class="flex pt-4">
                    OBS URL
                    <button
                      @click.prevent="handleCopyLink(selectedStream)"
                      class="flex h-6 w-auto items-center justify-center rounded-full p-1 text-grey-500 hover:bg-grey-200"
                    >
                      <i class="material-icons-outlined text-sm text-grey-400"
                        >content_copy</i
                      >
                    </button>
                  </div>
                  <div class="text-grey-400">
                    {{
                      coreGstSrtHost() +
                      `:${selectedStream.port}?${selectedStream.query}`
                    }}
                  </div>
                </div>
                <div v-else class="text-grey-400">
                  Details available once in rehearsal.
                </div>

                <div class="pt-4">
                  Encoder settings (<a
                    class="text-blue-500"
                    target="_blank"
                    href="https://support.joinin.live/portal/en/kb/articles/adding-external-streams"
                    >View guide</a
                  >)
                </div>
                <div class="text-grey-400">Encoder: H264/AAC</div>
                <div class="text-grey-400">Resolution: 1920x1080</div>
                <div class="text-grey-400">Frames per second (FPS): 25</div>
                <div class="text-grey-400">Video bitrate: 4000kbps video</div>
                <div class="text-grey-400">Audio bitrate: 256kbps</div>
              </div>
              <button
                class="btn-blue mt-10"
                v-if="
                  ['rehearsal', 'live'].includes(event.eventState) &&
                  selectedStream.isActive == false
                "
                :disabled="!isAllowAddSource"
                @click.prevent="handleAddStreamToSource(selectedStream)"
              >
                {{
                  isAllowAddSource ? 'Add to sources' : 'Max sources reached'
                }}
              </button>
            </div>

            <button
              v-else
              class="btn-blue"
              @click.prevent="navigateToAddStream"
            >
              Add stream
            </button>
          </div>
        </div>
      </template>

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="py-6">
          <button
            class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3 class="-ml-10 w-full text-center text-2xl font-bold">
            {{ upperFirst(section) }}
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add stream -->
        <template v-if="section == 'add_stream'">
          <form class="flex flex-col px-3" @submit.prevent="handleAddStream">
            <a
              href="https://support.joinin.live/portal/en/kb/articles/adding-external-streams"
              target="_blank"
              class="flex justify-end pb-4"
            >
              <p class="text-right text-sm text-blue-500">
                Recommended stream settings
              </p>
              <i class="material-icons-outlined text pl-1 text-sm text-blue-500"
                >help_outline</i
              >
            </a>

            <TextField
              name="new_stream_name"
              label="Stream name"
              v-model="newStreamName"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" id="btn_add_stream" class="btn-blue">
              Add stream
            </button>
          </form>
        </template>
        <!-- end of add stream -->

        <!-- delete stream -->
        <template v-if="section == 'delete_stream'">
          <div class="px-6">
            <h4 class="text-center text-lg font-bold">
              {{ selectedStream.name }}
            </h4>
            <div class="mt-10 text-center">
              Are you sure you want to delete this stream? It will be removed
              from your event and any publish details will change if you choose
              to add it again.
            </div>

            <button class="btn-red mt-10" @click.prevent="handleRemoveStream">
              Delete
            </button>
          </div>
        </template>
        <!-- end of delete stream -->

        <!-- rename stream -->
        <template v-if="section == 'rename_stream'">
          <form class="flex flex-col px-3" @submit.prevent="handleRenameStream">
            <h4 class="text-center text-lg font-bold">
              {{ selectedStream.name }}
            </h4>
            <TextField
              name="stream_name"
              label="Name"
              v-model="name"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" class="btn-blue">Rename stream</button>
          </form>
        </template>
        <!-- end of rename stream -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import DropdownMenu from '@/components/DropdownMenu';
import TextField from '@/components/TextField';
import errors from '@/mixins/errors';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  name: 'StudioPanelStreams',
  mixins: [errors],
  components: {
    DropdownMenu,
    TextField,
    UpgradePlanLink,
  },
  data: () => ({
    selectedStream: null,
    section: null,
    newStreamName: null,
    prevDropdown: null,
    name: null,
  }),
  computed: {
    ...mapState(['srtEndpoints', 'sources', 'event', 'isAllowAddSource']),
    ...mapGetters(['upperFirst']),
    getSrtEndpoints() {
      return this.srtEndpoints
        .map((srt) => {
          srt.isActive = false;

          var source = this.sources.find((s) => s.srtId == srt.id);
          if (source) {
            srt.isActive = true;
          }

          return srt;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  },

  methods: {
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    navigateToAddStream() {
      this.section = 'add_stream';
    },
    navigateToRemoveStream(stream) {
      this.selectedStream = stream;
      this.section = 'delete_stream';
    },
    navigateToRenameStream(stream) {
      this.selectedStream = stream;
      this.name = stream.name;

      this.section = 'rename_stream';
    },
    handleRenameStream() {
      this.$store.dispatch('RENAME_MEDIA', {
        type: 'SRT',
        id: this.selectedStream.id,
        name: this.name,
      });

      this.section = null;
      this.selectedStream = null;
      this.name = null;
    },
    handleAddStream() {
      this.$store.dispatch('ADD_SRT_ENDPOINT', {
        name: this.newStreamName,
      });

      this.newStreamName = null;
      this.section = null;
    },
    handleRemoveStream() {
      this.$store.dispatch('REMOVE_SRT_ENDPOINT', {
        id: this.selectedStream.id,
      });

      this.selectedStream = null;
      this.section = null;
    },
    handleSelectStream(stream) {
      this.$nextTick(() => {
        this.selectedStream = stream;
      });
    },
    handleAddStreamToSource(stream) {
      if (this.isAllowAddSource) {
        this.$store.dispatch('ADD_SOURCE', {
          name: stream.name,
          type: 'SRT',
          hasPreview: true,
          srtId: stream.id,
        });
        this.selectedStream = null;
      }
    },
    handleCopyLink(stream) {
      navigator.clipboard.writeText(
        `${this.coreGstSrtHost()}:${stream.port}?${stream.query}`
      );

      this.$store.commit(
        'MESSAGE',
        `SRT Endpoint link has been copied to the clipboard.`
      );
    },
    coreGstSrtHost() {
      const coreGstHost = this.event.coreGstHost;
      let regex = /https:/gi;
      return coreGstHost.replace(regex, 'srt:');
    },
  },
};
</script>
