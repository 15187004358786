<template>
  <div class="w-full">
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full overflow-x-auto border-b border-grey-100 md:relative"
      >
        <RouterLink
          to="/dashboard"
          class="flex-1 cursor-pointer whitespace-nowrap p-4 text-center text-grey-400 hover:bg-grey-100"
          exact-active-class="border-b-2 border-blue-800 text-grey-800 bg-grey-100"
          >Scheduled
        </RouterLink>
        <RouterLink
          to="/dashboard/archived"
          class="flex-1 cursor-pointer whitespace-nowrap p-4 text-center text-grey-400 hover:bg-grey-100"
          exact-active-class="border-b-2 border-blue-800 text-grey-800 bg-grey-100"
          >Completed
        </RouterLink>
      </div>
    </div>
    <div class="p-4 md:p-8">
      <RouterView :events="events" :loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(['events']),
  },
  mounted() {
    this.$store.commit('RESET_JOB');
    this.$store.dispatch('GET_JOBS');
  },
};
</script>
