<template>
  <li
    class="flex justify-between border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
  >
    <div
      class="flex flex-1 flex-auto flex-col items-start justify-center"
      @dblclick.prevent="handlePresenterUp"
    >
      <h4 class="flex flex-row font-medium">
        {{ presenter.name }}
        <div
          v-if="presenter.forceBitrate != 0"
          class="ml-3 flex flex-row items-center justify-start rounded border border-orange-500 px-1 text-xs text-orange-500"
        >
          <i class="material-icons-outlined text-xs text-orange-500">lock</i>
          {{
            presenter.forceBitrate > 1500000
              ? presenter.forceBitrate / 1000000 + 'mb'
              : presenter.forceBitrate / 1000 + 'kb'
          }}
        </div>
        <div
          v-if="
            assignedPlaylist && assignedPlaylist.allowPresenterControl == true
          "
          class="ml-3 flex flex-row items-center justify-start rounded border border-green-500 px-1 text-xs text-green-500"
        >
          <i class="material-icons-outlined text-xs text-green-500">layers</i>
        </div>
        <div
          v-if="
            assignedPlaylist && assignedPlaylist.allowPresenterControl == false
          "
          class="ml-3 flex flex-row items-center justify-start rounded border border-red-500 px-1 text-xs text-red-500"
        >
          <i class="material-icons-outlined text-xs text-red-500"
            >layers_clear</i
          >
        </div>
      </h4>
      <div v-if="isOnline" class="mb-0 flex flex-row items-center">
        <NetworkIndicator
          :bitrateList="bitrateList"
          :bitrate="presenter.bitrate"
        />
        <div v-if="presenter.muted" class="mx-2 text-sm text-red-500">
          Muted
        </div>
        <div v-else class="mx-2 text-sm text-grey-400">Unmuted</div>
      </div>
      <div v-else class="text-grey-400">Offline</div>
    </div>

    <!-- active presenters -->
    <div class="flex items-start justify-end">
      <TooltipModal
        v-if="
          type == 'active' &&
          actionControl &&
          ['rehearsal', 'live'].includes(event.eventState)
        "
        triggerType="hover"
      >
        <template slot="control">
          <button
            @click.once="handlePresenterDown"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">download</i>
          </button>
        </template>

        <template slot="content">
          <div>
            <span class="font-bold text-red-500">Remove this Presenter</span>
            from the Live room and remove their active source.
          </div>
        </template>
      </TooltipModal>

      <TooltipModal
        v-if="
          type != 'active' &&
          actionControl &&
          ['rehearsal', 'live'].includes(event.eventState)
        "
        triggerType="hover"
      >
        <template slot="control">
          <button
            :disabled="!isAllowAddSource"
            @click.once="handlePresenterUp"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">upload</i>
          </button>
        </template>

        <template slot="content">
          <div v-if="isAllowAddSource">
            <span class="font-bold text-blue-500">Add this Presenter</span> to
            the Live Room and as an active source.
          </div>
          <div v-else>
            You have reached the maximum number of live sources for this event.
            Remove an existing source to add this Presenter.
          </div>
        </template>
      </TooltipModal>

      <DropdownMenu ref="dropdown">
        <template slot="control" slot-scope="slotProps">
          <button
            @click.prevent="slotProps.toggle()"
            class="ml-1 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">more_vert</i>
          </button>
        </template>

        <template slot="menu">
          <button @click.prevent="handleShareLink" class="dropdownListItem">
            Copy link
          </button>

          <button
            @click.prevent="$emit('onrename', presenter)"
            class="dropdownListItem"
          >
            Rename
          </button>

          <button
            v-if="actionControl"
            @click.prevent="$emit('onlock', presenter)"
            :disabled="!isOnline"
            class="dropdownListItem"
          >
            Lock bitrate
          </button>

          <button
            v-if="actionControl"
            @click.prevent="handleMutePresenter(!presenter.muted)"
            :disabled="!isOnline"
            class="dropdownListItem"
          >
            {{ presenter.muted ? 'Unmute' : 'Mute' }}
          </button>

          <button
            v-if="actionControl && isOnline"
            @click.prevent="$emit('onkick', presenter)"
            class="dropdownListItem"
          >
            Log out presenter
          </button>

          <button
            v-if="actionControl"
            @click.prevent="$emit('onremove', presenter)"
            class="dropdownListItem"
            :disabled="isPromoted"
          >
            Delete
          </button>
        </template>
      </DropdownMenu>
    </div>
  </li>
</template>

<script>
import DropdownMenu from '@/components/DropdownMenu';
import TooltipModal from '@/components/TooltipModal';
import NetworkIndicator from '@/components/NetworkIndicator';
import { mapState } from 'vuex';

export default {
  components: {
    DropdownMenu,
    TooltipModal,
    NetworkIndicator,
  },
  name: 'StudioPanelPresenter',
  props: {
    presenter: { type: Object, required: true },
    type: { type: String, required: true },
    actionControl: { type: Boolean, required: true },
    isPromoted: { type: Boolean, required: true },
    isOnline: { type: Boolean, required: true },
  },
  computed: {
    ...mapState(['isAllowAddSource', 'bitrateList', 'sources', 'event']),
    assignedPlaylist() {
      var source = this.sources.find(
        (s) => s.plCtrlPresenterId && s.plCtrlPresenterId == this.presenter.pid
      );
      if (source) {
        return source;
      } else {
        return null;
      }
    },
  },
  methods: {
    handlePresenterUp() {
      if (!this.disabled && this.isAllowAddSource) {
        this.$store.dispatch('ADD_PRESENTER_SOURCE', {
          name: this.presenter.name,
          pid: this.presenter.pid,
        });
      } else {
        this.$store.comit(
          'Error',
          "You've reached the maximum number of presenters for your event type."
        );
      }
    },
    handlePresenterDown() {
      this.$store.dispatch('REMOVE_PRESENTER_SOURCE', {
        pid: this.presenter.pid,
      });
    },
    handleShareLink() {
      navigator.clipboard.writeText(
        'https://share.joinin.live/' + this.presenter.urlCode
      );

      this.$store.commit(
        'MESSAGE',
        `Presenter login link has been copied to clipboard.`
      );

      this.$refs.dropdown.close();
    },
    handleMutePresenter(muted) {
      this.$store.dispatch('TOGGLE_PRESENTER_MUTE', {
        pid: this.presenter.pid,
        muted: muted,
      });
    },
  },
};
</script>

<style>
.network_status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.network_status span {
  width: 4px;
  height: 10px;
  margin-right: 2px;
}
</style>
