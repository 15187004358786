<template>
  <label class="flex cursor-pointer items-center space-x-2">
    <i
      class="material-icons-outlined"
      :class="{ 'cursor-not-allowed': disabled }"
    >
      {{ value ? 'check_box' : 'check_box_outline_blank' }}
    </i>

    <input
      type="checkbox"
      class="hidden"
      :id="name"
      :name="name"
      autocomplete="off"
      :disabled="disabled"
      :checked="value"
      @input="handleInput"
    />

    <span v-if="label" :class="{ 'cursor-not-allowed': disabled }">{{
      label
    }}</span>
  </label>
</template>

<script>
export default {
  name: 'MaterialIconsCheckbox',
  props: {
    name: { type: String },
    value: { type: Boolean },
    label: { type: String },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    handleInput() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
