<template>
  <div v-if="streamStartTime">
    <!-- <div class="flex-1 pb-8">
            <div class="font-medium text-2xl">Statistics</div>
            <div class="flex-1 text-grey-400">View event statistics</div>
        </div> -->

    <div
      class="border-b border-grey-200 pb-12"
      v-if="['vod_open', 'vod_closed'].includes(event.config.eventState)"
    >
      <div class="text-xl">Overview</div>
      <div class="pb-8 text-grey-400">
        Analytics for your entire event combining the Live Stream and Video On
        Demand.
      </div>
      <div class="flex flex-1 flex-col space-y-4 py-4 lg:flex-row lg:space-y-0">
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Unique attendees</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of individual attendees who have logged into your
                portal.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ uniqueViewers }}</div>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Chat messages</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of chat messages submitted by attendees.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ messageCount }}</div>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Questions</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of questions submitted by attendees.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ questionCount }}</div>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Poll votes</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of poll votes submitted by attendees.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ totalVoteCount }}</div>
        </div>
      </div>
    </div>

    <div
      class="pb-12"
      :class="{
        'pt-12': ['vod_open', 'vod_closed'].includes(event.config.eventState),
      }"
    >
      <div class="text-xl">Live Stream</div>
      <div class="pb-8 text-grey-400">
        Analytics for your live event between
        {{ formattedTime(streamStartTime) }} and
        {{ formattedTime(streamStopTime) }}:
      </div>

      <div
        class="flex flex-1 flex-col space-y-4 py-4 pb-12 lg:flex-row lg:space-y-0"
      >
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Unique attendees</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of individual attendees who have logged into your
                portal during the live stream.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ streamUniqueViewers }}</div>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Peak attendees</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The highest number of attendees who were logged into your portal
                simultaneously during the live stream.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ streamPeakViewers }}</div>
        </div>
        <div
          v-if="event.config.eventState == 'live'"
          class="flex flex-1 flex-col"
        >
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Live attendees</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The number of attendees logged in to your portal right now.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ streamLiveViewers }}</div>
        </div>
      </div>

      <div class="flex flex-1 flex-col space-y-0 py-4 lg:flex-row">
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2 pb-6">
            <div class="flex font-bold">Attendees access timeline</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                Number of active attendees accessing the portal over the Live
                event period. View where attendees access and departure trends.
              </template>
            </TooltipModal>
          </div>
          <highcharts :options="streamChartOptions"></highcharts>
        </div>
      </div>

      <div class="flex flex-1 flex-col space-y-4 py-4 lg:flex-row lg:space-y-0">
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Total runtime</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The total amount of time between the event being made Live and
                being ended.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ streamRunTime }}</div>
        </div>
      </div>

      <div class="flex flex-1 flex-col space-y-4 py-4 lg:flex-row lg:space-y-0">
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Average minutes watched</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The average number of minutes watched by a single attendee.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">{{ averageMinutesWatched }}</div>
        </div>
        <div class="flex flex-1 flex-col">
          <div class="flex items-start space-x-2">
            <div class="flex font-bold">Total minutes watched</div>

            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1">
                  <i class="material-icons-outlined text-md">info</i>
                </button>
              </template>

              <template slot="content">
                The total number of minutes watched by all attendees.
              </template>
            </TooltipModal>
          </div>
          <div class="text-2xl text-grey-400">
            {{ streamMinutesWatched.toFixed(2) }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="event.config.vodEnabled">
      <div class="border-t border-grey-200 pt-12 text-xl">Video On Demand</div>
      <div
        v-if="!['vod_open', 'vod_closed'].includes(event.config.eventState)"
        class="text-grey-400"
      >
        On demand statistics will be available once the on demand portal has
        been opened from Studio.
      </div>

      <div v-else>
        <div class="pb-8 text-grey-400">
          Analytics for your on-demand portal between
          {{ formattedTime(vodStartTime) }} and
          {{ formattedTime(vodStopTime) }}:
        </div>

        <div
          class="flex flex-1 flex-col space-y-4 py-4 lg:flex-row lg:space-y-0"
        >
          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2">
              <div class="flex font-bold">Unique attendees</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  The number of individual attendees who have logged into your
                  portal.
                </template>
              </TooltipModal>
            </div>
            <div class="text-2xl text-grey-400">{{ vodUniqueViewers }}</div>
          </div>

          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2">
              <div class="flex font-bold">Peak attendees</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  The highest number of attendees who were logged into your
                  portal simultaneously.
                </template>
              </TooltipModal>
            </div>
            <div class="text-2xl text-grey-400">
              {{ vodPeakViewers }}
            </div>
          </div>

          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2">
              <div class="flex font-bold">Live attendees</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  The number of attendees logged in to your portal right now.
                </template>
              </TooltipModal>
            </div>
            <div class="text-2xl text-grey-400">{{ vodLiveViewers }}</div>
          </div>
        </div>
        <div class="flex flex-1 flex-col space-y-0 py-4 lg:flex-row">
          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2 pb-6">
              <div class="flex font-bold">Attendees access timeline</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  Number of active attendees accessing the portal over the Live
                  event period. View where attendees access and departure
                  trends.
                </template>
              </TooltipModal>
            </div>
            <highcharts :options="vodChartOptions"></highcharts>
          </div>
        </div>

        <div
          class="flex flex-1 flex-col space-y-4 py-4 lg:flex-row lg:space-y-0"
        >
          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2">
              <div class="flex font-bold">Total on-demand runtime</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  The total amount of time between the event being made Live and
                  being ended.
                </template>
              </TooltipModal>
            </div>
            <div class="text-2xl text-grey-400">{{ vodRunTime }}</div>
          </div>
          <div class="flex flex-1 flex-col">
            <div class="flex items-start space-x-2">
              <div class="flex font-bold">Returning attendees</div>

              <TooltipModal>
                <template slot="control">
                  <button class="flex items-center space-x-1">
                    <i class="material-icons-outlined text-md">info</i>
                  </button>
                </template>

                <template slot="content">
                  The number of attendees who watched the live stream and
                  on-demand.
                </template>
              </TooltipModal>
            </div>
            <div class="text-2xl text-grey-400">
              {{ streamAndVodUniqueViewers }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="pb-8 text-grey-400">
      Statistics available once your event is live.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import { Chart } from 'highcharts-vue';

import { formatRuntime } from '@/helper/formatting.js';

import TooltipModal from '@/components/TooltipModal';

export default {
  components: {
    TooltipModal,
    highcharts: Chart,
  },

  data() {
    return {
      now: new Date(),
      timer: 0,
      runMinutes: 0,
      runSeconds: 0,
      list: [],
    };
  },

  computed: {
    // TODO: Is there some canonical way to do this?
    ...mapState(['event']),
    ...mapState({
      uniqueViewers: (s) => s.stats.uniqueViewers,
      streamUniqueViewers: (s) => s.stats.streamUniqueViewers,
      streamLiveViewers: (s) => s.stats.streamLiveViewers,
      streamPeakViewers: (s) => s.stats.streamPeakViewers,
      streamMinutesWatched: (s) => s.stats.streamMinutesWatched,
      questionCount: (s) => s.stats.questionCount,
      messageCount: (s) => s.stats.messageCount,
      totalVoteCount: (s) => s.stats.totalVoteCount,
      streamStartTime: (s) =>
        s.stats.streamStartTime ? new Date(s.stats.streamStartTime) : null,
      streamStopTime: (s) =>
        s.stats.streamStopTime ? new Date(s.stats.streamStopTime) : null,
      vodUniqueViewers: (s) => s.stats.vodUniqueViewers,
      vodLiveViewers: (s) => s.stats.vodLiveViewers,
      vodPeakViewers: (s) => s.stats.vodPeakViewers,
      streamAndVodUniqueViewers: (s) => s.stats.streamAndVodUniqueViewers,
      vodStartTime: (s) =>
        s.stats.vodStartTime ? new Date(s.stats.vodStartTime) : null,
      vodStopTime: (s) =>
        s.stats.vodStopTime ? new Date(s.stats.vodStopTime) : null,
      streamChartOptions(s) {
        return this.generateChartOptions(
          s.stats.streamHistory,
          this.streamStartTime ? this.streamStartTime : null,
          this.streamStopTime ? this.streamStopTime : null
        );
      },
      vodChartOptions(s) {
        return this.generateChartOptions(
          s.stats.vodHistory,
          this.vodStartTime ? this.vodStartTime : null,
          this.vodStopTime ? this.vodStopTime : null
        );
      },
    }),

    averageMinutesWatched() {
      return (
        this.streamMinutesWatched / (this.streamUniqueViewers || 1)
      ).toFixed(2);
    },

    streamRunTime() {
      return formatRuntime(
        this.streamStartTime,
        this.streamStopTime ?? this.now
      );
    },

    vodRunTime() {
      return formatRuntime(this.vodStartTime, this.vodStopTime ?? this.now);
    },

    completeEvent() {
      return ['complete'].includes(this.event.eventState);
    },

    vodEvent() {
      return ['vod_open', 'vod_closed'].includes(this.event.eventState);
    },
  },

  methods: {
    startClock() {
      this.clockTimer = setInterval(() => {
        const now = new Date();
        this.now = now;
      }, 1000);
    },
    stopClock() {
      clearInterval(this.clockTimer);
    },
    formattedTime(timestamp) {
      return !timestamp
        ? 'now'
        : dayjs(timestamp).format('h:mm a, D MMM YY (Z)');
    },
    generateChartOptions(history, startTime, stopTime) {
      const data = [];
      if (startTime) {
        let t = startTime.getTime();
        let n = 0;
        for (let i = 0; i < history.length; i++) {
          const entry = history[i];
          while (entry.t > t) {
            data.push([t, n]);
            t += 60_000;
          }
          data.push([entry.t, entry.n]);
          n = entry.n;
        }
        t = (stopTime ? stopTime : new Date()).getTime();
        data.push([t, n]);
      }
      return {
        credits: {
          enabled: false,
        },
        series: [
          {
            type: 'line',
            name: 'Attendees',
            data: data,
            threshold: 0,
          },
        ],
        colors: ['#00B4F0'],
        chart: {
          zoomType: 'x',
          style: {
            fontFamily: ['Circular Std', 'Arial'],
          },
          height: '300px',
        },
        title: '',
        subtitle: '',
        tooltip: {
          shadow: false,
          backgroundColor: 'rgba(255,255,255,0.75)',
          borderColor: 'rgba(0,0,0,0.2)',
          xDateFormat: '%d %B %y, %H:%M',
        },
        xAxis: {
          type: 'datetime',
          tickLength: 0,
          lineWidth: 0,
          labels: {
            style: {
              color: '#9AA5B1',
              fontSize: '14px',
            },
            format: '{value:%H:%M}',
          },
        },
        yAxis: {
          type: 'linear',
          title: '',
          min: 0,
          tickInterval: 1,
          gridLineColor: '#E4E7EB',
          gridLineDashStyle: 'dash',
          labels: {
            style: {
              color: '#9AA5B1',
              fontSize: '14px',
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {},
        time: {
          timezoneOffset: -dayjs().utcOffset(),
        },
      };
    },
  },

  mounted() {
    this.startClock();
    this.$store.dispatch('ENTER_STATS');
  },

  beforeRouteLeave(to, from, next) {
    this.stopClock();
    this.$store.dispatch('LEAVE_STATS');
    next();
  },
};
</script>
