var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"relative h-20"},[_c('div',{staticClass:"tabs absolute flex w-full w-full justify-start overflow-x-auto border-b border-grey-100 md:relative"},[(_vm.event.chatEnabled == true)?_c('button',{staticClass:"relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100",class:{
          'border-b border-blue-800 bg-grey-100': _vm.activeTab == 'chat',
        },on:{"click":function($event){$event.preventDefault();_vm.activeTab = 'chat'}}},[_vm._v(" Chat "),(_vm.unreadEngagementChatMessagesCount > 0)?_c('div',{staticClass:"badge right-2 top-2"},[_vm._v(" "+_vm._s(_vm.unreadEngagementChatMessagesCount)+" ")]):_vm._e()]):_vm._e(),(_vm.event.qaEnabled == true)?_c('button',{staticClass:"relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100",class:{
          'border-b border-blue-800 bg-grey-100': _vm.activeTab == 'qa',
        },on:{"click":function($event){$event.preventDefault();_vm.activeTab = 'qa'}}},[_vm._v(" Q&A "),(_vm.unreadEngagementQAMessagesCount > 0)?_c('div',{staticClass:"badge right-2 top-2"},[_vm._v(" "+_vm._s(_vm.unreadEngagementQAMessagesCount)+" ")]):_vm._e()]):_vm._e(),(_vm.event.pollEnabled == true)?_c('button',{staticClass:"relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100",class:{
          'border-b border-blue-800 bg-grey-100': _vm.activeTab == 'poll',
        },on:{"click":function($event){$event.preventDefault();_vm.activeTab = 'poll'}}},[_vm._v(" Poll ")]):_vm._e(),(_vm.event.quizEnabled == true)?_c('button',{staticClass:"relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100",class:{
          'border-b border-blue-800 bg-grey-100': _vm.activeTab == 'quiz',
        },on:{"click":function($event){$event.preventDefault();_vm.activeTab = 'quiz'}}},[_vm._v(" Quiz ")]):_vm._e(),(_vm.event.surveyEnabled == true)?_c('button',{staticClass:"relative block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100",class:{
          'border-b border-blue-800 bg-grey-100': _vm.activeTab == 'survey',
        },on:{"click":function($event){$event.preventDefault();_vm.activeTab = 'survey'}}},[_vm._v(" Survey ")]):_vm._e()])]),_c('div',{staticClass:"p-4 md:p-8"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == 'chat'),expression:"activeTab == 'chat'"}],staticClass:"flex h-full min-h-0"},[(_vm.event.chatEnabled == true)?_c('StudioPanelChat',{attrs:{"isFocus":_vm.activeTab == 'chat'},on:{"newMessagesNotification":_vm.handleEngagementChatNewMessagesNotification}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == 'qa'),expression:"activeTab == 'qa'"}],staticClass:"flex h-full min-h-0"},[(_vm.event.qaEnabled == true)?_c('StudioPanelQA',{attrs:{"isFocus":_vm.activeTab == 'qa'},on:{"newMessagesNotification":_vm.handleEngagementQANewMessagesNotification}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == 'poll'),expression:"activeTab == 'poll'"}],staticClass:"flex h-full min-h-0"},[(_vm.event.pollEnabled == true)?_c('StudioPanelPoll'):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == 'quiz'),expression:"activeTab == 'quiz'"}],staticClass:"flex h-full min-h-0"},[(_vm.event.quizEnabled == true)?_c('StudioPanelQuiz'):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == 'survey'),expression:"activeTab == 'survey'"}],staticClass:"flex h-full min-h-0"},[(_vm.event.surveyEnabled == true)?_c('StudioPanelSurvey'):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }