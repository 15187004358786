<template>
  <div>
    <form v-show="event" class="mt-4" @submit.prevent="save()">
      <MdTextField
        name="title"
        label="Event title"
        v-model="form.title"
        :errors="errors"
      />

      <MdTextSelectField
        label="Event tags (optional)"
        name="event_tags"
        v-model="form.eventTags"
        validateMode="unique"
        tooltip="Add event tag for better organising"
        :errors="errors"
        @add-item="handleAddEventTag"
        @remove-item="handleRemoveEventTag"
      />

      <div class="mb-6 pt-6 text-lg font-medium">Access</div>

      <div class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            name="registration_type"
            label="Access mode"
            v-model="form.registrationType"
            :errors="errors"
            :options="typeOptions"
            :disabled="['live', 'complete'].includes(event.eventState)"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              <div>
                <span class="font-bold">Public:</span> the event is publicly
                accessible by anyone with the link.
              </div>
              <div>
                <span class="font-bold">Password:</span> the event is protected
                by a password you set. Passwords are case SenSitivE.
              </div>
              <div>
                <span class="font-bold">Automatic:</span> attendees must
                register for the event by completing a registration form. Once
                complete they will be emailed login details immediately.
              </div>
              <div>
                <span class="font-bold">Manual:</span> attendees must register
                for the event by completing a registration form. Registrations
                must be manually accepted before login details are emailed.
              </div>
              <div>
                <span class="font-bold">Pre-registration:</span> attendees are
                managed on a third party system and imported to Joinin.
                Attendees cannot register on your portal.
              </div>
              <div class="flex items-center justify-center">
                <a
                  class="pr-1 text-blue-500"
                  href="https://support.joinin.live/portal/en/kb/articles/registration-types"
                  target="_blank"
                  >More information</a
                >
                <i class="material-icons-outlined text-icon-sm text-blue-500"
                  >help_outline</i
                >
              </div>
            </div>
          </template>
        </TooltipModal>
      </div>

      <div
        v-if="['live', 'complete'].includes(event.eventState)"
        class="-mt-4 pb-6 text-sm text-grey-400"
      >
        Registration type cannot be changed once Live.
      </div>

      <MdTextField
        v-if="form.registrationType == 'password'"
        label="Access password"
        name="password"
        v-model="form.password"
        tooltip="The password attendees will use to access the event. Passwords are case SenSitivE."
        :errors="errors"
      />

      <MdTextSelectField
        v-if="
          [
            'password',
            'automatic_registration',
            'manual_registration',
          ].includes(form.registrationType)
        "
        label="Access domains (optional)"
        name="access_domains"
        v-model="form.registrationApprovedDomains"
        validateMode="domain"
        tooltip="Restrict access to only specified domains. For example, only allow access/registration from attendees with a '@joinin.live' email address. If left blank there are no restrictions."
        :errors="errors"
        @add-item="handleAddRegoDomain"
        @remove-item="handleRemoveRegoDomain"
      />

      <div v-if="!registrationEvent" class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            class="flex-1"
            label="Maximum attendees"
            name="max_attendees"
            v-model="form.maxAttendees"
            :disabled="
              ['rehearsal', 'live', 'complete'].includes(event.eventState)
            "
            :errors="errors"
            :options="maxAttendeeOptions"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              Set the maximum number of attendees that can view this event. If
              this number is exceeded, additional attendees will not be able to
              access your portal.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div v-if="event.eventState != 'complete'">
        <div class="mb-3 pt-6 text-lg font-medium">Schedule</div>
        <div class="pb-6 text-grey-400">
          Set the scheduled start time and duration.
        </div>

        <div v-if="event.eventState != 'live'">
          <div class="flex-1 flex-row">
            <MdSelectField
              class="flex-1"
              name="timezone"
              layout="md-select"
              label="Timezone"
              v-model="form.timezone"
              @input="updateStartTime"
              :search="true"
              :options="timezoneOptions"
              :errors="errors"
            />

            <div class="flex flex-1 items-center justify-end pb-6">
              <span
                v-if="isDaylightSaving"
                class="flex items-center rounded bg-orange-500 px-3 py-1 text-white"
              >
                <i class="material-icons-outlined mr-2 text-sm text-white"
                  >warning</i
                >
                Daylight Saving
              </span>
            </div>
          </div>

          <div class="flex space-x-4">
            <MdSelectField
              class="flex-1"
              label="Day"
              name="day"
              v-model="form.day"
              @input="updateStartTime"
              :errors="errors"
              :options="dayOptions"
            />

            <MdSelectField
              class="flex-1"
              label="Month"
              name="month"
              v-model="form.month"
              @input="updateStartTime"
              :errors="errors"
              :options="monthOptions"
            />

            <MdSelectField
              class="flex-1"
              label="Year"
              name="year"
              v-model="form.year"
              @input="updateStartTime"
              :errors="errors"
              :options="yearOptions"
            />
          </div>

          <div class="flex space-x-4">
            <MdSelectField
              class="flex-1"
              label="Hour"
              name="hour"
              v-model="form.hour"
              @input="updateStartTime"
              :errors="errors"
              :options="hourOptions"
            />

            <MdSelectField
              class="flex-1"
              label="Minute"
              name="minute"
              v-model="form.minute"
              @input="updateStartTime"
              :errors="errors"
              :options="minuteOptions"
            />

            <MdSelectField
              class="flex-1"
              label="AM/PM"
              name="am/pm"
              v-model="form.amPm"
              @input="updateStartTime"
              :errors="errors"
              :options="[
                {
                  id: 'am',
                  label: 'AM',
                },
                {
                  id: 'pm',
                  label: 'PM',
                },
              ]"
            />
          </div>
        </div>
        <div v-else class="pb-10">Scheduled at {{ formatStartTime }}</div>
      </div>

      <div v-if="event.eventState != 'complete'" class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            class="flex-1"
            label="Estimated duration"
            name="estimated_duration"
            v-model="form.estimatedDurationSeconds"
            :errors="errors"
            tooltip="The"
            :options="durationOptions"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            The estimated duration of this event. You have full control to start
            and extend the event as required.
          </template>
        </TooltipModal>
      </div>

      <div class="mb-6 pt-6 text-lg font-medium">Support</div>

      <MdEmailField
        name="support_email_address"
        label="Support email address"
        v-model="form.supportEmailAddress"
        :errors="errors"
        tooltip="The support contact email address for this event. Displayed to attendees when they request help."
      />

      <div class="mb-6 pt-6 text-lg font-medium">GDPR</div>

      <div class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            name="gdpr_consent_collection"
            label="Consent collection"
            v-model="form.gdprConsentCollection"
            :errors="errors"
            :options="[
              {
                id: true,
                label: 'Show',
              },
              {
                id: false,
                label: 'Hide',
              },
            ]"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            Collect General Data Protection Regulation (GRPR) consent
            information from attendees at Registration/Login. Enable this option
            to turn on GDPR related features and enhancements. Where you have
            (or intend to have) persons in the European Economic Area attend
            this event, this feature must be enabled. Please note, that all
            required GDPR requirements will be enabled, i.e Consent to data
            collection, consent on behalf of a minor and link to our privacy
            policy. When enabled permission must be granted by the attendee to
            access the event.
          </template>
        </TooltipModal>
      </div>

      <MdTextField
        v-if="form.gdprConsentCollection"
        label="GDPR controlling entity"
        name="gdpr_controller"
        v-model="form.gdprController"
        tooltip="The company/entity responsible for ensuring GDPR compliance. This entity name will be displayed to attendees. Generally this is your company name."
        :errors="errors"
      />

      <div
        v-if="isTimeBeforeNow"
        class="flex flex-row justify-center rounded-xl border bg-red-100 p-2 text-red-500"
      >
        Scheduled start must be after the current time
      </div>

      <SubmitButton :disabled="!saveable" :saving="saving" />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import errors from '@/mixins/errors';
import MdTextField from '@/components/MdTextField';
import MdTextSelectField from '@/components/MdTextSelectField';
import MdEmailField from '@/components/MdEmailField';
import MdSelectField from '@/components/MdSelectField';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    MdTextField,
    MdTextSelectField,
    MdEmailField,
    MdSelectField,
    TooltipModal,
    SubmitButton,
  },

  data() {
    return {
      isDaylightSaving: false,
      form: {
        id: null,
        title: null,
        eventTags: [],
        description: '',
        registrationType: 'anyone',
        maxAttendees: null,
        password: null,
        timezone: 'Australia/Sydney',
        day: null,
        month: null,
        year: null,
        hour: null,
        minute: null,
        amPm: null,
        estimatedDurationSeconds: null,
        supportEmailAddress: null,
        gdprConsentCollection: false,
        gdprController: '',
      },
      jobStartTime: null,
      isTimeBeforeNow: false,
      saving: false,
      monthOptions: [
        {
          id: 0,
          label: 'January',
        },
        {
          id: 1,
          label: 'February',
        },
        {
          id: 2,
          label: 'March',
        },
        {
          id: 3,
          label: 'April',
        },
        {
          id: 4,
          label: 'May',
        },
        {
          id: 5,
          label: 'June',
        },
        {
          id: 6,
          label: 'July',
        },
        {
          id: 7,
          label: 'August',
        },
        {
          id: 8,
          label: 'September',
        },
        {
          id: 9,
          label: 'October',
        },
        {
          id: 10,
          label: 'November',
        },
        {
          id: 11,
          label: 'December',
        },
      ],
    };
  },

  computed: {
    ...mapState(['sessionData']),

    saveable() {
      return this.jobStartTime != null;
    },

    timezoneOptions() {
      return Object.values(EventBus.data.timezones)
        .map((tz) => {
          return {
            id: `${tz.name}`,
            label: `${tz.name}`,
          };
        })
        .sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        });
    },

    registrationEvent() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.form.registrationType);
    },

    maxAttendeeOptions() {
      let maxAttendeeOptions = [];

      if (this.sessionData.account.type == 'trial') {
        maxAttendeeOptions.push({
          id: 200,
          label: 200,
          disabled: true,
        });
      } else {
        if (this.event.eventSize == 'basic') {
          maxAttendeeOptions.push({
            id: 100,
            label: 100,
            disabled: true,
          });
        }

        for (let i = 200; i <= 2000; i += 100) {
          maxAttendeeOptions.push({
            id: i,
            label: `${i}`,
          });
        }
      }

      return maxAttendeeOptions;
    },

    typeOptions() {
      let typeOptions = [
        {
          id: 'anyone',
          label: 'Anyone',
        },
        {
          id: 'password',
          label: 'Password',
        },
      ];

      typeOptions = typeOptions.concat([
        {
          id: 'automatic_registration',
          label:
            'Automatic registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
        {
          id: 'manual_registration',
          label:
            'Manual registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
        {
          id: 'pre_registration',
          label:
            'Pre registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
      ]);

      return typeOptions;
    },

    yearOptions() {
      let options = [];
      options.push({
        id: dayjs().format('YYYY'),
        label: dayjs().format('YYYY'),
      });

      options.push({
        id: dayjs().add(1, 'year').format('YYYY'),
        label: dayjs().add(1, 'year').format('YYYY'),
      });

      return options;
    },

    dayOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 31; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },

    hourOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 12; i++) {
        options.push({
          id: i + '',
          label: i,
        });
      }

      return options;
    },

    minuteOptions() {
      let options = [];
      let i;

      for (i = 0; i <= 59; i++) {
        let label = i;

        if (label < 10) {
          label = '0' + label;
        }

        options.push({
          id: label + '',
          label: label + '',
        });
      }

      return options;
    },

    durationOptions() {
      let options = [];
      const increments = ['00', '15', '30', '45'];
      let i;

      for (i = 0; i <= 11; i++) {
        increments.forEach((increment) => {
          const label = this.pad(i, 2) + ':' + increment;

          // Skip 00:00
          if (!i && increment == '00') {
            return;
          }

          const hourSeconds = i * 60 * 60;
          const minuteSeconds = parseInt(increment) * 60;
          const seconds = hourSeconds + minuteSeconds;

          options.push({
            id: seconds,
            label,
          });
        });
      }

      return options;
    },

    formatStartTime() {
      return dayjs(this.event.startTime)
        .tz(this.event.timezone)
        .format('DD/MM/YYYY hh:mm:ss A');
    },
  },

  watch: {
    event: async function () {
      this.setupForm();
    },
    jobStartTime(time) {
      if (!time) return;
      if (
        EventBus.data.timezones[this.form.timezone].utcOffset !=
        -dayjs(time).$d.getTimezoneOffset()
      ) {
        this.isDaylightSaving = true;
      } else {
        this.isDaylightSaving = false;
      }
    },
  },

  async mounted() {
    if (this.event) {
      this.setupForm();
      this.updateStartTime();
    }
  },

  methods: {
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    },

    setupForm() {
      const startTime = dayjs(this.event.startTime).tz(this.event.timezone);

      assign(
        this.form,
        pick(
          {
            ...this.event,
            day: startTime.date(),
            month: startTime.month(),
            year: startTime.year().toString(),
            hour: startTime.format('h'),
            minute: startTime.format('mm'),
            amPm: startTime.format('a'),
          },
          keys(this.form)
        )
      );

      if (this.sessionData.account.type == 'trial') {
        this.form.maxAttendees = 200;
      }
    },

    save() {
      this.saving = true;

      // TODO: Use the Vuex store for this!
      api
        .put('/jobs/' + this.event.id, {
          ...this.form,
          startTime: this.jobStartTime,
        })
        .then((response) => {
          this.saving = false;

          EventBus.$emit('event-updated', response.data);
          this.notify('success', 'Event updated');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },

    updateStartTime() {
      this.isTimeBeforeNow = false;
      this.jobStartTime = null;
      if (
        !this.form.day ||
        this.form.month == null ||
        !this.form.year ||
        !this.form.hour ||
        !this.form.minute
      ) {
        return;
      }

      const hour =
        this.form.amPm == 'am'
          ? +this.form.hour == 12
            ? 0
            : +this.form.hour
          : +this.form.hour == 12
          ? +this.form.hour
          : +this.form.hour + 12;

      const datetime = dayjs()
        .date(this.form.day)
        .month(this.form.month)
        .year(this.form.year)
        .hour(hour)
        .minute(this.form.minute)
        .second(0)
        .tz(this.form.timezone, true);

      if (
        ['rehearsal', 'scheduled'].includes(this.event.eventState) &&
        dayjs().isAfter(datetime)
      ) {
        this.isTimeBeforeNow = true;
        return;
      }

      this.jobStartTime = datetime.format();
    },

    handleAddEventTag(tag) {
      this.form.eventTags.push(tag);
    },

    handleRemoveEventTag(index) {
      this.form.eventTags.splice(index, 1);
    },

    handleAddRegoDomain(domain) {
      this.form.registrationApprovedDomains.push(domain);
    },

    handleRemoveRegoDomain(index) {
      this.form.registrationApprovedDomains.splice(index, 1);
    },
  },
};
</script>
