<template>
  <div
    class="theme-gradient flex min-h-screen flex-col items-center pt-6 lg:pt-12"
  >
    <img alt="Joinin logo" class="mb-6 w-60 lg:mb-6" src="../assets/logo.png" />

    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>
