<template>
  <div
    class="relative flex h-full w-full items-center justify-center overflow-hidden border-2 border-grey-400 bg-grey-200"
  >
    <div class="aspect-ratio-box h-full w-full">
      <div class="aspect-ratio-box-inner border-red-500 shadow-2xl">
        <video
          v-show="stream"
          class="h-full w-full"
          ref="video"
          playsinline
          autoplay
          :muted="videoElementMuted"
          @resize="handleVideoResize($event)"
          @loadedmetadata="
            handleVideoResize($event);
            $event.currentTarget.play();
          "
        />
        <div
          v-show="!stream"
          class="flex h-full w-full items-center justify-center"
        >
          <div class="flex items-center font-bold text-black">
            <i class="material-icons-outlined mr-2 text-black"
              >electrical_services</i
            >
            NOT CONNECTED
          </div>
        </div>

        <!-- controls -->
        <div
          v-if="allowStatus"
          class="absolute top-0 flex h-12 w-full flex-row items-center justify-around px-2 text-white md:justify-end"
        >
          <!-- settings dropdown -->
          <DropdownMenu
            class="relative flex"
            position="top"
            align="right"
            ref="settingsDropdown"
          >
            <template v-slot:control="slotProps">
              <button
                class="flex h-8 w-8 items-center justify-center rounded-full bg-grey-400 bg-opacity-50 opacity-75 hover:bg-grey-400"
                type="button"
                @click="slotProps.toggle()"
              >
                <i class="material-icons-outlined text-white">settings</i>
              </button>
            </template>

            <template v-slot:menu>
              <div class="w-60 text-black">
                <div class="border-b-2 border-grey-100 p-4 text-left">
                  <div class="font-bold">Maximum quality</div>
                  <div class="text-xs">
                    Set the maximum viewing quality for this stream.
                  </div>
                </div>
                <div class="flex w-full flex-col">
                  <button
                    v-for="(v, index) in streamOptions"
                    :key="index"
                    class="flex flex-row items-center justify-start"
                  >
                    <div class="mx-1 w-12">
                      <i
                        v-if="maxSubstream == v.substream"
                        class="material-icons-outlined text-green"
                        >done</i
                      >
                    </div>
                    <div
                      @click="setStreamOption(v.substream)"
                      class="w-full cursor-pointer px-4 py-4 text-left hover:bg-grey-100"
                    >
                      <div>{{ v.title }}</div>
                    </div>
                  </button>
                </div>
              </div>
            </template>
          </DropdownMenu>
        </div>
        <!-- end of controls -->
      </div>
    </div>

    <div
      v-if="allowStatus"
      class="absolute bottom-0 flex w-full flex-col border-t border-grey-200 bg-black px-3 text-white opacity-60"
    >
      <div class="flex justify-between">
        <div class="flex items-center">
          <span class="text-sm font-bold">{{ name }}</span>
          <span
            v-if="presenter.isLive"
            class="mx-2 rounded border border-green-500 px-1 text-xs text-green-500"
            >Live</span
          >
        </div>
        <div class="flex flex-1">
          <div class="w-full flex-1 items-center justify-center p-2">
            <AudioIndicator
              :stream="stream"
              class="audio"
              :indicatorNodes="30"
              :userGesture="true"
              :audioContext="audioContext"
            />
          </div>
          <button
            @click="toggleMute"
            class="mr-2 flex items-center justify-center text-xs"
            :class="{ 'text-red-500': videoElementMuted }"
          >
            <i
              class="material-icons-outlined text-icon-sm"
              :class="{ 'text-red-500': videoElementMuted }"
            >
              {{ videoElementMuted ? 'volume_off' : 'volume_up' }}
            </i>
            {{ videoElementMuted ? 'Player muted' : '' }}
          </button>
        </div>
      </div>

      <div class="flex">
        <div class="w-3/5 flex-col justify-center">
          <div
            v-for="(stat, index) in presenter.webrtcStats"
            :key="index"
            class="flex items-center justify-center rounded px-1 text-xs"
            :class="{
              'animate-pulse bg-orange-500':
                index === presenter.webrtcStats.length - 1 && stat.fps < 25,
            }"
          >
            <div class="flex-1">
              <span>
                {{
                  index === 0
                    ? 'L'
                    : index === 1
                    ? 'M'
                    : index === 2
                    ? 'H'
                    : index
                }}: {{ stat.width }}x{{ stat.height }}@{{ stat.fps }}
              </span>
              <span v-if="stat.bytesPerSecond" class="pl-1"
                >{{ Math.round(stat.bytesPerSecond / 125) }}kB/s</span
              >
              <span
                v-if="
                  stat.qualityLimitationReason &&
                  stat.qualityLimitationReason !== 'none'
                "
                class="pl-1 capitalize"
                >Limit: {{ stat.qualityLimitationReason }}</span
              >
            </div>
            <TooltipModal
              v-if="index === maxSubstream"
              triggerType="hover"
              yAlign="above"
              width="48"
              textAlign="center"
            >
              <template slot="control">
                <i class="material-icons-outlined pr-1 text-xs text-blue-500"
                  >lock</i
                >
              </template>

              <template slot="content">
                <div class="text-sm">
                  The maximum viewing quality set for this stream. Change the
                  quality via the settings menu.
                </div>
              </template>
            </TooltipModal>
            <TooltipModal
              v-if="currentVideoResolution === stat.width + 'x' + stat.height"
              triggerType="hover"
              yAlign="above"
              width="48"
              textAlign="center"
            >
              <template slot="control">
                <i class="material-icons-outlined pl-1 text-xs text-blue-500"
                  >visibility</i
                >
              </template>

              <template slot="content">
                <div class="text-sm">
                  The current stream being rendered by the browser. This may
                  differ from the maximum quality selected based on your
                  available bandwidth.
                </div>
              </template>
            </TooltipModal>
          </div>
        </div>
        <div class="flex-1 justify-center">
          <div
            v-if="presenter.webrtcStats.length"
            class="flex justify-end rounded px-1 text-xs"
          >
            <div v-if="totalBandwidth">
              Total Bandwidth: {{ Math.round(totalBandwidth / 125) }}kB/s
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioIndicator from '@/components/AudioIndicator';
import DropdownMenu from '@/components/DropdownMenu.vue';
import TooltipModal from '@/components/TooltipModal';

export default {
  name: 'PresenterPlayer',

  props: [
    'presenter',
    'name',
    'janus',
    'janusPin',
    'janusVideoRoomId',
    'index',
    'allowStatus',
    'videoElementMuted',
    'audioContext',
  ],

  components: {
    AudioIndicator,
    DropdownMenu,
    TooltipModal,
  },

  data: () => ({
    handle: null,
    stream: null,
    maxSubstream: 2,
    currentVideoResolution: null,
    streamOptions: [
      { id: 1, title: 'Low', tooltip: '', value: 'high', substream: 0 },
      { id: 2, title: 'Medium', tooltip: '', value: 'medium', substream: 1 },
      { id: 3, title: 'High', tooltip: '', value: 'low', substream: 2 },
    ],
  }),

  mounted() {
    if (this.presenter.isOnline) {
      this.janusInitSubscriber();
    }
  },

  beforeDestroy() {
    if (this.janus && !this.janus._destroying && this.handle) {
      this.handle.detach();
    }
  },

  watch: {
    'stream'(newStream, oldStream) {
      const video = this.$refs.video;
      if (newStream != null) {
        if (this.presenter.forceMuted) {
          video.muted = true;
        } else {
          // presenter mute is double-sided mute
          // when presenter mutes audio, no audio comes through the stream
          // also mute from the video player
          video.muted = this.presenter.muted;
        }

        video.srcObject = newStream;
      } else {
        video.srcObject = null;
      }

      if (oldStream instanceof MediaStream) {
        oldStream.getTracks().forEach((track) => track.stop());
      }
    },
    'presenter.isOnline'(isOnline) {
      if (isOnline) {
        if (!this.stream) {
          this.janusInitSubscriber();
        }
      } else {
        this.stream = null;
      }
    },
    'presenter.muted'(val) {
      if (this.presenter.forceMuted) {
        this.$refs.video.muted = true;
      } else {
        // presenter mute is double-sided mute
        // when presenter mutes audio, no audio comes through the stream
        // also mute from the video player
        this.$refs.video.muted = val;
      }
    },
  },

  computed: {
    videoMuted: {
      get() {
        return this.videoElementMuted;
      },
      set(value) {
        if (this.presenter.forceMuted) {
          // If presenter is force muted, cannot unmute
          return;
        }
        this.$emit('update:videoElementMuted', value, this.presenter.id);
      },
    },
    totalBandwidth() {
      return this.presenter.webrtcStats.reduce(
        (total, stat) => total + stat.bytesPerSecond,
        0
      );
    },
  },

  methods: {
    async janusInitSubscriber() {
      if (this.handle) {
        this.handle.detach();
        this.handle = null;
      }

      this.handle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: resolve,
          error: (error) => {
            console.log(error);
          },
          onmessage: (msg, jsep) => {
            if (jsep && jsep.type === 'offer') {
              this.handle.createAnswer({
                media: {
                  audioSend: false,
                  videoSend: false,
                  audioRecv: true,
                  videoRecv: true,
                  data: false,
                },
                success: (ansJsep) => {
                  this.handle.send({
                    message: { request: 'start' },
                    jsep: ansJsep,
                  });
                },
                error: console.error.bind(console),
                jsep,
              });
            }
          },
          onremotestream: (stream) => {
            stream.getTracks().forEach((track) => {
              if (track.kind == 'video') {
                track.contentHint = 'motion';
              }
              if (track.kind == 'audio') {
                track.contentHint = 'speech';
              }
            });

            this.stream = stream;
            this.isLoaded = true;

            // set initial videoelement mute state
            this.$refs.video.muted = this.videoMuted;
          },
          detached: (a) => {
            console.log('detached', a);
          },
        });
      });

      this.handle.send({
        message: {
          request: 'join',
          ptype: 'subscriber',
          room: String(this.janusVideoRoomId),
          pin: this.janusPin,
          feed: String(this.presenter.pid),
          video: true, //need to be true at init. otherwise the stream is empty?
          audio: true,
          substream: this.maxSubstream,
        },
      });
    },
    toggleMute() {
      if (this.presenter.forceMuted) {
        // If presenter is force muted, cannot unmute
        return;
      }
      this.videoMuted = !this.videoMuted;
    },
    setStreamOption(substream) {
      this.maxSubstream = substream;
      this.handle.send({
        message: {
          request: 'configure',
          substream,
        },
      });

      if (this.$refs.settingsDropdown) {
        this.$refs.settingsDropdown.close();
      }
    },
    handleVideoResize(event) {
      const vid = event.currentTarget;
      this.currentVideoResolution = vid.videoWidth + 'x' + vid.videoHeight;
    },
  },
};
</script>

<style scoped>
.aspect-ratio-box {
  position: relative;
  height: 100%;
}

.aspect-ratio-box-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
