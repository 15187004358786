<template>
  <div class="absolute top-0 flex w-full flex-col lg:bottom-0">
    <div class="flex min-h-0 flex-grow flex-col">
      <!-- top menu -->
      <div
        class="z-30 flex h-16 flex-shrink-0 items-center space-x-2 bg-white shadow"
      >
        <!-- dropdown menu -->
        <div
          class="flex h-full items-center justify-center border-r border-grey-200"
        >
          <DropdownMenu
            class="relative h-full w-full"
            ref="dropdown"
            align="left"
          >
            <template slot="control" slot-scope="slotProps">
              <button
                class="flex h-full w-full cursor-pointer items-center justify-center space-x-2 px-4 hover:bg-grey-100"
                @click.prevent="slotProps.toggle()"
              >
                <div class="truncate">{{ $route.meta.title }}</div>
                <i
                  class="material-icons-outlined transition-250q transform transition-all"
                  :class="{ 'rotate-180': slotProps.visible }"
                  >expand_more</i
                >
              </button>
            </template>

            <template slot="menu">
              <RouterLink
                to="/dashboard"
                class="dropdownListItem"
                target="_blank"
                >Dashboard
              </RouterLink>

              <RouterLink
                v-if="allow('studio')"
                :to="'/events/' + event.eventShortcode + '/studio'"
                target="_blank"
                class="dropdownListItem"
                exact-active-class="bg-grey-100"
                >Studio
              </RouterLink>

              <RouterLink
                v-if="allow('analytics-general')"
                :to="'/events/' + event.eventShortcode + '/analytics'"
                target="_blank"
                class="dropdownListItem"
                >Analytics
              </RouterLink>

              <RouterLink
                v-if="allow('registration') && registrationOpen"
                :to="'/events/' + event.eventShortcode + '/registration'"
                target="_blank"
                class="dropdownListItem"
                >Registration
              </RouterLink>

              <RouterLink
                v-if="allow('settings-branding')"
                :to="'/events/' + event.eventShortcode + '/branding'"
                target="_blank"
                class="dropdownListItem"
                >Branding
              </RouterLink>

              <RouterLink
                v-if="allow('settings-general')"
                :to="
                  '/events/' +
                  event.eventShortcode +
                  '/settings' +
                  (['complete', 'vod_open', 'vod_closed'].includes(
                    event.eventState
                  )
                    ? '/vod'
                    : '')
                "
                target="_blank"
                class="dropdownListItem"
                >Settings
              </RouterLink>

              <RouterLink
                v-if="allow('engagement-standalone')"
                :to="'/events/' + event.eventShortcode + '/engagement'"
                target="_blank"
                class="dropdownListItem"
                >Engagement
              </RouterLink>

              <RouterLink
                v-if="allow('io-router')"
                :to="'/events/' + event.eventShortcode + '/io-router'"
                target="_blank"
                class="dropdownListItem"
                >Router
              </RouterLink>

              <!-- external links -->
              <div class="my-4 border border-grey-100"></div>

              <div class="p-3 text-grey-400">Attendee portal</div>

              <a
                :href="'https://view.joinin.live/' + event.eventShortcode"
                target="_blank"
                class="dropdownListItemWithIcon"
                >Open login
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                  >open_in_new</i
                >
              </a>

              <a
                v-if="!completeEvent"
                :href="previewUrl"
                target="_blank"
                class="dropdownListItemWithIcon"
                >Preview portal
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                  >open_in_new</i
                >
              </a>
            </template>
          </DropdownMenu>
        </div>

        <!-- title -->
        <div
          class="flex min-w-0 flex-row items-center justify-center px-4 text-center lg:flex-1"
        >
          <div class="text-md font-bold lg:text-xl">{{ event.title }}</div>
        </div>

        <!-- notification -->
        <div class="flex hidden items-center justify-center lg:flex-1">
          <!-- <i class="material-icons-outlined text-green-200 mr-2">notifications</i> Notification goes here -->
        </div>

        <!-- status -->
        <div
          class="ml-auto flex hidden items-center justify-center px-4 lg:block"
        >
          <div
            class="flex items-center justify-between space-x-4 rounded bg-grey-100 px-4 py-2 text-sm"
          >
            <div
              v-if="event.eventState == 'live'"
              class="flex items-center rounded bg-red-100 px-2 capitalize text-red-800"
            >
              <div class="mr-2 h-1 w-1 rounded-full bg-red-500"></div>
              Live
            </div>
            <div
              v-else-if="event.eventState == 'complete'"
              class="flex items-center rounded bg-green-200 px-2 capitalize text-green-800"
            >
              <i class="material-icons-outlined mr-2 text-green-800">check</i>
              Complete
            </div>
            <div
              v-else-if="event.eventState == 'vod_open'"
              class="flex items-center rounded bg-green-200 px-2 capitalize text-green-800"
            >
              VOD: Open
            </div>
            <div
              v-else-if="event.eventState == 'vod_closed'"
              class="capitalize text-grey-800"
            >
              VOD: Closed
            </div>
            <div v-else class="capitalize text-grey-800">
              {{ event.eventState }}
            </div>

            <div
              class="flex items-center whitespace-nowrap text-grey-400 text-grey-800"
            >
              <i class="material-icons-outlined mr-1 text-sm">people_alt</i>
              <div class="mr-1 text-green-800">{{ onlinePresentersCount }}</div>
              / {{ presenters.length }}
            </div>

            <div
              v-if="event.eventState == 'live'"
              class="flex min-w-min items-center text-grey-800"
            >
              <i class="material-icons-outlined mr-1 text-sm text-grey-400"
                >visibility</i
              >
              {{ stats.streamLiveViewers }}
            </div>

            <TooltipModal
              v-if="
                shutdownMinutes <= event.idleShutdownWarningMinutes &&
                ['rehearsal', 'live'].includes(event.eventState)
              "
              triggerType="hover"
              width="48"
              textAlign="center"
            >
              <template slot="control">
                <div
                  class="flex items-center whitespace-nowrap text-grey-400 text-grey-800"
                  :class="{ 'text-red-500': shutdownMinutes < 5 }"
                >
                  <i
                    class="material-icons-outlined mr-1 text-sm"
                    :class="{ 'text-red-500': shutdownMinutes < 5 }"
                  >
                    cancel_presentation</i
                  >{{ shutdownMinutes }} mins
                </div>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div class="text-sm">
                    This event will automatically shut down in
                    {{ shutdownMinutes }} minutes if no operator action is
                    taken. Modify the 'Next' display to cancel the automatic
                    shut down.
                  </div>
                </div>
              </template>
            </TooltipModal>

            <div
              class="flex w-16 items-center whitespace-nowrap text-grey-400 text-grey-800"
            >
              <i class="material-icons-outlined mr-1 text-sm">schedule</i
              >{{ clock }}
            </div>
          </div>
        </div>
      </div>

      <!-- main content -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/services/api';
import DropdownMenu from '@/components/DropdownMenu';
import TooltipModal from '@/components/TooltipModal';

export default {
  components: {
    DropdownMenu,
    TooltipModal,
  },

  data: () => ({
    warningTime: 300 * 1000,
    clock: new Date().toTimeString().substring(0, 8),
  }),

  computed: {
    ...mapState([
      'event',
      'user',
      'stats',
      'presenters',
      'onlinePresentersCount',
    ]),
    ...mapGetters(['isTrialValid', 'subscriptionStatus']),

    shutdownMinutes() {
      return Math.floor((this.event.idleShutdownTimeout - Date.now()) / 60_000);
    },

    completeEvent() {
      return this.event.eventState == 'complete';
    },

    registrationOpen() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.event.registrationType);
    },

    previewUrl() {
      return (
        this.event &&
        `${process.env.VUE_APP_API_URL}/jobs/${this.event.jobId}/preview`
      );
    },
  },

  mounted() {
    this.startClock();
  },

  beforeDestroy() {
    this.stopClock();
  },

  methods: {
    logOut() {
      api.post('/authentication/logout');
      this.$store.commit('loggedOut');
      this.$router.replace('/login');
    },
    startClock() {
      this.clockTimer = setInterval(() => {
        this.clock = new Date().toTimeString().substring(0, 8);
      }, 1000);
    },
    stopClock() {
      clearInterval(this.clockTimer);
    },
    toggleDropdownMenu() {
      this.$refs.dropdown.toggle();
    },
  },
};
</script>
