<template>
  <div>
    <div v-if="!can('event-survey')" class="mb-6">
      <UpgradePlanLink />
    </div>

    <form v-else="event.surveyEnabled" @submit.prevent="save()">
      <div class="mb-3 flex items-center justify-between">
        <div class="text-lg font-medium">Custom survey form</div>
        <div class="flex items-center justify-between space-x-4">
          <button
            v-if="event.surveyFormId"
            @click.prevent="removeSurveyFormConfirmation"
            class="group flex items-center justify-center space-x-1 rounded-full border border-grey-200 px-2 py-1 hover:border-red-500"
          >
            <i class="material-icons-outlined group-hover:text-red-500"
              >delete</i
            >
            <span class="text-sm text-grey-800 group-hover:text-red-500"
              >Delete</span
            >
          </button>
        </div>
      </div>
      <div
        v-if="templates.length || event.surveyFormId"
        class="pb-6 text-grey-400"
      >
        Assign a
        <RouterLink to="/templates" class="cursor-pointer text-blue-500">
          Form Template
        </RouterLink>
        to this event. Form Templates are duplicated and can then be modified
        for this specific event.
      </div>
      <div v-if="!event.surveyFormId" class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            class="flex-1"
            label="Survey form template"
            name="survey_form"
            v-model="surveyFormId"
            :errors="errors"
            :options="templates"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            The estimated duration of this event. You have full control to start
            and extend the event as required.
          </template>
        </TooltipModal>
      </div>
      <div v-else class="flex-1">
        <MdTextField
          class="flex-1"
          label="Form"
          name="formNanme"
          v-model="form.surveyFormName"
          :required="true"
          :errors="errors"
        />

        <MdTextareaField
          class="flex-1"
          label="Form description"
          name="formDescription"
          v-model="form.surveyFormDescription"
          :rows="2"
          :required="false"
          :errors="errors"
        />

        <FormBuilder
          v-if="surveyForm"
          :form="surveyForm"
          :key="surveyForm.id"
          type="form"
        />

        <SubmitButton :saving="saving" />
      </div>
    </form>

    <!-- remove confirmation -->
    <Modal v-if="surveyForm" width="96" ref="deleteDialog">
      <template slot="content">
        <div
          class="mb-4 border-b border-grey-200 pb-4 text-center text-xl font-bold"
        >
          Delete form and data
        </div>
        <div>
          Deleting this form
          <strong
            >will remove all fields and any submitted attendee data</strong
          >
          from this event.
        </div>
        <div>
          Are you sure you want to delete the form and all attendee data. Data
          cannot be recovered once deleted.
        </div>

        <button
          @click.prevent="removeSurveyForm"
          class="mt-8 flex w-full items-center justify-center rounded bg-red-500 py-3 text-white hover:bg-red-800"
        >
          <i class="material-icons-outlined mr-2 text-white">delete</i>
          I understand. Delete
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';

import MdSelectField from '@/components/MdSelectField';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';
import FormBuilder from '@/components/FormBuilder';
import Modal from '@/components/Modal';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  mixins: [errors],
  name: 'EventSurvey',
  props: ['event'],

  components: {
    MdSelectField,
    TooltipModal,
    SubmitButton,
    FormBuilder,
    Modal,
    MdTextField,
    MdTextareaField,
    UpgradePlanLink,
  },

  data: () => ({
    isLoaded: false,
    saving: false,
    templates: [],
    forms: [],
    surveyForm: null,
    surveyFormId: null,
    form: {
      surveyFormName: null,
      surveyFormDescription: null,
    },
  }),

  watch: {
    surveyFormId: function (val) {
      if (val) {
        this.saveSurveyForm();
      }
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.isLoaded = true;

      if (this.event.surveyFormId) {
        const res = await api.get(`/forms/${this.event.surveyFormId}`);
        this.surveyForm = res.data;

        this.form.surveyFormName = this.surveyForm.name;
        this.form.surveyFormDescription = this.surveyForm.description;
      } else {
        const template_res = await api.get('/forms/templates');
        if (template_res.status >= 200 && template_res.status < 300) {
          this.templates = template_res.data
            .filter((row) => row.type == 'survey')
            .map((row) => {
              return { id: row.id, label: row.name };
            });
        }

        const form_res = await api.get('/forms');
        if (form_res.status >= 200 && form_res.status < 300) {
          this.forms = form_res.data;
        }
      }

      this.isLoaded = false;
    },
    saveSurveyForm() {
      this.saving = true;

      api
        .post(`/jobs/${this.event.id}/createSurveyForm`, {
          templateId: this.surveyFormId,
        })
        .then(async (response) => {
          this.saving = false;

          if (response.status >= 200 && response.status < 300) {
            this.forms.push({
              ...response.data,
              jobId: this.event.id,
            });
            this.$emit('set-survey-form-id', response.data.id);
            this.surveyFormId = null;

            await this.init();

            this.notify('success', 'Event updated');
          }
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
    removeSurveyFormConfirmation() {
      this.$refs.deleteDialog.show();
    },
    removeSurveyForm() {
      this.$refs.deleteDialog.close();
      api
        .delete(`/jobs/${this.event.id}/surveyForm`)
        .then(async (response) => {
          this.saving = false;

          if (response.status >= 200 && response.status < 300) {
            this.$emit('set-survey-form-id', null);
            this.notify('success', 'Event updated');
          }

          await this.init();
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
    save() {
      this.saving = true;

      //update registration form name && description
      api
        .put(`/forms/${this.surveyForm.id}`, {
          name: this.form.surveyFormName,
          description: this.form.surveyFormDescription,
        })
        .then(() => {
          this.saving = false;

          this.notify('success', 'Event updated');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
