import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // Contrary to some SO responses, there is no 'status' field on the error.
    // It's simply a JS "Error" object.
    // The below is godawful, but preserves the original intent:
    if (error?.message == 'Request failed with status code 401') {
      // TODO: This is brittle in that _NO_ method on the server may return
      //       a 401 for any reason other than that the user is not logged
      //       in!
      console.error('Your session seems to have expired. Logging out..');
      window.location.href = '/login';
    } else {
      throw error;
    }
  }
);

export default api;
