<template>
  <div class="flex w-full flex-grow flex-col">
    <div v-if="!can('event-poll')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="flex flex-col break-words lg:h-full">
          <div class="my-3 flex items-center justify-between space-x-2">
            <SelectFilter
              v-if="!isSearch"
              class="flex-1"
              v-model="filter"
              :options="filterOptions"
            />

            <input
              v-if="isSearch"
              type="text"
              v-model="search"
              placeholder="Search polls..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button @click.prevent="isSearch = !isSearch">
              <i
                v-if="!isSearch"
                class="material-icons-outlined flex justify-center"
                >search</i
              >
              <i
                v-if="isSearch"
                class="material-icons-outlined flex justify-center"
                >sort</i
              >
            </button>

            <RouterLink
              :to="{
                name: 'output-poll',
                params: { shortcode: $route.params.shortcode },
              }"
              target="_blank"
              class="flex items-center text-xs text-blue-500 hover:text-blue-800"
            >
              <i
                class="material-icons-outlined flex justify-center pr-1 text-sm text-blue-500"
                >dvr</i
              >Open display
            </RouterLink>
          </div>

          <div
            v-if="!filteredPolls.length"
            class="py-4 text-center text-grey-400"
          >
            No polls to display
          </div>

          <!-- question list -->
          <div v-else class="flex flex-grow flex-col overflow-auto pt-4">
            <div
              v-for="p in filteredPolls"
              :key="p.id"
              class="border-b border-grey-100 px-1 py-2 hover:bg-grey-100"
            >
              <div class="flex flex-row">
                <div
                  class="flex-1 whitespace-pre-line"
                  :class="{ 'text-grey-400': !p.isLive }"
                >
                  {{ p.content }}
                </div>
                <div class="flex">
                  <DropdownMenu>
                    <template slot="control" slot-scope="slotProps">
                      <button
                        @click.stop="slotProps.toggle()"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i class="material-icons-outlined">more_vert</i>
                      </button>
                    </template>

                    <template slot="menu">
                      <button
                        @click.prevent="navigateToEditPoll(p)"
                        class="dropdownListItem"
                      >
                        Edit
                      </button>

                      <button
                        @click.prevent="navigateToResetPoll(p)"
                        class="dropdownListItem"
                      >
                        Reset votes
                      </button>

                      <button
                        @click.prevent="navigateToDeletePoll(p)"
                        class="dropdownListItem"
                      >
                        Delete
                      </button>
                    </template>
                  </DropdownMenu>
                </div>
              </div>

              <div v-if="p.isLive">
                <!-- options -->
                <div
                  v-for="o in p.options"
                  :key="o.id"
                  class="my-2 px-4 text-sm"
                >
                  <div class="flex cursor-pointer text-grey-400">
                    <div class="flex-1 whitespace-pre-line">
                      {{ o.content }}
                    </div>
                    <div class="flex">
                      {{ o.voteCount }}
                      {{ o.voteCount == 1 ? 'vote' : 'votes' }} -
                      {{ o.percentage.toFixed(2) }}%
                    </div>
                  </div>

                  <!-- submitted -->
                  <div class="mt-1 flex items-center justify-between">
                    <div
                      class="flex h-3 w-3/4 rounded-full bg-blue-500"
                      :style="`width: ${o.percentage || 0}%`"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="flex flex-row pt-1 lg:pt-4">
                <button
                  @click.prevent="start(p.id)"
                  class="pr-4 text-grey-400 hover:text-green-800"
                  :class="{ 'text-green-500': p.isLive }"
                >
                  {{ p.isLive ? 'Started' : 'Start' }}
                </button>
                <button
                  @click.prevent="stop(p.id)"
                  class="pr-4 text-grey-400 hover:text-red-700"
                  :class="{ 'text-red-500': !p.isLive }"
                >
                  {{ !p.isLive ? 'Stopped' : 'Stop' }}
                </button>
                <div class="flex flex-grow items-center justify-end">
                  <div class="text-sm" :class="{ 'text-grey-400': !p.isLive }">
                    {{ p.totalVoteCount }} total
                    {{ p.totalVoteCount == 1 ? 'vote' : 'votes' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- action button -->
        <div
          class="flex flex-shrink-0 justify-center border-t border-grey-200 pt-3"
        >
          <button class="btn-blue" @click.prevent="navigateToAddPoll">
            Add poll
          </button>
        </div>
      </template>
      <!-- end of main -->

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="flex py-6">
          <button
            class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3
            class="-ml-10 flex w-full items-center justify-center text-xl font-bold"
          >
            {{ upperFirst(section) }}
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add/edit poll -->
        <template v-if="section == 'edit_poll' || section == 'add_poll'">
          <form
            class="flex flex-col overflow-auto px-3 py-2"
            @submit.prevent="addOrEdit"
          >
            <TextareaField
              name="content"
              class="pb-4"
              label="Enter poll question"
              v-model="selectedPoll.content"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <SelectField
              name="question_type"
              label="Question type"
              v-model="selectedPoll.hasMultipleResponse"
              :errors="errors"
              :options="questionTypes"
            />

            <div class="py-4 text-grey-400">Poll options</div>

            <div
              v-for="(o, i) in selectedPoll.options"
              :key="o.id"
              class="flex items-center pb-6"
            >
              <TextField
                class="-mb-0 flex-1"
                label="Enter option text"
                v-model="selectedPoll.options[i].content"
                :errors="errors"
              />

              <div class="flex pl-4">
                <i
                  class="material-icons-outlined cursor-pointer text-3xl text-grey-400 hover:text-red-700"
                  @click="removeOption(i)"
                >
                  close
                </i>
              </div>
            </div>

            <button
              @click="addOption"
              class="mb-4 flex w-full items-center justify-center rounded-full border border-dashed border-grey-400 py-3 hover:bg-grey-200"
            >
              Add option
            </button>

            <button type="submit" class="btn-blue">
              {{ selectedPoll.id ? 'Update' : 'Save' }}
            </button>
          </form>
        </template>
        <!-- end of edit poll -->

        <!-- reset poll -->
        <template v-if="section == 'reset_poll'">
          <div class="px-6">
            <h4 class="text-center text-lg font-bold">
              {{ selectedPoll.content }}
            </h4>
            <div class="mt-10 text-center">
              Are you sure you want to reset votes for this poll? All current
              vote data will be lost.
            </div>

            <button class="btn-blue mt-10" @click.prevent="resetPoll">
              Reset votes
            </button>
          </div>
        </template>
        <!-- end of reset poll -->

        <!-- delete poll -->
        <template v-if="section == 'delete_poll'">
          <div class="px-6">
            <h4 class="text-center text-lg font-bold">
              {{ selectedPoll.content }}
            </h4>
            <div class="mt-10 text-center">
              Are you sure you want to delete this poll? All vote data will be
              lost.
            </div>

            <button class="btn-red mt-10" @click.prevent="deletePoll">
              Delete
            </button>
          </div>
        </template>
        <!-- end of delete poll -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SelectFilter from '@/components/SelectFilter';
import DropdownMenu from '@/components/DropdownMenu';
import SelectField from '@/components/SelectField';
import TextField from '@/components/TextField';
import TextareaField from '@/components/TextareaField';
import errors from '@/mixins/errors';
import { some, sortBy, upperFirst } from 'lodash';
import UpgradePlanLink from '@/components/UpgradePlanLink';

const makeEmptyPoll = () => ({
  id: null,
  content: '',
  options: [{ content: '' }],
  hasMultipleResponse: false,
});

export default {
  name: 'StudioPanelPoll',
  mixins: [errors],
  components: {
    SelectFilter,
    DropdownMenu,
    SelectField,
    TextField,
    TextareaField,
    UpgradePlanLink,
  },

  data: () => ({
    section: null,
    search: null,
    isSearch: false,
    filter: 'all',
    filterOptions: [
      {
        id: 'all',
        label: 'All',
      },
      {
        id: 'started',
        label: 'Started',
      },
      {
        id: 'stopped',
        label: 'Stopped',
      },
    ],
    selectedPoll: makeEmptyPoll(),
    questionTypes: [
      {
        id: false,
        label: 'Single answer',
      },
      {
        id: true,
        label: 'Multiple answer',
      },
    ],
  }),
  computed: {
    ...mapState({
      polls: (s) =>
        s.polls.map((p) => ({
          ...p,
          options: p.options.map((o) => ({
            ...o,
            percentage: (100 * o.voteCount) / (p.totalVoteCount || 1),
          })),
        })),
    }),

    filteredPolls() {
      let res = this.polls;
      let order = ['id'];

      if (this.filter == 'started') {
        res = res.filter((p) => p.isLive);
      } else if (this.filter == 'stopped') {
        res = res.filter((p) => !p.isLive);
      }

      if (this.search) {
        const s = this.search.toLowerCase();
        res = res.filter((p) =>
          some([p.content], (text) => text.toLowerCase().indexOf(s) > -1)
        );
      }

      return sortBy(res, ...order);
    },
  },
  watch: {
    isActive: function (val) {
      if (val == true) {
        this.section = null;
      }
    },
    isSearch: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
  },
  methods: {
    navigateToAddPoll() {
      this.selectedPoll = makeEmptyPoll();
      this.section = 'add_poll';
    },
    navigateToEditPoll(poll) {
      this.selectedPoll = poll;
      this.section = 'edit_poll';
    },
    navigateToResetPoll(poll) {
      this.selectedPoll = poll;
      this.section = 'reset_poll';
    },
    navigateToDeletePoll(poll) {
      this.selectedPoll = poll;
      this.section = 'delete_poll';
    },
    addOption() {
      this.selectedPoll.options.push({ content: '' });
    },
    removeOption(i) {
      this.selectedPoll.options.splice(i, 1);
    },
    start(id) {
      this.$store.dispatch('START_POLL', { id });
    },
    stop(id) {
      this.$store.dispatch('STOP_POLL', { id });
    },
    resetPoll() {
      this.$store.dispatch('RESET_POLL', { id: this.selectedPoll.id });
      this.section = null;
      this.selectedPoll = makeEmptyPoll();
    },
    addOrEdit() {
      if (!this.selectedPoll.id) {
        this.$store.dispatch('CREATE_POLL', this.selectedPoll);
      } else {
        this.$store.dispatch('UPDATE_POLL', this.selectedPoll);
      }
      this.section = null;
      this.selectedPoll = makeEmptyPoll();
    },
    deletePoll() {
      this.$store.dispatch('DELETE_POLL', { id: this.selectedPoll.id });
      this.section = null;
      this.selectedPoll = makeEmptyPoll();
    },
    upperFirst(value) {
      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },
  },
};
</script>
