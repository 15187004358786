import Vue from 'vue';
import { string as randomString } from '@/helper/random.js';

const STATS_TEXTROOM = 3;

export function trackJanusPublishers({ janusPublishers, janusMsg }) {
  let removedId,
    added = [];
  if (janusMsg.unpublished || janusMsg.leaving) {
    const publisherId = janusMsg.unpublished || janusMsg.leaving;
    if (janusPublishers[publisherId]) {
      removedId = publisherId;
    }
    Vue.delete(janusPublishers, publisherId);
  }
  if (Array.isArray(janusMsg.publishers)) {
    janusMsg.publishers.forEach((pub) => {
      if (!janusPublishers[pub.id]) {
        const jPub = {
          id: pub.id,
          display: pub.display,
        };
        Vue.set(janusPublishers, pub.id, jPub);
        added.push(jPub);
      }
    });
  }
  return { removedId, added };
}

export function loadJanusJs(janusHost) {
  if (!window.adapterLoadedPromise) {
    const script = document.createElement('script');
    script.src = '/js/adapter-8.1.1.min.js';
    script.async = false;
    document.head.appendChild(script);
    window.adapterLoadedPromise = new Promise((resolve, reject) => {
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
    });
  }

  if (!window.janusLoadedPromise) {
    const script = document.createElement('script');
    script.src = janusHost + '/demos/janus.js';
    script.async = false;
    document.head.appendChild(script);
    window.janusLoadedPromise = new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        window.Janus.init({
          debug: ['warn', 'error'],
          callback: resolve,
        });
      });
      script.addEventListener('error', reject);
    });
  }
}

export function setupTextroomReceiveStats({
  janus,
  janusPin,
  clientWebrtcStats,
  destroying,
}) {
  let textroomHandle;
  janus.attach({
    plugin: 'janus.plugin.textroom',
    error: console.error,
    success: (handle) => {
      textroomHandle = handle;
      textroomHandle.send({ message: { request: 'setup' } });
    },
    onmessage: (msg, jsep) => {
      if (destroying.value) return;
      if (jsep?.type == 'offer') {
        textroomHandle.createAnswer({
          jsep,
          media: { audio: false, video: false, data: true },
          success: (ourJsep) => {
            textroomHandle.send({
              message: { request: 'ack' },
              success: () => {
                textroomHandle.data({
                  text: JSON.stringify({
                    textroom: 'join',
                    transaction: randomString(12),
                    room: STATS_TEXTROOM,
                    ack: false,
                    pin: janusPin,
                    username: 'ControlIoRouter-' + randomString(12),
                  }),
                });
              },
              jsep: ourJsep,
            });
          },
          error: console.error,
        });
      }
    },
    ondata: (_data) => {
      const data = JSON.parse(_data);
      if (data.textroom != 'message') return;
      const [type, clientId] = data.from.split('-');
      if (type != 'IoClient') return;
      const body = JSON.parse(data.text);
      const {
        frameWidth: width,
        frameHeight: height,
        framesPerSecond: fps,
        bytesPerSecond,
        qualityLimitationReason,
      } = body;

      let stats = clientWebrtcStats.find((c) => c.id == clientId);
      if (!stats) {
        stats = { id: clientId, stats: [] };
        clientWebrtcStats.push(stats);
      }
      let statIndex = stats.stats.findIndex(
        (s) => s.width == width && s.height == height
      );
      const stat = {
        width,
        height,
        fps,
        bytesPerSecond,
        qualityLimitationReason,
        expiry: Date.now() + 4000,
      };
      if (statIndex == -1) {
        stats.stats.push(stat);
      } else {
        Vue.set(stats.stats, statIndex, stat);
      }
    },
  });
}
