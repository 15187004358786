<template>
  <div
    class="relative flex h-full w-full flex-row items-center justify-center bg-black"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <video
      class="h-full w-full"
      ref="video"
      playsinline
      muted
      @ended="exitFullScreen"
      @webkitendfullscreen="webkitEndFullScreen"
      @volumechange="onVideoVolumeChange"
    />

    <!-- status message -->
    <div
      v-if="status"
      class="absolute bottom-0 left-0 flex h-10 items-center px-2 text-grey-400"
    >
      {{ status }}
    </div>

    <!-- controls -->
    <div
      v-if="controls.length > 0"
      v-show="enableControl == true"
      class="absolute bottom-0 flex h-10 w-full flex-row items-center justify-around px-2 text-white md:justify-end"
    >
      <!-- volume and mute -->
      <div class="flex flex-1 flex-row">
        <div
          v-if="isVolumeControl"
          class="mx-2 flex h-8 items-center justify-center rounded-full bg-grey-400 bg-opacity-50 px-4 hover:bg-grey-400"
        >
          <input
            type="range"
            :disabled="muted"
            ref="volume"
            class="cursor-pointer"
            v-model="volume"
            max="1"
            min="0"
            step="0.01"
          />
        </div>
        <button
          v-if="controls.includes('mute')"
          @click.prevent="handleMute"
          class="mx-2r flex h-8 w-8 items-center justify-center rounded-full bg-grey-400 bg-opacity-50 hover:bg-grey-400"
        >
          <i v-if="muted" class="material-icons-outlined">volume_off</i>
          <i v-else class="material-icons-outlined">{{ volumeClass }}</i>
        </button>
      </div>

      <!-- fullscreen -->
      <button
        v-if="isFullscreenControl"
        @click.prevent="enterFullScreen"
        class="mx-2 flex h-8 w-8 items-center justify-center rounded-full bg-grey-400 bg-opacity-50 hover:bg-grey-400"
      >
        <i class="material-icons-outlined">fullscreen</i>
      </button>

      <!-- settings dropdown -->
      <DropdownMenu
        v-if="isSettingsControl"
        class="relative flex"
        position="top"
        align="right"
        ref="settingsDropdown"
      >
        <template slot="control" slot-scope="slotProps">
          <button
            class="flex h-8 w-8 items-center justify-center rounded-full bg-grey-400 bg-opacity-50 hover:bg-grey-400"
            @click.prevent="slotProps.toggle()"
          >
            <i class="material-icons-outlined">settings</i>
          </button>
        </template>

        <template slot="menu">
          <div class="w-40 text-black">
            <div class="border-b-2 border-grey-100 p-2 text-left">
              <div class="font-bold">Quality</div>
            </div>
            <div class="flex w-full flex-col">
              <button
                v-for="(v, index) in streamOptions"
                :key="index"
                class="flex flex-row items-center justify-start"
              >
                <div class="mx-1 w-6">
                  <i
                    v-if="sltStreamOptionId == v.id"
                    class="material-icons-outlined text-green"
                    >done</i
                  >
                </div>
                <div
                  @click.prevent="setStreamOption(v.id)"
                  class="w-full cursor-pointer border-b-2 border-grey-100 px-2 py-1 text-left hover:bg-grey-100"
                >
                  <div>{{ v.title }}</div>
                </div>
              </button>
            </div>
          </div>
        </template>
      </DropdownMenu>
    </div>
    <!-- end of controls -->

    <div
      v-if="isLoading"
      class="absolute flex flex-row items-center text-white"
    >
      <svg
        version="1.1"
        id="loader-1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        enable-background="new 0 0 40 40"
        xml:space="preserve"
      >
        <path
          opacity="0.2"
          fill="#fff"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path
          fill="#fff"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="0.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      <span class="ml-3">Loading...</span>
    </div>
    <div
      v-if="!isLoading && muted"
      class="absolute left-3 top-3 flex flex-row items-center"
    >
      <i class="material-icons-outlined mr-2 text-white">volume_off</i>
      <button
        @click="toggleMuted"
        class="rounded-full bg-white px-2 px-4 py-1 text-sm text-black shadow hover:bg-grey-200"
      >
        Click to unmute
      </button>
    </div>
  </div>
</template>

<script>
/*=======================================================================*/
//  Component: Custom Video Player
//
//  $emit event @onScaleDown { substream: x::NUMBER }
//  $emit event @onScaleUp { substream: x::NUMBER }
//  $emit event @onSubstreamSelect { substream: x::NUMBER }
//
//  scaleDown triggers from parent component when encounter
//  slow_link, scaleDown process will abort when reaches the min
//  ex: this.$refs.video.scaleDown()
//
//  scaleUp triggers at component mount and scaleDown with
//  delay(scaleUpInterval), scaleUp process will abort when reaches the max
//
//  when scale down/up processes happen too often(scaleThreshold), the
//  scaling process will be temporary disabled for an accumulated amount
//  of time(disableScalingInterval)
/*========================================================================*/

import DropdownMenu from '@/components/DropdownMenu';
import { detect } from 'detect-browser';

export default {
  name: 'CustomVideoPlayer',

  components: {
    DropdownMenu,
  },

  data: () => ({
    browser: null,
    player: null,
    isLoading: true,
    muted: true,
    enableControl: false,
    streamOptions: [
      { id: 0, title: 'Auto', tooltip: '', value: 'auto', substream: null },
      { id: 1, title: 'High', tooltip: '', value: 'high', substream: 0 },
      { id: 2, title: 'Medium', tooltip: '', value: 'medium', substream: 1 },
      { id: 3, title: 'Low', tooltip: '', value: 'low', substream: 2 },
    ],
    sltStreamOptionId: 0,
    scaleDownCounter: 0,
    scaleDownThreshold: 4,
    scaleDownInterval: 30, //30 secs
    scaleDownIntervalHandler: null,
    scaleUpInterval: 30, //30 secs
    scaleUpIntervalHandler: null,
    currentSubstream: 1,
    disableScaling: false,
    disableScalingIntervalHandler: null,
    disableScalingIntervalIndex: 0,
    disableScalingInterval: [30, 60, 90, 120, 200],
    eventsList: [],
    eventsListClearInterval: 30, // 30 secs

    scaleDownWeight: 1,
    scaleUpWeight: 2,
    scaleThreshold: 3,

    volume: 0,
    prevVolume: 0.5,
    isFullScreen: false,
    fullScreenPlayTimeout: null,
  }),

  props: {
    controls: {
      type: Array,
      defualt: [],
    },
    stream: {
      required: true,
    },
    status: {
      type: String,
    },
  },

  mounted() {
    this.browser = detect();
    this.setCSSProperty();
  },

  beforeDestroy() {
    console.log('Destroy Video Player');
    clearTimeout(this.disableScalingIntervalHandler);
    clearTimeout(this.scaleDownIntervalHandler);
    clearTimeout(this.scaleUpIntervalHandler);
    clearTimeout(this.fullScreenPlayTimeout);
  },

  computed: {
    volumeClass() {
      var icon;

      if (this.volume == 0) {
        icon = 'volume_mute';
      } else if (this.volume <= 0.5) {
        icon = 'volume_down';
      } else if (this.volume > 0.5) {
        icon = 'volume_up';
      }

      return icon;
    },
    isVolumeControl() {
      return (
        this.controls.includes('volume') &&
        this.browser &&
        !['ios', 'android'].includes(this.browser.name)
      );
    },
    isFullscreenControl() {
      return this.controls.includes('fullscreen');
    },
    isSettingsControl() {
      return this.controls.includes('settings');
    },
  },

  watch: {
    stream: function (newStream, oldStream) {
      if (newStream != null) {
        this.muted = true;

        this.$refs.video.srcObject = newStream;
        this.$refs.video.volume = this.volume;
        this.$refs.video.muted = this.muted;

        this.$refs.video.onloadedmetadata = () => {
          this.$refs.video.play();
          this.isLoading = false;
        };

        //start scale up when stream is ready
        this.scaleUp();
      } else {
        oldStream.getTracks().forEach((track) => track.stop());

        this.$refs.video.srcObject = null;
      }
    },
    volume: function (val) {
      this.setCSSProperty();

      this.$refs.video.volume = val;
    },
  },

  methods: {
    onVideoVolumeChange(e) {
      if (this.muted == true) {
        this.muted = e.target.muted;
      }
    },

    setStreamOption(id) {
      this.sltStreamOptionId = id;

      if (id != 0) {
        var streamOption = this.streamOptions.find((s) => s.id == id);
        if (streamOption) {
          this.$emit('onSubstreamSelect', {
            substream: streamOption.substream,
          });
          this.currentSubstream = streamOption.substream;
        }
      } else {
        //start scale up when select auto
        this.scaleUp();
      }

      this.$refs.settingsDropdown.close();
    },
    toggleMuted() {
      this.muted = false;
      this.$refs.video.muted = this.muted;
      this.volume = this.prevVolume;
      this.$refs.video.volume = this.prevVolume;
    },
    handleMute() {
      this.muted = !this.muted;
      this.$refs.video.muted = this.muted;
      if (this.muted) {
        this.prevVolume = +this.volume;
        this.$refs.video.volume = 0;
        this.volume = 0;
      } else {
        this.$refs.video.volume = this.prevVolume;
        this.volume = this.prevVolume;
      }
    },
    handleMouseOver() {
      this.enableControl = true;
    },
    handleMouseLeave() {
      this.enableControl = false;
    },
    trackEvent(event) {
      //clear event older than 30 secs
      this.eventsList = this.eventsList.filter((e) => {
        return e.timestamp + this.eventsListClearInterval * 1000 > Date.now();
      });

      this.eventsList.push(event);

      var threshold = 0;
      this.eventsList.map((e) => {
        if (e.type == 'scale_up') threshold = threshold + this.scaleUpWeight;
        if (e.type == 'scale_down')
          threshold = threshold + this.scaleDownWeight;
      });

      if (threshold > this.scaleThreshold) {
        //stop scale event for x time
        this.disableScaling = true;
        console.log(
          ':::DISABLE SCALING:::',
          `stop scaling for ${
            this.disableScalingInterval[this.disableScalingIntervalIndex]
          } secs`
        );

        clearTimeout(this.disableScalingIntervalHandler);
        this.disableScalingIntervalHandler = setTimeout(() => {
          this.disableScaling = false;
          console.log(':::ENABLE SCALING:::');
          //try to scale up after enable
          this.scaleUp();

          if (
            this.disableScalingIntervalIndex <
            this.disableScalingInterval.length
          ) {
            this.disableScalingIntervalIndex++;
          }
        }, this.disableScalingInterval[this.disableScalingIntervalIndex] * 1000);
      }
    },
    scaleDown() {
      clearTimeout(this.scaleDownIntervalHandler);
      if (
        this.sltStreamOptionId != 0 ||
        this.currentSubstream == 2 ||
        this.disableScaling == true
      )
        return;

      this.scaleDownCounter++;
      if (this.scaleDownCounter >= this.scaleDownThreshold) {
        //scale down
        if (this.currentSubstream < 2) {
          this.currentSubstream++;
          this.$emit('onScaleDown', { substream: this.currentSubstream });
          //record event
          this.trackEvent({ type: 'scale_down', timestamp: Date.now() });

          //reset scale up after scale down
          this.scaleUp();
        }

        //reset counter
        this.scaleDownCounter = 0;
      } else {
        this.scaleDownIntervalHandler = setTimeout(() => {
          this.scaleDownCounter = 0;
        }, this.scaleDownInterval * 1000);
      }
    },
    scaleUp() {
      clearTimeout(this.scaleUpIntervalHandler);

      //only when select auto
      //or reach the substream 0
      if (
        this.sltStreamOptionId != 0 ||
        this.currentSubstream == 0 ||
        this.disableScaling == true
      )
        return;

      this.scaleUpIntervalHandler = setTimeout(() => {
        this.currentSubstream--;
        this.$emit('onScaleUp', { substream: this.currentSubstream });
        //record event
        this.trackEvent({ type: 'scale_up', timestamp: Date.now() });
        this.scaleUp();
      }, this.scaleUpInterval * 1000);
    },
    enterFullScreen() {
      if (this.$refs.video.requestFullscreen) {
        this.$refs.video.requestFullscreen();
      } else if (this.$refs.video.webkitSupportsFullscreen) {
        /* Safari */
        this.$refs.video.webkitEnterFullscreen();
      }
    },
    exitFullScreen() {
      if (this.isFullScreen == true) {
        if (this.$refs.video.cancelFullScreen) {
          this.$refs.video.cancelFullScreen();
        } else if (this.$refs.video.mozCancelFullScreen) {
          this.$refs.video.mozCancelFullScreen();
        } else if (this.$refs.video.webkitCancelFullScreen) {
          this.$refs.video.webkitCancelFullScreen();
        } else if (this.$refs.video.webkitExitFullScreen) {
          this.$refs.video.webkitExitFullScreen();
        }
      }
    },
    webkitEndFullScreen() {
      clearTimeout(this.fullScreenPlayTimeout);
      //long enough to switch control from iOS fullscreen to web
      this.fullScreenPlayTimeout = setTimeout(() => {
        this.$refs.video.play();
      }, 1000);
    },
    setCSSProperty() {
      const percent = this.volume * 100;

      if (this.$refs.volume) {
        this.$refs.volume.style.setProperty(
          '--webkitProgressPercent',
          `${percent}%`
        );
      }
    },
  },
};
</script>

<style scoped>
video::-webkit-media-controls-enclosure {
  display: none !important;
  visibility: visible;
}

video::-webkit-media-controls-timeline {
  display: none !important;
}

input[type='range'] {
  --thumbSize: 12px;
  --trackSize: 2px;
  --thumbBg: #fff;
  --trackBg: #bdbdbd;
  --progressBg: #01b4f0;
  --disabledTrackBg: #bdbdbd;
  --disabledProgressBg: #b51c29;

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
}
input[type='range']:focus {
  outline: none;
}

/* Thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type='range']::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Track */
input[type='range']::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-webkit-slider-runnable-track {
  background-image: linear-gradient(
    90deg,
    var(--disabledProgressBg) var(--webkitProgressPercent),
    var(--disabledTrackBg) var(--webkitProgressPercent)
  );
}
input[type='range']::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-moz-range-track {
  background-color: var(--disabledTrackBg);
}
input[type='range']::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-ms-track {
  background-color: var(--disabledTrackBg);
}

/* Progress */
input[type='range']::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-moz-range-progress {
  background-color: var(--disabledProgressBg) !important;
}
input[type='range']::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-ms-fill-lower {
  background-color: var(--disabledProgressBg) !important;
}
</style>
