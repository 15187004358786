<template>
  <div class="pb-4">
    <div class="flex items-center justify-between">
      <div class="text-lg font-semibold text-grey-1000">{{ event.title }}</div>

      <DropdownMenu ref="dropdown">
        <template slot="control" slot-scope="slotProps">
          <button
            @click.prevent="slotProps.toggle()"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">more_vert</i>
          </button>
        </template>

        <template slot="menu">
          <!-- links -->
          <RouterLink
            v-if="allow('studio')"
            :to="'/events/' + event.eventShortcode + '/studio'"
            class="dropdownListItem"
            >Studio
          </RouterLink>

          <RouterLink
            v-if="!completeEvent && allow('settings-general')"
            :to="'/events/' + event.eventShortcode + '/settings'"
            class="dropdownListItem"
            >Settings
          </RouterLink>

          <RouterLink
            v-if="completeEvent && allow('settings-ondemand')"
            :to="'/events/' + event.eventShortcode + '/settings/vod'"
            class="dropdownListItem"
            >Settings
          </RouterLink>

          <RouterLink
            v-if="allow('engagement-standalone')"
            :to="'/events/' + event.eventShortcode + '/engagement'"
            class="dropdownListItem"
            >Engagement
          </RouterLink>

          <RouterLink
            v-if="allow('io-router')"
            :to="'/events/' + event.eventShortcode + '/io-router'"
            class="dropdownListItem"
            >Router
          </RouterLink>

          <RouterLink
            v-if="!completeEvent && allow('settings-branding')"
            :to="'/events/' + event.eventShortcode + '/branding'"
            class="dropdownListItem"
            >Branding
          </RouterLink>

          <RouterLink
            v-if="allow('analytics-general')"
            :to="'/events/' + event.eventShortcode + '/analytics'"
            class="dropdownListItem"
            >Analytics
          </RouterLink>

          <RouterLink
            v-if="allow('registration') && registrationOpen"
            :to="'/events/' + event.eventShortcode + '/registration'"
            class="dropdownListItem"
            >Registration
          </RouterLink>

          <!-- external links -->
          <div class="my-1 border border-grey-100"></div>

          <div class="p-2 text-grey-400">Attendee portal</div>

          <button
            @click.prevent="handleCopyUrl(event.eventShortcode)"
            target="_blank"
            class="dropdownListItemWithIcon"
          >
            Copy URL
            <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
              >link</i
            >
          </button>

          <a
            :href="eventPortalUrl(event.eventShortcode)"
            target="_blank"
            class="dropdownListItemWithIcon"
            >Open login
            <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
              >open_in_new</i
            >
          </a>

          <a
            v-if="!completeEvent"
            :href="previewUrl"
            target="_blank"
            class="dropdownListItemWithIcon"
            >Preview portal
            <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
              >open_in_new</i
            >
          </a>

          <div v-if="can('delete-event') || can('duplicate-event')">
            <!-- actions -->
            <div class="my-1 border border-grey-100"></div>

            <div
              v-if="can('duplicate-event')"
              class="dropdownListItemWithIcon"
              @click="confirmDuplicateModal()"
            >
              Duplicate
              <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                >copy</i
              >
            </div>

            <div
              v-if="can('delete-event') && !liveEvent && !rehearsalEvent"
              class="dropdownListItemWithIcon"
              @click="confirmDeleteModal()"
            >
              Delete
              <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                >delete</i
              >
            </div>
            <div v-else class="dropdownListItemWithIcon">
              Delete
              <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                >delete</i
              >
            </div>
          </div>
        </template>
      </DropdownMenu>
    </div>

    <div class="pb-2 text-sm text-grey-400">
      <div v-if="rehearsalEvent" class="text-yellow-400">Rehearsing</div>
      <div :class="{ 'font-bold text-green-500': liveEvent }">
        {{ timeSummary }}
      </div>
      <div>Access: {{ registrationType }}</div>
      <div
        v-if="completeEvent && event.vodEnabled"
        :class="{
          'font-bold text-green-500': vodOpen,
          'text-red-500': vodClosed,
        }"
      >
        On Demand: {{ vodOpen ? 'Open' : 'Closed' }}
      </div>
    </div>
    <div
      v-if="event.description"
      class="whitespace-pre-line pb-2 text-xs text-grey-400"
    >
      {{ event.description }}
    </div>

    <div class="flex hidden flex-1 justify-between lg:flex">
      <div class="flex space-x-4">
        <!-- external link -->
        <a
          v-if="allow('studio')"
          target="_blank"
          :href="'/events/' + event.eventShortcode + '/studio'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Studio</a
        >

        <RouterLink
          v-if="allow('registration') && registrationOpen"
          :to="'/events/' + event.eventShortcode + '/registration'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Registration</RouterLink
        >

        <RouterLink
          v-if="allow('analytics-general') && (liveEvent || completeEvent)"
          :to="'/events/' + event.eventShortcode + '/analytics'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Analytics</RouterLink
        >

        <RouterLink
          v-if="allow('analytics-download') && completeEvent"
          :to="'/events/' + event.eventShortcode + '/analytics/download'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Download recording</RouterLink
        >

        <RouterLink
          v-if="!completeEvent && allow('settings-general')"
          :to="'/events/' + event.eventShortcode + '/settings'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Settings</RouterLink
        >

        <RouterLink
          v-if="completeEvent && allow('settings-ondemand')"
          :to="'/events/' + event.eventShortcode + '/settings/vod'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Settings</RouterLink
        >

        <RouterLink
          v-if="allow('io-router')"
          :to="'/events/' + event.eventShortcode + '/io-router'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Router</RouterLink
        >

        <RouterLink
          v-if="!completeEvent && allow('settings-branding')"
          :to="'/events/' + event.eventShortcode + '/branding'"
          class="font-bold text-blue-500 hover:text-blue-800"
          >Branding</RouterLink
        >
      </div>
      <div class="flex">
        <a
          :href="eventPortalUrl(event.eventShortcode)"
          target="_blank"
          class="flex items-center justify-center font-bold text-blue-500 hover:text-blue-800"
        >
          <i class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
            >open_in_new</i
          >
          Open Portal
        </a>
      </div>
    </div>

    <!-- Duplicate confirmation -->
    <Modal width="96" ref="confirmDuplicate">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Duplicate event</h1>
        <div class="mb-3">
          Are you sure you want to duplicate the event
          <strong>{{ event.title }}</strong
          >? The following will be copied to a new event:
        </div>

        <ul>
          <li>- Settings</li>
          <li>- Pages</li>
          <li>- Presenters</li>
          <li>- Layouts</li>
        </ul>

        <div class="pb-3 pt-6">The following will not be duplicated:</div>
        <ul>
          <li>- Registered attendees</li>
          <li>- Media</li>
          <li>- Scenes</li>
        </ul>

        <TextField
          name="duplicate_name"
          label="New event name"
          class="pt-12"
          v-model="duplicateEventName"
          :required="true"
          :autofocus="true"
          :errors="errors"
        />

        <div class="py-2">
          <MaterialIconsCheckbox
            v-model="includePresenters"
            :label="'Include Presenters'"
          />
        </div>

        <div class="py-2">
          <MaterialIconsCheckbox
            v-model="includePages"
            :label="'Include Pages'"
          />
        </div>

        <button class="btn-blue mt-6" @click="duplicateEvent">
          Duplicate event
        </button>

        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmDuplicate.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>

    <!-- Delete confirmation -->
    <Modal width="96" ref="confirmDelete">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Delete event</h1>
        <div class="mb-6">
          Are you sure you want to delete the event
          <strong>{{ event.title }}</strong
          >? All data will be removed and no longer accessible, including:
        </div>

        <ul>
          <li>- Analytics</li>
          <li>- Event recordings</li>
          <li>- Registrations</li>
          <li>- Media</li>
          <li>- Settings</li>
          <li>- Engagement activities</li>
        </ul>
        <div class="pb-6 pt-12">
          <MaterialIconsCheckbox
            v-model="eventDeleteCheckbox"
            :label="'I understand'"
          />
        </div>

        <button
          class="btn-red"
          :disabled="eventDeleteCheckbox == false"
          @click="deleteEvent"
        >
          Delete event
        </button>

        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmDelete.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import errors from '@/mixins/errors';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

import upperFirst from 'lodash/upperFirst';
import DropdownMenu from '@/components/DropdownMenu';
import Modal from '@/components/Modal';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';
import TextField from '@/components/TextField';

export default {
  mixins: [errors],

  components: {
    DropdownMenu,
    Modal,
    MaterialIconsCheckbox,
    TextField,
  },

  props: ['event'],

  data: () => ({
    eventDeleteCheckbox: false,
    duplicateEventName: null,
    includePresenters: null,
    includePages: null,
  }),

  computed: {
    size() {
      if (this.event.eventSize == 'trial_timed') {
        return 'Trial';
      } else {
        return upperFirst(this.event.eventSize);
      }
    },

    registrationType() {
      let regex = /_/gi;
      return upperFirst(this.event.registrationType.replace(regex, ' '));
    },

    registrationOpen() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.event.registrationType);
    },

    timeSummary() {
      if (this.liveEvent) {
        return 'Live now!';
      }

      if (this.upcomingEvent) {
        return (
          'Scheduled start: ' +
          dayjs(this.event.startTime).format('D MMM YY') +
          ' @ ' +
          dayjs(this.event.startTime).format('h:mma Z')
        );
      }

      if (this.completeEvent) {
        return 'Completed';
      }
      return '';
    },

    upcomingEvent() {
      return !['live', 'complete'].includes(this.event.eventState);
    },

    rehearsalEvent() {
      return this.event.eventState == 'rehearsal';
    },

    liveEvent() {
      return this.event.eventState == 'live';
    },

    completeEvent() {
      return ['complete', 'vod_open', 'vod_closed'].includes(
        this.event.eventState
      );
    },

    vodOpen() {
      return ['vod_open'].includes(this.event.eventState);
    },

    vodClosed() {
      return (
        ['vod_closed', 'complete'].includes(this.event.eventState) &&
        this.event.vodEnabled
      );
    },

    previewUrl() {
      return (
        this.event &&
        `${process.env.VUE_APP_API_URL}/jobs/${this.event.id}/preview`
      );
    },
  },

  methods: {
    confirmDuplicateModal() {
      this.$refs.dropdown.close();
      this.duplicateEventName = this.event.title + ' - copy';
      this.$refs.confirmDuplicate.show();
    },

    confirmDeleteModal() {
      this.$refs.dropdown.close();
      this.$refs.confirmDelete.show();
    },

    duplicateEvent() {
      this.$store.dispatch('DUPLICATE_JOB', {
        id: this.event.id,
        name: this.duplicateEventName,
        presenters: this.includePresenters,
        pages: this.includePages,
      });
      this.$refs.confirmDuplicate.close();
    },

    deleteEvent() {
      // TODO: Can this ever be the selected event? If so what do we do?
      // TODO: Failure mode? try/catch here? display error where?
      this.$store.dispatch('REMOVE_JOB', { id: this.event.id });
      this.$refs.confirmDelete.close();
    },
    eventPortalUrl(shortcode) {
      return `https://view.joinin.live/` + shortcode;
    },
    handleCopyUrl(shortcode) {
      navigator.clipboard.writeText(this.eventPortalUrl(shortcode));

      this.$store.commit(
        'MESSAGE',
        `Event portal URL has been copied to the clipboard.`
      );
    },
  },
};
</script>
