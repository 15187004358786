<template>
  <div
    class="page flex h-full w-full items-center justify-center bg-black text-white"
  >
    <div v-if="getQuestion" class="flex h-full w-full flex-1 flex-col p-12">
      <div
        v-if="getQuestion.forPresenterId"
        class="scaleText py-2 font-bold text-grey-300"
      >
        @{{ getQuestion.presenterName }}
      </div>
      <VClamp
        :max-height="`90%`"
        :autoresize="true"
        class="scaleText h-full whitespace-pre-line font-bold"
      >
        {{ getQuestion.content }}
      </VClamp>
      <div class="mt-6 flex flex-col justify-between border-t-4 lg:flex-row">
        <div class="scaleText py-2 font-bold text-grey-300">
          {{ formatName(getQuestion) }}
        </div>
        <div class="scaleText py-2 text-grey-300">
          <div class="flex items-center space-x-4">
            <div class="text-grey-400">
              {{ formatDate(getQuestion.createdAt) }}
            </div>

            <DropdownMenu v-if="convertableToQA">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.stop="slotProps.toggle()"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </template>

              <template slot="menu">
                <button
                  @click.prevent="convertToQaQuestion(m)"
                  class="dropdownListItem"
                >
                  Move to Q&A
                </button>
              </template>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VClamp from 'vue-clamp';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export default {
  name: 'EventOutputQA',
  components: {
    VClamp,
  },
  computed: {
    ...mapState(['event', 'qaQuestions', 'presenters']),
    getQuestion() {
      const onScreenQuestion = this.qaQuestions.find((q) => q.isOnScreen);
      if (!onScreenQuestion) return null;

      const presenter = this.presenters.find(
        (p) => p.pid === onScreenQuestion.forPresenterId
      );
      const name = presenter ? presenter.name : null;
      console.log({ ...onScreenQuestion, presenterName: name });
      return { ...onScreenQuestion, presenterName: name };
    },
  },
  methods: {
    formatDate(time) {
      if (dayjs(time).isToday()) {
        return dayjs(time).format('h:mma');
      } else {
        return dayjs(time).format('h:mma, DD MMM YY');
      }
    },
    formatName: ({ isAnonymous, firstName, lastName }) =>
      isAnonymous
        ? 'Anonymous'
        : [firstName, lastName].filter((x) => x).join(' ') || '(No name)',
  },
};
</script>

<style scoped>
.scaleText {
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1920 - 300)));
  line-height: 1.2;
}
</style>
