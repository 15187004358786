<template>
  <div class="py-1" :class="networkClass">
    <div
      v-if="bitrate != null && bitrate != 0"
      class="has-tooltip flex cursor-pointer flex-row items-center justify-center"
    >
      <span
        class="tooltip bg-gray-100 -mt-12 whitespace-nowrap rounded p-1 text-grey-500 text-red-500 shadow-lg"
        >Connection: {{ bitrateText }}</span
      >
      <div class="justify-space-between flex flex-row items-end">
        <div
          class="mr-0.5 w-1"
          :class="networkIndicator(n)"
          v-for="n in 3"
          :key="n"
        ></div>
      </div>
      <div class="ml-1 flex text-sm capitalize">{{ networkStatus }}</div>
    </div>
    <div v-else class="text-sm text-green-500">Online</div>
  </div>
</template>

<script>
export default {
  name: 'NetworkIndicator',
  props: ['bitrateList', 'bitrate'],
  data: () => ({
    networkStatus: null,
    networkClass: null,
    bitrateText: null,
  }),
  mounted() {
    this.getNetworkStatus();
  },
  watch: {
    bitrate: function () {
      this.getNetworkStatus();
    },
    networkStatus: function (val) {
      this.getNetworkStatusColor(val);
    },
  },
  methods: {
    getNetworkStatus() {
      if (this.bitrateList.length == 0 || this.bitrate == null) {
        this.networkStatus = null;
        return;
      }

      var index = this.bitrateList.findIndex((b) => b.value == this.bitrate);
      if (index == -1) {
        this.networkStatus = null;
        return;
      }

      if (index <= 1) {
        this.networkStatus = 'low';
      } else if (index <= 3) {
        this.networkStatus = 'moderate';
      } else {
        this.networkStatus = 'good';
      }

      this.bitrateText = this.bitrateList[index].text;
    },
    networkIndicator(n) {
      var cls = '';

      switch (this.networkStatus) {
        case 'good':
          cls = `h-${n} bg-green-500`;
          break;
        case 'moderate':
          cls = n <= 2 ? `h-${n} bg-orange-500` : `h-${n} bg-grey-200`;
          break;
        case 'low':
          cls = n <= 1 ? `h-${n} bg-red-500` : `h-${n} bg-grey-200`;
          break;
      }

      return cls;
    },
    getNetworkStatusColor(val) {
      switch (val) {
        case 'good':
          this.networkClass = 'text-green-500';
          break;
        case 'moderate':
          this.networkClass = 'text-orange-500';
          break;
        case 'low':
          this.networkClass = 'text-red-500';
          break;
      }
    },
  },
};
</script>

<style scoped>
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
</style>
