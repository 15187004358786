<template>
  <div
    class="relative mb-8 flex flex-col items-start"
    @click="emitClick"
    v-click-outside="close"
  >
    <div class="relative flex w-full items-center">
      <label
        class="float-label absolute text-grey-400"
        :class="floatLabel ? 'float-label-active' : 'cursor-pointer'"
        @click.prevent="visible = true"
        >{{ label }}</label
      >

      <input
        type="text"
        ref="input"
        class="mt-2 w-full flex-auto cursor-pointer border-b border-grey-300 pt-1 text-black focus:border-blue-500"
        :class="{ 'is-invalid': errors.has(name) }"
        :id="name"
        :name="name"
        autocomplete="off"
        :disabled="disabled"
        :value="selectedLabel"
        :required="required"
        @focus="focused = true"
        @blur="focused = false"
        @click.prevent="visible = true"
        readonly
      />

      <i
        class="material-icons-outlined absolute right-0 cursor-pointer"
        @click="disabled == false ? (visible = true) : null"
        >arrow_drop_down</i
      >

      <div
        v-show="visible"
        class="absolute top-0 z-20 mt-4 w-full bg-white py-2 shadow"
      >
        <div
          class="overflow-x-hidden overflow-y-scroll text-grey-900"
          ref="optionsScrollArea"
          :style="`max-height:` + scrollHeight"
        >
          <slot
            :options="options"
            :selectOption="selectOption"
            :selectedIndex="selectedIndex"
          >
            <div
              v-if="search"
              class="search relative flex flex-row items-center border-b border-grey-300 py-3"
            >
              <i class="material-icons-outlined absolute left-1 text-grey-300"
                >search</i
              >
              <input
                type="text"
                v-model="s"
                class="flex-auto pl-8"
                placeholder="Search..."
              />
              <i
                @click="s = null"
                class="material-icons-outlined cursor-pointer rounded-full text-grey-200 hover:bg-grey-200 hover:text-white"
              >
                close
              </i>
            </div>
            <div v-for="(option, index) in getOptions" :key="option.id">
              <span
                v-if="option.disabled && option.disabled == true"
                class="select-menu-option block cursor-not-allowed px-4 py-3 text-grey-400"
              >
                {{ option.label }}
              </span>
              <span
                v-else
                @click="selectOption(option)"
                class="select-menu-option block cursor-pointer px-4 py-3 hover:bg-grey-100"
                :class="
                  index == selectedIndex ? 'bg-grey-200' : 'hover:bg-grey-50'
                "
              >
                {{ option.label }}
              </span>
            </div>
          </slot>
        </div>
      </div>
    </div>

    <ValidationErrors :errors="errors" :name="name" />
  </div>
</template>

<script>
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import ValidationErrors from '@/components/ValidationErrors';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'MdSelectField',

  components: {
    ValidationErrors,
  },

  directives: {
    ClickOutside,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    value: {},
    label: { type: String },
    required: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    search: { type: Boolean, default: false },
  },

  data() {
    return {
      visible: false,
      selectedIndex: null,
      firstVisiblePosition: 0,
      visibleRange: 5,
      optionHeight: 48,
      s: null,
    };
  },

  computed: {
    /**
     * Set the total height of the scroll area.
     *
     * This will be the height of one option, multiplied by the visible range.
     */
    scrollHeight() {
      return this.visibleRange * this.optionHeight + 'px';
    },

    floatLabel() {
      return !isNull(this.value) || this.focused || this.visible;
    },

    selectedLabel() {
      if (this.value == null || !this.options.length) {
        return;
      }

      const option = find(this.options, { id: this.value });
      if (option == undefined) {
        return;
      }
      return option.label;
    },
    getOptions() {
      return this.options.filter((option) => {
        if (this.s != null && this.s != '') {
          if (option.label.toLowerCase().includes(this.s.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    },
  },

  watch: {
    /**
     * Set the scroll position whenever the first visible position has changed.
     */
    firstVisiblePosition() {
      this.$refs.optionsScrollArea.scrollTo(
        0,
        this.firstVisiblePosition * this.optionHeight
      );
    },

    options(newOptions, oldOptions) {
      if (!this.options.length) {
        return;
      }

      if (isEqual(newOptions, oldOptions)) {
        return;
      }

      this.selectedIndex = 0;
    },

    visible() {
      this.visiblityChanged();
    },
  },

  beforeDestroy() {
    this.removeKeyBindings();
  },

  methods: {
    selectOption(option) {
      this.$emit('input', option.id);
      this.close();
    },

    visiblityChanged() {
      if (this.visible) {
        // Blur the toggle button so pressing enter won't close dropdown.
        this.$refs.input.blur();
        this.registerKeyBindings();
        return;
      }

      this.removeKeyBindings();
    },

    registerKeyBindings() {
      window.addEventListener('keydown', this.handleKeyPress);
    },

    removeKeyBindings() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },

    handleKeyPress(event) {
      switch (event.keyCode) {
        case 13:
          this.handleEnter(event);
          break;

        case 38:
          this.handleUpArrow(event);
          break;

        case 40:
          this.handleDownArrow(event);
          break;
      }
    },

    handleEnter(event) {
      event.preventDefault();

      const option = this.options[this.selectedIndex];
      this.selectOption(option);

      this.close();
    },

    handleUpArrow(event) {
      event.preventDefault();

      // select the previous item if there is one
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;

        // scroll up only if we've moved before the first visible position
        if (this.selectedIndex < this.firstVisiblePosition) {
          this.firstVisiblePosition = this.selectedIndex;
        }

        return;
      }

      // otherwise go to the end of the list
      this.selectedIndex = this.options.length - 1;

      let newFirstPosition = this.selectedIndex - this.visibleRange + 1;

      if (newFirstPosition >= 0) {
        this.firstVisiblePosition = newFirstPosition;
        return;
      }

      this.firstVisiblePosition = 0;
    },

    handleDownArrow(event) {
      event.preventDefault();

      // handle initial selection
      if (isNull(this.selectedIndex)) {
        this.selectedIndex = 0;
        return;
      }

      // select the next item if there is one
      if (this.selectedIndex + 1 < this.options.length) {
        this.selectedIndex = this.selectedIndex + 1;

        if (
          this.selectedIndex >
          this.firstVisiblePosition + this.visibleRange - 1
        ) {
          this.firstVisiblePosition =
            this.selectedIndex - this.visibleRange + 1;
        }

        return;
      }

      this.selectedIndex = 0;
      this.firstVisiblePosition = 0;
    },

    close() {
      this.visible = false;
    },

    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
