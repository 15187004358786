<template>
  <div class="w-full overflow-auto">
    <h4 class="pt-2 text-lg font-bold">Audio</h4>
    <div
      class="flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"
    >
      <div>
        <div>Audio input device</div>
        <div class="py-1 text-sm text-grey-400">
          Select the audio input device for comms/talkback.
          <span v-if="commsConnected !== false">
            Disconnect comms/talkback to change input device.</span
          >
        </div>
      </div>
      <DropdownMenu ref="inputDropdown" class="w-full">
        <template slot="control" slot-scope="slotProps">
          <i
            v-if="audioInputDevices.length == 0"
            class="material-icons-outlined animate-spin"
          >
            sync
          </i>
          <button
            v-else
            @click.prevent="slotProps.toggle"
            class="dropdownListItemWithIcon"
            :disabled="commsConnected !== false"
          >
            <div class="truncate">
              {{
                selectedAudioInputDevice ? selectedAudioInputDevice.label : ''
              }}
            </div>
            <i class="material-icons-outlined">keyboard_arrow_down</i>
          </button>
        </template>

        <template slot="menu">
          <button
            @click.prevent="setInputDevice(device)"
            v-for="(device, index) in audioInputDevices"
            :key="device.deviceId"
            class="dropdownListItemWithIcon"
          >
            {{ device.label }}
            <div class="mr-3 w-8">
              <i
                v-if="
                  (!sltAudioInputDeviceId && index == 0) ||
                  sltAudioInputDeviceId == device.deviceId
                "
                class="material-icons-outlined text-green-500"
              >
                check
              </i>
            </div>
          </button>
        </template>
      </DropdownMenu>
    </div>
    <div
      class="flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"
    >
      <div>
        <div>Audio output device</div>
        <div class="text-sm text-grey-400">Select audio device for output</div>
      </div>
      <DropdownMenu ref="outputDropdown" class="w-full">
        <template slot="control" slot-scope="slotProps">
          <button
            @click.prevent="slotProps.toggle"
            class="dropdownListItemWithIcon"
            :disabled="commsConnected === false"
          >
            <div class="truncate">
              {{
                selectedAudioOutputDevice ? selectedAudioOutputDevice.label : ''
              }}
            </div>
            <i class="material-icons-outlined">keyboard_arrow_down</i>
          </button>
        </template>

        <template slot="menu">
          <button
            @click.prevent="setOutputDevice(device)"
            v-for="(device, index) in audioOutputDevices"
            :key="device.deviceId"
            class="dropdownListItemWithIcon"
          >
            {{ device.label }}
            <div class="mr-3 w-8">
              <i
                v-if="
                  (!sltAudioOutputDeviceId && index == 0) ||
                  sltAudioOutputDeviceId == device.deviceId
                "
                class="material-icons-outlined text-green-500"
              >
                check
              </i>
            </div>
          </button>
        </template>
      </DropdownMenu>
    </div>
    <h4 class="pt-2 text-lg font-bold">Keyboard shortcuts</h4>
    <div
      class="flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"
    >
      <div>
        <div class="text-sm text-grey-400">
          Keyboard shortcuts can be used to perform quick actions in Studio
        </div>
        <div class="pt-2">ALT + Space</div>
        <div class="text-sm text-grey-400">Cut</div>
        <div class="pt-2">ALT + Enter</div>
        <div class="text-sm text-grey-400">Fade</div>
        <div class="pt-2">ALT + Right Arrow</div>
        <div class="text-sm text-grey-400">Next scene</div>
        <div class="pt-2">ALT + Left Arrow</div>
        <div class="text-sm text-grey-400">Previous scene</div>
        <div class="pt-2">ALT + O</div>
        <div class="text-sm text-grey-400">Remove overlay</div>
        <div class="pt-2">ALT + L</div>
        <div class="text-sm text-grey-400">Remove all latched audio</div>
        <div class="pt-2">Left Shift (hold)</div>
        <div class="text-sm text-grey-400">Activate comms talk</div>
        <div class="pt-2">Left Shift (double press)</div>
        <div class="text-sm text-grey-400">Latch comms talk toggle</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownMenu from '@/components/DropdownMenu';

import { getAudioDevices } from '@/helper/devices.js';

export default {
  name: 'StudioPanelSettings',
  components: {
    DropdownMenu,
  },
  props: {
    commsConnected: { type: [Boolean, String] },
  },
  data: () => ({
    audioInputDevices: [],
    audioOutputDevices: [],
  }),
  computed: {
    ...mapState(['sltAudioInputDeviceId', 'sltAudioOutputDeviceId']),

    selectedAudioInputDevice() {
      let device;
      if (this.sltAudioInputDeviceId) {
        device = this.audioInputDevices.find(
          (ad) => ad.deviceId == this.sltAudioInputDeviceId
        );
      }
      return device || this.audioInputDevices[0];
    },

    selectedAudioOutputDevice() {
      let device;
      if (this.sltAudioOutputDeviceId) {
        device = this.audioOutputDevices.find(
          (ad) => ad.deviceId == this.sltAudioOutputDeviceId
        );
      }

      return device || this.audioOutputDevices[0];
    },
  },
  created() {
    getAudioDevices().then(({ aInput, aOutput }) => {
      this.audioInputDevices = Array.from(aInput.values());
      this.audioOutputDevices = Array.from(aOutput.values());
    });
  },
  mounted() {
    console.log(this.audioOutputDevices);
  },
  methods: {
    setInputDevice(device) {
      this.$refs.inputDropdown.toggle();
      this.$store.commit('SET_AUDIO_INPUT_DEVICE', device.deviceId);
    },
    setOutputDevice(device) {
      this.$refs.outputDropdown.toggle();
      this.$store.commit('SET_AUDIO_OUTPUT_DEVICE', device.deviceId);
    },
  },
};
</script>
