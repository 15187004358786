<template>
  <div class="h-full w-full">
    <!-- header -->
    <div class="flex items-center justify-end py-4">
      <template v-if="!isReorder">
        <button
          @click.prevent="toggleReorder"
          class="group mr-2 flex items-center justify-center space-x-1 rounded-full px-2 py-1"
        >
          <i class="material-icons-outlined group-hover:text-blue-500"
            >reorder</i
          >
          <span class="text-sm text-grey-800 group-hover:text-blue-500"
            >Reorder</span
          >
        </button>

        <slot name="header"></slot>
      </template>

      <template v-if="isReorder">
        <div class="flex w-full items-center justify-between">
          <div>Reordering</div>
          <div class="flex">
            <button
              @click.prevent="isReorder = false"
              class="ml-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">close</i>
            </button>
            <button
              @click.prevent="saveReorder"
              class="ml-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">check</i>
            </button>
          </div>
        </div>
      </template>
    </div>

    <!-- list -->
    <Draggable
      v-model="getItems"
      :disabled="!isReorder"
      draggable=".item"
      class="flex w-full flex-col"
    >
      <TransitionGroup type="transition" :name="transitionName">
        <div
          v-for="(item, index) in getItems"
          :key="item.id"
          class="item flex items-start justify-between border-b border-grey-100 bg-white py-4"
          :class="{ 'cursor-move': isReorder }"
        >
          <slot name="item" :item="item" :index="index"></slot>

          <div>
            <slot
              v-if="!isReorder"
              name="actions"
              :item="item"
              :index="index"
            ></slot>
            <i v-else class="material-icons-outlined p-1">reorder</i>
          </div>
        </div>
      </TransitionGroup>
    </Draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'ReorderListView',
  props: {
    items: {
      type: Array,
      required: true,
    },
    transition: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Draggable,
  },
  data: () => ({
    list: [],
    isReorder: false,
  }),
  computed: {
    getItems: {
      get() {
        if (this.isReorder) return this.list;
        else return this.items;
      },
      set(value) {
        this.list = value;
      },
    },
    transitionName() {
      if (this.transition) {
        return 'flip-list';
      } else {
        return '';
      }
    },
  },
  methods: {
    toggleReorder() {
      this.isReorder = !this.isReorder;

      if (this.isReorder) {
        this.list = this.items.map((item) => {
          return { ...item };
        });
      }
    },
    saveReorder() {
      //save reorder result
      this.$emit('onUpdate', this.list);

      //delay toggle to smooth transition
      setTimeout(() => {
        this.isReorder = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.25s;
}
</style>
