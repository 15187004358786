var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout_wrap"},[_c('div',{ref:"layout",staticClass:"layout"},[_c('div',[(!_vm.isLoaded)?_c('div',[_vm._v("Loading...")]):_vm._l((_vm.layout.slots),function(slot){return _c('div',{key:slot.slotNo,staticClass:"slot",class:{
          selected: _vm.selectedSlot == slot,
          done: slot.source && slot.source != null ? true : false,
        },style:({
          zIndex: slot.slotNo,
          width: _vm.width(slot.width, _vm.offsetWidth) + 'px',
          height: _vm.height(slot.height, _vm.offsetHeight) + 'px',
          left: _vm.posLeft(slot.x, _vm.offsetWidth) + 'px',
          top: _vm.posTop(slot.y, _vm.offsetHeight) + 'px',
        }),on:{"click":function($event){return _vm.handleSelectSlot(slot)}}},[_vm._v(" "+_vm._s(slot.slotNo)+" ")])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }