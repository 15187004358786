<template>
  <transition
    mode="out-in"
    v-bind="$attrs"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
    :css="false"
    :key="uniqueKey"
    appear
  >
    <slot></slot>
  </transition>
</template>

<script>
import Velocity from 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';

export default {
  props: {
    animation: {
      default: 'transition.fadeIn',
    },
    leaveAnimation: {
      default: 'transition.fadeOut',
    },
    delay: {
      type: Number,
      default: 0,
    },
    leaveDelay: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 150,
    },
    uniqueKey: null,
    callback: null,
  },

  methods: {
    beforeEnter: function (el) {
      el.style.opacity = 0;
    },

    enter: function (el) {
      Velocity(el, this.animation, {
        delay: this.delay,
        duration: this.duration,
        runOnMount: true,
        targetQuerySelector: null,
        interruptBehavior: 'stop',
        visibility: 'visible',
        easing: [0.4, 0.0, 0.2, 1],
        display: null,
        setRef: undefined,
        complete: () => {
          if (this.callback) {
            this.callback();
          }
        },
      });
    },

    leave: function (el) {
      Velocity(el, this.leaveAnimation, {
        delay: this.leaveDelay,
        duration: this.duration,
        runOnMount: false,
        targetQuerySelector: null,
        interruptBehavior: 'stop',
        visibility: 'hidden',
        easing: [0.4, 0.0, 0.2, 1],
        display: null,
        setRef: undefined,
        complete: () => {
          if (this.callback) {
            this.callback();
          }

          // We need to remove the height after removing an element up and out.
          if (this.leaveAnimation == 'transition.slideUpOut') {
            el.style.height = 0;
          }

          if (this.leaveAnimation == 'transition.fadeOut') {
            // el.style.height = 0;
          }
        },
      });
    },
  },
};
</script>
