<template>
  <div
    class="flex w-full flex-1 rounded-full border-2 border-grey-200 bg-white"
  >
    <button
      @click.prevent="initAction()"
      class="flex-1 items-center justify-center rounded-l-full border-r border-grey-200 px-4 py-4 hover:bg-blue-100"
      :disabled="disabled"
    >
      <div class="flex items-center justify-center">
        <i class="material-icons-outlined pr-1 text-base">{{ icon }}</i>
        <div>{{ value | upperFirst }}</div>
      </div>
    </button>

    <DropdownMenu :width="48" ref="dropdown">
      <template slot="control" slot-scope="slotProps">
        <button
          @click.prevent="slotProps.toggle()"
          class="flex h-full w-full items-center justify-center rounded-full px-4"
        >
          <i class="material-icons-outlined">keyboard_arrow_down</i>
        </button>
      </template>

      <template slot="menu">
        <button
          v-for="(option, index) in options"
          :key="index"
          @click.prevent="selectOption(option)"
          class="dropdownListItem"
        >
          {{ option | upperFirst }}
        </button>
      </template>
    </DropdownMenu>
  </div>
</template>

<script>
import DropdownMenu from '@/components/DropdownMenu';

export default {
  components: {
    DropdownMenu,
  },

  props: ['value', 'options', 'icon'],

  data: () => ({
    disabled: false,
    disabledTimeout: null,
  }),

  methods: {
    initAction() {
      this.$emit('selected');
      this.disabled = true;

      clearTimeout(this.disabledTimeout);
      this.disabledTimeout = setTimeout(() => {
        this.disabled = false;
      }, 250);
    },

    selectOption(option) {
      this.$emit('change', option);
      this.$refs.dropdown.close();
    },
  },
};
</script>

<style scoped>
button:disabled {
  opacity: 0.75;
  background-color: inherit !important;
}
</style>
