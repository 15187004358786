<template>
  <li
    class="flex justify-between border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
    @dblclick.prevent="handleInputUp"
  >
    <div class="flex flex-1 flex-auto flex-col items-start justify-center">
      <h4 class="flex flex-row font-medium">
        {{ routerInput.name }}
        <div
          class="ml-3 flex flex-row items-center justify-start rounded border border-orange-500 px-1 text-xs text-orange-500"
        >
          <i class="material-icons-outlined text-xs text-orange-500">lock</i>
          TODO bitrate
        </div>
      </h4>
      <div class="text-grey-400" :class="{ 'text-green-500': isPublishing }">
        {{ isPublishing ? 'Publishing' : 'Offline' }}
      </div>
    </div>

    <!-- active router input -->
    <div class="flex items-center justify-end">
      <TooltipModal
        v-if="
          type == 'active' && ['rehearsal', 'live'].includes(event.eventState)
        "
        triggerType="hover"
      >
        <template slot="control">
          <button
            @click.once="handleInputDown"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">download</i>
          </button>
        </template>

        <template slot="content">
          <div>
            <span class="font-bold text-red-500">Remove this Router Input</span>
            live source.
          </div>
        </template>
      </TooltipModal>

      <TooltipModal
        v-if="
          type != 'active' && ['rehearsal', 'live'].includes(event.eventState)
        "
        triggerType="hover"
      >
        <template slot="control">
          <button
            :disabled="!isAllowAddSource"
            @click.once="handleInputUp"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">upload</i>
          </button>
        </template>

        <template slot="content">
          <div v-if="isAllowAddSource">
            <span class="font-bold text-blue-500">Add this Router Input</span>
            as a live source.
          </div>
          <div v-else>
            You have reached the maximum number of live sources for this event.
            Remove an existing source to add this Router Input.
          </div>
        </template>
      </TooltipModal>
    </div>
  </li>
</template>

<script>
import TooltipModal from '@/components/TooltipModal';
import { mapState } from 'vuex';
import api from '@/services/api';

export default {
  components: {
    TooltipModal,
  },
  name: 'StudioPanelRouterInput',
  props: {
    routerInput: { type: Object, required: true },
    type: { type: String, required: true },
    isPromoted: { type: Boolean, required: true },
    isPublishing: { type: Boolean, required: true },
  },
  computed: {
    ...mapState(['isAllowAddSource', 'bitrateList', 'sources', 'event']),
  },
  methods: {
    handleInputUp() {
      if (this.isAllowAddSource) {
        const url = `/mixer/${this.event.jobId}/addManagedSource`;
        const body = {
          type: 'IoClient',
          ioClientId: this.routerInput.id,
        };
        api.post(url, body);
      }
    },
    handleInputDown() {
      const source = this.sources.find(
        (s) => s.ioClientId == this.routerInput.id
      );
      const url = `/mixer/${this.event.jobId}/managedSource/${source.sid}`;
      api.delete(url);
    },
  },
};
</script>
