<template>
  <div class="flex flex-col justify-start">
    <div class="relative mb-8 mt-2 flex items-start">
      <label
        v-if="name"
        class="float-label absolute text-grey-400"
        :class="floatLabel ? 'float-label-active' : 'cursor-text'"
        :for="name"
        >{{ label }}</label
      >

      <textarea
        ref="input"
        class="w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500"
        :class="{ 'is-invalid': errors.has(name) }"
        :id="name"
        :name="name"
        :rows="rows"
        autocomplete="off"
        :value="value"
        :disabled="disabled"
        :autofocus="autofocus"
        :required="required"
        @focus="focused = true"
        @blur="focused = false"
        @input="handleInput"
      ></textarea>

      <TooltipModal v-if="tooltip">
        <template slot="control">
          <div class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
            <i class="material-icons-outlined text-icon-sm">info</i>
          </div>
        </template>

        <template slot="content">
          {{ tooltip }}
        </template>
      </TooltipModal>
    </div>

    <div>
      <ValidationErrors :errors="errors" :name="name" />
    </div>
  </div>
</template>

<script>
import autosize from 'autosize';
import TooltipModal from '@/components/TooltipModal';
import ValidationErrors from '@/components/ValidationErrors';

export default {
  components: {
    TooltipModal,
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    value: { type: String },
    label: { type: String },
    autofocus: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    tooltip: { type: String },
    disabled: { type: Boolean, default: false },
    rows: { default: 5 },
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    floatLabel() {
      return this.value != null || this.focused;
    },
  },

  mounted() {
    this.$nextTick(() => {
      autosize(this.$refs.input);
    });
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.currentTarget.value);
    },
  },
};
</script>
