<template>
  <div class="w-auto bg-white shadow-lg lg:w-2/3 xl:w-1/2">
    <div class="flex flex-col lg:flex-row">
      <div
        class="order-last flex flex-col justify-between bg-grey-100 p-8 lg:order-first"
      >
        <div class="mb-6 text-center text-3xl font-bold">
          Your all-in-one virtual event platform
        </div>
        <div class="space-y-6">
          <div class="flex items-center space-x-2">
            <i class="material-icons-outlined text-3xl text-green-500"
              >check_circle</i
            >
            <div class="text-xl text-grey-600">
              FREE to try all features for 30-days
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <i class="material-icons-outlined text-3xl text-green-500"
              >check_circle</i
            >
            <div class="text-xl text-grey-600">
              Customisable branding for your events
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <i class="material-icons-outlined text-3xl text-green-500"
              >check_circle</i
            >
            <div class="text-xl text-grey-600">Inbuilt registration</div>
          </div>
          <div class="flex items-center space-x-2">
            <i class="material-icons-outlined text-3xl text-green-500"
              >check_circle</i
            >
            <div class="text-xl text-grey-600">
              Powerful video creation and layout tools
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <i class="material-icons-outlined text-3xl text-green-500"
              >check_circle</i
            >
            <div class="text-xl text-grey-600">No credit card required</div>
          </div>
        </div>
        <!-- marketing video -->
        <div
          class="wistia_responsive_padding my-6"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <div
            class="wistia_responsive_wrapper"
            style="
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            "
          >
            <iframe
              src="https://fast.wistia.net/embed/iframe/f1gjnruq2d?seo=false&videoFoam=true"
              title="Joinin Intro Video"
              allow="autoplay; fullscreen"
              allowtransparency="true"
              frameborder="0"
              scrolling="no"
              class="wistia_embed"
              name="wistia_embed"
              allowfullscreen
              msallowfullscreen
              width="100%"
              height="100%"
            >
            </iframe>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center p-8 lg:w-2/3">
        <div class="w-auto lg:w-96">
          <div class="flex-1 justify-between">
            <div class="flex-1 text-center">
              <div class="mb-3 text-center text-3xl font-bold">
                Start your free trial
              </div>
              <div class="mb-6 text-center text-xl text-grey-400">
                Please contact us to arrange a demonstration and trial account.
              </div>
              <a
                href="mailto:support@joinin.live?subject=Joinin trial account"
                class="text-blue-500"
                >Contact support</a
              >
            </div>
            <!--
                Disable sign up
                <div class="font-bold text-3xl text-center mb-3">Start a free trial now!</div>
                <div class="text-xl text-center text-grey-400 mb-6">No credit card required</div>

                <form @submit.prevent="save()">
                    <TextField name="first_name"
                        label="First name"
                        v-model="form.firstName"
                        :errors="errors" />

                    <TextField name="last_name"
                        label="Last name"
                        v-model="form.lastName"
                        :errors="errors" />

                    <EmailField name="email"
                        label="Email"
                        v-model="form.email"
                        :errors="errors" />

                    <PasswordField name="password"
                        label="Password"
                        v-model="form.password"
                        :errors="errors" />

                    <TextField name="company"
                        label="Company"
                        v-model="form.company"
                        :errors="errors" />

                    <CountrySelect
                            v-model="form.country"
                            :country="form.country"
                            placeholder="Country"
                            topCountry="AU"
                            required
                            disablePlaceholder
                            class="input mb-6" />

                    <div class="flex-1 flex flex-col mb-4">
                        <label class="flex items-start w-full cursor-pointer">
                            <input
                                class="bg-grey-200 focus:bg-grey-400 flex-shrink-0 h-4 w-4"
                                type="checkbox"
                                id="terms"
                                name="terms"
                                autocomplete="off"
                                required
                                v-model="form.terms" />
                            <span class="ml-2 text-grey-800 text-xs"
                                for="terms">
                                I agree with the <a href="https://www.joinin.live/terms" target="_blank" class="text-blue-500 hover:text-blue-800">Terms & Conditions</a>
                                and <a href="https://www.crowdconvergence.com/privacy-policy" target="_blank" class="text-blue-500 hover:text-blue-800">Privacy Policy</a>
                            </span>
                        </label>

                        <ValidationErrors :errors="errors"
                            :field="{ name: 'terms' }" />
                    </div>

                    <div class="flex items-start space-x-4 mb-4">
                        <label class="flex items-start w-full cursor-pointer">
                            <input
                                class="bg-grey-200 focus:bg-grey-400 flex-shrink-0 h-4 w-4"
                                type="checkbox"
                                id="gdpr"
                                name="gdpr"
                                autocomplete="off"
                                required
                                v-model="form.gdpr" />
                            <span class="ml-2 text-grey-800 text-xs"
                                for="gdpr">
                                I consent to Joinin storing and processing my submitted information
                            </span>
                        </label>

                        <ValidationErrors :errors="errors"
                            :field="{ name: 'gdpr' }" />

                        <div>
                            <TooltipModal
                                yAlign="above">
                                <template slot="control">
                                    <button class="flex space-x-1 items-center text-sm">
                                        <i class="material-icons-outlined text-icon-sm">info</i>
                                    </button>
                                </template>

                                <template slot="content">
                                    <div class="flex flex-col space-y-4 text-sm">
                                        <div>
                                            <span class="font-bold">This information applies to residents of the European Economic Area only.</span>
                                        </div>
                                        <div>
                                            The information submitted to this website will be submitted directly to a company in Australia and stored on servers based in Australia. Your data will not at any time be hosted or stored in the European Economic Area.
                                        </div>
                                        <div>
                                            In submitting data to us, you understand that:
                                            <br>
                                            <span class="text-xs">
                                                - We are the controller and processor of the data.
                                                <br>
                                                - We rely on the derogations at Article 49 of the GDPR as the basis upon which your data is transferred outside of the European Economic Area. We rely on these derogations in order to fulfil the contract you are entering into directly with us.
                                                <br>
                                                - As we rely on the derogations under the GDPR, there is a risk to you that your data may not at all times be handled in a manner consistent with the GDPR. Please refer to our Privacy Policy for full details of how your data will handled.
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </TooltipModal>
                        </div>

                    </div>

                    <div v-if="submitError != null" class="text-red-500 px-2 py-1 mt-3 mb-1 flex flex-row items-center">
                      <i class="material-icons-outlined text-red-500 mr-2">error</i>
                      {{ submitError }}
                    </div>

                    <SubmitButton :saving="saving"
                        label="Start free trial" />
                </form>
            -->

            <LoginLink label="event" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
<script>
import random from 'lodash/random';
import { CountrySelect } from 'vue-country-region-select';

import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import dayjs from 'dayjs';
import errors from '@/mixins/errors';
import TextField from '@/components/TextField';
import EmailField from '@/components/EmailField';
import PasswordField from '@/components/PasswordField';
import SubmitButton from '@/components/SubmitButton';
import ValidationErrors from '@/components/ValidationErrors';
import LoginLink from '@/components/LoginLink';
import TooltipModal from '@/components/TooltipModal';

export default {
  mixins: [errors],

  components: {
    TextField,
    EmailField,
    PasswordField,
    SubmitButton,
    ValidationErrors,
    LoginLink,
    TooltipModal,
    CountrySelect,
  },

  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        country: null,
        company: null,
        terms: false,
        gdpr: false,
        utmString: null,
      },
      saving: false,
    };
  },

  mounted() {
    // set utmString to cookie value
    // this cookie is set via google tag manager
    this.form.utmString = this.getCookiebyName('__gtm_campaign_url');
  },

  methods: {
    getCookiebyName(name) {
      var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
      return !!pair ? pair[1] : null;
    },

    save() {
      this.saving = true;

      api.post('/signup', this.form).then(
        async (response) => {
          const sessionData = (await api.get('/account/getSession')).data;
          this.$store.commit('loggedIn', sessionData);

          const { user, account } = sessionData;

          const created_at = dayjs(user.createdAt).unix();

          // fire google tag manager event
          this.$gtm.trackEvent({
            event: 'sign-up-form-submission', // Event type [default = 'interaction'] (Optional)
          });

          this.notify('success', 'Welcome.');

          this.$router.replace(this.$store.state.intended || '/dashboard');
        },
        (error) => {
          if (error?.response?.data?.error == 'duplicate email') {
            this.submitError = 'That email is already in use';
          } else {
            this.handleSaveError(error);
          }
          this.saving = false;
        }
      );
    },
  },
};
</script>
