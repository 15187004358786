<template>
  <div>
    <div v-if="account == null">Loading</div>

    <div v-else>
      <div class="mb-6 pt-6 text-lg font-medium">Your subscription</div>
      <div v-if="isTrialAccount">
        <div v-if="isTrialValid" class="pb-4">
          <div
            class="mb-4 rounded-lg border-2 border-blue-500 px-6 py-4 text-center"
          >
            <div class="text-xl font-bold">Trial plan</div>
            <div>You have free access until {{ trialExpiry }}.</div>
          </div>
        </div>
        <div v-else class="pb-4">
          <div
            class="mb-4 rounded-lg border-2 border-red-700 bg-red-100 px-6 py-4 text-center"
          >
            <div class="text-xl font-bold">Trial expired</div>
            <div>
              Upgrade your plan below to continue creating amazing online
              events.
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="text-grey-400">Plan</div>

        <div v-if="account.type != 'enterprise'">
          <div class="pb-6 capitalize">
            {{ account.type }} ({{
              account.stripeSubscriptionInterval == 'month'
                ? 'Monthly'
                : 'Annually'
            }})
          </div>

          <div v-if="subscriptionStatus">
            <div class="text-grey-400">Status</div>
            <div
              class="pb-6 capitalize"
              :class="{
                'text-green-800': subscriptionStatus == 'active',
                'text-red-500': subscriptionStatus == 'canceled',
              }"
            >
              {{ subscriptionStatus }}
            </div>
          </div>

          <div v-if="subscriptionStatus == 'past_due'" class="pb-4">
            <div
              class="mb-4 rounded-lg border-2 border-orange-500 bg-orange-100 px-6 py-4 text-center"
            >
              <div class="text-xl font-bold">Payment overdue</div>
              <div>
                Your subscription payment is overdue. Please update your payment
                method now to ensure continued access to your account.
              </div>
              <div
                @click.prevent="manageStripeSubscription"
                class="cursor-pointer text-blue-800 hover:text-blue-500"
              >
                Update payment method.
              </div>
            </div>
          </div>

          <div
            v-if="
              subscriptionStatus == 'canceled' || subscriptionStatus == 'unpaid'
            "
            class="pb-4"
          >
            <div
              class="mb-4 rounded-lg border-2 border-red-700 bg-red-100 px-6 py-4 text-center"
            >
              <div class="text-xl font-bold">Subscription canceled</div>
              <div class="">
                Your subscription has been canceled. Resubscribe to access your
                account.
              </div>
            </div>
          </div>

          <div class="flex flex-1 items-center justify-center space-x-4">
            <button
              class="secondary-btn hidden items-center space-x-2 lg:flex"
              @click.prevent="manageStripeSubscription"
            >
              <i class="material-icons-outlined">subscriptions</i>
              <div>Manage subscription</div>
            </button>
            <button
              class="secondary-btn hidden items-center space-x-2 lg:flex"
              @click.prevent="manageStripeSubscription"
            >
              <i class="material-icons-outlined">receipt</i>
              <div>Download invoices</div>
            </button>
          </div>
        </div>
        <div v-else>
          Your account is on an Enterprise plan. Please
          <a href="mailto:support@joinin.live" class="text-blue-500"
            >contact support</a
          >
          for billing assistance.
        </div>
      </div>

      <!-- pricing table -->
      <div v-if="account.type != 'enterprise'">
        <div class="mb-6 pt-12 text-lg font-medium">Available plans</div>
        <div class="flex flex-1 justify-center">
          <div class="w-3/4">
            <div class="switch">
              <div class="button-slider">
                <div class="button-slider__wrapper">
                  <div
                    @click="handleBillingSwitch('month', $event)"
                    data-billing="month"
                    class="button-slider__btn plan--month"
                  >
                    <span data-billing="month">Monthly</span>
                  </div>
                  <div
                    @click="handleBillingSwitch('year', $event)"
                    data-billing="year"
                    class="button-slider__btn plan--year active"
                  >
                    <span data-billing="year">Annual</span>
                    <span data-billing="year" class="discount">up to -20%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pricing-choose">
              <div class="text-center">
                <div class="tabsplan-wrapper">
                  <div
                    data-cy="static-tabs"
                    id="static-tabs"
                    class="choose-plan-tabs"
                  >
                    <div role="group" class="choose-plan-tabs__group btn-group">
                      <button
                        v-if="account.type == 'trial'"
                        @click="handleTabSwitch"
                        type="button"
                        class="btn choose-plan-tabs__item btn-transparent"
                        data-tab="free"
                      >
                        Free trial
                      </button>
                      <button
                        @click="handleTabSwitch"
                        type="button"
                        class="btn choose-plan-tabs__item btn-transparent"
                        data-tab="one"
                      >
                        Basic
                      </button>
                      <button
                        @click="handleTabSwitch"
                        type="button"
                        class="btn choose-plan-tabs__item btn-transparent active"
                        data-tab="two"
                      >
                        Standard
                      </button>
                      <button
                        @click="handleTabSwitch"
                        type="button"
                        class="btn choose-plan-tabs__item btn-transparent"
                        data-tab="three"
                      >
                        Business
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pricing-wrapper">
              <div class="container">
                <div>
                  <div
                    data-cy="static-plans"
                    id="static-plans"
                    class="plan-section plan-section--4"
                  >
                    <div class="plan-draft">
                      <p class="plan-draft__title"></p>
                      <p class="plan-draft__desc"></p>
                    </div>
                    <div v-if="account.type == 'trial'" class="plan plan--free">
                      <p class="plan__title">Free trial</p>
                      <p class="plan__desc">
                        Explore all features and run amazing online events.
                      </p>
                      <div class="plan__content">
                        <div class="plan__promo_wrapper">
                          <div class="plan__price_wrapper plan--year active">
                            <p class="plan__price">
                              Free
                              <span class="currency symbol"></span>
                            </p>
                          </div>
                          <div class="plan__price_wrapper plan--month">
                            <p class="plan__price">
                              Free
                              <span class="currency symbol"></span>
                            </p>
                          </div>
                          <p class="plan__price_desc">
                            For 30 days. No credit card required
                          </p>
                        </div>
                        <div
                          v-if="isTrialValid"
                          class="plan__btn plan__btn__disabled"
                          target="_blank"
                        >
                          <span class="text-blue-500">Your plan</span>
                        </div>
                        <div
                          v-else
                          class="plan__btn plan__btn__disabled"
                          target="_blank"
                        >
                          Trial expired
                        </div>
                        <p class="plan__desc plan__desc--cancelation"></p>
                      </div>
                    </div>
                    <div class="plan plan--one">
                      <p class="plan__title">Basic</p>
                      <p class="plan__desc">
                        Everything you need to run quality online events and
                        webinars.
                      </p>
                      <div class="plan__content">
                        <div class="plan__promo_wrapper">
                          <div class="plan__price_wrapper plan--year active">
                            <p class="plan__price">
                              79
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Per month, billed annually until you cancel.
                              Excluding tax
                            </p>
                          </div>
                          <div class="plan__price_wrapper plan--month">
                            <p class="plan__price">
                              99
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Billed per month until you cancel. Excluding tax
                            </p>
                          </div>
                        </div>
                        <div
                          @click.prevent="changePlan('basic')"
                          class="plan__btn"
                        >
                          Select plan
                        </div>
                        <p class="plan__desc plan__desc--cancelation"></p>
                      </div>
                    </div>
                    <div class="plan plan--popular plan--two active">
                      <div class="popular">Most popular</div>
                      <p class="plan__title">Standard</p>
                      <p class="plan__desc">
                        More powerful branding, engagement and Studio features
                        to take your events and webinars to the next level.
                      </p>
                      <div class="plan__content">
                        <div class="plan__promo_wrapper">
                          <div class="plan__price_wrapper plan--year active">
                            <p class="plan__price">
                              249
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Per month, billed annually until you cancel.
                              Excluding tax
                            </p>
                          </div>
                          <div class="plan__price_wrapper plan--month">
                            <p class="plan__price">
                              299
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Billed per month until you cancel. Excluding tax
                            </p>
                          </div>
                        </div>
                        <div
                          @click.prevent="changePlan('standard')"
                          class="plan__btn"
                        >
                          Select plan
                        </div>
                        <p class="plan__desc plan__desc--cancelation"></p>
                      </div>
                    </div>
                    <div class="plan plan--three">
                      <p class="plan__title">Business</p>
                      <p class="plan__desc">
                        Perfect for companies seeking customisation and control.
                        Manage large online events and webinars without
                        compromise.
                      </p>
                      <div class="plan__content">
                        <div class="plan__promo_wrapper">
                          <div class="plan__price_wrapper plan--year active">
                            <p class="plan__price">
                              799
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Per month, billed annually until you cancel.
                              Excluding tax
                            </p>
                          </div>
                          <div class="plan__price_wrapper plan--month">
                            <p class="plan__price">
                              999
                              <span class="currency symbol">$</span>
                            </p>
                            <p class="plan__price_desc">
                              Billed per month until you cancel. Excluding tax
                            </p>
                          </div>
                        </div>
                        <div
                          @click.prevent="changePlan('business')"
                          class="plan__btn"
                        >
                          Select plan
                        </div>
                        <p class="plan__desc plan__desc--cancelation"></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="packets">
                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Events</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Number of events</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The total number of events you can organise with
                                your account. Subject to fair use.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>Unlimited</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Concurrent events</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The number of events you can run simultaneously
                                with your account.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>1</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>1</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>1</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>1</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Included rehearsal time</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The amount of free rehearsal time included with
                                any single event. Start and stop rehearsal on
                                demand to use your time across multiple
                                rehearsal sessions. Subject to fair use.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>Unlimited</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>Unlimited</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Number of attendees</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The number of attendees that can view your
                                event. Events can have a maximum of 2000
                                attendees.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>250</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>100</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>250</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>2000</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Number of presenters</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The number of presenters that can be online in
                                the Waiting or Live Room at any one time.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>6</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>3</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>6</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>25</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>HD Recording</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Live HD recording of events from the moment they
                                go live.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Stream quality</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The maximum stream quality delivered to
                                attendees. This is also the quality of the event
                                recording.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>1080p</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>1080p</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>1080p</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>1080p</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Video on Demand</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The attendee portal remains open so attendees
                                who missed the live session can view the
                                recording.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>GDPR consent collection</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Collect General Data Protection Regulation
                                (GRPR) consent information from attendees at
                                Registration/Login. When enabled permission must
                                be granted by the attendee to access the event.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Studio</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Number of live sources</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                The number of sources active and ready to go 'To
                                Air' at any one time. Live sources can be
                                swapped throughout your event. Images, videos,
                                presentations and media in your Studio but not
                                active do not count toward this number.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div>8</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div>8</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div>8</div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div>8</div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Image/video upload</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Upload image and video content and use this
                                media in the event video stream.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>PowerPoint/Keynote upload</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Upload PowerPoint, Keynote or PDF presentations
                                and use this media in the event video stream.
                                Control slides easily from the Studio.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Picture-in-picture layouts</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Show multiple live sources on screen
                                simultaneously. For example: 2 presenters, 8
                                presenters Brady Bunch style, PowerPoint
                                presentation and presenter, presenter and
                                interpreter. The options are endless.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Scene creation</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Save Scenes to program your event in advance
                                during rehearsal. A scene is a snapshot of the
                                current Studio state. Scenes can be recalled to
                                make operating your event even more simple.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>External stream inputs</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Connect external sources or studios via
                                high-quality SRT streams and use this media in
                                the event video stream.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Webpage inputs</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Add webpages as live sources and use this media
                                in the event video stream.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Private chat with presenters</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Chat privately with presenters via live audio
                                and text to prepare and run your event.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Moderate Chat, Q&A and manage Polls</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Moderate submitted content before it can be seen
                                by other attendees. Send replies to attendee
                                chat and Q&A. Create and manage polls and
                                responses.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Branding</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Event logo</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Upload an event logo to brand the event. Event
                                logos appear during registration, login, live
                                sessions and on all event emails.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Sponsor logo</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Upload a sponsor logo to brand the event.
                                Sponsor logos appear during registration, login,
                                live sessions and on all event emails.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Background</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Upload a background image or colour to brand the
                                event. Backgrounds appear during registration,
                                login and live sessions.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Colours and font</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Select the font and colours to brand the event.
                                Font and colours appear during registration,
                                login, live sessions and on all event emails.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Email branding</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                All emails to attendees and presenters are
                                branded with the event settings including event
                                logo, sponsor logo and colours.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Custom messaging</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Include custom messaging to attendees and
                                presenters. Different messaging is available for
                                registration, login, emails and reminders.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Engagement</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Chat</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Attendees can post comments and images
                                throughout the event. Chat messages are
                                moderated and, once approved, are visible to
                                attendees.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Q&A</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Attendees can post questions throughout the
                                event. Questions are moderated and, once
                                approved, are visible to attendees. Attendees
                                can submit anonymous questions and upvote other
                                questions.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Polling</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Attendees can vote on multiple choice polls and
                                view poll results during the event.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Quiz</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Boost engagement with an interactive quiz.
                                Attendees can participate in a live quiz and
                                track their position on the overall leaderboard.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Survey</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Collect feedback with an event survey. Invite
                                attendees to answer a custom survey form during
                                your event.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Custom page content</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Add static content to the attendee portal for
                                engagement and delivery of content. Examples
                                include a welcome message, agenda, presenter
                                biography, product information, etc.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Registration</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Pre-event registration portal</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                A branded pre-event registration portal for
                                collecting and managing attendee registration
                                information.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Customisable form fields</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Create and manage the fields attendees must
                                complete to register for events.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Reminder emails</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Automatic reminder emails sent to attendees
                                before your event starts.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Bulk attendee import</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Registered attendees can be added in bulk via a
                                CSV file upload.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Presenting</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <div class="packets-features-item__new">New</div>
                          <span>Presenter slide control</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Presenters can control their presentation
                                progression and view a slide preview so the
                                never lose track.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Waiting and Live rooms</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Separate Waiting and Live rooms for presenters
                                to enabled seamless onboarding without
                                interrupting the live event.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Private messaging</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Private presenter and operator messaging allows
                                non-verbal communication during live events.
                                Perfect for countdowns, host communication and
                                operational discussions.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Screen sharing</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Allow presenters to share their screen with
                                attendees.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Statistics & Reporting</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Event statistics</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Advanced live and post-event event metics to
                                track attendees and ROI.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Login tracking</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Attendee login, logout and duration online is
                                tracked and reported as proof of attendance.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Report download</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Download and store a complete report post-event
                                including all engagement, statistics and login
                                tracking.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Recording download</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Download the HD event recording moments after
                                the event is complete.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Account</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Multiple users</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Invite multiple users to collaborate on your
                                account and events.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>User access control</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Restrict users to specific roles with unique
                                functionality. E.g. Allow users to collaborate
                                on moderation and registration but not control
                                live event video.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Invoice download</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Manage and download simple and clear invoices
                                from your account.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion packets-features">
                    <div @click="toggleAccordion" class="accordion-header">
                      <div class="accordion-header__wrapper">
                        <p class="accordion_title">Support</p>
                      </div>
                      <div class="accordion_arrow active"></div>
                    </div>
                    <div class="accordion-content">
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Knowledge base</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Guides, articles, tips and documentation is
                                always available at https://support.joinin.live
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Ticketing</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Ticket based support with a 24-hour target
                                response time. Tickets are submitted via the
                                support portal https://support.joinin.live
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                      <div class="packets-features-row">
                        <div
                          class="packets-features-item packets-features-item--labels"
                        >
                          <span>Phone support</span>
                          <TooltipModal
                            triggerType="hover"
                            width="48"
                            textAlign="center"
                          >
                            <template slot="control">
                              <button
                                class="flex items-center space-x-1 text-sm"
                              >
                                <i
                                  class="material-icons-outlined pl-1 text-icon-sm"
                                  >info</i
                                >
                              </button>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Live phone support for training and assistance.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--free"
                          v-if="account.type == 'trial'"
                        >
                          <div class="dot"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--one"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--two packets-features-item--active"
                        >
                          <div class="dot dot-empty"></div>
                        </div>
                        <div
                          class="packets-features-item packets-features-item packets-features-item--three"
                        >
                          <div class="dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end pricing table -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import api from '@/services/api';
import { EventBus } from '@/services/event-bus';
import errors from '@/mixins/errors';
import TooltipModal from '@/components/TooltipModal';

export default {
  mixins: [errors],

  components: {
    TooltipModal,
  },

  props: {
    loading: Boolean,
    account: Object,
  },

  data() {
    return {
      saving: false,
      mode: 'year',
    };
  },
  mounted() {
    EventBus.$emit('close-modal');
  },
  computed: {
    ...mapState({
      user: (s) => s.sessionData.user,
    }),
    ...mapGetters([
      'trialExpiry',
      'isTrialAccount',
      'isTrialValid',
      'subscriptionStatus',
    ]),
  },
  methods: {
    manageStripeSubscription() {
      api.post('/account/update-plan').then((res) => {
        if (res.status >= 200 && res.status < 300) {
          window.location.replace(res.data.url);
        }
      });
    },
    changePlan(plan) {
      api
        .post('/account/update-plan', {
          type: plan,
          interval: this.mode,
          id: this.user.accountId,
        })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            window.location.replace(res.data.url);
          }
        })
        .catch(() => {
          this.notify(
            'error',
            'Your request could not be completed. Please contact support for assistance.'
          );
        });
    },
    toggleAccordion(e) {
      var el = e.target
        .closest('.accordion')
        .querySelector('.accordion-content');
      var arrow = e.target
        .closest('.accordion')
        .querySelector('.accordion_arrow');

      if (el.classList.contains('hide')) {
        el.classList.remove('hide');
        arrow.classList.add('active');
      } else {
        el.classList.add('hide');
        arrow.classList.remove('active');
      }
    },
    handleBillingSwitch(mode, e) {
      // change billing frequency
      document
        .querySelectorAll('div.button-slider__wrapper div')
        .forEach((el) => {
          el.classList.remove('active');
        });

      // set switch position
      document
        .querySelector(
          'div.button-slider__wrapper div.plan--' + e.target.dataset.billing
        )
        .classList.add('active');

      // show appropriate price
      document.querySelectorAll('div.plan__price_wrapper').forEach((el) => {
        el.classList.remove('active');
      });

      document
        .querySelectorAll('.plan--' + e.target.dataset.billing)
        .forEach((el) => {
          el.classList.add('active');
        });

      this.mode = mode;
    },
    handleTabSwitch(e) {
      // change plan
      document.querySelectorAll('div.choose-plan-tabs button').forEach((el) => {
        el.classList.remove('active');
      });

      e.target.classList.add('active');

      document.querySelectorAll('div.plan').forEach((el) => {
        el.classList.remove('active');
      });

      document
        .querySelector('#static-plans .plan--' + e.target.dataset.tab)
        .classList.add('active');

      document.querySelectorAll('.packets-features-item').forEach((el) => {
        el.classList.remove('packets-features-item--active');
      });

      document
        .querySelectorAll('.packets-features-item--' + e.target.dataset.tab)
        .forEach((el) => {
          el.classList.add('packets-features-item--active');
        });
    },
  },
};
</script>

<style>
.no-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.button-slider__wrapper {
  position: relative;
  background-color: #eef1f7;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  float: left;
  height: 40px;
  width: 350px;
}

@media only screen and (max-width: 576px) {
  .button-slider__wrapper {
    width: 100% !important;
  }
}

.button-slider__btn {
  position: relative;
  width: 50%;
  margin: 0 8px;
  padding: 8px 10px;
  color: #1b1f2b;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button-slider__btn span {
  position: relative;
  white-space: nowrap;
}

.button-slider__btn:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #00b4f0, #009ade);
  border-radius: 30px;
  transition: 0.4s;
  opacity: 0;
}

.button-slider__btn .discount {
  background-color: #ffbf00;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
  padding: 4px;
  border-radius: 3px;
  font-size: 0.75rem;
  font-weight: 700;
  margin-left: 8px;
  white-space: nowrap;
}

@media only screen and (max-width: 992px) {
  .button-slider__btn {
    font-size: 0.7rem;
  }

  .button-slider__btn .discount {
    font-size: 0.6rem;
    padding: 2px;
    margin-left: 3px;
  }
}

.button-slider__btn.active {
  color: #fff;
}

.button-slider__btn.active:first-child:before,
.button-slider__btn.active:last-child:before {
  transform: translateX(0);
  opacity: 1;
}

.button-slider__btn.active .discount {
  background-color: #ffbf00;
}

.button-slider__btn:first-child {
  margin-left: 5px;
}

.button-slider__btn:first-child:before {
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.button-slider__btn:last-child {
  margin-right: 5px;
}

.button-slider__btn:last-child:before {
  top: 0;
  right: 0;
  transform: translateX(-100%);
}

.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  flex-direction: column;
  position: relative;
}

.switch p {
  font-size: 0.875rem;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  position: static;
}

@media only screen and (max-width: 576px) {
  .switch .button-slider {
    width: 100%;
  }
}

/* button groups for plan selector on mobile */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.choose-plan-tabs__group {
  width: 100%;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px white solid;
}

.choose-plan-tabs .btn-group > .btn-group:not(:first-child),
.choose-plan-tabs .btn-group > .btn:not(:first-child) {
  margin-left: 0;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  border: 0;
  text-transform: uppercase;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-header {
  justify-content: space-between;
  padding: 13px 18px;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.accordion-header:hover {
  cursor: pointer;
}

.accordion-header,
.accordion-header__wrapper {
  display: flex;
  align-items: center;
}

.accordion_arrow:before {
  content: '';
  display: inline-block;
  height: 1em;
  width: 1em;
  margin: 0 6px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='i-angle-arrow' viewBox='0 0 100 100'%3E%3Cpath d='M77.3 48.2L31.8 2.8c-1-1-2.6-1-3.6 0l-5.5 5.5c-1 1-1 2.6 0 3.6L60.9 50 22.7 88.2c-1 1-1 2.6 0 3.6l5.5 5.5c1 1 2.6 1 3.6 0l45.4-45.4c1.1-1.1 1.1-2.7.1-3.7z'%3E%3C/path%3E%3C/svg%3E");
}

.accordion_arrow {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  width: 16px;
  height: 16px;
}

.accordion_arrow.active {
  transform: rotate(90deg);
}

.accordion_title {
  font-size: 0.8125rem;
  font-weight: 500;
  margin: 0;
  color: #6b7183;
}

.accordion-content {
  border-top: 0;
  max-height: 1000px;
  transition: max-height 0.3s ease-in;
}

.accordion-content.hide {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.active > .d-none {
  position: absolute;
  bottom: 100%;
  background: #333;
  color: #fff;
  width: 100%;
  border-radius: 12px;
  display: block !important;
  z-index: 999;
  padding: 15px;
  font-weight: 400;
}

.d-none:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #333;
  position: absolute;
  left: calc(50% - 4px);
  bottom: -8px;
}

.pricing-choose {
  background-color: #fff;
  padding-bottom: 20px;
}

@media only screen and (max-width: 992px) {
  .pricing-choose {
    padding-bottom: 30px;
  }
}

.dot {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin: 0 auto;
  display: inline-block;
}

.dot-empty {
  background-color: #fff !important;
  border: 1px solid #e6e6e6;
}

.tooltip-info_icon {
  width: 13px;
  height: 13px;
  line-height: 13px;
  font-size: 0.6875rem;
  text-align: center;
  background-color: #cdd2dd;
  color: #fff;
  border-radius: 50%;
  transition: background-color 0.5s ease-in-out;
}

.tooltip-info_icon:hover {
  background-color: #00b4f0;
}

.tooltip.pricing {
  min-width: 300px;
  font-size: 0.6875rem;
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .packets {
    padding-bottom: 20px;
  }
}

.packets-features .accordion-header {
  border-bottom: 1px solid #dfe4ed;
}

.packets-features .accordion-content {
  padding: 0;
}

.packets-features .accordion_arrow {
  fill: #6b7183;
}

.packets-features .tooltip-info {
  margin-left: 10px;
}

.packets-features-row {
  display: flex;
  background-color: #fff;
  transition: background-color 0.5s ease-in-out;
}

.packets-features-row:hover {
  background-color: #f5f6f9;
  cursor: pointer;
}

.packets-features-row:hover .tooltip-info_icon {
  background-color: #00b4f0;
}

.packets-features-item {
  width: 225px;
  font-weight: 500;
  padding: 2px 10px;
  border-bottom: 1px solid #eef1f7;
  border-right: 1px solid #eef1f7;
  text-align: center;
  line-height: 16px;
  height: 45px;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .packets-features-item {
    padding: 2px 10px;
  }
}

@media only screen and (max-width: 992px) {
  .packets-features-item {
    width: 40%;
    padding-right: 20px;
    border-left: 0;
    border-right: 0;
    justify-content: flex-end;
    text-align: right;
    display: none;
  }
}

.packets-features-item--labels {
  width: 300px;
  font-size: 0.8125rem;
  text-align: left;
  padding-left: 18px;
  padding-right: 18px;
  justify-content: flex-start;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .packets-features-item--labels {
    width: 60%;
    border-right: 0;
    justify-content: flex-start;
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .packets-features-item--active {
    display: flex;
  }
}

.packets-features-item__new {
  background-color: #00b4f0;
  color: #fff;
  font-size: 0.5625rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px;
  border-radius: 3px;
  position: absolute;
  right: 95%;
}

@media only screen and (max-width: 992px) {
  .packets-features-item__new {
    right: auto;
    left: 0;
    top: 0;
    padding: 1px 4px;
    line-height: 11px;
  }
}

@media only screen and (max-width: 576px) {
  .packets-features-item__new {
    line-height: 8px;
    font-size: 0.411rem;
    font-weight: 400;
  }
}

.packets-features-item__coming {
  background-color: #ef9c00;
  color: #fff;
  font-size: 0.5625rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px;
  border-radius: 3px;
  position: absolute;
  right: 95%;
}

@media only screen and (max-width: 992px) {
  .packets-features-item__coming {
    right: auto;
    left: 0;
    top: 0;
    padding: 1px 4px;
    line-height: 11px;
  }
}

@media only screen and (max-width: 576px) {
  .packets-features-item__coming {
    line-height: 8px;
    font-size: 0.411rem;
    font-weight: 400;
  }
}

.packets-features-item--free .dot {
  background-color: rgba(98, 203, 241, 1);
}

.packets-features-item--one {
  border-right: 0;
}

.packets-features-item--one .dot {
  background-color: rgba(0, 180, 240, 1);
}

.packets-features-item--two {
  position: relative;
  border-right: 0;
}

.packets-features-item--two:before {
  box-shadow: inset -12px 0 12px -8px rgba(65, 90, 131, 0.1);
  content: ' ';
  height: 100%;
  left: -25px;
  position: absolute;
  top: 0;
  width: 25px;
}

@media only screen and (max-width: 992px) {
  .packets-features-item--two:before {
    display: none;
  }
}

.packets-features-item--two:after {
  box-shadow: inset 12px 0 12px -8px rgba(65, 90, 131, 0.1);
  content: ' ';
  height: 100%;
  position: absolute;
  top: 0;
  right: -25px;
  width: 25px;
}

@media only screen and (max-width: 992px) {
  .packets-features-item--two:after {
    display: none;
  }
}

.packets-features-item--two .dot {
  background-color: rgba(26, 152, 194, 1);
}

.packets-features-item--three .dot {
  background-color: rgba(0, 130, 173, 1);
}

.packets-features-item .dot {
  margin: initial;
}

.plan {
  width: 225px;
  text-align: left;
  padding: 29px 10px;
  border-right: 1px solid #eef1f7;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .plan {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 992px) {
  .plan {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .plan.active {
    display: flex;
    box-shadow: none;
    width: 100%;
    padding: 20px 70px;
    text-align: center;
  }
}

.plan--popular {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.15);
  border-right: 0;
}

.plan--free .plan__title {
  color: rgba(98, 203, 241, 1);
}

.plan--free .plan__btn {
  background-color: rgba(98, 203, 241, 1);
}

.plan--free .plan__btn:hover {
  background-color: rgba(0, 180, 240, 1);
}

.plan--free .plan__price {
  font-size: 1.625rem;
}

.plan--one {
  border-right: 0;
}

.plan--one .plan__title {
  color: rgba(0, 180, 240, 1);
}

.plan--one .plan__btn {
  background-color: rgba(0, 180, 240, 1);
}

.plan--one .plan__btn:hover {
  background-color: rgba(26, 152, 194, 1);
}

.plan--two .plan__title {
  color: rgba(26, 152, 194, 1);
}

.plan--two .plan__btn {
  background-color: rgba(26, 152, 194, 1);
}

.plan--two .plan__btn:hover {
  background-color: rgba(0, 130, 173, 1);
}

.plan--three .plan__title {
  color: rgba(0, 130, 173, 1);
}

.plan--three .plan__btn {
  background-color: rgba(0, 130, 173, 1);
}

.plan--three .plan__btn:hover {
  background-color: rgba(0, 120, 160, 1);
}

.plan__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.plan .popular {
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(26, 152, 194, 1);
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding: 1px 0;
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
}

.plan__title {
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 21px;
  margin: 0 0 10px;
}

@media only screen and (max-width: 992px) {
  .plan__title {
    min-height: 0;
    margin-bottom: 15px;
  }
}

.plan__desc {
  font-size: 0.75rem;
  color: #6b7183;
  line-height: 18px;
  margin-bottom: 25px;
  min-height: 54px;
}

@media only screen and (max-width: 992px) {
  .plan__desc {
    min-height: auto;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .plan__desc {
    max-width: 170px;
    margin: 0 auto 40px;
  }
}

.plan__desc--cancelation {
  display: none;
}

@media only screen and (max-width: 992px) {
  .plan__desc--cancelation {
    display: block;
    margin-bottom: 0;
    margin-top: 12px;
    text-align: center;
    max-width: 100%;
  }
}

.plan__promo_wrapper {
  position: relative;
}

.plan__promo {
  font-size: 0.75rem;
  font-weight: 700;
  color: #6b7183;
  position: absolute;
  top: -20px;
  width: 100%;
  white-space: nowrap;
}

.plan__promo .currency {
  margin-left: 2px;
}

.plan__promo .currency.symbol {
  float: left;
  margin-left: 0;
  margin-right: 2px;
}

@media only screen and (max-width: 992px) {
  .plan__promo {
    left: 50%;
    transform: translateX(-50%);
  }
}

.plan__price {
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
  color: #1b1f2b;
}
.plan__price .currency {
  font-size: 1.3125rem;
  vertical-align: 6px;
  margin-left: 5px;
}

.plan__price .currency.symbol {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}

.plan__price_desc {
  font-size: 0.6875rem;
  color: #6b7183;
  margin-top: 4px;
  margin-bottom: 0;
}

.plan__btn {
  font-size: 0.9375rem;
  line-height: 15px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  padding: 15px 0;
  width: 100%;
  display: block;
  border-radius: 35px;
  background-color: #000;
  margin-top: 15px;
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;
}

.plan__btn:hover {
  text-decoration: none;
}

.plan__btn__disabled {
  background-color: rgba(0, 0, 0, 0) !important;
  font-weight: 400;
}

.plan__price_wrapper {
  display: none;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.plan__price_wrapper.active {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .plan__price_wrapper {
    align-items: center;
    justify-content: center;
  }
}

@-webkit-keyframes smoothScroll-data-v-0db3b8a5 {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes smoothScroll-data-v-0db3b8a5 {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

.plan-section {
  display: flex;
  background-color: #fff;
}

.plan-section.plan-section--2 {
  width: 750px;
  margin: 0 auto;
}

.plan-draft {
  width: 300px;
  text-align: left;
  padding: 29px 10px;
  border-right: 1px solid #eef1f7;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) {
  .plan-draft {
    padding: 20px 18px;
  }
}

@media only screen and (max-width: 992px) {
  .plan-draft {
    display: none;
  }
}

.plan-draft__title {
  font-weight: 500;
  font-size: 1.1875rem;
  line-height: 21px;
  margin: 0 0 10px;
  color: #6b7183;
}

@media only screen and (max-width: 992px) {
  .plan-draft__title {
    min-height: 0;
    margin-bottom: 15px;
  }
}

.plan-draft__desc {
  font-size: 0.75rem;
  color: #6b7183;
  line-height: 18px;
}

@-webkit-keyframes smoothScroll-data-v-2c07eb9a {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes smoothScroll-data-v-2c07eb9a {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

.choose-plan-tabs {
  text-align: center;
  z-index: 7;
}

@media only screen and (max-width: 576px) {
  .choose-plan-tabs__group {
    width: 100%;
  }
}

.choose-plan-tabs__item {
  font-size: 0.75rem;
  font-weight: 500;
  color: #1b1f2b;
  border-radius: 35px;
  height: 40px;
  padding: 10px 15px;
  background-color: #eef1f7;
  border-right: 1px solid #fff;
  min-width: 80px;
}

@media only screen and (max-width: 576px) {
  .choose-plan-tabs__item {
    font-size: 0.625em;
    padding: 0 2px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 320px) {
  .choose-plan-tabs__item {
    min-width: auto;
  }
}

.choose-plan-tabs__item:focus {
  outline: 0;
  box-shadow: none;
}

.choose-plan-tabs__item span {
  padding: 8px 12px;
}

@media only screen and (max-width: 768px) {
  .choose-plan-tabs__item span {
    padding: 8px 6px;
  }
}

.choose-plan-tabs__item.active {
  background-image: linear-gradient(90deg, #00b4f0, #009ade);
  color: #fff;
}

.choose-plan-tabs__item:last-child {
  border-right: 0;
}

.choose-plan-tabs .btn-group > .btn-group:not(:first-child),
.choose-plan-tabs .btn-group > .btn:not(:first-child) {
  margin-left: 0;
}

@-webkit-keyframes smoothScroll-data-v-40847cf8 {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes smoothScroll-data-v-40847cf8 {
  0% {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
}

.choose-plan-section__counter {
  margin-bottom: 20px;
  padding: 5px 0 20px;
  display: none;
}

@media only screen and (max-width: 992px) {
  .choose-plan-section__counter {
    display: block;
  }
}

.tabsplan-wrapper {
  display: none;
}

@media only screen and (max-width: 992px) {
  .tabsplan-wrapper {
    display: block;
  }
}
</style>
