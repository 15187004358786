<template>
  <input
    ref="range"
    type="range"
    :max="max"
    :min="min"
    :step="step"
    v-model="currentProgress"
    v-on:input="setCSSProperty"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    currentProgress: {
      get() {
        return this.progress;
      },
      set(value) {
        this.$emit('updateprogress', +value);
      },
    },
  },
  mounted() {
    this.setCSSProperty();
  },
  watch: {
    progress: function () {
      this.setCSSProperty();
    },
  },
  methods: {
    setCSSProperty() {
      const percent =
        ((this.currentProgress - this.min) / (this.max - this.min)) * 100;

      this.$refs.range.style.setProperty(
        '--webkitProgressPercent',
        `${percent}%`
      );
    },
  },
};
</script>

<style scoped>
input[type='range'] {
  --thumbSize: 12px;
  --trackSize: 2px;
  --thumbBg: #fff;
  --trackBg: #bdbdbd;
  --progressBg: #01b4f0;
  --disabledTrackBg: #bdbdbd;
  --disabledProgressBg: #b51c29;

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
}
input[type='range']:focus {
  outline: none;
}

/* Thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type='range']::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Track */
input[type='range']::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-webkit-slider-runnable-track {
  background-image: linear-gradient(
    90deg,
    var(--disabledProgressBg) var(--webkitProgressPercent),
    var(--disabledTrackBg) var(--webkitProgressPercent)
  );
}
input[type='range']::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-moz-range-track {
  background-color: var(--disabledTrackBg);
}
input[type='range']::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-ms-track {
  background-color: var(--disabledTrackBg);
}

/* Progress */
input[type='range']::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-moz-range-progress {
  background-color: var(--disabledProgressBg) !important;
}
input[type='range']::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type='range']:disabled::-ms-fill-lower {
  background-color: var(--disabledProgressBg) !important;
}
</style>
