<template>
  <div class="w-full">
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full w-full justify-start overflow-x-auto border-b border-grey-100 md:relative"
      >
        <RouterLink
          v-if="allow('billing-details')"
          to="/billing"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Account detail
        </RouterLink>
        <RouterLink
          v-if="allow('billing-details')"
          to="/billing/plan"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Subscription
        </RouterLink>
        <RouterLink
          v-if="allow('billing-details')"
          to="/billing/closure"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Account closure
        </RouterLink>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <RouterView :loading="loading" :account="account" :reloadAccount="load" />
    </div>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  data() {
    return {
      loading: true,
      account: null,
    };
  },
  mounted() {
    this.$store.commit('RESET_JOB');
    this.load();
  },
  methods: {
    load() {
      api.get('/account').then((response) => {
        this.account = response.data;
        this.loading = false;
      });
    },
  },
};
</script>
