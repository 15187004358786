import { render, staticRenderFns } from "./MdColorField.vue?vue&type=template&id=6f34d37a&"
import script from "./MdColorField.vue?vue&type=script&lang=js&"
export * from "./MdColorField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports