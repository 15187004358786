<template>
  <div>
    <div class=""></div>
    <h1 class="mb-6 text-center text-3xl font-bold">Error</h1>

    <div class="pb-6 text-center">
      Joinin does not support this browser. We recommend Chrome, Safari, Firefox
      or Edge browsers
    </div>
  </div>
</template>

<script>
export default {};
</script>
