<template>
  <div>
    <div v-if="!can('settings-ondemand')" class="mb-6">
      <UpgradePlanLink />
    </div>
    <div
      v-else-if="
        event &&
        !['complete', 'vod_open', 'vod_closed'].includes(this.event.eventState)
      "
      class="text-grey-400"
    >
      The event must be complete before On Demand settings can be accessed.
    </div>

    <div v-else-if="event">
      <form class="mt-8" @submit.prevent="save()">
        <div class="mb-6 text-lg font-medium">On demand access</div>

        <!-- TODDO: JOININ-665. Manual mode only until vimeo integration complete
                <div class="flex items-start">
                    <div class="flex-1">
                        <MdSelectField name="enabled"
                            label="Mode"
                            v-model="form.vodMode"
                            :errors="errors"
                            :options="[{
                                id: 'automatic',
                                label: 'Automatic'
                            },{
                                id: 'manual',
                                label: 'Manual'
                            }]" />
                    </div>

                    <TooltipModal>
                        <template slot="control">
                            <button class="flex space-x-1 items-center text-sm -ml-5 -mt-2">
                                <i class="material-icons-outlined text-icon-sm">info</i>
                            </button>
                        </template>

                        <template slot="content">
                            <div class="flex flex-col space-y-4">
                                <div>
                                    Control how the on-demand portal opens to attendees.
                                </div>
                                <div>
                                    <span class="font-bold">Automatic:</span> the event recording posted to your connected Video Hosting account will be automatically added to the on-demand portal. The portal will be opened as soon as the video is ready.
                                </div>
                                <div>
                                    <span class="font-bold">Manual:</span> the event recording must be manually added via an embed URL, and the portal manually opened.
                                </div>
                                <div class="flex justify-center items-center">
                                    <a class="text-blue-500 pr-1" href="https://support.joinin.live/portal/en/kb/articles/on-demand" target="_blank">More information</a>
                                    <i class="material-icons-outlined text-blue-500 text-icon-sm">help_outline</i>
                                </div>
                            </div>
                        </template>
                    </TooltipModal>
                </div>
                -->

        <div v-if="form.vodMode == 'manual'" class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Enabled"
              v-model="form.vodEnabled"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees to access an on-demand recording if your event
              once complete. Attendees will be able to access the portal and see
              the embed URL video content. On-demand access can be opened/closed
              from the Studio once the event is complete.
            </template>
          </TooltipModal>
        </div>

        <MdTextField
          v-if="form.vodMode == 'manual' && form.vodEnabled"
          name="embed"
          label="Embed URL"
          :required="form.vodEnabled == true"
          v-model="form.streamOverrideUrl"
          tooltip="URL link of the content you want to show in the on-demand portal. e.g.: https://player.vimeo.com/video/576334329"
          :errors="errors"
        />

        <!-- enable registration -->
        <div
          v-if="
            ['automatic_registration', 'manual_registration'].includes(
              this.event.registrationType
            )
          "
        >
          <div class="mb-6 pt-6 text-lg font-medium">Registration</div>

          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                name="enabled"
                label="Allow new registrations"
                v-model="form.vodAllowRegistration"
                :errors="errors"
                :options="[
                  {
                    id: true,
                    label: 'Yes',
                  },
                  {
                    id: false,
                    label: 'No',
                  },
                ]"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Allow attendees who missed the live session to register for this
                event during On Demand. Enabling this setting will override any
                configured registration cut off time. All attendees who
                registered prior to the live session will retain access.
              </template>
            </TooltipModal>
          </div>
        </div>

        <div
          v-if="event.chatEnabled || event.qaEnabled || event.pollEnabled"
          class="mb-6 pt-6 text-lg font-medium"
        >
          On demand engagement
        </div>

        <div v-if="event.chatEnabled" class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Display chat content"
              v-model="form.vodShowChat"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees view chat messages submitted and approved during
              the live session. Submission is disabled.
            </template>
          </TooltipModal>
        </div>

        <div v-if="event.qaEnabled" class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Display Q&A content"
              v-model="form.vodShowQa"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees view questions submitted and approved during the
              live session. Submission is disabled.
            </template>
          </TooltipModal>
        </div>

        <div v-if="event.pollEnabled" class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Display poll content"
              v-model="form.vodShowPoll"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees to view poll results submitted and approved during
              the live session. Submission is disabled.
            </template>
          </TooltipModal>
        </div>

        <div v-if="event.surveyEnabled" class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              name="enabled"
              label="Display survey"
              v-model="form.vodShowSurvey"
              :errors="errors"
              :options="[
                {
                  id: true,
                  label: 'Yes',
                },
                {
                  id: false,
                  label: 'No',
                },
              ]"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              Allow attendees to submit a survey response.
            </template>
          </TooltipModal>
        </div>
        <SubmitButton :disabled="!isValidtoSubmit" :saving="saving" />
      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import errors from '@/mixins/errors';
import MdTextField from '@/components/MdTextField';
import MdSelectField from '@/components/MdSelectField';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';
import UpgradePlanLink from '@/components/UpgradePlanLink';

const urlPattern =
  /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/i;

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    MdTextField,
    MdSelectField,
    TooltipModal,
    SubmitButton,
    UpgradePlanLink,
  },

  data() {
    return {
      form: {
        vodMode: 'manual',
        vodEnabled: false,
        streamOverrideUrl: null,
        vodShowChat: true,
        vodShowQa: true,
        vodShowPoll: true,
        vodShowSurvey: true,
        vodAllowRegistration: false,
      },
      isUriError: false,
      saving: false,
    };
  },

  computed: {
    isValidtoSubmit() {
      if (this.isUriError) {
        return false;
      }

      return true;
    },
  },

  watch: {
    'event'() {
      this.setupForm();
    },
    'form.streamOverrideUrl': function (newVal, oldVal) {
      if (oldVal == null || oldVal == '') {
        if (newVal.match(/^http/g) == null) {
          this.form.streamOverrideUrl = 'http://' + newVal;
        }
      }

      if (
        this.form.vodMode == 'manual' &&
        this.form.streamOverrideUrl != '' &&
        !urlPattern.test(this.form.streamOverrideUrl)
      ) {
        this.isUriError = true;
        this.errors.set({ errors: { embed: 'Please enter a valid URL.' } });
        return;
      }

      this.isUriError = false;
      this.errors.forget();
    },
    'form.vodMode': function (val) {
      if (val == 'automatic') {
        this.form.streamOverrideUrl = null;
        this.isUriError = false;
        this.errors.forget();
      }
    },
  },

  mounted() {
    if (this.event) {
      this.setupForm();
    }
    console.log(this.event);
  },

  methods: {
    setupForm() {
      for (const key in this.form) {
        if (this.event.hasOwnProperty(key)) {
          this.form[key] = this.event[key];
        }
      }
    },

    save() {
      this.saving = true;

      api
        .put('/jobs/' + this.event.id + '/vod', {
          ...this.form,
        })
        .then((response) => {
          this.saving = false;

          this.notify('success', 'Event updated');
          EventBus.$emit('event-updated', response.data);
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
