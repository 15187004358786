<template>
  <div>
    <div v-if="!tableData.outputs.length" class="text-grey-400">No clients</div>
    <form v-else @submit.prevent="setRouting">
      <div class="flex items-center space-x-2 px-2 pb-4 text-grey-400">
        <div class="flex flex-1">
          <div class="flex-1">Output client</div>
          <div class="flex w-48">Status</div>
          <div class="flex-1">Current input</div>
        </div>
        <div class="flex-1">Update</div>
      </div>

      <div
        v-for="o in tableData.outputs"
        :key="o.id"
        class="flex items-center space-x-2 border-b border-grey-100 px-2 py-1 hover:bg-grey-100"
        :class="{ 'animate-pulse bg-orange-100': o.formChanged }"
      >
        <div class="flex flex-1 items-center">
          <div class="flex-1">{{ o.name }}</div>
          <div class="flex w-48 flex-col justify-center space-y-1 pr-2">
            <div
              class="flex h-4 justify-center rounded text-xs uppercase text-white"
              :class="{
                'bg-green-500': o.isOnline,
                'bg-red-500': !o.isOnline,
              }"
            >
              Signed {{ o.isOnline ? 'in' : 'out' }}
            </div>

            <div
              v-if="
                ['input', 'both'].includes(o.ioDirection) &&
                !o.webrtcStats?.length
              "
              class="flex h-4 justify-center rounded text-xs uppercase text-white"
              :class="{
                'bg-green-500': o.isPublishing,
                'bg-red-500': !o.isPublishing,
              }"
            >
              {{ o.isPublishing ? '' : 'Not' }} Publishing
            </div>
            <div
              v-for="(stat, index) in o.webrtcStats"
              :key="index"
              class="flex h-4 justify-center rounded bg-blue-400 text-xs uppercase text-white"
            >
              {{
                index === 0
                  ? 'L'
                  : index === 1
                  ? 'M'
                  : index === 2
                  ? 'H'
                  : index
              }}: {{ stat.width }}x{{ stat.height }}@{{ stat.fps }}
            </div>
            <div
              v-if="o.outputMuted"
              class="flex h-4 justify-center rounded bg-orange-500 text-xs uppercase text-white"
            >
              Muted
            </div>
          </div>

          <div class="flex-1 space-x-1">
            <div
              class="flex"
              :class="{ 'font-bold text-orange-500': o.formChanged }"
            >
              {{ routingForm[o.id].watchingName }}
            </div>
            <div
              v-if="routingForm[o.id].watchingMuted"
              class="flex h-6 rounded bg-orange-500 px-2 py-1 text-xs uppercase text-white"
            >
              Muted
            </div>
          </div>
        </div>

        <div class="flex flex-1 space-x-2">
          <div class="flex-1">
            <div class="flex space-x-2">
              <select
                :key="'watching-type'"
                class="select-css"
                :class="{ 'cursor-not-allowed': isCallingApi }"
                v-model="routingForm[o.id].selectedType"
                :disabled="isCallingApi"
              >
                <option value="blank">Blank</option>
                <option value="eventProgram">Studio Program</option>
                <option value="janusVideoroom">Input</option>
              </select>

              <select
                v-if="routingForm[o.id].selectedType == 'janusVideoroom'"
                :key="'watching-id'"
                class="select-css"
                :class="{ 'cursor-not-allowed': isCallingApi }"
                v-model="routingForm[o.id].selectedId"
                :required="true"
                :disabled="isCallingApi"
              >
                <optgroup label="Online">
                  <option
                    v-for="i in tableData.onlineInputs"
                    :key="i.id"
                    :value="i.id"
                  >
                    {{ i.name }}
                  </option>
                </optgroup>
                <optgroup label="Offline">
                  <option
                    v-for="i in tableData.offlineInputs"
                    :key="i.id"
                    :value="i.id"
                  >
                    {{ i.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="flex">
            <select
              :key="'output-muted'"
              class="select-css pr-12"
              :class="{ 'cursor-not-allowed': isCallingApi }"
              v-model="routingForm[o.id].outputMuted"
              :required="true"
              :disabled="isCallingApi"
            >
              <option value="true">Mute</option>
              <option value="false">Unmute</option>
            </select>
          </div>
        </div>
      </div>

      <SubmitButton
        :disabled="isCallingApi"
        :saving="isCallingApi"
        label="Set routes"
      />
    </form>
  </div>
</template>

<script>
import api from '@/services/api';
import Vue from 'vue';

import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'IoRouterRouting',
  props: {
    event: { type: Object, required: true },
    presenceMembers: { type: Array, required: true },
    ioClients: { type: Array, required: true },
    janusPublishersArray: { type: Array, required: true },
    clientWebrtcStats: { type: Array },
  },
  components: {
    SubmitButton,
  },
  data: () => ({
    isCallingApi: false,
    routingForm: {},
  }),
  computed: {
    tableData: function () {
      const outputs = [],
        inputs = [];
      const clients = this.ioClients.slice();
      clients.sort((a, b) => a.name.localeCompare(b.name));
      for (const ioc of clients) {
        const c = {
          id: ioc.id,
          name: ioc.name,
          ioDirection: ioc.ioDirection,
          watchingType: ioc.watchingType,
          watchingId: ioc.watchingId,
          mixerMuted: ioc.mixerMuted,
          outputMuted: ioc.outputMuted,
          isOnline: this.presenceMembers.some(
            (m) => m.id == 'ioClient-' + ioc.id
          ),
          isPublishing: this.janusPublishersArray.some((p) =>
            p.id.startsWith(`IoClient-${ioc.id}-`)
          ),
          formChanged: false,
          webrtcStats:
            this.clientWebrtcStats
              .find((s) => ioc.id == s.id)
              ?.stats.sort((a, b) => a.width - b.width) ?? [],
        };
        const formData = this.routingForm[ioc.id];
        if (formData) {
          if (
            formData.selectedType != ioc.watchingType ||
            (ioc.watchingType == 'janusVideoroom' &&
              !(
                (formData.selectedId == -1 && ioc.watchingId == null) ||
                formData.selectedId == ioc.watchingId
              )) ||
            formData.outputMuted != (ioc.outputMuted ? 'true' : 'false')
          ) {
            c.formChanged = true;
          }
        }
        switch (ioc.ioDirection) {
          case 'output':
            outputs.push(c);
            break;
          case 'input':
            inputs.push(c);
            break;
          case 'both':
            outputs.push(c);
            inputs.push(c);
            break;
        }
      }
      return {
        outputs,
        inputs,
        onlineInputs: inputs.filter((i) => i.isOnline),
        offlineInputs: inputs.filter((i) => !i.isOnline),
      };
    },
  },
  watch: {
    ioClients: function (newClients, oldClients) {
      this.updateFormState(newClients, oldClients);
    },
  },
  beforeMount() {
    this.updateFormState(this.ioClients, []);
  },
  methods: {
    setRouting() {
      if (this.isCallingApi) return;
      const routing = {};
      for (const c of this.ioClients) {
        if (!['output', 'both'].includes(c.ioDirection)) continue;
        const form = this.routingForm[c.id];
        if (form.selectedType == null) continue; // exclude if type is null
        const data = {
          type: form.selectedType,
          outputMuted: form.outputMuted == 'true',
        };
        if (form.selectedType == 'janusVideoroom') {
          data.id = form.selectedId;
          if (data.id == -1) {
            data.id = null;
          }
        }
        routing[c.id] = data;
      }
      this.isCallingApi = true;
      api
        .post(`/io/${this.event.jobId}/setRouting`, { clients: routing })
        .then(() => {
          this.isCallingApi = false;
        })
        .catch((e) => {
          console.error(e);
          this.isCallingApi = false;
        });
    },
    updateFormState(newClients, oldClients) {
      // Set of clients in old but not in new
      const removed = new Set(oldClients.map((o) => o.id));

      const someInput = this.ioClients.find((ioc) =>
        ['input', 'both'].includes(ioc.ioDirection)
      );
      for (const c of newClients) {
        const old = oldClients.find((o) => o.id == c.id);
        if (
          !old ||
          old.watchingType != c.watchingType ||
          c.watchingType == 'janusVideoroom'
        ) {
          const client = {
            selectedType: c.watchingType,
            selectedId: someInput?.id || -1,
            outputMuted: c.outputMuted ? 'true' : 'false',
          };
          if (c.watchingType == 'janusVideoroom') {
            let watchingName;
            const input = newClients.find(
              (_c) =>
                _c.id == c.watchingId &&
                ['input', 'both'].includes(_c.ioDirection)
            );
            if (input) {
              watchingName = input.name;
              client.watchingMuted = input.mixerMuted;
              client.selectedId = c.watchingId;
            }
            client.watchingName = `Input: ${watchingName}`;
          } else if (c.watchingType == 'eventProgram') {
            client.watchingName = 'Studio Program';
          } else if (c.watchingType == 'blank') {
            client.watchingName = 'Blank';
          }
          Vue.set(this.routingForm, c.id, client);
        }
        removed.delete(c.id);
      }
      for (const r of removed) {
        Vue.delete(this.routingForm, r);
      }
    },
  },
};
</script>
