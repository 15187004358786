<template>
  <div>
    <div v-if="!can('event-registration')" class="mb-6">
      <UpgradePlanLink />
    </div>

    <div
      v-else="
        ['automatic_registration', 'manual_registration'].includes(
          this.event.registrationType
        )
      "
    >
      <form v-show="event" class="mt-8" @submit.prevent="save()">
        <div class="mb-3 text-lg font-medium">Registration cut off</div>
        <div class="pb-12 text-grey-400">
          Set an optional registration cut off time.
          <span
            @click.prevent="handleRemoveCutOffTime"
            class="cursor-pointer text-blue-500 hover:text-blue-800"
          >
            Remove cut off time
          </span>
        </div>

        <div class="flex space-x-4">
          <MdSelectField
            class="flex-1"
            label="Day"
            name="day"
            v-model="form.registrationCloseDay"
            :errors="errors"
            :options="dayOptions"
          />

          <MdSelectField
            class="flex-1"
            label="Month"
            name="month"
            v-model="form.registrationCloseMonth"
            :errors="errors"
            :options="monthOptions"
          />

          <MdSelectField
            class="flex-1"
            label="Year"
            name="year"
            v-model="form.registrationCloseYear"
            :errors="errors"
            :options="yearOptions"
          />
        </div>

        <div class="flex space-x-4">
          <MdSelectField
            class="flex-1"
            label="Hour"
            name="hour"
            v-model="form.registrationCloseHour"
            :errors="errors"
            :options="hourOptions"
          />

          <MdSelectField
            class="flex-1"
            label="Minute"
            name="minute"
            v-model="form.registrationCloseMinute"
            :errors="errors"
            :options="minuteOptions"
          />

          <MdSelectField
            class="flex-1"
            label="AM/PM"
            name="am/pm"
            v-model="form.registrationCloseAmPm"
            :errors="errors"
            :options="[
              {
                id: 'am',
                label: 'AM',
              },
              {
                id: 'pm',
                label: 'PM',
              },
            ]"
          />
        </div>

        <div
          v-if="isCloseTimeError"
          class="rounded bg-red-100 px-4 py-2 text-center text-sm text-red-500"
        >
          Please complete all date and time fields.
        </div>

        <div class="mb-3 pt-6 text-lg font-medium">
          Custom registration form
        </div>
        <div v-if="!can('templates')" class="mb-6">
          <UpgradePlanLink />
        </div>

        <div v-else>
          <div
            v-if="templates.length || event.registrationFormId"
            class="pb-6 text-grey-400"
          >
            Assign a
            <RouterLink
              to="/settings/templates"
              class="cursor-pointer text-blue-500"
            >
              Form Template
            </RouterLink>
            to this event. Form Templates are duplicated and can then be
            modified for this specific event.
          </div>
          <div v-else class="pb-6 text-grey-400">
            Create a
            <RouterLink to="/templates" class="cursor-pointer text-blue-500">
              Form Template
            </RouterLink>
            for use in events.
          </div>

          <div v-if="!event.registrationFormId" class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                label="Assign form template"
                name="custom_form"
                v-model="registrationFormId"
                tooltip=""
                :options="templates"
                :errors="errors"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                Select the Form Template to duplicate for this event. Once
                selected you can create or edit fields.
              </template>
            </TooltipModal>
          </div>
          <div v-else class="flex-1">
            <MdTextField
              class="flex-1"
              label="Form"
              name="formNanme"
              v-model="form.registrationFormName"
              :required="true"
              :errors="errors"
            />

            <MdTextareaField
              class="flex-1"
              label="Form description"
              name="formDescription"
              v-model="form.registrationFormDescription"
              :rows="2"
              :required="false"
              :errors="errors"
            />

            <FormBuilder
              v-if="registrationForm"
              :form="registrationForm"
              :key="registrationForm.id"
              type="form"
            />
          </div>
        </div>

        <div class="flex flex-col items-center justify-center">
          <ConfirmModalButton
            @click="removeRegistrationForm"
            className="w-full p-2 block rounded cursor-pointer flex items-center justify-between"
            buttonType="red"
            buttonText="Delete"
          >
            <template slot="button">
              <div
                class="group mt-4 flex items-center justify-center space-x-1 rounded-full border border-grey-200 px-2 py-1 hover:border-red-500"
              >
                <i class="material-icons-outlined group-hover:text-red-500"
                  >delete</i
                >
                <span class="text-sm text-grey-800 group-hover:text-red-500"
                  >Delete form and data</span
                >
              </div>
            </template>

            <template slot="content">
              <div>
                <div>
                  Deleting this form
                  <strong
                    >will remove all fields and any submitted attendee
                    data</strong
                  >
                  from this event.
                </div>
                <div>
                  Are you sure you want to delete the form and all attendee
                  data? Data cannot be recovered once deleted.
                </div>
              </div>
            </template>
          </ConfirmModalButton>

          <SubmitButton :saving="saving" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import assign from 'lodash/assign';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import errors from '@/mixins/errors';
import SubmitButton from '@/components/SubmitButton';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';
import MdSelectField from '@/components/MdSelectField';
import UpgradePlanLink from '@/components/UpgradePlanLink';
import FormBuilder from '@/components/FormBuilder';
import TooltipModal from '@/components/TooltipModal';
import ConfirmModalButton from '@/components/ConfirmModalButton';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    SubmitButton,
    MdTextField,
    MdTextareaField,
    MdSelectField,
    UpgradePlanLink,
    FormBuilder,
    TooltipModal,
    ConfirmModalButton,
  },

  data() {
    return {
      templates: [],
      forms: [],
      registrationForm: null,
      registrationFormId: null,
      form: {
        timezone: 'Australia/Sydney',
        registrationCloseDay: null,
        registrationCloseMonth: null,
        registrationCloseYear: null,
        registrationCloseHour: null,
        registrationCloseMinute: null,
        registrationCloseAmPm: null,
        registrationFormName: null,
        registrationFormDescription: null,
      },

      saving: false,
      isCloseTimeError: false,
      timezoneOptions: [
        {
          id: 'Australia/Brisbane',
          label: 'Australia/Brisbane',
        },
        {
          id: 'Australia/Sydney',
          label: 'Australia/Sydney',
        },
      ],
      monthOptions: [
        {
          id: 0,
          label: 'January',
        },
        {
          id: 1,
          label: 'February',
        },
        {
          id: 2,
          label: 'March',
        },
        {
          id: 3,
          label: 'April',
        },
        {
          id: 4,
          label: 'May',
        },
        {
          id: 5,
          label: 'June',
        },
        {
          id: 6,
          label: 'July',
        },
        {
          id: 7,
          label: 'August',
        },
        {
          id: 8,
          label: 'September',
        },
        {
          id: 9,
          label: 'October',
        },
        {
          id: 10,
          label: 'November',
        },
        {
          id: 11,
          label: 'December',
        },
      ],
    };
  },

  computed: {
    registrationCloseTime() {
      if (
        !this.form.registrationCloseDay ||
        isNil(this.form.registrationCloseMonth) ||
        !this.form.registrationCloseYear ||
        !this.form.registrationCloseHour ||
        !this.form.registrationCloseMinute
      ) {
        return;
      }

      const hour =
        this.form.registrationCloseAmPm == 'am'
          ? +this.form.registrationCloseHour == 12
            ? 0
            : +this.form.registrationCloseHour
          : +this.form.registrationCloseHour == 12
          ? +this.form.registrationCloseHour
          : +this.form.registrationCloseHour + 12;

      return dayjs()
        .date(this.form.registrationCloseDay)
        .month(this.form.registrationCloseMonth)
        .year(this.form.registrationCloseYear)
        .hour(hour)
        .minute(this.form.registrationCloseMinute)
        .second(0)
        .tz(this.form.timezone, true)
        .format();
    },

    yearOptions() {
      let options = [];
      options.push({
        id: dayjs().format('YYYY'),
        label: dayjs().format('YYYY'),
      });

      options.push({
        id: dayjs().add(1, 'year').format('YYYY'),
        label: dayjs().add(1, 'year').format('YYYY'),
      });

      return options;
    },

    dayOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 31; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },

    hourOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 12; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },

    minuteOptions() {
      let options = [];
      let i;

      for (i = 0; i <= 59; i++) {
        let label = i;

        if (label < 10) {
          label = '0' + label;
        }

        options.push({
          id: label + '',
          label: label + '',
        });
      }

      return options;
    },
  },

  watch: {
    event() {
      this.setupForm();
    },
    registrationFormId: function (val) {
      if (val) {
        this.saveRegistrationForm();
      }
    },
  },

  async mounted() {
    if (this.event) {
      await this.init();

      this.setupForm();
    }
  },

  methods: {
    async init() {
      this.isLoaded = true;

      if (this.event.registrationFormId) {
        const res = await api.get(`/forms/${this.event.registrationFormId}`);
        this.registrationForm = res.data;

        this.form.registrationFormName = this.registrationForm.name;
        this.form.registrationFormDescription =
          this.registrationForm.description;
      } else {
        const template_res = await api.get('/forms/templates');
        if (template_res.status >= 200 && template_res.status < 300) {
          this.templates = template_res.data
            .filter((row) => row.type == 'registration')
            .map((row) => {
              return { id: row.id, label: 'Assign form: ' + row.name };
            });
        }

        const form_res = await api.get('/forms');
        if (form_res.status >= 200 && form_res.status < 300) {
          this.forms = form_res.data;
        }
      }

      this.isLoaded = false;
    },
    setupForm() {
      const registrationCloseTime = this.event.registrationCloseTime
        ? dayjs(this.event.registrationCloseTime).tz(this.event.timezone)
        : null;

      assign(
        this.form,
        pick(
          {
            ...this.event,
            registrationCloseDay: registrationCloseTime
              ? registrationCloseTime.date()
              : null,
            registrationCloseMonth: registrationCloseTime
              ? registrationCloseTime.month()
              : null,
            registrationCloseYear: registrationCloseTime
              ? registrationCloseTime.year().toString()
              : null,
            registrationCloseHour: registrationCloseTime
              ? parseInt(registrationCloseTime.format('h'))
              : null,
            registrationCloseMinute: registrationCloseTime
              ? registrationCloseTime.format('mm')
              : null,
            registrationCloseAmPm: registrationCloseTime
              ? registrationCloseTime.format('a')
              : null,
          },
          keys(this.form)
        )
      );

      if (this.registrationForm) {
        this.form.registrationFormName = this.registrationForm.name;
        this.form.registrationFormDescription =
          this.registrationForm.description;
      }
    },

    handleRemoveCutOffTime() {
      this.form.registrationCloseDay = null;
      this.form.registrationCloseMonth = null;
      this.form.registrationCloseYear = null;
      this.form.registrationCloseHour = null;
      this.form.registrationCloseMinute = null;
      this.form.registrationCloseAmPm = null;
    },

    validateCloseTime() {
      if (
        !this.form.registrationCloseDay &&
        isNil(this.form.registrationCloseMonth) &&
        !this.form.registrationCloseYear &&
        !this.form.registrationCloseHour &&
        !this.form.registrationCloseMinute &&
        !this.form.registrationCloseAmPm
      ) {
        this.isCloseTimeError = false;
      } else if (
        !this.form.registrationCloseDay ||
        isNil(this.form.registrationCloseMonth) ||
        !this.form.registrationCloseYear ||
        !this.form.registrationCloseHour ||
        !this.form.registrationCloseMinute ||
        !this.form.registrationCloseAmPm
      ) {
        this.isCloseTimeError = true;
      } else {
        this.isCloseTimeError = false;
      }
    },

    save() {
      this.saving = true;

      this.validateCloseTime();
      if (this.isCloseTimeError) {
        this.saving = false;
        return;
      }

      //update registration form name && description
      api.put(`/forms/${this.registrationForm.id}`, {
        name: this.form.registrationFormName,
        description: this.form.registrationFormDescription,
      });

      api
        .put('/jobs/' + this.event.id + '/registration', {
          registrationCloseTime: this.registrationCloseTime,
        })
        .then((response) => {
          this.saving = false;

          this.notify('success', 'Event updated');

          EventBus.$emit('event-updated', response.data);
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },

    saveRegistrationForm() {
      this.saving = true;

      api
        .post(`/jobs/${this.event.id}/createRegistrationForm`, {
          templateId: this.registrationFormId,
        })
        .then(async (response) => {
          this.saving = false;

          if (response.status >= 200 && response.status < 300) {
            this.forms.push({
              ...response.data,
              jobId: this.event.id,
            });
            this.$emit('set-rego-form-id', response.data.id);
            this.registrationFormId = null;

            await this.init();

            this.notify('success', 'Event updated');
          }
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },

    removeRegistrationForm() {
      api
        .delete(`/jobs/${this.event.id}/registrationForm`)
        .then(async (response) => {
          this.saving = false;

          if (response.status >= 200 && response.status < 300) {
            this.$emit('set-rego-form-id', null);
            this.notify('success', 'Event updated');

            await this.init();
          }
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
