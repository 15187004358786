<template>
  <div class="h-full w-full">
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full justify-start overflow-x-auto border-b border-grey-200 md:relative"
      >
        <RouterLink
          :to="'routing'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
        >
          Routing
        </RouterLink>
        <RouterLink
          :to="'clients'"
          class="p4 block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
        >
          Clients
        </RouterLink>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <RouterView
        v-if="janusLoadFinished"
        :event="event"
        :presenceMembers="presenceMembers"
        :ioClients="ioClients"
        :janusPublishersArray="janusPublishersArray"
        :clientWebrtcStats="clientWebrtcStats"
      />
      <div v-else>Loading</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/services/api';
import { initPusher } from '@/services/pusher';
import {
  loadJanusJs,
  trackJanusPublishers,
  setupTextroomReceiveStats,
} from '@/helper/janus.js';

export default {
  name: 'IoRouter',
  data: () => ({
    // Use a separate pusher instance from the store to have a different
    // auth function, since only one auth function can be specified per
    // pusher instance
    pusher: initPusher(),
    presenceMembers: [],
    ioClients: [],
    janusPublishers: {},
    janus: null,
    janusLoadFinished: false,
    clientWebrtcStats: [],
  }),
  computed: {
    ...mapState({
      event: (state) => state.event,
    }),
    janusPublishersArray() {
      return Object.values(this.janusPublishers);
    },
  },
  mounted() {
    const jobId = this.event.jobId;
    const channel = `presence-${jobId}-io-clients`;
    this.pusher._setAuthCallbackFn(async function (context, socketId) {
      const body = [
        'socketId=' + encodeURIComponent(socketId),
        'channel=' + encodeURIComponent(channel),
      ].join('&');
      const res = await api.post(`/io/${jobId}/operatorPusherAuth`, body, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      });
      return res.data;
    });
    this.presenceChannel = this.pusher.subscribe(channel);
    this.presenceChannel.bind('pusher:subscription_succeeded', (members) => {
      members.each((m) => {
        this.presenceMembers.push({ ...m });
      });
    });
    this.presenceChannel.bind('pusher:member_added', (member) => {
      this.presenceMembers.push({ ...member });
    });
    this.presenceChannel.bind('pusher:member_removed', (member) => {
      const index = this.presenceMembers.findIndex((m) => m.id == member.id);
      if (index != -1) {
        this.presenceMembers.splice(index, 1);
      }
    });
    this.presenceChannel.bind('clients-update', this.fetchClients);

    this.fetchClients();
    this._destroying = { value: false };
    this.$store.dispatch('GET_JOB').then(() => {
      if (this.event.janusHost) {
        this.janusInit();
        this.janusVideoroom();
        this.janusConnectedPromise.then(() => {
          if (this._destroying.value) return;
          setupTextroomReceiveStats({
            janus: this.janus,
            janusPin: this.event.janusPin,
            clientWebrtcStats: this.clientWebrtcStats,
            destroying: this._destroying,
          });
        });
        this.clientWebrtcStatsExpireInterval = setInterval(() => {
          for (const stats of this.clientWebrtcStats) {
            for (let i = stats.stats.length - 1; i >= 0; i--) {
              const stat = stats.stats[i];
              if (Date.now() > stat.expiry) {
                stats.stats.splice(i, 1);
              }
            }
          }
        }, 1000);
      } else {
        this.janusLoadFinished = true;
      }
    });
  },
  beforeDestroy() {
    this._destroying.value = true;
    if (this.janus) {
      this.janus._destroying = true;
      this.janus.destroy();
    }
    if (this.clientWebrtcStatsExpireInterval) {
      clearInterval(this.clientWebrtcStatsExpireInterval);
    }
    this.pusher.disconnect();
  },
  methods: {
    async fetchClients() {
      await api.get(`/io/${this.event.jobId}/clients`).then((res) => {
        this.ioClients = res.data;
      });
    },
    janusInit() {
      loadJanusJs(this.event.janusHost);
      this.janusConnectedPromise = Promise.all([
        window.adapterLoadedPromise,
        window.janusLoadedPromise,
      ]).then(
        () =>
          new Promise((resolve) => {
            if (this._destroying.value) {
              return void resolve();
            }
            this.janus = new Janus({
              server: this.event.janusHost + '/janus',
              success: resolve,
              error: console.error.bind(console),
            });
          })
      );

      this.janusConnectedPromise.then(() => {
        this.janusLoadFinished = true;
      });
    },
    async janusVideoroom() {
      await this.janusConnectedPromise;
      if (this._destroying.value) return;
      this.janus.attach({
        plugin: 'janus.plugin.videoroom',
        success: (handle) => {
          handle.send({
            message: {
              request: 'join',
              ptype: 'publisher',
              room: String(this.event.videoRoomId),
              pin: this.event.janusPin,
              display: 'ControlIoRouter',
            },
          });
        },
        error: (err) => {
          console.log(err);
        },
        onmessage: (msg) => {
          const { removedId } = trackJanusPublishers({
            janusPublishers: this.janusPublishers,
            janusMsg: msg,
          });
          if (removedId) {
            const [pType, pTypeId] = removedId.split('-');
            if (pType === 'IoClient') {
              const index = this.clientWebrtcStats.findIndex((c) => c.id == pTypeId);
              if (index != -1) {
                this.clientWebrtcStats.splice(index, 1);
              }
            }
          }
        },
      });
    },
  },
};
</script>
