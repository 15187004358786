<template>
  <div class="w-full">
    <ListView
      :isLoading="isLoading"
      :items="sortedUsers"
      :search="true"
      :sortName="true"
      :sortId="false"
      filterKey="userType"
      :filterOptions="[
        { id: 'super', label: 'Super Admin' },
        { id: 'admin', label: 'Administrator' },
        { id: 'manager', label: 'Manager' },
        { id: 'assistant', label: 'Assistant' },
      ]"
      @onNewItem="handleAddNewItem"
    >
      <!-- template for tags which position under title -->
      <template #tags="{ item }">
        <div class="rounded bg-grey-500 px-1 text-xs uppercase text-white">
          {{ item.type }}
        </div>
      </template>
      <!-- end of tags template -->

      <!-- template for dropdown menu -->
      <!-- manage custom function in current component -->
      <template #item="{ item }">
        <RouterLink
          :to="'/settings/users/' + item.id"
          class="block cursor-pointer rounded p-2 hover:bg-grey-100"
          >Edit
        </RouterLink>

        <DropdownInlineConfirmButton
          @callback="deleteUser(item)"
          confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
        >
          Delete
          <i class="material-icons-outlined pl-1 text-grey-400">delete</i>
        </DropdownInlineConfirmButton>
      </template>
      <!-- end of dropdown menu template-->
    </ListView>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortBy } from 'lodash';

import api from '@/services/api';
import errors from '@/mixins/errors';
import ListView from '@/components/ui/ListView';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';

export default {
  mixins: [errors],

  components: {
    ListView,
    DropdownInlineConfirmButton,
  },

  data() {
    return {
      isLoading: true,
      selectedUser: {},
      users: [],
    };
  },

  computed: {
    ...mapState({
      user: (s) => s.sessionData.user,
    }),
    sortedUsers() {
      return sortBy(this.users, [
        (u) => ['super', 'admin', 'manager', 'assistant'].indexOf(u.userType),
        (u) => this.formatName(u),
        'email',
      ]).map((user) => {
        user.name = this.formatName(user);
        user.description = user.email;
        user.type = this.formatRole(user);
        return user;
      });
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      const { data } = await api.get('/account/users');
      this.users = data;
      this.isLoading = false;
    },

    async deleteUser(selectedUser) {
      const id = selectedUser.id;
      if (!id) {
        return;
      }

      // TODO: Prevent deleting yourself. The backend already throws an
      //       error, but we want to reflect this in the UI.
      let success = false;
      try {
        const { data } = await api.delete(`/account/users/${id}`);
        success = data?.deleted === 1;
      } catch (e) {
        this.handleErrorStatus(e);
      }
      if (success) {
        this.users = this.users.filter((u) => u.id != id);
        this.notify('success', 'User deleted');
      }

      this.selectedUser = {};
    },

    handleAddNewItem() {
      this.$router.push('/settings/users/new');
    },

    formatName: (u) => [u.firstName, u.lastName].filter((x) => x).join(' '),

    formatRole: (u) =>
      ({
        super: 'Super Admin',
        admin: 'Administrator',
        manager: 'Manager',
        assistant: 'Assistant',
      }[u.userType]),
  },
};
</script>
