<template>
  <div class="h-full w-full">
    <div
      class="flex h-full flex-col lg:flex-row lg:space-y-0"
      @click="activateContext"
    >
      <!-- main content -->
      <div
        v-if="event.eventState == 'scheduled'"
        class="flex flex-1 select-none flex-col items-center justify-center"
      >
        <div v-if="can('studio-control')">
          <ul>
            <li class="text-medium m-6 text-xl">Get started</li>
            <li class="my-3 flex flex-row text-blue-500 hover:text-blue-800">
              <div
                class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
              >
                1
              </div>
              <a
                href="https://support.joinin.live/portal/en/kb/articles/getting-started-in-the-control-page"
                target="_blank"
                >Getting started guide</a
              >
            </li>
            <li
              class="my-3 flex cursor-pointer flex-row text-blue-500 hover:text-blue-800"
              @click="navigateTo('presenters')"
            >
              <div
                class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
              >
                2
              </div>
              Invite presenters
            </li>
            <li
              class="my-3 flex cursor-pointer flex-row text-blue-500 hover:text-blue-800"
              @click="navigateTo('library')"
            >
              <div
                class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
              >
                3
              </div>
              Add media
            </li>
          </ul>
          <button
            class="btn-blue relative mt-6 max-w-max"
            @click="handleEventStartRehearsal"
            :disabled="isStartRehearsalSaving"
          >
            <i
              v-if="isStartRehearsalSaving"
              class="material-icons-outlined absolute left-0 ml-3 animate-spin text-white"
            >
              sync
            </i>
            <span class="px-5">Start rehearsal</span>
          </button>
        </div>
        <p class="pt-6 text-center text-sm text-grey-400">
          You can view the live stream once the event is in Rehearsal mode.
        </p>
      </div>

      <div
        v-if="event.eventState == 'starting' && this.countDown >= 0"
        class="flex flex-grow select-none flex-col items-center justify-center"
      >
        <h1 class="text-medium mb-6 text-xl">Starting rehearsal</h1>
        <p class="text-center text-sm text-grey-400">
          Please wait while your rehearsal is prepared. This can take up to 5
          minutes but is usually faster.
        </p>
        <p class="mb-12 text-center text-sm text-grey-400">
          You can navigate away from this page so why not update the
          <RouterLink
            :to="'/events/' + event.eventShortcode + '/branding'"
            class="text-blue-500 hover:text-blue-800"
          >
            event branding
          </RouterLink>
          while you wait.
        </p>

        <p class="mb-6 mt-12 text-sm text-grey-400">
          {{ progress }}% - {{ timeCountDown }} remaining
        </p>
        <div class="relative h-2 w-8/12 rounded bg-grey-200">
          <div
            class="mb-4 flex h-2 overflow-hidden rounded bg-blue-500 text-xs"
            :style="`width: ${progress}%`"
          ></div>
        </div>
      </div>

      <div
        v-else-if="
          event.eventState == 'starting' &&
          this.countDown <= 0 &&
          this.countDown >
            -(this.vmAutoRestartAttempts * this.countDownThreshold)
        "
        class="flex flex-grow select-none flex-col items-center justify-center"
      >
        <h1 class="text-medium mb-6 text-xl">Starting rehearsal</h1>
        <p class="text-center text-sm text-grey-400">
          It's taking a bit longer than usual but your rehearsal is still being
          prepared. Please wait.
        </p>
      </div>

      <div
        v-else-if="
          event.eventState == 'starting' &&
          this.countDown ==
            -(this.vmAutoRestartAttempts * this.countDownThreshold)
        "
        class="flex flex-grow select-none flex-col items-center justify-center"
      >
        <h1 class="text-medium mb-6 text-xl">Starting rehearsal failed</h1>
        <p class="text-center text-sm text-grey-400">
          Your rehearsal unfortunately couldn't be started at this time. Please
          <a
            href="mailto:support@joinin.live?subject=Rehearsal start failure"
            class="text-blue-500"
            >contact support</a
          >
          for assistance.
        </p>
      </div>

      <div
        v-if="event.eventState == 'rehearsal' || event.eventState == 'live'"
        class="flex flex-grow select-none flex-col"
      >
        <!-- live sources -->
        <div class="p-2 shadow-lg lg:p-4">
          <div
            class="item-center relative flex h-8 flex-row justify-center pb-1 text-sm"
          >
            <span
              v-if="isAudioLatched && can('studio-control')"
              class="mx-2 flex items-center"
              >Audio latched ON -
              <span
                class="mx-1 cursor-pointer text-blue-500"
                @click="removeLatchedAudio"
                >Remove (Alt + L)</span
              ></span
            >
            <span
              v-if="overlayId != null && can('studio-control')"
              class="mx-2 flex items-center"
              >Overlay ON -
              <span
                class="mx-1 cursor-pointer text-blue-500"
                @click="removeOverlay"
                >Remove (Escape)</span
              ></span
            >
          </div>

          <div class="flex space-x-2 lg:space-x-4">
            <div v-for="output in eventOutputs" :key="output.id" class="flex-1">
              <MixerVideoSource
                :data="output"
                :layout="'lite'"
                :janus="janus"
                :janusPublishers="janusPublishers"
                @set-ui-muted="handleSetOutputUiMuted"
              />
            </div>
          </div>

          <div
            class="mt-6 flex flex-1 flex-col items-center justify-center lg:flex-row"
          >
            <div
              class="flex w-full flex-1 flex-col rounded-lg bg-grey-100 p-2 md:grid md:grid-cols-5 md:flex-row md:gap-4"
            >
              <div
                v-if="can('studio-control')"
                class="col-span-2 flex flex-col rounded-lg bg-white px-4 py-2"
              >
                <div
                  class="mb-1 flex items-center justify-between border-b border-grey-100 pb-1"
                >
                  <div class="py-2 text-sm text-grey-400">Scene control</div>
                </div>
                <div
                  class="grid flex-1 grid-cols-1 items-center justify-between pt-2"
                >
                  <div class="grid grid-cols-5 space-x-3">
                    <div class="flex flex-col border-r border-grey-200">
                      <button
                        class="flex h-12 w-12 items-center justify-center rounded-full border-2 border-grey-200 bg-white font-bold text-white hover:border-blue-400 hover:bg-blue-100"
                        :class="{
                          'bg-red-500 hover:bg-red-800': prevForceUpdate,
                        }"
                        @click="handleScenePrev"
                        :disabled="disablePrev"
                      >
                        <i
                          class="material-icons-outlined"
                          :class="{ 'text-white': prevForceUpdate }"
                          >first_page</i
                        >
                      </button>
                    </div>

                    <div
                      class="col-span-3 flex flex-col items-center justify-center border-r border-grey-200 text-xs"
                    >
                      <div class="text-grey-400">Last used</div>
                      <div class="text-center">
                        {{ lastUsedScene ? lastUsedScene.name : '' }}
                      </div>
                    </div>

                    <div class="flex flex-col items-end">
                      <button
                        class="flex h-12 w-12 items-center justify-center rounded-full border-2 border-green-500 bg-white font-bold text-white hover:border-green-800 hover:bg-green-100"
                        :class="{
                          'bg-red-500 hover:bg-red-800': nextForceUpdate,
                        }"
                        @click="handleSceneNext"
                        :disabled="disableNext"
                      >
                        <i
                          class="material-icons-outlined"
                          :class="{ 'text-white': nextForceUpdate }"
                          >last_page</i
                        >
                      </button>
                    </div>
                  </div>
                  <div class="grid grid-cols-2 space-x-3">
                    <div
                      class="h-8 space-x-2 truncate whitespace-nowrap pt-2 text-xs text-grey-400"
                    >
                      {{ previousScene ? previousScene.name : '-' }}
                    </div>

                    <div
                      class="h-8 flex-1 truncate whitespace-nowrap pt-2 text-right text-xs text-grey-400"
                    >
                      {{ nextScene ? nextScene.name : '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="can('studio-control')"
                class="flex flex-1 flex-col space-y-2 py-4 md:space-y-4 md:py-1"
              >
                <button
                  @click.prevent="handleCut"
                  class="flex items-center justify-center rounded-full border-2 border-grey-200 bg-white px-4 py-4 hover:border-blue-400 hover:bg-blue-100"
                >
                  <i class="material-icons-outlined pr-1 text-base"
                    >swap_horiz</i
                  >Cut
                </button>
                <div class="flex-auto"></div>
                <ActionSelectButton
                  class="flex-1 hover:border-blue-400"
                  :options="['fade', 'slow fade']"
                  name="Scene"
                  :value="cutMode"
                  icon="shuffle"
                  @change="setCutMode"
                  @selected="handleFade"
                />
              </div>
              <OperatorComm
                class="col-span-2"
                :class="{ 'col-span-3 col-start-2': !can('studio-control') }"
                :janus="janus"
                :janusPublishers="janusPublishers"
                :publishHandle="publishHandle"
                :publisherId="janusPublisherId"
                :commsRoom="commsRoom"
                @set-comms-room="handleSetCommsRoom"
                :isCommsTalking="isCommsTalking"
                @set-comms-talking="handleSetCommsTalking"
                :commsConnected="commsConnected"
                @set-comms-connected="handleSetCommsConnected"
                @onToggleSettingMenu="handleToggleAudioSettingMenu"
              />
            </div>
          </div>
        </div>

        <!-- source list -->
        <div class="flex-1 overflow-y-auto pt-5">
          <div v-if="!isSourcesLoaded">
            <!-- a loader ? -->
          </div>

          <!-- source grid -->
          <div v-else class="pb-4">
            <div
              class="grid auto-rows-fr grid-cols-2 gap-4 px-4 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6"
            >
              <div v-for="source in eventSources" :key="source.sid">
                <MixerVideoSource
                  :data="source"
                  :layout="can('studio-control') ? '' : 'view'"
                  :janus="janus"
                  :janusPublishers="janusPublishers"
                  @set-ui-muted="handleSetSourceUiMuted"
                />
              </div>

              <!-- add source panel -->
              <div
                v-if="
                  sources.length < event.maxSources && can('studio-control')
                "
                class="flex flex-col items-center justify-center bg-grey-200 shadow-lg"
                style="min-height: 250px"
              >
                <i class="material-icons-outlined text-4xl">photo_filter</i>
                <div class="text-lg">Add source</div>

                <button
                  @click.prevent="activeTab = 'media'"
                  class="text-blue-500 hover:text-blue-800"
                >
                  Media
                </button>

                <button
                  @click.prevent="activeTab = 'presenters'"
                  class="text-blue-500 hover:text-blue-800"
                >
                  Presenter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="completeEvent && !vodOpen"
        class="flex flex-grow select-none flex-col items-center justify-center"
      >
        <h1 class="text-medium mb-6 text-xl">
          Congratulations! Your live event is complete.
        </h1>
        <ul>
          <li
            class="my-3 flex cursor-pointer flex-row text-blue-500 hover:text-blue-800"
          >
            <div
              class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
            >
              1
            </div>
            <RouterLink :to="'/events/' + event.eventShortcode + '/analytics'">
              View analytics
            </RouterLink>
          </li>
          <li
            class="my-3 flex cursor-pointer flex-row text-blue-500 hover:text-blue-800"
          >
            <div
              class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
            >
              2
            </div>

            <RouterLink
              :to="'/events/' + event.eventShortcode + '/analytics/download'"
              class="cursor-pointer text-blue-500 hover:text-blue-800"
              exact-active-class="border-b-2 border-black text-grey-800"
              >Download recording
            </RouterLink>
          </li>
          <li
            v-if="event.vodEnabled"
            class="my-3 flex cursor-pointer flex-row text-blue-500 hover:text-blue-800"
          >
            <div
              class="mr-3 flex w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white"
            >
              3
            </div>

            <RouterLink
              :to="'/events/' + event.eventShortcode + '/settings/vod'"
              class="cursor-pointer text-blue-500 hover:text-blue-800"
              exact-active-class="border-b-2 border-black text-grey-800"
              >Change on-demand settings
            </RouterLink>
          </li>
        </ul>
        <button
          v-if="can('studio-control') && event.vodEnabled"
          class="btn-blue relative max-w-max"
          @click="handleEventVodOpen"
        >
          <span class="px-5">Open video on-demand</span>
        </button>
      </div>
      <div
        v-if="vodOpen"
        class="flex flex-grow select-none flex-col items-center justify-center"
      >
        <h1 class="text-medium mb-6 text-xl">Your event is open on-demand.</h1>
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/settings/vod'"
          class="my-3 cursor-pointer text-blue-500 hover:text-blue-800"
          exact-active-class="border-b-2 border-black text-grey-800"
          >Change on-demand settings
        </RouterLink>
        <button
          v-if="can('studio-control')"
          class="btn-blue relative max-w-max"
          @click="handleEventVodClose"
        >
          <span class="px-5">Close video on-demand</span>
        </button>
      </div>

      <!-- sidebar -->
      <div
        class="relative flex h-full flex-shrink-0 flex-row-reverse border-t border-grey-200 bg-white shadow-md lg:w-1/3 lg:border-t-0 hd:w-1/4"
      >
        <!-- tab bar -->
        <div
          class="flex w-24 flex-shrink-0 flex-col items-center overflow-x-hidden overflow-y-scroll bg-blue-500 px-2 text-white"
        >
          <TabItem
            label="Event info"
            tab="info"
            icon="info"
            :active="activeTab"
            @click="updateActiveTab"
          />
          <TabItem
            label="Presenters"
            tab="presenters"
            icon="people_alt"
            class="mx-2"
            :notifications="unreadPresentersCount"
          >
            <TabItem
              v-if="can('studio-control')"
              label="Manage"
              tab="presenters"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              label="Messages"
              tab="messages"
              :active="activeTab"
              @click="updateActiveTab"
              :notifications="unreadPresentersCount"
            />
          </TabItem>
          <TabItem
            v-if="!completeEvent && can('studio-control')"
            label="Media"
            tab="media"
            icon="folder_open"
          >
            <TabItem
              v-if="can('studio-control')"
              label="Library"
              tab="library"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="can('studio-control') && can('studio-source-playlist')"
              label="Playlists"
              tab="playlists"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="can('studio-control')"
              label="Streams"
              tab="streams"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="allow('io-router')"
              label="Router"
              tab="router"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="can('studio-control')"
              label="Web"
              tab="web"
              :active="activeTab"
              @click="updateActiveTab"
            />
          </TabItem>
          <TabItem
            v-if="!completeEvent && can('studio-control')"
            label="Layouts"
            tab="layouts"
            icon="grid_on"
            :active="activeTab"
            @click="updateActiveTab"
          />
          <TabItem
            v-if="!completeEvent && can('studio-control')"
            label="Scenes"
            tab="scenes"
            icon="photo_library"
            :active="activeTab"
            @click="updateActiveTab"
          />
          <TabItem
            v-if="engagementOptions.length > 0"
            label="Engagement"
            tab="engagement"
            icon="question_answer"
            :notifications="unreadEngagementCount"
          >
            <TabItem
              v-if="event.config.chatEnabled == true"
              label="Chat"
              tab="chat"
              :active="activeTab"
              @click="updateActiveTab"
              :notifications="unreadEngagementChatMessagesCount"
            />
            <TabItem
              v-if="event.config.qaEnabled == true"
              label="Q&A"
              tab="qa"
              :active="activeTab"
              @click="updateActiveTab"
              :notifications="unreadEngagementQAMessagesCount"
            />
            <TabItem
              v-if="event.config.pollEnabled == true"
              label="Poll"
              tab="poll"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="event.config.quizEnabled == true"
              label="Quiz"
              tab="quiz"
              :active="activeTab"
              @click="updateActiveTab"
            />
            <TabItem
              v-if="event.config.surveyEnabled == true"
              label="Survey"
              tab="survey"
              :active="activeTab"
              @click="updateActiveTab"
            />
          </TabItem>
          <TabItem
            v-if="!completeEvent && can('studio-control')"
            label="Outbound streams"
            tab="outbound_streams"
            icon="cast_connected"
            :active="activeTab"
            @click="updateActiveTab"
          >
          </TabItem>
          <TabItem
            label="Settings"
            class="mt-auto"
            tab="settings"
            icon="settings"
            :active="activeTab"
            @click="updateActiveTab"
          />
        </div>

        <!-- info tab -->
        <div class="flex h-full flex-1 flex-col bg-white p-2 lg:p-4">
          <div v-if="activeTab == 'info'" class="flex h-full min-h-0 flex-col">
            <div
              class="flex flex-col items-center justify-center"
              v-if="event.eventState == 'scheduled'"
            >
              <div class="pb-3 pt-8 text-grey-400">
                Scheduled event start {{ eventStartTime }}
              </div>

              <button
                v-if="can('studio-control')"
                class="btn-blue relative max-w-max"
                @click="handleEventStartRehearsal"
                :disabled="isStartRehearsalSaving"
              >
                <i
                  v-if="isStartRehearsalSaving"
                  class="material-icons-outlined absolute left-0 ml-3 animate-spin text-white"
                >
                  sync
                </i>
                <span class="px-5">Start rehearsal</span>
              </button>
            </div>

            <div
              class="flex flex-col items-center justify-center"
              v-if="event.eventState == 'starting'"
            >
              <div class="text-medium py-3 text-xl">
                <strong>REHEARSAL</strong> mode is starting
              </div>
              <div class="mb-12 text-center text-grey-400">
                Scheduled event start {{ eventStartTime }}
              </div>
              <div class="mt-3 text-xs text-grey-400">
                We're getting your event ready for rehearsal, please wait.
              </div>
            </div>

            <div
              v-if="event.eventState == 'rehearsal'"
              class="flex flex-1 flex-col items-center"
            >
              <div class="text-medium py-3 text-xl">
                You are in <strong>REHEARSAL</strong> mode
              </div>
              <div class="mb-12 text-center text-grey-400">
                Scheduled event start {{ eventStartTime }}
              </div>
              <button
                v-if="can('studio-control')"
                class="btn-blue mb-12 max-w-max"
                @click="checkEventAllowedLive"
              >
                Go Live
              </button>

              <div class="flex flex-col items-center">
                <div class="flex-1">
                  <div class="text-lg font-bold">Attendee portal</div>
                </div>
              </div>
              <a
                :href="'https://view.joinin.live/event/' + event.eventShortcode"
                target="_blank"
                class="flex items-center text-blue-500 hover:text-blue-800"
                >Open login
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-blue-400"
                  >open_in_new</i
                >
              </a>

              <a
                :href="apiOrigin + '/jobs/' + jobId + '/preview'"
                target="_blank"
                class="flex items-center text-blue-500 hover:text-blue-800"
                >Preview portal
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-blue-400"
                  >open_in_new</i
                >
              </a>
            </div>

            <div
              v-if="event.eventState == 'live'"
              class="flex flex-col overflow-auto lg:flex-1"
            >
              <div class="flex items-center space-x-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Stream health</div>
                </div>

                <div>
                  <TooltipModal>
                    <template slot="control">
                      <button class="flex items-center space-x-1 text-sm">
                        <i class="material-icons-outlined">info</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        The health of the final stream being sent to attendees.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
              <div class="text-lg text-green-500">Good</div>

              <div class="flex items-center pt-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Recording</div>
                </div>

                <div>
                  <TooltipModal>
                    <template slot="control">
                      <button class="flex items-center space-x-1 text-sm">
                        <i class="material-icons-outlined">info</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        The status of the final event recording. Recording
                        starts when the event is made Live and stops when ended.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
              <div class="text-lg text-green-500">Active</div>

              <div v-if="event.eventState == 'live'">
                <div class="flex items-center pt-4">
                  <div class="flex-1">
                    <div class="text-lg font-bold">Live viewers</div>
                  </div>

                  <div>
                    <TooltipModal>
                      <template slot="control">
                        <button class="flex items-center space-x-1 text-sm">
                          <i class="material-icons-outlined">info</i>
                        </button>
                      </template>

                      <template slot="content">
                        <div class="flex flex-col space-y-4">
                          The number of attendees logged in to your portal right
                          now.
                        </div>
                      </template>
                    </TooltipModal>
                  </div>
                </div>
                <div class="text-lg text-grey-400">{{ streamLiveViewers }}</div>
              </div>

              <div class="flex items-center pt-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Unique live viewers</div>
                </div>

                <div>
                  <TooltipModal>
                    <template slot="control">
                      <button class="flex items-center space-x-1 text-sm">
                        <i class="material-icons-outlined">info</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        The number of individual attendees who have logged into
                        your portal.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
              <div class="text-lg text-grey-400">{{ streamUniqueViewers }}</div>

              <div class="flex items-center pt-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Total live runtime</div>
                </div>

                <div>
                  <TooltipModal>
                    <template slot="control">
                      <button class="flex items-center space-x-1 text-sm">
                        <i class="material-icons-outlined">info</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        The total amount of time between the event being made
                        Live and being ended.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
              <div class="text-lg text-grey-400">{{ runTime }}</div>

              <div class="flex flex-col pt-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Attendee portal</div>
                </div>
              </div>
              <a
                :href="'https://view.joinin.live/event/' + event.eventShortcode"
                target="_blank"
                class="flex items-center text-blue-500 hover:text-blue-800"
                >Open login
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-blue-400"
                  >open_in_new</i
                >
              </a>

              <a
                :href="apiOrigin + '/jobs/' + jobId + '/preview'"
                target="_blank"
                class="flex items-center text-blue-500 hover:text-blue-800"
                >Preview portal
                <i
                  class="material-icons-outlined pl-1 text-icon-sm text-blue-400"
                  >open_in_new</i
                >
              </a>
            </div>

            <!-- event complete -->
            <div
              v-if="completeEvent"
              class="flex flex-col overflow-auto p-4 lg:flex-1"
            >
              <div class="flex items-center pt-4">
                <div class="flex-1">
                  <div class="text-lg font-bold">Recording</div>
                </div>

                <div>
                  <TooltipModal>
                    <template slot="control">
                      <button class="flex items-center space-x-1 text-sm">
                        <i class="material-icons-outlined">info</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        The status of the final event recording. Recording
                        starts when the event is made Live and stops when ended.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
              <RouterLink
                :to="'/events/' + event.eventShortcode + '/analytics/download'"
                class="cursor-pointer text-blue-500 hover:text-blue-800"
                exact-active-class="border-b-2 border-black text-grey-800"
                >Download recording
              </RouterLink>
              <div class="pt-4">
                <div class="text-lg font-bold">Analytics</div>
              </div>
              <RouterLink
                :to="'/events/' + event.eventShortcode + '/analytics'"
                class="cursor-pointer text-blue-500 hover:text-blue-800"
                exact-active-class="border-b-2 border-black text-grey-800"
                >View analytics
              </RouterLink>
            </div>

            <div
              v-if="event.eventState == 'rehearsal' && can('studio-control')"
              class="flex flex-col justify-center border-t border-grey-200 pt-3"
            >
              <button
                class="text-red-700 hover:text-red-500"
                @click="$refs.eventStopRehearsalDialog.show()"
              >
                Stop rehearsal
              </button>
              <div class="text-center text-xs text-grey-400">
                Your current setup will be saved if you stop.
              </div>
            </div>
            <div
              class="flex flex-col justify-center border-t border-grey-200 pt-3"
              v-if="event.eventState == 'live'"
            >
              <button
                class="text-red-700 hover:text-red-500"
                @click="$refs.eventEndEventDialog.show()"
              >
                End event
              </button>
            </div>
          </div>

          <!-- presenters tab -->
          <div v-if="activeTab == 'presenters'" class="flex h-full min-h-0">
            <StudioPanelPresenters
              :actionControl="can('studio-control')"
              :isActive="activeTab == 'presenters'"
              :janusPublishers="janusPublishers"
            />
          </div>

          <div v-show="activeTab == 'messages'" class="flex h-full min-h-0">
            <StudioPanelPresenterMessages
              v-if="['rehearsal', 'live'].includes(event.eventState)"
              :janus="janus"
              role="mixer"
              :username="username"
              :config="{ room: event.presenterTextRoomId, pin: event.janusPin }"
              :isFocus="activeTab == 'messages'"
              @newMessagesNotification="handleNewMessagesNotification"
            />
            <div v-else class="w-full py-4 text-center text-xs text-grey-400">
              Presenter messages available when event is in
              <strong>Rehearsal</strong> or <strong>Live</strong>.
            </div>
          </div>

          <!-- media tab -->
          <div
            v-if="
              activeTab == 'library' && !completeEvent && can('studio-control')
            "
            class="flex h-full min-h-0"
          >
            <StudioPanelFiles />
          </div>

          <div
            v-if="
              activeTab == 'playlists' &&
              !completeEvent &&
              can('studio-control') &&
              can('studio-source-playlist')
            "
            class="flex h-full min-h-0"
          >
            <StudioPanelPlaylists />
          </div>

          <div
            v-if="
              activeTab == 'streams' && !completeEvent && can('studio-control')
            "
            class="flex h-full min-h-0"
          >
            <StudioPanelStreams />
          </div>

          <div
            v-if="activeTab == 'router' && !completeEvent && allow('io-router')"
            class="flex h-full min-h-0"
          >
            <StudioPanelRouter :janusPublishers="janusPublishers" />
          </div>

          <div
            v-if="activeTab == 'web' && !completeEvent && can('studio-control')"
            class="flex h-full min-h-0"
          >
            <StudioPanelHtmls />
          </div>

          <!-- layouts tab -->
          <div
            v-if="
              activeTab == 'layouts' && !completeEvent && can('studio-control')
            "
            class="flex h-full min-h-0 select-none flex-col"
          >
            <StudioPanelLayouts />
          </div>

          <!-- scenes tab -->
          <div
            v-if="
              activeTab == 'scenes' && !completeEvent && can('studio-control')
            "
            class="flex h-full min-h-0 select-none flex-col"
          >
            <div class="flex h-full min-h-0">
              <StudioPanelScenes ref="scenes" />
            </div>
          </div>

          <!-- engagement -->
          <div v-show="activeTab == 'chat'" class="flex h-full min-h-0">
            <StudioPanelChat
              v-if="event.config.chatEnabled == true"
              :isFocus="activeTab == 'chat'"
              @newMessagesNotification="
                handleEngagementChatNewMessagesNotification
              "
              :isActive="activeTab == 'engagement'"
            />
          </div>

          <div v-show="activeTab == 'qa'" class="flex h-full min-h-0">
            <StudioPanelQA
              v-if="event.config.qaEnabled == true"
              :isFocus="activeTab == 'qa'"
              @newMessagesNotification="
                handleEngagementQANewMessagesNotification
              "
              :isActive="activeTab == 'engagement'"
            />
          </div>

          <div v-show="activeTab == 'poll'" class="flex h-full min-h-0">
            <StudioPanelPoll v-if="event.config.pollEnabled == true" />
          </div>

          <div v-show="activeTab == 'quiz'" class="flex h-full min-h-0">
            <StudioPanelQuiz v-if="event.config.quizEnabled == true" />
          </div>

          <div v-show="activeTab == 'survey'" class="flex h-full min-h-0">
            <StudioPanelSurvey v-if="event.config.surveyEnabled == true" />
          </div>

          <!-- outbound streams -->
          <div
            v-if="
              activeTab == 'outbound_streams' &&
              !completeEvent &&
              can('studio-control')
            "
            class="flex h-full min-h-0"
          >
            <StudioPanelOutboundStreams />
          </div>

          <div v-if="activeTab == 'settings'" class="flex h-full min-h-0">
            <StudioPanelSettings :commsConnected="commsConnected" />
          </div>
        </div>
      </div>

      <!-- Event go live confirmation - trigged by eventConfirmation -->
      <Modal width="96" ref="eventGoLiveDialog">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">Go live</h1>
          <p>You are about to go live.</p>

          <ul>
            <li>
              - Attendees will gain access to the portal and will see your On
              Air video
            </li>
            <li>- The event recording will begin</li>
            <li>- You can not restart rehearsal</li>
          </ul>

          <div class="pb-6 pt-12">
            <MaterialIconsCheckbox
              v-model="eventGoLiveCheckbox"
              :label="'I understand'"
            />
          </div>

          <button
            class="btn-blue"
            :disabled="eventGoLiveCheckbox == false"
            @click="handleEventGoLive"
          >
            Go live
          </button>
          <button
            class="mt-6 w-full text-center text-blue-500"
            @click="$refs.eventGoLiveDialog.close()"
          >
            Take me back to rehearsal
          </button>
        </template>
      </Modal>

      <!-- Rehearsal stop confirmation -->
      <Modal width="96" ref="eventStopRehearsalDialog">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">Stop rehearsal</h1>
          <ul>
            <li>- Your set up will be saved</li>
            <li>- Live presenters will be logged out</li>
            <li>- Video sources will stop displaying</li>
            <li>- You can start rehearsal again at any time</li>
          </ul>

          <button class="btn-red mt-12" @click="handleStopRehearsal">
            Stop rehearsing
          </button>
          <button
            class="mt-6 w-full text-center text-blue-500"
            @click="$refs.eventStopRehearsalDialog.close()"
          >
            Take me back to rehearsal
          </button>
        </template>
      </Modal>

      <!-- Event stop confirmation -->
      <Modal width="96" ref="eventEndEventDialog">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">End event</h1>
          <ul>
            <li>- Your attendees and presenters will be logged out.</li>
            <li>- The event recording will end.</li>
            <li>- You can not restart the event.</li>
          </ul>
          <div class="pb-6 pt-12">
            <MaterialIconsCheckbox
              v-model="eventEndEventCheckbox"
              :label="'I understand'"
            />
          </div>

          <button
            class="btn-red"
            :disabled="eventEndEventCheckbox == false"
            @click="handleEndEvent"
          >
            End event
          </button>
        </template>
      </Modal>

      <!-- max concurrent running jobs modal -->
      <Modal ref="maxConcurrentRunningJobsDialog">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">Concurrent events</h1>
          <div class="mb-12 text-grey-500">
            <p class="mb-6">
              Your account allows {{ maxConcurrentJobs }}
              {{ maxConcurrentJobs > 1 ? 'running events' : 'running event' }}
              at once. Another event is currently running and must be stopped
              before this event can be started.
            </p>
            <p class="mb-6">
              <a
                href="mailto:support@joinin.live?subject=Enterprise concurrent events"
                class="text-blue-500"
                >Contact us</a
              >
              for an enterprise solution to run more concurrent events.
            </p>
          </div>

          <div class="flex flex-row items-center justify-center">
            <button
              class="btn-blue text-center"
              @click="
                () => {
                  this.$refs.maxConcurrentRunningJobsDialog.close();
                }
              "
            >
              OK
            </button>
          </div>
        </template>
      </Modal>

      <!-- event shutdown pending -->
      <Modal ref="idleShutdownWarningDialog" :closeBtn="false">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">
            Idle event shutdown pending
          </h1>
          <div class="mb-12 text-grey-500">
            <p class="mb-6">
              It looks like this event has been left running and is idle. Our
              systems detect and automatically close down idle events to prevent
              resource wastage.
              <a
                href="https://support.joinin.live/portal/en/kb/articles/idle-events"
                target="_blank"
                class="text-blue-500"
                >Read more here.</a
              >
            </p>
            <p>
              <span class="text-red-700"
                >This event will shutdown automatically at
                {{ idleShutdownTime }}.</span
              >
              Click the button below to cancel the automatic shutdown on this
              occasion.
            </p>
          </div>

          <div class="flex items-center justify-between">
            <button class="btn-red mr-2" @click="handleIdleShutdown">
              Shut down now
            </button>
            <button class="btn-blue" @click="handleIdleShutdownReset">
              Keep event running
            </button>
          </div>
        </template>
      </Modal>

      <!-- file upload page exit modal -->
      <Modal ref="confirmToLeaveDialog" :closeBtn="false">
        <template slot="content">
          <h1 class="mb-6 text-center text-2xl font-bold">File uploading</h1>
          <div class="mb-12 text-center text-grey-500">
            <p class="mb-6">
              A file upload is in progress. Leaving this page will cancel the
              upload. Are you sure?
            </p>
          </div>

          <button class="btn-blue" @click="$refs.confirmToLeaveDialog.close()">
            Stay on page
          </button>
          <button
            class="mt-6 w-full text-center text-red-500"
            @click="handleConfirmToLeave"
          >
            Leave page and cancel upload
          </button>
        </template>
      </Modal>
    </div>

    <!-- file uploader modals -->
    <div
      v-if="fileUploadQueue.length > 0"
      class="fixed bottom-2 left-2 flex justify-end"
    >
      <FileUploaderModal
        ref="fileUploaderModal"
        v-for="(item, index) in fileUploadQueue"
        :key="item.filename"
        :index="index"
        :file="item.file"
        :filename="item.filename"
        :replaceFileId="item.replaceFileId"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { formatRuntime } from '@/helper/formatting.js';
import { loadJanusJs, trackJanusPublishers } from '@/helper/janus.js';
import { string as randomString } from '@/helper/random.js';

import MixerVideoSource from '@/components/MixerVideoSource';
import Modal from '@/components/Modal';
import StudioPanelLayouts from '@/components/StudioPanelLayouts';
import StudioPanelPresenters from '@/components/StudioPanelPresenters';
import StudioPanelPresenterMessages from '@/components/StudioPanelPresenterMessages';
import StudioPanelScenes from '@/components/StudioPanelScenes';
import StudioPanelFiles from '@/components/StudioPanelFiles';
import StudioPanelPlaylists from '@/components/StudioPanelPlaylists';
import StudioPanelStreams from '@/components/StudioPanelStreams';
import StudioPanelRouter from '@/components/StudioPanelRouter';
import StudioPanelOutboundStreams from '@/components/StudioPanelOutboundStreams.vue';
import StudioPanelHtmls from '@/components/StudioPanelHtmls';
import StudioPanelQA from '@/components/StudioPanelQA';
import StudioPanelQuiz from '@/components/StudioPanelQuiz';
import StudioPanelChat from '@/components/StudioPanelChat';
import StudioPanelPoll from '@/components/StudioPanelPoll';
import StudioPanelSurvey from '@/components/StudioPanelSurvey';
import ActionSelectButton from '@/components/ActionSelectButton';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';
import TooltipModal from '@/components/TooltipModal';
import FileUploaderModal from '@/components/FileUploaderModal';
import OperatorComm from '@/components/OperatorComm';
import StudioPanelSettings from '@/components/StudioPanelSettings';

import TabItem from '@/components/ui/TabItem';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default {
  name: 'EventStudio',
  components: {
    MixerVideoSource,
    Modal,
    StudioPanelLayouts,
    StudioPanelScenes,
    StudioPanelFiles,
    StudioPanelPlaylists,
    StudioPanelStreams,
    StudioPanelRouter,
    StudioPanelOutboundStreams,
    StudioPanelHtmls,
    StudioPanelPresenters,
    StudioPanelPresenterMessages,
    StudioPanelQA,
    StudioPanelChat,
    StudioPanelPoll,
    StudioPanelQuiz,
    StudioPanelSurvey,
    ActionSelectButton,
    MaterialIconsCheckbox,
    TooltipModal,
    FileUploaderModal,
    TabItem,
    OperatorComm,
    StudioPanelSettings,
  },
  props: {
    jobId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    publishHandle: null,
    unreadPresenterMessagesCount: 0,
    unreadEngagementChatMessagesCount: 0,
    unreadEngagementQAMessagesCount: 0,
    selectedIndex: null,
    eventConfirmation: false,
    eventEndEventCheckbox: false,
    eventGoLiveCheckbox: false,
    presentersSubTab: 'manage',
    engagementSubTab: 'Q&A',
    activeTab: 'info',
    countDownThreshold: 300,
    vmAutoRestartAttempts: 1,
    countDown: 0,
    progress: 0,
    timeCountDownInterval: null,
    sceneShortcutDisabled: false,
    sceneShortcutDisabledTimeout: null,
    isStartRehearsalSaving: false,
    clockTimer: 0,
    now: null,
    checkShutdownInterval: null,
    navigateSceneTimeout: null,
    isConfirmToLeave: false,
    routeTo: null,
    nextForceUpdate: false,
    prevForceUpdate: false,
    sourcesUi: {},
    outputsUi: {},
    commsRoom: null,
    isCommsTalking: false,
    commsConnected: false,
    janusPublisherId: null,
    janusPublishers: {},
    cutMode: 'fade',
  }),
  computed: {
    ...mapState([
      'userGesture',
      'isSourcesLoaded',
      'janus',
      'outputs',
      'event',
      'layouts',
      'sources',
      'scenes',
      'presenters',
      'selectedScene',
      'files',
      'overlayId',
      'triggerSaveScene',
      'events',
      'lastSceneId',
      'fileUploadQueue',
      'audioContext',
      'sessionData',
    ]),
    ...mapGetters(['username', 'validateScene']),

    ...mapState({
      streamUniqueViewers: (s) => s.stats.streamUniqueViewers,
      streamLiveViewers: (s) => s.stats.streamLiveViewers,
      streamStartTime: (s) =>
        s.stats.streamStartTime ? new Date(s.stats.streamStartTime) : null,
      streamStopTime: (s) =>
        s.stats.streamStopTime ? new Date(s.stats.streamStopTime) : null,

      apiOrigin: (s) => s.apiOrigin,
    }),

    completeEvent() {
      return ['complete', 'vod_open', 'vod_closed'].includes(
        this.event.eventState
      );
    },

    vodOpen() {
      return ['vod_open'].includes(this.event.eventState);
    },

    runTime() {
      return formatRuntime(
        this.streamStartTime,
        this.streamStopTime ?? this.now
      );
    },

    idleShutdownTime() {
      return dayjs(this.event.idleShutdownTimeout).format('h:mm A');
    },

    isAudioLatched() {
      var audioLatch = false;

      this.sources.map((source) => {
        if (source.audioLatch == true) {
          audioLatch = true;
        }
      });

      return audioLatch;
    },
    eventStartTime() {
      return dayjs(this.event.startTime).format('h:mma, D MMMM YYYY');
    },
    // orderedSceneOptions() {
    //     var list = [...this.scenes];

    //     return list
    //         .map((scene) => {
    //             //avoid data mutation. use loadh cloneDeep
    //             //mode has multiple levels
    //             const layout = _.cloneDeep(
    //                 this.layouts.find((layout) => layout.mid == scene.modeId)
    //             );

    //             if (layout) {
    //                 layout.slots.map((slot, j) => {
    //                     var existSlot = scene.slots.find(
    //                         (sceneSlot) => sceneSlot.slotKey == slot.key
    //                     );
    //                     if (existSlot) {
    //                         var source = this.sources.find(
    //                             (source) => source.sid == existSlot.sourceId
    //                         );
    //                         if (source) {
    //                             layout.slots[j] = {
    //                                 ...slot,
    //                                 sourceTitle: source.title,
    //                             };
    //                         }
    //                     }
    //                 });
    //             }

    //             scene.layout = layout;

    //             return scene;
    //         })
    //         .sort((a, b) =>
    //             a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    //         )
    //         .map((scene, index) => {
    //             return {
    //                 id: index,
    //                 label: scene.title,
    //                 // text: scene.title,
    //                 // value: index,
    //                 scene: scene,
    //             };
    //         });
    // },
    getFiles() {
      return this.files.map((file) => {
        return { text: file.title, value: file.url };
      });
    },
    unreadPresentersCount() {
      return this.unreadPresenterMessagesCount > 99
        ? '99+'
        : this.unreadPresenterMessagesCount;
    },
    unreadEngagementCount() {
      var res =
        this.unreadEngagementChatMessagesCount +
        this.unreadEngagementQAMessagesCount;
      return res > 99 ? '99+' : res;
    },
    timeCountDown() {
      //m:ss
      return (
        Math.floor(this.countDown / 60) +
        ':' +
        ('0' + Math.floor(this.countDown % 60)).substr(-2)
      );
    },
    engagementOptions() {
      var options = [];
      if (this.event.config.chatEnabled) {
        options.push('chat');
      }

      if (this.event.config.qaEnabled) {
        options.push('Q&A');
      }

      if (this.event.config.pollEnabled) {
        options.push('poll');
      }

      if (this.event.config.quizEnabled) {
        options.push('quiz');
      }

      if (this.event.config.surveyEnabled) {
        options.push('survey');
      }

      return options;
    },
    maxConcurrentJobs() {
      const { account, accountTypes } = this.sessionData;
      const accountType = accountTypes.find((at) => at.id == account.type);
      return Number(
        account.customMaxConcurrentJobs ?? accountType.maxConcurrentJobs ?? 0
      );
    },
    orderedScenes() {
      const ordered = Array.from(this.scenes);
      return ordered.sort((a, b) => (+a.sortId > +b.sortId ? 1 : -1));
    },
    getOrderedScenes() {
      return this.orderedScenes.map((s, i) => {
        return { id: i, label: s.name };
      });
    },
    disablePrev() {
      return this.lastUsedSceneIndex == -1 || this.lastUsedSceneIndex == 0;
    },
    disableNext() {
      return this.lastUsedSceneIndex == this.orderedScenes.length - 1;
    },
    previousScene() {
      if (this.lastUsedSceneIndex == -1) {
        return null;
      } else {
        if (this.lastUsedSceneIndex == 0) {
          return null;
        } else {
          return this.orderedScenes[this.lastUsedSceneIndex - 1];
        }
      }
    },
    nextScene() {
      if (this.lastUsedSceneIndex == -1) {
        return this.orderedScenes[0];
      } else {
        if (this.lastUsedSceneIndex < this.orderedScenes.length - 1) {
          return this.orderedScenes[this.lastUsedSceneIndex + 1];
        } else {
          return null;
        }
      }
    },
    lastUsedScene() {
      if (this.lastSceneId) {
        return this.orderedScenes.find((s) => s.id == this.lastSceneId);
      }

      return null;
    },
    lastUsedSceneIndex() {
      if (this.lastSceneId) {
        return this.orderedScenes.findIndex((s) => s.id == this.lastSceneId);
      }

      return -1;
    },
    eventSources() {
      return this.sources.map((s) => {
        const uiFields = this.sourcesUi[s.sid] || {
          muted: true,
        };
        return Object.assign({}, s, uiFields);
      });
    },
    eventOutputs() {
      return this.outputs.map((o) => {
        const uiFields = this.outputsUi[o.oid] || {
          muted: true,
        };
        return Object.assign({}, o, uiFields);
      });
    },
    onlinePresentersCount() {
      let count = 0;
      for (const p of this.presenters) {
        if (this.janusPublishers[p.pid]) {
          count += 1;
        }
      }
      return count;
    },
  },

  watch: {
    'jobId': function () {
      console.log('job ID changed');
    },
    'activeTab': function (newVal, oldVal) {
      if (oldVal == 'layouts') {
        //when switch out from layouts. turn off layout builder mode
        this.$store.commit('SET_LAYOUT_BUILDING_OFF');
      }

      if (newVal == 'presenters' && this.presentersSubTab == 'messages') {
        this.unreadPresenterMessagesCount = 0;
      }
    },
    'presentersSubTab': function (val) {
      if (val === 'messages') {
        //activate Messages sub tab
        this.unreadPresenterMessagesCount = 0;
      }
    },
    'event.eventState': async function (newVal, oldVal) {
      if (newVal == 'starting') {
        if (oldVal == 'scheduled') {
          this.triggerTimeCountDown(true);
        }
      } else if (newVal == 'scheduled') {
        this.$store.commit('modalOpen', false);

        //reset buttons
        this.isStartRehearsalSaving = false;
      } else if (newVal == 'rehearsal') {
        if (oldVal == 'scheduled' || oldVal == 'starting') {
          clearInterval(this.timeCountDownInterval);
          // jobs with active VMs go straight from scheduled -> rehearsal
          await this.loadData();
          this.janusInit();
          this.janusVideoroom();
        }
      }
    },
    'triggerSaveScene': function (val) {
      if (val == true) {
        this.navigateTo('scenes');
        this.navigateSceneTimeout = setTimeout(() => {
          this.$refs.scenes.navigateToSaveScene();
        }, 100);
      }
    },
    'event.idleShutdownWarning': function (val) {
      if (val == true && this.can('studio-control')) {
        this.$refs.idleShutdownWarningDialog.show();
      }
    },
    // selectedScene: function(val) {
    //     //sync selected scene from Scene component
    //     if(val != null) {
    //         var sIndex = this.orderedScenes.findIndex(s => s.id == val.id);
    //         this.selectedIndex = sIndex;

    //         this.prevForceUpdate = false;
    //         this.nextForceUpdate = false;
    //     }
    // }
    'presenters': function (newPresenters, oldPresenters) {
      const addedPresenters = newPresenters.filter(
        (np) => !oldPresenters.some((op) => op.pid == np.pid)
      );
      for (const ap of addedPresenters) {
        if (this.janusPublishers[ap.pid]) {
          this.$store.commit('MESSAGE', `Presenter ${ap.name} is online.`);

          this.$store.dispatch('CONTROL', {
            mode: 'request_presenter_state',
            payload: { id: ap.pid },
          });
        }
      }
    },
    'onlinePresentersCount'() {
      this.$store.commit(
        'SET_ONLINE_PRESENTERS_COUNT',
        this.onlinePresentersCount
      );
    },
  },

  mounted() {
    this.startClock();
    this.init();

    this.checkShutdownTimeout();
    this.checkShutdownInterval = setInterval(() => {
      this.checkShutdownTimeout();
    }, 30000);

    if (!this.completeEvent && this.can('studio-control')) {
      window.addEventListener('keydown', this.handleKeyboardShortcut);
      window.addEventListener('keyup', this.releaseKeyboardShortcut);
    }
  },

  beforeDestroy() {
    this._destroying = true;
    clearInterval(this.checkShutdownInterval);
    clearInterval(this.timeCountDownInterval);
    clearTimeout(this.navigateSceneTimeout);
    if (this.janus) {
      this.janus.destroy();
      // janus.js does not expose whether a destroy() is in progress
      // Add our own flag to signal to child components not to detach()
      // handles if we are already destroying the session
      this.janus._destroying = true;
    }
    this.$store.commit('SET_JANUS', null);

    window.removeEventListener('keydown', this.handleKeyboardShortcut);
    window.removeEventListener('keyup', this.releaseKeyboardShortcut);
  },

  // "unmounted" does not seem to be called reliably enough
  beforeRouteLeave(to, from, next) {
    if (
      this.fileUploadQueue &&
      this.fileUploadQueue.length > 0 &&
      !this.isConfirmToLeave
    ) {
      this.routeTo = to;
      this.$refs.confirmToLeaveDialog.show();
    } else {
      this.stopClock();
      this.$store.dispatch('CONTROL_LEAVE');

      next();
    }
  },

  methods: {
    async handleConfirmToLeave() {
      this.isConfirmToLeave = true;
      this.$refs.confirmToLeaveDialog.close();

      for (var i = 0; i < this.fileUploadQueue.length; i++) {
        this.$refs.fileUploaderModal[i].handleCancelFileUpload();
      }

      this.$router.push(this.routeTo);
    },
    activateContext() {
      if (!this.userGesture) {
        this.audioContext.resume().then(() => {
          this.$store.commit('SET_USER_INPUT');
        });
      }
    },
    async init() {
      await this.loadData();
      this.controlInit();

      if (this.event.eventState == 'starting') {
        this.triggerTimeCountDown(false);
      } else if (['rehearsal', 'live'].includes(this.event.eventState)) {
        this.janusInit();
        this.janusVideoroom();
      }
    },
    controlInit() {
      this.$store.dispatch('CONTROL_INIT').then(() => {
        // Does not need to be unbound on destroy as pusher channel
        // teardown happens in CONTROL_LEAVE
        this.$store.state.clientControl.bind('client-control', (data) => {
          if (!this.janusPublisherId) return;
          if (data.mode == 'request_operator_comms_state') {
            if (data.payload.id == this.janusPublisherId) {
              this.sendCommsUpdate();
            }
          } else if (data.mode == 'operator_comms_state') {
            const jp = this.janusPublishers[data.payload.id];
            if (jp && jp.operator) {
              jp.operator.commsRoom = data.payload.commsRoom;
              jp.operator.isCommsTalking = data.payload.isTalking;
            }
          }
        });
      });
    },
    janusInit() {
      loadJanusJs(this.event.janusHost);
      this.janusConnectedPromise = Promise.all([
        window.adapterLoadedPromise,
        window.janusLoadedPromise,
      ]).then(
        () =>
          new Promise((resolve) => {
            if (this._destroying) {
              return void resolve();
            }
            const _janus = new Janus({
              server: this.event.janusHost + '/janus',
              success: () => {
                this.$store.commit('SET_JANUS', _janus);
                resolve();
              },
              error: console.error.bind(console),
              destroyed: () => {
                //console.log('destroyed');
              },
            });
          })
      );
    },
    async janusVideoroom() {
      await this.janusConnectedPromise;
      if (this._destroying) return;

      if (this.publishHandle) {
        this.publishHandle.detach();
        this.publishHandle = null;
      }
      this.publishHandle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: resolve,
          error: (err) => {
            console.log('hit', err);
          },
          onmessage: (msg, jsep) => {
            if (msg.videoroom == 'joined') {
              this.janusPublisherId = msg.id;
            }

            const { removedId, added } = trackJanusPublishers({
              janusPublishers: this.janusPublishers,
              janusMsg: msg,
            });
            if (removedId) {
              const [pType, pTypeId] = removedId.split('-');
              if (pType === 'Presenter') {
                const pub = this.presenters.find((p) => p.pid == pTypeId);
                if (pub) {
                  this.$store.commit(
                    'ERROR',
                    `Presenter ${pub.name} is offline.`
                  );
                }
              }
            }
            if (added.length > 0) {
              for (const newPub of added) {
                const [pType, pTypeId] = newPub.id.split('-');
                if (pType === 'Presenter') {
                  const presenter = this.presenters.find(
                    (p) => p.pid == pTypeId
                  );
                  if (presenter) {
                    this.$store.commit(
                      'MESSAGE',
                      `Presenter ${presenter.name} is online.`
                    );

                    this.$store.dispatch('CONTROL', {
                      mode: 'request_presenter_state',
                      payload: { id: presenter.pid },
                    });
                  }
                } else if (pType === 'StudioOperator') {
                  Vue.set(newPub, 'operator', {
                    commsRoom: null,
                    isCommsTalking: false,
                  });
                  this.$store.dispatch('CONTROL', {
                    mode: 'request_operator_comms_state',
                    payload: { id: newPub.id },
                  });
                }
              }
            }

            if (jsep && jsep.type === 'answer') {
              this.publishHandle.handleRemoteJsep({ jsep });
            }
          },
        });
      });

      this.publishHandle.send({
        message: {
          request: 'join',
          ptype: 'publisher',
          room: String(this.event.videoRoomId),
          pin: this.event.janusPin,
          id:
            'StudioOperator-' +
            this.sessionData.user.firstName +
            '-' +
            randomString(12),
          display: this.sessionData.user.firstName,
        },
      });
    },
    async loadData() {
      await this.$store.dispatch('GET_JOB');
    },
    handleCut() {
      this.$store.dispatch('SWITCH_PREVIEW_PROGRAM');
    },
    handleFade() {
      this.$store.dispatch('SWITCH_PREVIEW_PROGRAM', this.cutMode);
    },
    handleSelectScene(e) {
      this.selectedIndex = +e;
      this.$store.commit(
        'SELECT_SCENE',
        this.orderedScenes[this.selectedIndex]
      );

      this.handleSendScene();
    },
    handleScenePrev() {
      // if (this.selectedIndex > 0) {
      //     this.selectedIndex--;
      //     this.$store.commit(
      //         'SELECT_SCENE',
      //         this.orderedScenes[this.selectedIndex]
      //     );

      //     console.log(this.selectedScene, this.validateScene);
      //     if(!this.validateScene || (this.validateScene && this.prevForceUpdate)) {
      //         this.handleSendScene();
      //     } else {
      //         this.activeTab = 'scenes';
      //     }
      // }
      this.processScene('prev');
    },
    handleSceneNext() {
      // console.log('validate', this.validateScene);
      // if(this.validateScene && !this.nextForceUpdate) {
      //     this.nextForceUpdate = true;
      //     this.activeTab = 'scenes';
      // } else {
      //     if(this.selectedIndex == null) this.selectedIndex = 0;
      //     else if (this.selectedIndex < this.orderedScenes.length - 1) {
      //         this.selectedIndex++;
      //     }

      //     this.$store.commit(
      //         'SELECT_SCENE',
      //         this.orderedScenes[this.selectedIndex]
      //     );

      //     if(!this.validateScene || (this.validateScene && this.nextForceUpdate)) {
      //         this.handleSendScene();
      //     }
      // }

      this.processScene('next');
    },
    handleSendScene() {
      this.prevForceUpdate = false;
      this.nextForceUpdate = false;

      this.$store.dispatch(
        'SEND_SCENE',
        this.orderedScenes[this.selectedIndex].id
      );
      /*var list = [];
            var layout = this.layouts.find(
                (layout) => layout.mid == this.selectedScene.modeId
            );

            if (layout) {
                layout.slots.map((slot) => {
                    var existSlot = this.selectedScene.slots.find(
                        (sceneSlot) => sceneSlot.slotKey == slot.key
                    );
                    if (existSlot) {
                        list.push({
                            ...slot,
                            sourceId: existSlot.sourceId,
                        });
                    }
                });
            }

            this.$store.dispatch('SEND_TO_PREVIEW', list);*/
    },
    toggleEventConfirmation() {
      this.eventConfirmation = true;
    },
    handleNewMessagesNotification(payload) {
      this.unreadPresenterMessagesCount = payload;
    },
    handleEngagementChatNewMessagesNotification(payload) {
      this.unreadEngagementChatMessagesCount = payload;
    },
    handleEngagementQANewMessagesNotification(payload) {
      this.unreadEngagementQAMessagesCount = payload;
    },
    setCutMode(cutMode) {
      this.cutMode = cutMode;
    },
    navigateTo(route) {
      this.activeTab = null;
      this.$nextTick(() => {
        this.activeTab = route;
      });
    },
    async handleEventStartRehearsal() {
      //pop modal to warn if reaches maxConcurrent jobs
      await this.$store.dispatch('GET_JOBS');

      if (
        this.events.filter(
          (r) =>
            ['starting', 'rehearsal', 'live'].includes(r.eventState) &&
            r.id != this.event.jobId
        ).length >= this.maxConcurrentJobs
      ) {
        this.$refs.maxConcurrentRunningJobsDialog.show();
        return;
      }

      this.isStartRehearsalSaving = true;
      this.$store.dispatch('EVENT_STATE_REHEARSAL');
    },
    handleEndEvent() {
      this.$refs.eventEndEventDialog.close();
      this.$store.dispatch('EVENT_STATE_COMPLETE');
    },
    handleEventVodOpen() {
      this.$store.dispatch('EVENT_STATE_VOD_OPEN', 'vod_open');
    },
    handleEventVodClose() {
      this.$store.dispatch('EVENT_STATE_VOD_CLOSED', 'vod_closed');
    },
    handleStopRehearsal() {
      this.$store.dispatch('EVENT_STATE_SCHEDULED');
    },
    async checkEventAllowedLive() {
      //pop modal to warn if reaches maxConcurrent jobs
      await this.$store.dispatch('GET_JOBS');
      if (
        this.events.filter(
          (r) =>
            ['starting', 'rehearsal', 'live'].includes(r.eventState) &&
            r.id != this.event.jobId
        ).length >= this.maxConcurrentJobs
      ) {
        this.$refs.maxConcurrentRunningJobsDialog.show();
        return;
      }

      this.$refs.eventGoLiveDialog.show();
    },
    handleEventGoLive() {
      this.$refs.eventGoLiveDialog.close();
      this.$store.dispatch('EVENT_STATE_LIVE');
    },
    handleIdleShutdownReset() {
      this.$refs.idleShutdownWarningDialog.close();
      this.$store.dispatch('RESET_JOB_IDLE_SHUTDOWN_TIMEOUT');
    },
    handleIdleShutdown() {
      this.$refs.idleShutdownWarningDialog.close();
      //reset idle_warning
      this.$store.commit('SET_JOB_IDLE_SHUTDOWN_WARNING', false);

      if (this.event.eventState == 'rehearsal') {
        this.$store.dispatch('EVENT_STATE_SCHEDULED');
      } else if (this.event.eventState == 'live') {
        this.$store.dispatch('EVENT_STATE_COMPLETE');
      }
    },
    triggerTimeCountDown(trigger) {
      clearInterval(this.timeCountDownInterval);
      if (this.event.eventState != 'starting') return;

      if (trigger) {
        this.countDown = this.countDownThreshold;
      } else {
        const diff =
          dayjs().unix() - dayjs(this.event.config.eventStateCreatedAt).unix();

        if (diff > this.countDownThreshold) {
          this.countDown = 0;
        } else {
          this.countDown = this.countDownThreshold - diff;
        }
      }

      this.timeCountDownInterval = setInterval(() => {
        if (
          this.countDown >
          -(this.countDownThreshold * this.vmAutoRestartAttempts)
        ) {
          this.countDown--;
          this.progress = Math.floor(
            (1 - this.countDown / this.countDownThreshold) * 100
          );
        } else {
          clearInterval(this.timeCountDownInterval);
        }
      }, 1000);
    },
    removeLatchedAudio() {
      this.$store.dispatch('RESET_AUDIO_LATCH');
    },
    removeOverlay() {
      this.$store.dispatch('RESET_OVERLAY');
    },
    handleRemoveNotification(payload) {
      this.$store.commit('REMOVE_NOTIFICATION', payload);
    },
    startClock() {
      this.clockTimer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    },
    stopClock() {
      clearInterval(this.clockTimer);
    },
    checkShutdownTimeout() {
      if (
        dayjs(this.event.idleShutdownTimeout).diff(dayjs(), 'm') <=
        this.event.idleShutdownWarningMinutes
      ) {
        this.$store.dispatch('GET_JOB_IDLE_SHUTDOWN_TIMEOUT');
      }
    },
    handleKeyboardShortcut(e) {
      if (e.repeat) return;
      var target = e.target.nodeName.toLowerCase();
      if (!/input|select|textarea/.test(target)) {
        //scenes alt + * shortcut
        if (e.altKey == true && e.key != 'Alt' && !this.sceneShortcutDisabled) {
          this.sceneShortcutDisabled = true;

          // enforce 1 second timeout between scene recalls
          clearTimeout(this.sceneShortcutDisabledTimeout);
          this.sceneShortcutDisabledTimeout = setTimeout(() => {
            this.sceneShortcutDisabled = false;
          }, 1000);

          var mode = '';
          if (e.key == 'ArrowLeft') mode = 'prev';
          else if (e.key == 'ArrowRight') mode = 'next';
          else mode = e.code;
          this.processScene(mode);
        }

        // presenter comm shortcut
        // shift left
        if (e.code == 'ShiftLeft') {
          this.$store.commit('ACTIVATE_SHORTCUT', 'talk');
        }

        // alt + escape/return
        if (e.key === 'Escape' || (e.altKey && e.code === 'KeyO')) {
          this.removeOverlay();
        }

        // alt + space
        if (e.altKey && e.code === 'Space') {
          this.handleCut();
        }

        // alt + enter
        if (e.altKey && e.key === 'Enter') {
          this.handleFade();
        }

        // alt + l
        if (e.altKey && e.code === 'KeyL') {
          this.removeLatchedAudio();
        }
      }
    },
    processScene(mode) {
      var forceProcess = false;
      this.prevForceUpdate = false;
      this.nextForceUpdate = false;

      if (mode == 'prev') {
        let cIndex = this.orderedScenes.findIndex(
          (s) => s.id == this.lastSceneId
        );
        if (cIndex < 1 && cIndex != -1) return;

        if (cIndex == -1) cIndex = 0;
        else cIndex = cIndex - 1;

        if (
          this.selectedScene &&
          this.selectedScene.id == this.orderedScenes[cIndex].id
        ) {
          //console.log('prev force');
          forceProcess = true;
        }

        this.$store.commit('SET_SELECT_SCENE', this.orderedScenes[cIndex]);
      } else if (mode == 'next') {
        let cIndex = this.orderedScenes.findIndex(
          (s) => s.id == this.lastSceneId
        );
        if (cIndex >= this.orderedScenes.length - 1) return;

        if (cIndex == -1) cIndex = 0;
        else cIndex = cIndex + 1;

        if (
          this.selectedScene &&
          this.selectedScene.id == this.orderedScenes[cIndex].id
        ) {
          //console.log('next force');
          forceProcess = true;
        }

        this.$store.commit('SET_SELECT_SCENE', this.orderedScenes[cIndex]);
      } else {
        const selectedScene = this.scenes.find(
          (s) =>
            s.shortcut ==
            mode.replace('Key', '').replace('Digit', '').toLowerCase()
        );
        if (!selectedScene) return;

        if (this.selectedScene && this.selectedScene.id == selectedScene.id) {
          //console.log('next force');
          forceProcess = true;
        }

        this.$store.commit('SET_SELECT_SCENE', selectedScene);
      }

      if (this.selectedScene) {
        if (forceProcess) {
          this.$store.dispatch('SEND_SCENE', this.selectedScene.id);
        } else {
          if (this.validateScene) {
            if (mode == 'prev') this.prevForceUpdate = true;
            if (mode == 'next') this.nextForceUpdate = true;
            this.activeTab = 'scenes';
          } else {
            this.$store.dispatch('SEND_SCENE', this.selectedScene.id);
          }
        }
      }
    },
    releaseKeyboardShortcut(e) {
      if (e.code == 'ShiftLeft') {
        this.$store.commit('DEACTIVATE_SHORTCUT', 'talk');
      }
    },
    updateActiveTab(tab) {
      this.activeTab = tab;
    },
    handleSetOutputUiMuted(oid, muted) {
      let output = this.outputsUi[oid];
      if (!output) {
        Vue.set(this.outputsUi, oid, { muted: null });
        output = this.outputsUi[oid];
      }
      output.muted = muted;
    },
    handleSetSourceUiMuted(sid, muted) {
      let source = this.sourcesUi[sid];
      if (!source) {
        Vue.set(this.sourcesUi, sid, { muted: null });
        source = this.sourcesUi[sid];
      }
      source.muted = muted;
    },
    handleSetCommsConnected(value) {
      this.commsConnected = value;
    },
    handleSetCommsRoom(room, suppressUpdate = false) {
      const oldRoom = this.commsRoom;
      if (this.commsRoom != room) {
        this.isCommsTalking = false;
      }
      this.commsRoom = room;
      if (!suppressUpdate) {
        this.sendCommsUpdate();
      }

      // Mute all sources/outputs when starting to listen to comms
      if (oldRoom == null && room != null) {
        for (const id in this.sourcesUi) {
          Vue.delete(this.sourcesUi, id);
        }
        for (const id in this.outputsUi) {
          Vue.delete(this.outputsUi, id);
        }
      }
    },
    handleSetCommsTalking(talking) {
      this.isCommsTalking = talking;
      this.sendCommsUpdate();
    },
    sendCommsUpdate() {
      if (!this.janusPublisherId) {
        console.error('Called sendCommsUpdate before janus publisher ready');
        return;
      }
      this.$store.dispatch('CONTROL', {
        mode: 'operator_comms_state',
        payload: {
          id: this.janusPublisherId,
          commsRoom: this.commsRoom,
          isTalking: this.isCommsTalking,
        },
      });
    },
    handleToggleAudioSettingMenu() {
      this.activeTab = 'settings';
    },
  },
};
</script>
