import Pusher from 'pusher-js';

export const initPusher = () => {
  // Bypass default pusher auth workflow
  // Default pusher auth request doesn't allow a separate auth endpoint for
  // each channel, or doing a CORS request
  // Retrieve the auth credentials separately and pass to pusher through the
  // custom authorizer hooks
  // https://support.pusher.com/hc/en-us/articles/360019419713-Providing-a-custom-Channels-authoriser
  const sym = Symbol('authorizer');
  const authorizers = Pusher.Runtime.getAuthorizers();
  Pusher.Runtime.getAuthorizers = () => authorizers;
  let authCallbackFn;
  authorizers[sym] = async function (context, socketId, callback) {
    try {
      callback(false, await authCallbackFn.call(this, context, socketId));
    } catch (e) {
      console.error('authCallbackFn error', e);
      callback(true);
    }
  };

  const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authTransport: sym,
  });

  pusher.connection.bind('error', (err) => {
    console.log('Pusher Error', err);
  });

  pusher._setAuthCallbackFn = (fn) => {
    authCallbackFn = fn;
  };

  return pusher;
};
