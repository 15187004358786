<template>
  <div class="relative h-full">
    <template v-if="layout == 'view'">
      <div
        :loading="!isLoaded"
        :class="{
          'ring-4 ring-blue-500 ring-offset-0': isProgramSelected,
          'ring-4 ring-green-500 ring-offset-0': isPreviewSelected,
          'animate-pulse': attentionHighlight,
        }"
        class="flex flex-col justify-start rounded bg-grey-200 shadow-lg"
      >
        <VideoPlayer
          v-if="data.streamId"
          :stream="stream"
          :muted="data.muted"
          :volume="data.volume"
          class="h-64 overflow-hidden rounded-t"
        />

        <div class="rounded-b bg-white p-2">
          <div class="flex items-start items-center justify-between">
            <div class="title text-sm font-bold">{{ title }}</div>
          </div>

          <div class="mt-1 text-xs text-grey-400">
            {{ displayType }}
            <button
              @click="toggleMuted"
              class="float-right cursor-pointer rounded-full px-2 text-grey-400 hover:bg-grey-200"
              :class="{ 'text-blue-500': !data.muted }"
            >
              {{ data.muted ? 'Listen off' : 'Listen on' }}
            </button>
          </div>
          <div>
            <div
              class="mt-4 flex justify-between text-xs font-semibold text-grey-300"
            >
              <div
                class="flex-1"
                :class="{ 'text-green-500': isPreviewSelected }"
                cols="6"
              >
                Next
              </div>
              <div
                class="flex-1 text-right"
                :class="{ 'text-blue-800': isProgramSelected }"
                cols="6"
              >
                On Air
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="layout == 'lite'">
      <div class="relative w-full">
        <VideoPlayer
          v-if="data.streamId"
          class="h-64 rounded-sm"
          :stream="stream"
          :muted="data.muted"
          :volume="data.volume"
        />

        <div class="flex items-center justify-center bg-white p-3">
          <AudioIndicator
            :stream="stream"
            class="audio"
            :indicatorNodes="30"
            :userGesture="this.userGesture"
            :audioContext="this.audioContext"
          />
        </div>

        <div class="mt-1 flex flex-row items-center justify-between">
          <div class="flex items-center justify-center">
            <div
              class="font-bold"
              :class="{
                'text-blue-500': data.mixerName == 'program',
                'text-green-500': data.mixerName == 'preview',
              }"
            >
              {{ title }}
            </div>
            <TooltipModal
              triggerType="hover"
              width="48"
              xAlign="left"
              textAlign="center"
            >
              <template slot="control">
                <button
                  @click="removeAudioLatch"
                  class="flex h-6 w-auto items-center justify-center rounded-full p-1 text-grey-500 hover:bg-grey-200"
                >
                  <i class="material-icons-outlined text-icon-sm"
                    >help_outline</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div v-if="data.mixerName == 'program'" class="text-sm">
                    Your live video and audio display. The 'On Air' display will
                    be
                    <span class="font-bold text-blue-500"
                      >seen by the audience</span
                    >
                    and recorded when your event is Live.
                  </div>
                  <div v-else-if="data.mixerName == 'preview'" class="text-sm">
                    Your upcoming video and audio display. The 'Next' display is
                    <span class="font-bold text-red-700">not</span> seen by the
                    audience. This display will be transitioned 'On Air' when
                    you use the Switch or Fade button.
                  </div>
                </div>
              </template>
            </TooltipModal>
          </div>
          <div v-if="isSingleProgramVideo" class="">
            Video remaining {{ timeFormat(data.countDown) }}
          </div>
          <button
            @click="toggleMuted"
            class="float-right cursor-pointer rounded-full px-2 text-grey-400 hover:bg-grey-200"
            :class="{ 'text-blue-500': !data.muted }"
          >
            {{ data.muted ? 'Listen off' : 'Listen on' }}
          </button>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        :loading="!isLoaded"
        :class="{
          'ring-4 ring-blue-500 ring-offset-0': isProgramSelected,
          'ring-4 ring-green-500 ring-offset-0': isPreviewSelected,
          'animate-pulse': attentionHighlight,
        }"
        class="flex h-full flex-col justify-start rounded bg-grey-200 shadow-lg"
      >
        <VideoPlayer
          v-if="data.streamId"
          :stream="stream"
          :muted="data.muted"
          class="h-64 cursor-pointer overflow-hidden rounded-t"
          @click.native.stop="handleClick(data)"
        />

        <div
          v-if="data.audioLatch"
          class="top absolute flex w-full items-center justify-center bg-blue-500 bg-opacity-50 py-1 text-xs text-white"
        >
          Audio latched ON
        </div>

        <div class="controls p-1" v-if="isMediaVideo">
          <div class="flex flex-row justify-between">
            <TooltipModal triggerType="hover" width="48" textAlign="center">
              <template slot="control">
                <button
                  @click.prevent="handleVideoPlay"
                  class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                >
                  <i
                    v-if="data.play"
                    class="material-icons-outlined text-icon-sm text-blue-400"
                    >pause_circle_filled</i
                  >

                  <i
                    v-if="!data.play"
                    class="material-icons-outlined text-icon-sm text-grey-400"
                    >play_circle_filled</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div v-if="data.play" class="text-sm">Pause video</div>

                  <div v-if="!data.play" class="text-sm">Play video</div>
                </div>
              </template>
            </TooltipModal>

            <TooltipModal triggerType="hover" width="48" textAlign="center">
              <template slot="control">
                <button
                  @click="handleVideoReset"
                  class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                >
                  <i class="material-icons-outlined text-icon-sm text-grey-400"
                    >skip_previous</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div class="text-sm">Reset video to start</div>
                </div>
              </template>
            </TooltipModal>

            <TooltipModal triggerType="hover" width="48" textAlign="center">
              <template slot="control">
                <button
                  @click.prevent="handleVideoLoop(!data.loop)"
                  class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                >
                  <i
                    v-if="data.loop"
                    class="material-icons-outlined text-icon-sm text-blue-400"
                    >repeat_on</i
                  >

                  <i
                    v-if="!data.loop"
                    class="material-icons-outlined text-icon-sm text-grey-400"
                    >repeat</i
                  >
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div v-if="data.loop" class="text-sm">Remove loop</div>

                  <div v-if="!data.loop" class="text-sm">Loop video</div>
                </div>
              </template>
            </TooltipModal>
          </div>
          <div class="time_bar flex flex-col items-end space-y-1 px-1">
            <span
              class="mt-1"
              :class="countdownHighlight"
              @click="toggleCounterDisplay"
              v-if="currentFile.videoLengthMs != null"
              >{{ counterDisplay }}</span
            >

            <RangeSlider
              :max="currentFile.videoLengthMs"
              :min="0"
              :step="100"
              :disabled="currentFile.videoLengthMs == null"
              :progress="videoProgress"
              @updateprogress="videoControlSeek"
            ></RangeSlider>
          </div>
        </div>

        <div class="controls" v-else-if="isPresenter">
          <button
            @click="toggleSourceAudioInput"
            :disabled="data.presenterId == null"
            class="flex h-6 w-auto items-center justify-start rounded-full p-1 text-grey-500 hover:bg-grey-200"
            :class="{ 'text-red-700': data.sourceMuted }"
          >
            <i
              v-if="data.sourceMuted"
              class="material-icons-outlined pr-1 text-icon-sm text-red-700"
              >mic_off</i
            >
            <i
              v-else
              class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
              >mic</i
            >
            {{ data.sourceMuted ? 'Mic off' : 'Mic on' }}
          </button>

          <!-- TODO: JOININ-499 show presenter title
                    <button
                        class="hover:bg-grey-200 w-auto h-6 p-1 rounded-full flex items-center justify-end text-grey-500">
                            <i class="material-icons-outlined text-icon-sm text-grey-400 pr-1">call_to_action</i>
                            Show title
                    </button>
                    -->
        </div>

        <div class="controls" v-else-if="isIoClient">
          <button
            @click="toggleSourceAudioInput"
            :disabled="data.ioClientId == null"
            class="flex h-6 w-auto items-center justify-start rounded-full p-1 text-grey-500 hover:bg-grey-200"
            :class="{ 'text-red-700': data.sourceMuted }"
          >
            <i
              v-if="data.sourceMuted"
              class="material-icons-outlined pr-1 text-icon-sm text-red-700"
              >mic_off</i
            >
            <i
              v-else
              class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
              >mic</i
            >
            {{ data.sourceMuted ? 'Mic off' : 'Mic on' }}
          </button>
        </div>

        <div class="controls" v-else-if="isImage || isStream || isIoClient">
          <button
            @click="handleClickOverlay"
            class="flex h-6 w-auto items-center justify-end rounded-full p-1 text-grey-500 hover:bg-grey-200"
            :class="{ 'text-blue-500': data.sid == overlayId }"
          >
            <i
              class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
              :class="{ 'text-blue-500': data.sid == overlayId }"
              >call_to_action</i
            >
            Overlay
          </button>
        </div>

        <div class="controls" v-else-if="isHtml">
          <button
            @click="handleClickOverlay"
            class="flex h-6 w-auto items-center justify-end rounded-full p-1 text-grey-500 hover:bg-grey-200"
            :class="{ 'text-blue-500': data.sid == overlayId }"
          >
            <i
              class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
              :class="{ 'text-blue-500': data.sid == overlayId }"
              >call_to_action</i
            >
            Overlay
          </button>

          <!-- JOININ-174 JOININ-175 html reload
                    <button
                        class="hover:bg-grey-200 w-auto h-6 p-1 rounded-full flex items-center justify-center text-grey-500">
                            <i class="material-icons-outlined text-icon-sm text-grey-400 pr-1">refresh</i>
                            Reload
                    </button>
                    -->
        </div>

        <div class="flex flex-col" v-else-if="isPlaylist">
          <div class="controls p-1">
            <div class="flex w-full flex-row items-center justify-between">
              <button
                @click="handleClickOverlay"
                class="flex h-6 w-auto items-center justify-end rounded-full p-1 text-grey-500 hover:bg-grey-200"
                :class="{ 'text-blue-500': data.sid == overlayId }"
              >
                <i
                  class="material-icons-outlined pr-1 text-icon-sm text-grey-400"
                  :class="{ 'text-blue-500': data.sid == overlayId }"
                  >call_to_action</i
                >
                Overlay
              </button>

              <TooltipModal triggerType="hover" width="48" textAlign="center">
                <template slot="control">
                  <button
                    @click="handlePlaylistPrevious"
                    :disabled="data.playlistPosition == 0"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                  >
                    <i
                      class="material-icons-outlined text-icon-sm font-bold text-black"
                      :class="{
                        'text-grey-400':
                          isPrevPositionProcessing ||
                          data.playlistPosition == 0,
                      }"
                    >
                      keyboard_arrow_left
                    </i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div class="text-sm">Show previous playlist item</div>
                  </div>
                </template>
              </TooltipModal>

              <DropdownMenu ref="jumpPosition">
                <template slot="control">
                  <button
                    @click="handleJumpPositionOpen"
                    class="text-xs text-grey-400 underline"
                  >
                    {{ data.playlistPosition + 1 }}/{{ playlistTotal }}
                  </button>
                </template>

                <template slot="menu">
                  <div class="flex w-full flex-col items-center px-4 py-2">
                    <div class="mb-2 flex text-xs text-grey-400">
                      Jump to position
                    </div>
                    <div class="flex items-center justify-between">
                      <input
                        type="number"
                        min="1"
                        :max="playlistTotal"
                        ref="playlistPositionInput"
                        v-model="playlistPosition"
                        @keyup.enter="jumpToSlide"
                        class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1 text-center focus:border-blue-500"
                      />
                      <button
                        @click="jumpToSlide"
                        class="ml-1 rounded-full border border-grey-200 px-1 py-1 hover:bg-grey-100"
                      >
                        <i class="material-icons-outlined">arrow_forward</i>
                      </button>
                    </div>
                  </div>
                </template>
              </DropdownMenu>

              <TooltipModal triggerType="hover" width="48" textAlign="center">
                <template slot="control">
                  <button
                    @click="handlePlaylistNext"
                    :disabled="data.playlistPosition + 1 == playlistTotal"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                  >
                    <i
                      class="material-icons-outlined text-icon-sm font-bold text-black"
                      :class="{
                        'text-grey-400':
                          isNextPositionProcessing ||
                          data.playlistPosition == playlistTotal,
                      }"
                    >
                      keyboard_arrow_right
                    </i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div class="text-sm">Show next playlist item</div>
                  </div>
                </template>
              </TooltipModal>

              <!-- show if access allows and if there is a presenter assigned control -->
              <TooltipModal
                v-if="can('studio-presenter-control') && data.plCtrlPresenterId"
                triggerType="hover"
                width="48"
                textAlign="center"
              >
                <template slot="control">
                  <button
                    @click="togglePlaylistPresenterControl"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                    :disabled="!data.plCtrlPresenterId"
                  >
                    <i
                      class="material-icons-outlined text-icon-sm font-bold"
                      :class="{ 'text-red-500': !data.allowPresenterControl }"
                    >
                      {{
                        data.allowPresenterControl ? 'layers' : 'layers_clear'
                      }}
                    </i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div v-if="data.allowPresenterControl" class="text-sm">
                      Disable presenter control temporarily
                    </div>
                    <div v-else class="text-sm">Allow presenter control</div>
                  </div>
                </template>
              </TooltipModal>
            </div>
          </div>

          <div
            class="flex items-center justify-between bg-grey-200 p-1"
            v-if="isPlaylistVideo"
          >
            <div class="flex flex-row justify-between">
              <TooltipModal triggerType="hover" width="48" textAlign="center">
                <template slot="control">
                  <button
                    @click.prevent="handleVideoPlay"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                  >
                    <i
                      v-if="data.play"
                      class="material-icons-outlined text-icon-sm text-blue-400"
                      >pause_circle_filled</i
                    >

                    <i
                      v-if="!data.play"
                      class="material-icons-outlined text-icon-sm text-grey-400"
                      >play_circle_filled</i
                    >
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div v-if="data.play" class="text-sm">Pause video</div>

                    <div v-if="!data.play" class="text-sm">Play video</div>
                  </div>
                </template>
              </TooltipModal>

              <TooltipModal triggerType="hover" width="48" textAlign="center">
                <template slot="control">
                  <button
                    @click="handleVideoReset"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                  >
                    <i
                      class="material-icons-outlined text-icon-sm text-grey-400"
                      >skip_previous</i
                    >
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div class="text-sm">Reset video to start</div>
                  </div>
                </template>
              </TooltipModal>

              <TooltipModal triggerType="hover" width="48" textAlign="center">
                <template slot="control">
                  <button
                    @click.prevent="handleVideoLoop(!data.loop)"
                    class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                  >
                    <i
                      v-if="data.loop"
                      class="material-icons-outlined text-icon-sm text-blue-400"
                      >repeat_on</i
                    >

                    <i
                      v-if="!data.loop"
                      class="material-icons-outlined text-icon-sm text-grey-400"
                      >repeat</i
                    >
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    <div v-if="data.loop" class="text-sm">Remove loop</div>

                    <div v-if="!data.loop" class="text-sm">Loop video</div>
                  </div>
                </template>
              </TooltipModal>
            </div>
            <div class="time_bar flex flex-col items-end space-y-1 px-1">
              <span
                class="mt-1"
                :class="countdownHighlight"
                @click="toggleCounterDisplay"
                v-if="currentFile.videoLengthMs != null"
                >{{ counterDisplay }}</span
              >

              <RangeSlider
                :max="currentFile.videoLengthMs"
                :min="0"
                :step="100"
                :disabled="currentFile.videoLengthMs == null"
                :progress="videoProgress"
                @updateprogress="videoControlSeek"
              ></RangeSlider>
            </div>
          </div>
        </div>

        <div class="flex flex-1 flex-col rounded-b bg-white p-2">
          <div class="flex min-w-0 items-start items-center justify-between">
            <div class="flex-1 truncate text-sm font-bold">{{ title }}</div>

            <DropdownMenu v-if="data.streamId" ref="dropdown">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.prevent="slotProps.toggle()"
                  class="flex h-6 w-6 items-center justify-center rounded-full p-1 hover:bg-grey-200"
                >
                  <i class="material-icons-outlined text-icon-sm text-grey-400"
                    >more_vert</i
                  >
                </button>
              </template>

              <template slot="menu">
                <DropdownMenu
                  v-if="can('studio-presenter-control') && isPlaylist"
                  ref="subDropdown"
                  top="0%"
                  right="-100%"
                >
                  <template slot="control" slot-scope="slotProps">
                    <button
                      @click.prevent="slotProps.toggle()"
                      class="dropdownListItemWithIcon"
                    >
                      Presenter control
                      <i
                        class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
                        >keyboard_arrow_right</i
                      >
                    </button>
                  </template>

                  <template slot="menu">
                    <div v-if="liveRoomPresenters.length">
                      <div class="border-b-2 border-grey-100 p-2 text-grey-400">
                        Live room
                      </div>
                      <div
                        v-for="presenter in liveRoomPresenters"
                        :key="presenter.pid"
                        class=""
                      >
                        <button
                          @click="assignPlaylistToPresenter(presenter.pid)"
                          class="dropdownListItemWithIcon"
                          :class="{
                            'bg-green-200':
                              data.plCtrlPresenterId == presenter.pid,
                          }"
                        >
                          {{ presenter.name }}
                          <i
                            v-if="data.plCtrlPresenterId == presenter.pid"
                            class="material-icons-outlined text-icon-sm"
                          >
                            check
                          </i>
                        </button>
                      </div>
                    </div>

                    <div v-if="waitingRoomPresenters.length">
                      <div class="border-b-2 border-grey-100 p-2 text-grey-400">
                        Waiting room
                      </div>
                      <div
                        v-for="presenter in waitingRoomPresenters"
                        :key="presenter.pid"
                        class=""
                      >
                        <button
                          @click="assignPlaylistToPresenter(presenter.pid)"
                          class="dropdownListItemWithIcon"
                          :class="{
                            'bg-green-200':
                              data.plCtrlPresenterId == presenter.pid,
                          }"
                        >
                          {{ presenter.name }}
                          <i
                            v-if="data.plCtrlPresenterId == presenter.pid"
                            class="material-icons-outlined text-icon-sm"
                          >
                            check
                          </i>
                        </button>
                      </div>
                    </div>

                    <div v-if="offlinePresenters.length">
                      <div class="border-b-2 border-grey-100 p-2 text-grey-400">
                        Offline
                      </div>
                      <div
                        v-for="presenter in offlinePresenters"
                        :key="presenter.pid"
                      >
                        <button
                          @click="assignPlaylistToPresenter(presenter.pid)"
                          class="dropdownListItemWithIcon"
                          :class="{
                            'bg-green-200':
                              data.plCtrlPresenterId == presenter.pid,
                          }"
                        >
                          {{ presenter.name }}
                          <i
                            v-if="data.plCtrlPresenterId == presenter.pid"
                            class="material-icons-outlined text-icon-sm"
                          >
                            check
                          </i>
                        </button>
                      </div>
                    </div>
                  </template>
                </DropdownMenu>

                <div v-if="isStream || isPlaylist">
                  <button
                    v-if="data.alphaFilter"
                    @click="
                      handleRemoveAlphaFilter();
                      $refs.dropdown.close();
                    "
                    class="dropdownListItem"
                  >
                    Restore background
                  </button>
                  <button
                    v-else
                    @click="
                      $refs.backgroundAlphaColor.show();
                      $refs.dropdown.close();
                    "
                    class="dropdownListItem"
                  >
                    Remove background colour
                  </button>
                </div>

                <DropdownInlineConfirmButton
                  @callback="handleDeleteSource"
                  confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
                >
                  Remove source
                </DropdownInlineConfirmButton>
              </template>
            </DropdownMenu>
          </div>

          <div class="mt-1 flex w-full justify-between text-xs text-grey-400">
            {{ displayType }}
            <TooltipModal triggerType="hover" width="48" textAlign="center">
              <template slot="control">
                <button
                  v-if="isVideo || isStream || isPresenter || isIoClient"
                  @click="toggleMuted"
                  class="float-right cursor-pointer rounded-full px-1 text-grey-400 hover:bg-grey-200"
                  :class="{ 'text-blue-500': !data.muted }"
                >
                  {{ data.muted ? 'Listen off' : 'Listen on' }}
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div class="text-sm">Preview audio for this source</div>
                </div>
              </template>
            </TooltipModal>
          </div>
          <div class="flex flex-1 flex-col">
            <template
              v-if="
                isMediaVideo ||
                isStream ||
                isPresenter ||
                isPlaylistVideo ||
                isIoClient
              "
            >
              <div class="flex flex-grow items-end justify-between py-3">
                <div class="flex w-full">
                  <RangeSlider
                    :max="1"
                    :min="0"
                    :step="0.01"
                    :disabled="
                      !data.streamId || data.sourceMuted ? true : false
                    "
                    :progress="data.volume"
                    @updateprogress="handleVolumeChange"
                  >
                  </RangeSlider>
                </div>

                <TooltipModal
                  v-if="!data.audioLatch"
                  triggerType="hover"
                  width="48"
                  textAlign="center"
                >
                  <template slot="control">
                    <button
                      @click="addAudioLatch"
                      class="flex h-6 w-auto items-center justify-center rounded-full p-1 text-grey-500 hover:bg-grey-200"
                    >
                      <i
                        class="material-icons-outlined text-icon-sm text-grey-400"
                        >lock_open</i
                      >
                    </button>
                  </template>

                  <template slot="content">
                    <div class="flex flex-col space-y-4">
                      <div class="text-sm">
                        <span class="font-bold text-blue-500">Latch</span>
                        source audio On Air. Latched audio is heard On Air at
                        all times.
                      </div>
                    </div>
                  </template>
                </TooltipModal>

                <TooltipModal
                  v-else
                  triggerType="hover"
                  width="48"
                  textAlign="center"
                >
                  <template slot="control">
                    <button
                      @click="removeAudioLatch"
                      class="flex h-6 w-auto items-center justify-center rounded-full p-1 text-grey-500 hover:bg-grey-200"
                    >
                      <i
                        class="material-icons-outlined text-icon-sm text-blue-400"
                        >lock</i
                      >
                    </button>
                  </template>

                  <template slot="content">
                    <div class="flex flex-col space-y-4">
                      <div class="text-sm">
                        <span class="font-bold text-red-500">Remove</span>
                        latched audio
                      </div>
                    </div>
                  </template>
                </TooltipModal>
              </div>

              <div class="flex items-center justify-center">
                <AudioIndicator
                  :stream="stream"
                  class="audio"
                  :indicatorNodes="30"
                  :userGesture="this.userGesture"
                  :audioContext="this.audioContext"
                />
              </div>
            </template>
          </div>
          <div
            class="mt-4 flex items-end justify-between text-xs font-semibold text-grey-300"
          >
            <div
              class="flex-1"
              :class="{ 'text-green-500': isPreviewSelected }"
              cols="6"
            >
              Next
            </div>
            <div
              class="flex-1 text-right"
              :class="{ 'text-blue-500': isProgramSelected }"
              cols="6"
            >
              On Air
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Add chroma key modal -->
    <Modal width="2/6" ref="backgroundAlphaColor">
      <template slot="content">
        <h1 class="pb-10 text-center text-2xl font-bold">Remove background</h1>
        <p>Set the HEX colour code to remove from this source.</p>

        <form @submit.prevent="handleAddAlphaFilter">
          <SelectField
            name="question_type"
            class="my-6"
            label="Select colour"
            v-model="alphaColorSelect"
            :errors="errors"
            :options="[
              {
                id: 'rgb(255,0,0)',
                label: 'Red',
              },
              {
                id: 'rgb(0,255,0)',
                label: 'Green',
              },
              {
                id: 'rgb(0,0,255)',
                label: 'Blue',
              },
              {
                id: 'rgb(255,0,255)',
                label: 'Magenta',
              },
              {
                id: 'custom',
                label: 'Custom',
              },
            ]"
          />

          <div v-if="alphaColorSelect == 'custom'">
            <div
              class="rounded-input my-6 flex border border-grey-200 bg-white p-3"
            >
              <div
                class="mr-2 w-20 rounded-full border border-grey-200 px-4 text-center"
              >
                Red
              </div>
              <input
                name="chroma_background_color_r"
                v-model="alphaColorCustom.r"
                :required="true"
                :errors="errors"
                placeholder="Enter red value (0-255)"
                autofocus="true"
                class="flex-1 text-center"
                maxlength="3"
                type="text"
              />
            </div>
            <div
              class="rounded-input my-6 flex border border-grey-200 bg-white p-3"
            >
              <div
                class="mr-2 w-20 rounded-full border border-grey-200 px-4 text-center"
              >
                Green
              </div>
              <input
                name="chroma_background_color_g"
                v-model="alphaColorCustom.g"
                :required="true"
                :errors="errors"
                placeholder="Enter green value (0-255)"
                class="flex-1 text-center"
                maxlength="3"
                type="text"
              />
            </div>
            <div
              class="rounded-input my-6 flex border border-grey-200 bg-white p-3"
            >
              <div
                class="mr-2 w-20 rounded-full border border-grey-200 px-4 text-center"
              >
                Blue
              </div>
              <input
                name="chroma_background_color_b"
                v-model="alphaColorCustom.b"
                :required="true"
                :errors="errors"
                placeholder="Enter blue value (0-255)"
                class="flex-1 text-center"
                maxlength="3"
                type="text"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <button type="submit" class="btn-blue">Remove background</button>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';
import DropdownMenu from '@/components/DropdownMenu';
import Modal from '@/components/Modal';
import VideoPlayer from '@/components/VideoPlayer';
import RangeSlider from '@/components/RangeSlider';
import AudioIndicator from '@/components/AudioIndicator';
import TooltipModal from '@/components/TooltipModal';
import SelectField from '@/components/SelectField';
import errors from '@/mixins/errors';
import api from '@/services/api';

export default {
  name: 'MixerVideoSource',
  mixins: [errors],
  components: {
    DropdownInlineConfirmButton,
    DropdownMenu,
    Modal,
    VideoPlayer,
    RangeSlider,
    AudioIndicator,
    TooltipModal,
    SelectField,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: String,
      required: false,
    },
    janus: { type: Object },
    janusPublishers: { type: Object, required: true },
  },
  data: () => ({
    isLoaded: false,
    menu: false,
    handle: null,
    stream: null,
    isVolumeChange: false,
    tempStream: null,
    myVideoProgressInterval: null,
    isCountDownDisplay: false,
    isConnected: false,
    reconnectProcessTimeout: null,
    reconnectProcessThreshold: 10000,
    reconnectProcessLimit: 6,
    isPrevPositionProcessing: false,
    isNextPositionProcessing: false,
    alphaColorSelect: 'rgb(0,255,0)',
    alphaColorCustom: {
      r: null,
      g: null,
      b: null,
    },
    playlistPosition: null,
    janusNullCheckInterval: null,
    // Video progress should be a computed property. Instead of storing a
    // computed property in data, store these values to compute the video
    // progress
    now: Date.now(),
    nowUpdateInterval: null,
    lastProgress: null,
    lastProgressTime: null,
    requestingTracking: null,
    requestTrackingBackoff: 1000,
    requestingSeek: null,
    destroying: false,
  }),
  computed: {
    ...mapState([
      'event',
      'trackSlots',
      'isLayoutBuildingMode',
      'overlayId',
      'files',
      'sources',
      'srtEndpoints',
      'htmlSources',
      'presenters',
      'ioClients',
      'playlists',
      'audioContext',
      'userGesture',
    ]),
    title() {
      switch (this.data.type) {
        case 'Media':
          var file = this.files.find((f) => f.fid == this.data.mediaId);
          return file.title;
        case 'SRT':
          var srt = this.srtEndpoints.find((s) => s.id == this.data.srtId);
          return srt.name;
        case 'HTML':
          var html = this.htmlSources.find((h) => h.id == this.data.htmlId);
          return html.name;
        case 'Presenter':
          var presenter = this.presenters.find(
            (p) => p.pid == this.data.presenterId
          );
          return presenter.name;
        case 'IoClient':
          var ioClient = this.ioClients.find(
            (i) => i.id == this.data.ioClientId
          );
          return ioClient.name;
        case 'Playlist':
          var playlist = this.playlists.find(
            (p) => p.id == this.data.playlistId
          );
          return playlist.name;
        default:
          return this.data.title;
      }
    },
    type() {
      let cls = '';
      switch (this.data.type) {
        case 'Presenter':
          cls = 'blue';
          break;
        case 'Media':
          cls = 'grey';
          break;
        default:
          cls = 'grey';
          break;
      }

      return cls;
    },
    isPreviewSelected() {
      // in preview mixer
      // or latched audio
      // or overlay
      return (
        Object.values(this.trackSlots.preview).some((track) => {
          return track.sourceId == this.data.sid;
        }) ||
        this.data.audioLatch ||
        this.overlayId == this.data.sid
      );
    },
    isProgramSelected() {
      // in program mixer
      // or latched audio
      // or overlay
      return (
        Object.values(this.trackSlots.program).some((track) => {
          return track.sourceId == this.data.sid;
        }) ||
        this.data.audioLatch ||
        this.overlayId == this.data.sid
      );
    },
    isMediaVideo() {
      return (
        this.data.type === 'Media' &&
        this.currentFile &&
        this.currentFile.type === 'video'
      );
    },
    isVideo() {
      return this.isMediaVideo || this.isPlaylistVideo;
    },
    isImage() {
      return (
        this.data.type === 'Media' &&
        this.currentFile &&
        this.currentFile.type === 'image'
      );
    },
    isPresenter() {
      return this.data.type == 'Presenter';
    },
    isIoClient() {
      return this.data.type == 'IoClient';
    },
    isStream() {
      return this.data.type == 'SRT';
    },
    isHtml() {
      return this.data.type == 'HTML';
    },
    isPlaylist() {
      return this.data.type == 'Playlist';
    },
    isPlaylistVideo() {
      if (!this.data.type == 'Playlist') return false;

      const playlist = this.playlists.find((p) => p.id == this.data.playlistId);
      if (!playlist) return false;

      const file = this.files.find(
        (f) => f.fid == playlist.list[this.data.playlistPosition]
      );
      if (!file) return false;

      return file.type === 'video';
    },
    currentFile() {
      if (this.data.type == 'Playlist') {
        const playlist = this.playlists.find(
          (p) => p.id == this.data.playlistId
        );
        return this.files.find(
          (f) => f.fid == playlist.list[this.data.playlistPosition]
        );
      } else {
        return this.files.find((f) => f.fid == this.data.mediaId);
      }
    },
    displayType() {
      switch (this.data.type) {
        case 'Media':
          return this.isMediaVideo ? 'Video' : 'Image';
        case 'SRT':
          return 'Stream';
        case 'HTML':
          return 'Web';
        case 'IoClient':
          return 'Router Input';
        case 'Playlist':
          var playlist = this.playlists.find(
            (p) => p.id == this.data.playlistId
          );
          return playlist.type == 'fixed' ? 'Presentation' : 'Playlist';
        default:
          return this.data.type;
      }
    },
    countdownHighlight() {
      if (!this.isVideo) return;

      const t =
        Math.floor(this.currentFile.videoLengthMs - this.videoProgress) / 1000;
      if (t <= 10) {
        return 'text-red-700';
      } else if (t <= 30) {
        return 'text-orange-500';
      } else {
        return 'text-grey-500';
      }
    },
    attentionHighlight() {
      if (
        this.isVideo == false ||
        (this.data.loop && this.data.loop == true) ||
        this.isProgramSelected == false ||
        this.data.play == false
      )
        return;

      const t =
        Math.floor(this.currentFile.videoLengthMs - this.videoProgress) / 1000;
      if (t <= 10) {
        return true;
      }

      return false;
    },
    counterDisplay() {
      if (this.isCountDownDisplay) {
        return `${this.timeFormat(
          this.videoProgress / 1000
        )} / ${this.timeFormat(this.currentFile.videoLengthMs / 1000)}`;
      } else {
        const diff = this.currentFile.videoLengthMs - this.videoProgress;

        return `${this.timeFormat(
          this.videoProgress / 1000
        )} / -${this.timeFormat(diff / 1000)}`;
      }
    },
    isSingleProgramVideo() {
      //block for now
      //TODO
      //sync single video time tick
      return false;

      /*
            //oid = 1 is on air stream
            if(!this.data.oid || this.data.oid != 1) return;

            var list = Object.values(this.trackSlots.program).filter(track => {
                var source = this.sources.find(source => source.sid == track.sourceId);

                if(source && source.type == 'Media' && !source.loop) {
                    var file = this.files.find(media => media.fid == source.mediaId);
                    if(file && file.type == 'video') {
                        return true;
                    }
                }

                return false;
            });

            return list.length == 1;
            */
    },
    playlistTotal() {
      var playlist = this.playlists.find((p) => p.id == this.data.playlistId);
      if (playlist) {
        return playlist.list.length;
      } else {
        return 0;
      }
    },
    liveRoomPresenters() {
      // promoted. either online or offline
      const presenters = this.presenters
        .filter((presenter) =>
          this.sources.find((src) => presenter.pid == src.presenterId)
        )
        .map((p) =>
          Object.assign({}, p, { isOnline: !!this.janusPublishers[p.pid] })
        );

      return presenters.sort((a, b) => {
        if (a.isOnline != b.isOnline) {
          return +a.isOnline < +b.isOnline ? 1 : -1;
        }
        return a.name.localeCompare(b.name);
      });
    },
    waitingRoomPresenters() {
      // online and not promoted
      const presenters = this.presenters.filter((presenter) => {
        // Online
        if (!this.janusPublishers[presenter.pid]) {
          return false;
        }
        // Not promoted
        return !this.sources.find((src) => presenter.pid == src.presenterId);
      });
      return presenters.sort((a, b) => a.name.localeCompare(b.name));
    },
    offlinePresenters() {
      // offline and not promoted
      const presenters = this.presenters.filter((presenter) => {
        // Offline
        if (this.janusPublishers[presenter.pid]) {
          return false;
        }
        // Not promoted
        return !this.sources.find((src) => presenter.pid == src.presenterId);
      });
      return presenters.sort((a, b) => a.name.localeCompare(b.name));
    },
    videoProgress() {
      if (this.lastProgress == null || this.lastProgressTime == null) {
        return 0;
      }

      if (!this.data.play) {
        return this.lastProgress;
      }

      // This is required to make Vue recalculate videoProgress when this.now changes
      // Deleting this will stop the video progress from updating
      this.now;

      const loopLatency = 400;
      const currentProgress =
        Date.now() - this.lastProgressTime + this.lastProgress;
      if (currentProgress > this.currentFile.videoLengthMs) {
        if (this.data.loop) {
          this.getMediaTrackingData();
          // Estimate play time after loop until new progress is queried from coreGst
          return Math.max(
            0,
            (currentProgress % this.currentFile.videoLengthMs) - loopLatency
          );
        } else {
          return this.currentFile.videoLengthMs;
        }
      } else {
        return currentProgress;
      }
    },
  },
  mounted() {
    if (this.data.streamId != null) {
      this.janusNullCheckInterval = setInterval(async () => {
        if (this.janus != null) {
          clearInterval(this.janusNullCheckInterval);
          await this.janusWatch();
        }
      }, 200);
    }

    this.playlistPosition = this.data.playlistPosition + 1;
    if (this.isVideo) {
      this.getMediaTrackingData();
    }
  },
  beforeDestroy() {
    clearInterval(this.janusNullCheckInterval);
    clearInterval(this.nowUpdateInterval);
    this.destroying = true;
    if (this.janus && !this.janus._destroying && this.handle) {
      this.handle.detach();
    }
  },
  watch: {
    'data.streamId': async function (newId) {
      if (this.stream != null) {
        this.stream = null;
      }

      if (newId != null) {
        await this.janusWatch();
      }
    },
    'data.play': function (newVal, oldVal) {
      if (!this.isVideo) {
        clearInterval(this.nowUpdateInterval);
        return;
      }

      if (newVal && !oldVal) {
        // Video resumes playing, start video progress at where we last paused
        this.lastProgressTime = Date.now();
      } else if (oldVal && !newVal) {
        this.lastProgress += Date.now() - this.lastProgressTime;
        this.lastProgressTime = Date.now();
      }
      if (newVal) {
        this.videoProgressTick();
      } else {
        clearInterval(this.nowUpdateInterval);
      }
      this.getMediaTrackingData();
    },
    'data.playlistPosition': function (val) {
      this.isPrevPositionProcessing = false;
      this.isNextPositionProcessing = false;

      this.playlistPosition = val + 1;

      //move to next position.
      //reset video control
      clearInterval(this.nowUpdateInterval);
      this.lastProgress = 0;
      this.lastProgressTime = Date.now();
      this.$store.commit('SET_SOURCE_STATE', {
        sid: this.data.sid,
        key: 'play',
        value: false,
      });
      this.$store.commit('SET_SOURCE_STATE', {
        sid: this.data.sid,
        key: 'loop',
        value: false,
      });
    },
  },
  methods: {
    videoProgressTick() {
      if (this.isVideo && this.data.play) {
        clearInterval(this.nowUpdateInterval);
        this.nowUpdateInterval = setInterval(() => {
          this.now = Date.now();
        }, 100);
      }
    },
    getMediaTrackingData() {
      if (this.requestingTracking) {
        this.requestingTracking.pending = true;
        return;
      }
      this.requestingTracking = { pending: false };
      const url = `/mixer/${this.event.jobId}/mediaTracking/${this.data.sid}`;
      const start = Date.now();
      api
        .get(url)
        .then((res) => {
          if (this.destroying) return;
          this.requestTrackingBackoff = 1000;
          if (res.data.currentTime != null) {
            if (res.data.playState === 'play') {
              let rtt = Date.now() - start;
              // Estimate network latency as RTT/2
              // If RTT > 1s, assume server congestion and cap RTT to 1s
              rtt = Math.min(1000, rtt);
              this.lastProgress = Math.max(0, res.data.currentTime - rtt / 2);
            } else {
              this.lastProgress = res.data.currentTime;
            }
            this.lastProgressTime = Date.now();
          }
          this.$store.commit('SET_SOURCE_STATE', {
            sid: this.data.sid,
            key: 'play',
            value: res.data.playState == 'play',
          });
          const pending = this.requestingTracking.pending;
          this.requestingTracking = null;
          if (pending) {
            this.getMediaTrackingData();
          }
        })
        .catch((e) => {
          if (this.destroying) return;
          console.error(e);
          setTimeout(() => {
            if (this.destroying) return;
            this.requestingTracking = null;
            this.getMediaTrackingData();
          }, this.requestTrackingBackoff);
          this.requestTrackingBackoff = Math.min(
            this.requestTrackingBackoff * 2,
            60000
          );
        });
    },
    handleClick(source) {
      if (this.isLayoutBuildingMode) {
        this.$store.commit('SELECT_SOURCE', source);
      } else {
        var payload = [
          {
            key: 1,
            slotNo: 1,
            sourceId: source.sid,
            width: this.event.windowWidth,
            height: this.event.windowHeight,
            x: 0,
            y: 0,
            opacity: 1.0,
          },
        ];

        this.$store.dispatch('SEND_TO_PREVIEW', payload);
      }
    },
    async janusWatch() {
      this.isLoaded = false;

      if (this.handle) {
        this.handle.detach();
        this.handle = null;
      }

      this.handle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.streaming',
          success: resolve,
          error: console.error.bind(console),
          iceState: (state) => {
            //console.log(':::ICE STATE:::', state);
            if (state == 'disconnected' && this.janus != null) {
              this.isConnected = false;
              //this.reconnectProcess();
            }
          },
          onmessage: (msg, jsep) => {
            if (jsep) {
              this.handle.createAnswer({
                media: {
                  audioSend: false,
                  videoSend: false,
                  audioRecv: true,
                  videoRecv: true,
                },
                success: (ansJsep) => {
                  this.handle.send({
                    message: { request: 'start' },
                    jsep: ansJsep,
                  });
                },
                error: console.error.bind(console),
                jsep,
              });
            }
          },
          onremotestream: (stream) => {
            this.isLoaded = true;
            this.isConnected = true;

            if (this.stream !== stream) {
              this.stream = stream;
            }
          },
        });
      });

      this.handle.send({
        message: {
          request: 'watch',
          id: this.data.streamId,
          pin: this.event.janusPin,
        },
      });
    },
    reconnectProcess() {
      if (this.reconnectProcessTimeout != null) {
        clearTimeout(this.reconnectProcessTimeout);
      }

      if (this.reconnectProcessLimit == 0 && this.isConnected == false) {
        //this.isRestartProcessing = false;
        clearTimeout(this.reconnectProcessTimeout);
        return;
      }

      if (this.isConnected == true) {
        //this.isRestartProcessing = false;
        this.reconnectProcessThreshold = 10000;
        this.reconnectProcessLimit = 6;
        clearTimeout(this.reconnectProcessTimeout);
        return;
      }

      console.log(`Retry to connect [${this.reconnectProcessLimit}]...`);

      this.reconnect();

      this.reconnectProcessTimeout = setTimeout(() => {
        this.reconnectProcessLimit--;
        this.reconnectProcessThreshold += 10000;
        this.reconnectProcess();
      }, this.reconnectProcessThreshold);
    },
    reconnect() {
      console.log(':::RECONNECT:::');
      this.handle.createOffer({
        iceRestart: true,
        media: {
          audioSend: false,
          videoSend: false,
          audioRecv: true,
          videoRecv: true,
        },
        success: (jsep) => {
          this.handle.send({
            message: {
              request: 'watch',
              id: this.data.streamId,
              pin: this.event.janusPin,
              restart: true,
            },
            jsep: jsep,
          });
        },
      });
    },
    toggleMuted() {
      const id = this.data.oid != null ? this.data.oid : this.data.sid;
      this.$emit('set-ui-muted', id, !this.data.muted);
    },
    addAudioLatch() {
      this.$store.dispatch('ADD_AUDIO_LATCH', {
        id: this.data.sid,
        audioLatch: true,
      });
    },
    removeAudioLatch() {
      this.$store.dispatch('REMOVE_AUDIO_LATCH', {
        id: this.data.sid,
        audioLatch: false,
      });
    },
    handleClickOverlay() {
      this.$store.dispatch('TOGGLE_OVERLAY', {
        id: this.data.sid,
      });
    },
    handleDeleteSource() {
      //force to put down presenter
      if (this.data.type == 'Presenter') {
        this.$store.dispatch('REMOVE_PRESENTER_SOURCE', {
          pid: this.data.presenterId,
        });
      } else {
        this.$store.dispatch('REMOVE_SOURCE', { id: this.data.sid });
      }
    },
    toggleSourceAudioInput() {
      this.$store.commit('TOGGLE_SOURCE_AUDIO_INPUT', {
        sid: this.data.sid,
      });
    },
    handleVideoPlay() {
      const newPlay = !this.data.play;
      if (newPlay) {
        this.videoControlPlay();
      } else {
        this.videoControlPause();
      }
    },
    handleVolumeChange(val) {
      this.$store.commit('ADJUST_SOURCE_AUDIO', {
        ...this.data,
        volume: val,
      });
    },
    async handleVideoReset() {
      // Possible race condition in coreGst: Making both pause/seek
      // requests in parallel sometimes results in the video paused
      // without being reset to 0
      await this.videoControlPause(), await this.videoControlSeek(0);
    },
    async handleVideoLoop(loop) {
      await this.videoControlLoop(loop);

      // Restart video if enabling loop while the video has ended
      // Can't use this.videoProgress here since it wraps on loop
      const progress = Date.now() - this.lastProgressTime + this.lastProgress;
      if (loop && this.currentFile.videoLengthMs - progress < 200) {
        this.videoControlSeek(0);
      }
    },
    handleAddAlphaFilter() {
      var rgb = null;
      if (this.alphaColorSelect == 'custom') {
        rgb =
          'rgb(' +
          this.alphaColorCustom.r +
          ',' +
          this.alphaColorCustom.g +
          ',' +
          this.alphaColorCustom.b +
          ')';
      } else {
        rgb = this.alphaColorSelect;
      }

      this.$store.dispatch('ADD_ALPHA_FILTER', {
        id: this.data.sid,
        alphaFilter: true,
        rgb: rgb,
      });
      this.$refs.backgroundAlphaColor.close();
    },
    handleRemoveAlphaFilter() {
      this.$store.dispatch('REMOVE_ALPHA_FILTER', {
        id: this.data.sid,
        alphaFilter: false,
      });
    },
    handlePlaylistPrevious() {
      this.isPrevPositionProcessing = true;
      this.$store.dispatch('SET_PLAYLIST_PREVIOUS', {
        sid: this.data.sid,
        currentPlaylistPosition: this.data.playlistPosition,
      });
    },
    handlePlaylistNext() {
      this.isNextPositionProcessing = true;
      this.$store.dispatch('SET_PLAYLIST_NEXT', {
        sid: this.data.sid,
        currentPlaylistPosition: this.data.playlistPosition,
      });
    },
    jumpToSlide() {
      this.$store.dispatch('SET_PLAYLIST_POSITION', {
        sid: this.data.sid,
        currentPlaylistPosition: this.playlistPosition - 1,
      });
      this.$refs.jumpPosition.close();
    },
    timeFormat(time) {
      //mm:ss
      return (
        Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).substr(-2)
      );
    },
    toggleCounterDisplay() {
      this.isCountDownDisplay = !this.isCountDownDisplay;
    },
    assignPlaylistToPresenter(pid) {
      this.$refs.dropdown.close();
      this.$refs.subDropdown.close();
      const presenterId = this.data.plCtrlPresenterId == pid ? null : pid;
      const data = {
        sourceId: this.data.sid,
        presenterId: presenterId,
      };

      this.$store.dispatch('ASSIGN_PLAYLIST_TO_PRESENTER', data);
    },
    handleJumpPositionOpen() {
      this.$refs.jumpPosition.open();

      this.$nextTick(() => {
        this.$refs.playlistPositionInput.focus();
      });
    },
    togglePlaylistPresenterControl() {
      this.$store.dispatch('TOGGLE_PLAYLIST_PRESENTER_CONTROL', this.data.sid);
    },
    async videoControlPlay() {
      const url = `/mixer/${this.event.jobId}/mediaPlay`;
      const res = await api.post(url, { id: this.data.sid });
      if (res.status >= 200 && res.status < 300) {
        this.$store.commit('SET_SOURCE_STATE', {
          sid: this.data.sid,
          key: 'play',
          value: true,
        });
      } else {
        this.$store.commit('ERROR', `Video play failed.`);
      }
    },
    async videoControlPause() {
      const url = `/mixer/${this.event.jobId}/mediaPause`;
      const res = await api.post(url, { id: this.data.sid });
      if (res.status >= 200 && res.status < 300) {
        this.$store.commit('SET_SOURCE_STATE', {
          sid: this.data.sid,
          key: 'play',
          value: false,
        });
      } else {
        this.$store.commit('ERROR', `Video pause failed.`);
      }
    },
    async videoControlSeek(seek_ms) {
      this.lastProgress = seek_ms;
      this.lastProgressTime = Date.now();
      if (this.requestingSeek) {
        this.requestingSeek.pending = true;
        this.requestingSeek.seek_ms = seek_ms;
        return;
      }
      this.requestingSeek = { pending: false };
      const url = `/mixer/${this.event.jobId}/mediaSeek`;
      const res = await api.post(url, { id: this.data.sid, seek_ms });
      if (res.status != 200) {
        this.$store.commit('ERROR', `Video seek failed.`);
      }
      const { pending, seek_ms: newSeekMs } = this.requestingSeek;
      this.requestingSeek = null;
      if (pending) {
        this.videoControlSeek(newSeekMs);
      } else {
        // Refreshing the current position while dragging the video
        // time slider causes the slider value to jump. Only refresh
        // after dragging is complete
        this.getMediaTrackingData();
      }
    },
    async videoControlLoop(loop) {
      const url = `/mixer/${this.event.jobId}/editManagedSource`;
      const res = await api.post(url, { id: this.data.sid, loop });
      if (res.status >= 200 && res.status < 300) {
        this.$store.commit('UPDATE_SOURCE', { id: this.data.sid, loop });
      } else {
        this.$store.commit('ERROR', 'Loop set failed');
      }
    },
  },
};
</script>
<style scoped>
.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.time_bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}
.time_bar span {
  font-size: 60%;
  position: absolute;
  width: 85%;
  top: -8px;
  cursor: pointer;
}

.controls {
  background: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}
.controls button {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  align-items: center;
}
</style>
