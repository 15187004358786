<template>
  <div class="relative mb-6">
    <select
      class="select-css"
      :class="{
        'is-invalid rounded-b-none': errors.has(name),
        'opacity-50': !value,
        'cursor-not-allowed': disabled,
      }"
      :id="name"
      :name="name"
      autocomplete="off"
      :value="value"
      :autofocus="autofocus"
      :required="required"
      :disabled="disabled"
      @input="handleInput"
    >
      <option
        v-for="option in allOptions"
        :key="option.id"
        :disabled="option.id === null"
        :value="option.id"
      >
        {{ option.label }}
      </option>
    </select>

    <ValidationErrors :errors="errors" :name="name" />
  </div>
</template>

<script>
import ValidationErrors from '@/components/ValidationErrors';

export default {
  name: 'SelectField',

  components: {
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    value: {},
    label: { type: String },
    autofocus: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    allOptions() {
      const options = Array.from(this.options);

      options.unshift({
        id: null,
        label: this.label,
      });

      return options;
    },
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.currentTarget.value);
    },
  },
};
</script>
