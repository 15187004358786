<template>
  <div class="flex w-full flex-grow flex-col">
    <div v-if="!can('event-qa')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="flex h-full flex-col break-words">
          <!-- search filter -->
          <div class="my-3 flex items-center justify-between space-x-2">
            <SelectFilter
              v-if="!isSearch"
              class="flex-1"
              v-model="filter"
              :options="qaFilterOptions"
            />

            <input
              v-if="isSearch"
              type="text"
              v-model="search"
              placeholder="Search questions..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button @click.prevent="isSearch = !isSearch">
              <i
                v-if="!isSearch"
                class="material-icons-outlined flex justify-center"
                >search</i
              >
              <i
                v-if="isSearch"
                class="material-icons-outlined flex justify-center"
                >sort</i
              >
            </button>

            <RouterLink
              :to="'/events/' + event.eventShortcode + '/output/qa'"
              target="_blank"
              class="flex items-center text-xs text-blue-500 hover:text-blue-800"
            >
              <i
                class="material-icons-outlined flex justify-center pr-1 text-sm text-blue-500"
                >dvr</i
              >Open display
            </RouterLink>
          </div>

          <div
            v-if="!filteredQuestions.length"
            class="py-4 text-center text-grey-400"
          >
            No questions to display
          </div>

          <!-- question list -->
          <div
            v-else
            ref="scroll"
            @scroll="onScroll"
            class="flex flex-grow flex-col overflow-auto"
          >
            <div
              v-for="q in filteredQuestions"
              :key="q.id"
              class="border-b border-grey-100 px-1 py-2 hover:bg-grey-100"
            >
              <div class="flex flex-row items-center">
                <div
                  class="flex-1 truncate"
                  :class="{
                    'text-grey-400': q.approvalState != APPROVED,
                    'line-through': q.approvalState == REJECTED,
                  }"
                >
                  {{ formatName(q) }}
                </div>
                <div
                  v-if="event.config.qaAttendeeUpvoting"
                  class="flex pr-4 text-grey-400"
                >
                  {{ q.netVoteCount
                  }}<i class="material-icons-outlined pl-2 text-grey-400"
                    >thumb_up</i
                  >
                </div>
                <div class="text-grey-400">
                  {{ formatDate(q.createdAt) }}
                </div>
                <DropdownMenu>
                  <template slot="control" slot-scope="slotProps">
                    <button
                      @click.stop="slotProps.toggle()"
                      class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                    >
                      <i class="material-icons-outlined">more_vert</i>
                    </button>
                  </template>

                  <template slot="menu">
                    <button
                      @click.prevent="navigateToReply(q)"
                      class="dropdownListItem"
                    >
                      Post reply
                    </button>
                    <button
                      @click.prevent="pushToOutput(q)"
                      class="dropdownListItem"
                    >
                      {{ q.isOnScreen ? 'Remove from ' : 'Display on ' }}
                      output screen
                    </button>
                    <button
                      v-if="q.forPresenterId"
                      @click.prevent="assignToPresenter(q)"
                      class="dropdownListItem"
                    >
                      Remove presenter assignment
                    </button>
                    <div class="border-b border-grey-100"></div>
                    <button
                      v-for="p in presenters"
                      :key="p.id"
                      @click.prevent="assignToPresenter(q, p)"
                      class="dropdownListItem"
                    >
                      Assign to @{{ p.name }}
                    </button>
                  </template>
                </DropdownMenu>
              </div>
              <div
                class="whitespace-pre-line py-1 lg:py-2"
                :class="{
                  'text-grey-500':
                    q.approvalState != APPROVED || q.approvalState != ANSWERED,
                  'line-through': q.approvalState == REJECTED,
                }"
              >
                <span v-if="q.forPresenterId">@{{ q.presenterName }}: </span>
                {{ q.content }}
              </div>
              <div v-for="r in q.replies" :key="r.id">
                <div
                  class="ml-10 mt-2 text-grey-400"
                  :class="{
                    'text-grey-200': q.approvalState == REJECTED,
                  }"
                >
                  <div class="flex justify-between">
                    <div
                      :class="{
                        'line-through': q.approvalState == REJECTED,
                      }"
                    >
                      {{ formatReplyName(r) }}
                    </div>
                    <div>{{ formatDate(r.createdAt) }}</div>
                  </div>

                  <div class="flex justify-between">
                    <div
                      :class="{
                        'line-through': q.approvalState == REJECTED,
                      }"
                      class="whitespace-pre-line"
                    >
                      {{ r.content }}
                    </div>

                    <TooltipModal
                      triggerType="hover"
                      width="48"
                      textAlign="center"
                    >
                      <template slot="control">
                        <i
                          @click="removeAnswer(q.id, r.id)"
                          class="material-icons-outlined mx-1 mt-1 cursor-pointer text-icon-sm text-grey-300 hover:text-red-700"
                        >
                          close
                        </i>
                      </template>

                      <template slot="content">
                        <div class="flex flex-col space-y-4 text-left">
                          <div class="text-sm">Delete this reply message</div>
                        </div>
                      </template>
                    </TooltipModal>
                  </div>
                </div>
              </div>

              <div class="flex flex-row pt-1">
                <button
                  v-if="q.approvalState != ANSWERED"
                  @click.prevent="approve(q.id)"
                  class="pr-4 text-grey-400 hover:text-green-800"
                  :class="{
                    'text-green-500': q.approvalState == APPROVED,
                  }"
                >
                  {{ q.approvalState == APPROVED ? 'Approved' : 'Approve' }}
                </button>
                <button
                  v-if="q.approvalState != REJECTED"
                  @click.prevent="answer(q.id)"
                  class="pr-4 text-grey-400 hover:text-blue-800"
                  :class="{
                    'text-blue-500': q.approvalState == ANSWERED,
                  }"
                >
                  {{
                    q.approvalState == ANSWERED ? 'Answered' : 'Set Answered'
                  }}
                </button>
                <button
                  v-if="q.approvalState != ANSWERED"
                  @click.prevent="reject(q.id)"
                  class="pr-4 text-grey-400 hover:text-red-700"
                  :class="{
                    'text-red-500': q.approvalState == REJECTED,
                  }"
                >
                  {{ q.approvalState == REJECTED ? 'Removed' : 'Remove' }}
                </button>
                <div class="flex flex-grow justify-end pr-2">
                  <TooltipModal
                    triggerType="hover"
                    width="48"
                    textAlign="center"
                  >
                    <template slot="control">
                      <button
                        v-if="
                          [APPROVED, ANSWERED].includes(q.approvalState) &&
                          can('studio-output-qa')
                        "
                        @click.prevent="pushToOutput(q)"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i
                          v-if="q.isOnScreen"
                          class="material-icons-outlined text-blue-500"
                        >
                          dvr
                        </i>
                        <i v-else class="material-icons-outlined"> tv </i>
                      </button>
                    </template>

                    <template slot="content">
                      <div v-if="q.isOnScreen" class="text-sm">
                        Remove this message from the Output screen.
                      </div>
                      <div v-else class="text-sm">
                        Display this message on the Output screen.
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
            </div>
          </div>

          <button
            @click="handleClickNewMessages"
            v-if="isNewMessages"
            class="absolute bottom-5 flex items-center self-center rounded-full bg-black bg-opacity-50 px-3 py-1 text-sm text-white"
          >
            New questions
            <i class="material-icons-outlined brounce text-white"
              >keyboard_arrow_down</i
            >
          </button>
        </div>
      </template>
      <!-- end of main -->

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="py-6">
          <button
            class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3 class="-ml-10 w-full text-center text-2xl font-bold">
            Post reply
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add reply -->
        <template v-if="section == 'reply'">
          <div class="text-center text-grey-400">
            {{ formatName(this.selectedQuestion) }}:
          </div>
          <div class="pb-4 text-center">
            {{ this.selectedQuestion.content }}
          </div>
          <form class="flex flex-col px-3 py-2" @submit.prevent="reply">
            <TextField
              name="new_reply"
              label="Reply message"
              v-model="replyContent"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" class="btn-blue">Post publicly</button>
          </form>
        </template>
        <!-- end of add reply -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

import SelectFilter from '@/components/SelectFilter';
import TextField from '@/components/TextField';
import DropdownMenu from '@/components/DropdownMenu';
import errors from '@/mixins/errors';
import { some, sortBy } from 'lodash';
import { PENDING, APPROVED, REJECTED, ANSWERED } from '@/helper/moderation';
import TooltipModal from '@/components/TooltipModal';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  name: 'StudioPanelQA',
  mixins: [errors],
  components: {
    SelectFilter,
    TextField,
    TooltipModal,
    UpgradePlanLink,
    DropdownMenu,
  },
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
    },
  },
  data: () => ({
    PENDING,
    APPROVED,
    REJECTED,
    ANSWERED,

    section: null,
    search: null,
    isSearch: false,
    filter: 'all',
    replyContent: '',
    selectedQuestion: null,
    newMessagesCount: 0,
    isNewMessages: false,
    autoScroll: true,
    scrollTimeout: null,
  }),
  beforeDestroy() {
    clearTimeout(this.scrollTimeout);
  },
  computed: {
    ...mapState(['qaQuestions', 'event', 'presenters']),

    qaFilterOptions() {
      const options = [
        { id: 'all', label: 'All' },
        { id: 'pending', label: 'Pending' },
        { id: 'approved', label: 'Approved' },
        { id: 'rejected', label: 'Removed' },
        { id: 'answered', label: 'Answered' },
      ];

      if (this.event.config.qaAttendeeUpvoting) {
        options.splice(3, 0, { id: 'popular', label: 'Popular' });
      }

      const forPresenterIds = new Set(
        this.qaQuestions.map((q) => q.forPresenterId)
      );
      const presenters = this.presenters.filter((p) =>
        forPresenterIds.has(p.pid)
      );
      const presenterOptions = presenters
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((p) => ({
          id: `presenter_${p.pid}`,
          label: `@${p.name}`,
          forPresenterId: p.pid,
        }));

      return [...options, ...presenterOptions];
    },

    filteredQuestions() {
      let res = this.qaQuestionsWithPresenterNames;
      let order = ['createdAt', 'id'];

      if (this.filter == 'pending') {
        res = res.filter((q) => q.approvalState == PENDING);
      } else if (this.filter == 'rejected') {
        res = res.filter((q) => q.approvalState == REJECTED);
      } else if (this.filter == 'approved') {
        res = res.filter((q) => q.approvalState == APPROVED);
        order = ['approvedAt', 'id'];
      } else if (this.filter == 'answered') {
        res = res.filter((q) => q.approvalState == ANSWERED);
      } else if (this.filter == 'popular') {
        res = res.filter((q) => q.approvalState == APPROVED);
        order = [(q) => -q.netVoteCount, 'approvedAt', 'id'];
      } else if (this.filter.startsWith('presenter_')) {
        const forPresenterId = this.filter.slice('presenter_'.length);
        res = res.filter((q) => q.forPresenterId == forPresenterId);
      }

      if (this.search) {
        const s = this.search.toLowerCase();
        res = res.filter((q) =>
          some(
            [this.formatName(q), q.content],
            (text) => text.toLowerCase().indexOf(s) > -1
          )
        );
      }

      return sortBy(res, ...order);
    },
    qaQuestionsWithPresenterNames() {
      return this.qaQuestions.map((q) => {
        const presenter = this.presenters.find(
          (p) => p.pid === q.forPresenterId
        );
        const name = presenter ? presenter.name : null;
        return { ...q, presenterName: name };
      });
    },
  },
  watch: {
    isActive: function (val) {
      if (val == true) {
        this.section = null;
      }
    },
    isSearch: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
    isFocus: function (newValue, oldValue) {
      if (oldValue == false && newValue == true && this.$refs.scroll) {
        this.newMessagesCount = 0;

        this.$emit('newMessagesNotification', this.newMessagesCount);

        this.autoScroll = true;
        this.scrollToBottom();
      }
    },
    qaQuestions: function (newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length > oldVal.length) {
        //count new incoming messages when not focus
        if (!this.isFocus) {
          this.newMessagesCount += newVal.length - oldVal.length;
          this.$emit('newMessagesNotification', this.newMessagesCount);
        } else {
          this.scrollToBottom();
          if (
            this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight >
            this.$refs.scroll.scrollTop
          ) {
            this.isNewMessages = true;
          }
        }
      }
    },
  },
  methods: {
    navigateToReply(question) {
      this.selectedQuestion = question;
      this.section = 'reply';
    },
    getQuestions(type) {
      return this.qas.filter((q) => q.type == type);
    },
    formatDate(time) {
      if (dayjs(time).isToday()) {
        return dayjs(time).format('h:mma');
      } else {
        return dayjs(time).format('h:mma, DD MMM YY');
      }
    },
    formatName: ({ isAnonymous, firstName, lastName }) =>
      isAnonymous
        ? 'Anonymous'
        : [firstName, lastName].filter((x) => x).join(' ') || '(No name)',
    formatReplyName: ({ firstName, lastName, accountName }) => {
      return (
        [firstName, lastName].filter((x) => x).join(' ') +
        ' (' +
        accountName +
        ')'
      );
    },
    approve(id) {
      this.$store.dispatch('APPROVE_QUESTION', id);
    },
    answer(id) {
      var question = this.qaQuestions.find((q) => q.id == id);
      if (question && question.approvalState == ANSWERED) {
        this.$store.dispatch('APPROVE_QUESTION', id);
      } else {
        this.$store.dispatch('ANSWER_QUESTION', id);
      }
    },
    reject(id) {
      this.$store.dispatch('REJECT_QUESTION', id);
    },
    reply() {
      this.$store.dispatch('REPLY_TO_QUESTION', {
        id: this.selectedQuestion.id,
        content: this.replyContent,
      });

      this.replyContent = '';
      this.selectedQuestion = null;
      this.section = null;
      this.scrollToBottom();
    },
    removeAnswer(questionId, id) {
      this.$store.dispatch('REMOVE_QUESTION_ANSWER', { questionId, id });
    },
    scrollToBottom() {
      if (this.autoScroll) {
        this.scrollTimeout = setTimeout(() => {
          this.$refs.scroll.scrollTo(0, this.$refs.scroll.scrollHeight);
        }, 100);
      }
    },
    handleClickNewMessages() {
      this.autoScroll = true;
      this.scrollToBottom();
    },
    onScroll() {
      if (
        this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight ==
        this.$refs.scroll.scrollTop
      ) {
        this.isNewMessages = false;
        this.autoScroll = true;
      } else {
        this.autoScroll = false;
      }
    },
    pushToOutput(q) {
      this.$store.dispatch('SET_QUESTION_OUTPUT', {
        questionId: q.isOnScreen ? null : q.id,
      });
    },
    assignToPresenter(question, presenter = null) {
      this.$store.dispatch('SET_QUESTION_FOR_PRESENTER', {
        questionId: question.id,
        forPresenterId: presenter?.pid,
      });
    },
  },
};
</script>
