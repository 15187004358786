import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import dayjs from 'dayjs';

Vue.filter('upperFirst', function (value) {
  return upperFirst(value);
});

Vue.filter('dateTime', function (value) {
  return dayjs(value).format('D MMM YYYY H:mm a');
});

Vue.filter('formatDate', function (time) {
  if (dayjs(time).isToday()) {
    return dayjs(time).format('h:mma');
  }

  return dayjs(time).format('h:mma, DD MMM YY');
});
