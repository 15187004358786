<template>
  <div class="flex flex-grow flex-col">
    <div v-if="!can('studio-outbound-streams')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="flex h-full flex-col">
          <div class="mb-3 flex items-center justify-between space-x-2">
            <input
              type="text"
              v-model="search"
              placeholder="Search outbound streams..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />
            <button @click="setSearchFocus">
              <i class="material-icons-outlined flex justify-center">search</i>
            </button>
          </div>

          <!-- list -->
          <div class="flex flex-grow flex-col overflow-auto pt-6">
            <div
              v-if="getStreams.length == 0"
              class="flex flex-1 justify-center text-center text-grey-400"
            >
              Add RTMP / SRT input streams using the button below
            </div>
            <div
              v-for="(stream, index) in getStreams"
              :key="index"
              @click.prevent="handleSelectStream(stream)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              :class="{ 'bg-grey-100': selectedStream == stream }"
            >
              <div class="flex flex-1 items-center space-x-2">
                <div class="flex w-12 justify-center">
                  <div
                    class="rounded border border-grey-400 px-1 text-xs text-grey-400"
                  >
                    {{ stream.type }}
                  </div>
                </div>
                <div class="font-medium">{{ stream.name }}</div>
              </div>

              <button
                v-if="['rehearsal', 'live'].includes(event.eventState)"
                @click.prevent="toggleStreamActive(stream)"
                class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
              >
                <i
                  v-if="!stream.running"
                  class="material-icons-outlined text-green-500"
                  >play_arrow</i
                >
                <i v-else class="material-icons-outlined text-red-500">pause</i>
              </button>

              <DropdownMenu ref="dropdown">
                <template slot="control" slot-scope="slotProps">
                  <button
                    @click.stop="handleDropdown(slotProps)"
                    class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">more_vert</i>
                  </button>
                </template>

                <template slot="menu">
                  <button
                    @click="handleCopyStreamURL(stream)"
                    :disabled="
                      !['rehearsal', 'live'].includes(event.eventState)
                    "
                    class="dropdownListItem"
                  >
                    Copy url
                  </button>

                  <button
                    @click="navigateToEditStream(stream)"
                    :disabled="stream.running"
                    class="dropdownListItem"
                  >
                    Edit
                  </button>

                  <button
                    @click="navigateToRemoveStream(stream)"
                    :disabled="stream.isActive"
                    class="dropdownListItem"
                  >
                    Delete
                  </button>
                </template>
              </DropdownMenu>
            </div>
          </div>

          <!-- preview -->
          <div
            v-if="selectedStream"
            class="flex w-full flex-col border-t border-grey-200 pt-3"
          >
            <div class="flex flex-1 justify-between py-3">
              <h3 class="flex w-full items-center text-xl font-bold">
                {{ selectedStream.name }}
              </h3>
              <button
                class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                @click.prevent="selectedStream = null"
              >
                <i class="material-icons-outlined">close</i>
              </button>
            </div>

            <div class="flex flex-col justify-center">
              <div>Stream details</div>
              <div v-if="selectedStream.type == 'RTMP'" class="text-grey-400">
                URL: {{ selectedStream.uri }}
              </div>
              <div v-if="selectedStream.type == 'SRT'" class="text-grey-400">
                <div v-if="showStreamURL">
                  SRT URI: {{ selectedStream.uri }}
                </div>
                <div v-else>Details available once in rehearsal</div>
              </div>

              <div class="pt-4">Stream settings</div>
              <div class="text-grey-400">
                Video bitrate: {{ selectedStream.videoBitrate }}kbps
              </div>

              <div v-if="selectedStream.type == 'SRT'">
                <div class="text-grey-400">Port: {{ selectedStream.port }}</div>
                <div class="text-grey-400">
                  Passphrase:
                  {{
                    selectedStream.passphrase
                      ? selectedStream.passphrase
                      : 'N/A'
                  }}
                </div>
                <div class="text-grey-400">Mode: Caller</div>

                <div class="flex pt-4">
                  VLC URL
                  <button
                    @click.prevent="handleCopyStreamURL(getStreamURL)"
                    class="flex h-6 w-auto items-center justify-center rounded-full p-1 text-grey-500 hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined text-sm text-grey-400"
                      >content_copy</i
                    >
                  </button>
                </div>
                <div class="text-grey-400">
                  <div v-if="showStreamURL">
                    {{ getStreamURL }}
                  </div>
                  <div v-else class="flex">
                    Details available once in rehearsal
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button v-else class="btn-blue" @click.prevent="navigateToAddStream">
            Add outbound stream
          </button>
        </div>
      </template>

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="py-6">
          <button
            class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3 class="-ml-10 w-full text-center text-2xl font-bold">
            {{ upperFirst(section) }}
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add stream -->
        <template v-if="section == 'add_stream'">
          <form class="flex flex-col px-3" @submit.prevent="handleAddStream">
            <a
              href="https://support.joinin.live/portal/en/kb/articles/outbound-streams"
              target="_blank"
              class="flex justify-end pb-4"
            >
              <p class="text-right text-sm text-blue-500">
                Recommended stream settings
              </p>
              <i class="material-icons-outlined text pl-1 text-sm text-blue-500"
                >help_outline</i
              >
            </a>

            <TextField
              name="new_stream_name"
              label="Name"
              v-model="newStream.name"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <SelectField
              name="new_stream_type"
              label="Stream type"
              v-model="newStream.type"
              :required="true"
              :errors="errors"
              :options="typeOptions"
            />

            <SelectField
              name="new_stream_bitrate"
              label="Bitrate"
              v-model="newStream.videoBitrate"
              :required="true"
              :errors="errors"
              :options="bitrateOptions"
            />

            <TextField
              v-if="newStream.type == 'RTMP'"
              name="new_stream_uri"
              label="Uri"
              v-model="newStream.uri"
              :required="true"
              :errors="errors"
            />

            <TextField
              v-if="newStream.type == 'SRT'"
              name="new_stream_passphrase"
              label="Passphrase"
              v-model="newStream.passphrase"
              :required="false"
              :errors="errors"
            />

            <button type="submit" id="btn_add_stream" class="btn-blue">
              Add stream
            </button>
          </form>
        </template>
        <!-- end of add stream -->

        <!-- delete stream -->
        <template v-if="section == 'delete_stream'">
          <div class="px-6">
            <h4 class="text-center text-lg font-bold">
              {{ selectedStream.name }}
            </h4>
            <div class="mt-10 text-center">
              Are you sure you want to delete this stream? It will be removed
              from your event and any publish details will change if you choose
              to add it again.
            </div>

            <button class="btn-red mt-10" @click.prevent="handleRemoveStream">
              Delete
            </button>
          </div>
        </template>
        <!-- end of delete stream -->

        <!-- update stream -->
        <template v-if="section == 'edit_stream'">
          <form class="flex flex-col px-3" @submit.prevent="handleEditStream">
            <h4 class="text-center text-lg font-bold">
              {{ selectedStream.name }}
            </h4>
            <TextField
              name="stream_name"
              label="Name"
              v-model="selectedStream.name"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <SelectField
              name="stream_bitrate"
              label="Bitrate"
              v-model="selectedStream.videoBitrate"
              :required="true"
              :errors="errors"
              :options="bitrateOptions"
            />

            <template v-if="selectedStream.type == 'RTMP'">
              <TextField
                name="stream_uri"
                label="Uri"
                v-model="selectedStream.uri"
                :required="true"
                :errors="errors"
              />
            </template>

            <template v-if="selectedStream.type == 'SRT'">
              <TextField
                name="stream_port"
                label="Port"
                v-model="selectedStream.port"
                :required="true"
                :errors="errors"
              />

              <TextField
                name="stream_passphrase"
                label="Passphrase"
                v-model="selectedStream.passphrase"
                :required="false"
                :errors="errors"
              />
            </template>

            <button type="submit" class="btn-blue">Update</button>
          </form>
        </template>
        <!-- end of rename stream -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import DropdownMenu from '@/components/DropdownMenu';
import TextField from '@/components/TextField';
import SelectField from '@/components/SelectField';
import errors from '@/mixins/errors';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  name: 'StudioPanelOutboundStreams',
  mixins: [errors],
  components: {
    DropdownMenu,
    TextField,
    SelectField,
    UpgradePlanLink,
  },
  data: () => ({
    typeOptions: [
      { id: 'RTMP', label: 'RTMP/RTMPS stream' },
      { id: 'SRT', label: 'SRT stream' },
    ],
    bitrateOptions: [
      { id: 3000, label: '3000 kbps' },
      { id: 4000, label: '4000 kbps' },
      { id: 5000, label: '5000 kbps' },
      { id: 6000, label: '6000 kbps' },
      { id: 7000, label: '7000 kbps' },
      { id: 8000, label: '8000 kbps' },
    ],
    section: null,
    selectedStream: null,
    search: null,
    newStream: {
      type: null,
      name: null,
      uri: null,
      videoBitrate: null,
      passphrase: null,
    },
  }),
  computed: {
    ...mapState(['event', 'isAllowAddSource', 'destinations']),
    ...mapGetters(['upperFirst']),
    getStreams() {
      if (this.search) {
        return this.destinations.filter(
          (row) =>
            row.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.destinations;
      }
    },
    showStreamURL() {
      return ['rehearsal', 'live'].includes(this.event.eventState);
    },
    getStreamURL() {
      if (this.selectedStream.type == 'RTMP') {
        return `${this.event.coreGstHost.replace('https://', 'rtmp://')}${
          this.selectedStream.uri
        }`;
      } else if (this.selectedStream.type == 'SRT') {
        return `${this.event.coreGstHost.replace('https://', 'srt://')}:${
          this.selectedStream.port
        }?passphrase=${this.selectedStream.passphrase}&pbkenlen=32`;
      }
      return '';
    },
  },
  methods: {
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    setSearchFocus() {
      this.$refs.search.focus();
    },
    handleSelectStream(stream) {
      this.selectedStream = stream;
    },
    navigateToAddStream() {
      this.newStream = {
        type: null,
        name: null,
        uri: null,
        videoBitrate: null,
        passphrase: null,
      };

      this.section = 'add_stream';
    },
    navigateToRemoveStream(stream) {
      this.selectedStream = stream;
      this.section = 'delete_stream';
    },
    navigateToEditStream(stream) {
      this.selectedStream = stream;
      console.log(stream);
      this.section = 'edit_stream';
    },
    toggleStreamActive(stream) {
      this.$store.dispatch('TOGGLE_STREAM_STATE', stream.id);
    },
    handleAddStream() {
      this.$store.dispatch('ADD_DESTINATION', this.newStream);

      this.section = null;
    },
    handleRemoveStream() {
      this.$store.dispatch('REMOVE_DESTINATION', this.selectedStream);

      this.section = null;
    },
    handleEditStream() {
      this.$store.dispatch('EDIT_DESTINATION', this.selectedStream);

      this.section = null;
    },
    handleCopyStreamURL(stream) {
      this.selectedStream = stream;

      navigator.clipboard.writeText(this.getStreamURL);

      this.$store.commit(
        'MESSAGE',
        `Outbound stream link has been copied to clipboard.`
      );

      this.$refs.dropdown?.map((dropdown) => {
        dropdown.close();
      });
    },
  },
};
</script>
