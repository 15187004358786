<template>
  <div class="flex w-full flex-1 flex-col justify-center">
    <div
      v-if="getFormFields.length == 0"
      @click="handleNewField"
      class="cursor-pointer text-center text-blue-500"
    >
      Add a new field
    </div>

    <ReorderViewList
      v-else
      :items="getFormFields"
      :transition="false"
      @onUpdate="handleReorderUpdate"
    >
      <template slot="header">
        <button
          @click.prevent="handleNewField"
          class="group flex items-center justify-center space-x-1 rounded-full px-2 py-1"
        >
          <i class="material-icons-outlined group-hover:text-blue-500">add</i>
          <span class="text-sm text-grey-800 group-hover:text-blue-500"
            >New</span
          >
        </button>
      </template>

      <template #item="{ item, index }">
        <div class="px-4 font-medium">{{ index + 1 }}</div>
        <div class="flex flex-1 flex-col">
          <div class="flex-1">
            <div v-if="!['image'].includes(item.fieldType)">
              {{ item.title }}
            </div>
            <div v-else>
              <img class="flex flex-1 px-4" :src="item.title" />
            </div>
          </div>
          <div v-if="item.subTitle" class="flex-1 text-grey-400">
            {{ item.subTitle }}
          </div>
          <div v-if="item.tag" class="flex space-x-1 pt-2 text-xs">
            <div class="rounded bg-grey-200 px-2 capitalize text-grey-400">
              {{ item.tag }}
            </div>
          </div>
        </div>
      </template>

      <template #actions="{ item, index }">
        <div class="flex items-center">
          <i
            @click.prevent="handleViewField(item, index)"
            class="material-icons-outlined mr-2 cursor-pointer rounded-full p-1 text-grey-400 hover:bg-grey-200"
            >wysiwyg</i
          >
          <ConfirmModalButton
            @click="handleDeleteItem(item)"
            className="w-full block rounded cursor-pointer flex items-center justify-between"
            buttonType="red"
            buttonText="Delete"
          >
            <template slot="button">
              <i
                class="material-icons-outlined cursor-pointer rounded-full p-1 text-red-500 hover:bg-grey-200"
                >close</i
              >
            </template>

            <template slot="content">
              <div>
                <div>
                  You're going to delete field: <strong>{{ item.name }}</strong
                  >.
                </div>
                <div v-if="type === 'form'" class="pt-4">
                  All submitted attendee data for this field will be removed.
                  Data cannot be recovered once deleted.
                </div>
                <div class="pt-4">Are you sure?</div>
              </div>
            </template>
          </ConfirmModalButton>
        </div>
      </template>
    </ReorderViewList>

    <Modal @close="handleCancelDialog" ref="fieldDialog">
      <template slot="content">
        <div class="flex items-center justify-end py-4">
          <div class="mr-auto flex items-center">
            <span class="text-xl font-bold"
              ><span v-if="!isNew">{{ currentIndex + 1 }} - </span>
              {{ isNew ? 'New field' : field.label }}</span
            >
          </div>
        </div>

        <div class="mt-6 flex items-start">
          <div class="flex-1">
            <MdSelectField
              class="flex-1"
              label="Type"
              name="type"
              v-model="field.fieldType"
              :disabled="!isNew"
              :errors="errors"
              :options="options"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                Choose the form field you would like to add.
              </div>
            </template>
          </TooltipModal>
        </div>

        <div v-if="field.fieldType != 'image'" class="flex items-start">
          <div class="flex-1">
            <MdTextField
              class="flex-1"
              label="Text"
              name="label"
              v-model="field.label"
              :errors="errors"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                <div
                  v-if="!['header', 'description'].includes(field.fieldType)"
                >
                  The text to display above the form field.
                </div>
                <div v-else>The content for this field.</div>
              </div>
            </template>
          </TooltipModal>
        </div>

        <div v-else class="flex items-start">
          <div class="flex-1">
            <MdTextField
              class="flex-1"
              label="Image URL"
              name="label"
              v-model="field.label"
              :errors="errors"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                The hosted URL of the image to display. E.g:
                https://cdn.instagram.com/image
              </div>
            </template>
          </TooltipModal>
        </div>

        <template
          v-if="!['header', 'description', 'image'].includes(field.fieldType)"
        >
          <div class="flex items-start">
            <div class="flex-1">
              <MdSelectField
                class="flex-1"
                label="Required"
                name="required"
                v-model="field.isRequired"
                :errors="errors"
                :options="requiredOptions"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Select if this field must be completed by the attendee.
                </div>
              </template>
            </TooltipModal>
          </div>
        </template>

        <template
          v-if="field.fieldType == 'text' || field.fieldType == 'text_long'"
        >
          <div class="flex items-start">
            <div class="flex-1">
              <MdTextField
                class="flex-1"
                label="Placeholder (optional)"
                name="placeholder"
                v-model="field.placeholder"
                :errors="errors"
              />
            </div>

            <TooltipModal>
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  Set the placeholder text that will appear in the text field
                  before it is completed. This is usually a call to action. E.g:
                  "Add you information now".
                </div>
              </template>
            </TooltipModal>
          </div>
        </template>

        <template v-if="isSelection">
          <template v-if="field.fieldType == 'multiselect'">
            <div class="mt-4 flex items-start">
              <div class="flex-1">
                <MdSelectField
                  class="flex-1"
                  label="Minimum option selection"
                  name="type"
                  v-model="field.selectionCountMin"
                  :errors="errors"
                  :options="countMinOptions"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    Set the minimum number of options that must be selected.
                  </div>
                </template>
              </TooltipModal>
            </div>

            <div class="flex items-start">
              <div class="flex-1">
                <MdSelectField
                  class="flex-1"
                  label="Maximum option selection"
                  name="type"
                  v-model="field.selectionCountMax"
                  :errors="errors"
                  :options="countMaxOptions"
                  @click="toggleManualEntry"
                />
              </div>

              <TooltipModal>
                <template slot="control">
                  <button
                    class="-ml-5 -mt-2 flex items-center space-x-1 text-sm"
                  >
                    <i class="material-icons-outlined text-icon-sm">info</i>
                  </button>
                </template>

                <template slot="content">
                  <div class="flex flex-col space-y-4">
                    Set the maximum number of options that can be selected.
                  </div>
                </template>
              </TooltipModal>
            </div>
          </template>

          <div class="mb-4 flex items-center justify-between">
            Options:
            <button
              @click.prevent="handleAddNewOption"
              class="group flex items-center justify-center rounded-full border border-grey-200 px-2 py-1 text-sm text-grey-800 hover:border-blue-500 hover:text-blue-500"
            >
              <i class="material-icons-outlined group-hover:text-blue-500"
                >add</i
              >New option
            </button>
          </div>
          <div class="flex w-full flex-col">
            <div
              v-for="(option, index) in fieldOptions"
              :key="index"
              class="flex flex-1 items-center justify-between"
            >
              <MdTextField
                :name="'new_question_option_label_' + index"
                class="mr-4 flex-1"
                label="Option"
                v-model="option.value"
                :required="true"
                :errors="errors"
                ref="options"
                @keydown.enter.prevent="handleOptionKeydown"
              />

              <i
                @click.prevent="removeFieldOption(index)"
                class="material-icons-outlined mx-2 -mt-5 cursor-pointer text-red-500 hover:text-red-800"
              >
                close
              </i>
            </div>
          </div>
        </template>

        <div class="mt-4 flex justify-between">
          <button
            @click.prevent="handleSaveField"
            class="flex-1 rounded-full bg-blue-500 px-2 py-2 text-white hover:bg-blue-800"
            :disabled="isSaving || !isValid"
          >
            <i
              v-if="isSaving"
              class="material-icons-outlined spin mr-4 text-white"
              >loop</i
            >
            <span v-if="isSaving">Saving</span>
            <span v-else>Save</span>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import api from '@/services/api';
import Modal from '@/components/Modal';
import errors from '@/mixins/errors';
import MdSelectField from '@/components/MdSelectField';
import MdTextField from '@/components/MdTextField';
import TooltipModal from '@/components/TooltipModal';
import ConfirmModalButton from '@/components/ConfirmModalButton';
import ReorderViewList from '@/components/ui/ReorderListView';

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export default {
  mixins: [errors],
  name: 'FormBuilder',
  props: ['form', 'type'],
  data: () => ({
    isLoaded: false,
    isSaving: false,
    options: [
      { id: 'header', label: 'Header' },
      { id: 'description', label: 'Description' },
      { id: 'image', label: 'Image display' },
      { id: 'select', label: 'Select' },
      { id: 'multiselect', label: 'Multi select' },
      { id: 'text', label: 'Text - short' },
      { id: 'text_long', label: 'Text - long' },
      { id: 'checkbox', label: 'Checkbox' },
      { id: 'stars', label: 'Star rating' },
    ],
    field: {
      name: 'field_' + Date.now(),
      label: null,
      isRequired: false,
      fieldType: null,
      placeholder: null,
      selectionCountMin: null,
      selectionCountMax: null,
    },
    requiredOptions: [
      { id: false, label: 'No' },
      { id: true, label: 'Yes' },
    ],
    fieldOptions: [],
    isNew: false,
    section: null,
    currentIndex: null,
    selectedField: null,
    isManualAdjusted: false,
  }),
  components: {
    Modal,
    MdSelectField,
    MdTextField,
    TooltipModal,
    ConfirmModalButton,
    ReorderViewList,
  },
  computed: {
    getFormFields() {
      return this.form?.fields.map((field) => {
        if (field.fieldType == 'select' && field.selectionCountMax > 1) {
          field.fieldType = 'multiselect';
        }

        field.title = field.label;
        field.tag = this.getType(field.fieldType);

        return field;
      });
    },
    countMinOptions() {
      var list = [];
      for (let i = 1; i <= this.fieldOptions.length; i++) {
        list.push({ id: i, label: i });
      }

      return list;
    },
    countMaxOptions() {
      var list = [];
      for (let i = 1; i <= this.fieldOptions.length; i++) {
        list.push({
          id: i,
          label: i,
          disabled: this.field.selectionCountMin > i ? true : false,
        });
      }

      return list;
    },
    isSelection() {
      return ['select', 'multiselect'].includes(this.field.fieldType);
    },
    getUrl() {
      return this.type == 'template' ? '/forms/templates' : '/forms';
    },
    isValid() {
      return (
        this.field.label &&
        this.field.label != '' &&
        this.field.fieldType &&
        (!['select', 'multiselect'].includes(this.field.fieldType) ||
          (this.fieldOptions.length > 0 &&
            this.fieldOptions.every((option) => option.value))) &&
        (this.field.fieldType != 'multiselect' ||
          (this.field.selectionCountMin > 0 &&
            this.field.selectionCountMax > 0))
      );
    },
  },
  watch: {
    'field.selectionCountMin': function (val) {
      if (this.isSelection && val && val > this.field.selectionCountMax) {
        this.field.selectionCountMax = val;
      }
    },
    'field.fieldType': function (val) {
      //add new option to new field when type = select
      if (['select', 'multiselect'].includes(val) && this.isNew) {
        this.fieldOptions.push({
          value: null,
        });

        this.field.selectionCountMin = 1;
        this.field.selectionCountMax = 1;
      }
    },
    'fieldOptions': function (val) {
      if (this.field.fieldType == 'multiselect') {
        //force max = options length when max greater than options length
        //when delete option
        if (this.field.selectionCountMax > val.length) {
          this.field.selectionCountMax = val.length;
        }

        //force max =  options length
        //when add option
        //ignored when manual adjust activated.
        // if(!this.isManualAdjusted) {
        //     console.log('watch fieldOptions manual');
        //     this.field.selectionCountMax = val.length;
        // }
      }
    },
  },
  methods: {
    handleNewField() {
      this.fieldOptions = [];
      this.isManualAdjusted = false;

      this.isNew = true;
      this.$refs.fieldDialog.show();
    },
    handleAddNewOption() {
      this.fieldOptions.push({
        value: null,
      });
    },
    async handleSaveField() {
      this.isSaving = true;

      if (this.isNew) {
        const response = await api.post(
          `${this.getUrl}/${this.form.id}/fields`,
          {
            ...this.field,
            fieldType:
              this.field.fieldType == 'multiselect'
                ? 'select'
                : this.field.fieldType,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          var field = response.data;
          if (this.isSelection) {
            //register options
            field.options = [];
            for (let i = 0; i < this.fieldOptions.length; i++) {
              const res = await api.post(
                `${this.getUrl}/${this.form.id}/fields/${response.data.id}/options`,
                this.fieldOptions[i]
              );

              if (res.status >= 200 && res.status < 300) {
                field.options.push(this.fieldOptions[i]);
              }
            }
          }

          this.form.fields.push(field);
          this.$store.commit('MESSAGE', 'Field has been added successfully.');
        }

        this.isSaving = false;
        this.section = null;
      } else {
        const cIndex = this.form.fields.findIndex(
          (field) => field.id == this.field.id
        );
        if (cIndex === -1) return;

        if (this.isSelection) {
          for (let i = 0; i < this.fieldOptions.length; i++) {
            if (!hasOwnProperty(this.fieldOptions[i], 'id')) {
              //new option
              const res = await api.post(
                `${this.getUrl}/${this.form.id}/fields/${this.field.id}/options`,
                this.fieldOptions[i]
              );

              this.fieldOptions[i] = {
                fieldId: this.field.id,
                ...res.data,
              };
            }
          }
        }

        var updateData = {
          name: this.field.name,
          label: this.field.label,
          isRequired: this.field.isRequired,
          placeholder: this.field.placeholder,
        };

        if (this.field.fieldType == 'multiselect') {
          updateData.selectionCountMin = this.field.selectionCountMin;
          updateData.selectionCountMax = this.field.selectionCountMax;
        }

        api
          .put(
            `${this.getUrl}/${this.form.id}/fields/${this.field.id}`,
            updateData
          )
          .then((response) => {
            this.isSaving = false;

            this.section = null;

            if (response.status >= 200 && response.status < 300) {
              this.$store.commit(
                'MESSAGE',
                'Field has been updated successfully.'
              );
              //update
              this.form.fields[cIndex].label = response.data.label;
              this.form.fields[cIndex].isRequired = response.data.isRequired;

              //returned options = []
              this.form.fields[cIndex].options = this.fieldOptions;
            }
          });
      }

      this.$refs.fieldDialog.close();
    },
    handleViewField(field, index) {
      this.currentIndex = index;
      this.field = field;

      this.fieldOptions = field.options;

      this.isNew = false;
      this.isManualAdjusted = false;

      this.$refs.fieldDialog.show();
    },
    toggleRemoveFieldConfirmation(field) {
      this.selectedField = field;

      this.$nextTick(() => {
        this.$refs.deleteQuestionDialog.show();
      });
    },
    handleDeleteItem(field) {
      api
        .delete(`${this.getUrl}/${this.form.id}/fields/${field.id}`)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            const cIndex = this.form.fields.findIndex((f) => f.id == field.id);
            if (cIndex !== -1) {
              this.form.fields.splice(cIndex, 1);

              this.selectedField = null;

              this.$store.commit('MESSAGE', 'Field has been removed.');
            }
          }
        });
    },
    removeFieldOption(index) {
      const option = this.fieldOptions[index];

      if (hasOwnProperty(option, 'id')) {
        api
          .delete(
            `${this.getUrl}/${this.form.id}/fields/${this.field.id}/options/${option.id}`
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              this.fieldOptions.splice(index, 1);
            }
          });
      } else {
        this.fieldOptions.splice(index, 1);
      }
    },
    handleCancelDialog() {
      //reset
      this.field = {
        name: 'field_' + Date.now(),
        label: null,
        fieldType: null,
        isRequired: false,
        placeholder: null,
        selectionCountMin: null,
        selectionCountMax: null,
      };

      this.isNew = true;
    },
    handleOptionKeydown(e) {
      if (e.code == 'Enter') {
        this.handleAddNewOption();
        this.$nextTick(() => {
          this.$refs.options[this.$refs.options.length - 1].focus();
        });
      }
    },
    toggleManualEntry() {
      this.isManualAdjusted = true;
    },
    getType(type) {
      switch (type) {
        case 'text_long':
          return 'Text area';
        case 'multiselect':
          return 'Multi select';
        case 'stars':
          return 'Star rating';
        default:
          return type;
      }
    },
    async handleReorderUpdate(list) {
      const response = await api.post(
        `${this.getUrl}/${this.form.id}/fields/order`,
        { ids: list.map((i) => i.id), formId: this.form.id }
      );

      if (response.status >= 200 && response.status < 300) {
        this.form.fields.sort((a, b) => {
          return response.data.indexOf(a.id) - response.data.indexOf(b.id);
        });
      }
    },
  },
};
</script>
