<template>
  <div id="app">
    <Component :is="layout">
      <RouterView />
    </Component>

    <!-- modal backdrop confirmation modals - sits on top of everything except for the modal -->
    <PortalVue to="modal-position">
      <Transition name="fade">
        <div v-if="$store.state.modalOpen">
          <div
            class="modal-overlay fixed inset-x-0 inset-y-0 bg-black opacity-75"
          ></div>
        </div>
      </Transition>
    </PortalVue>

    <Notifications />
  </div>
</template>

<script>
import { Portal as PortalVue } from 'portal-vue';
import includes from 'lodash/includes';
import AuthLayout from '@/layouts/AuthLayout';
import MainLayout from '@/layouts/MainLayout';
import MixerLayout from '@/layouts/MixerLayout';
import ErrorLayout from '@/layouts/ErrorLayout';
import BlankLayout from '@/layouts/BlankLayout';
import Notifications from '@/components/Notifications';

import '@/assets/tailwind.css';

export default {
  components: {
    AuthLayout,
    MainLayout,
    MixerLayout,
    ErrorLayout,
    BlankLayout,
    Notifications,
    PortalVue,
  },

  computed: {
    layout() {
      if (this.$route.name == 'error') {
        return 'ErrorLayout';
      }

      if (
        includes(
          ['login', 'forgot', 'reset', 'setup', 'signup'],
          this.$route.name
        )
      ) {
        return 'AuthLayout';
      }

      if (this.$route.name == 'studio') {
        return 'MixerLayout';
      }

      if (
        [
          'output-io-clients',
          'output-presenters',
          'output-qa',
          'output-poll',
          'output-chat',
        ].includes(this.$route.name)
      ) {
        return 'BlankLayout';
      }

      return 'MainLayout';
    },
  },
};
</script>
