<template>
  <form @submit.prevent="save()">
    <div class="pb-8 text-grey-400">
      Customise event branding used on the Attendee portal, emails and Presenter
      Hub.
    </div>

    <div class="mb-2 mt-6 text-lg font-medium">Background</div>

    <FileInput
      name="page_background_image"
      id="file_upload_page_background"
      label="Page background image"
      class="mb-8"
      :supports="['png', 'jpg', 'jpeg']"
      :fileSizeLimit="2048"
      v-model="form.pageBackgroundImage"
      tooltip="Set an optional background image. This image is displayed on top of the page background colour. Recommended 1920 (w) x 1080 (h) pixel JPG."
      :required="false"
      :errors="errors"
    />

    <MdColorField
      name="page_background_color"
      label="Page background colour"
      v-model="form.pageBackgroundColor"
      tooltip="Set a background colour for the portal. This is displayed under the page background image if uploaded."
      :errors="errors"
    />

    <div class="mb-2 mt-4 text-lg font-medium">Font</div>
    <MdFontField
      name="font"
      label="Font"
      v-model="form.font"
      :errors="errors"
    />

    <div class="mb-2 mt-12 text-lg font-medium">Logos</div>
    <FileInput
      name="logo"
      id="file_upload_logo"
      label="Primary logo"
      class="mb-8"
      :supports="['png', 'jpg', 'jpeg']"
      :fileSizeLimit="2048"
      v-model="form.logo"
      tooltip="Set an optional logo image. Recommended 600 (w) x 200 (h) transparent PNG."
      :required="false"
      :errors="errors"
    />

    <div v-if="!can('event-registration')" class="mb-6">
      <UpgradePlanLink />
    </div>
    <div v-else>
      <FileInput
        name="sponsor_image"
        id="file_upload_sponsor"
        label="Sponsor logo"
        class="mb-8"
        :supports="['png', 'jpg', 'jpeg']"
        :fileSizeLimit="2048"
        v-model="form.sponsorImage"
        tooltip="Set an optional sponsor logo image to be displayed within the portal. Recommended 600 (w) x 200 (h) transparent PNG."
        :required="false"
        :errors="errors"
      />
    </div>

    <div class="mb-4 mt-12 text-lg font-medium">Colours</div>
    <MdColorField
      name="content_background_color"
      label="Content background colour"
      v-model="form.contentBackgroundColor"
      tooltip="Set the background colour of the main content area. The logo, text and buttons will display on this background."
      :errors="errors"
    />

    <MdColorField
      name="text_color"
      label="Text colour"
      v-model="form.textColor"
      tooltip="Set the colour of text. Text displays on top of the content background colour."
      :errors="errors"
    />

    <MdColorField
      name="button_color"
      label="Button colour"
      v-model="form.buttonColor"
      tooltip="Set the colour of buttons. Buttons display on top of the content background colour."
      :errors="errors"
    />

    <MdColorField
      name="button_text_color"
      label="Button text colour"
      v-model="form.buttonTextColor"
      tooltip="Set the text colour for buttons."
      :errors="errors"
    />

    <SubmitButton :saving="saving" />
  </form>
</template>

<script>
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import includes from 'lodash/includes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import api from '@/services/api';
import errors from '@/mixins/errors';

import SubmitButton from '@/components/SubmitButton';
import MdColorField from '@/components/MdColorField';
import FileInput from '@/components/FileInput';
import MdFontField from '@/components/MdFontField';
import UpgradePlanLink from '@/components/UpgradePlanLink';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    SubmitButton,
    MdColorField,
    FileInput,
    MdFontField,
    UpgradePlanLink,
  },

  data: () => ({
    form: {
      pageBackgroundImage: null,
      pageBackgroundColor: '#00b4f1',
      font: 'Lato',
      logo: null,
      sponsorImage: null,
      contentBackgroundColor: '#ffffff',
      textColor: '#212121',
      buttonColor: '#00b4f1',
      buttonTextColor: '#ffffff',
      portalHeading: null,
      description: null,
      tagline: null,
      eventFinishedText: null,
      supportText: null,
      registrationCallToActionText: null,
      registrationCompleteText: null,
      secondaryLogo: null,
      secondaryPageBackgroundImage: null,
      secondarySponsorImage: null,
    },
    saving: false,
    isFormChanged: false,
    sendEmail: false,
  }),

  mounted() {
    if (this.event) {
      this.setupForm();
    }
  },

  methods: {
    setupForm() {
      assign(this.form, pick(this.event, keys(this.form)));

      this.$nextTick(() => {
        this.isFormChanged = false;
      });
    },

    save() {
      this.saving = true;

      // Manually create a form data object so we can mix a file upload with other fields
      let data = new FormData();
      data.append('logo', this.form.logo);
      data.append('pageBackgroundImage', this.form.pageBackgroundImage);
      data.append('sponsorImage', this.form.sponsorImage);

      // FormData cannot send literal null, so we only append the fields with a value.
      for (const property in this.form) {
        if (
          includes(
            [
              'logo',
              'pageBackgroundImage',
              'sponsorImage',
              'secondaryLogo',
              'secondaryPageBackgroundImage',
              'secondarySponsorImage',
            ],
            property
          )
        ) {
          continue;
        }

        const value = this.form[property];

        if (value) {
          data.append(property, value);
        }
      }

      // TODO: Use the Vuex store for this!
      api
        .put('/jobs/' + this.event.id + '/branding', data)
        .then((response) => {
          this.saving = false;

          this.notify('success', 'Event updated');
          this.$emit('event-updated', response.data);
        })
        .catch(() => {
          this.saving = false;
        });
    },
  },
};
</script>
