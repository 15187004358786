<template>
  <div class="flex w-full flex-grow flex-col">
    <div v-if="!can('event-quiz')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else-if="quiz" class="flex h-full flex-1 flex-col">
      <div class="my-2 w-full">
        <div class="flex justify-between rounded bg-grey-100 px-2 py-2">
          <div class="flex flex-1 flex-col">
            <div
              v-if="!quiz.isRunning"
              class="flex flex-1 items-center text-grey-400"
            >
              Quiz inactive
            </div>

            <div
              v-if="quiz.isRunning && quiz.activeQuestionExpiration"
              class="flex w-36 items-center justify-between rounded px-1"
              :class="{
                'animate-pulse':
                  countDownTime != null &&
                  countDownTime > 0 &&
                  countDownTime < 10,
              }"
            >
              <span class="flex items-center">
                <i
                  class="material-icons-outlined text-icon-sm"
                  :class="{
                    'text-red-500': countDownTime != null && countDownTime < 10,
                    'text-green-500':
                      countDownTime != null && countDownTime >= 10,
                  }"
                >
                  timer
                </i>
              </span>
              <span
                class="flex-1 px-1"
                :class="{
                  'text-red-500': countDownTime != null && countDownTime < 10,
                }"
              >
                {{
                  countDownTime != null
                    ? countDownTime > 0
                      ? countDownTime + 's remaining'
                      : "Time's up"
                    : '...'
                }}
              </span>
            </div>

            <div
              v-if="quiz.isRunning && getActiveRound"
              class="mx-2 flex flex-1 flex-col overflow-hidden"
            >
              <div
                @click="navigateToView(getActiveRound)"
                class="cursor-pointer text-sm text-grey-400 hover:text-grey-800"
              >
                {{ getActiveRound.name }} - Q{{ getActiveQuestionIndex + 1 }}
              </div>
              <div
                @click="
                  navigateToView(getActiveRound);
                  navigateToQuestionDetail(getActiveQuestion);
                "
                class="cursor-pointer py-1 hover:text-grey-800"
              >
                {{ getActiveQuestion.text }}
              </div>
              <div v-if="getActiveQuestion.answered" class="text-grey-400">
                <strong>{{ getActiveQuestion.answered }}</strong>
                {{ getActiveQuestion.answered > 1 ? 'attendees' : 'attendee' }}
                answered
                <span v-if="currentLiveViewers"
                  >({{
                    Math.floor(
                      (getActiveQuestion.answered / currentLiveViewers) * 100
                    )
                  }}%)</span
                >
              </div>
              <div v-else class="text-grey-400">Waiting for answers...</div>
            </div>
          </div>

          <div class="flex w-1/3 flex-col space-y-1">
            <button
              v-if="!this.quiz.activeQuestionId"
              @click="handleActivateQuestion()"
              class="rounded bg-green-500 px-2 py-1 text-sm text-white hover:bg-green-800"
            >
              Start
              {{
                quiz.blocks.length > 0 ? '"' + quiz.blocks[0].name + '"' : null
              }}
            </button>
            <div v-else class="flex w-full">
              <button
                v-if="showNextQuestion"
                @click="handleActivateQuestion(getNextQuestionId)"
                :disabled="
                  countDownTime > 0 || isBlockReorder || isQuestionReorder
                "
                class="flex-1 rounded bg-green-500 px-2 py-1 text-sm text-white hover:bg-green-800"
              >
                Next question
              </button>
              <button
                v-else
                @click="handleActivateQuestion(getNextRoundQuestionId)"
                :disabled="
                  !getNextRound || !getNextRound.questions || countDownTime > 0
                "
                class="flex-1 rounded bg-green-500 px-2 py-1 text-sm text-white hover:bg-green-800"
              >
                Next round
              </button>
            </div>

            <button
              v-if="quiz.isRunning"
              @click="toggleDisplay"
              :disabled="countDownTime > 0"
              class="rounded bg-grey-800 px-2 py-1 text-sm text-white hover:bg-grey-400"
            >
              Show
              {{
                quiz.screenStateCode == 'leaderboard'
                  ? 'question'
                  : 'leaderboard'
              }}
            </button>

            <button
              v-if="quiz.isRunning"
              @click="hideQuiz"
              class="rounded bg-orange-500 px-2 py-1 text-sm text-white hover:bg-orange-800"
            >
              Hide quiz
            </button>
          </div>
        </div>
      </div>

      <div class="mt-2 flex items-center justify-between text-grey-400">
        <button
          @click="handleSelectContent('question')"
          class="flex-1 border-b border-grey-200 py-2 text-center"
          :class="{ 'border-blue-500': sltContent == 'question' }"
        >
          <div :class="{ 'text-black': sltContent == 'question' }">
            Questions
          </div>
        </button>
        <button
          @click="handleSelectContent('leaderboard')"
          class="flex-1 border-b border-grey-200 py-2 text-center"
          :class="{ 'border-blue-500': sltContent == 'leaderboard' }"
        >
          <div :class="{ 'text-black': sltContent == 'leaderboard' }">
            Leaderboard
          </div>
        </button>
      </div>

      <!-- main content -->
      <template v-if="sltContent == 'question'">
        <template v-if="section == null">
          <!-- main content navigation -->
          <div class="relative flex items-center justify-center py-2">
            <div class="mr-auto"></div>
            <h3 class="absolute text-center text-xl font-bold">Rounds</h3>
            <button
              v-if="!isBlockReorder"
              @click="toggleBlockReorder"
              class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined text-3xl">toc</i>
            </button>
            <div
              v-else-if="isQuizBlockReorderSaving"
              class="flex h-10 w-10 items-center justify-center"
            >
              <i class="material-icons-outlined spin text-3xl">sync</i>
            </div>
            <div
              v-else-if="isBlockReorder && !isQuizBlockReorderSaving"
              class="flex items-center justify-between"
            >
              <button
                @click="isBlockReorder = false"
                class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
              >
                <i class="material-icons-outlined">close</i>
              </button>
              <button
                v-if="!isQuizBlockReorderSaving"
                @click="toggleBlockReorder"
                class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
              >
                <i class="material-icons-outlined">check</i>
              </button>
            </div>
          </div>

          <!-- search: round -->
          <div
            v-if="!isBlockReorder"
            class="my-2 flex items-center justify-between space-x-2"
          >
            <input
              type="text"
              v-model="search"
              placeholder="Search quiz rounds..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <i class="material-icons-outlined flex justify-center">search</i>
          </div>

          <!-- main content navigation -->
          <div class="flex flex-col overflow-auto lg:flex-grow">
            <div
              v-if="filteredBlocks.length == 0"
              class="mt-12 flex flex-1 justify-center text-grey-400"
            >
              Add new quiz round to continue.
            </div>
            <div
              v-for="block in filteredBlocks"
              :key="block.id"
              @click.self="navigateToView(block)"
              class="flex w-full cursor-pointer space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              :id="block.id"
              :draggable="isBlockReorder"
              @dragstart="handleStartDragBlock(block)"
              @dragover="handleDragOverBlock"
              @dragend="handleStopDragBlock"
            >
              <div
                v-if="
                  isBlockReorder &&
                  isQuizBlockDragging &&
                  selectedBlock &&
                  selectedBlock.id == block.id
                "
                class="flex flex-1 items-center justify-center rounded border border-dashed border-grey-400 py-4"
              >
                <i class="material-icons-outlined mx-2 text-grey-400"
                  >vertical_align_bottom</i
                >
                Drop here
              </div>
              <div v-else class="flex flex-1 cursor-pointer items-center">
                <div class="mr-2 flex flex-shrink-0 justify-center">
                  <i class="material-icons-outlined">format_list_bulleted</i>
                </div>
                <div
                  class="flex flex-1 items-center space-x-2"
                  @click="navigateToView(block)"
                >
                  <div class="font-medium">
                    {{ block.name }}
                  </div>
                </div>

                <div v-if="!isBlockReorder" class="items-right flex">
                  <div
                    v-if="getActiveRound && getActiveRound.id == block.id"
                    class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
                  >
                    Active
                  </div>
                </div>

                <DropdownMenu v-if="!isBlockReorder" ref="dropdown">
                  <template slot="control" slot-scope="slotProps">
                    <button
                      @click.stop="handleDropdown(slotProps)"
                      class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                    >
                      <i class="material-icons-outlined">more_vert</i>
                    </button>
                  </template>

                  <template slot="menu">
                    <button
                      @click.prevent="navigateToRenameRound(block)"
                      class="dropdownListItem"
                    >
                      Rename
                    </button>
                    <button
                      @click.prevent="navigateToDeleteRound(block)"
                      :disabled="
                        getActiveRound && getActiveRound.id == block.id
                      "
                      class="dropdownListItem"
                    >
                      Delete
                    </button>
                  </template>
                </DropdownMenu>
                <div v-else class="flex h-10 w-10 items-center rounded-full">
                  <i class="material-icons-outlined text-blue-500"
                    >unfold_more</i
                  >
                </div>
              </div>
            </div>
          </div>

          <button
            v-if="!isBlockReorder"
            @click="navigateToNewRound"
            class="btn-blue"
          >
            Add round
          </button>
        </template>
        <!-- end of main -->

        <!-- sub content -->
        <template v-else>
          <!-- sub content navigation -->
          <div class="relative flex items-center justify-center py-3">
            <button
              class="z-10 mr-auto flex h-10 w-10 items-center justify-center rounded-full px-2 hover:bg-grey-200"
              @click.prevent="navigateBack"
            >
              <i class="material-icons-outlined text-3xl text-black"
                >arrow_back</i
              >
            </button>
            <h3 v-if="selectedBlock" class="absolute text-xl font-bold">
              {{ selectedBlock.name }}
            </h3>
            <h3 v-else class="absolute text-xl font-bold">
              {{ upperFirst(section) }}
            </h3>

            <button
              v-if="section == 'view_question'"
              class="ml-auto px-2 py-1 text-sm text-blue-500 hover:text-blue-800"
              @click="navigateToEditQuestion(selectedQuestion)"
            >
              Edit
            </button>

            <div v-if="section == 'view_round'">
              <button
                v-if="!isQuestionReorder"
                @click="toggleQuestionReorder"
                class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
              >
                <i class="material-icons-outlined text-3xl">toc</i>
              </button>
              <div
                v-else-if="isQuizBlockQuestionReorderSaving"
                class="flex h-10 w-10 items-center justify-center"
              >
                <i class="material-icons-outlined spin text-3xl">sync</i>
              </div>
              <div
                v-else-if="
                  isQuestionReorder && !isQuizBlockQuestionReorderSaving
                "
                class="flex items-center justify-between"
              >
                <button
                  @click="isQuestionReorder = false"
                  class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">close</i>
                </button>
                <button
                  v-if="!isQuizBlockQuestionReorderSaving"
                  @click="toggleQuestionReorder"
                  class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">check</i>
                </button>
              </div>
            </div>
          </div>
          <!-- sub content navigation -->

          <!-- new round -->
          <template v-if="section == 'new_round'">
            <form class="px-3" @submit.prevent="handleAddNewRound">
              <TextField
                name="new_round_name"
                label="Round name"
                v-model="newRoundName"
                :required="true"
                :errors="errors"
                :autofocus="true"
              />

              <button @click="handleAddNewRound" class="btn-blue">
                Add round
              </button>
            </form>
          </template>

          <!-- rename round -->
          <template v-if="section == 'rename_round'">
            <form class="px-3" @submit.prevent="handleUpdateRound">
              <TextField
                name="rename_round_name"
                label="Round name"
                v-model="updateRoundName"
                :required="true"
                :errors="errors"
                :autofocus="true"
              />

              <button @click="handleUpdateRound" class="btn-blue mt-10">
                Update
              </button>
            </form>
          </template>

          <!-- delete round -->
          <template v-if="section == 'delete_round'">
            <!-- sub content navigation -->
            <div class="px-3">
              <div class="text-center">
                Deleting a round also deletes all questions in that round. All
                submitted data for the round will be lost. Are you sure you want
                to delete this round?
              </div>

              <button @click="handleDeleteRound" class="btn-red mt-10">
                Delete round
              </button>
            </div>
          </template>

          <!-- view block -->
          <template v-if="section == 'view_round'">
            <!-- sub content navigation -->
            <div class="flex flex-col overflow-auto lg:flex-grow">
              <div
                v-if="
                  !selectedBlock.questions ||
                  selectedBlock.questions.length == 0
                "
                class="flex flex-1 justify-center text-grey-400"
              >
                Add new question to this round.
              </div>
              <div
                v-for="(question, index) in getQuestions"
                :key="index"
                @click.self="navigateToQuestionDetail(question)"
                :id="question.id"
                :draggable="isQuestionReorder"
                @dragstart="handleStartDragQuestion(question)"
                @dragover="handleDragOverQuestion"
                @dragend="handleStopDragQuestion"
                class="flex space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
              >
                <div
                  v-if="
                    isQuestionReorder &&
                    isQuizQuestionDragging &&
                    selectedQuestion &&
                    selectedQuestion.id == question.id
                  "
                  class="flex flex-1 items-center justify-center rounded border border-dashed border-grey-400 py-4"
                >
                  <i class="material-icons-outlined mx-2 text-grey-400"
                    >vertical_align_bottom</i
                  >
                  Drop here
                </div>
                <div
                  v-else
                  class="flex flex-1 cursor-pointer items-start justify-between"
                >
                  <div
                    class="flex flex-1 items-start space-x-3 overflow-hidden"
                    @click="navigateToQuestionDetail(question)"
                  >
                    <div class="font-medium">Q{{ index + 1 }}</div>
                    <div class="flex flex-col">
                      <div class="flex-1">
                        {{ question.text }}
                      </div>
                      <div class="flex space-x-1 pt-2 text-xs">
                        <div class="rounded bg-grey-200 px-2 text-grey-400">
                          {{ getType(question.questionTypeCode) }}
                        </div>
                        <div class="rounded bg-grey-200 px-2 text-grey-400">
                          {{ question.timeLimitSeconds }}s
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!isQuestionReorder" class="items-right flex">
                    <div
                      v-if="question.id == quiz.activeQuestionId"
                      class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
                    >
                      Active
                    </div>
                  </div>

                  <DropdownMenu v-if="!isQuestionReorder" ref="dropdown">
                    <template slot="control" slot-scope="slotProps">
                      <button
                        @click.stop="handleDropdown(slotProps)"
                        class="flex h-10 w-10 items-start justify-center rounded-full hover:bg-grey-200"
                      >
                        <i class="material-icons-outlined mt-2">more_vert</i>
                      </button>
                    </template>

                    <template slot="menu">
                      <DropdownInlineConfirmButton
                        v-if="question.id == quiz.activeQuestionId"
                        @callback="handleActivateQuestion(question.id, true)"
                        confirmClass="text-green-500 hover:text-green-800 hover:bg-green-100"
                      >
                        Restart timer
                      </DropdownInlineConfirmButton>
                      <DropdownInlineConfirmButton
                        v-else
                        @callback="handleActivateQuestion(question.id)"
                        confirmClass="text-green-500 hover:text-green-800 hover:bg-green-100"
                        >Jump to question
                      </DropdownInlineConfirmButton>
                      <button
                        @click.prevent="navigateToEditQuestion(question)"
                        class="dropdownListItem"
                      >
                        Edit
                      </button>
                      <DropdownInlineConfirmButton
                        :disabled="
                          question.id == quiz.activeQuestionId &&
                          countDownTime > 0
                        "
                        @callback="handleResetQuestion(question.id)"
                        confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
                      >
                        Reset question answers
                      </DropdownInlineConfirmButton>
                      <button
                        @click.prevent="navigateToDeleteQuestion(question)"
                        :disabled="question.id == quiz.activeQuestionId"
                        class="dropdownListItem"
                      >
                        Delete
                      </button>
                    </template>
                  </DropdownMenu>
                  <div v-else class="flex h-10 w-10 items-center rounded-full">
                    <i class="material-icons-outlined text-blue-500"
                      >unfold_more</i
                    >
                  </div>
                </div>
              </div>
            </div>

            <button
              v-if="!isQuestionReorder"
              @click="navigateToNewQuestion"
              class="btn-blue"
            >
              Add question
            </button>
          </template>

          <!-- delete question -->
          <template v-if="section == 'delete_question'">
            <!-- sub content navigation -->
            <div class="px-3">
              <div class="text-center text-lg font-bold">
                {{ selectedQuestion.text }}
              </div>
              <div class="mt-10 text-center">
                All submitted data for this question will be lost. Are you sure
                you want to delete this question?
              </div>

              <button @click="handleDeleteQuestion" class="btn-red mt-10">
                Delete question
              </button>
            </div>
          </template>

          <!-- new/edit question -->
          <template v-if="section == 'new_question'">
            <form
              class="flex flex-col overflow-auto px-3 py-2"
              @submit.prevent="handleAddNewQuestion"
            >
              <TextareaField
                name="new_question_text"
                class="mb-4"
                label="Type your question"
                v-model="questionForm.text"
                :required="true"
                :errors="errors"
                :autofocus="true"
              />

              <SelectField
                name="new_question_type"
                :disabled="!isNewQuestion"
                class="mb-4"
                label="Type"
                v-model="questionForm.questionTypeCode"
                :options="questionTypeOptions"
                :required="true"
                :errors="errors"
              />

              <SelectField
                name="new_question_time"
                class="mb-4"
                label="Time limit"
                v-model="questionForm.timeLimitSeconds"
                :options="questionTimeOptions"
                :required="true"
                :errors="errors"
              />

              <div
                v-if="questionForm.questionTypeCode == 'mc'"
                class="overflow-y-scroll"
              >
                <div class="py-4 text-grey-400">Answer options</div>
                <div
                  v-for="(option, index) in questionForm.options"
                  :key="index"
                  class="flex items-center justify-between space-x-2 pb-6"
                >
                  <div class="flex-1">
                    <TextField
                      :name="'new_question_option_' + index"
                      class="-mb-0"
                      :label="'Option ' + (index + 1)"
                      v-model="option.name"
                      :required="true"
                      :errors="errors"
                    />
                  </div>
                  <div class="w-24">
                    <TextField
                      :name="'new_question_option_score_' + index"
                      class="-mb-0"
                      label="Score"
                      v-model="option.score"
                      :required="true"
                      :errors="errors"
                    />
                  </div>
                  <div class="flex">
                    <i
                      class="material-icons-outlined cursor-pointer text-3xl text-grey-400 hover:text-red-700"
                      @click="removeOption(index)"
                    >
                      close
                    </i>
                  </div>
                </div>
                <button
                  @click="addOption"
                  class="mb-4 flex w-full items-center justify-center rounded-full border border-dashed border-grey-400 py-3 hover:bg-grey-200"
                >
                  Add option
                </button>
              </div>

              <button type="submit" class="btn-blue">
                {{ isNewQuestion ? 'Save' : 'Update' }}
              </button>
            </form>
          </template>

          <!-- view question -->
          <template v-if="section == 'view_question'">
            <!-- sub content navigation -->
            <div class="overflow-y-auto px-3">
              <div class="my-3">{{ selectedQuestion.text }}</div>
              <img
                v-if="selectedQuestion.image"
                class="my-3 w-full rounded bg-grey-100"
                :src="selectedQuestion.image"
              />

              <div class="my-3 flex items-center justify-between">
                Type:
                <strong>{{
                  getType(selectedQuestion.questionTypeCode)
                }}</strong>
              </div>
              <div class="my-3 flex items-center justify-between">
                Time:
                <strong>{{ selectedQuestion.timeLimitSeconds }} Secs</strong>
              </div>

              <div v-if="selectedQuestion.questionTypeCode == 'mc'">
                <div class="my-3">Options:</div>
                <div
                  v-for="option in selectedQuestion.options"
                  :key="option.id"
                  class="my-3 ml-4 flex justify-between text-grey-800"
                  :class="{ 'font-bold': option.score > 0 }"
                >
                  <span>{{ option.name }}</span>
                  <span>Score: {{ option.score }}</span>
                </div>
              </div>

              <div
                v-if="selectedQuestion.answered"
                class="mt-4 flex items-center justify-between border-t border-grey-200 py-4"
              >
                Answered:
                <strong>{{ selectedQuestion.answered }}</strong>
              </div>
            </div>
          </template>
        </template>
      </template>

      <template v-if="sltContent == 'leaderboard'">
        <div class="flex h-full flex-col">
          <template v-if="isResetConfirmation">
            <!-- sub content navigation -->
            <div class="flex py-3">
              <button
                class="z-10 flex h-10 w-10 items-center justify-center rounded-full px-2 hover:bg-grey-200"
                @click.prevent="isResetConfirmation = false"
              >
                <i class="material-icons-outlined text-3xl text-black"
                  >arrow_back</i
                >
              </button>
              <h3
                class="-ml-10 flex w-full items-center justify-center text-xl font-bold"
              >
                Reset Confirmation
              </h3>
            </div>
            <!-- sub content navigation -->

            <div class="px-2 py-4">
              <div class="pb-12 text-center">
                Resetting the quiz will remove all answers and reset the
                leaderboard points. Are you sure you want to reset the quiz?
              </div>
              <button @click.prevent="resetQuiz" class="btn-red">
                Reset quiz
              </button>
            </div>
          </template>
          <template v-else>
            <div
              class="flex flex-1 flex-grow flex-col justify-start overflow-auto"
            >
              <div
                v-for="item in quizLeaderboard"
                :key="item.attendeeId"
                class="flex items-center justify-between rounded px-4 py-4"
              >
                <div
                  class="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white"
                  :class="{
                    'text-sm': item.rank > 9 && item.rank < 99,
                    'text-xs': item.rank > 99,
                  }"
                >
                  P{{ item.rank }}
                </div>
                <div class="flex flex-1 items-center">
                  <div class="w-10">
                    <div
                      v-if="getAttendeePositionChange(item) > 0"
                      class="mr-2 flex items-center text-green-500"
                    >
                      <i class="material-icons-outlined text-green-500"
                        >arrow_drop_up</i
                      >{{ getAttendeePositionChange(item) }}
                    </div>
                    <div
                      v-if="getAttendeePositionChange(item) < 0"
                      class="mr-2 flex items-center text-red-500"
                    >
                      <i class="material-icons-outlined text-red-500"
                        >arrow_drop_down</i
                      >{{ -getAttendeePositionChange(item) }}
                    </div>
                  </div>
                  {{ item.firstName }} {{ item.lastName }}
                </div>
                <div class="flex flex-col text-right">
                  <div class="text-xl text-black">
                    {{
                      item.totalScore > 0
                        ? '+' + item.totalScore
                        : item.totalScore
                    }}
                  </div>
                  <div class="flex items-center text-right text-xs opacity-50">
                    <i class="material-icons-outlined mr-1 text-xs">timer</i>
                    {{ timeTakenSec(item.timeTakenMs) }}
                  </div>
                </div>
              </div>
            </div>

            <button @click.prevent="navigateToResetQuiz" class="btn-red">
              Reset all answers
            </button>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import upperFirst from 'lodash/upperFirst';

dayjs.extend(isToday);
dayjs.extend(utc);

import SelectField from '@/components/SelectField';
import TextField from '@/components/TextField';
import TextareaField from '@/components/TextareaField';
import errors from '@/mixins/errors';
import UpgradePlanLink from '@/components/UpgradePlanLink';
import DropdownMenu from '@/components/DropdownMenu';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';

export default {
  name: 'StudioPanelQuiz',
  mixins: [errors],
  components: {
    TextField,
    UpgradePlanLink,
    DropdownMenu,
    SelectField,
    TextareaField,
    DropdownInlineConfirmButton,
  },
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
    },
  },
  data: () => ({
    section: null,
    search: null,
    replyContent: '',
    selectedBlock: null,
    selectedBlockIndex: null,
    selectedQuestion: null,
    countDownTime: null,
    countDownInterval: null,
    newMessagesCount: 0,
    isNewMessages: false,
    autoScroll: true,
    newRoundName: null,
    questionTypeOptions: [
      { id: 'mc', label: 'Multiple choice' },
      //{id: 'free', label: 'Text'}
    ],
    questionTimeOptions: [
      { id: 15, label: '15 seconds' },
      { id: 20, label: '20 seconds' },
      { id: 25, label: '25 seconds' },
      { id: 30, label: '30 seconds' },
      { id: 45, label: '45 seconds' },
      { id: 60, label: '60 seconds' },
      { id: 120, label: '120 seconds' },
    ],
    sltContent: 'question',
    questionForm: {
      questionTypeCode: null,
      timeLimitSeconds: null,
      text: null,
      options: [],
    },
    isNewQuestion: true,
    isResetConfirmation: false,
    currentLiveViewers: 0,
    updateRoundName: null,
    dragoverBlock: null,
    isBlockReorder: false,
    reorderBlocks: [],
    isQuizBlockDragging: false,
    currentOrderBlockTargetId: null,
    isQuestionReorder: false,
    reorderQuestions: [],
    isQuizQuestionDragging: false,
    currentOrderQuestionTargetId: null,
  }),
  beforeDestroy() {
    clearInterval(this.countDownInterval);
  },
  computed: {
    ...mapState([
      'quiz',
      'quizLeaderboard',
      'previousQuizLeaderboard',
      'stats',
      'isQuizBlockReorderSaving',
      'isQuizBlockQuestionReorderSaving',
    ]),

    filteredBlocks() {
      if (this.isBlockReorder) {
        return this.reorderBlocks;
      } else {
        return this.quiz.blocks
          .filter((block) => {
            if (this.search) {
              return (
                block.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
                -1
              );
            } else {
              return block;
            }
          })
          .sort((a, b) => {
            return +a.sortId - +b.sortId > 0 ? -1 : 1;
          });
      }
    },

    getQuestions() {
      if (this.isQuestionReorder) {
        return this.reorderQuestions;
      } else {
        return this.quiz.blocks[this.selectedBlockIndex].questions;
      }
    },

    getActiveRound() {
      if (this.quiz.activeQuestionId) {
        return this.quiz.blocks.find((block) => {
          if (block.questions) {
            return block.questions.find(
              (q) => q.id == this.quiz.activeQuestionId
            );
          } else {
            return false;
          }
        });
      }

      return null;
    },

    getActiveQuestion() {
      return (
        this.getActiveRound?.questions.find(
          (q) => q.id == this.quiz.activeQuestionId
        ) || null
      );
    },

    getActiveQuestionIndex() {
      if (this.getActiveRound) {
        return this.getActiveRound.questions.findIndex(
          (q) => q.id == this.quiz.activeQuestionId
        );
      }

      return -1;
    },

    getNextRound() {
      var cbIndex = -1;
      if (this.quiz.activeQuestionId) {
        cbIndex = this.quiz.blocks.findIndex((block) => {
          return block.questions.find(
            (q) => q.id == this.quiz.activeQuestionId
          );
        });
      }

      return this.quiz.blocks[cbIndex + 1] || null;
    },

    getNextQuestionIndex() {
      if (this.quiz.activeQuestionId) {
        if (
          this.getActiveRound.questions.length - 1 >
          this.getActiveQuestionIndex
        ) {
          return this.getActiveQuestionIndex + 1;
        }
      }

      return -1;
    },

    getNextQuestion() {
      if (this.quiz.activeQuestionId) {
        return this.getActiveRound.questions[this.getNextQuestionIndex];
      }

      return null;
    },

    getNextQuestionId() {
      if (this.quiz.activeQuestionId) {
        var cbIndex = this.getActiveRound.questions?.findIndex(
          (q) => q.id == this.quiz.activeQuestionId
        );
        return this.getActiveRound.questions[cbIndex + 1]?.id || null;
      }

      return null;
    },

    getNextRoundQuestionId() {
      return this.getNextRound?.questions[0]?.id || null;
    },

    showNextQuestion() {
      return (
        this.getActiveRound &&
        this.getActiveRound.questions &&
        this.getActiveRound.questions.length - 1 > this.getActiveQuestionIndex
      );
    },
  },
  watch: {
    'isActive': function (val) {
      if (val == true) {
        this.section = null;
      }
    },
    'isFocus': function (newValue, oldValue) {
      if (oldValue == false && newValue == true && this.$refs.scroll) {
        this.newMessagesCount = 0;

        this.$emit('newMessagesNotification', this.newMessagesCount);

        this.autoScroll = true;
        this.scrollToBottom();
      }
    },
    'quiz.activeQuestionExpiration': function () {
      this.questionCountDown();
    },
    'quiz.isRunning': function (val) {
      if (!val) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      clearInterval(this.countDownInterval);
      this.countDownTime = 0;
    },
    handleSelectContent(type) {
      this.sltContent = type;
    },
    questionCountDown() {
      if (
        !this.quiz.activeQuestionExpiration ||
        dayjs(this.quiz.activeQuestionExpiration).unix() < dayjs.utc().unix()
      ) {
        this.countDownTime = 0;
        return;
      }

      clearInterval(this.countDownInterval);
      this.countDownTime =
        dayjs(this.quiz.activeQuestionExpiration).unix() - dayjs.utc().unix();
      this.countDownInterval = setInterval(() => {
        this.countDownTime =
          dayjs(this.quiz.activeQuestionExpiration).unix() - dayjs.utc().unix();

        if (this.countDownTime <= 0) {
          clearInterval(this.countDownInterval);
        }
      }, 1000);
    },
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    hideQuiz() {
      this.reset();
      this.$store.dispatch('TOGGLE_QUIZ_STATE');
      if (this.quiz.screenStateCode == 'leaderboard') {
        this.toggleDisplay();
      }
    },
    navigateBack() {
      switch (this.section) {
        case 'delete_question':
        case 'new_question':
        case 'view_question':
          this.section = 'view_round';
          break;
        default:
          this.section = null;
          break;
      }

      this.search = null;
      this.isBlockReorder = false;
      this.isQuestionReorder = false;
    },
    navigateToNewRound() {
      this.newRoundName = null;
      this.section = 'new_round';
    },
    navigateToNewQuestion() {
      //reset inputs
      this.questionForm = {
        questionTypeCode: 'mc',
        timeLimitSeconds: 30,
        text: null,
        options: [],
      };

      this.isNewQuestion = true;

      this.section = 'new_question';
    },
    navigateToQuestionDetail(question) {
      if (this.isQuestionReorder) return;

      this.selectedQuestion = question;
      this.section = 'view_question';
    },
    navigateToView(block) {
      if (this.isBlockReorder) return;

      this.selectedBlockIndex = this.quiz.blocks.findIndex(
        (b) => b.id == block.id
      );
      this.selectedBlock = block;
      this.section = 'view_round';
    },
    navigateToDeleteRound(block) {
      this.selectedBlock = block;
      this.section = 'delete_round';
    },
    navigateToRenameRound(block) {
      this.selectedBlock = block;
      this.updateRoundName = block.name;
      this.section = 'rename_round';
    },
    navigateToDeleteQuestion(question) {
      this.selectedQuestion = question;
      this.section = 'delete_question';
    },
    handleAddNewRound() {
      this.$store.dispatch('CREATE_NEW_QUIZ_ROUND', {
        name: this.newRoundName,
      });
      this.section = null;
    },
    handleDeleteRound() {
      this.$store.dispatch('DELETE_QUIZ_ROUND', {
        id: this.selectedBlock.id,
      });
      this.section = null;
    },
    handleUpdateRound() {
      this.$store.dispatch('UPDATE_QUIZ_ROUND', {
        id: this.selectedBlock.id,
        name: this.updateRoundName,
      });
      this.section = null;
    },
    handleDeleteQuestion() {
      this.$store.dispatch('DELETE_QUIZ_QUESTION', {
        blockId: this.selectedBlock.id,
        id: this.selectedQuestion.id,
      });

      setTimeout(() => {
        //update selected block
        this.selecteQuestion = null;
        this.selectedBlock = this.quiz.blocks.find(
          (block) => block.id == this.selectedBlock.id
        );
        this.section = 'view_round';
      }, 200);
    },
    addOption() {
      this.questionForm.options.push({
        name: null,
        score: 0,
      });
    },
    removeOption(index) {
      this.questionForm.options.splice(index, 1);
    },
    handleAddNewQuestion() {
      if (this.isNewQuestion) {
        this.$store.dispatch('CREATE_NEW_QUIZ_QUESTION', {
          ...this.questionForm,
          blockId: this.selectedBlock.id,
        });
      } else {
        this.$store.dispatch('EDIT_QUIZ_QUESTION', {
          ...this.questionForm,
          blockId: this.selectedBlock.id,
          questionId: this.selectedQuestion.id,
        });
      }

      setTimeout(() => {
        //update selected block
        this.selectedBlock = this.quiz.blocks.find(
          (block) => block.id == this.selectedBlock.id
        );
        this.section = 'view_round';
      }, 200);
    },
    navigateToEditQuestion(question) {
      this.questionForm = question;
      this.selectedQuestion = question;
      this.isNewQuestion = false;

      this.section = 'new_question';
    },
    async handleActivateQuestion(id = null, reset = false) {
      clearInterval(this.countDownInterval);
      this.countDownTime = null;
      this.currentLiveViewers = this.stats.liveViewers;

      if (this.quiz.screenStateCode == 'leaderboard') {
        await this.toggleDisplay();
      }

      if (!this.quiz.isRunning) {
        await this.toggleQuizState();
      }

      this.$refs.dropdown.map((dd) => dd.close());

      if (!id) {
        //start
        if (
          this.quiz.blocks.length > 0 &&
          this.quiz.blocks[0]?.questions.length > 0
        ) {
          await this.$store.dispatch('ACTIVATE_QUIZ_QUESTION', {
            blockId: this.quiz.blocks[0].id,
            id: this.quiz.blocks[0].questions[0].id,
            reset: false,
          });
        }
      } else {
        await this.$store.dispatch('ACTIVATE_QUIZ_QUESTION', {
          blockId: this.getQuestionBlockById(id)?.id,
          id: id,
          reset: reset,
        });
      }
    },
    handleDeactiveQuestion() {
      this.$store.dispatch('DEACTIVATE_QUIZ_QUESTION');
    },
    handleResetQuestion(id, index) {
      this.$refs.dropdown.map((dd) => dd.close());

      this.$store.dispatch('RESET_QUIZ_QUESTION', {
        blockId: this.selectedBlock.id,
        id: id,
        blockName: this.selectedBlock.name,
        roundName: 'Q' + (index + 1),
      });
    },
    getType(type) {
      switch (type) {
        case 'mc':
          return 'Multiple choice';
        //case 'free':
        //return 'Text';
        default:
          return type;
      }
    },
    async toggleQuizState() {
      await this.$store.dispatch('TOGGLE_QUIZ_STATE');
    },
    async toggleDisplay() {
      await this.$store.dispatch('TOGGLE_QUIZ_DISPLAY');
    },
    upperFirst(value) {
      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },

    timeTakenSec(timeTakenMs) {
      return Math.round(timeTakenMs / 1000) + ' sec';
    },

    getAttendeePositionChange(attendee) {
      const pa = this.previousQuizLeaderboard.find(
        (pa) => pa.attendeeId == attendee.attendeeId
      );
      if (pa) {
        return pa.rank - attendee.rank;
      } else {
        return 0;
      }
    },

    resetQuiz() {
      this.$store.dispatch('RESET_QUIZ');
      this.isResetConfirmation = false;
    },

    getQuestionBlockById(id) {
      return this.quiz.blocks.find((block) => {
        return block.questions?.find((q) => q.id == id);
      });
    },

    navigateToResetQuiz() {
      this.isResetConfirmation = true;
    },

    handleStartDragBlock(block) {
      this.selectedBlock = block;

      setTimeout(() => {
        this.isQuizBlockDragging = true;
      }, 200);
    },

    handleDragOverBlock(e) {
      e.preventDefault();

      if (
        e.target &&
        e.target.id &&
        e.target.id !== '' &&
        +e.target.id != this.selectedBlock.id &&
        +e.target.id != this.currentOrderBlockTargetId
      ) {
        this.currentOrderBlockTargetId = +e.target.id;

        const cIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.selectedBlock.id
        );
        const tIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.currentOrderBlockTargetId
        );

        this.reorderBlocks.splice(
          tIndex,
          0,
          this.reorderBlocks.splice(cIndex, 1)[0]
        );
      }
    },

    handleStopDragBlock() {
      this.isQuizBlockDragging = false;
    },

    toggleBlockReorder() {
      this.isBlockReorder = !this.isBlockReorder;

      if (this.isBlockReorder) {
        this.reorderBlocks = [...this.quiz.blocks];
      } else {
        //submit order
        const list = this.reorderBlocks.map((b) => b.id);
        this.$store.dispatch('REORDER_QUIZ_ROUND', list);
      }
    },

    handleStartDragQuestion(question) {
      this.selectedQuestion = question;
      setTimeout(() => {
        this.isQuizQuestionDragging = true;
      }, 200);
    },

    handleDragOverQuestion(e) {
      e.preventDefault();

      if (
        e.target &&
        e.target.id &&
        e.target.id !== '' &&
        +e.target.id != this.selectedQuestion.id &&
        +e.target.id != this.currentOrderQuestionTargetId
      ) {
        this.currentOrderQuestionTargetId = +e.target.id;

        const cIndex = this.reorderQuestions.findIndex(
          (block) => block.id == this.selectedQuestion.id
        );
        const tIndex = this.reorderQuestions.findIndex(
          (block) => block.id == this.currentOrderQuestionTargetId
        );

        this.reorderQuestions.splice(
          tIndex,
          0,
          this.reorderQuestions.splice(cIndex, 1)[0]
        );
      }
    },

    handleStopDragQuestion() {
      this.isQuizQuestionDragging = false;
    },

    toggleQuestionReorder() {
      this.isQuestionReorder = !this.isQuestionReorder;

      if (this.isQuestionReorder) {
        this.reorderQuestions = [
          ...this.quiz.blocks[this.selectedBlockIndex].questions,
        ];
      } else {
        //submit order
        const list = this.reorderQuestions.map((b) => b.id);
        this.$store.dispatch('REORDER_QUIZ_ROUND_QUESTIONS', {
          blockId: this.selectedBlock.id,
          list: list,
        });
      }
    },
  },
};
</script>
