<template>
  <div class="w-full">
    <ListView
      :isLoading="isLoading"
      :items="filterFroms"
      :search="true"
      :sortName="true"
      :sortId="false"
      :filterOptions="[
        { id: 'survey', label: 'Survey' },
        { id: 'registration', label: 'Registration' },
      ]"
      @onNewItem="handleAddNewItem"
    >
      <!-- template for tags which position under title -->
      <template #tags="{ item: form }">
        <div class="rounded bg-grey-500 px-1 text-xs uppercase text-white">
          {{ form.type }}
        </div>
      </template>
      <!-- end of tags template -->

      <!-- template for dropdown menu -->
      <!-- manage custom function in current component -->
      <template #item="{ item: form }">
        <RouterLink
          :to="'/settings/templates/' + form.id"
          class="block cursor-pointer rounded p-2 hover:bg-grey-100"
          >Edit
        </RouterLink>

        <DropdownInlineConfirmButton
          @callback="handleDeleteForm(form)"
          confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
        >
          Delete
          <i class="material-icons-outlined pl-1 text-grey-400">delete</i>
        </DropdownInlineConfirmButton>
      </template>
      <!-- end of dropdown menu template-->
    </ListView>

    <!-- new form modal-->
    <Modal width="400" ref="formDialog">
      <template slot="content">
        <h1
          class="mb-4 border-b border-grey-200 pb-4 text-center text-xl font-bold"
        >
          New form template
        </h1>

        <div class="flex items-start">
          <div class="flex-1">
            <MdTextField
              class="flex-1"
              label="Name"
              name="name"
              v-model="form.name"
              :errors="errors"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                Add a name for this form template.
              </div>
            </template>
          </TooltipModal>
        </div>

        <div class="flex items-start">
          <div class="flex-1">
            <MdSelectField
              class="flex-1"
              label="Type"
              name="type"
              v-model="form.type"
              :errors="errors"
              :options="options"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                <div>
                  Select the template type to determine where it can be used.
                </div>
                <div>
                  <span class="font-bold">Registration:</span> form can be
                  assigned for event registration.
                </div>
                <div>
                  <span class="font-bold">Survey:</span> form can be assigned as
                  an event survey.
                </div>
                <div class="flex items-center justify-center">
                  <a
                    class="pr-1 text-blue-500"
                    href="https://support.joinin.live/portal/en/kb/articles/form-templates"
                    target="_blank"
                    >More information</a
                  >
                  <i class="material-icons-outlined text-icon-sm text-blue-500"
                    >help_outline</i
                  >
                </div>
              </div>
            </template>
          </TooltipModal>
        </div>

        <div class="flex items-start">
          <div class="flex-1">
            <MdTextareaField
              class="flex-1"
              label="Instructions"
              name="description"
              v-model="form.description"
              rows="5"
              :errors="errors"
            />
          </div>

          <TooltipModal>
            <template slot="control">
              <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                <i class="material-icons-outlined text-icon-sm">info</i>
              </button>
            </template>

            <template slot="content">
              <div class="flex flex-col space-y-4">
                Instructions will be shown to attendees whilst completing the
                form.
              </div>
            </template>
          </TooltipModal>
        </div>

        <button
          @click="handleSaveForm"
          class="w-full rounded-full border-2 border-blue-500 bg-blue-500 px-6 py-3 text-xl font-bold text-white"
          :disabled="isSaving"
        >
          <i
            v-if="isSaving"
            class="material-icons-outlined spin mr-4 text-white"
            >loop</i
          >
          <span v-if="isSaving">Saving</span>
          <span v-else>Save</span>
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import errors from '@/mixins/errors';
import api from '@/services/api';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';
import Modal from '@/components/Modal';
import TooltipModal from '@/components/TooltipModal';
import MdSelectField from '@/components/MdSelectField';
import ListView from '@/components/ui/ListView';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';

export default {
  mixins: [errors],
  name: 'FormTemplates',
  components: {
    MdTextField,
    MdTextareaField,
    Modal,
    TooltipModal,
    MdSelectField,
    ListView,
    DropdownInlineConfirmButton,
  },
  data: () => ({
    isLoading: false,
    isSaving: false,
    s: null,
    forms: [],
    form: {
      name: null,
      description: null,
      type: null,
    },
    selectedForm: null,
    options: [
      { id: 'survey', label: 'Survey' },
      { id: 'registration', label: 'Registration' },
    ],
  }),
  mounted() {
    this.init();
  },
  computed: {
    filterFroms() {
      return this.forms.filter((f) => {
        if (this.s) {
          return f.name.toLowerCase().match(this.s.toLowerCase());
        } else {
          return f;
        }
      });
    },
  },
  watch: {
    'form.type': function (val) {
      if (val == 'registration') {
        this.form.description = 'Please complete the form to register.';
      } else if (val == 'survey') {
        this.form.description = 'Share your feedback to help us improve.';
      }
    },
  },
  methods: {
    init() {
      this.isLoading = true;

      api.get('/forms/templates').then((response) => {
        this.isLoading = false;
        this.forms = response.data;
      });
    },
    handleDeleteForm(form) {
      api.delete(`/forms/templates/${form.id}`).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const cIndex = this.forms.findIndex((f) => f.id == form.id);
          if (cIndex !== -1) {
            this.forms.splice(cIndex, 1);
          }

          this.$store.commit('MESSAGE', `Form ${form.name} has been deleted.`);
        }
      });
    },
    handleAddNewItem() {
      this.form = {
        name: null,
        description: null,
        type: null,
      };

      this.$refs.formDialog.show();
    },
    handleSaveForm() {
      this.isSaving = true;
      api.post('/forms/templates', this.form).then((response) => {
        this.isSaving = false;
        if (response.status >= 200 && response.status < 300) {
          this.forms.push({
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            type: response.data.type,
          });
        }

        this.$refs.formDialog.close();
      });
    },
  },
};
</script>
