<template>
  <div
    id="notifications"
    class="fixed left-3 top-16 flex flex-col items-start opacity-80"
    style="z-index: 999"
  >
    <!-- preload icons -->
    <i class="material-icons-outlined hidden text-white opacity-0"
      >check_circle</i
    >
    <i class="material-icons-outlined hidden text-white opacity-0">cancel</i>
    <i class="material-icons-outlined hidden text-white opacity-0">error</i>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
  data: () => ({
    removeNotificationThreshold: 3, //5 secs
    clearNotificationInterval: null,
  }),
  mounted() {
    this.clearNotificationInterval = setInterval(() => {
      var parent = document.getElementById('notifications');
      var list = document.querySelectorAll('.notification');
      if (list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          if (
            +list[i].getAttribute('data-timestamp') +
              this.removeNotificationThreshold * 1000 <
            Date.now()
          ) {
            parent.removeChild(list[i]);
          }
        }
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.clearNotificationInterval);
  },
};
</script>

<style>
i {
  color: inherit;
}

@keyframes ex {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.notify_progress {
  animation: ex 5s;
  animation-fill-mode: forwards;
}
</style>
