var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group w-full cursor-pointer text-blue-100",class:{
    'text-white': _vm.active && _vm.active === _vm.tab,
    'm-1 rounded-lg border-2 border-white': _vm.isTabGroup && _vm.visible,
  }},[(!_vm.isSubTab)?_c('div',{staticClass:"relative flex flex-col items-center px-2 py-6 text-grey-800",on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[(_vm.icon)?_c('i',{staticClass:"material-icons-outlined group-hover:text-white",class:(_vm.active && _vm.active === _vm.tab) || _vm.visible ? 'text-white' : 'text-grey-800'},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.isTabGroup)?_c('i',{staticClass:"material-icons-outlined absolute right-2 group-hover:text-white",class:_vm.visible ? 'expand text-white' : 'text-grey-800'},[_vm._v("chevron_right")]):_vm._e(),_c('span',{staticClass:"pt-1 text-center text-xs group-hover:text-white",class:{ 'text-white': (_vm.active && _vm.active === _vm.tab) || _vm.visible }},[_vm._v(_vm._s(_vm.label))]),(_vm.notifications > 0)?_c('div',{staticClass:"badge right-2 top-2"},[_vm._v(" "+_vm._s(_vm.notifications)+" ")]):_vm._e()]):_c('div',{staticClass:"relative w-full cursor-pointer bg-blue-100 py-4 hover:bg-blue-400 hover:text-white",class:{
      'border-l-4 border-grey-800 bg-blue-400': _vm.active && _vm.active === _vm.tab,
      'rounded-b': _vm.isLastSubTab,
    },on:{"click":function($event){$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{staticClass:"pt-1 text-center text-xs text-grey-800"},[_vm._v(_vm._s(_vm.label))]),(_vm.notifications > 0)?_c('div',{staticClass:"badge right-2 top-2"},[_vm._v(" "+_vm._s(_vm.notifications)+" ")]):_vm._e()])]),(_vm.visible)?_c('div',[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }