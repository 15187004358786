<template>
  <div>
    <div class="flex-1 pb-8">
      <div class="flex-1 text-grey-400">
        Attendee statistics for your event.
      </div>
    </div>

    <div class="flex flex-1 flex-col space-y-4">
      <!-- search controls -->
      <div class="flex justify-between">
        <div class="flex-1 pr-8">
          <MdSearchField
            name="search"
            label="Search attendees"
            v-model="search"
          />
        </div>
      </div>

      <div
        v-for="l in filteredLogins"
        :key="l.attendeeId"
        class="flex space-x-4 border-b border-grey-100 px-1 py-4 first:border-t hover:bg-grey-100"
      >
        <div class="flex-1 overflow-hidden">
          <div class="overflow-hidden overflow-ellipsis text-lg font-semibold">
            {{ formatName(l) }}
          </div>
          <div
            class="overflow-hidden overflow-ellipsis text-sm text-grey-400 md:text-base"
          >
            {{ l.email }}
          </div>
          <div
            v-for="i in l.intervals"
            :key="i.from.valueOf()"
            class="text-sm text-grey-400 md:text-base"
          >
            Attended
            {{ i.sessionType == 'stream' ? 'live stream' : 'on demand' }}:
            {{ formatDate(i.from) }} - {{ formatDate(i.to) }}
            &bull;
            {{ formatDuration(i) }}
          </div>

          <div
            v-if="l.intervals.length"
            class="mt-1 text-sm text-grey-500 md:text-base"
          >
            Total duration online: {{ formatTotalDuration(l.intervals) }}
          </div>
        </div>

        <div
          v-if="can('event-registration')"
          class="flex flex-col text-grey-800"
        >
          <RouterLink
            :to="
              '/events/' + event.eventShortcode + '/attendee/' + l.attendeeId
            "
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">chevron_right</i>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import MdSearchField from '@/components/MdSearchField';

dayjs.extend(isToday);

export default {
  props: ['event'],

  components: {
    MdSearchField,
  },

  data() {
    return {
      timer: null,
      now: new Date(),
      search: '',
    };
  },

  computed: {
    ...mapState(['loginData']),

    filteredLogins() {
      var searchString = this.search.toLowerCase();

      return filter(this.loginData, (attendee) => {
        if (attendee.firstName) {
          if (attendee.firstName.toLowerCase().indexOf(searchString) > -1) {
            return true;
          }
        }

        if (attendee.lastName) {
          if (attendee.lastName.toLowerCase().indexOf(searchString) > -1) {
            return true;
          }
        }

        if (attendee.company) {
          if (attendee.company.toLowerCase().indexOf(searchString) > -1) {
            return true;
          }
        }

        if (attendee.email) {
          if (attendee.email.toLowerCase().indexOf(searchString) > -1) {
            return true;
          }
        }

        return false;
      });
    },
  },

  methods: {
    formatName: ({ firstName, lastName }) => {
      return [firstName, lastName].filter((x) => x).join(' ');
    },

    formatDate(time) {
      if (time) {
        if (dayjs(time).isToday()) {
          return dayjs(time).format('h:mma');
        } else {
          return dayjs(time).format('h:mma, DD MMM YY');
        }
      } else {
        return 'now';
      }
    },

    formatDuration({ from, to }) {
      let hours = 0,
        minutes = 0;

      const d = (dayjs(to) || this.now) - dayjs(from);
      minutes = Math.floor(d / 60_000) % 60;
      hours = Math.floor(d / 3_600_000);

      return hours ? `${hours}hr ${minutes}min` : `${minutes}min`;
    },

    formatTotalDuration(list) {
      var total = 0,
        minutes = 0,
        hours = 0;
      list.map((i) => {
        const d = (dayjs(i.to) || this.now) - dayjs(i.from);
        total += d;
      });

      minutes = Math.floor(total / 60_000) % 60;
      hours = Math.floor(total / 3_600_000);

      return hours ? `${hours}hr ${minutes}min` : `${minutes}min`;
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timer);
    },
  },

  mounted() {
    this.startTimer();
    this.$store.dispatch('ENTER_LOGINS');
  },

  beforeRouteLeave(to, from, next) {
    this.stopTimer();
    this.$store.dispatch('LEAVE_LOGINS');
    next();
  },
};
</script>
