<template>
  <div
    class="page flex h-full w-full items-center justify-center bg-black text-white"
  >
    <div v-if="getMessage" class="flex h-full w-full flex-1 flex-col p-12">
      <VClamp
        :max-height="`90%`"
        :autoresize="true"
        class="scaleText h-full whitespace-pre-line font-bold"
        >{{ getMessage.content }}</VClamp
      >
      <div class="mt-6 flex flex-col justify-between border-t-4 lg:flex-row">
        <div class="scaleText py-2 font-bold text-grey-300">
          {{ formatName(getMessage) }}
        </div>
        <div class="scaleText py-2 text-grey-300">
          {{ formatDate(getMessage.createdAt) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VClamp from 'vue-clamp';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export default {
  name: 'EventOutputChat',
  components: {
    VClamp,
  },
  computed: {
    ...mapState(['chatMessages']),
    getMessage() {
      return this.chatMessages.find((m) => m.isOnScreen);
    },
  },
  methods: {
    formatDate(time) {
      if (dayjs(time).isToday()) {
        return dayjs(time).format('h:mma');
      } else {
        return dayjs(time).format('h:mma, DD MMM YY');
      }
    },
    formatName: ({ isAnonymous, firstName, lastName }) =>
      isAnonymous
        ? 'Anonymous'
        : [firstName, lastName].filter((x) => x).join(' ') || '(No name)',
  },
};
</script>

<style scoped>
.scaleText {
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1920 - 300)));
  line-height: 1.2;
}
</style>
