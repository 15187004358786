<template>
  <div
    class="flex w-full flex-grow justify-center"
    :class="{ 'overflow-hidden': this.$route.name == 'studio' }"
  >
    <div class="w-full flex-1" v-if="event">
      <RouterView
        :event="event"
        :jobId="event.id"
        :loadEvent="load"
        @event-updated="onEventUpdatedEvent"
        @set-rego-form-id="handleSetRegoFormId"
        @set-survey-form-id="handleSetSurveyFormId"
      />
    </div>
  </div>
</template>

<script>
import { extend } from 'lodash';
import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';

export default {
  data() {
    return {
      shortcode: this.$route.params.shortcode,
      event: null,
      loading: false,
    };
  },

  computed: {},

  mounted() {
    this.load();

    EventBus.$on('event-updated', this.onEventUpdatedEvent);
  },

  beforeDestroy() {
    EventBus.$off('event-updated', this.onEventUpdatedEvent);
  },

  methods: {
    onEventUpdatedEvent(event) {
      extend(this.event, event);
    },
    load() {
      this.loading = true;

      api.get('/jobs/settingsData/' + this.shortcode).then((response) => {
        this.loading = false;
        this.event = response.data;
        this.$store.commit('SET_JOB_CONFIG', response.data);
      });
    },
    handleSetRegoFormId(id) {
      if (this.event) {
        this.event.registrationFormId = id;
      }
    },
    handleSetSurveyFormId(id) {
      if (this.event) {
        this.event.surveyFormId = id;
      }
    },
  },
};
</script>
