<template>
  <div class="flex-col justify-start">
    <div class="relative flex items-start">
      <textarea
        ref="input"
        class="input h-12 max-h-40 w-full overflow-y-scroll rounded-3xl px-4 py-3 text-lg"
        :class="{ 'is-invalid rounded-b-none': errors.has(name) }"
        :id="name"
        :name="name"
        :placeholder="label"
        autocomplete="off"
        :value="value"
        :autofocus="autofocus"
        :required="required"
        @input="handleInput"
      ></textarea>
    </div>

    <ValidationErrors :errors="errors" :name="name" />
  </div>
</template>

<script>
import autosize from 'autosize';
import ValidationErrors from '@/components/ValidationErrors';

export default {
  name: 'TextareaField',

  components: {
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    value: {},
    label: { type: String },
    autofocus: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
  },

  watch: {
    value(newVal) {
      if (newVal == '') {
        this.$nextTick(() => {
          autosize.update(this.$refs.input);
        });
      }
    },
  },

  mounted() {
    autosize(this.$refs.input);
    if (this.autofocus) {
      this.$nextTick(() => this.$refs.input.focus());
    }
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.currentTarget.value);
    },
  },
};
</script>
