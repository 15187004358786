<template>
  <div class="flex w-full flex-col p-4 md:p-8">
    <form @submit.prevent="save()">
      <MdTextField
        name="title"
        label="Event title"
        v-model="form.title"
        autofocus
        :errors="errors"
      />

      <div class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            name="registration_type"
            label="Access type"
            v-model="form.registrationType"
            :errors="errors"
            :options="typeOptions"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              <div>
                <span class="font-bold">Public:</span> the event is publicly
                accessible by anyone with the link.
              </div>
              <div>
                <span class="font-bold">Password:</span> the event is protected
                by a password you set. Passwords are case SenSitivE.
              </div>
              <div>
                <span class="font-bold">Automatic:</span> attendees must
                register for the event by completing a registration form. Once
                complete they will be emailed login details immediately.
              </div>
              <div>
                <span class="font-bold">Manual:</span> attendees must register
                for the event by completing a registration form. Registrations
                must be manually accepted before login details are emailed.
              </div>
              <div>
                <span class="font-bold">Pre-registration:</span> attendees are
                managed on a third party system and imported to Joinin.
                Attendees cannot register on your portal.
              </div>
              <div class="flex items-center justify-center">
                <a
                  class="pr-1 text-blue-500"
                  href="https://support.joinin.live/portal/en/kb/articles/registration-types"
                  target="_blank"
                  >More information</a
                >
                <i class="material-icons-outlined text-icon-sm text-blue-500"
                  >help_outline</i
                >
              </div>
            </div>
          </template>
        </TooltipModal>
      </div>

      <MdTextField
        v-if="form.registrationType == 'password'"
        label="Access password"
        name="password"
        v-model="form.password"
        tooltip="The password attendees will use to access the event. Passwords are case SenSitivE."
        :errors="errors"
      />

      <div v-if="!registrationEvent" class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            class="flex-1"
            label="Maximum attendees"
            name="max_attendees"
            v-model="form.maxAttendees"
            :disabled="['basic', 'trial'].includes(accountType)"
            :errors="errors"
            :options="maxAttendeeOptions"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              Set the maximum number of attendees that can view this event. If
              this number is exceeded, additional attendees will not be able to
              access your portal.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div class="mb-6 mt-6 text-center text-lg font-medium">Schedule</div>

      <div class="flex-1 flex-row">
        <MdSelectField
          class="w-full flex-1"
          name="timezone"
          layout="md-select"
          label="Timezone"
          v-model="form.timezone"
          @input="updateStartTime"
          :search="true"
          :options="timezoneOptions"
          :errors="errors"
        />

        <div class="mb-6 flex flex-1 items-center justify-end">
          <span
            v-if="isDaylightSaving"
            class="flex items-center rounded bg-orange-500 px-2 py-1 text-white"
          >
            <i class="material-icons-outlined mr-2 text-sm text-white"
              >warning</i
            >
            Daylight Saving
          </span>
        </div>
      </div>

      <div class="flex w-full flex-row items-center space-x-2">
        <MdSelectField
          name="day"
          label="Day"
          class="flex-auto"
          v-model="form.day"
          @input="updateStartTime"
          :errors="errors"
          :options="dayOptions"
        />

        <MdSelectField
          name="month"
          label="Month"
          class="flex-auto"
          v-model="form.month"
          @input="updateStartTime"
          :errors="errors"
          :options="monthOptions"
        />

        <MdSelectField
          name="year"
          label="Year"
          class="flex-auto"
          v-model="form.year"
          @input="updateStartTime"
          :errors="errors"
          :options="yearOptions"
        />
      </div>

      <div class="flex w-full flex-row items-center space-x-2">
        <MdSelectField
          class="max-w-1/3 flex-auto"
          label="Hour"
          name="hour"
          placeholder="Hour"
          v-model="form.hour"
          @input="updateStartTime"
          :errors="errors"
          :options="hourOptions"
        />

        <MdSelectField
          class="max-w-1/3 flex-auto"
          label="Minute"
          name="minute"
          placeholder="Minute"
          v-model="form.minute"
          :errors="errors"
          :options="minuteOptions"
        />

        <MdSelectField
          class="max-w-1/3 flex-auto"
          label="AM/PM"
          name="am/pm"
          placeholder="AM/PM"
          v-model="form.amPm"
          @input="updateStartTime"
          :errors="errors"
          :options="[
            {
              id: 'am',
              label: 'AM',
            },
            {
              id: 'pm',
              label: 'PM',
            },
          ]"
        />
      </div>

      <div class="flex items-start">
        <div class="flex-1">
          <MdSelectField
            class="flex-1"
            label="Estimated duration"
            name="estimated_duration"
            v-model="form.estimatedDurationSeconds"
            :errors="errors"
            :required="true"
            :options="durationOptions"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              The estimated duration of this event. You have full control to
              start and extend the event as required.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div class="mb-6 mt-6 text-center text-lg font-medium">Support</div>

      <div class="flex items-start">
        <div class="flex-1">
          <MdEmailField
            label="Support email address"
            name="support_email"
            v-model="form.supportEmail"
            :errors="errors"
          />
        </div>

        <TooltipModal>
          <template slot="control">
            <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
              <i class="material-icons-outlined text-icon-sm">info</i>
            </button>
          </template>

          <template slot="content">
            <div class="flex flex-col space-y-4">
              The support contact email address for this event. Displayed to
              attendees when they request help.
            </div>
          </template>
        </TooltipModal>
      </div>

      <div
        v-if="isTimeBeforeNow"
        class="flex flex-row justify-center rounded-xl border bg-red-100 p-2 text-red-500"
      >
        Scheduled start must be after the current time
      </div>

      <SubmitButton
        :saving="saving"
        :disabled="!saveable"
        label="Create new event"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import { EventBus } from '@/services/event-bus.js';
import api from '@/services/api';
import errors from '@/mixins/errors';
import TooltipModal from '@/components/TooltipModal';
import SubmitButton from '@/components/SubmitButton';
import MdSelectField from '@/components/MdSelectField';
import MdTextField from '@/components/MdTextField';
import MdEmailField from '@/components/MdEmailField';

export default {
  mixins: [errors],

  components: {
    TooltipModal,
    SubmitButton,
    MdSelectField,
    MdTextField,
    MdEmailField,
  },

  data() {
    return {
      isDaylightSaving: false,
      form: {
        id: null,
        title: null,
        registrationType: 'anyone',
        maxAttendees: 200,
        password: null,
        supportEmail: null,
        timezone: 'Australia/Sydney',
        day: null,
        month: null,
        year: null,
        hour: null,
        minute: null,
        amPm: null,
        estimatedDurationSeconds: null,
      },
      jobStartTime: null,
      isTimeBeforeNow: false,
      saving: false,
      monthOptions: [
        {
          id: 0,
          label: 'January',
        },
        {
          id: 1,
          label: 'February',
        },
        {
          id: 2,
          label: 'March',
        },
        {
          id: 3,
          label: 'April',
        },
        {
          id: 4,
          label: 'May',
        },
        {
          id: 5,
          label: 'June',
        },
        {
          id: 6,
          label: 'July',
        },
        {
          id: 7,
          label: 'August',
        },
        {
          id: 8,
          label: 'September',
        },
        {
          id: 9,
          label: 'October',
        },
        {
          id: 10,
          label: 'November',
        },
        {
          id: 11,
          label: 'December',
        },
      ],
    };
  },

  mounted() {
    this.form.maxAttendees = this.maxAttendeeOptions[0].id;
  },

  computed: {
    ...mapState({
      accountType: (s) => s.sessionData?.account.type,
    }),
    saveable() {
      return (
        this.jobStartTime != null &&
        this.form.estimatedDurationSeconds &&
        this.form.supportEmail &&
        this.form.title
      );
    },

    title() {
      return 'New event';
    },

    timezoneOptions() {
      return Object.values(EventBus.data.timezones)
        .map((tz) => {
          return {
            id: `${tz.name}`,
            label: `${tz.name}`,
          };
        })
        .sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        });
    },

    registrationEvent() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.form.registrationType);
    },

    maxAttendeeOptions() {
      let maxAttendeeOptions = [];

      if (this.accountType == 'trial') {
        maxAttendeeOptions.push({
          id: 200,
          label: 200,
          disabled: true,
        });
      } else if (this.accountType == 'basic') {
        maxAttendeeOptions.push({
          id: 100,
          label: 100,
          disabled: true,
        });
      } else {
        for (let i = 200; i <= 2000; i += 100) {
          maxAttendeeOptions.push({
            id: i,
            label: `${i}`,
          });
        }
      }

      return maxAttendeeOptions;
    },

    typeOptions() {
      let typeOptions = [
        {
          id: 'anyone',
          label: 'Public',
        },
        {
          id: 'password',
          label: 'Password',
        },
      ];

      typeOptions = typeOptions.concat([
        {
          id: 'automatic_registration',
          label:
            'Automatic registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
        {
          id: 'manual_registration',
          label:
            'Manual registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
        {
          id: 'pre_registration',
          label:
            'Pre registration' +
            (this.can('event-registration') ? '' : ' (upgrade plan)'),
          disabled: this.can('event-registration') ? false : true,
        },
      ]);

      return typeOptions;
    },

    hourOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 12; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },

    yearOptions() {
      let options = [];
      options.push({
        id: dayjs().format('YYYY'),
        label: dayjs().format('YYYY'),
      });

      options.push({
        id: dayjs().add(1, 'year').format('YYYY'),
        label: dayjs().add(1, 'year').format('YYYY'),
      });

      return options;
    },

    dayOptions() {
      let options = [];
      let i;

      for (i = 1; i <= 31; i++) {
        options.push({
          id: i,
          label: i,
        });
      }

      return options;
    },

    minuteOptions() {
      let options = [];
      let i;

      for (i = 0; i <= 59; i++) {
        let label = i;

        if (label < 10) {
          label = '0' + label;
        }

        options.push({
          id: label + '',
          label: label + '',
        });
      }

      return options;
    },

    durationOptions() {
      let options = [];
      const increments = ['00', '15', '30', '45'];
      let i;

      for (i = 0; i <= 11; i++) {
        increments.forEach((increment) => {
          const label = this.pad(i, 2) + ':' + increment;

          // Skip 00:00
          if (!i && increment == '00') {
            return;
          }

          const hourSeconds = i * 60 * 60;
          const minuteSeconds = parseInt(increment) * 60;
          const seconds = hourSeconds + minuteSeconds;

          options.push({
            id: seconds,
            label,
          });
        });
      }

      return options;
    },
  },

  watch: {
    jobStartTime(time) {
      if (!time) return;
      if (
        EventBus.data.timezones[this.form.timezone].utcOffset !=
        -dayjs(time).$d.getTimezoneOffset()
      ) {
        this.isDaylightSaving = true;
      } else {
        this.isDaylightSaving = false;
      }
    },
  },

  methods: {
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    },

    save() {
      this.create();
    },

    create() {
      this.saving = true;

      api
        .post('/jobs', {
          ...this.form,
          startTime: this.jobStartTime,
        })
        .then((response) => {
          this.saving = false;

          this.notify('success', 'Event created');

          this.$router.push(`/events/${response.data.eventShortcode}/branding`);
        })
        .catch((error) => {
          this.saving = false;
          console.error(error);
          this.notify('error', error.response.data.error);
        });
    },
    updateStartTime() {
      this.isTimeBeforeNow = false;
      this.jobStartTime = null;
      if (
        !this.form.day ||
        this.form.month == null ||
        !this.form.year ||
        !this.form.hour ||
        !this.form.minute ||
        !this.form.amPm
      ) {
        return;
      }

      const hour =
        this.form.amPm == 'am'
          ? +this.form.hour == 12
            ? 0
            : +this.form.hour
          : +this.form.hour == 12
          ? +this.form.hour
          : +this.form.hour + 12;

      const datetime = dayjs()
        .date(this.form.day)
        .month(this.form.month)
        .year(this.form.year)
        .hour(hour)
        .minute(this.form.minute)
        .second(0)
        .tz(this.form.timezone, true);

      if (dayjs().isAfter(datetime)) {
        this.isTimeBeforeNow = true;
        return;
      }

      this.jobStartTime = datetime.format();
    },
  },
};
</script>
