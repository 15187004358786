<template>
  <div class="w-auto bg-white p-8 shadow-lg sm:w-96">
    <h1 class="mb-6 text-center text-3xl font-bold">Log in</h1>

    <div v-if="errorMessage" class="pb-6 text-center text-red-700">
      Login failed. Please check your credentials and try again.
    </div>

    <form @submit.prevent="save()">
      <EmailField
        name="email"
        label="Email"
        :autofocus="true"
        v-model="form.email"
        :errors="errors"
      />

      <PasswordField
        name="password"
        label="Password"
        v-model="form.password"
        :errors="errors"
      />

      <RouterLink
        to="/forgot"
        class="-mt-4 block text-center text-blue-500 hover:text-blue-800"
        tabindex="-1"
        >Forgot password
      </RouterLink>

      <SubmitButton :saving="saving" label="Log In" />
    </form>

    <div class="mt-8 flex flex-col items-center">
      <div class="block">New to Joinin?</div>
      <RouterLink
        to="/signup"
        class="block text-center text-base font-medium text-blue-500 hover:text-blue-800"
        tabindex="-1"
        >Sign up now
      </RouterLink>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';
import EmailField from '@/components/EmailField';
import PasswordField from '@/components/PasswordField';
import SubmitButton from '@/components/SubmitButton';

export default {
  mixins: [errors],

  components: {
    EmailField,
    PasswordField,
    SubmitButton,
  },

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      saving: false,
      errorMessage: null,
    };
  },

  methods: {
    save() {
      this.saving = true;
      this.errorMessage = false;

      api
        .post('/authentication/login', this.form)
        .then((response) => {
          this.$store.commit('loggedIn', response.data);
          this.$router.replace(this.$store.state.intended || '/dashboard');
        })
        .catch((error) => {
          this.handleSaveError(error);

          if (error.response.status >= 400) {
            this.errorMessage = true;
          }
        });
    },
  },
};
</script>
