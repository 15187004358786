<template>
  <div class="h-full w-full">
    <div
      v-if="!clickedStart"
      class="mt-48 flex flex-col items-center justify-center"
    >
      <img class="w-36" src="../assets/logo-icon-blue.svg" />
      <div class="font-xl mb-2 mt-6 font-bold text-grey-800">
        Presenter multiview
      </div>
      <div class="text-grey-400">
        Event must be in rehearsal or live to preview presenters
      </div>
      <button
        @click="initAudio"
        class="mt-12 cursor-pointer rounded bg-blue-500 px-4 py-1 text-white hover:bg-blue-800"
      >
        Start
      </button>
    </div>
    <div
      v-if="clickedStart && !isLoaded"
      class="flex h-full items-center justify-center"
    >
      <LoadingIcon />
    </div>
    <div
      v-if="clickedStart && isLoaded"
      class="grid h-full grid-flow-row auto-rows-fr gap-1"
      :class="gridLayout"
    >
      <PresenterPlayer
        v-for="(presenter, index) in getPresenters"
        :key="presenter.pid"
        v-show="
          mode != 'active-speaker' ||
          (mode == 'active-speaker' && presenter.isShowing)
        "
        :presenter="presenter"
        :janus="janus"
        :janusVideoRoomId="event.videoRoomId"
        :janusPin="event.janusPin"
        :name="presenter.name"
        :index="index + 1"
        :audioContext="audioContext"
        :allowStatus="allowStatus"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PresenterPlayer from '@/components/PresenterPlayer.vue';
import LoadingIcon from '@/components/ui/LoadingIcon.vue';
import { loadJanusJs, trackJanusPublishers } from '@/helper/janus.js';

function hOP(obj, p) {
  return Object.prototype.hasOwnProperty.call(obj, p);
}

export default {
  name: 'EventOuputPresenters',
  components: {
    PresenterPlayer,
    LoadingIcon,
  },
  data: () => ({
    clickedStart: false,
    mode: null,
    isLoaded: false,
    janus: null,
    janusPublishers: {},
    currentTalkingPresenter: {
      pid: null,
      start: null,
      end: null,
    },
    audioContext: null,
  }),
  computed: {
    ...mapState(['event', 'presenters', 'sources', 'control']),
    gridLayout() {
      var total = this.getPresenters.length;
      if (this.mode == 'active-speaker') total = 1;

      if (total == 1) {
        return 'grid-cols-1';
      } else if (total == 2) {
        return 'grid-cols-2';
      } else if (total <= 4) {
        return 'grid-cols-2';
      } else if (total <= 6) {
        return 'grid-cols-3';
      } else if (total <= 9) {
        return 'grid-cols-3';
      } else if (total <= 12) {
        return 'grid-cols-4';
      } else if (total <= 16) {
        return 'grid-cols-4';
      } else if (total <= 25) {
        return 'grid-cols-5';
      } else {
        return 'grid-cols-6';
      }
    },
    getPresenters() {
      let list = this.presenters
        .map((p) =>
          Object.assign({}, p, {
            isLive: this.sources.some((src) => p.pid == src.presenterId),
            isOnline: !!this.janusPublishers[p.pid],
          })
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      const query = this.$route.query;
      if (this.mode == 'online') {
        list = list.filter((p) => p.isOnline);
      } else if (this.mode == 'live') {
        list = list.filter((p) => p.isLive);
      } else if (this.mode == 'waiting') {
        list = list.filter((p) => p.isOnline && !p.isLive);
      } else if (this.mode == 'mix-minus') {
        if (hOP(query, 'index') && list[+query.index - 1]) {
          list[+query.index - 1].forceMuted = true;
        } else if (hOP(query, 'name') && query.name != '') {
          const lowerName = query.name.toLowerCase();
          const p = list.find((p) => p.name.toLowerCase() == lowerName);
          if (p) {
            p.forceMuted = true;
          }
        }
      } else if (this.mode == 'live-mix-minus') {
        list = list.filter((p) => p.isLive == true);
        if (hOP(query, 'index') && list[+query.index - 1]) {
          list[+query.index - 1].forceMuted = true;
        } else if (hOP(query, 'name') && query.name != '') {
          const lowerName = query.name.toLowerCase();
          const p = list.find((p) => p.name.toLowerCase() == lowerName);
          if (p) {
            p.forceMuted = true;
          }
        }
      } else if (this.mode == 'active-speaker') {
        list.forEach((p) => {
          p.isShowing = this.currentTalkingPresenter?.pid == p.pid;
        });
      } else if (this.mode == 'live-active-speaker') {
        list = list
          .filter((p) => p.isLive == true)
          .forEach((p) => {
            p.isShowing = this.currentTalkingPresenter?.pid == p.pid;
          });
      } else if (this.mode == 'individual') {
        if (hOP(query, 'name') && query.name != '') {
          const lowerName = query.name.toLowerCase();
          list = list.filter((p) => p.name.toLowerCase() == lowerName);
        }
      }

      return list;
    },
    allowStatus() {
      return !hOP(this.$route.query, 'cleanScreen');
    },
  },
  watch: {
    presenters: function (newPresenters, oldPresenters) {
      const addedPresenters = newPresenters.filter(
        (np) => !oldPresenters.some((op) => op.pid == np.pid)
      );
      for (const ap of addedPresenters) {
        if (this.janusPublishers[ap.pid]) {
          this.$store.commit('MESSAGE', `Presenter ${ap.name} is online.`);
        }
      }
    },
  },
  mounted() {
    this.mode = this.$route.query.mode || 'all';
    this.janusInit();
    this.janusVideoroom();

    // Pusher setup/teardown handled in EventOutput component
    this.control.bind('event-update', (data) => {
      const payload = data.payload;
      if (data.mode == 'mute') {
        if (
          payload.muted == true &&
          this.currentTalkingPresenter?.pid == payload.id
        ) {
          this.currentTalkingPresenter.end = Date.now();
        }
      }
    });
  },
  beforeDestroy() {
    this._destroying = true;
    if (this.janus) {
      this.janus._destroying = true;
      this.janus.destroy();
    }
  },
  methods: {
    janusInit() {
      loadJanusJs(this.event.janusHost);
      this.janusConnectedPromise = Promise.all([
        window.adapterLoadedPromise,
        window.janusLoadedPromise,
      ]).then(
        () =>
          new Promise((resolve) => {
            if (this._destroying) {
              return void resolve();
            }
            this.janus = new Janus({
              server: this.event.janusHost + '/janus',
              success: resolve,
              error: console.error.bind(console),
              destroyed: () => {
                console.log('destroyed');
              },
            });
          })
      );

      this.janusConnectedPromise.then(() => {
        this.isLoaded = true;
      });
    },
    async janusVideoroom() {
      await this.janusConnectedPromise;
      if (this._destroying) return;
      this.janus.attach({
        plugin: 'janus.plugin.videoroom',
        success: (handle) => {
          handle.send({
            message: {
              request: 'join',
              ptype: 'publisher',
              room: String(this.event.videoRoomId),
              pin: this.event.janusPin,
              display: 'Mixer',
            },
          });
        },
        error: (err) => {
          console.log(err);
        },
        onmessage: (msg) => {
          //console.log(msg);
          const { removedId, added } = trackJanusPublishers({
            janusPublishers: this.janusPublishers,
            janusMsg: msg,
          });
          if (removedId) {
            const [pType, pTypeId] = removedId.split('-');
            if (pType === 'Presenter') {
              const pub = this.presenters.find((p) => p.pid == pTypeId);
              if (pub) {
                this.$store.commit('ERROR', `Presenter ${pub.name} is offline.`);
              }
            }
          }
          if (added.length > 0) {
            for (const newPub of added) {
              const [pType, pTypeId] = newPub.id.split('-');
              if (pType === 'Presenter') {
                const presenter = this.presenters.find((p) => p.pid == pTypeId);
                if (presenter) {
                  this.$store.commit(
                    'MESSAGE',
                    `Presenter ${presenter.name} is online.`
                  );
                }
              }
            }
          }
          if (msg.videoroom == 'talking') {
            const presenter = this.presenters.find((p) => p.pid == msg.id);
            if (presenter) {
              if (
                !this.currentTalkingPresenter ||
                this.currentTalkingPresenter.pid == presenter.pid ||
                (this.currentTalkingPresenter &&
                  this.currentTalkingPresenter.pid != presenter.pid &&
                  this.currentTalkingPresenter.end < Date.now())
              ) {
                this.currentTalkingPresenter = {
                  pid: presenter.pid,
                  start: Date.now(),
                };
              }
            }
          } else if (msg.videoroom == 'stopped-talking') {
            const presenter = this.presenters.find((p) => p.pid == msg.id);
            if (presenter) {
              if (this.currentTalkingPresenter?.pid == presenter.pid) {
                this.currentTalkingPresenter.end = Math.max(
                  Date.now(),
                  this.currentTalkingPresenter.start + 2000
                );
              }
            }
          }
        },
      });
    },
    initAudio() {
      this.audioContext = new AudioContext();
      this.clickedStart = true;
    },
  },
};
</script>
