import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPage from '@/views/DashboardPage';
import EventSettings from '@/views/EventSettings';
import EventAnalytics from '@/views/EventAnalytics';
import EventRegistration from '@/views/EventRegistration';
import EventAttendee from '@/views/EventAttendee';
import EventAttendeeForm from '@/views/EventAttendeeForm';
import EventAttendeeImport from '@/views/EventAttendeeImport';
import EventStudio from '@/views/EventStudio';
import EventForm from '@/views/EventForm';
import BillingPage from '@/views/BillingPage';
import UsersPage from '@/views/UsersPage';
import UserForm from '@/views/UserForm';
import EventPage from '@/views/EventPage';
import EventBranding from '@/views/EventBranding';
import EventList from '@/components/EventList';
import ArchivedEventList from '@/components/ArchivedEventList';
import EventSettingsGeneral from '@/components/EventSettingsGeneral';
import EventSettingsRegistration from '@/components/EventSettingsRegistration';
import EventSettingsEngagement from '@/components/EventSettingsEngagement';
import EventSettingsNotifications from '@/components/EventSettingsNotifications';
import EventSettingsSurvey from '@/components/EventSettingsSurvey';
import EventSettingsPages from '@/components/EventSettingsPages';
import EventPageEdit from '@/views/EventPageEdit';
import EventSettingsVod from '@/components/EventSettingsVod';
import EventAnalyticsStatisticsTab from '@/components/EventAnalyticsStatisticsTab';
import EventAnalyticsLoginsTab from '@/components/EventAnalyticsLoginsTab';
import EventAnalyticsDownloadTab from '@/components/EventAnalyticsDownloadTab';
import AccountDetails from '@/components/AccountDetails';
import AccountClose from '@/components/AccountClose';
import PlanDetails from '@/components/PlanDetails';
import ErrorUnsupported from '@/views/ErrorUnsupported';
import ErrorAccessDenied from '@/views/ErrorAccessDenied';
import LoginPage from '@/views/LoginPage';
import SignupPage from '@/views/SignupPage';
import ForgotPassword from '@/views/ForgotPassword';
import ResetPassword from '@/views/ResetPassword';
import ProfilePage from '@/views/ProfilePage';
import EventHybrid from '@/views/EventHybrid';
import EventOutput from '@/components/EventOutput';
import EventOutputPresenters from '@/views/EventOutputPresenters';
import EventOutputQA from '@/views/EventOutputQA';
import EventOutputPoll from '@/views/EventOutputPoll';
import EventOutputChat from '@/views/EventOutputChat';
import EventOutputIoClients from '@/views/EventOutputIoClients';
import FormTemplates from '@/views/FormTemplates';
import FormTemplate from '@/views/FormTemplate';
import LayoutsPage from '@/views/LayoutsPage';
import LayoutPage from '@/views/LayoutPage';
import EventBrandingBasic from '@/components/EventBrandingBasic';
import EventBrandingAdvanced from '@/components/EventBrandingAdvanced';
import EventBrandingPreview from '@/components/EventBrandingPreview';
import AccountSettings from '@/views/AccountSettings';
import EventRegistrationAttendees from '@/components/EventRegistrationAttendees';
import EventEngagement from '@/views/EventEngagement';
import IoRouter from '@/views/IoRouter';
import IoRouterRouting from '@/views/IoRouterRouting';
import IoRouterClients from '@/views/IoRouterClients';

Vue.use(VueRouter);

const routes = [
  {
    path: '/error/unsupported',
    name: 'unsupported',
    component: ErrorUnsupported,
    meta: {
      title: 'Unsupported',
    },
  },
  {
    path: '/error/denied',
    name: 'denied',
    component: ErrorAccessDenied,
    meta: {
      title: 'Error',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: {
      title: 'Free signup',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPassword,
    meta: {
      title: 'Forgot password',
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPassword,
    meta: {
      title: 'Reset password',
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/settings',
    component: AccountSettings,
    children: [
      {
        path: 'users',
        name: 'users',
        component: UsersPage,
        meta: {
          title: 'Settings',
          tabTitle: 'User management',
        },
      },
      {
        path: 'users/:userId',
        name: 'user',
        component: UserForm,
        meta: {
          title: 'Settings',
          tabTitle: 'User details',
          allowNavBack: true,
        },
      },
      {
        path: 'templates',
        name: 'templates',
        component: FormTemplates,
        meta: {
          title: 'Settings',
          tabTitle: 'Form templates',
        },
      },
      {
        path: 'templates/:id',
        name: 'template',
        component: FormTemplate,
        meta: {
          title: 'Settings',
          tabTitle: 'Form template',
          allowNavBack: true,
        },
      },
      {
        path: 'layouts',
        name: 'layouts',
        component: LayoutsPage,
        meta: {
          title: 'Settings',
          tabTitle: 'Layouts',
        },
      },
      {
        path: 'layouts/:type/:id?',
        name: 'layout',
        component: LayoutPage,
        meta: {
          title: 'Settings',
          tabTitle: 'Layout',
          allowNavBack: true,
        },
      },
    ],
  },
  {
    path: '/billing',
    component: BillingPage,
    children: [
      {
        path: '',
        name: 'account-detail',
        component: AccountDetails,
        meta: {
          title: 'Billing',
          tabTitle: 'Account detail',
        },
      },
      {
        path: 'closure',
        name: 'account-closure',
        component: AccountClose,
        meta: {
          title: 'Billing',
          tabTitle: 'Account closure',
        },
      },
      {
        path: 'plan',
        name: 'account-plan',
        component: PlanDetails,
        meta: {
          title: 'Billing',
          tabTitle: 'Subscription',
        },
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      title: 'Your profile',
    },
  },
  {
    path: '/new-event',
    name: 'new-event',
    component: EventForm,
    meta: {
      title: 'New event',
      allowNavBack: true,
    },
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    children: [
      {
        path: '',
        component: EventList,
        name: 'dashboard',
        meta: {
          title: 'Events',
        },
      },
      {
        path: 'archived',
        name: 'archived',
        component: ArchivedEventList,
        meta: {
          title: 'Events',
        },
      },
    ],
  },
  {
    path: '/events/:shortcode',
    component: EventPage,
    children: [
      {
        path: 'studio',
        component: EventStudio,
        name: 'studio',
        meta: {
          title: 'Studio',
        },
      },
      {
        path: 'hybrid',
        name: 'hybrid',
        component: EventHybrid,
        meta: {
          title: 'Hybrid',
        },
      },
      {
        path: 'engagement',
        name: 'engagement',
        component: EventEngagement,
        meta: {
          title: 'Engagement',
        },
      },
      {
        path: 'output',
        name: 'output',
        component: EventOutput,
        children: [
          {
            path: 'presenters',
            component: EventOutputPresenters,
            name: 'output-presenters',
            meta: {
              title: 'Output: Presenters',
            },
          },
          {
            path: 'qa',
            component: EventOutputQA,
            name: 'output-qa',
            meta: {
              title: 'Output: Q&A',
            },
          },
          {
            path: 'poll',
            component: EventOutputPoll,
            name: 'output-poll',
            meta: {
              title: 'Output: Poll',
            },
          },
          {
            path: 'chat',
            component: EventOutputChat,
            name: 'output-chat',
            meta: {
              title: 'Output: Chat',
            },
          },
          {
            path: 'io-clients',
            name: 'output-io-clients',
            component: EventOutputIoClients,
            meta: {
              title: 'IO Multiview',
            },
          },
        ],
      },
      {
        path: 'registration',
        component: EventRegistration,
        meta: {
          title: 'Registration',
        },
        children: [
          {
            path: '',
            name: 'registration-attendees',
            component: EventRegistrationAttendees,
            meta: {
              title: 'Registration',
              tabTitle: 'Event Attendees',
            },
          },
          {
            path: 'import',
            name: 'registration-import',
            component: EventAttendeeImport,
            meta: {
              title: 'Registration',
              tabTitle: 'Bulk import',
            },
          },
        ],
      },
      {
        path: 'attendee/:attendeeId',
        name: 'attendee',
        component: EventAttendee,
        meta: {
          title: 'Attendee',
          allowNavBack: true,
        },
      },
      {
        path: 'attendee/edit/:attendeeId',
        name: 'attendee-edit',
        component: EventAttendeeForm,
        meta: {
          title: 'Attendee',
          allowNavBack: true,
        },
      },
      {
        path: 'branding',
        component: EventBranding,
        meta: {
          title: 'Branding',
        },
        children: [
          {
            path: '',
            name: 'branding-basic',
            component: EventBrandingBasic,
            meta: {
              title: 'Branding',
              tabTitle: 'Basic',
            },
          },
          {
            path: 'advanced',
            name: 'branding-advanced',
            component: EventBrandingAdvanced,
            meta: {
              title: 'Branding',
              tabTitle: 'Advanced',
            },
          },
          {
            path: 'preview',
            name: 'branding-preview',
            component: EventBrandingPreview,
            meta: {
              title: 'Branding',
              tabTitle: 'Preview',
            },
          },
        ],
      },
      {
        path: 'settings',
        component: EventSettings,
        children: [
          {
            path: '',
            name: 'settings-general',
            component: EventSettingsGeneral,
            meta: {
              title: 'Settings',
              tabTitle: 'General',
            },
          },
          {
            path: 'registration',
            name: 'settings-registration',
            component: EventSettingsRegistration,
            meta: {
              title: 'Settings',
              tabTitle: 'Registration',
            },
          },
          {
            path: 'engagement',
            name: 'settings-engagement',
            component: EventSettingsEngagement,
            meta: {
              title: 'Settings',
              tabTitle: 'Engagement',
            },
          },
          {
            path: 'vod',
            name: 'settings-ondemand',
            component: EventSettingsVod,
            meta: {
              title: 'Settings',
              tabTitle: 'On demand',
            },
          },
          {
            path: 'pages/:pageId',
            name: 'settings-page',
            component: EventPageEdit,
            meta: {
              title: 'Settings',
              tabTitle: 'Page',
            },
          },
          {
            path: 'pages',
            name: 'settings-pages',
            component: EventSettingsPages,
            meta: {
              title: 'Settings',
              tabTitle: 'Pages',
            },
          },
          {
            path: 'notifications',
            name: 'settings-notifications',
            component: EventSettingsNotifications,
            meta: {
              title: 'Settings',
              tabTitle: 'Notifications',
            },
          },
          {
            path: 'survey',
            name: 'settings-survey',
            component: EventSettingsSurvey,
            meta: {
              title: 'Settings',
              tabTitle: 'Survey',
            },
          },
        ],
      },
      {
        path: 'analytics',
        meta: { title: 'Analytics' },
        component: EventAnalytics,
        children: [
          {
            path: '',
            component: EventAnalyticsStatisticsTab,
            name: 'analytics-statistics',
            meta: {
              title: 'Analytics',
              tabTitle: 'Statistics',
            },
          },
          {
            path: 'logins',
            name: 'analytics-logins',
            component: EventAnalyticsLoginsTab,
            meta: {
              title: 'Analytics',
              tabTitle: 'Attendees',
            },
          },
          {
            path: 'download',
            name: 'analytics-download',
            component: EventAnalyticsDownloadTab,
            meta: {
              title: 'Analytics',
              tabTitle: 'Download',
            },
          },
        ],
      },
      {
        path: 'io-router',
        name: 'io-router',
        component: IoRouter,
        meta: {
          title: 'Router',
        },
        redirect: 'io-router/routing',
        children: [
          {
            path: 'routing',
            name: 'io-router-routing',
            component: IoRouterRouting,
            meta: {
              title: 'Router',
            },
          },
          {
            path: 'clients',
            name: 'io-router-clients',
            component: IoRouterClients,
            meta: {
              title: 'Router',
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
