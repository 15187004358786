<template>
  <div class="flex h-full flex-grow flex-col">
    <!-- main content -->
    <template>
      <div class="flex h-full flex-col">
        <!-- message list -->
        <div
          ref="scroll"
          @scroll="onScroll"
          class="flex flex-grow flex-col overflow-auto py-2"
        >
          <div v-for="(item, index) in messages" :key="index" class="">
            <div v-if="item.type === 'message'" class="flex flex-col pt-4">
              <div
                class="flex items-center space-x-1 text-grey-400"
                :class="{ 'self-end': messageType(item) == 'self' }"
              >
                <div class="flex">
                  {{ messageName(item) }}
                </div>
                <div>&middot;</div>
                <div>{{ item.message.createdAt | formatDate }}</div>
              </div>

              <span
                class="flex-shrink-1 flex max-w-max rounded-b-2xl bg-grey-100 px-4 py-2"
                :class="
                  messageType(item) == 'self'
                    ? 'self-end rounded-tl-2xl bg-blue-400 text-white'
                    : 'rounded-tr-2xl'
                "
              >
                <span v-if="item.message.action == 'message'">{{
                  item.message.content
                }}</span>
                <span v-if="item.message.action == 'raise_hand'"
                  ><i class="material-icons-outlined">pan_tool</i></span
                >
                <span v-if="item.message.action == 'help'"
                  >@Operator, I need help</span
                >
                <span v-if="item.isSent == false">
                  <i class="material-icons-outlined text-white">cached</i>
                </span>
              </span>
            </div>
            <div v-else-if="item.type === 'join'" class="mb-4 mt-4 text-center">
              <span class="rounded-full bg-grey-100 px-3 py-2 text-grey-400"
                >{{ item.name }} joined the chat</span
              >
            </div>
            <div
              v-else-if="item.type === 'leave'"
              class="mb-4 mt-4 text-center"
            >
              <span class="rounded-full bg-grey-100 px-3 py-2 text-grey-400"
                >{{ item.name }} left the chat</span
              >
            </div>
          </div>
        </div>

        <!-- actions -->
        <div
          class="relative flex flex-shrink-0 flex-col justify-center border-t border-grey-200 pt-3"
        >
          <button
            @click="handleClickNewMessages"
            v-if="isNewMessages"
            class="absolute -top-10 flex items-center self-center rounded-full bg-black bg-opacity-50 px-3 py-1 text-sm text-white"
          >
            New messages
            <i class="material-icons-outlined brounce text-white"
              >keyboard_arrow_down</i
            >
          </button>

          <div v-if="disabled">
            <div class="text-center text-sm text-grey-400">
              You can send messages once in the Live Room
            </div>
          </div>
          <div v-else>
            <button
              v-if="actions"
              class="mx-auto mb-3 flex max-w-max items-center justify-center rounded-full bg-grey-200 px-3 py-2 text-grey-800 hover:bg-grey-100"
              :class="{ disabled: disabled }"
              :disabled="disabled"
              @click="handleClickRaiseHand"
            >
              <i class="material-icons-outlined mr-2">pan_tool</i> Raise your
              hand
            </button>

            <div class="flex items-center space-x-2">
              <div class="flex-1">
                <TextField
                  name="message"
                  label="Send message"
                  v-model="message"
                  :errors="errors"
                  class="-mb-0 rounded-full border border-grey-200 bg-white"
                  :class="{ disabled: disabled }"
                  :disabled="disabled"
                  @keypress-enter="handleSendMessage"
                />
              </div>
              <div>
                <button
                  class="align-center flex w-full justify-center rounded-full border-2 border-blue-500 bg-blue-500 px-3 py-3 text-xl font-bold text-white shadow-lg hover:border-blue-800 hover:bg-blue-800"
                  @click.prevent="hanldeClickSendMessage"
                  :class="{ disabled: disabled }"
                  :disabled="disabled"
                >
                  <i class="material-icons-outlined text-white">arrow_upward</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- end of main -->
  </div>
</template>
<script>
import dayjs from 'dayjs';

import errors from '@/mixins/errors';
import TextField from '@/components/TextField';
import { string as randomString } from '@/helper/random.js';

export default {
  name: 'StudioPanelPresenterMessages',
  mixins: [errors],
  components: {
    TextField,
  },
  props: {
    role: {
      type: String,
      default: 'presenter',
    },
    username: {
      type: String,
      required: true,
    },
    janus: {
      type: Object,
      //required: true
    },
    config: {
      type: Object,
      required: true,
      default: function () {
        return {
          room: '',
          pin: '',
        };
      },
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoaded: false,
    textroom: null,
    requestParticipantsTransaction: null,
    transactions: {},
    participants: {},
    message: null,
    newMessagesCount: 0,
    autoScroll: true,
    messages: [],
    isNewMessages: false,
    scrollTimeout: null,
  }),
  computed: {
    id() {
      return (this.role == 'mixer' ? 'op-' : '') + randomString(12);
    },
  },
  mounted() {
    this.initChatroom();
  },

  beforeDestroy() {
    if (this.janus && !this.janus._destroying && this.textroom) {
      this.textroom.detach();
    }

    clearTimeout(this.scrollTimeout);
  },

  watch: {
    janus: function () {
      this.initChatroom();
    },
    messages: function () {
      //count new incoming messages when not focus
      if (!this.isFocus) {
        if (this.messages[this.messages.length - 1].type == 'message') {
          this.newMessagesCount++;

          this.$emit('newMessagesNotification', this.newMessagesCount);
        }
      }

      this.scrollToBottom();
      if (
        this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight >
        this.$refs.scroll.scrollTop
      ) {
        this.isNewMessages = true;
      }
    },
    isFocus: function (newValue, oldValue) {
      if (oldValue == false && newValue == true) {
        this.newMessagesCount = 0;

        let res = this.messages.filter(
          (m) => m.type == 'message' && m.isRead == false
        );
        if (res.length > 0) {
          res.map((m) => {
            return (m.isRead = true);
          });
        }

        this.$emit('newMessagesNotification', 0);

        this.autoScroll = true;
        this.scrollToBottom();
      }
    },
    username: function () {
      this.renameUser();
    },
  },
  methods: {
    getDateString(jsonDate) {
      var when = new Date();
      if (jsonDate) {
        when = new Date(Date.parse(jsonDate));
      }
      var dateString =
        ('0' + when.getUTCHours()).slice(-2) +
        ':' +
        ('0' + when.getUTCMinutes()).slice(-2) +
        ':' +
        ('0' + when.getUTCSeconds()).slice(-2);
      return dateString;
    },
    async initChatroom() {
      if (!this.janus) return;

      this.isLoaded = false;

      if (this.textroom) {
        this.textroom.detach();
        this.textroom = null;
      }

      this.janus.attach({
        plugin: 'janus.plugin.textroom',
        success: (pluginHandle) => {
          this.textroom = pluginHandle;
          this.textroom.send({ message: { request: 'setup' } });
        },
        error: (error) => {
          console.error('  -- Error attaching plugin...', error);
        },
        /*
                webrtcState: (on) => {
                    console.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                },
                */
        onmessage: (msg, jsep) => {
          if (msg['error'] !== undefined && msg['error'] !== null) {
            console.log(msg['error']);
          }

          if (jsep !== undefined && jsep !== null) {
            // Answer
            this.textroom.createAnswer({
              jsep: jsep,
              media: { audio: false, video: false, data: true },
              success: (ourjsep) => {
                //console.log("Got SDP!");
                //console.log(ourjsep);
                this.textroom.send({
                  message: { request: 'ack' },
                  jsep: ourjsep,
                });
              },
              error: (error) => {
                console.log('WebRTC error:', error);
              },
            });
          }
        },
        ondataopen: () => {
          //console.log("The DataChannel is available!", data);
          //this.getParticipantsList();
          this.registerUser();
        },
        ondata: (data) => {
          //console.log("We got data from the DataChannel! " + data);

          var json = JSON.parse(data);
          var transaction = json['transaction'];
          if (this.transactions[transaction]) {
            // Someone was waiting for this
            this.transactions[transaction](json);
            delete this.transactions[transaction];
            return;
          }
          var what = json['textroom'];

          if (what === 'message') {
            // Incoming message: public or private?
            var msg = json['text'];
            msg = msg.replace(new RegExp('<', 'g'), '&lt');
            msg = msg.replace(new RegExp('>', 'g'), '&gt');

            //var dateString = this.getDateString(json["date"]);
            var whisper = json['whisper'];

            if (whisper === true) {
              // Private message
              this.messages.push({
                type: 'message',
                to: [],
                id: json['from'],
                name: this.participants[json['from']],
                message: JSON.parse(msg),
                isRead: this.isFocus,
                isSent: true,
                isOperator: json['from'].indexOf('op-') == 0,
                isPrivate: true,
              });
            } else {
              // Public message
              this.messages.push({
                type: 'message',
                to: [],
                id: json['from'],
                name: this.participants[json['from']],
                message: JSON.parse(msg),
                isRead: this.isFocus,
                isSent: true,
                isOperator: json['from'].indexOf('op-') == 0,
              });
            }
          } else if (what === 'join') {
            if (!this.participants[json['username']]) {
              this.participants[json['username']] = json['display'];
            }

            this.messages.push({
              type: 'join',
              id: json['username'],
              name: json['display'],
            });
          } else if (what === 'leave') {
            this.messages.push({
              type: 'leave',
              id: json['username'],
              name: this.participants[json['username']],
            });

            if (this.participants[json['username']]) {
              delete this.participants[json['username']];
            }
          } else if (what === 'kicked') {
            delete this.participants[json['username']];
          } else if (what === 'destroyed') {
            // Room was destroyed, goodbye!
            console.warn('The room has been destroyed!');
          } else {
            //return from getParticipants
            if (
              this.requestParticipantsTransaction == json['transaction'] &&
              json['participants']
            ) {
              json['participants'].map((p) => {
                this.participants[p.username] = p.display;
              });
            }
          }
        },
        oncleanup: () => {
          console.log(' ::: Got a cleanup notification :::');
        },
      });
    },
    getParticipantsList() {
      this.requestParticipantsTransaction = randomString(12);

      this.textroom.data({
        text: JSON.stringify({
          textroom: 'listparticipants',
          transaction: this.requestParticipantsTransaction,
          pin: this.config.pin,
          room: this.config.room,
        }),
        error: function (reason) {
          console.log(reason);
        },
      });
    },
    registerUser() {
      var transaction = randomString(12);

      var register = {
        textroom: 'join',
        transaction: transaction,
        pin: this.config.pin,
        room: this.config.room,
        username: this.id,
        display: this.username,
      };

      this.transactions[transaction] = (response) => {
        if (response['textroom'] === 'error') {
          // Something went wrong
          if (response['error_code'] === 417) {
            // This is a "no such room" error: give a more meaningful description
            console.log(
              '<p>Apparently room <code>' +
                this.config.room +
                '</code> (the one this demo uses as a test room) ' +
                'does not exist...</p><p>Do you have an updated <code>janus.plugin.textroom.cfg</code> ' +
                'configuration file? If not, make sure you copy the details of room <code>' +
                this.config.room +
                '</code> ' +
                'from that sample in your current configuration file, then restart Janus and try again.'
            );
          } else {
            console.log(response['error']);
          }

          return;
        }
        // We're in
        // Any participants already in?
        if (response.participants && response.participants.length > 0) {
          var participants = {};
          for (var i in response.participants) {
            var p = response.participants[i];
            participants[p.username] = p.display || p.username;
          }

          this.participants = participants;
        }
      };

      this.textroom.data({
        text: JSON.stringify(register),
        error: function (reason) {
          console.log(reason);
        },
      });
    },
    renameUser() {
      this.textroom.data({
        text: JSON.stringify({
          textroom: 'leave',
          transaction: randomString(12),
          room: this.config.room,
          pin: this.config.pin,
          username: this.id,
          display: this.username,
        }),
        success: () => {
          this.textroom.data({
            text: JSON.stringify({
              textroom: 'join',
              transaction: randomString(12),
              pin: this.config.pin,
              room: this.config.room,
              username: this.id,
              display: this.username,
            }),
            error: function (reason) {
              console.log(reason);
            },
          });
        },
        error: (reason) => {
          console.error(reason);
        },
      });
    },
    messageType(item) {
      if (item.type !== 'message') return;

      let classList = '';
      if (item.id == this.id) {
        classList += 'self';
      }
      return classList;
    },
    messageName(item) {
      return this.id == item.id ? 'Me' : item.name;
    },
    sendMessage() {
      if (this.message == '' || this.message == null) {
        this.$store.commit('ERROR', 'Message can not be blank.');
        return;
      }

      var transaction = randomString(12);
      this.textroom.data({
        text: JSON.stringify({
          textroom: 'message',
          transaction: transaction,
          room: this.config.room,
          text: JSON.stringify({
            action: 'message',
            content: this.message,
            createdAt: dayjs(),
          }),
        }),
        error: function (reason) {
          console.log(reason);
        },
      });

      this.message = null;

      this.scrollToBottom();
    },
    hanldeClickSendMessage() {
      this.sendMessage();
    },
    handleSendMessage(e) {
      switch (e.key) {
        case 'Enter':
          this.sendMessage();

          break;
        default:
          break;
      }
    },
    handleClickRaiseHand() {
      var transaction = randomString(12);
      this.textroom.data({
        text: JSON.stringify({
          textroom: 'message',
          transaction: transaction,
          room: this.config.room,
          text: JSON.stringify({ action: 'raise_hand', createdAt: dayjs() }),
        }),
        error: function (reason) {
          console.log(reason);
        },
      });
    },
    handleClickNewMessages() {
      this.autoScroll = true;
      this.scrollToBottom();
    },
    scrollToBottom() {
      if (this.autoScroll) {
        this.scrollTimeout = setTimeout(() => {
          this.$refs.scroll.scrollTo(0, this.$refs.scroll.scrollHeight);
          this.isNewMessages = false;
        }, 100);
      }
    },
    onScroll() {
      const scroll = this.$refs.scroll;
      //console.log(scroll.scrollHeight, scroll.offsetHeight, scroll.scrollHeight - scroll.offsetHeight,scroll.scrollTop);
      if (scroll.scrollHeight - scroll.offsetHeight <= scroll.scrollTop) {
        this.isNewMessages = false;
        this.autoScroll = true;
      } else {
        this.autoScroll = false;
      }
    },
    handlePresenterSelection(presenter) {
      this.message += presenter.name + ' ';
    },
    sendHelpRequest() {
      var operator_username = Object.keys(this.participants).find(
        (key) => this.participants[key] == 'Operator'
      );

      var transaction = randomString(12);
      this.textroom.data({
        text: JSON.stringify({
          textroom: 'message',
          transaction: transaction,
          room: this.config.room,
          text: JSON.stringify({ action: 'help', createdAt: dayjs() }),
          to: operator_username,
        }),
        error: function (reason) {
          console.log(reason);
        },
      });
    },
  },
};
</script>

<style scoped>
.brounce {
  animation-name: brounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes brounce {
  0% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(3px);
  }
}
</style>
