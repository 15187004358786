<template>
  <div>
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full w-full justify-start overflow-x-auto border-b border-grey-100 md:relative"
      >
        <RouterLink
          v-if="allow('settings-branding')"
          :to="'/events/' + event.eventShortcode + '/branding'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Basic
        </RouterLink>
        <RouterLink
          v-if="allow('settings-branding')"
          :to="'/events/' + event.eventShortcode + '/branding/advanced'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Advanced
        </RouterLink>
        <RouterLink
          v-if="allow('settings-branding')"
          :to="'/events/' + event.eventShortcode + '/branding/preview'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Preview
        </RouterLink>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <RouterView @event-updated="handleEventUpdated" :event="event" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['event'],
  methods: {
    handleEventUpdated(newEvent) {
      this.$emit('event-updated', newEvent);
    },
  },
};
</script>
