<template>
  <div class="w-auto bg-white p-8 shadow-lg sm:w-96">
    <div v-if="!saving">
      <h1 class="mb-6 text-center text-3xl font-bold">Forgot password</h1>

      <div class="mb-6 text-center text-grey-400">
        To reset your password, please provide your email address.
      </div>

      <form @submit.prevent="save()">
        <EmailField
          name="email"
          label="Email"
          v-model="form.email"
          :errors="errors"
        />

        <div
          v-if="submitError != null"
          class="mb-1 mt-3 flex flex-row items-center px-2 py-1 text-red-500"
        >
          <i class="material-icons-outlined mr-2 text-red-500">error</i>
          {{ submitError }}
        </div>

        <SubmitButton :saving="saving" label="Send reset instructions" />
      </form>
    </div>
    <div v-else class="text-center">
      <h1 class="font-boild mb-6 text-center text-3xl">Reset password</h1>

      <i class="material-icons-outlined py-10 text-6xl">send</i>

      <p class="mb-3">
        If we found an account associated with that email address we've sent
        password reset instructions.
      </p>
      <p></p>
      <p>Still having trouble logging in?</p>
      <p>
        <a
          class="text-blue-500 hover:text-blue-400"
          href="mailto:support@joinin.live"
          >Contact Support</a
        >
      </p>
    </div>

    <LoginLink label="event" />
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';
import EmailField from '@/components/EmailField';
import SubmitButton from '@/components/SubmitButton';
import LoginLink from '@/components/LoginLink';

export default {
  mixins: [errors],

  components: {
    EmailField,
    SubmitButton,
    LoginLink,
  },

  data() {
    return {
      form: {
        email: null,
      },
      saving: false,
    };
  },

  methods: {
    save() {
      api
        .post(`/account/requestPasswordReset`, {
          email: this.form.email,
        })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.saving = true;
          }
        });
    },
  },
};
</script>
