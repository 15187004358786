<template>
  <div>
    <audio ref="audio" playsinline autoplay muted />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AudioPlayer',
  props: {
    publisherId: { type: [String, Number], required: true },
    janus: { type: Object, required: true },
    isUnmuted: { type: Boolean, required: true },
  },
  data: () => ({
    stream: null,
    handle: null,
  }),
  computed: {
    ...mapState(['event', 'sltAudioOutputDeviceId']),
    isPlaying() {
      return (
        this.$refs.audio.currentTime > 0 &&
        !this.$refs.audio.paused &&
        !this.$refs.audio.ended &&
        this.$refs.audio.readyState > this.$refs.audio.HAVE_CURRENT_DATA
      );
    },
  },
  mounted() {
    this.janusInitSubscriber();
  },
  beforeDestroy() {
    if (this.janus && !this.janus._destroying && this.handle) {
      this.handle.detach();
    }
  },
  watch: {
    userGesture: function (val) {
      if (val && this.$refs.audio) {
        this.$refs.audio.play();
      }
    },
    stream: function (val) {
      if (val) {
        this.$refs.audio.muted = !this.isUnmuted;
        this.$refs.audio.srcObject = this.stream;
        this.$refs.audio.onloadedmetadata = () => {
          //if(this.userGesture && !this.isPlaying) {
          this.$refs.audio.play();
          //}
        };
      }
    },
    isUnmuted: function (val) {
      this.$refs.audio.muted = !val;
    },
    sltAudioOutputDeviceId: function (val) {
      if (val) {
        this.$refs.audio.setSinkId(val);
      }
    },
  },
  methods: {
    async janusInitSubscriber() {
      this.handle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: resolve,
          error: console.error.bind(console),
          onmessage: (msg, jsep) => {
            if (jsep && jsep.type === 'offer') {
              this.handle.createAnswer({
                media: {
                  video: false,
                  audio: true,
                  data: false,
                },
                success: (ansJsep) => {
                  this.handle.send({
                    message: { request: 'start' },
                    jsep: ansJsep,
                  });
                },
                error: console.error.bind(console),
                jsep,
              });
            }
          },
          onremotestream: (stream) => {
            stream.getTracks().forEach((track) => {
              if (track.kind == 'video') {
                track.contentHint = 'motion';
              }
              if (track.kind == 'audio') {
                track.contentHint = 'speech';
              }
            });

            this.stream = stream;
          },
          detached: (a) => {
            console.log('detached', a);
          },
        });
      });

      this.handle.send({
        message: {
          request: 'join',
          ptype: 'subscriber',
          room: String(this.event.videoRoomId),
          pin: this.event.janusPin,
          feed: String(this.publisherId),
          video: false,
          audio: true,
          substream: 2,
        },
      });
    },
  },
};
</script>
