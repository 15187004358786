<template>
  <div class="p-4 md:p-8">
    <form class="mt-8" @submit.prevent="save()">
      <MdTextField
        name="firstName"
        label="First name (required)"
        v-model="form.firstName"
        :errors="errors"
      />

      <MdTextField
        name="lastName"
        label="Last name (required)"
        v-model="form.lastName"
        :required="false"
        :errors="errors"
      />

      <MdEmailField
        name="email"
        label="Email (required)"
        v-model="form.email"
        :errors="errors"
      />

      <div v-if="isNew">
        <MaterialIconsCheckbox
          name="send_reset_email"
          label="Send confirmation email"
          class="mb-6"
          v-model="form.sendEmail"
        />

        <div v-if="form.sendEmail" class="pb-6 text-grey-400">
          A confirmation email with unique access link will be sent to the
          attendee immediately.
        </div>

        <div v-if="!form.sendEmail" class="pb-6 text-grey-400">
          No confirmation email will be sent to the attendee. You must share
          attendee access details manually.
        </div>
      </div>

      <SubmitButton id="btn_new_attendee" :saving="saving" />
    </form>
  </div>
</template>

<script>
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

import api from '@/services/api';
import errors from '@/mixins/errors';
import SubmitButton from '@/components/SubmitButton';
import MdTextField from '@/components/MdTextField';
import MdEmailField from '@/components/MdEmailField';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    SubmitButton,
    MdTextField,
    MdEmailField,
    MaterialIconsCheckbox,
  },

  data() {
    return {
      page: { title: '', content: '' },
      attendeeId: null,
      isNew: false,
      loading: false,
      saving: false,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        company: null,
        division: null,
        position: null,
        country: null,
        state: null,
        city: null,
        phone: null,
        age: null,
        website: null,
        sendEmail: true,
      },
      regoTypePreRegistration: false,
    };
  },

  watch: {
    event: function () {
      this.regoTypePreRegistration =
        this.event.registrationType == 'pre_registration';
    },
  },

  mounted() {
    // TODO: Put this somewhere else?
    this.attendeeId = this.$route.params.attendeeId;
    this.isNew = this.attendeeId == 'new';

    this.load();
  },

  methods: {
    load() {
      this.loading = true;

      if (
        ![
          'automatic_registration',
          'manual_registration',
          'pre_registration',
        ].includes(this.event.registrationType)
      ) {
        this.$router.replace('/dashboard');
        return;
      }

      if (!this.isNew) {
        api
          .get('/jobs/' + this.event.id + '/attendees/' + this.attendeeId)
          .then((response) => {
            this.loading = false;
            assign(this.form, pick(response.data, keys(this.form)));

            //hack state
            //to force state v-model not updated by country assignment
            this.$nextTick(() => {
              this.form.state = response.data.state;
            });
          });
      }
    },

    async save() {
      this.saving = true;

      try {
        const baseUrl = `/jobs/${this.event.id}/attendees`;
        const response = await (this.isNew
          ? api.post(baseUrl, this.form)
          : api.put(`${baseUrl}/${this.attendeeId}`, this.form));
        this.saving = false;
        if (this.isNew) {
          this.attendeeId = response.data.id;
        }

        const { eventShortcode } = this.event;
        this.$router.push(
          `/events/${eventShortcode}/attendee/${this.attendeeId}`
        );

        this.notify(
          'success',
          this.isNew
            ? 'New attendee created and confirmation email sent'
            : 'Attendee updated'
        );
      } catch (e) {
        this.saving = false;
        // TODO: Way to show error permanently?
        this.notify(
          'error',
          e?.response?.data?.userMessage ||
            'Failed to save. Please check all fields and try again.'
        );
      }
    },
    preRegistration() {
      return this.event.registrationType == 'pre_registration';
    },
    backLink() {
      if (this.isNew) {
        return '/events/' + this.event.eventShortcode + '/registration';
      } else {
        return (
          '/events/' +
          this.event.eventShortcode +
          '/attendee/' +
          this.attendeeId
        );
      }
    },
  },
};
</script>
