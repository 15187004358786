<template>
  <div>
    <div class="mb-2 text-lg font-medium">Preview</div>
    <div class="pb-2 text-grey-400">
      Preview your design. Refresh the preview page after saving branding
      changes.
    </div>

    <a
      :href="previewUrl"
      id="link_preview_portal"
      target="_blank"
      class="mb-10 flex cursor-pointer items-center text-blue-500 hover:text-blue-800"
      >Open design preview
      <i
        class="material-icons-outlined pl-2 text-icon-sm text-blue-500 hover:text-blue-800"
        >open_in_new</i
      >
    </a>

    <div class="mb-2 text-lg font-medium">Preview email</div>
    <div class="pb-2 text-grey-400">
      Send a preview email to your address to check the design.
    </div>

    <button
      type="button"
      id="btn_send_preview_email"
      @click.prevent="sendTestEmail"
      class="mb-10 flex items-center text-blue-500 hover:text-blue-800"
    >
      Send preview email
      <i
        class="material-icons-outlined pl-2 text-icon-sm text-blue-500 hover:text-blue-800"
        >send</i
      >
    </button>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: ['event'],

  computed: {
    previewUrl() {
      return (
        this.event &&
        `${process.env.VUE_APP_API_URL}/jobs/${this.event.id}/preview`
      );
    },
  },

  methods: {
    sendTestEmail() {
      api
        .put('/jobs/' + this.event.id + '/sendTestEmail/test')
        .then((response) => {
          this.notify('success', 'Preview email sent');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
