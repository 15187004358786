<template>
  <div class="h-full w-full">
    <div v-if="!videoAvailable">
      <div class="pt-6 text-center text-sm text-grey-400">
        You can view live streams once the event is in Rehearsal mode.
      </div>
    </div>
    <div v-else class="h-full w-full">
      <div class="aspect-ratio-box flex-1">
        <div class="aspect-ratio-box-content relative">
          <CustomVideoPlayer
            v-if="isReady"
            ref="video"
            :stream="stream"
            :controls="['volume', 'mute', 'fullscreen', 'settings']"
            @onSubstreamSelect="handleOptionChange"
            @onScaleDown="handleScaleDown"
            @onScaleUp="handleScaleUp"
          />
          <div
            v-else
            class="flex h-full w-full flex-col items-center justify-center bg-grey-200 text-grey-400"
          >
            <div class="rounded-full bg-grey-100 p-9">
              <i class="material-icons-outlined text-9xl text-grey-400"
                >connected_tv</i
              >
            </div>
            <p class="mt-12">Select video source</p>
          </div>
        </div>
      </div>
      <div
        class="input-shadow mx-1 mt-4 flex items-center rounded-full border bg-white px-4 py-1"
        :class="focused ? 'border-blue-500' : 'border-grey-200'"
      >
        <i class="material-icons-outlined mr-4">connected_tv</i>
        <select
          class="flex-1 pr-2 text-grey-800 group-focus:border-blue-500"
          v-model="selectOption"
          @focus="focused = true"
          @blur="focused = false"
        >
          <option
            v-for="option in filterOptions"
            :key="option.id"
            :value="option.id"
            :disabled="option.id == 0"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomVideoPlayer from '@/components/CustomVideoPlayer.vue';
import { loadJanusJs, trackJanusPublishers } from '@/helper/janus.js';

export default {
  name: 'EventHybrid',
  components: {
    CustomVideoPlayer,
  },
  data: () => ({
    janus: null,
    handle: null,
    stream: null,
    isReady: false,
    isConnected: false,
    focused: false,
    selectOption: 0,
    janusPublishers: {},
  }),
  computed: {
    ...mapState([
      'event',
      'outputs',
      'sources',
      'files',
      'presenters',
      'playlists',
    ]),

    videoAvailable() {
      return ['rehearsal', 'live'].includes(this.event.eventState);
    },
    filterOptions() {
      var options = [{ id: 0, label: 'Select video source' }];

      this.outputs.forEach((r) => {
        options.push({
          id: 'output_' + r.streamId,
          streamId: r.streamId,
          label: r.title,
          type: 'output',
          dataset: r,
        });
      });

      this.presenters.forEach((r) => {
        const isOnline = !!this.janusPublishers[r.pid];
        options.push({
          id: 'presenter_' + r.pid,
          presenterId: r.pid,
          label:
            'Presenter - ' +
            r.name +
            ' [' +
            (isOnline ? 'online' : 'offline') +
            ']',
          type: 'presenter',
          dataset: r,
        });
      });

      this.sources.map((r) => {
        var name = '';
        if (r.mediaId) {
          name = this.files.find((f) => f.fid == r.mediaId)?.title;
        }
        if (r.playlistId) {
          name = this.playlists.find((p) => p.id == r.playlistId)?.name;
        }

        options.push({
          id: 'source_' + r.streamId,
          streamId: r.streamId,
          label: 'Low resolution Preview - ' + name,
          type: 'source',
          dataset: r,
        });
      });

      return options;
    },
  },
  watch: {
    selectOption: function (id) {
      this.isReady = true;
      var option = this.filterOptions.find((option) => option.id == id);
      switch (option.type) {
        case 'output':
        case 'source':
          this.janusWatch(option.streamId);
          break;
        case 'presenter':
          this.janusVideoRoom(option.presenterId);
          break;
      }
    },
    presenters: function (newPresenters, oldPresenters) {
      const addedPresenters = newPresenters.filter(
        (np) => !oldPresenters.some((op) => op.pid == np.pid)
      );
      for (const ap of addedPresenters) {
        if (this.janusPublishers[ap.pid]) {
          this.$store.commit('MESSAGE', `Presenter ${ap.name} is online.`);
        }
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('GET_JOB');
    await this.$store.commit('SETUP_PUSHER_SUBSCRIPTIONS');
    this.janusInit();
    this.janusInitVideoroom();
  },
  beforeDestroy() {
    this._destroying = true;
    if (this.janus) {
      this.janus.destroy();
    }
    this.$store.commit('UNSUBSCRIBE_PUSHER');
  },
  methods: {
    janusInit() {
      loadJanusJs(this.event.janusHost);
      this.janusConnectedPromise = Promise.all([
        window.adapterLoadedPromise,
        window.janusLoadedPromise,
      ]).then(
        () =>
          new Promise((resolve) => {
            if (this._destroying) {
              return void resolve();
            }
            this.janus = new Janus({
              server: this.event.janusHost + '/janus',
              success: resolve,
              error: console.error.bind(console),
              destroyed: () => {
                console.log('destroyed');
              },
            });
          })
      );
    },

    async janusInitVideoroom() {
      await this.janusConnectedPromise;
      if (this._destroying) return;
      this.janus.attach({
        plugin: 'janus.plugin.videoroom',
        success: (handle) => {
          handle.send({
            message: {
              request: 'join',
              ptype: 'publisher',
              room: String(this.event.videoRoomId),
              pin: this.event.janusPin,
              display: 'Hybrid',
            },
          });
        },
        error: (err) => {
          console.log(err);
        },
        onmessage: (msg) => {
          const { removeId, added } = trackJanusPublishers({
            janusPublishers: this.janusPublishers,
            janusMsg: msg,
          });
          if (removeId) {
            const [pType, pTypeId] = removeId.split('-');
            if (pType === 'Presenter') {
              const pub = this.presenters.find((p) => p.pid == pTypeId);
              if (pub) {
                this.$store.commit('ERROR', `Presenter ${pub.name} is offline.`);
              }
            }
          }
          if (added.length > 0) {
            for (const newPub of added) {
              const [pType, pTypeId] = newPub.id.split('-');
              if (pType === 'Presenter') {
                const presenter = this.presenters.find((p) => p.pid == pTypeId);
                if (presenter) {
                  this.$store.commit(
                    'MESSAGE',
                    `Presenter ${presenter.name} is online.`
                  );
                }
              }
            }
          }
        },
      });
    },

    async janusWatch(streamId) {
      await this.janusConnectedPromise;
      if (this._destroying) return;
      if (this.handle) {
        this.handle.detach();
        this.handle = null;
      }
      this.handle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.streaming',
          success: resolve,
          error: console.error.bind(console),
          iceState: (state) => {
            console.log(':::ICE STATE:::', state);
            //if(this.isRestartProcessing == false) {
            if (state == 'disconnected') {
              this.isConnected = false;
              this.reconnectProcess();
            }
            //}
          },
          slowLink: (uplink) => {
            console.log(':::SLOW LINK:::', uplink);
            if (uplink) {
              this.$refs.video.scaleDown();
            }
          },
          onmessage: (msg, jsep) => {
            //console.log("msg", msg);
            //&& this.isInitLoad
            if (jsep) {
              this.handle.createAnswer({
                media: {
                  audioSend: false,
                  videoSend: false,
                  audioRecv: true,
                  videoRecv: true,
                },
                success: (ansJsep) => {
                  this.handle.send({
                    message: { request: 'start' },
                    jsep: ansJsep,
                  });

                  this.handle.send({
                    message: {
                      request: 'configure',
                      substream: 1,
                    },
                  });

                  //allow this createAnswer once to start
                  this.isInitLoad = false;
                },
                error: console.error.bind(console),
                jsep,
              });
            }
          },
          onremotestream: (stream) => {
            //if(!this.tempStream !== stream) {
            //this.tempStream = stream;
            this.isLoaded = true;
            if (this.stream !== stream) {
              this.stream = stream;
            }

            //this.isRestartProcessing = false;
            this.isConnected = true;
            //}
          },
        });
      });

      this.handle.send({
        message: {
          request: 'watch',
          id: streamId,
          pin: this.event.janusPin,
        },
      });
    },

    async janusVideoRoom(feedId) {
      await this.janusConnectedPromise;
      if (this._destroying) return;
      if (this.handle) {
        this.handle.detach();
        this.handle = null;
      }
      this.handle = await new Promise((resolve) => {
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: resolve,
          error: console.error.bind(console),
          onmessage: (msg, jsep) => {
            if (jsep && jsep.type === 'offer') {
              this.handle.createAnswer({
                media: {
                  audioSend: false,
                  videoSend: false,
                  audioRecv: true,
                  videoRecv: true,
                  data: false,
                },
                success: (ansJsep) => {
                  this.handle.send({
                    message: { request: 'start' },
                    jsep: ansJsep,
                  });
                },
                error: console.error.bind(console),
                jsep,
              });
            }
          },
          onremotestream: (stream) => {
            stream.getTracks().forEach((track) => {
              if (track.kind == 'video') {
                track.contentHint = 'motion';
              }
              if (track.kind == 'audio') {
                track.contentHint = 'speech';
              }
            });

            this.stream = stream;
            this.isReady = true;
          },
          detached: (a) => {
            console.log('detached', a);
          },
        });
      });

      this.handle.send({
        message: {
          request: 'join',
          ptype: 'subscriber',
          room: String(this.event.videoRoomId),
          pin: this.event.janusPin,
          feed: String(feedId),
          video: true, //need to be true at init. otherwise the stream is empty?
          audio: true,
          substream: 0,
        },
      });
    },

    handleOptionChange(option) {
      if (option.substream != null) {
        this.handle.send({
          message: {
            request: 'configure',
            substream: option.substream,
          },
        });
      }
    },

    handleScaleDown(option) {
      console.log('Scale down', option);
      if (this.handle) {
        this.handle.send({
          message: {
            request: 'configure',
            substream: option.substream,
          },
        });
      }
    },

    handleScaleUp(option) {
      console.log('Scale up', option);
      if (this.handle) {
        this.handle.send({
          message: {
            request: 'configure',
            substream: option.substream,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.aspect-ratio-box {
  --size: 100%;
  height: 0;
  padding-top: calc(var(--size) / (16 / 9));
  position: relative;
}
.aspect-ratio-box-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
video {
  background: #333;
  width: 100%;
  height: 100%;
}
</style>
