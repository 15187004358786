import Vue from 'vue';
import Vuex from 'vuex';
import Notify from '@/mixins/Notify';
import { initPusher } from '@/services/pusher';
import dayjs from 'dayjs';
import router from '@/router';

import {
  extend,
  findIndex,
  pick,
  sortBy,
  upperFirst,
  debounce,
  fromPairs,
} from 'lodash';

import api from '@/services/api';

// Reuse a single pusher instance across all logins
const pusher = initPusher();

Vue.use(Vuex);

const addSource = (state, payload) => {
  if (
    payload.type == 'Media' &&
    payload.role &&
    ['Background', 'PresenterFallback'].includes(payload.role)
  ) {
    const cb = state.files.find((f) => f.mediaRole == payload.role);
    if (cb) {
      cb.mediaRole = null;
    }

    const index = state.files.findIndex((f) => f.fid == payload.srcObjId);
    state.files[index].mediaRole = payload.role;

    return;
  }

  const newSource = {
    sid: payload.id,
    type: payload.type,
    streamId: payload.streamSlotNo,
    stream: null,
    sourceMuted: false,
    audioLatch: payload.audioLatch,
    loop: payload.loop,
    alphaFilter: payload.elements.alphaFilter,
    play: null,
    playlistPosition: payload.playlistPosition,
    plCtrlPresenterId: payload.plCtrlPresenterId,
    allowPresenterControl: payload.allowPresenterControl,
  };
  let vol, srcObj;
  switch (newSource.type) {
    case 'SRT':
      vol = state.srtEndpoints.find((s) => s.id == payload.srcObjId).volume;
      newSource.srtId = payload.srcObjId;
      break;
    case 'Media':
      vol = state.files.find((s) => s.fid == payload.srcObjId).volume;
      newSource.mediaId = payload.srcObjId;
      break;
    case 'Presenter':
      srcObj = state.presenters.find((s) => s.pid == payload.srcObjId);
      vol = srcObj.volume;
      newSource.sourceMuted = srcObj.muted;
      newSource.presenterId = payload.srcObjId;
      break;
    case 'HTML':
      newSource.htmlId = payload.srcObjId;
      break;
    case 'Playlist':
      newSource.playlistId = payload.srcObjId;
      vol = state.playlists.find((p) => p.id == payload.srcObjId).volume;
      break;
    case 'IoClient':
      srcObj = state.ioClients.find((s) => s.id == payload.srcObjId);
      vol = srcObj.volume;
      newSource.sourceMuted = srcObj.muted;
      newSource.ioClientId = payload.srcObjId;
      break;
  }
  newSource.volume = vol;
  state.sources.push(newSource);

  if (state.sources.length < state.maxSources) {
    state.isAllowAddSource = true;
  } else {
    state.isAllowAddSource = false;
  }
};

function addPresenter(state, payload) {
  state.presenters.push({
    pid: payload.id,
    name: payload.name,
    email: payload.email,
    urlCode: payload.urlCode,
    bitrate: 0,
    forceBitrate: payload.forceBitrate,
    volume: payload.volume,
    muted: payload.muted,
  });
}

function addMediaFile(state, payload, overwrite) {
  const newFile = {
    fid: payload.id,
    title: payload.name,
    filePath: payload.s3Path,
    url: payload.url,
    type: getFileType(payload.s3Path),
    mimeType: payload.mimeType,
    videoLengthMs: payload.videoLengthMs,
    volume: payload.volume,
    section: payload.section,
  };
  if (overwrite) {
    const index = state.files.findIndex((f) => f.fid == payload.id);
    if (index !== -1) {
      const oldFile = state.files[index];
      state.files.splice(index, 1, Object.assign({}, oldFile, newFile));
    }
  } else {
    state.files.push(Object.assign({ mediaRole: null }, newFile));
  }
}

function addPlaylist(state, playlist, replace) {
  const newPlaylist = {
    id: playlist.id,
    name: playlist.name,
    list: playlist.mediaIds,
    type: playlist.subtype,
    uploadState: playlist.uploadState,
    volume: playlist.volume,
  };
  if (replace) {
    const index = state.playlists.findIndex((p) => p.id == playlist.id);
    if (index !== -1) {
      state.playlists.splice(index, 1, newPlaylist);
    }
  } else {
    state.playlists.push(newPlaylist);
  }
}

const getFileType = (s3Path) => {
  const imagePattern = ['jpeg', 'jpg', 'png'];
  const videoPattern = ['mp4', 'm4v', 'mov'];

  const fileExt = s3Path.split('.').pop().toLowerCase();

  if (imagePattern.indexOf(fileExt) !== -1) {
    return 'image';
  } else if (videoPattern.indexOf(fileExt) !== -1) {
    return 'video';
  } else {
    return false;
  }
};

const makePeriodicJob = (proc, interval, plusMinus = 0) => {
  const f = async () => {
    return await proc();
  };
  let timeout = null;

  const nextInterval = plusMinus
    ? () => interval + Math.floor((1 - 2 * Math.random()) * plusMinus)
    : () => interval;

  function stop() {
    clearTimeout(timeout);
  }

  function start() {
    clearTimeout(timeout);
    const startTime = Date.now();
    return f()
      .catch((e) => console.error(e))
      .then(() => {
        const elapsed = Date.now() - startTime;
        timeout = setTimeout(start, Math.max(0, nextInterval() - elapsed));
      });
  }

  function reset() {
    clearTimeout(timeout);
    timeout = setTimeout(start, Math.max(0, nextInterval()));
  }

  return { start, stop, reset };
};

class Reloader {
  constructor() {
    this.jobs = [];
    this.byGroup = {};
  }

  addJob(proc, interval, { groups = [], plusMinus = 0 }) {
    const job = makePeriodicJob(proc, interval, plusMinus);
    this.jobs.push(job);
    groups.forEach((g) => {
      if (!this.byGroup[g]) {
        this.byGroup[g] = [];
      }
      this.byGroup[g].push(job);
    });
  }

  jobGroup(g) {
    return g === null ? this.jobs : this.byGroup[g];
  }

  start(g = null) {
    return Promise.all(this.jobGroup(g).map((j) => j.start()));
  }
  stop(g = null) {
    this.jobGroup(g).forEach((j) => j.stop());
  }
  reset(g = null) {
    this.jobGroup(g).forEach((j) => j.reset());
  }
}

// Not too thrilled about the singleton object with circular reference but it
// works..
let store;
const reloader = new Reloader();

const addReloaderAction = (action, delay, ...groups) => {
  reloader.addJob(() => store && store.dispatch(action), delay, { groups });
};
addReloaderAction('LOAD_MESSAGES', 10_000, 'engagement', 'chat');
addReloaderAction('LOAD_QUESTIONS', 10_000, 'engagement', 'qa');
addReloaderAction('LOAD_POLLS', 10_000, 'engagement', 'polls');
addReloaderAction('LOAD_QUIZ', 10_000, 'engagement', 'quiz');
addReloaderAction(
  'LOAD_QUIZ_LEADERBOARD',
  10_000,
  'engagement',
  'quiz-leaderboard'
);
addReloaderAction('LOAD_STATS', 10_000, 'stats');
addReloaderAction('LOAD_LOGINS', 10_000, 'logins');

const debouncedChangeVolume = debounce(function (url, payload, store) {
  api.post(url, payload).catch((e) => store.commit('ERROR', e?.message || e));
}, 50);

store = new Vuex.Store({
  state: {
    apiOrigin: process.env.VUE_APP_API_URL,
    userGesture: false,
    intended: null,
    sessionData: null,
    pageTitle: null,
    modalOpen: false,
    dropdownOpen: false,
    tooltipModalOpen: false,
    confirmationOpen: false,

    // Mixer
    api: {},
    isSourcesLoaded: false,
    pagesLoaded: false,
    janus: null, //janus instance,
    outputs: [
      {
        //source for video on mixer top left
        oid: 0,
        streamId: 2,
        mixerName: 'preview',
        title: 'Next',
        type: 'media',
        volume: 1,
        countDown: 0,
      },
      {
        //source for video on mixer top right
        oid: 1,
        streamId: 1,
        mixerName: 'program',
        title: 'On Air',
        type: 'media',
        volume: 1,
        countDown: 0,
      },
    ],
    windowWidth: 1920,
    windowHeight: 1080,
    messageType: '', //error message type, which will be reset automatically
    message: '', //notification message, which will be reset automatically
    notifications: [],
    unreadMessageCount: 0, //count the presenter messages unread
    selectedScene: null,
    maxSources: null,
    isAllowAddSource: true,
    trackSlots: {
      //tracking slot used for volume change. slot reset.
      preview: {},
      program: {},
    },
    event: {
      config: {},
      idleShutdownWarning: null,
      idleShutdownWarningMinutes: 15,
    },
    eventStates: [
      'scheduled',
      'rehearsal',
      'live',
      'complete',
      'vod_open',
      'vod_closed',
    ],
    events: [],
    sources: [],
    lastSceneId: null,
    scenes: [],
    presenters: [],
    ioClients: [],
    layouts: [],
    destinations: [],
    coreGstMediaSynced: false,
    files: [],
    srtEndpoints: [],
    htmlSources: [],
    chatMessages: [],
    qaQuestions: [],
    polls: [],
    quiz: null,
    quizLeaderboard: [],
    previousQuizLeaderboard: [],
    pages: [],
    pusher: pusher,
    clientControl: null,
    control: null,
    isLayoutBuildingMode: false,
    selectedSource: null,
    overlayId: null,
    bitrateList: [
      { text: 'Max 128 kbps', value: 128000 },
      { text: 'Max 256 kbps', value: 256000 },
      { text: 'Max 512 kbps', value: 512000 },
      { text: 'Max 768 kbps', value: 768000 },
      { text: 'Max 1 Mbps', value: 1024000 },
      { text: 'Max 1.5 Mbps', value: 1500000 },
      { text: 'Max 3 Mbps', value: 3000000 },
      { text: 'Max 5 Mbps', value: 5000000 },
    ],
    stats: {
      uniqueViewers: 0,
      liveViewers: 0,
      history: [],
    },
    loginData: {},
    triggerSaveScene: false,
    playlists: [],
    sltAudioInputDeviceId: null,
    sltAudioOutputDeviceId: null,
    shortcut: {
      talk: false,
    },
    isQuizBlockReorderSaving: false,
    isQuizBlockQuestionReorderSaving: false,
    fileUploadQueue: [],
    // For hark audio processing. Only one global instance needed
    audioContext: new AudioContext(),
    onlinePresentersCount: 0,
  },

  getters: {
    width:
      (state) =>
      (width, offsetWidth = 1) => {
        return width / (state.windowWidth / offsetWidth);
      },
    height:
      (state) =>
      (height, offsetHeight = 1) => {
        return height / (state.windowHeight / offsetHeight);
      },
    posLeft:
      (state) =>
      (x, offsetWidth = 1) => {
        return x / (state.windowWidth / offsetWidth);
      },
    posTop:
      (state) =>
      (y, offsetHeight = 1) => {
        return y / (state.windowHeight / offsetHeight);
      },
    upperFirst: () => (str) => {
      let regex = /_/gi;
      return upperFirst(str.replace(regex, ' '));
    },
    username: (state) => {
      const user = state.sessionData?.user;
      if (!user) return '(Operator)';
      return `${user.firstName} ${user.lastName} (Operator)`;
    },
    user: (state) => {
      return state.sessionData?.user;
    },
    account: (state) => {
      return state.sessionData?.account;
    },
    isTrialAccount: (state) => {
      return state.sessionData.account.type == 'trial';
    },
    isTrialValid: (state) => {
      if (state.sessionData.account.trialExpiry) {
        return (
          state.sessionData.account.type == 'trial' &&
          dayjs(state.sessionData.account.trialExpiry).isAfter(dayjs())
        );
      } else {
        return false;
      }
    },
    trialExpiry: (state) => {
      // if today, show date and time
      // else show only time
      if (!state.sessionData?.account?.trialExpiry) {
        return false;
      }
      const expiry = dayjs(state.sessionData.account.trialExpiry);
      return expiry.isToday()
        ? expiry.format('h:mma')
        : expiry.format('DD MMM YYYY');
    },
    subscriptionStatus: (state) => {
      return state.sessionData.account.stripeSubscriptionStatus;
    },
    validateScene: (state) => {
      /*=============================================================================================*/
      // validate scene which may cause program video update
      // validation process
      //
      // 1. source: scene doesn't have the program sources, which will cause program sources removed
      //
      // 2. audio latch: scene doesn't have audio latch, which will cause program audio latch removed
      //
      // 3. overlay: scene doesn't have overlay, which will cause program overlay removed
      //
      // 4. source: scene has different program sources, which will cause program sources replaced
      //
      // 5. audio latch: scene has different audio latch, which will cause program audio latch replaced
      //
      // 6. overlay: scene has different overlay, which will cause program overlay replaced
      /*==========================================================================================*/
      //console.log('validate scene', state.selectedScene);
      if (!state.selectedScene) return false;

      const sourceTypeId = {
        Presenter: 'presenterId',
        Media: 'mediaId',
        Playlist: 'playlistId',
      };

      var warning = false;

      //=================================
      //check from program part
      //=================================
      Object.values(state.trackSlots.program).map((p) => {
        const source = state.sources.find((s) => s.sid == p.sourceId);
        if (source) {
          //check program sources
          const incomingProgramSource = state.selectedScene.sources.find(
            (ss) => ss.srcObjId == source[sourceTypeId[source.type]]
          );
          if (!incomingProgramSource) {
            //scene sources doesn't have this program source
            warning = true;
          } else {
            //check when program source is an audio latch
            if (source.audioLatch) {
              if (state.selectedScene.audioLatch.length > 0) {
                //scene has different audio latch
                if (
                  !state.selectedScene.audioLatch.find(
                    (al) => al.srbObjId == source[sourceTypeId[source.type]]
                  )
                ) {
                  warning = true;
                }
              } else {
                //scene doesn't have audio latch
                warning = true;
              }
            }

            //check background / presenter fallback
            if (source.role) {
              if (
                !state.selectedScene.sources.find(
                  (ss) =>
                    ss.role == source.role &&
                    ss.srcObjId == source[sourceTypeId[source.type]]
                )
              ) {
                //scene has different background / presenter fallback
                warning = true;
              }
            }
          }
        }
      });

      if (state.overlayId) {
        if (state.selectedScene.overlay) {
          const source = state.sources.find(
            (s) =>
              s[sourceTypeId[state.selectedScene.overlay.type]] ==
              state.selectedScene.overlay.srcObjId
          );
          if (source && source.sid != state.overlayId) {
            //scene has different overlay
            warning = true;
          }
        } else {
          //scene doesn't have overlay
          warning = true;
        }
      }

      //=================================
      //check from scene part
      //=================================
      if (
        state.selectedScene.sources &&
        state.selectedScene.sources.length > 0
      ) {
        state.selectedScene.sources.map((ss) => {
          if (ss.role) {
            const source = state.files.find(
              (f) => f.mediaRole == ss.role && f.fid == ss.srcObjId
            );
            if (!source) {
              //program has different background/presenter fallback
              warning = true;
            }
          }
        });
      }

      if (
        state.selectedScene.audioLatch &&
        state.selectedScene.audioLatch.length > 0
      ) {
        state.selectedScene.audioLatch.map((al) => {
          const source = state.sources.find(
            (source) =>
              source.audioLatch == true &&
              al.srcObjId == source[sourceTypeId[source.type]]
          );
          if (!source) {
            //program has different audio latch
            warning = true;
          }
        });
      }

      if (state.selectedScene.overlay) {
        const source = state.sources.find(
          (s) =>
            s[sourceTypeId[state.selectedScene.overlay.type]] ==
            state.selectedScene.overlay.srcObjId
        );
        if (source && source.sid != state.overlayId) {
          //program has different overlay
          warning = true;
        }
      }

      return warning;
    },
  },

  mutations: {
    SET_USER_INPUT(state) {
      state.userGesture = true;
    },
    intended(state, payload) {
      state.intended = payload.path;
    },

    setPageTitle(state, payload) {
      document.title = payload;
      state.pageTitle = payload;
    },

    loggedIn(state, sessionData) {
      state.sessionData = {
        user: sessionData.user,
        account: sessionData.account,
        accountTypes: sessionData.accountTypes,
      };
    },

    loggedOut(state) {
      state.sessionData = null;
    },

    modalOpen(state, payload) {
      state.modalOpen = payload;
    },

    dropdownOpen(state, payload) {
      state.dropdownOpen = payload;
    },

    tooltipModalOpen(state, payload) {
      state.tooltipModalOpen = payload;
    },

    confirmationOpen(state, payload) {
      state.confirmationOpen = payload;
    },

    // Mixer
    ERROR(state, message) {
      //not proper way to call mixin function
      //but workable for now
      Notify.methods.notify('error', message);
    },
    MESSAGE(state, message) {
      //block output messages
      if (router.history.current.name.match(/output-/g)) return;

      Notify.methods.notify('success', message);
    },
    WARNING(state, message) {
      //block output error messages
      if (router.history.current.name.match(/output-/g)) return;

      Notify.methods.notify('warning', message);
    },
    REMOVE_NOTIFICATION(state, payload) {
      state.notifications.splice(payload.index, 1);
    },
    SET_API_DATA(state, payload) {
      state.api.username = payload.user.login;
    },
    SET_USER(state, user) {
      state.sessionData.user = Object.assign(
        {},
        state.sessionData.user,
        pick(user, [
          'id',
          'firstName',
          'lastName',
          'email',
          'createdAt',
          'userType',
        ])
      );
    },
    SET_JANUS(state, janus) {
      state.janus = janus;

      //console.log('Janus Init!', state.janus.getSessionId());
    },
    SET_PUSHER_CHANNELS(state, { control, clientControl }) {
      state.control = control;
      state.clientControl = clientControl;
    },
    RESET_JOB_STATE_FROM_BACKEND_DATA(state, event) {
      // Vue reactivity disallows directly adding/assigning object
      // properties in some cases
      // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      state.event = {
        ...{ janusHost: null, coreGstHost: null },
        ...state.event,
        ...event.job,
      };

      state.maxSources = event.job.maxSources;

      state.presenters = [];

      event.presenters.forEach((p) => {
        addPresenter(state, p);
      });

      state.files = [];
      event.mediaFiles.forEach((f) => {
        addMediaFile(state, f);
      });
      state.coreGstMediaSynced = event.coreGstMediaSynced;

      state.playlists = [];
      event.playlists.forEach((p) => {
        addPlaylist(state, p);
      });

      ['srtEndpoints', 'htmlSources', 'pages', 'ioClients'].forEach((f) => {
        state[f] = event[f];
      });

      state.sources = [];
      event.managedSources.forEach((p) => {
        addSource(state, p);
      });
      event.audioLatchSourceIds.forEach((sid) => {
        const source = state.sources.find((s) => s.sid == sid);
        if (!source) return;
        source.audioLatch = true;
      });
      state.isSourcesLoaded = true;

      state.layouts = event.modes;

      state.trackSlots.preview = fromPairs(
        event.previewSources.map((p, i) => [
          i,
          {
            sourceId: p.sourceId,
            volume: p.audio_volume,
            opacity: p.opacity,
            z_index: i,
            crop: p.crop,
            geometry: p.geometry,
          },
        ])
      );
      state.trackSlots.program = fromPairs(
        event.programSources.map((p, i) => [
          i,
          {
            sourceId: p.sourceId,
            volume: p.audio_volume,
            opacity: p.opacity,
            z_index: i,
            crop: p.crop,
            geometry: p.geometry,
          },
        ])
      );

      state.overlayId = event.overlaySourceId || null;

      state.scenes = event.scenes;

      state.lastSceneId = event.lastSceneId;

      state.destinations = [];

      event.destinations.map((des) => {
        var dataset = {
          id: des.id,
          type: des.destType,
          name: des.name,
          uri: des[des.destType].uri,
          videoBitrate: des.videoBitrate,
          running: des.running,
        };

        if (des.destType == 'SRT') {
          dataset.port = des.SRT.port;
          dataset.passphrase = des.SRT.passphrase;
        }

        state.destinations.push(dataset);
      });
    },
    SET_JOB_CONFIG(state, data) {
      state.event.config = data;
      state.event.eventShortcode = data.eventShortcode;
      state.event.jobId = data.id;
    },
    SET_JOBS(state, data) {
      state.events = data;
    },
    RESET_JOB(state) {
      //reset selected event.
      //when back to dashboard
      state.event = {
        config: {},
        idleShutdownWarning: null,
      };
    },
    REMOVE_JOB(state, { id }) {
      const i = findIndex(state.events, (e) => e.id == id);
      if (i >= 0) {
        state.events.splice(i, 1);
      }
    },
    RESET_EVENT(state) {
      // close any open modals
      state.modalOpen = false;
      state.dropdownOpen = false;
      state.sources = [];

      // reload event
      this.dispatch('GET_JOB');
    },
    ADD_SOURCE(state, payload) {
      addSource(state, payload);
    },
    REMOVE_SOURCE(state, payload) {
      let newSlots = { ...state.trackSlots.program },
        changed = false;
      for (const [k, v] of Object.entries(state.trackSlots.program)) {
        if (v.sourceId == payload.id) {
          delete newSlots[k];
          changed = true;
        }
      }
      if (changed) {
        state.trackSlots.program = newSlots;
      }
      (newSlots = { ...state.trackSlots.preview }), (changed = false);
      for (const [k, v] of Object.entries(state.trackSlots.preview)) {
        if (v.sourceId == payload.id) {
          delete newSlots[k];
          changed = true;
        }
      }
      if (changed) {
        state.trackSlots.preview = newSlots;
      }

      var index = state.sources.findIndex((source) => source.sid == payload.id);
      if (index === -1) {
        // Sources with roles aren't tracked in state.sources
        return;
      }
      state.sources.splice(index, 1);

      if (state.sources.length < state.maxSources) {
        state.isAllowAddSource = true;
      } else {
        state.isAllowAddSource = false;
      }

      //this.commit('MESSAGE', `Source ${source.title} has been removed.`);
    },
    ADD_PRESENTER(state, payload) {
      addPresenter(state, payload);
      this.commit('MESSAGE', `Presenter ${payload.name} has been added.`);
    },
    REMOVE_PRESENTER(state, payload) {
      var index = state.presenters.findIndex((p) => p.pid == payload.pid);
      var presenter = state.presenters[index];
      state.presenters.splice(index, 1);

      this.commit('MESSAGE', `Presenter ${presenter.name} has been removed`);
    },
    UPDATE_PRESENTER(state, { id, key, value }) {
      var presenter = state.presenters.find((p) => p.pid === id);
      if (presenter) {
        presenter[key] = value;
      }
    },
    COUNT_UNREAD_MESSAGES(state, count) {
      state.unreadMessageCount = count;
    },
    TOGGLE_SOURCE(state) {
      const ts = state.trackSlots;
      // Swap trackSlots program/preview
      [ts.preview, ts.program] = [ts.program, ts.preview];
    },
    TRACK_PREVIEW(state, payload) {
      state.trackSlots.preview = {
        ...state.trackSlots.preview,
        [payload.slot]: {
          sourceId: payload.sourceId,
          opacity: payload.opacity,
          volume: payload.volume,
          z_index: payload.slot,
          crop: {
            top_edge: payload.top_edge,
            bottom_edge: payload.bottom_edge,
            left_edge: payload.left_edge,
            right_edge: payload.right_edge,
          },
          geometry: {
            x: payload.x,
            y: payload.y,
            x_scale: payload.x_scale,
            y_scale: payload.y_scale,
          },
        },
      };
    },
    RESET_PREVIEW_SLOTS(state) {
      state.trackSlots.preview = {};
    },
    ADJUST_SOURCE_AUDIO(state, payload) {
      let srcObj, srcObjId;
      switch (payload.type) {
        case 'SRT':
          srcObj = state.srtEndpoints.find((s) => s.id == payload.srtId);
          srcObjId = srcObj.id;
          break;
        case 'Media':
          srcObj = state.files.find((s) => s.fid == payload.mediaId);
          srcObjId = srcObj.fid;
          break;
        case 'Presenter':
          srcObj = state.presenters.find((s) => s.pid == payload.presenterId);
          srcObjId = srcObj.pid;
          break;
        case 'IoClient':
          srcObj = state.ioClients.find((i) => i.id == payload.ioClientId);
          srcObjId = srcObj.id;
          break;
        case 'Playlist':
          srcObj = state.playlists.find((s) => s.id == payload.playlistId);

          srcObjId = srcObj.id;
          break;
      }
      srcObj.volume = payload.volume;

      const _payload = {
        type: payload.type,
        srcObjId,
        fields: { volume: payload.volume },
      };
      this.dispatch('CHANGE_VOLUME', _payload);
    },
    RENAME_SOURCE(state, payload) {
      let source = state.sources.find((source) => source.sid == payload.sid);

      source.title = payload.title;

      //this.commit('MESSAGE', `Source ${prevTitle} renamed to ${payload.title}`);
    },
    RENAME_PRESENTER(state, payload) {
      let presenter = state.presenters.find(
        (presenter) => presenter.pid == payload.id
      );

      presenter.name = payload.name;

      this.dispatch('CONTROL', {
        mode: 'presenter_name_update',
        payload: {
          id: presenter.pid,
          name: presenter.name,
        },
      });
    },
    ADD_FILE(state, { newFile, replaceFile }) {
      addMediaFile(state, newFile);
      if (replaceFile) {
        this.commit('MESSAGE', `File ${newFile.name} has been replaced.`);
      } else {
        this.commit('MESSAGE', `File ${newFile.name} has been added.`);
      }
    },
    ADD_PRESENTATION_FILE(state, payload) {
      addPlaylist(state, payload);
      this.commit('MESSAGE', `File ${payload.name} has been added.`);
    },
    REMOVE_FILE(state, payload) {
      let fIndex = state.files.findIndex((file) => file.fid == payload.id);
      if (fIndex !== -1) {
        let file = state.files[fIndex];

        state.files.splice(fIndex, 1);

        this.commit('MESSAGE', `File ${file.title} has been removed.`);
      }
    },
    REMOVE_PRESENTATION_FILE(state, payload) {
      var pIndex = state.playlists.findIndex((p) => p.id == payload.id);
      var playlist = state.playlists[pIndex];

      state.playlists.splice(pIndex, 1);

      this.commit('MESSAGE', `File ${playlist.name} has been removed.`);
    },
    RENAME_FILE(state, payload) {
      var file = state.files.find((file) => file.fid == payload.id);
      if (file) {
        file.title = payload.name;
        this.commit('MESSAGE', `File ${payload.name} has been renamed.`);
      }
    },
    REPLACE_MEDIA_FILE(state, mediaFile) {
      addMediaFile(state, mediaFile, true);
    },
    ADD_SRT_ENDPOINT(state, payload) {
      state.srtEndpoints.push({
        id: payload.id,
        name: payload.name,
        passphrase: payload.passphrase,
        port: payload.port,
        query: payload.query,
      });

      this.commit('MESSAGE', `Stream endpoint ${payload.name} has been added.`);
    },
    RENAME_SRT_ENDPOINT(state, payload) {
      var srt = state.srtEndpoints.find((s) => s.id == payload.id);
      if (srt) {
        srt.name = payload.name;
        this.commit(
          'MESSAGE',
          `Stream endpoint ${payload.name} has been renamed.`
        );
      }
    },
    REMOVE_SRT_ENDPOINT(state, payload) {
      var index = state.srtEndpoints.findIndex((s) => s.id == payload.id);
      var srtEndpoint = state.srtEndpoints[index];
      state.srtEndpoints.splice(index, 1);

      this.commit(
        'MESSAGE',
        `Stream endpoint ${srtEndpoint.name} has been removed.`
      );
    },
    ADD_HTML_SOURCE(state, payload) {
      state.htmlSources.push({
        id: payload.id,
        name: payload.name,
        uri: payload.uri,
      });

      this.commit('MESSAGE', `HTML Source ${payload.name} has been added.`);
    },
    REMOVE_HTML_SOURCE(state, payload) {
      var index = state.htmlSources.findIndex((s) => s.id == payload.id);
      var htmlSource = state.htmlSources.splice(index, 1)[0];

      this.commit(
        'MESSAGE',
        `HTML Source ${htmlSource.name} has been removed.`
      );
    },
    RENAME_HTML(state, payload) {
      var html = state.htmlSources.find((s) => s.id == payload.id);
      if (html) {
        html.name = payload.name;
        this.commit('MESSAGE', `HTML Source ${payload.name} has been renamed.`);
      }
    },
    TOGGLE_SOURCE_AUDIO_INPUT(state, payload) {
      let source = state.sources.find((source) => source.sid == payload.sid);
      source.sourceMuted = !source.sourceMuted;
      let srcObj, srcObjId;
      switch (source.type) {
        case 'Presenter':
          srcObj = state.presenters.find((s) => s.pid == source.presenterId);
          srcObjId = srcObj.pid;
          break;
        case 'IoClient':
          srcObj = state.ioClients.find((i) => i.id == source.ioClientId);
          srcObjId = srcObj.id;
          break;
      }
      srcObj.muted = source.sourceMuted;

      const url = `/mixer/${state.event.jobId}/editMedia`;
      const _payload = {
        type: source.type,
        srcObjId,
        fields: { muted: source.sourceMuted },
      };
      api
        .post(url, _payload)
        .catch((e) => store.commit('ERROR', e?.message || e));
    },
    UPDATE_SOURCE(state, payload) {
      //console.log('UPDATE source', payload);
      //update source
      let source = state.sources.find((source) => source.sid == payload.id);

      //let prevTitle = source.title
      //source.title = payload.title;
      if (source) {
        if ('loop' in payload) {
          source.loop = payload.loop;
        }

        if ('audioLatch' in payload) {
          source.audioLatch = payload.audioLatch;
        }

        if ('mediaId' in payload) {
          source.mediaId = payload.mediaId;
        }

        if ('alphaFilter' in payload) {
          source.alphaFilter = payload.alphaFilter;
        }
      }
    },
    SELECT_SCENE(state, scene) {
      if (state.selectedScene == scene) {
        state.selectedScene = null;
      } else {
        state.selectedScene = scene;
      }
    },
    SET_SELECT_SCENE(state, scene) {
      state.selectedScene = scene;
    },
    SET_SOURCE_STATE(state, payload) {
      var source = state.sources.find((s) => s.sid == payload.sid);
      if (source) {
        source[payload.key] = payload.value;
      }
    },
    SET_LAYOUT_BUILDING_ON(state) {
      state.isLayoutBuildingMode = true;
    },
    SET_LAYOUT_BUILDING_OFF(state) {
      state.isLayoutBuildingMode = false;

      //reset selected source
      state.selectedSource = null;
    },
    RESET_SELECT_SOURCE(state) {
      state.selectedSource = null;
    },
    SELECT_SOURCE(state, payload) {
      state.selectedSource = payload;
    },
    SET_EVENT_STATE(state, payload) {
      state.event.eventState = payload;

      if (state.event.eventState == 'rehearsal') {
        this.commit('RESET_EVENT');
        this.commit(
          'SET_VM_HOSTS',
          pick(payload, ['janusHost', 'coreGstHost'])
        );
      }

      if (['rehearsal', 'live'].includes(state.event.eventState)) {
        state.isAllowAddSource = true;
      } else {
        state.isAllowAddSource = false;
      }
    },
    SET_EVENT_MEDIA_STATE(state, payload) {
      state.coreGstMediaSynced = payload.synced;
      if (payload.synced) {
        this.commit('MESSAGE', `Library media is synced and ready to use`);
      }
    },
    SET_JOB_IDLE_SHUTDOWN_TIMEOUT(state, idleShutdownTimeout) {
      state.event.idleShutdownTimeout = idleShutdownTimeout;
    },
    SET_JOB_IDLE_SHUTDOWN_WARNING(state, idleShutdownWarning) {
      state.event.idleShutdownWarning = idleShutdownWarning;
    },
    SET_VM_HOSTS(state, { janusHost, coreGstHost }) {
      state.event.janusHost = janusHost;
      state.event.coreGstHost = coreGstHost;
    },
    SAVE_SCENE(state, payload) {
      state.scenes.push(payload);
      this.commit('MESSAGE', `Scene ${payload.name} has been added`);
    },
    RENAME_SCENE(state, payload) {
      var scene = state.scenes.find((s) => s.id == payload.id);
      if (scene) {
        var oldName = scene.name;
        scene.name = payload.name;

        this.commit(
          'MESSAGE',
          `Scene ${oldName} has been renamed to ${scene.name}`
        );
      }
    },
    REMOVE_SCENE(state, id) {
      var sceneIdx = state.scenes.findIndex((s) => s.id == id);
      if (sceneIdx !== -1) {
        var oldName = state.scenes[sceneIdx].name;
        state.scenes.splice(sceneIdx, 1);

        this.commit('MESSAGE', `Scene ${oldName} has been removed`);
      }
    },
    REPLACE_SCENE(state, scene) {
      const index = state.scenes.findIndex((s) => s.id == scene.id);
      if (index !== -1) {
        state.scenes.splice(index, 1, scene);
      }
    },
    UPDATE_PREVIEW_TRACK(state, payload) {
      state.trackSlots.preview = fromPairs(
        payload.map((p, i) => [
          i,
          {
            sourceId: p.sourceId,
            volume: p.audio_volume,
            opacity: p.opacity,
            z_index: i,
            crop: p.crop,
            geometry: p.geometry,
          },
        ])
      );
    },

    UPDATE_OVERLAY(state, payload) {
      if (state.overlayId == payload.id) {
        state.overlayId = null;
      } else {
        state.overlayId = payload.id;
      }
    },

    SET_PAGES(state, pages) {
      state.pages = pages;
      state.pagesLoaded = true;
    },

    SET_SCENES(state, scenes) {
      state.scenes = scenes;
      state.scenesLoaded = true;
    },

    REPLACE_PAGE(state, page) {
      state.pages = state.pages.filter((p) => p.id != page.id).concat([page]);
    },

    REMOVE_PAGE(state, id) {
      state.pages = state.pages.filter((p) => p.id != id);
    },

    // ENGAGEMENT
    SET_MESSAGES(state, messages) {
      state.chatMessages = messages;
    },

    REPLACE_MESSAGE(state, message) {
      // Just take advantage of sorting in the view...
      state.chatMessages = state.chatMessages
        .filter((m) => m.id != message.id)
        .concat([message]);
    },

    REPLACE_MESSAGE_REPLY(state, { messageId, reply }) {
      state.chatMessages = state.chatMessages.map((m) =>
        m.id != messageId
          ? m
          : {
              ...m,
              replies: sortBy(
                m.replies.filter((r) => r.id != reply.id).concat([reply]),
                'createdAt'
              ),
            }
      );
    },

    REMOVE_MESSAGE_REPLY(state, { messageId, id }) {
      state.chatMessages = state.chatMessages.map((m) =>
        m.id != messageId
          ? m
          : {
              ...m,
              replies: sortBy(
                m.replies.filter((r) => r.id != id),
                'createdAt'
              ),
            }
      );
    },

    SET_MESSAGE_OUTPUT(state, messageId) {
      state.chatMessages.forEach((m) => {
        m.isOnScreen = m.id == messageId;
      });
    },

    SET_QUESTIONS(state, questions) {
      state.qaQuestions = questions;
    },

    SET_QUIZ(state, quiz) {
      state.quiz = quiz;
    },

    SET_QUIZ_LEADERBOARD(state, quizLeaderboard) {
      state.quizLeaderboard = quizLeaderboard;
    },

    UPDATE_QUIZ_ACTIVE_QUESTION_ANSWERED(state, payload) {
      state.quiz.blocks.map((block) => {
        const question = block.questions?.find(
          (q) => q.id == +payload.questionId
        );
        if (question) {
          question.answered = payload.answered;
        }
      });
    },

    REPLACE_QUESTION(state, question) {
      state.qaQuestions = state.qaQuestions
        .filter((q) => q.id != question.id)
        .concat([question]);
    },

    REPLACE_QUESTION_REPLY(state, { questionId, reply }) {
      state.qaQuestions = state.qaQuestions.map((q) =>
        q.id != questionId
          ? q
          : {
              ...q,
              replies: sortBy(
                q.replies.filter((r) => r.id != reply.id).concat([reply]),
                'createdAt'
              ),
            }
      );
    },

    REMOVE_QUESTION_ANSWER(state, { questionId, id }) {
      state.qaQuestions = state.qaQuestions.map((q) =>
        q.id != questionId
          ? q
          : {
              ...q,
              replies: sortBy(
                q.replies.filter((r) => r.id != id),
                'createdAt'
              ),
            }
      );
    },

    SET_QUESTION_OUTPUT(state, { questionId, isOnScreen }) {
      state.qaQuestions.map((q) => (q.isOnScreen = false));

      if (questionId) {
        var question = state.qaQuestions.find((q) => q.id == questionId);
        question.isOnScreen = isOnScreen;
      }
    },

    SET_POLLS(state, polls) {
      state.polls = polls;
    },

    ADD_POLL(state, poll) {
      state.polls.push(poll);
    },

    UPDATE_POLL(state, poll) {
      const i = findIndex(state.polls, (p) => p.id == poll.id);
      if (i >= 0) {
        const { options, ...rest } = poll;
        const current = state.polls[i];
        extend(current, rest);
        current.options = options.map((o) =>
          extend(find(current.options, { id: o.id }), o)
        );
      }
      if (poll.isLive) {
        for (let j = 0; j < state.polls.length; j++) {
          if (j != i) state.polls[j].isLive = false;
        }
      }
    },

    DELETE_POLL(state, id) {
      const i = findIndex(state.polls, (p) => p.id == id);
      if (i >= 0) {
        state.polls.splice(i, 1);
      }
    },

    STOP_POLLS(state) {
      state.polls.forEach((p) => (p.isLive = false));
    },

    SET_STATS(state, data) {
      state.stats = data;
    },

    SET_LOGINS(state, data) {
      state.loginData = data;
    },

    TRIGGER_SAVE_SCENE(state) {
      state.triggerSaveScene = true;
      setTimeout(() => {
        state.triggerSaveScene = false;
      }, 1000);
    },
    UPDATE_PLAYLIST(state, payload) {
      const playlist = state.playlists.find((p) => p.id == payload.id);
      if (playlist) {
        playlist.list = payload.mediaIds;
        playlist.uploadState = payload.uploadState;
      }

      this.commit('MESSAGE', `${playlist.name} playlist has been updated.`);
    },
    UPDATE_PLAYLIST_STATUS(state, payload) {
      var playlist = state.playlists.find((p) => p.id == payload.playlistId);
      if (playlist) {
        playlist.uploadState = payload.uploadState;
      }
    },
    UPDATE_PLAYLIST_NAME(state, payload) {
      var playlist = state.playlists.find((p) => p.id == payload.id);
      if (playlist) {
        playlist.name = payload.name;
      }
    },
    REPLACE_PLAYLIST(state, playlist) {
      addPlaylist(state, playlist, true);
    },
    SET_PLAYLIST_POSITION(state, payload) {
      var source = state.sources.find((s) => s.sid == payload.sourceId);
      if (source) {
        source.playlistPosition = payload.playlistPosition;
      }
    },
    SET_PLAYLIST_CONTROL(state, payload) {
      var source = state.sources.find((s) => s.sid == payload.sourceId);
      if (source) {
        source.allowPresenterControl = payload.allowPresenterControl;
      }
    },
    SET_PRESENTER_TO_PLAYLIST_SOURCE(state, payload) {
      if (payload.presenterId != null) {
        state.sources.map((s) => {
          if (s.plCtrlPresenterId == payload.presenterId) {
            s.plCtrlPresenterId = null;
          }
        });
      }

      var source = state.sources.find((s) => s.sid == payload.sourceId);
      if (source) {
        source.plCtrlPresenterId = payload.presenterId;
      }
    },
    SET_LAST_USED_SCENE_ID(state, sceneId) {
      state.lastSceneId = sceneId;

      const scene = state.scenes.find((s) => s.id == sceneId);
      this.commit('MESSAGE', `Scene ${scene.name} loaded successfully`);
    },
    SET_AUDIO_INPUT_DEVICE(state, device_id) {
      state.sltAudioInputDeviceId = device_id;
    },
    SET_AUDIO_OUTPUT_DEVICE(state, device_id) {
      state.sltAudioOutputDeviceId = device_id;
    },
    UPDATE_SCENE_SHORTCUT(state, payload) {
      var scene = state.scenes.find((s) => s.id == payload.id);
      if (scene) {
        scene.shortcut = payload.shortcut;
        this.commit(
          'MESSAGE',
          `Scene keyboard shortcut ${payload.shortcut ? `added` : `removed`}`
        );
      }
    },
    SYNC_QUIZ(state, payload) {
      state.quiz = payload;
    },
    SET_QUIZ_DISPLAY(state, payload) {
      state.quiz.screenStateCode = payload;
    },
    RECORD_CURRENT_QUIZ_LEADERBOARD(state) {
      state.previousQuizLeaderboard = state.quizLeaderboard;
    },
    ACTIVATE_SHORTCUT(state, payload) {
      state.shortcut[payload] = true;
    },
    DEACTIVATE_SHORTCUT(state, payload) {
      state.shortcut[payload] = false;
    },
    SET_SURVEY_STATE(state, payload) {
      state.event.config.surveyActivated = payload;
    },
    SET_QUIZ_BLOCK_REORDER_UPDATING(state, payload) {
      state.isQuizBlockReorderSaving = payload;
    },
    SET_QUIZ_BLOCK_QUESTION_REORDER_UPDATING(state, payload) {
      state.isQuizBlockQuestionReorderSaving = payload;
    },
    SET_PAGES_REORDER_UPDATING(state, payload) {
      state.isPagesReorderSaving = payload;
    },
    SET_SCENES_REORDER_UPDATING(state, payload) {
      state.isScenesReorderSaving = payload;
    },
    ADD_PLAYLIST(state, payload) {
      state.playlists.push({
        id: payload.id,
        name: payload.name,
        list: payload.mediaIds,
        type: null, //what is the type here other than fixed
        uploadState: 'ready', //suspect this is always ready for custom playlist as files were from media library
      });

      this.commit('MESSAGE', `Playlist ${payload.name} has been added.`);
    },
    REMOVE_PLAYLIST(state, payload) {
      const pIndex = state.playlists.findIndex((pl) => pl.id == payload.id);
      if (pIndex !== -1) {
        const name = state.playlists[pIndex].name;
        state.playlists.splice(pIndex, 1);
        this.commit('MESSAGE', `Playlist ${name} has been removed.`);
      }
    },
    SET_PRESENTER_MUTE_STATE(state, payload) {
      const presenter = state.presenters.find((p) => p.pid == payload.pid);
      if (presenter) {
        presenter.muted = payload.muted;
      }
    },
    ADD_FILE_TO_UPLOAD(state, payload) {
      state.fileUploadQueue.push(payload);
    },
    REMOVE_FILE_FROM_UPLOAD(state, index) {
      state.fileUploadQueue.splice(index, 1);
    },
    ADD_DESTINATION(state, payload) {
      var dataset = {
        id: payload.id,
        name: payload.name,
        videoBitrate: payload.videoBitrate,
        type: payload.type,
      };

      if (payload.type == 'RTMP') {
        dataset.uri = payload.uri;
      } else if (payload.type == 'SRT') {
        dataset.port = payload.port;
        dataset.passphrase = payload.passphrase;
      }

      state.destinations.push(dataset);

      this.commit('MESSAGE', `Outbound stream ${payload.name} has been added.`);
    },
    REMOVE_DESTINATION(state, id) {
      const dIndex = state.destinations.findIndex((d) => d.id == id);
      var destination = state.destinations[dIndex];

      state.destinations.splice(dIndex, 1);

      this.commit(
        'MESSAGE',
        `Outbound stream ${destination.name} has been removed.`
      );
      destination = null;
    },
    SET_ONLINE_PRESENTERS_COUNT(state, count) {
      state.onlinePresentersCount = count;
    },
  },

  actions: {
    // Mixer
    async GET_JOB({ commit, state }) {
      const url = `/mixer/${state.event.jobId}/jobState`;

      try {
        const event = (await api(url)).data;

        if (event) {
          commit('RESET_JOB_STATE_FROM_BACKEND_DATA', event);
        } else {
          commit('ERROR', 'Failed to fetch job.');
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async GET_JOB_CONFIG({ commit, state }) {
      try {
        const res = await api.get(
          `/jobs/settingsData/${state.event.eventShortcode}`
        );

        if (res.status >= 200 && res.status < 300) {
          this.commit('SET_JOB_CONFIG', res.data);
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async GET_JOB_IDLE_SHUTDOWN_TIMEOUT({ commit, state }) {
      try {
        const res = await api.get(
          `/jobs/${state.event.jobId}/idleShutdownTimeout`
        );

        if (res.status >= 200 && res.status < 300) {
          this.commit(
            'SET_JOB_IDLE_SHUTDOWN_TIMEOUT',
            res.data.idleShutdownTimeout
          );

          if (
            res.data.idleShutdownTimeout - Date.now() <=
              state.event.idleShutdownWarningMinutes * 60000 &&
            ['rehearsal', 'live'].includes(state.event.eventState)
          ) {
            this.commit('SET_JOB_IDLE_SHUTDOWN_WARNING', true);
          }
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async RESET_JOB_IDLE_SHUTDOWN_TIMEOUT({ commit, state }) {
      const url = `/jobs/${state.event.jobId}/resetIdleShutdownTimeout`;
      const res = await api.post(url);

      if (res.status >= 200 && res.status < 300) {
        this.commit(
          'SET_JOB_IDLE_SHUTDOWN_TIMEOUT',
          res.data.idleShutdownTimeout
        );
        this.commit('SET_JOB_IDLE_SHUTDOWN_WARNING', false);
      } else {
        commit('ERROR', 'Event shutdown override failed.');
      }
    },
    async GET_JOBS({ commit }) {
      const url = `/jobs`;

      try {
        const res = await api.get(url);

        if (res.status >= 200 && res.status < 300) {
          commit('SET_JOBS', res.data);
        } else {
          commit('ERROR', 'Failed to fetch jobs.');
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async DUPLICATE_JOB({ commit }, payload) {
      try {
        const res = await api.post(`/jobs/${payload.id}/cloneJob`, {
          title: payload.name,
          presenters: payload.presenters,
          pages: payload.pages,
        });

        if (res.status >= 200 && res.status < 300) {
          this.dispatch('GET_JOBS');
          commit('MESSAGE', 'Event duplicated');
        } else {
          throw new Error();
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', 'Unexpected error deleting job');
      }
    },
    async REMOVE_JOB({ commit }, { id }) {
      try {
        const res = await api.delete(`/jobs/${id}`);
        if (res.status >= 200 && res.status < 300) {
          commit('REMOVE_JOB', { id });
          commit('MESSAGE', 'Event deleted');
        } else {
          throw new Error();
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', 'Unexpected error deleting job');
      }
    },
    async CONTROL_INIT({ dispatch }) {
      await dispatch('SETUP_PUSHER_SUBSCRIPTIONS');

      reloader.start('engagement');
      reloader.start('stats');
    },
    CONTROL_LEAVE({ commit, dispatch }) {
      reloader.stop('engagement');
      reloader.stop('stats');
      commit('SET_MESSAGES', []);
      commit('SET_QUESTIONS', []);
      commit('SET_POLLS', []);
      commit('SET_QUIZ', null);
      commit('SET_QUIZ_LEADERBOARD', []);

      //unsubscribe pusher channels
      dispatch('UNSUBSCRIBE_PUSHER');
    },
    CONTROL({ state }, payload) {
      state.clientControl.trigger('client-control', payload);
    },
    async ADD_SOURCE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addManagedSource`;

      try {
        await api.post(url, payload);
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async REMOVE_SOURCE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/managedSource/${payload.id}`;

      try {
        await api.delete(url);
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async ADD_PRESENTER_SOURCE({ commit, state }, payload) {
      try {
        if (state.sources.find((slot) => slot.presenterId == payload.pid)) {
          //escape if presenter exists in a slot
          commit('ERROR', `Presenter assigned already.`);
          return;
        }

        let url = `/mixer/${state.event.jobId}/addManagedSource`;
        let res = await api.post(url, {
          type: 'Presenter',
          presenterId: payload.pid,
        });
        if (res.status != 200) {
          return void commit('ERROR', 'Presenter addManagedSource failed');
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async REMOVE_FILE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/media/${payload.id}`;

      try {
        const res = await api.delete(url);
        if (res.status >= 200 && res.status < 300 && res.data == 'ok') {
          commit('REMOVE_FILE', payload);
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    async REMOVE_PRESENTATION_FILE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/playlist/${payload.id}`;

      try {
        const res = await api.delete(url);

        if (res.status >= 200 && res.status < 300 && res.data == 'ok') {
          commit('REMOVE_PRESENTATION_FILE', payload);
        }
      } catch (e) {
        console.error(e);
        commit('ERROR', e);
      }
    },
    SEND_TO_PREVIEW({ commit, state }, payload) {
      commit('RESET_PREVIEW_SLOTS');

      var slots = [];
      payload.map((row) => {
        slots.push({
          slot: row.slotNo,
          sourceId: row.sourceId,
          x: this.getters.posLeft(row.x),
          y: this.getters.posTop(row.y),
          x_scale: this.getters.width(row.width) || 1.0,
          y_scale: this.getters.height(row.height) || 1.0,
          crop_left: this.getters.width(row.cropLeft) || 0,
          crop_right: this.getters.width(row.cropRight) || 0,
          crop_top: this.getters.height(row.cropTop) || 0,
          crop_bottom: this.getters.height(row.cropBottom) || 0,
          opacity: row.opacity,
        });
      });

      const url = `/mixer/${state.event.jobId}/setPreviewSlots`;
      return api
        .post(url, { slots })
        .then(async (result) => {
          if (result.status != 200) {
            commit('ERROR', result.data);
          } else {
            /*payload.map(row => {
                        commit('TRACK_PREVIEW', {
                            slotKey: row.slotNo,
                            sourceId: row.sourceId,

                        });
                    });*/

            slots.map((slot) => {
              commit('TRACK_PREVIEW', slot);
            });
          }
        })
        .catch((e) => {
          commit('ERROR', e);
        });
    },
    CHANGE_VOLUME({ state }, payload) {
      const url = `/mixer/${state.event.jobId}/editMedia`;
      debouncedChangeVolume(url, payload, this);
    },
    SWITCH_PREVIEW_PROGRAM({ commit, state }, cutMode) {
      commit('TOGGLE_SOURCE');
      let fadeDuration = 0;

      switch (cutMode) {
        case 'fade':
          fadeDuration = 0.25;
          break;
        case 'slow fade':
          fadeDuration = 1;
          break;
        default:
          break;
      }

      // Backend plays videos in preview when switching to program
      Object.values(state.trackSlots.program).map((row) => {
        var source = state.sources.find((s) => s.sid == row.sourceId);
        if (source.type == 'Media') {
          //image play should be ignored.
          source.play = true;
        }
      });

      const url = `/mixer/${state.event.jobId}/switchProgramPreview`;
      api
        .post(url, { fadeDuration })
        .catch((e) => commit('ERROR', e?.message || e));
      //commit('MESSAGE', `Preview pushed to program.`);
    },
    async ADD_SRT_ENDPOINT({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addSrtEndpoint`;

      const res = await api.post(url, { name: payload.name });

      if (res.status >= 200 && res.status < 300) {
        commit('ADD_SRT_ENDPOINT', res.data);
      } else {
        commit('ERROR', 'Stream endpoint creation failed.');
      }
    },
    async REMOVE_SRT_ENDPOINT({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/srtEndpoint/${payload.id}`;

      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        commit('REMOVE_SRT_ENDPOINT', payload);
      } else {
        commit('ERROR', 'Stream endpoint removal failed.');
      }
    },
    async ADD_HTML_SOURCE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addHtmlSource`;

      const res = await api.post(url, {
        name: payload.name,
        uri: payload.uri,
      });

      if (res.status >= 200 && res.status < 300) {
        commit('ADD_HTML_SOURCE', res.data);
      } else {
        commit('ERROR', 'HTML source creation failed.');
      }
    },
    async REMOVE_HTML_SOURCE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/htmlSource/${payload.id}`;
      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        commit('REMOVE_HTML_SOURCE', payload);
      } else {
        commit('ERROR', 'HTML source removal failed.');
      }
    },
    async ADD_PRESENTER({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addPresenter`;

      const res = await api.post(url, {
        name: payload.name,
        email: payload.email,
        sendInv: payload.sendInv,
      });

      if (res.status >= 200 && res.status < 300) {
        commit('ADD_PRESENTER', res.data);
      } else {
        commit('ERROR', 'Presenter creation failed.');
      }
    },
    async REMOVE_PRESENTER({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/presenter/${payload.pid}`;

      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        this.dispatch('CONTROL', {
          mode: 'remove_presenter',
          payload: {
            id: payload.pid,
          },
        });

        commit('REMOVE_PRESENTER', payload);
      } else {
        commit('ERROR', 'Presenter removal failed.');
      }
    },
    async REMOVE_PRESENTER_SOURCE({ state }, payload) {
      const source = state.sources.find((s) => s.presenterId == payload.pid);
      return this.dispatch('REMOVE_SOURCE', { id: source.sid });
    },
    async ADD_AUDIO_LATCH({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addAudioLatch`;
      // TODO: Sort out what exactly the Axios wrapper does in the case
      //       we are interested in -- thow, or a success but not 200?
      try {
        await api.post(url, {
          sourceId: payload.id,
          audioLatch: payload.audioLatch,
        });
      } catch (e) {
        // We suspect that this may be the request object, as per the
        // Axios docs:
        if (e?.data?.error?.match(/Source '\d*' already latched/g)) {
          commit('UPDATE_SOURCE', payload);
        }
        commit('ERROR', 'Audio latch failed.');
      }
    },
    async REMOVE_AUDIO_LATCH({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/audioLatch/${payload.id}`;
      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        commit('UPDATE_SOURCE', payload);
      } else {
        commit('ERROR', 'Audio unlatch failed.');
      }
    },
    async RESET_AUDIO_LATCH({ commit, state }) {
      state.sources.map(async (source) => {
        if (source.audioLatch == true) {
          const url = `/mixer/${state.event.jobId}/audioLatch/${source.sid}`;
          let res = await api.delete(url);

          if (res.status >= 200 && res.status < 300) {
            commit('UPDATE_SOURCE', {
              id: source.sid,
              audioLatch: false,
            });
          } else {
            commit('ERROR', 'Audio unlatch failed.');
          }
        }
      });
    },
    async TOGGLE_OVERLAY({ state }, payload) {
      if (payload.id == state.overlayId) {
        await api.delete(`/mixer/${state.event.jobId}/overlay`);
      } else {
        const url = `/mixer/${state.event.jobId}/setOverlay`;
        await api.post(url, { sourceId: payload.id });
      }
    },
    async RESET_OVERLAY({ state }) {
      const url = `/mixer/${state.event.jobId}/overlay`;
      await api.delete(url);
    },
    async ADD_ALPHA_FILTER({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/addAlphaFilter`;
      const res = await api.post(url, {
        sourceId: payload.id,
        rgb: payload.rgb,
      });

      if (res.status >= 200 && res.status < 300) {
        commit('UPDATE_SOURCE', payload);
        commit('MESSAGE', `Background removed`);
      } else {
        commit('ERROR', `Background could not be removed`);
      }
    },
    async REMOVE_ALPHA_FILTER({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/alphaFilter/${payload.id}`;
      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        commit('UPDATE_SOURCE', payload);
        commit('MESSAGE', `Background restored`);
      } else {
        commit('ERROR', `Alpha filter could not be removed`);
      }
    },
    async RENAME_SOURCE({ commit, state }, payload) {
      const url = `/mixer/${state.event.jobId}/editManagedSource`;
      const res = await api.post(url, {
        id: payload.sid,
        name: payload.title,
      });

      if (res.status >= 200 && res.status < 300) {
        commit('RENAME_SOURCE', payload);
      } else {
        commit('ERROR', 'Rename failed');
      }
    },
    async RENAME_MEDIA(ctx, payload) {
      try {
        const url = `/mixer/${ctx.state.event.jobId}/editMedia`;

        const res = await api.post(url, {
          type: payload.type,
          srcObjId: payload.id,
          fields: { name: payload.name },
        });

        if (res.status >= 200 && res.status < 300) {
          switch (payload.type) {
            case 'Presenter':
              ctx.commit('RENAME_PRESENTER', payload);

              var source = ctx.state.sources.find(
                (source) => source.presenterId == payload.id
              );
              if (source) {
                this.dispatch('RENAME_SOURCE', {
                  sid: source.sid,
                  title: payload.name,
                });
              }

              break;
            case 'Media':
              ctx.commit('RENAME_FILE', payload);
              break;
            case 'HTML':
              ctx.commit('RENAME_HTML', payload);
              break;
            case 'SRT':
              ctx.commit('RENAME_SRT_ENDPOINT', payload);
              break;
          }
        } else {
          throw new Error('Rename failed');
        }
      } catch (e) {
        if (e.response.data.message == 'duplicated media file name') {
          ctx.commit(
            'ERROR',
            `The filename ${payload.name} is already taken. Please choose a different name.`
          );
        } else {
          ctx.commit('ERROR', 'Rename failed');
        }
      }
    },
    async EVENT_STATE_VOD_OPEN({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'vod_open',
        });
      } catch (e) {
        console.error(e);
        commit('ERROR', e.message || e);
      }
    },
    async EVENT_STATE_VOD_CLOSED({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'vod_closed',
        });
      } catch (e) {
        console.error(e);
        commit('ERROR', e.message || e);
      }
    },
    async EVENT_STATE_SCHEDULED({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'scheduled',
        });
        this.dispatch('GET_JOB_CONFIG');
      } catch (e) {
        console.error(e);
        commit('ERROR', e.message || e);
      }
    },
    async EVENT_STATE_REHEARSAL({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'rehearsal',
        });
      } catch (e) {
        console.error(e);
        if (e.response.data.error == 'Max concurrent jobs reached') {
          commit(
            'ERROR',
            `Max active job limit reached. Upgrade your account or stop a running event to continue.`
          );
          router.replace('/dashboard');
        } else {
          commit('ERROR', e.message || e);
        }
      }
    },
    async EVENT_STATE_LIVE({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'live',
        });
      } catch (e) {
        console.error(e);
        commit('ERROR', e.message || e);
      }
    },
    async EVENT_STATE_COMPLETE({ commit, state }) {
      try {
        const url = `/jobs/${state.event.jobId}/setEventState`;
        await api.post(url, {
          state: 'complete',
        });
      } catch (e) {
        console.error(e);
        commit('ERROR', e.message || e);
      }
    },
    async SAVE_SCENE(ctx, payload) {
      try {
        const url = `mixer/${ctx.state.event.jobId}/saveScene`;
        const res = await api.post(url, {
          mixer: 'preview',
          name: payload.name,
          shortcut: payload.shortcut,
        });

        if (res.status >= 200 && res.status < 300) {
          this.commit('SAVE_SCENE', res.data);
        }
      } catch (e) {
        console.error(e);
        if (e.response.data.message == 'duplicated shortcut') {
          this.commit(
            'ERROR',
            'Scene could not be saved. Please ensure your shortcut is unique and try again.'
          );
        }
      }
    },
    async OVERRIDE_SCENE(ctx, payload) {
      await this.dispatch('REMOVE_SCENE', payload.id);
      await this.dispatch('SAVE_SCENE', payload);
    },
    async SEND_SCENE(ctx, id) {
      const url = `mixer/${ctx.state.event.jobId}/loadScene`;
      await api.post(url, { sceneId: id });
    },
    async RENAME_SCENE(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/renameScene`;
      const res = await api.post(url, payload);

      if (res.status >= 200 && res.status < 300) {
        this.commit('RENAME_SCENE', payload);
      }
    },
    async REMOVE_SCENE(ctx, id) {
      const url = `mixer/${ctx.state.event.jobId}/scene/${id}`;
      const res = await api.delete(url);

      if (res.status >= 200 && res.status < 300) {
        this.commit('REMOVE_SCENE', id);
      }
    },
    async UPDATE_SCENE_SHORTCUT(ctx, payload) {
      try {
        const url = `mixer/${ctx.state.event.jobId}/updateSceneShortcut`;
        const res = await api.post(url, {
          sceneId: payload.id,
          shortcut: payload.shortcut,
        });

        if (res.status >= 200 && res.status < 300) {
          this.commit('UPDATE_SCENE_SHORTCUT', payload);
        }
      } catch (e) {
        console.error(e);
        if (e.response.data.errorCode == 'pg23505') {
          this.commit(
            'ERROR',
            'Scene could not be updated. Please ensure your shortcut is unique and try again.'
          );
        }
      }
    },
    async ADD_PLAYLIST(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/addPlaylist`;
      await api.post(url, {
        name: payload.name,
        mediaIds: payload.list,
      });
    },
    async REMOVE_PLAYLIST(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/playlist/${payload.id}`;
      await api.delete(url);
    },
    async SET_PLAYLIST_PREVIOUS(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/playlistJump`;
      await api.post(url, {
        sourceId: payload.sid,
        back: true,
      });
    },
    async SET_PLAYLIST_NEXT(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/playlistJump`;
      await api.post(url, {
        sourceId: payload.sid,
        forward: true,
      });
    },
    async SET_PLAYLIST_POSITION(ctx, payload) {
      const url = `mixer/${ctx.state.event.jobId}/playlistJump`;
      await api.post(url, {
        sourceId: payload.sid,
        position: payload.currentPlaylistPosition,
      });
    },
    async ASSIGN_PLAYLIST_TO_PRESENTER(
      { commit, dispatch, state },
      { sourceId, presenterId }
    ) {
      const url = `mixer/${state.event.jobId}/setPlaylistController`;
      const res = await api.post(url, { sourceId, presenterId });

      if (res.status >= 200 && res.status < 300) {
        if (presenterId) {
          const source = state.sources.find((s) => s.sid === sourceId);

          if (source && !source.allowPresenterControl) {
            dispatch('TOGGLE_PLAYLIST_PRESENTER_CONTROL', sourceId);
          }

          const presenter = state.presenters.find((p) => p.pid == presenterId);
          commit('MESSAGE', `Playlist control assigned to ${presenter.name}.`);
        } else {
          commit('MESSAGE', `Playlist control removed.`);
        }
      }
    },
    async TOGGLE_PLAYLIST_PRESENTER_CONTROL({ state, commit }, sourceId) {
      const url = `mixer/${state.event.jobId}/togglePlaylistControl`;
      const res = await api.post(url, { sourceId: sourceId });
      if (res.status >= 200 && res.status < 300) {
        const source = state.sources.find((s) => s.sid === sourceId);
        commit(
          'MESSAGE',
          `Playlist control ${
            source.allowPresenterControl ? 'disabled' : 'enabled'
          }.`
        );
      } else {
        throw new Error('MESSAGE', `Toggle playlist control failed.`);
      }
    },
    async TOGGLE_PRESENTER_MUTE({ state, commit }, payload) {
      try {
        const url = `/mixer/${state.event.jobId}/presenter/${payload.pid}/toggleMute`;

        const res = await api.post(url, {
          muted: payload.muted,
        });

        if (res.status >= 200 && res.status < 300) {
          commit('SET_PRESENTER_MUTE_STATE', payload);
        } else {
          throw new Error('Toggle presenter mute state failed');
        }
      } catch (e) {
        console.error(e);
      }
    },

    // TODO: Sort out why the event is not set here.
    //       Also, decide whether we should prefer the job ID as a
    //       parameter in the engagement-related actions as well.
    async LOAD_PAGES(ctx, { jobId }) {
      const res = await api.get(`/jobs/${jobId}/pages`);
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_PAGES', res.data);
      }
    },

    async CREATE_PAGE(ctx, { jobId, title, content }) {
      const data = new FormData();
      data.append('title', title);
      data.append('content', content);
      const res = await api.post(`/jobs/${jobId}/pages`, data);
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('REPLACE_PAGE', res.data);
        this.commit('MESSAGE', `Page created`);
      }
    },

    async UPDATE_PAGE(ctx, { pageId, jobId, title, content }) {
      const data = new FormData();
      data.append('title', title);
      data.append('content', content);
      const res = await api.put(`/jobs/${jobId}/pages/${pageId}`, data);
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('REPLACE_PAGE', res.data);
        this.commit('MESSAGE', `Page updated`);
      }
    },

    async SHOW_PAGE(ctx, { pageId, jobId }) {
      const res = await api.put(`/jobs/${jobId}/pages/${pageId}`, {
        isHidden: false,
      });
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('REPLACE_PAGE', res.data);
        this.commit('MESSAGE', `Page showing`);
      }
    },

    async HIDE_PAGE(ctx, { pageId, jobId }) {
      const res = await api.put(`/jobs/${jobId}/pages/${pageId}`, {
        isHidden: true,
      });
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('REPLACE_PAGE', res.data);
        this.commit('MESSAGE', `Page hidden`);
      }
    },

    async REMOVE_PAGE(ctx, { pageId, jobId }) {
      const res = await api.delete(`/jobs/${jobId}/pages/${pageId}`);
      if (res.status >= 200 && res.status < 300) {
        ctx.commit('REMOVE_PAGE', pageId);
        this.commit('MESSAGE', `Page deleted`);
      }
    },

    // ENGAGEMENT
    async LOAD_MESSAGES(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/engagement/chat/${jobId}/messages`);
      if (res.status >= 200 && res.status < 300) {
        const { response } = res.data;
        ctx.commit('SET_MESSAGES', response);
      }
    },

    async SHOW_MESSAGE(ctx, id) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/chat/${jobId}/messages/${id}/show`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('chat');
        ctx.commit('REPLACE_MESSAGE', res.data.response);
      }
    },

    async HIDE_MESSAGE(ctx, id) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/chat/${jobId}/messages/${id}/hide`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('chat');
        ctx.commit('REPLACE_MESSAGE', res.data.response);
      }
    },

    async REPLY_TO_MESSAGE(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/chat/${jobId}/messages/${payload.id}/replies`,
        { content: payload.content }
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('chat');
        ctx.commit('REPLACE_MESSAGE_REPLY', {
          messageId: payload.id,
          reply: res.data.response,
        });
        this.commit('MESSAGE', `Message reply posted.`);
      }
    },

    async REMOVE_MESSAGE_REPLY(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/chat/${jobId}/reply/remove`, {
        id: payload.id,
      });

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('chat');
        ctx.commit('REMOVE_MESSAGE_REPLY', {
          messageId: payload.messageId,
          id: payload.id,
        });
        this.commit('MESSAGE', `Message reply deleted.`);
      }
    },

    async SET_MESSAGE_OUTPUT(ctx, { messageId }) {
      const res = await api.post(
        `engagement/chat/${ctx.state.event.jobId}/messages/setLive`,
        { messageId }
      );

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_MESSAGE_OUTPUT', messageId);
        this.commit('MESSAGE', `Chat output display updated.`);
      }
    },

    async LOAD_QUESTIONS(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/engagement/qa/${jobId}/questions`);
      if (res.status >= 200 && res.status < 300) {
        const { response } = res.data;
        ctx.commit('SET_QUESTIONS', response);
      }
    },

    async APPROVE_QUESTION(ctx, id) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/qa/${jobId}/questions/${id}/approve`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REPLACE_QUESTION', res.data.response);
      }
    },

    async ANSWER_QUESTION(ctx, id) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/qa/${jobId}/questions/${id}/answer`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REPLACE_QUESTION', res.data.response);
      }
    },

    async REJECT_QUESTION(ctx, id) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/qa/${jobId}/questions/${id}/reject`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REPLACE_QUESTION', res.data.response);
      }
    },

    async REPLY_TO_QUESTION(ctx, { id, content }) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/qa/${jobId}/questions/${id}/replies`,
        { content }
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REPLACE_QUESTION_REPLY', {
          questionId: id,
          reply: res.data.response,
        });
        this.commit('MESSAGE', `Question reply posted.`);
      }
    },

    async REMOVE_QUESTION_ANSWER(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/qa/${jobId}/reply/remove`, {
        id: payload.id,
      });

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REMOVE_QUESTION_ANSWER', {
          questionId: payload.questionId,
          id: payload.id,
        });
        this.commit('MESSAGE', `Question reply deleted.`);
      }
    },

    async SET_QUESTION_OUTPUT(ctx, payload) {
      const res = await api.post(
        `engagement/qa/${ctx.state.event.jobId}/questions/setLive`,
        {
          questionId: payload.questionId,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('SET_QUESTION_OUTPUT', payload);
        this.commit('MESSAGE', `Question output display updated.`);
      }
    },

    async SET_QUESTION_FOR_PRESENTER(ctx, payload) {
      const res = await api.post(
        `engagement/qa/${ctx.state.event.jobId}/questions/setForPresenter`,
        {
          questionId: payload.questionId,
          forPresenterId: payload.forPresenterId,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('qa');
        ctx.commit('REPLACE_QUESTION', res.data.response);
        this.commit('MESSAGE', `Question presenter assignment updated.`);
      }
    },

    async LOAD_QUIZ(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/engagement/quiz/${jobId}`);
      if (res.status >= 200 && res.status < 300) {
        const { response } = res.data;
        ctx.commit('SET_QUIZ', response);
      }
    },

    async LOAD_QUIZ_LEADERBOARD(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/engagement/quiz/${jobId}/leaderboard`);
      if (res.status >= 200 && res.status < 300) {
        const { response } = res.data;
        ctx.commit('SET_QUIZ_LEADERBOARD', response);
      }
    },

    async LOAD_POLLS(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/engagement/polling/${jobId}/polls`);
      if (res.status >= 200 && res.status < 300) {
        const { response } = res.data;
        ctx.commit('SET_POLLS', response);
      }
    },

    async CREATE_POLL(ctx, { content, hasMultipleResponse, options }) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/polling/${jobId}/polls`, {
        content,
        hasMultipleResponse,
        options: options.map((o) => pick(o, 'content')),
      });
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        ctx.commit('ADD_POLL', res.data.response);
        this.commit('MESSAGE', `Poll added.`);
      }
    },

    async UPDATE_POLL(ctx, { id, content, hasMultipleResponse, options }) {
      const { jobId } = ctx.state.event;
      const res = await api.put(`/engagement/polling/${jobId}/polls/${id}`, {
        content,
        hasMultipleResponse,
        options: options.map((o) => pick(o, 'id', 'content')),
      });
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        ctx.commit('UPDATE_POLL', res.data.response);
        this.commit('MESSAGE', `Poll updated.`);
      }
    },

    async DELETE_POLL(ctx, { id }) {
      const { jobId } = ctx.state.event;
      const res = await api.delete(`/engagement/polling/${jobId}/polls/${id}`);
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        if (res.data.response === true) {
          ctx.commit('DELETE_POLL', id);
          this.commit('MESSAGE', `Poll deleted.`);
        }
      }
    },

    async START_POLL(ctx, { id }) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/polling/${jobId}/polls/${id}/start`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        ctx.commit('UPDATE_POLL', res.data.response);
        this.commit('MESSAGE', `Poll started.`);
      }
    },

    async STOP_POLL(ctx, { id }) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/polling/${jobId}/polls/${id}/stop`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        ctx.commit('UPDATE_POLL', res.data.response);
        this.commit('MESSAGE', `Poll stopped.`);
      }
    },

    async RESET_POLL(ctx, { id }) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/polling/${jobId}/polls/${id}/reset`
      );
      if (res.status >= 200 && res.status < 300) {
        reloader.reset('polls');
        ctx.commit('UPDATE_POLL', res.data.response);
        this.commit('MESSAGE', `Poll votes reset.`);
      }
    },

    async LOAD_STATS(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/jobs/${jobId}/analytics/stats`);
      if (res.status != 200) {
        throw new Error(res.status);
      }
      ctx.commit('SET_STATS', res.data);
    },

    async LOAD_LOGINS(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.get(`/jobs/${jobId}/analytics/logins`);
      if (res.status != 200) {
        throw new Error(res.status);
      }
      ctx.commit('SET_LOGINS', res.data);
    },

    async CREATE_NEW_QUIZ_ROUND(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/quiz/${jobId}/blocks`, {
        name: payload.name,
      });

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'New quiz round has been created.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async DELETE_QUIZ_ROUND(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.delete(
        `/engagement/quiz/${jobId}/blocks/${payload.id}`
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz round has been deleted.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async UPDATE_QUIZ_ROUND(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.put(
        `/engagement/quiz/${jobId}/blocks/${payload.id}`,
        {
          name: payload.name,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz round has been renamed.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async REORDER_QUIZ_ROUND(ctx, payload) {
      ctx.commit('SET_QUIZ_BLOCK_REORDER_UPDATING', true);
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/quiz/${jobId}/blocks/order`, {
        ids: payload,
      });

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_QUIZ_BLOCK_REORDER_UPDATING', false);

        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz round order has been updated.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async REORDER_QUIZ_ROUND_QUESTIONS(ctx, payload) {
      ctx.commit('SET_QUIZ_BLOCK_QUESTION_REORDER_UPDATING', true);
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions/order`,
        {
          ids: payload.list,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_QUIZ_BLOCK_QUESTION_REORDER_UPDATING', false);

        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz round questions have been reordered.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async CREATE_NEW_QUIZ_QUESTION(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions`,
        payload
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'New quiz question has been created.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async EDIT_QUIZ_QUESTION(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.put(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions/${payload.questionId}`,
        payload
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz question has been updated.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async DELETE_QUIZ_QUESTION(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.delete(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions/${payload.id}`
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('MESSAGE', 'Quiz question has been deleted.');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async ACTIVATE_QUIZ_QUESTION(ctx, payload) {
      ctx.commit('RECORD_CURRENT_QUIZ_LEADERBOARD');

      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions/${payload.id}/activate`,
        {
          reset: payload.reset,
        }
      );

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        //ctx.commit('MESSAGE', `Quiz question activated.`);
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async DEACTIVATE_QUIZ_QUESTION(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/quiz/${jobId}/deactivate`);

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async TOGGLE_QUIZ_STATE(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/quiz/${jobId}/toggleState`);

      if (res.status >= 200 && res.status < 300) {
        reloader.reset('quiz');
        ctx.commit('SYNC_QUIZ', res.data.response);
      }
    },

    async TOGGLE_QUIZ_DISPLAY(ctx) {
      const { jobId } = ctx.state.event;
      await api.post(`/engagement/quiz/${jobId}/toggleDisplay`, {
        code:
          ctx.state.quiz.screenStateCode == 'leaderboard'
            ? 'question'
            : 'leaderboard',
      });
    },

    async RESET_QUIZ_QUESTION(ctx, payload) {
      const { jobId } = ctx.state.event;
      const res = await api.post(
        `/engagement/quiz/${jobId}/blocks/${payload.blockId}/questions/${payload.id}/reset`
      );

      if (res.status >= 200 && res.status < 300) {
        ctx.commit(
          'MESSAGE',
          `Quiz ${payload.blockName} ${payload.roundName} reset`
        );
      }
    },

    async RESET_QUIZ(ctx) {
      const { jobId } = ctx.state.event;
      const res = await api.post(`/engagement/quiz/${jobId}/reset`);

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('MESSAGE', `Quiz has been reset`);

        reloader.reset('quiz');
        ctx.commit('SET_QUIZ', res.data.response);
        reloader.reset('quiz-leaderboard');
        ctx.dispatch('LOAD_QUIZ_LEADERBOARD');
      }
    },

    async UPDATE_PLAYLIST(ctx, payload) {
      const { jobId } = ctx.state.event;

      await api.post(`/mixer/${jobId}/reorderPlaylist`, {
        id: payload.pid,
        mediaIds: payload.list,
        activePlaylistPosition: payload.activePlaylistPosition
          ? payload.activePlaylistPosition
          : null,
      });
    },

    async RENAME_PLAYLIST(ctx, payload) {
      const { jobId } = ctx.state.event;

      const res = await api.post(`/mixer/${jobId}/renamePlaylist`, {
        id: payload.id,
        name: payload.name,
      });

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('UPDATE_PLAYLIST_NAME', payload);
      }
    },

    async SET_SURVEY_STATE(ctx) {
      const { jobId } = ctx.state.event;
      await api.post(`/mixer/survey/${jobId}/toggleState`);

      //pusher update state
    },

    async LOAD_PLAYLIST(ctx, payload) {
      const { jobId } = ctx.state.event;
      const url = `/jobs/${jobId}/playlists?ids=${payload.id}`;
      const res = await api.get(url);

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('UPDATE_PLAYLIST', res.data.playlists[0]);

        //force re-render by jumping to current position
        /*const sources = ctx.state.sources.map(source => {
                    if(res.data.playlists[0].id == source.playlistId) {
                        console.log(source);
                        ctx.dispatch('SET_PLAYLIST_POSITION', {
                            sid: source.sid,
                            currentPlaylistPosition: source.playlistPosition,
                        });
                    }
                })*/
      }
    },

    async ADD_DESTINATION(ctx, payload) {
      const { jobId } = ctx.state.event;

      var dataset = {
        destType: payload.type,
        sourceType: 'mProgram',
        name: payload.name,
        videoBitrate: payload.videoBitrate,
        running: false,
      };

      if (payload.type == 'RTMP') {
        dataset.uri = payload.uri;
      }

      if (payload.type == 'SRT') {
        dataset.port = payload.port;
        dataset.passphrase = payload.passphrase;
      }

      await api.post(`/mixer/${jobId}/addDestination`, dataset);
    },

    async REMOVE_DESTINATION(ctx, payload) {
      const { jobId } = ctx.state.event;
      await api.delete(`/mixer/${jobId}/destination/${payload.id}`);
    },

    async EDIT_DESTINATION(ctx, payload) {
      const { jobId } = ctx.state.event;
      var dataset = {
        id: payload.id,
        name: payload.name,
        videoBitrate: payload.videoBitrate,
      };

      if (payload.type == 'RTMP') {
        dataset.uri = payload.uri;
      } else if (payload.type == 'SRT') {
        dataset.port = payload.port;
        dataset.passphrase = payload.passphrase;
      }

      await api.post(`/mixer/${jobId}/editDestination`, dataset);

      this.commit(
        'MESSAGE',
        `Outbound stream ${payload.name} has been updated.`
      );
    },

    async TOGGLE_STREAM_STATE(ctx, id) {
      const { jobId } = ctx.state.event;
      const destination = ctx.state.destinations.find((d) => d.id == id);

      if (!destination.running) {
        await api.post(`/mixer/${jobId}/startDestination/${id}`);
        destination.running = true;
      } else {
        await api.post(`/mixer/${jobId}/stopDestination/${id}`);
        destination.running = false;
      }
    },

    async REORDER_PAGES(ctx, payload) {
      ctx.commit('SET_PAGES_REORDER_UPDATING', true);
      const { jobId } = ctx.state.event;
      const res = await api.post(`/jobs/${jobId}/pages/order`, {
        ids: payload,
      });

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_PAGES_REORDER_UPDATING', false);
        ctx.commit('MESSAGE', 'Page order has been updated.');
      }
    },

    async REORDER_SCENES(ctx, payload) {
      ctx.commit('SET_SCENES_REORDER_UPDATING', true);
      const { jobId } = ctx.state.event;
      const res = await api.post(`/jobs/${jobId}/scenes/order`, {
        ids: payload,
      });

      if (res.status >= 200 && res.status < 300) {
        ctx.commit('SET_SCENES_REORDER_UPDATING', false);

        //update sortId manually
        //backend returned null
        ctx.state.scenes.map((scene) => {
          var index = payload.findIndex((id) => id == scene.id);
          scene.sortId = index + 1;
          return scene;
        });

        ctx.commit('MESSAGE', 'Scene order has been updated.');
      }
    },

    // Currently used for the analytics pages, although it may be desirable
    // to load chat/qa/poll info only on demand, rather than always.
    async ENTER_STATS() {
      await reloader.start('stats');
    },
    async LEAVE_STATS() {
      await reloader.stop('stats');
    },
    async ENTER_CHAT() {
      await reloader.start('chat');
    },
    async LEAVE_CHAT() {
      await reloader.stop('chat');
    },
    async ENTER_QA() {
      await reloader.start('qa');
    },
    async LEAVE_QA() {
      await reloader.stop('qa');
    },
    async ENTER_POLLS() {
      await reloader.start('polls');
    },
    async LEAVE_POLLS() {
      await reloader.stop('polls');
    },
    async ENTER_LOGINS() {
      await reloader.start('logins');
    },
    async LEAVE_LOGINS() {
      await reloader.stop('logins');
    },

    async SETUP_PUSHER_SUBSCRIPTIONS({ state, commit, dispatch }) {
      state.pusher._setAuthCallbackFn(async function (context, socketId) {
        const channel = this.channel.name;
        let url = '/pusher/controlAuth';
        if (channel == `private-job-${state.event.jobId}`) {
          url = '/pusher/auth';
        }
        const body = [
          'socket_id=' + encodeURIComponent(socketId),
          'channel=' + encodeURIComponent(channel),
        ].join('&');
        const res = await api.post(url, body, {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
        });
        return res.data;
      });
      let channel = `private-job-${state.event.jobId}`;
      const clientControl = state.pusher.subscribe(channel);
      clientControl.bind('client-control', (data) => {
        //console.log('client-control', data);
        const payload = data.payload;
        if (data.mode == 'update_presenter_state') {
          const presenter = state.presenters.find((p) => p.pid === payload.id);
          if (presenter) {
            presenter.bitrate = payload.bitrate;
          }
        }
      });

      // Pusher channels/messages
      channel = `private-job-${state.event.jobId}-control`;
      const control = state.pusher.subscribe(channel);
      control.bind('event-update', (data) => {
        const payload = data.payload;
        if (data.mode == 'mute') {
          const source = state.sources.find(
            (s) => s.presenterId === payload.id
          );
          if (source) {
            source.sourceMuted = payload.muted;
          }

          const presenter = state.presenters.find((p) => p.pid === payload.id);
          if (presenter) {
            presenter.muted = payload.muted;
          }
        } else if (data.mode == 'force_bitrate') {
          const presenter = state.presenters.find((p) => p.pid === payload.id);
          if (presenter) {
            presenter.forceBitrate = payload.forceBitrate;
          }
        } else if (data.mode == 'set_pl_ctrl_presenter_id') {
          commit('SET_PRESENTER_TO_PLAYLIST_SOURCE', payload);
        } else if (data.mode == 'set_pl_ctrl_position') {
          commit('SET_PLAYLIST_POSITION', payload);
        } else if (data.mode == 'toggle_pl_ctrl') {
          commit('SET_PLAYLIST_CONTROL', payload);
        } else if (data.mode == 'presenter_name_update') {
          const presenter = state.presenters.find((p) => p.pid === payload.id);

          presenter.name = payload.name;
          commit('MESSAGE', `$Presenter ${presenter.name} name updated.`);
        } else if (data.mode == 'state_change') {
          dispatch('GET_JOB_IDLE_SHUTDOWN_TIMEOUT');
          commit('SET_EVENT_STATE', payload.newState);
        } else if (data.mode == 'set_survey_state') {
          commit('SET_SURVEY_STATE', payload.survey_activated);
        } else if (data.mode == 'set_quiz_display') {
          commit('SET_QUIZ_DISPLAY', payload.display);
        } /*else if (data.mode == 'playlist_media_remove') {
                    commit('REMOVE_PLAYLIST_MEDIA', payload);
                } */ else if (data.mode == 'playlist_update') {
          dispatch('LOAD_PLAYLIST', payload);
        } else if (data.mode == 'add_destination') {
          commit('ADD_DESTINATION', payload);
        } else if (data.mode == 'remove_destination') {
          commit('REMOVE_DESTINATION', payload.id);
        }
      });

      control.bind('control-update', (data) => {
        const payload = data.payload;
        if (data.mode == 'add_source') {
          commit('ADD_SOURCE', payload);
        } else if (data.mode == 'remove_source') {
          commit('REMOVE_SOURCE', payload);
        } else if (data.mode == 'add_audio_latch') {
          commit('UPDATE_SOURCE', {
            id: payload.sourceId,
            audioLatch: true,
          });
        } else if (data.mode == 'remove_audio_latch') {
          commit('UPDATE_SOURCE', {
            id: payload.sourceId,
            audioLatch: false,
          });
        } else if (data.mode == 'remove_overlay') {
          commit('UPDATE_OVERLAY', { id: null });
        } else if (data.mode == 'set_overlay') {
          commit('UPDATE_OVERLAY', { id: payload.sourceId });
        } else if (data.mode == 'set_preview_slots') {
          commit('UPDATE_PREVIEW_TRACK', payload);
        } else if (data.mode == 'createpptplaylist_status') {
          commit('UPDATE_PLAYLIST_STATUS', payload);
        } else if (data.mode == 'coregst_media_synced') {
          commit('SET_EVENT_MEDIA_STATE', payload);
        } else if (data.mode == 'set_question_output') {
          commit('SET_QUESTION_OUTPUT', payload);
        } else if (data.mode == 'set_chat_message_output') {
          commit('SET_MESSAGE_OUTPUT', payload.messageId);
        } else if (data.mode == 'state_change') {
          commit('SET_EVENT_STATE', payload.newState);
        } else if (data.mode == 'update_quiz_active_question_answered') {
          commit('UPDATE_QUIZ_ACTIVE_QUESTION_ANSWERED', payload);
        } else if (data.mode == 'playlist_created') {
          dispatch('LOAD_PLAYLIST', payload);
        } else if (data.mode == 'scene_update') {
          const sceneId = payload.id;
          const url = `/jobs/${state.event.jobId}/scenes?id=${sceneId}`;
          api(url).then(({ data }) => {
            commit('REPLACE_SCENE', data.scenes[0]);
          });
        } else if (data.mode == 'media_file_update') {
          const mediaId = payload.id;
          const url = `/jobs/${state.event.jobId}/mediaFiles?ids=${mediaId}`;
          api(url).then(({ data }) => {
            commit('REPLACE_MEDIA_FILE', data.mediaFiles[0]);
          });
        } else if (data.mode == 'add_playlist') {
          commit('ADD_PLAYLIST', payload);
        } else if (data.mode == 'remove_playlist') {
          commit('REMOVE_PLAYLIST', payload);
        } else if (data.mode == 'set_scene_id') {
          commit('SET_LAST_USED_SCENE_ID', payload.scene_id);
        }
      });
      control.bind('mute-io-client', ({ id, muted }) => {
        const ioClient = state.ioClients.find((ioc) => ioc.id == id);
        if (!ioClient) return;
        ioClient.muted = muted;

        // TODO: why do we need to track an ioClient and a source?
        const source = state.sources.find((s) => s.ioClientId === id);
        if (!source) return;
        source.sourceMuted = muted;
      });

      commit('SET_PUSHER_CHANNELS', { clientControl, control });
    },
    UNSUBSCRIBE_PUSHER({ state }) {
      state.pusher.unsubscribe(`private-job-${state.event.jobId}`);
      state.pusher.unsubscribe(`private-job-${state.event.jobId}-control`);
    },
  },
});

export default store;
