<template>
  <div v-html="linkifiedText" />
</template>

<script>
import linkifyHtml from 'linkify-html';

export default {
  props: ['text'],

  data() {
    return {
      options: {
        className: 'text-blue-500 hover:text-blue-800',
        defaultProtocol: 'https',
        target: '_blank',
      },
    };
  },

  computed: {
    linkifiedText() {
      if (!this.text) {
        return null;
      }

      return linkifyHtml(this.text, this.options);
    },
  },
};
</script>
