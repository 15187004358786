<template>
  <div class="flex w-full flex-col text-white">
    <div class="flex flex-col border-b border-grey-600 p-4">
      <RouterLink
        to="/dashboard"
        class="flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">view_list</i>
        <span>Dashboard</span>
      </RouterLink>
      <RouterLink
        to="/new-event"
        v-show="
          ['dashboard', 'archived'].includes($route.name) && allow('new-event')
        "
        class="mt-2 flex cursor-pointer space-x-4 rounded p-3 hover:bg-green-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">add</i>
        <span>New event</span>
      </RouterLink>
    </div>
    <div v-if="event.config.eventShortcode" class="flex flex-col rounded p-4">
      <div
        class="visible mb-2 whitespace-nowrap rounded bg-purple-600 p-2 md:hidden"
      >
        {{ event.config.title }}
      </div>
      <a
        :href="'/events/' + event.config.eventShortcode + '/studio'"
        target="_blank"
        class="mb-1 flex cursor-pointer items-center justify-start space-x-4 rounded p-3 hover:bg-blue-500"
      >
        <i class="material-icons-outlined">play_circle</i>
        <span>Studio</span>
        <i
          class="material-icons-outlined text-icon-sm"
          style="margin-left: auto !important"
          >launch</i
        >
      </a>
      <RouterLink
        v-if="allow('analytics')"
        :to="'/events/' + event.config.eventShortcode + '/analytics'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">equalizer</i>
        <span>Analytics</span>
      </RouterLink>
      <RouterLink
        v-if="allow('registration') && registrationOpen"
        :to="'/events/' + event.config.eventShortcode + '/registration'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">people</i>
        <span>Registration</span>
      </RouterLink>
      <RouterLink
        v-if="allow('settings-branding')"
        :to="'/events/' + event.config.eventShortcode + '/branding'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">draw</i>
        <span>Branding</span>
      </RouterLink>
      <RouterLink
        v-if="allow('settings-general')"
        :to="'/events/' + event.config.eventShortcode + '/settings'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">settings</i>
        <span>Settings</span>
      </RouterLink>
      <RouterLink
        v-if="allow('engagement-standalone')"
        :to="'/events/' + event.config.eventShortcode + '/engagement'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">question_answer</i>
        <div>Engagement</div>
      </RouterLink>
      <RouterLink
        v-if="allow('io-router')"
        :to="'/events/' + event.config.eventShortcode + '/io-router'"
        class="mb-1 flex cursor-pointer space-x-4 rounded p-3 hover:bg-blue-500"
        active-class="bg-blue-800"
      >
        <i class="material-icons-outlined">shuffle</i>
        <div class="flex">
          Router
          <div
            class="ml-1 rounded-full bg-blue-500 px-2 py-1 text-xs uppercase"
          >
            SUPER
          </div>
        </div>
      </RouterLink>

      <div v-if="allow('output-presenters') || allow('output-io-clients')">
        <div class="p-2 text-grey-400">Hybrid event</div>

        <a
          v-if="allow('output-presenters')"
          :href="
            '/events/' + event.config.eventShortcode + '/output/presenters'
          "
          target="_blank"
          class="mb-1 flex cursor-pointer items-center justify-start space-x-4 rounded p-3 hover:bg-blue-500"
        >
          <i class="material-icons-outlined">grid_on</i>
          <div class="flex">
            Presenters
            <div
              class="ml-1 rounded-full bg-blue-500 px-2 py-1 text-xs uppercase"
            >
              SUPER
            </div>
          </div>
          <i
            class="material-icons-outlined text-icon-sm"
            style="margin-left: auto !important"
            >launch</i
          >
        </a>

        <a
          v-if="allow('output-io-clients')"
          :href="
            '/events/' + event.config.eventShortcode + '/output/io-clients'
          "
          target="_blank"
          class="mb-1 flex cursor-pointer items-center justify-start space-x-4 rounded p-3 hover:bg-blue-500"
        >
          <i class="material-icons-outlined">grid_on</i>
          <div class="flex">Router Multiview</div>
          <i
            class="material-icons-outlined text-icon-sm"
            style="margin-left: auto !important"
            >launch</i
          >
        </a>
      </div>

      <div class="p-2 text-grey-400">Attendee portal</div>

      <a
        :href="'https://view.joinin.live/' + event.eventShortcode"
        target="_blank"
        class="mb-1 flex cursor-pointer items-center justify-start space-x-4 rounded p-3 hover:bg-blue-500"
      >
        <i class="material-icons-outlined">event</i>
        <span>Open login</span>
        <i
          class="material-icons-outlined text-icon-sm"
          style="margin-left: auto !important"
          >launch</i
        >
      </a>
      <a
        :href="previewUrl"
        target="_blank"
        class="flex cursor-pointer items-center justify-start space-x-4 rounded p-3 hover:bg-blue-500"
      >
        <i class="material-icons-outlined">visibility</i>
        <span>Preview portal</span>
        <i
          class="material-icons-outlined text-icon-sm"
          style="margin-left: auto !important"
          >launch</i
        >
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavigationLinks',
  computed: {
    ...mapState(['event']),
    registrationOpen() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.event.config.registrationType);
    },
    previewUrl() {
      return (
        this.event &&
        `${process.env.VUE_APP_API_URL}/jobs/${this.event.jobId}/preview`
      );
    },
  },
};
</script>
