<template>
  <button
    type="submit"
    class="relative mt-6 flex items-center justify-center"
    :class="[classes, { 'cursor-not-allowed opacity-90': saving || disabled }]"
    :disabled="disableControl"
  >
    <i
      v-show="saving"
      class="material-icons-outlined absolute left-0 ml-3 animate-spin text-white"
    >
      sync</i
    >
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      default: 'Save',
    },
    classes: {
      default: 'btn-blue',
    },
    saving: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    disableControl() {
      return this.saving || this.disabled;
    },
  },
};
</script>
