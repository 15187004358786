function hasAudioLabels(devices) {
  return devices.some(
    (d) => ['audioinput', 'audiooutput'].includes(d.kind) && d.label
  );
}

export async function getAudioDevices() {
  let devices = await navigator.mediaDevices.enumerateDevices();
  if (!hasAudioLabels(devices)) {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    for (const track of stream.getAudioTracks()) {
      track.stop();
    }
    devices = await navigator.mediaDevices.enumerateDevices();
  }
  if (!hasAudioLabels(devices)) {
    throw new Error('Cannot get audio device labels');
  }

  const aInput = new Map();
  const aOutput = new Map();
  for (const d of devices) {
    if (d.kind === 'audioinput') {
      if (aInput.has(d.groupId)) {
        const a = aInput.get(d.groupId);
        if (['default', 'communications'].includes(a.deviceId)) {
          d.default = true;
          aInput.set(d.groupId, d);
        }
      } else {
        aInput.set(d.groupId, d);
      }
    } else if (d.kind === 'audiooutput') {
      if (aOutput.has(d.groupId)) {
        const a = aOutput.get(d.groupId);
        if (['default', 'communications'].includes(a.deviceId)) {
          d.default = true;
          aOutput.set(d.groupId, d);
        }
      } else {
        aOutput.set(d.groupId, d);
      }
    }
  }

  return { aInput, aOutput };
}
