<template>
  <div class="flex flex-grow flex-col overflow-auto">
    <div class="pb-6 text-center text-grey-400">
      Select a layout to build your display.
    </div>
    <template v-if="selectedLayout == null">
      <div class="grid grid-cols-2 gap-3">
        <div
          v-for="layout in getOrderedLayouts"
          :key="`${layout.type}_${layout.id}`"
          @click="handleselectedLayout(layout)"
        >
          <div class="flex items-center justify-between">
            <div class="text-sm text-grey-400">{{ layout.name }}</div>
            <TooltipModal
              v-if="layout.description"
              triggerType="hover"
              width="40"
            >
              <template slot="control">
                <button class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined text-icon-sm">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  {{ layout.description }}
                </div>
              </template>
            </TooltipModal>
          </div>
          <StudioPanelLayout :layout="layout" />
        </div>
      </div>
    </template>

    <!--layout -->
    <template v-else>
      <div class="flex py-6">
        <button
          class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          @click.prevent="handleNavigateToLayouts"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </button>
        <h3
          class="-ml-10 flex w-full items-center justify-center text-xl font-bold"
        >
          Build your layout
        </h3>
      </div>

      <div class="py-2 text-center">
        <div class="font-bold">{{ selectedLayout.name }}</div>
        <div class="text-grey-400">{{ selectedLayout.description }}</div>
      </div>

      <StudioPanelLayout
        ref="layoutPreview"
        :layout="selectedLayout"
        @onselect="handleSelectSlot"
        :isSelectable="true"
        class="mb-6"
      />

      <div
        v-if="['rehearsal', 'live'].includes(event.eventState)"
        class="flex flex-col lg:h-full"
      >
        <p v-if="selectedSlot" class="py-3 text-center">
          Set a source for
          <strong>{{ 'Position ' + selectedSlot.slotNo }}</strong> by clicking
          on an active source
        </p>

        <div class="align-center flex-auto flex-col justify-between px-3 py-3">
          <div class="col-10 flex-auto">
            <div v-for="slot in selectedLayout.slots" :key="slot.slotNo">
              <span @click="triggerHanldSelectSlot(slot)">
                <TextField
                  :name="`slot_${slot.slotNo}`"
                  :id="slot.slotNo"
                  :label="`Position ${slot.slotNo}`"
                  :value="slot.source ? sourceTitle(slot.source) : null"
                  disabled
                  :required="true"
                  :errors="errors"
                  :clearable="false"
                  @onclear="handleRemoveSlotSource"
                />
              </span>
            </div>
          </div>
        </div>

        <!--<div
                    class="flex flex-col justify-center border-t border-grey-200 pt-3">
                    <button class="btn-blue bg-grey-200"
                        @click="handleSaveScene">
                        Save as a scene
                    </button>
                </div>-->
      </div>
      <div v-else class="py-2 text-center text-grey-400">
        Layouts available when event is in Rehearsal or Live.
      </div>
    </template>
    <!-- end of layout -->
  </div>
</template>
<script>
import { mapState } from 'vuex';
import StudioPanelLayout from '@/components/StudioPanelLayout';
import TextField from '@/components/TextField';
import errors from '@/mixins/errors';
import TooltipModal from '@/components/TooltipModal';
import _ from 'lodash';

export default {
  name: 'StudioPanelLayouts',
  mixins: [errors],
  components: {
    StudioPanelLayout,
    TextField,
    TooltipModal,
  },
  data: () => ({
    selectedLayout: null,
    selectedSlot: null,
  }),
  computed: {
    ...mapState([
      'event',
      'layouts',
      'selectedSource',
      'files',
      'srtEndpoints',
      'htmlSources',
      'playlists',
    ]),
    getOrderedLayouts() {
      const layouts = Array.from(this.layouts);
      return layouts.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
    },
    isSlotReady() {
      return this.selectedSource && this.selectedSlot;
    },
  },
  watch: {
    selectedSlot: function () {
      //when select new slot.
      //reset select source
      this.$store.commit('RESET_SELECT_SOURCE');
    },
    selectedSource: function (val) {
      if (val != null && this.selectedSlot) {
        var index = this.selectedLayout?.slots.findIndex(
          (slot) => slot.slotNo == this.selectedSlot.slotNo
        );
        if (index !== undefined && index !== -1) {
          this.selectedLayout.slots[index].source = val;

          if (index < this.selectedLayout.slots.length) {
            index = index + 1;

            this.$nextTick(() => {
              if (this.$refs.layoutPreview) {
                this.$refs.layoutPreview.handleSelectSlot(
                  this.selectedLayout.slots[index]
                );
              }
            });
          }
        }
      }
    },
    isSlotReady: function () {
      this.sendToPreview();
    },
  },
  methods: {
    handleselectedLayout(layout) {
      this.selectedLayout = _.cloneDeep(layout);

      this.$store.commit('SET_LAYOUT_BUILDING_ON');
    },
    label(slotNo) {
      return 'Position ' + slotNo;
    },
    handleSelectSlot(slot) {
      this.selectedSlot = slot;
    },
    triggerHanldSelectSlot(slot) {
      this.$refs.layoutPreview.handleSelectSlot(slot);
    },
    sendToPreview() {
      if (!this.selectedSource || !this.selectedSlot) return;

      var slot = this.selectedLayout?.slots.find(
        (slot) => slot.slotNo == this.selectedSlot.slotNo
      );
      if (slot) {
        slot.source = this.selectedSource;
      }

      var list = [];
      if (this.selectedLayout?.slots) {
        this.selectedLayout.slots.map((slot) => {
          if (slot.source && slot.source != null) {
            list.push({
              ...slot,
              sourceId: slot.source.sid,
            });
          }
        });
      }

      if (list.length > 0) {
        this.$store.dispatch('SEND_TO_PREVIEW', list);

        if (list.length == this.selectedLayout.slots.length) {
          this.handleNavigateToLayouts();
          this.$store.commit('MESSAGE', 'Layout has been added successfully');
        }
      }
    },
    handleRemoveSlotSource(slotNo) {
      var slot = this.selectedLayout.slots.find((s) => s.slotNo == slotNo);

      slot.source = null;

      this.sendToPreview();
    },
    handleNavigateToLayouts() {
      this.selectedLayout = null;

      this.$store.commit('SET_LAYOUT_BUILDING_OFF');
    },
    sourceTitle(source) {
      switch (source.type) {
        case 'Playlist':
          var playlist = this.playlists.find(
            (pl) => pl.id == source.playlistId
          );
          return playlist.name;
        case 'Media':
          var file = this.files.find((f) => f.fid == source.mediaId);
          return file.title;
        case 'SRT':
          var srt = this.srtEndpoints.find((s) => s.id == source.srtId);
          return srt.name;
        case 'HTML':
          var html = this.htmlSources.find((h) => h.id == source.htmlId);
          return html.name;
        case 'Presenter':
          return source.presenter.name;
        default:
          return source.title;
      }
    },
    handleSaveScene() {
      this.$store.commit('TRIGGER_SAVE_SCENE');
    },
  },
};
</script>
