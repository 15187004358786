<template>
  <div class="flex flex-grow flex-col">
    <RouterLink
      v-if="allow('billing-details')"
      :to="'/events/' + event.eventShortcode + '/io-router/clients'"
      target="_blank"
      class="my-6 text-center text-blue-500 hover:text-blue-800"
    >
      <div class="whitespace-nowrap">Manage router clients</div>
    </RouterLink>
    <!-- main content -->
    <div class="flex h-full flex-col">
      <div class="flex flex-col overflow-auto lg:flex-grow">
        <div class="flex items-center justify-between">
          <h4 class="text-center text-lg font-bold">
            Live ({{ liveRouterInputs.length }})
          </h4>
        </div>

        <div
          v-if="liveRouterInputs.length == 0"
          class="p-3 text-center text-grey-300"
          id="live_list_empty"
        >
          No live router inputs
        </div>
        <ul class="w-full">
          <StudioPanelRouterInput
            v-for="input in liveRouterInputs"
            :key="input.id"
            :routerInput="input"
            :type="'active'"
            :isPromoted="true"
            :isPublishing="input.isPublishing"
          />
        </ul>

        <div class="flex items-center justify-between pt-6">
          <h4 class="text-lg font-bold">
            Publishing ({{ publishingRouterInputs.length }})
          </h4>
        </div>

        <div
          v-if="publishingRouterInputs.length == 0"
          class="p-3 text-center text-grey-300"
          id="waiting_list_empty"
        >
          No publishing router inputs
        </div>

        <ul class="w-full" id="waiting_list">
          <StudioPanelRouterInput
            v-for="input in publishingRouterInputs"
            :key="input.id"
            :routerInput="input"
            :type="'inactive'"
            :isPromoted="false"
            :isPublishing="true"
          />
        </ul>

        <h4 class="pt-6 text-lg font-bold">
          Offline ({{ offlineRouterInputs.length }})
        </h4>
        <div
          v-if="offlineRouterInputs.length == 0"
          class="p-3 text-center text-grey-300"
        >
          No offline router inputs
        </div>

        <ul class="w-full">
          <StudioPanelRouterInput
            v-for="input in offlineRouterInputs"
            :key="input.id"
            :routerInput="input"
            :type="'inactive'"
            :isPromoted="false"
            :isPublishing="false"
          />
        </ul>
      </div>
    </div>
    <!-- end of main content -->
  </div>
</template>
<script>
import { mapState } from 'vuex';

import StudioPanelRouterInput from '@/components/StudioPanelRouterInput';

export default {
  name: 'StudioPanelRouter',

  components: {
    StudioPanelRouterInput,
  },
  props: {
    janusPublishers: { type: Object, required: true },
  },
  computed: {
    ...mapState(['event', 'sources', 'ioClients']),
    liveRouterInputs() {
      const pubs = Object.values(this.janusPublishers);
      // promoted. either online or offline
      const clients = this.ioClients
        .filter((client) =>
          this.sources.some((src) => client.id == src.ioClientId)
        )
        .map((c) =>
          Object.assign({}, c, {
            isPublishing: pubs.some((p) => p.id.startsWith(`IoClient-${c.id}-`)),
          })
        );

      return clients.sort((a, b) => {
        if (a.isPublishing != b.isPublishing) {
          return +a.isPublishing < +b.isPublishing ? 1 : -1;
        }
        return a.name.localeCompare(b.name);
      });
    },
    publishingRouterInputs() {
      const pubs = Object.values(this.janusPublishers);
      // online and not promoted
      const clients = this.ioClients.filter((c) => {
        // Online
        if (!pubs.some((p) => p.id.startsWith(`IoClient-${c.id}-`))) {
          return false;
        }
        // Not promoted
        if (this.sources.find((src) => c.id == src.ioClientId)) {
          return false;
        }
        // Output direction
        return ['input', 'both'].includes(c.ioDirection);
      });
      return clients.sort((a, b) => a.name.localeCompare(b.name));
    },
    offlineRouterInputs() {
      const pubs = Object.values(this.janusPublishers);
      // offline and not promoted
      const clients = this.ioClients.filter((c) => {
        // Offline
        if (pubs.some((p) => p.id.startsWith(`IoClient-${c.id}-`))) {
          return false;
        }
        // Not promoted
        if (this.sources.find((src) => c.id == src.ioClientId)) {
          return false;
        }
        // Output direction
        return ['input', 'both'].includes(c.ioDirection);
      });
      return clients.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
