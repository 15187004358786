<template>
  <div
    class="group w-full cursor-pointer text-blue-100"
    :class="{
      'text-white': active && active === tab,
      'm-1 rounded-lg border-2 border-white': isTabGroup && visible,
    }"
  >
    <div
      v-if="!isSubTab"
      @click.prevent="handleClick"
      class="relative flex flex-col items-center px-2 py-6 text-grey-800"
    >
      <i
        v-if="icon"
        class="material-icons-outlined group-hover:text-white"
        :class="
          (active && active === tab) || visible ? 'text-white' : 'text-grey-800'
        "
        >{{ icon }}</i
      >

      <i
        v-if="isTabGroup"
        class="material-icons-outlined absolute right-2 group-hover:text-white"
        :class="visible ? 'expand text-white' : 'text-grey-800'"
        >chevron_right</i
      >
      <span
        class="pt-1 text-center text-xs group-hover:text-white"
        :class="{ 'text-white': (active && active === tab) || visible }"
        >{{ label }}</span
      >
      <div v-if="notifications > 0" class="badge right-2 top-2">
        {{ notifications }}
      </div>
    </div>
    <div
      v-else
      @click.prevent="handleClick"
      class="relative w-full cursor-pointer bg-blue-100 py-4 hover:bg-blue-400 hover:text-white"
      :class="{
        'border-l-4 border-grey-800 bg-blue-400': active && active === tab,
        'rounded-b': isLastSubTab,
      }"
    >
      <div class="flex flex-col items-center">
        <span class="pt-1 text-center text-xs text-grey-800">{{ label }}</span>
        <div v-if="notifications > 0" class="badge right-2 top-2">
          {{ notifications }}
        </div>
      </div>
    </div>
    <div v-if="visible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabItem',

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    tab: {
      type: String,
      required: true,
    },
    active: {
      type: String,
    },
    notifications: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    visible: false,
  }),

  computed: {
    isTabGroup() {
      return this.$slots.default && this.$slots.default.length > 0;
    },
    isSubTab() {
      return (
        this.$parent.$slots.default && this.$parent.$slots.default.length > 0
      );
    },
    isLastSubTab() {
      return this.$parent.$slots.default[this.$parent.$slots.default.length - 1]
        .componentInstance == this
        ? true
        : false;
    },
  },

  methods: {
    handleClick() {
      if (this.isTabGroup) {
        this.visible = !this.visible;
      } else {
        this.$emit('click', this.tab);
      }
    },
  },
};
</script>

<style scoped>
.expand {
  transform: rotateZ(90deg);
}
</style>
