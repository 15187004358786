<template>
  <div class="flex flex-1 items-start py-3">
    <div class="flex-1 flex-col space-y-4">
      <div class="text-center">
        Survey is
        <span
          class="text-green-500"
          :class="{ 'text-red-500': !event.config.surveyActivated }"
        >
          {{ event.config.surveyActivated ? 'visible' : 'not visible' }}
        </span>
        to attendees.
      </div>
      <button
        @click="handleToggleSurvey"
        class="btn-blue"
        :class="{ 'btn-red': event.config.surveyActivated }"
      >
        {{ event.config.surveyActivated ? 'Close' : 'Open' }} Survey
      </button>

      <RouterLink
        :to="'/events/' + event.eventShortcode + '/settings/survey'"
        target="_blank"
        class="flex cursor-pointer items-center justify-center text-blue-500 hover:text-blue-800"
        exact-active-class="border-b-2 border-black text-grey-800"
      >
        Manage survey
        <i class="material-icons-outlined pl-1 text-icon-sm text-blue-500"
          >open_in_new</i
        >
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'StudioPanelSurvey',
  computed: {
    ...mapState(['event']),
  },
  methods: {
    handleToggleSurvey() {
      this.$store.dispatch('SET_SURVEY_STATE');
    },
  },
};
</script>
