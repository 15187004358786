<template>
  <div class="w-full">
    <div class="flex-1 pb-8">
      <div class="flex-1 text-grey-400">
        Bulk import attendees via a CSV file upload.
      </div>
    </div>

    <form class="flex flex-col" @submit.prevent="save">
      <div v-if="!isFileUploading">
        <a
          href="https://support.joinin.live/portal/en/kb/articles/bulk-attendee-import"
          target="_blank"
          class="flex"
        >
          <p class="text-blue-500">View CSV file specifications and template</p>
        </a>
        <div v-if="!regoTypePreRegistration" class="py-3 text-grey-400">
          Passwords specified in the CSV are <strong>ignored</strong> for
          'Automatic Registration' and 'Manual Registration' events.
        </div>

        <FileInput
          name="file"
          v-model="file"
          class="mb-3"
          :supports="['csv']"
          :required="true"
          :errors="errors"
        />

        <MaterialIconsCheckbox
          name="send_reset_email"
          class="mb-6"
          label="Send confirmation emails"
          v-model="sendEmails"
        />

        <div v-if="sendEmails" class="pb-6 text-grey-400">
          A confirmation email with unique access link will be sent to each
          imported attendee immediately.
        </div>

        <div v-if="!sendEmails" class="pb-6 text-grey-400">
          No confirmation emails will be sent to imported attendees. You must
          share attendee access details manually.
        </div>

        <!-- show errors -->
        <div v-if="importErrors" class="rounded bg-red-100 p-4">
          <div class="mb-4">
            <div class="font-bold">Import errors</div>
            <div class="text-sm">
              The import was aborted. Please fix the following errors in your
              CSV file and try again.
            </div>
          </div>
          <table class="w-full text-sm">
            <thead>
              <tr>
                <th class="w-16 p-1 text-center">Row</th>
                <th class="p-1 text-left">Message</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(importError, index) in orderedImportErrors"
                :key="index"
                class="border-b border-red-800 last:border-b-0"
              >
                <td
                  class="flex w-16 items-start justify-center p-2 text-center"
                >
                  {{
                    importError.context == 'row' ? importError.row + 2 : 'All'
                  }}
                </td>
                <td class="p-2">{{ importError.message }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <SubmitButton
          id="btn_import_attendees"
          :saving="saving"
          label="Import attendees"
        />
      </div>
      <div class="px-12 text-center" v-else>
        <p>{{ filename }}</p>
        <p class="mt-6 text-xs">{{ progress }}</p>
        <div class="relative rounded bg-grey-200">
          <div
            class="mb-4 flex h-2 overflow-hidden rounded bg-blue-500 text-xs"
            :style="`width: ${progress}`"
          ></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';
import FileInput from '@/components/FileInput';
import SubmitButton from '@/components/SubmitButton';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    FileInput,
    SubmitButton,
    MaterialIconsCheckbox,
  },

  data() {
    return {
      file: null,
      isFileUploading: false,
      progress: 0,
      regoTypePreRegistration: false,
      saving: false,
      sendEmails: true,
      importErrors: null,
    };
  },

  computed: {
    orderedImportErrors() {
      if (!this.importErrors) {
        return;
      }

      const errors = Array.from(this.importErrors);
      // eslint-disable-next-line no-unused-vars
      return errors.sort((x, y) => {
        return x.context == 'overall' ? -1 : 1;
      });
    },
  },

  watch: {
    event: function () {
      this.regoTypePreRegistration =
        this.event.registrationType == 'pre_registration';
    },

    file() {
      if (!this.file) {
        this.importErrors = null;
      }
    },
  },

  methods: {
    save() {
      this.saving = true;
      this.importErrors = null;

      const fd = new FormData();
      fd.set('file', this.file);
      fd.set('sendEmails', this.sendEmails);

      api
        .post(`/jobs/${this.event.id}/uploadAttendeesCsv`, fd)
        .then(() => {
          // redirect to attendee page
          this.$router.push(
            `/events/${this.event.eventShortcode}/registration`
          );
          this.notify('success', 'Bulk import successful');
        })
        .catch((error) => {
          try {
            this.importErrors = error.response.data.errors;
          } catch {
            this.notify('error', 'Bulk import failed');
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
