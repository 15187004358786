const withUnit = (term, n) => `${n} ${term}${n == 1 ? '' : 's'}`;

// "(H hour(s),) M minute(s), S second(s)", with the hours omitted if zero.
// Normally takes DateTimes..
export const formatRuntime = (from = 0, to = from) => {
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;
  if (from) {
    const totalSeconds = Math.floor((to - from) / 1000);
    seconds = totalSeconds % 60;
    const totalMinutes = (totalSeconds - seconds) / 60;
    minutes = totalMinutes % 60;
    const totalHours = (totalMinutes - minutes) / 60;
    hours = totalHours % 24;
    days = (totalHours - hours) / 24;
  }
  return (
    (days ? withUnit('day', days) + ', ' : '') +
    (hours ? withUnit('hour', hours) + ', ' : '') +
    `${withUnit('minute', minutes)}, ${withUnit('second', seconds)}`
  );
};
