var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pb-8 text-grey-400"},[_vm._v(" Configure email notifications sent for your event. ")]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.event),expression:"event"}],staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticClass:"mb-6 text-lg font-medium"},[_vm._v("Presenter invitation")]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextareaField',{attrs:{"name":"presenter_invitation_email","label":"Email text","required":false,"tooltip":"This text will be included in the email sent to presenters when they are invited to present during this event.","errors":_vm.errors},model:{value:(_vm.form.presenterInvitationEmailText),callback:function ($$v) {_vm.$set(_vm.form, "presenterInvitationEmailText", $$v)},expression:"form.presenterInvitationEmailText"}})],1)]),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v(" Attendee registration confirmation ")]),(!_vm.can('event-registration'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):(
        [
          'automatic_registration',
          'manual_registration',
          'pre_registration',
        ].includes(this.event.registrationType)
      )?_c('div',[_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextField',{attrs:{"name":"registration_confirmation_email_subject","label":"Subject line","required":true,"tooltip":"The subject line for the attendee registration confirmation email.","errors":_vm.errors},model:{value:(_vm.form.registrationConfirmationEmailSubject),callback:function ($$v) {_vm.$set(_vm.form, "registrationConfirmationEmailSubject", $$v)},expression:"form.registrationConfirmationEmailSubject"}})],1)]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextareaField',{attrs:{"name":"registration_confirmation_email_text","label":"Email text","required":true,"tooltip":"The body text for the second email reminder. This text will be included in the email sent to attendee when they complete registration for this event. The event schedule details and calendar invitations will be included automatically below this text.","errors":_vm.errors},model:{value:(_vm.form.registrationConfirmationEmailText),callback:function ($$v) {_vm.$set(_vm.form, "registrationConfirmationEmailText", $$v)},expression:"form.registrationConfirmationEmailText"}})],1)])]):_c('div',{staticClass:"mb-6 text-grey-400"},[_vm._v(" Attendee notifications are available when event access type is 'Automatic registration', 'Manual registration' or 'Pre registration'. Change access type on the "),_c('RouterLink',{staticClass:"text-blue-500",attrs:{"to":'/events/' + _vm.event.eventShortcode + '/settings'}},[_vm._v(" General settings ")]),_vm._v(" page. ")],1),(!_vm.can('event-registration'))?_c('div',{staticClass:"mb-6"},[_c('UpgradePlanLink')],1):(
        [
          'automatic_registration',
          'manual_registration',
          'pre_registration',
        ].includes(this.event.registrationType)
      )?_c('div',[_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v(" Attendee event reminder 1 ")]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{staticClass:"flex-1",attrs:{"label":"Enabled","name":"reminder_email_1","errors":_vm.errors,"options":[
              { id: true, label: 'Enabled' },
              { id: false, label: 'Disabled' },
            ]},model:{value:(_vm.form.reminderEmail1Enabled),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail1Enabled", $$v)},expression:"form.reminderEmail1Enabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Send a first reminder email to attendees in the days prior to the event. This email includes a calendar invitation. ")])],2)],1),(_vm.form.reminderEmail1Enabled)?_c('div',[_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextField',{attrs:{"name":"reminder_email_1_subject","label":"Subject line","required":true,"tooltip":"The subject line for the first email reminder.","errors":_vm.errors},model:{value:(_vm.form.reminderEmail1Subject),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail1Subject", $$v)},expression:"form.reminderEmail1Subject"}})],1)]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextareaField',{attrs:{"name":"reminder_email_1_text","label":"Email text","required":true,"tooltip":"The body text for the first email reminder. The event schedule details and calendar invitations will be included automatically below this text.","errors":_vm.errors},model:{value:(_vm.form.reminderEmail1Text),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail1Text", $$v)},expression:"form.reminderEmail1Text"}})],1)]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{staticClass:"flex-1",attrs:{"label":"Email schedule","name":"reminder_email_1_interval","errors":_vm.errors,"options":[
                { id: 24, label: '1 day before scheduled start' },
                { id: 48, label: '2 days before scheduled start' },
                { id: 72, label: '3 days before scheduled start' },
                { id: 96, label: '4 days before scheduled start' },
                { id: 120, label: '5 days before scheduled start' },
              ]},model:{value:(_vm.form.reminderEmail1Interval),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail1Interval", $$v)},expression:"form.reminderEmail1Interval"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Select when this reminder email should be sent. ")])],2)],1)]):_vm._e(),_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v(" Attendee event reminder 2 ")]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{staticClass:"flex-1",attrs:{"label":"Enabled","name":"reminder_email_2","errors":_vm.errors,"options":[
              { id: true, label: 'Enabled' },
              { id: false, label: 'Disabled' },
            ]},model:{value:(_vm.form.reminderEmail2Enabled),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail2Enabled", $$v)},expression:"form.reminderEmail2Enabled"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Send a second reminder email to attendees the day of the event. This email includes a calendar invitation. ")])],2)],1),(_vm.form.reminderEmail2Enabled)?_c('div',[_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextField',{attrs:{"name":"reminder_email_2_subject","label":"Subject line","required":true,"tooltip":"The subject line for the second email reminder.","errors":_vm.errors},model:{value:(_vm.form.reminderEmail2Subject),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail2Subject", $$v)},expression:"form.reminderEmail2Subject"}})],1)]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdTextareaField',{attrs:{"name":"reminder_email_2_text","label":"Email text","required":true,"tooltip":"The body text for the second email reminder. The event schedule details and calendar invitations will be included automatically below this text.","errors":_vm.errors},model:{value:(_vm.form.reminderEmail2Text),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail2Text", $$v)},expression:"form.reminderEmail2Text"}})],1)]),_c('div',{staticClass:"flex flex-auto"},[_c('div',{staticClass:"flex-1"},[_c('MdSelectField',{staticClass:"flex-1",attrs:{"label":"Email schedule","name":"reminder_email_2_interval","errors":_vm.errors,"options":[
                { id: 1, label: '1 hour before scheduled start' },
                { id: 2, label: '2 hours before scheduled start' },
                { id: 3, label: '3 hours before scheduled start' },
                { id: 4, label: '4 hours before scheduled start' },
                { id: 5, label: '5 hours before scheduled start' },
              ]},model:{value:(_vm.form.reminderEmail2Interval),callback:function ($$v) {_vm.$set(_vm.form, "reminderEmail2Interval", $$v)},expression:"form.reminderEmail2Interval"}})],1),_c('TooltipModal',[_c('template',{slot:"control"},[_c('button',{staticClass:"-ml-5 -mt-2 flex items-center space-x-1 text-sm"},[_c('i',{staticClass:"material-icons-outlined text-icon-sm"},[_vm._v("info")])])]),_c('template',{slot:"content"},[_vm._v(" Select when this reminder email should be sent. ")])],2)],1)]):_vm._e()]):_c('div',[_c('div',{staticClass:"mb-6 pt-6 text-lg font-medium"},[_vm._v(" Attendee event reminders ")]),_c('div',{staticClass:"mb-6 text-grey-400"},[_vm._v(" Attendee notifications are available when event access type is 'Automatic registration', 'Manual registration' or 'Pre registration'. Change access type on the "),_c('RouterLink',{staticClass:"text-blue-500",attrs:{"to":'/events/' + _vm.event.eventShortcode + '/settings'}},[_vm._v(" General settings ")]),_vm._v(" page. ")],1)]),_c('SubmitButton',{attrs:{"disabled":!_vm.saveable,"saving":_vm.saving}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }