<template>
  <div>
    <a
      class="focus:outline-none cursor-pointer transition hover:text-blue-500"
      @click.prevent="show()"
    >
      <slot name="control"></slot>
    </a>

    <transition name="fade">
      <div
        v-if="visible"
        class="fixed inset-0 z-50 mx-auto my-6 flex max-w-3xl items-center justify-center overflow-y-auto overflow-x-hidden"
        :class="modalWidth"
      >
        <div
          class="relative mx-4 max-h-full flex-1 flex-shrink-0 py-6 text-grey-1000 shadow-lg lg:rounded-lg"
        >
          <button
            v-if="closeBtn == true"
            @click.prevent="close()"
            class="input-shadow absolute right-2 -mr-6 -mt-4 flex h-8 w-8 items-center justify-center rounded-full bg-white hover:bg-grey-200"
          >
            <i class="material-icons-outlined">close</i>
          </button>
          <div class="rounded-lg bg-white p-8" ref="modalContent">
            <slot name="content" :close="close"></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/services/event-bus';
import { mapState } from 'vuex';

export default {
  props: {
    width: {
      type: String,
      default: 'full',
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    ...mapState(['modalOpen']),

    modalWidth() {
      return 'w-' + this.width;
    },
  },

  watch: {
    modalOpen: function (val) {
      if (val == false) {
        this.close();
      }
    },
  },

  mounted() {
    EventBus.$on('close-modal', this.onCloseModalEvent);
  },

  beforeDestroy() {
    EventBus.$off('close-modal', this.onCloseModalEvent);
    window.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('mousedown', this.onMouseDown);
  },

  methods: {
    onCloseModalEvent() {
      this.close();
    },

    /**
     * Listens for global events that should close the modal.
     */
    registerCloseListeners() {
      // escape key closes modal
      window.addEventListener('keydown', this.onKeyDown);

      // clicking outside the modal closes modal
      document.addEventListener('mousedown', this.onMouseDown);
    },
    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.close();
      }
    },
    onMouseDown(event) {
      if (event.target.classList.contains('modal-overlay')) {
        this.close();
      }
    },

    show() {
      this.visible = true;
      if (this.closeBtn == true) {
        this.registerCloseListeners();
      }
      this.$store.commit('modalOpen', true);
      this.$emit('shown');

      //allow auto focus if input exists when modal open
      this.$nextTick(() => {
        var el = this.$refs.modalContent.querySelector('input');
        if (el && el != null) {
          el.focus();
        }
      });
    },

    close() {
      this.visible = false;
      this.$emit('close');
      this.$store.commit('modalOpen', false);
    },
  },
};
</script>
