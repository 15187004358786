<template>
  <div class="mb-6">
    <input
      type="email"
      class="input"
      :class="{ 'is-invalid rounded-b-none': errors.has(name) }"
      :id="name"
      :name="name"
      autocomplete="off"
      autocapitalize="off"
      autocorrect="off"
      :placeholder="label"
      :value="value"
      :autofocus="autofocus"
      :required="required"
      @input="handleInput"
    />

    <ValidationErrors :errors="errors" :name="name" />
  </div>
</template>

<script>
import ValidationErrors from '@/components/ValidationErrors';

export default {
  name: 'EmailField',

  components: {
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String },
    placeholder: { type: String },
    label: { type: String },
    value: { type: String },
    autofocus: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e.currentTarget.value);
    },
  },
};
</script>
