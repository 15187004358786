var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full overflow-auto"},[_c('h4',{staticClass:"pt-2 text-lg font-bold"},[_vm._v("Audio")]),_c('div',{staticClass:"flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"},[_c('div',[_c('div',[_vm._v("Audio input device")]),_c('div',{staticClass:"py-1 text-sm text-grey-400"},[_vm._v(" Select the audio input device for comms/talkback. "),(_vm.commsConnected !== false)?_c('span',[_vm._v(" Disconnect comms/talkback to change input device.")]):_vm._e()])]),_c('DropdownMenu',{ref:"inputDropdown",staticClass:"w-full",scopedSlots:_vm._u([{key:"control",fn:function(slotProps){return [(_vm.audioInputDevices.length == 0)?_c('i',{staticClass:"material-icons-outlined animate-spin"},[_vm._v(" sync ")]):_c('button',{staticClass:"dropdownListItemWithIcon",attrs:{"disabled":_vm.commsConnected !== false},on:{"click":function($event){$event.preventDefault();return slotProps.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.selectedAudioInputDevice ? _vm.selectedAudioInputDevice.label : '')+" ")]),_c('i',{staticClass:"material-icons-outlined"},[_vm._v("keyboard_arrow_down")])])]}}])},[_c('template',{slot:"menu"},_vm._l((_vm.audioInputDevices),function(device,index){return _c('button',{key:device.deviceId,staticClass:"dropdownListItemWithIcon",on:{"click":function($event){$event.preventDefault();return _vm.setInputDevice(device)}}},[_vm._v(" "+_vm._s(device.label)+" "),_c('div',{staticClass:"mr-3 w-8"},[(
                (!_vm.sltAudioInputDeviceId && index == 0) ||
                _vm.sltAudioInputDeviceId == device.deviceId
              )?_c('i',{staticClass:"material-icons-outlined text-green-500"},[_vm._v(" check ")]):_vm._e()])])}),0)],2)],1),_c('div',{staticClass:"flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"},[_vm._m(0),_c('DropdownMenu',{ref:"outputDropdown",staticClass:"w-full",scopedSlots:_vm._u([{key:"control",fn:function(slotProps){return [_c('button',{staticClass:"dropdownListItemWithIcon",attrs:{"disabled":_vm.commsConnected === false},on:{"click":function($event){$event.preventDefault();return slotProps.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.selectedAudioOutputDevice ? _vm.selectedAudioOutputDevice.label : '')+" ")]),_c('i',{staticClass:"material-icons-outlined"},[_vm._v("keyboard_arrow_down")])])]}}])},[_c('template',{slot:"menu"},_vm._l((_vm.audioOutputDevices),function(device,index){return _c('button',{key:device.deviceId,staticClass:"dropdownListItemWithIcon",on:{"click":function($event){$event.preventDefault();return _vm.setOutputDevice(device)}}},[_vm._v(" "+_vm._s(device.label)+" "),_c('div',{staticClass:"mr-3 w-8"},[(
                (!_vm.sltAudioOutputDeviceId && index == 0) ||
                _vm.sltAudioOutputDeviceId == device.deviceId
              )?_c('i',{staticClass:"material-icons-outlined text-green-500"},[_vm._v(" check ")]):_vm._e()])])}),0)],2)],1),_c('h4',{staticClass:"pt-2 text-lg font-bold"},[_vm._v("Keyboard shortcuts")]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("Audio output device")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Select audio device for output")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-1 flex-col items-start justify-between border-b border-grey-100 py-2"},[_c('div',[_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v(" Keyboard shortcuts can be used to perform quick actions in Studio ")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + Space")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Cut")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + Enter")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Fade")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + Right Arrow")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Next scene")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + Left Arrow")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Previous scene")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + O")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Remove overlay")]),_c('div',{staticClass:"pt-2"},[_vm._v("ALT + L")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Remove all latched audio")]),_c('div',{staticClass:"pt-2"},[_vm._v("Left Shift (hold)")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Activate comms talk")]),_c('div',{staticClass:"pt-2"},[_vm._v("Left Shift (double press)")]),_c('div',{staticClass:"text-sm text-grey-400"},[_vm._v("Latch comms talk toggle")])])])
}]

export { render, staticRenderFns }