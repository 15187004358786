<template>
  <div>
    <button :class="className" @click.prevent="promptConfirmation">
      <slot name="button"></slot>
    </button>

    <!-- confirm modal -->
    <transition name="fade">
      <div
        v-if="visible"
        class="left-50 top-50 fixed inset-0 z-50 mx-auto flex w-96 max-w-2xl items-center justify-center text-grey-800"
      >
        <div
          class="modal-overlay fixed inset-x-0 inset-y-0 bg-black opacity-75"
          @click="handleCancel"
        ></div>
        <div
          class="relative mx-4 max-w-3xl flex-1 bg-grey-50 text-grey-1000 shadow-lg lg:rounded-lg"
        >
          <div
            class="border-b border-grey-200 px-6 py-4 text-center text-xl font-bold"
          >
            Confirm
          </div>

          <div class="px-6 py-4">
            <slot name="content"></slot>
          </div>

          <div class="flex items-center justify-between space-x-4 px-6 py-4">
            <button
              class="flex-1 rounded-full border border-grey-200 bg-white px-4 py-2 hover:border-grey-400"
              @click.prevent="handleCancel"
            >
              Cancel
            </button>
            <button
              @click.prevent="handleConfirmation"
              class="flex-1 rounded-full px-4 py-2 text-white"
              :class="
                buttonType == 'red'
                  ? 'bg-red-500 hover:bg-red-800'
                  : 'bg-blue-500 hover:bg-blue-800'
              "
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </transition>
    <!-- end of modal -->
  </div>
</template>

<script>
export default {
  name: 'ConfirmModalButton',

  props: {
    className: {
      type: String,
    },
    buttonType: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: 'Confirm',
    },
  },

  data: () => ({
    visible: false,
  }),

  methods: {
    promptConfirmation() {
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handleConfirmation() {
      this.visible = false;
      this.$emit('click');
    },
  },
};
</script>
