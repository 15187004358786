// account checked before user

const pageAccountAccessMatrix = {
  'users': ['trial', 'standard', 'business', 'enterprise'],
  'hybrid': ['business', 'enterprise'],
  'registration': ['trial', 'standard', 'business', 'enterprise'],
  'registration-import': ['trial', 'standard', 'business', 'enterprise'],
  'attendee': ['trial', 'standard', 'business', 'enterprise'],
  'attendee-edit': ['trial', 'standard', 'business', 'enterprise'],
  'templates': ['trial', 'business', 'enterprise'],
};

const pageUserAccessMatrix = {
  'billing-details': ['admin'],
  'account-plan': ['admin'],
  'new-event': ['admin'],
  'users': ['admin'],
  'user': ['admin'],
  'studio': ['admin', 'manager', 'assistant'],
  'output-qa': ['admin', 'manager'],
  'io-router': ['admin'],
  'io-router-routing': ['admin'],
  'io-router-clients': ['admin'],
  'io-router-clients-form': ['admin'],
  'output-presenters': ['super'],
  'output-io-clients': ['admin', 'manager', 'assistant'],
  'settings-general': ['admin', 'manager'],
  'settings-branding': ['admin', 'manager'],
  'settings-ondemand': ['admin', 'manager'],
  'settings-registration': ['admin', 'manager'],
  'settings-engagement': ['admin', 'manager'],
  'settings-page': ['admin', 'manager'],
  'settings-pages': ['admin', 'manager'],
  'settings-notifications': ['admin', 'manager'],
  'layouts': ['admin', 'manager'],
  'layout': ['admin', 'manager'],
  'templates': ['admin'],
  'engagement-standalone': ['admin', 'manager', 'assistant'],
  'registration': ['admin', 'manager', 'assistant'],
  'analytics': ['admin', 'manager', 'assistant'],
  'analytics-download': ['admin', 'manager'],
};

const functionAccountAccessMatrix = {
  'studio-presenter-control': ['trial', 'standard', 'business', 'enterprise'],
  'studio-source-stream': ['trial', 'business', 'enterprise'],
  'studio-outbound-stream': ['trial', 'business', 'enterprise'],
  'studio-source-html': ['trial', 'standard', 'business', 'enterprise'],
  'branding-sponsor-logo': ['trial', 'standard', 'business', 'enterprise'],
  'event-registration': ['trial', 'standard', 'business', 'enterprise'], //matches pageMatrix
  'event-chat': ['trial', 'standard', 'business', 'enterprise'],
  'event-qa': ['trial', 'basic', 'standard', 'business', 'enterprise'],
  'event-poll': ['trial', 'standard', 'business', 'enterprise'],
  'event-quiz': ['trial', 'business', 'enterprise'],
  'event-survey': ['trail', 'business', 'enterprise'],
  'settings-ondemand': ['trial', 'standard', 'business', 'enterprise'],
  'settings-pages': ['trial', 'standard', 'business', 'enterprise'],
  'templates': ['trial', 'business', 'enterprise'], //not yet implemented
};

const functionUserAccessMatrix = {
  'create-event': ['admin'],
  'delete-event': ['admin'],
  'duplicate-event': ['admin'],
  'studio-control': ['admin', 'manager'],
  'studio-output-qa': ['admin', 'manager', 'assistant'],
  'studio-output-chat': ['admin', 'manager', 'assistant'],
  'studio-source-playlist': ['admin', 'manager'],
  'templates': ['admin'],
};

export default {
  methods: {
    allow(page, user = null, account = null) {
      const _user = user || this.$store.state.sessionData?.user;
      const _account = account || this.$store.state.sessionData?.account;

      if (!page) {
        console.error(
          'Route name not specified in src/router/index.js for ' +
            new URL(window.location).pathname
        );
        return false;
      }
      const key = page.toLowerCase();

      const accountAccess = pageAccountAccessMatrix[key];
      if (accountAccess && !accountAccess.includes(_account.type)) {
        return false;
      }
      if (_user.userType == 'super') {
        return true;
      }
      const userAccess = pageUserAccessMatrix[key];
      return !userAccess || userAccess.includes(_user.userType);
    },
    can(func, user = null, account = null) {
      const _user = user || this.$store.state.sessionData?.user;
      const _account = account || this.$store.state.sessionData?.account;
      const key = func.toLowerCase();

      const accountAccess = functionAccountAccessMatrix[key];
      if (accountAccess && !accountAccess.includes(_account.type)) {
        return false;
      }
      if (_user.userType == 'super') {
        return true;
      }
      const userAccess = functionUserAccessMatrix[key];
      return !userAccess || userAccess.includes(_user.userType);
    },
  },
};
