const b64UrlList =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

function b64UrlEncode(typedArray) {
  let output = [];
  if (typedArray instanceof Uint8Array) {
    for (let i = 0; i < typedArray.length; i += 3) {
      let [b1, b2, b3] = typedArray.slice(i, i + 3);
      if (b2 == null) b2 = 0;
      if (b3 == null) b3 = 0;
      let o1, o2, o3, o4;
      o1 = b64UrlList[(b1 & 0xfc) >> 2];
      o2 = b64UrlList[((b1 & 0x03) << 4) + ((b2 & 0xf0) >> 4)];
      if (i + 1 >= typedArray.length) {
        o3 = '=';
      } else {
        o3 = b64UrlList[((b2 & 0x0f) << 2) + ((b3 & 0xc0) >> 6)];
      }
      if (i + 2 >= typedArray.length) {
        o4 = '=';
      } else {
        o4 = b64UrlList[b3 & 0x3f];
      }
      output.push(o1, o2, o3, o4);
    }
  } else {
    throw new Error('Unhandled type');
  }
  return output.join('');
}

export function string(len) {
  const arr = new Uint8Array(Math.ceil((len / 4) * 3));
  crypto.getRandomValues(arr);
  return b64UrlEncode(arr).substring(0, len);
}
