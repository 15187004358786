import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import includes from 'lodash/includes';
import axios from 'axios';
import { detect } from 'detect-browser';
import Notify from '@/mixins/Notify';
import Access from '@/mixins/Access';
import api from '@/services/api';
import VueGtm from '@gtm-support/vue2-gtm';

import '@/filters';

window.axios = axios;
const browser = detect();
const supportBrowsers = [
  'chrome',
  'safari',
  'firefox',
  'edge',
  'edge-chromium',
  'edge-ios',
  'chromium-webview',
  'crios',
  'ios',
  'ios-webview',
  'android',
  'opera',
];

Vue.mixin(Notify);
Vue.mixin(Access);
// Google Tag Manager
Vue.use(VueGtm, {
  id: 'GTM-5PSB7KP',
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // Unsupported browser redirect
  if (!supportBrowsers.includes(browser.name)) {
    if (to.name != 'error') {
      return next('/error/unsupported');
    }
  }

  // Unauthenticated users always go to the login page
  if (
    !includes(['error', 'login', 'forgot', 'reset', 'setup', 'signup'], to.name)
  ) {
    if (!store.state.sessionData) {
      store.commit({
        type: 'intended',
        path: to.path,
      });

      return next('/login');
    }
  }

  // logged in
  if (store.state.sessionData) {
    const { user, account } = store.state.sessionData;
    // push userId to gtm
    window.dataLayer?.push({
      userId: user.id,
    });

    if (account.type != 'enterprise') {
      // Redirect expired trials and subscriptions to plan page
      // Retain access to billing details and account closure
      if (!includes(['/billing', '/billing/plan'], to.path)) {
        if (
          (store.getters.isTrialAccount && !store.getters.isTrialValid) ||
          store.getters.subscriptionStatus == 'canceled' ||
          store.getters.subscriptionStatus == 'unpaid'
        ) {
          return next('/billing/plan');
        }
      }

      if (!Access.methods.allow(to.name, user, account)) {
        return next('/error/denied');
      }
    }
  }

  store.commit('setPageTitle', to.meta.title || null);
  return next();
});

api('/account/getSession')
  .then((session) => {
    if (session.data?.loggedIn) {
      store.commit('loggedIn', session.data);
    }
  })
  .catch((e) => {
    console.error(e);
  })
  .finally(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  });
