<template>
  <div class="h-full w-full">
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full justify-start overflow-x-auto border-b border-grey-200 md:relative"
      >
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/analytics'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
        >
          Statistics
        </RouterLink>
        <RouterLink
          :to="'/events/' + event.eventShortcode + '/analytics/logins'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
        >
          Attendees
        </RouterLink>
        <RouterLink
          v-if="allow('analytics-download')"
          :to="'/events/' + event.eventShortcode + '/analytics/download'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
        >
          Download
        </RouterLink>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <RouterView :event="event" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['event'],

  data() {
    return {
      loading: false,
    };
  },
};
</script>
