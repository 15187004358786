<template>
  <div
    class="cursor-pointe my-1 block w-full rounded"
    :class="{
      'cursor-not-allowed bg-grey-200': disabled,
      'cursor-pointer': !disabled,
    }"
  >
    <div
      v-if="!isConfirm"
      class="flex justify-between p-2 hover:bg-grey-100"
      @click.prevent="open"
    >
      <slot></slot>
    </div>
    <div v-else class="h-full w-full">
      <Animation animation="transition.expandIn">
        <div class="flex items-center justify-between">
          <div @click="close">
            <i
              class="material-icons-outlined flex cursor-pointer items-center justify-center rounded p-1 hover:bg-grey-100 hover:text-black"
            >
              close
            </i>
          </div>
          <button
            :class="confirmClass"
            @click.prevent="process"
            class="flex-1 rounded px-4 py-2 hover:bg-grey-100"
          >
            Confirm
          </button>
        </div>
      </Animation>
    </div>
  </div>
</template>

<script>
import Animation from '@/components/Animation';
import { mapState } from 'vuex';

export default {
  name: 'DropdownInlineConfirmButton',
  components: {
    Animation,
  },
  props: ['confirmClass', 'disabled'],
  data: () => ({
    isConfirm: false,
    listener: null,
  }),
  computed: {
    ...mapState(['confirmationOpen']),
  },
  mounted() {
    this.listener = (e) => {
      if (e.target !== this.$el && !this.$el.contains(e.target)) {
        this.isConfirm = false;
      }
    };
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  watch: {
    isConfirm(visible) {
      this.$emit('visibility-changed', visible);

      if (visible) {
        this.addEventListeners();
        return;
      }

      this.removeEventListeners();
    },
    confirmationOpen: function (val) {
      if (val == false) {
        this.close();
      }
    },
  },
  methods: {
    addEventListeners() {
      window.addEventListener('click', this.listener);
    },

    removeEventListeners() {
      window.removeEventListener('click', this.listener);
    },

    open() {
      if (this.disabled) return;

      this.isConfirm = true;
      this.$store.commit('confirmationOpen', this.isConfirm);
    },

    close() {
      this.isConfirm = false;
    },

    process() {
      this.$emit('callback');
      this.isConfirm = false;
    },
  },
};
</script>
