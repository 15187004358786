export default {
  methods: {
    notify(type, message) {
      var parent = document.getElementById('notifications');
      var node = document.createElement('div');

      var notifyClass = '';
      var icon = '';
      switch (type) {
        case 'error':
          icon = '<i class="material-icons-outlined text-white">cancel</i>';
          notifyClass = 'bg-red-500 text-white';

          break;
        case 'warning':
          icon = '<i class="material-icons-outlined text-white">error</i>';
          notifyClass = 'bg-orange-500 text-white';

          break;
        case 'success':
          icon =
            '<i class="material-icons-outlined text-white">check_circle</i>';
          notifyClass = 'bg-green-500 text-white';

          break;
        case 'message':
        default:
          icon =
            '<i class="material-icons-outlined text-white">check_circle</i>';
          notifyClass = 'bg-white text-black';

          break;
      }

      node.classList =
        'notification shadow-md rounded mb-3 relative ' + notifyClass;
      node.setAttribute('data-timestamp', Date.now());
      node.innerHTML = `<div class="p-4 flex flex-row"><div class="flex items-center justify-center px-1">
                                ${icon}
                             </div>
                             <div class="flex flex-col justify-between px-4 max-w-lg">${message}</div></div>
                             <div class="notify_progress absolute bottom-0 h-2 w-full bg-black opacity-25"></div>
                             `;

      parent.appendChild(node);
    },
  },
};
