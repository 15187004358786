<template>
  <div>
    <div class="relative h-20">
      <div
        class="tabs absolute flex w-full justify-start overflow-x-auto border-b border-grey-100 md:relative"
      >
        <RouterLink
          v-if="allow('settings-general')"
          :to="'/events/' + event.eventShortcode + '/settings'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >General
        </RouterLink>
        <RouterLink
          v-if="
            allow('settings-registration') &&
            this.registrationEvent &&
            !completeEvent
          "
          :to="'/events/' + event.eventShortcode + '/settings/registration'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Registration
        </RouterLink>
        <RouterLink
          v-if="allow('settings-engagement')"
          :to="'/events/' + event.eventShortcode + '/settings/engagement'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Engagement
        </RouterLink>
        <RouterLink
          v-if="allow('settings-notifications') && !completeEvent"
          :to="'/events/' + event.eventShortcode + '/settings/notifications'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Notifications
        </RouterLink>
        <RouterLink
          v-if="allow('settings-survey') && event.surveyEnabled"
          :to="'/events/' + event.eventShortcode + '/settings/survey'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >Survey
        </RouterLink>
        <RouterLink
          v-if="allow('settings-pages')"
          :to="'/events/' + event.eventShortcode + '/settings/pages'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-10"
          >Pages
        </RouterLink>
        <RouterLink
          v-if="allow('settings-ondemand')"
          :to="'/events/' + event.eventShortcode + '/settings/vod'"
          class="block flex flex-1 cursor-pointer items-center justify-center whitespace-nowrap p-4 hover:bg-grey-100"
          exact-active-class="border-b border-blue-800 bg-grey-100"
          >On demand
        </RouterLink>
      </div>
    </div>

    <div class="p-4 md:p-8">
      <RouterView
        :event="event"
        :loadEvent="loadEvent"
        @set-rego-form-id="handleSetRegoFormId"
        @set-survey-form-id="handleSetSurveyFormId"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
    loadEvent: Function,
  },
  computed: {
    completeEvent() {
      return this.event.eventState == 'complete';
    },
    registrationEvent() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.event.registrationType);
    },
  },
  methods: {
    handleSetRegoFormId(id) {
      this.$emit('set-rego-form-id', id);
    },
    handleSetSurveyFormId(id) {
      this.$emit('set-survey-form-id', id);
    },
  },
};
</script>
