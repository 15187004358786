<template>
  <div class="relative flex min-h-screen w-full flex-1 flex-col">
    <PortalTarget name="modal-position" class="z-40" />

    <!-- top menu -->
    <div
      class="z-30 flex h-20 flex-shrink-0 items-center justify-center space-x-2 bg-purple-500 px-2 shadow-lg md:px-4"
    >
      <!-- mobile menu -->
      <button
        class="mx-4 flex justify-end lg:hidden"
        @click.prevent="toggleMenu"
      >
        <div class="nav-icon" :class="{ open: isMenuActive }">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      <!-- back links and logo -->
      <div class="flex w-60">
        <RouterLink to="/dashboard">
          <img src="../assets/logo.png" class="hidden h-10 lg:flex" />
          <img src="../assets/logo-icon.png" class="flex h-10 w-10 lg:hidden" />
        </RouterLink>
      </div>

      <!-- title and dropdown -->
      <div
        class="flex min-w-0 flex-1 items-center justify-start space-x-4 text-left lg:text-center"
      >
        <div
          v-if="event.config.eventShortcode"
          class="hidden flex-1 items-center rounded-lg bg-purple-600 px-4 py-3 text-white md:flex"
        >
          <div class="rounded bg-purple-400 px-2 py-1 capitalize">
            <div v-if="event.config.eventState == 'vod_open'">VOD: Open</div>
            <div v-else-if="event.config.eventState == 'vod_closed'">
              VOD: Closed
            </div>
            <div v-else>
              {{ event.config.eventState }}
            </div>
          </div>
          <div class="ml-4">{{ event.config.title }}</div>
        </div>
      </div>

      <!-- account links -->
      <div
        class="flex w-2/12 items-center justify-end lg:w-3/12 lg:space-x-4 lg:px-4"
      >
        <div v-if="isTrialValid">
          <RouterLink
            v-if="allow('billing-details')"
            to="/billing/plan"
            class="border-blue-200 hidden items-center space-x-2 rounded-3xl border-2 px-3 py-1 text-sm text-blue-500 hover:border-blue-800 hover:text-blue-800 lg:flex"
          >
            <i class="material-icons-outlined text-blue-500">timelapse</i>
            <div class="whitespace-nowrap">Upgrade Trial</div>
          </RouterLink>

          <RouterLink
            to="/billing/plan"
            class="flex w-12 justify-end lg:hidden"
          >
            <i class="material-icons-outlined text-blue-500 hover:text-blue-800"
              >timelapse</i
            >
          </RouterLink>
        </div>

        <div v-if="subscriptionStatus == 'past_due'">
          <RouterLink
            v-if="allow('billing-details')"
            to="/billing/plan"
            class="hidden items-center space-x-2 rounded-3xl border-2 border-orange-500 px-3 py-1 text-sm text-orange-500 hover:border-orange-800 hover:text-orange-800 lg:flex"
          >
            <i class="material-icons-outlined text-orange-500"
              >report_problem</i
            >
            <div class="whitespace-nowrap">Urgent billing issue</div>
          </RouterLink>

          <RouterLink
            to="/billing/plan"
            class="flex w-12 justify-end lg:hidden"
          >
            <i
              class="material-icons-outlined text-orange-500 hover:text-orange-800"
              >report_problem</i
            >
          </RouterLink>
        </div>
      </div>

      <DropdownMenu>
        <template slot="control" slot-scope="slotProps">
          <div
            class="group flex cursor-pointer items-center rounded-lg px-4 py-2 hover:bg-purple-400"
            :class="{ 'bg-purple-400': slotProps.visible }"
            @click.prevent="slotProps.toggle()"
          >
            <i
              class="material-icons-outlined hidden rounded-full bg-grey-400 px-2 py-1 text-3xl text-white md:flex"
              >person</i
            >
            <div class="flex flex-1 flex-col md:ml-4">
              <div class="whitespace-nowrap text-white">
                {{ user.firstName }} {{ user.lastName }}
              </div>
              <div class="hidden text-xs capitalize text-grey-400 md:flex">
                {{ user.userType }}
              </div>
            </div>
            <i
              class="material-icons-outlined transform duration-150 group-hover:text-white md:ml-8"
              :class="{ 'rotate-90': slotProps.visible }"
              >keyboard_arrow_right</i
            >
          </div>
        </template>

        <template slot="menu">
          <RouterLink to="/profile" class="dropdownListItemWithIcon">
            <span>Your profile</span>
            <i class="material-icons-outlined">person</i>
          </RouterLink>
          <RouterLink
            v-if="allow('billing-details')"
            to="/billing"
            class="dropdownListItemWithIcon"
          >
            <span>Billing</span>
            <i class="material-icons-outlined">loyalty</i>
          </RouterLink>
          <RouterLink
            v-if="allow('users') || allow('layouts') || allow('templates')"
            :to="accountSettingsUri"
            class="dropdownListItemWithIcon"
          >
            <span>Account settings</span>
            <i class="material-icons-outlined">manage_accounts</i>
          </RouterLink>
          <a
            href="https://support.joinin.live/"
            target="_blank"
            class="dropdownListItemWithIcon"
          >
            <span>Help</span>
            <i class="material-icons-outlined">help</i>
          </a>
          <div class="border-b border-grey-200"></div>
          <a @click.prevent="logOut()" class="dropdownListItemWithIcon">
            <span>Log out</span>
            <i class="material-icons-outlined">logout</i>
          </a>
        </template>
      </DropdownMenu>
    </div>

    <!-- sidebar menu -->
    <Transition name="slide">
      <div v-if="isMenuActive" class="fixed z-20 flex h-full w-2/3 pt-20">
        <div class="flex-1 bg-purple-500" @click.prevent="isMenuActive = false">
          <NavigationLinks />
        </div>
      </div>
    </Transition>
    <div
      v-if="isMenuActive"
      class="fixed z-10 h-full w-full bg-black opacity-75"
      @click.prevent="toggleMenu"
    ></div>

    <!-- content -->
    <div class="items-strench flex flex-1 overflow-x-hidden">
      <div class="hidden w-64 bg-purple-500 lg:flex">
        <NavigationLinks />
      </div>
      <div class="flex flex-1 flex-col bg-grey-100 md:p-4">
        <div class="flex items-center bg-grey-100 px-4 py-4 md:px-0 md:pt-0">
          <i
            v-if="$route.meta.allowNavBack"
            @click.prevent="goBack"
            class="material-icons-outlined mx-4 cursor-pointer rounded px-1 hover:bg-grey-200 md:ml-auto"
            >navigate_before</i
          >
          <div class="flex flex-1 flex-col items-start justify-center">
            <div class="text-md font-medium md:text-xl">
              {{ $route.meta.title }}
            </div>
          </div>
        </div>
        <div
          class="content flex flex-1 flex-col overflow-y-auto rounded bg-white"
        >
          <div class="flex">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { PortalTarget } from 'portal-vue';
import DropdownMenu from '@/components/DropdownMenu';
import NavigationLinks from '@/components/NavigationLinks';
import api from '@/services/api';

export default {
  components: {
    DropdownMenu,
    NavigationLinks,
    PortalTarget,
  },

  data: () => ({
    isMenuActive: false,
  }),

  computed: {
    ...mapState(['event']),
    ...mapGetters([
      'user',
      'account',
      'username',
      'isTrialValid',
      'subscriptionStatus',
    ]),
    eventTitle() {
      return this.$route.path.match(/^\/events/);
    },
    to() {
      if (this.$route.name == 'template') {
        return '/templates';
      } else if (this.$route.name == 'layout') {
        return '/layouts';
      } else {
        return '/dashboard';
      }
    },
    registrationOpen() {
      return [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(this.event.config.registrationType);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    accountSettingsUri() {
      if (this.allow('users')) {
        return '/settings/users';
      }

      if (this.allow('layouts')) {
        return '/settings/layouts';
      }

      if (this.allow('forms')) {
        return '/settings/forms';
      }
    },
  },

  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    goBack() {
      if (this.$route.name == 'attendee-edit') {
        //new/edit registration
        this.$router.replace(
          `/events/${this.event.eventShortcode}/registration`
        );
        return;
      }

      this.$router.back();
    },
    logOut() {
      api.post('/authentication/logout');
      this.$store.commit('loggedOut');
      this.$router.replace('/login');
    },
  },
};
</script>

<style>
.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
