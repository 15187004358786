<template>
  <div class="flex flex-grow justify-center bg-grey-50">
    <div class="w-full bg-white">
      <LoadingIcon v-if="!isLoaded" />
      <div v-else>
        <div class="pb-8 font-bold capitalize">{{ form.type }} template</div>
        <form @submit.prevent="save">
          <MdTextField
            class="flex-1"
            label="Name"
            name="formNanme"
            v-model="form.name"
            :required="true"
            :errors="errors"
          />

          <MdTextareaField
            class="flex-1"
            label="Description"
            name="formDescription"
            v-model="form.description"
            :rows="2"
            :required="false"
            :errors="errors"
          />

          <FormBuilder
            :form="form"
            @onRemove="handleRemoveForm"
            type="template"
          />

          <SubmitButton :saving="saving" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import errors from '@/mixins/errors';

import SubmitButton from '@/components/SubmitButton';
import FormBuilder from '@/components/FormBuilder';
import LoadingIcon from '@/components/ui/LoadingIcon';
import MdTextField from '@/components/MdTextField';
import MdTextareaField from '@/components/MdTextareaField';

export default {
  mixins: [errors],
  name: 'FormTemplate',
  data: () => ({
    isLoaded: false,
    form: null,
    saving: false,
  }),
  components: {
    FormBuilder,
    LoadingIcon,
    MdTextField,
    MdTextareaField,
    SubmitButton,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      api.get(`/forms/templates/${this.id}`).then((response) => {
        this.isLoaded = true;
        this.form = response.data;
      });
    },
    handleRemoveForm: function () {
      this.$router.replace('/templates');
    },
    save() {
      api
        .put(`/forms/templates/${this.id}`, {
          name: this.form.name,
          description: this.form.description,
        })
        .then(() => {
          this.saving = false;

          this.notify('success', 'Form updated');
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
        });
    },
  },
};
</script>
