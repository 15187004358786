<template>
  <div class="flex w-full flex-grow flex-col">
    <div v-if="!can('event-chat')" class="my-6">
      <UpgradePlanLink />
    </div>

    <div v-else class="flex h-full flex-grow flex-col">
      <!-- main content -->
      <template v-if="section == null">
        <div class="relative flex h-full flex-col break-words">
          <!-- search filter -->
          <div class="my-3 flex items-center justify-between space-x-2">
            <SelectFilter
              v-if="!isSearch"
              class="flex-1"
              v-model="filter"
              :options="filterOptions"
            />

            <input
              v-if="isSearch"
              type="text"
              v-model="search"
              placeholder="Search messages..."
              ref="search"
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button @click.prevent="isSearch = !isSearch">
              <i
                v-if="!isSearch"
                class="material-icons-outlined flex justify-center"
                >search</i
              >
              <i
                v-if="isSearch"
                class="material-icons-outlined flex justify-center"
                >sort</i
              >
            </button>

            <RouterLink
              :to="{
                name: 'output-chat',
                params: { shortcode: $route.params.shortcode },
              }"
              target="_blank"
              class="flex items-center text-xs text-blue-500 hover:text-blue-800"
            >
              <i
                class="material-icons-outlined flex justify-center pr-1 text-sm text-blue-500"
                >dvr</i
              >Open display
            </RouterLink>
          </div>

          <div
            v-if="!filteredChatMessages.length"
            class="py-4 text-center text-grey-400"
          >
            No messages to display
          </div>

          <!-- chat messages -->
          <div
            v-else
            ref="scroll"
            @scroll="onScroll"
            class="flex flex-grow flex-col overflow-auto"
          >
            <div
              v-for="m in filteredChatMessages"
              :key="m.id"
              class="border-b border-grey-100 px-1 py-2 hover:bg-grey-100"
            >
              <div class="flex flex-row items-center">
                <div
                  class="flex-1 text-grey-400"
                  :class="{ 'text-grey-300 line-through': m.isHidden }"
                >
                  {{ formatName(m) || '(No name)' }}
                </div>
                <div class="flex items-center space-x-4">
                  <div class="text-grey-400">{{ formatDate(m.createdAt) }}</div>

                  <DropdownMenu v-if="convertableToQA">
                    <template slot="control" slot-scope="slotProps">
                      <button
                        @click.stop="slotProps.toggle()"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i class="material-icons-outlined">more_vert</i>
                      </button>
                    </template>

                    <template slot="menu">
                      <button
                        @click.prevent="convertToQaQuestion(m)"
                        class="dropdownListItem"
                      >
                        Move to Q&A
                      </button>
                    </template>
                  </DropdownMenu>
                </div>
              </div>
              <div
                class="whitespace-pre-line py-1 lg:py-2"
                :class="{
                  'text-grey-300': m.approvalState != APPROVED,
                  'line-through': m.approvalState == REJECTED,
                }"
              >
                <LinkifiedText :text="m.content" />
              </div>
              <div v-if="m.imageUrl" class="flex justify-center py-1 lg:py-2">
                <img class="max-h-80" :src="m.imageUrl" />
              </div>

              <div v-for="r in m.replies" :key="r.id">
                <div
                  class="ml-10 mt-4 text-grey-400"
                  :class="{
                    'text-grey-300 opacity-100': m.approvalState == REJECTED,
                  }"
                >
                  <div class="flex justify-between">
                    <div
                      :class="{ 'line-through': m.approvalState == REJECTED }"
                    >
                      {{ formatReplyName(r) }}
                    </div>
                    <div>{{ formatDate(r.createdAt) }}</div>
                  </div>

                  <div class="flex justify-between">
                    <div
                      :class="{ 'line-through': m.approvalState == REJECTED }"
                      class="whitespace-pre-line"
                    >
                      <LinkifiedText :text="r.content" />
                    </div>

                    <TooltipModal
                      triggerType="hover"
                      width="48"
                      textAlign="center"
                    >
                      <template slot="control">
                        <i
                          @click="removeReply(m.id, r.id)"
                          class="material-icons-outlined mx-1 mt-1 cursor-pointer text-icon-sm text-grey-300 hover:text-red-700"
                        >
                          close
                        </i>
                      </template>

                      <template slot="content">
                        <div class="flex flex-col space-y-4">
                          <div class="text-sm">Delete this reply message</div>
                        </div>
                      </template>
                    </TooltipModal>
                  </div>
                </div>
              </div>

              <div class="flex flex-row pt-1">
                <button
                  @click.prevent="show(m.id)"
                  class="pr-4 text-grey-400 hover:text-green-800"
                  :class="{ 'text-green-500': m.approvalState == APPROVED }"
                >
                  {{ m.approvalState == APPROVED ? 'Showing' : 'Show' }}
                </button>
                <button
                  @click.prevent="hide(m.id)"
                  class="text-grey-400 hover:text-red-700"
                  :class="{ 'text-red-500': m.approvalState == REJECTED }"
                >
                  {{ m.approvalState == REJECTED ? 'Hidden' : 'Hide' }}
                </button>
                <div class="flex flex-grow justify-end">
                  <TooltipModal
                    triggerType="hover"
                    width="48"
                    textAlign="center"
                  >
                    <template slot="control">
                      <button
                        v-if="can('studio-output-chat')"
                        @click.prevent="pushToOutput(m)"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i
                          v-if="m.isOnScreen"
                          class="material-icons-outlined text-blue-500"
                        >
                          dvr
                        </i>
                        <i v-else class="material-icons-outlined"> tv </i>
                      </button>
                    </template>

                    <template slot="content">
                      <div v-if="m.isOnScreen" class="text-sm">
                        Remove this message from the Output screen.
                      </div>
                      <div v-else class="text-sm">
                        Display this message on the Output screen.
                      </div>
                    </template>
                  </TooltipModal>

                  <TooltipModal
                    triggerType="hover"
                    width="48"
                    textAlign="center"
                  >
                    <template slot="control">
                      <button
                        v-if="m.approvalState != REJECTED"
                        @click.prevent="navigateToReply(m)"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i class="material-icons-outlined">reply</i>
                      </button>
                    </template>

                    <template slot="content">
                      <div class="flex flex-col space-y-4">
                        <div class="text-sm">
                          Publicly reply to chat message
                        </div>
                      </div>
                    </template>
                  </TooltipModal>
                </div>
              </div>
            </div>
          </div>

          <button
            @click="handleClickNewMessages"
            v-if="isNewMessages"
            class="absolute bottom-5 flex items-center self-center rounded-full bg-black bg-opacity-50 px-3 py-1 text-sm text-white"
          >
            New messages
            <i class="material-icons-outlined brounce text-white"
              >keyboard_arrow_down</i
            >
          </button>
        </div>
      </template>
      <!-- end of main -->

      <!-- sub content -->
      <template v-else>
        <!-- sub content navigation -->
        <div class="py-6">
          <button
            class="float-left flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            @click.prevent="section = null"
          >
            <i class="material-icons-outlined text-3xl text-black"
              >arrow_back</i
            >
          </button>
          <h3 class="-ml-10 w-full text-center text-2xl font-bold">
            Post reply
          </h3>
        </div>
        <!-- sub content navigation -->

        <!-- add reply -->
        <template v-if="section == 'reply'">
          <div class="text-left text-grey-400">
            {{ formatName(selectedMessage) }}:
          </div>
          <div
            class="whitespace-pre-line pb-4 text-left"
            v-html="selectedMessage.content"
          ></div>
          <form class="flex flex-col px-3 py-2" @submit.prevent="reply">
            <TextField
              name="new_reply"
              label="Reply message"
              v-model="replyContent"
              :required="true"
              :errors="errors"
              :autofocus="true"
            />

            <button type="submit" class="btn-blue">Post publicly</button>
          </form>
        </template>
        <!-- end of add reply -->
      </template>
      <!-- end of sub content -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import filter from 'lodash/filter';

dayjs.extend(isToday);

import api from '@/services/api';
import SelectFilter from '@/components/SelectFilter';
import TextField from '@/components/TextField';
import errors from '@/mixins/errors';
import { some, sortBy } from 'lodash';
import { PENDING, APPROVED, REJECTED } from '@/helper/moderation';
import TooltipModal from '@/components/TooltipModal';
import UpgradePlanLink from '@/components/UpgradePlanLink';
import DropdownMenu from '@/components/DropdownMenu';
import LinkifiedText from '@/components/LinkifiedText';

export default {
  name: 'StudioPanelChat',
  mixins: [errors],
  components: {
    SelectFilter,
    TextField,
    TooltipModal,
    UpgradePlanLink,
    DropdownMenu,
    LinkifiedText,
  },
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
    },
  },
  data: () => ({
    PENDING,
    APPROVED,
    REJECTED,

    section: null,
    search: null,
    isSearch: false,
    filter: 'all',
    filterOptions: [
      {
        id: 'all',
        label: 'All',
      },
      {
        id: 'pending',
        label: 'Pending',
      },
      {
        id: 'approved',
        label: 'Showing',
      },
      {
        id: 'rejected',
        label: 'Hidden',
      },
    ],
    replyContent: '',
    selectedMessage: null,
    newMessagesCount: 0,
    isNewMessages: false,
    autoScroll: true,
    scrollToTimeout: null,
  }),
  computed: {
    ...mapState(['chatMessages', 'event']),

    filteredChatMessages() {
      let res = this.chatMessages;

      if (this.filter == 'pending') {
        res = res.filter((m) => m.approvalState == PENDING);
      } else if (this.filter == 'rejected') {
        res = res.filter((m) => m.approvalState == REJECTED);
      } else if (this.filter == 'approved') {
        res = res.filter((m) => m.approvalState == APPROVED);
      }

      if (this.search) {
        const s = this.search.toLowerCase();
        res = res.filter((m) =>
          some(
            [this.formatName(m), m.content],
            (text) => text.toLowerCase().indexOf(s) > -1
          )
        );
      }

      return sortBy(res, 'createdAt', 'id');
    },

    /**
     * Chat messages can be coverted to QA questions if both chat and qa are enabled on this event.
     */
    convertableToQA() {
      return this.event.config.chatEnabled && this.event.config.qaEnabled;
    },
  },
  beforeDestroy() {
    clearTimeout(this.scrollToTimeout);
  },
  watch: {
    isActive: function (val) {
      if (val == true) {
        this.section = null;
      }
    },
    isSearch: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
    isFocus: function (newValue, oldValue) {
      if (oldValue == false && newValue == true && this.$refs.scroll) {
        this.newMessagesCount = 0;

        this.$emit('newMessagesNotification', this.newMessagesCount);

        this.autoScroll = true;
        this.scrollToBottom();
      }
    },
    chatMessages: function (newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length > oldVal.length) {
        //count new incoming messages when not focus
        if (!this.isFocus) {
          this.newMessagesCount += newVal.length - oldVal.length;
          this.$emit('newMessagesNotification', this.newMessagesCount);
        } else {
          this.scrollToBottom();
          if (
            this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight >
            this.$refs.scroll.scrollTop
          ) {
            this.isNewMessages = true;
          }
        }
      }
    },
  },
  methods: {
    convertToQaQuestion(message) {
      const chatMessages = filter(
        this.chatMessages,
        (existingMessage) => existingMessage.id != message.id
      );

      this.$store.commit('SET_MESSAGES', chatMessages);

      api.post(
        `/engagement/chat/${this.event.jobId}/messages/${message.id}/convert`
      );
    },

    navigateToReply(message) {
      this.selectedMessage = message;
      this.section = 'reply';
    },
    formatDate(time) {
      if (dayjs(time).isToday()) {
        return dayjs(time).format('h:mma');
      } else {
        return dayjs(time).format('h:mma, DD MMM YY');
      }
    },
    show(id) {
      this.$store.dispatch('SHOW_MESSAGE', id);
    },
    hide(id) {
      this.$store.dispatch('HIDE_MESSAGE', id);
    },
    reply() {
      this.$store.dispatch('REPLY_TO_MESSAGE', {
        id: this.selectedMessage.id,
        content: this.replyContent,
      });

      this.replyContent = '';
      this.selectedMessage = null;
      this.section = null;
      this.scrollToBottom();
    },
    removeReply(messageId, id) {
      this.$store.dispatch('REMOVE_MESSAGE_REPLY', { messageId, id });
    },
    formatName: ({ firstName, lastName }) => {
      return [firstName, lastName].filter((x) => x).join(' ');
    },
    formatReplyName: ({ firstName, lastName, accountName }) => {
      return (
        [firstName, lastName].filter((x) => x).join(' ') +
        ' (' +
        accountName +
        ')'
      );
    },
    scrollToBottom() {
      if (this.autoScroll) {
        this.scrollToTimeout = setTimeout(() => {
          this.$refs.scroll.scrollTo(0, this.$refs.scroll.scrollHeight);
        }, 100);
      }
    },
    handleClickNewMessages() {
      this.autoScroll = true;
      this.scrollToBottom();
    },
    onScroll() {
      if (
        this.$refs.scroll.scrollHeight - this.$refs.scroll.offsetHeight ==
        this.$refs.scroll.scrollTop
      ) {
        this.isNewMessages = false;
        this.autoScroll = true;
      } else {
        this.autoScroll = false;
      }
    },
    pushToOutput(m) {
      this.$store.dispatch('SET_MESSAGE_OUTPUT', {
        messageId: m.isOnScreen ? null : m.id,
      });
    },
  },
};
</script>
