<template>
  <div class="w-full">
    <div class="flex flex-col p-4 md:p-8">
      <form v-if="user" class="mt-8" @submit.prevent="save()">
        <MdTextField
          name="firstname"
          label="First name"
          v-model="form.firstName"
          :required="true"
          tooltip="Your first name."
          :errors="errors"
        />

        <MdTextField
          name="lastname"
          label="Last name"
          v-model="form.lastName"
          :required="true"
          tooltip="Your last name."
          :errors="errors"
        />

        <MdTextField
          name="email"
          label="Email"
          v-model="form.email"
          :required="true"
          tooltip="Your email address. This is used as your login."
          :errors="errors"
        />

        <div class="mb-6 pt-6 text-lg font-medium">Change password</div>
        <div class="pb-6 text-grey-400">
          To change your password enter your current password and a new
          password.
        </div>

        <MdTextField
          name="curentPassword"
          label="Current password"
          type="password"
          v-model="form.password"
          :required="false"
          :errors="errors"
        />

        <MdTextField
          name="newPassword"
          label="New password"
          type="password"
          v-model="form.newPassword"
          :required="false"
          :errors="errors"
        />

        <MdTextField
          name="repeatPassword"
          label="Repeat new password"
          type="password"
          v-model="form.repeatPassword"
          :required="false"
          :errors="errors"
        />

        <div class="flex justify-center">
          <SubmitButton
            classes="btn-blue-alt w-32"
            :saving="saving"
            :disabled="!isValid"
            label="Save"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import assign from 'lodash/assign';
import pick from 'lodash/pick';
import keys from 'lodash/keys';

import api from '@/services/api';
import errors from '@/mixins/errors';
import MdTextField from '@/components/MdTextField';
import SubmitButton from '@/components/SubmitButton';

export default {
  name: 'ProfilePage',
  mixins: [errors],
  components: {
    MdTextField,
    SubmitButton,
  },
  data: () => ({
    saving: false,
    form: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      password: '',
      newPassword: '',
      repeatPassword: '',
    },
  }),
  computed: {
    ...mapState({
      user: (s) => s.sessionData.user,
    }),
    isValid() {
      return (
        (this.form.password == '' &&
          this.form.newPassword == '' &&
          this.form.repeatPassword == '') ||
        (this.form.password != '' &&
          this.form.newPassword != '' &&
          this.form.newPassword == this.form.repeatPassword)
      );
    },
  },
  mounted() {
    this.$store.commit('RESET_JOB');
    this.setupForm();
  },
  methods: {
    setupForm() {
      assign(this.form, pick(this.user, keys(this.form)));
    },
    save() {
      this.saving = true;

      api
        .post('/account/editProfile', this.form)
        .then((response) => {
          this.saving = false;

          if (response.status >= 200 && response.status < 300) {
            this.form.password = '';
            this.form.newPassword = '';
            this.form.repeatPassword = '';

            this.$store.commit(
              'SET_USER',
              pick(this.form, ['firstName', 'lastName', 'email'])
            );
            this.notify('success', 'Profile updated');
          }
        })
        .catch((e) => {
          this.saving = false;
          this.form.password = '';
          this.form.newPassword = '';
          this.form.repeatPassword = '';

          if (
            e.response.data.errorCode &&
            e.response.data.errorCode == 'pg23505'
          ) {
            this.notify(
              'error',
              'That email address is already in use. Please use another.'
            );
          } else {
            this.notify('error', e.response.data.error);
          }
        });
    },
  },
};
</script>
