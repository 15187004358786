<template>
  <div class="flex flex-col justify-start">
    <div class="relative mb-8 flex items-center">
      <label
        v-if="label"
        class="float-label absolute text-grey-400"
        :class="floatLabel ? 'float-label-active top-0' : 'cursor-text'"
        :for="name"
        >{{ label }}</label
      >

      <div class="flex w-full flex-col pt-3">
        <div class="flex w-full flex-row flex-wrap">
          <div
            v-for="item in list"
            :key="item.index"
            class="mx-1 my-1 flex flex-row items-center justify-between whitespace-nowrap rounded-full border border-grey-200 px-2"
            :class="{ 'border-red-500 text-red-500': item.duplicated }"
          >
            {{ item.val }}
            <i
              @click="handleRemoveItem(item.index)"
              class="material-icons-outlined ml-1 cursor-pointer border-l border-grey-200 pl-1 text-sm hover:text-black"
              :class="{ 'border-red-500 text-red-500': item.duplicated }"
            >
              close
            </i>
          </div>
        </div>

        <input
          :type="type"
          class="w-full border-b border-grey-300 pt-3 text-black focus:border-blue-500"
          :class="{
            'border-red-500 text-red-500 focus:border-red-500': isError,
          }"
          :id="name"
          :name="name"
          autocomplete="off"
          v-model="localValue"
          :autofocus="autofocus"
          @focus="focused = true"
          @blur="focused = false"
          @keydown.enter.prevent="process"
        />

        <i
          class="material-icons-outlined absolute bottom-2 right-6 cursor-pointer hover:text-black"
          @click.prevent="process"
          >add</i
        >
      </div>

      <TooltipModal v-if="tooltip">
        <template slot="control">
          <div class="-ml-5 -mt-2 flex items-center space-x-1 text-sm">
            <i class="material-icons-outlined text-icon-sm">info</i>
          </div>
        </template>

        <template slot="content">
          {{ tooltip }}
        </template>
      </TooltipModal>
    </div>

    <div>
      <ValidationErrors :errors="errors" :name="name" />
    </div>
  </div>
</template>

<script>
import TooltipModal from '@/components/TooltipModal';
import ValidationErrors from '@/components/ValidationErrors';

const domainValidatePattern =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

export default {
  name: 'MdTextSelectField',

  components: {
    TooltipModal,
    ValidationErrors,
  },

  props: {
    errors: {},
    name: { type: String, required: true },
    value: { type: Array, required: true },
    label: { type: String },
    autofocus: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    tooltip: { type: String },
    type: { type: String, default: 'text' },
    validateMode: { type: String },
  },

  data() {
    return {
      localValue: null,
      focused: false,
      checkErrors: false,
    };
  },

  computed: {
    floatLabel() {
      return this.value.length > 0 || this.focused;
    },
    list() {
      const list = [],
        duplicatedIndexes = new Set();
      for (let [i, val] of this.value.entries()) {
        const entry = {
          index: i,
          val,
          duplicated: duplicatedIndexes.has(i),
        };
        // Even if duplicated is already true, check again to catch even more duplicates
        const index = this.value.indexOf(val, i + 1);
        if (index !== -1) {
          entry.duplicated = true;
          duplicatedIndexes.set(index);
        }
        list.push(entry);
      }
      return list;
    },
    isError() {
      if (!this.checkErrors) return false;
      if (
        this.validateMode == 'domain' &&
        !domainValidatePattern.test(this.localValue)
      ) {
        return true;
      }
      if (this.value.indexOf(this.localValue) !== -1) {
        return true;
      }
      return false;
    },
  },

  watch: {
    localValue() {
      this.checkErrors = false;
    },
  },

  methods: {
    handleRemoveItem(index) {
      //reset error
      this.checkErrors = false;
      this.$emit('remove-item', index);
    },
    process() {
      if (this.localValue == null) {
        return;
      }
      if (this.validateMode == 'domain') {
        //prefix http / www / @ remove
        this.localValue = this.localValue
          .toLowerCase()
          .replace(/^http+s?:\/\//i, '')
          .replace(/^www./i, '')
          .replace(/^@/i, '');
      }

      this.checkErrors = true;
      if (!this.isError) {
        this.$emit('add-item', this.localValue);
        this.localValue = null;
      }
    },
  },
};
</script>
