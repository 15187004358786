<template>
  <div>
    <div class="mb-3 pt-6 text-lg font-medium">Close account</div>
    <div class="pb-3 text-grey-400">
      Permanently delete all event and account information and close the
      account.
    </div>
    <a
      href="mailto:support@joinin.live?subject=Account closure request"
      class="text-sm text-red-700"
      >Request account closure</a
    >
  </div>
</template>

<script>
export default {
  name: 'AccountClose',
};
</script>
