<template>
  <div class="row flex w-full items-center">
    <button
      v-if="!userGesture"
      class="mr-2 flex h-0 items-center justify-center rounded text-xs text-grey-500"
    >
      Enable
    </button>
    <ul class="audio-meter" ref="audioMeter">
      <li v-for="i in indicatorNodes" :key="i" />
    </ul>
  </div>
</template>
<script>
import hark from 'hark';

export default {
  name: 'AudioIndicator',
  props: {
    stream: {
      type: MediaStream,
    },
    indicatorNodes: {
      type: Number,
      default: 20,
    },
    userGesture: {
      type: Boolean,
      default: false,
    },
    audioContext: {
      type: AudioContext,
      required: true,
    },
  },
  data: () => ({
    audioEvent: null,
    audioMeterInterval: null,
    isReady: false,
  }),
  watch: {
    stream: function (newStream) {
      if (newStream != null) {
        this.init();
      }
    },
    userGesture: function () {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.stream) return false;
      if (!this.userGesture) return false;

      if (this.audioEvent != null) {
        this.audioEvent.stop();
      }

      this.audioEvent = new hark(this.stream, {
        audioContext: this.audioContext,
      });
      this.audioEvent.on('volume_change', (volume) => {
        if (volume < -100) return false; //ignore
        this.soundMeter(100 + volume);
      });
    },
    soundMeter(volume) {
      if (this.$refs.audioMeter) {
        var total = Math.ceil((volume * this.indicatorNodes) / 100);
        var list = this.$refs.audioMeter.querySelectorAll('li');

        list.forEach((li) => {
          li.className = '';
        });

        for (var i = 0; i < total; i++) {
          var classStr = 'green';
          if (i >= this.indicatorNodes / 2 && i < this.indicatorNodes / 1.4) {
            classStr = 'yellow';
          } else if (i >= this.indicatorNodes / 1.4) {
            classStr = 'red';
          }

          if (list[i]) {
            list[i].classList = classStr;
          }
        }

        this.resetMeter();
      }
    },
    resetMeter() {
      clearTimeout(this.audioMeterInterval);

      this.audioMeterInterval = setTimeout(() => {
        if (this.$refs.audioMeter) {
          var list = this.$refs.audioMeter.querySelectorAll('li');

          if (list.length > 0) {
            list.forEach((li) => {
              li.className = '';
            });
          }
        }
      }, 500);
    },
  },
  beforeDestroy() {
    clearTimeout(this.audioMeterInterval);
    if (this.audioEvent) {
      this.audioEvent.stop();
    }
  },
};
</script>
<style>
.audio-meter {
  width: 100%;
  height: 3px;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.audio-meter li {
  list-style: none;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  flex: 1 1 auto;
  margin: 0 1px 0 0 !important;
}
.audio-meter li.green {
  background: #8cd08c;
}
.audio-meter li.yellow {
  background: #f2c94c;
}
.audio-meter li.red {
  background: #d87c75;
}
</style>
