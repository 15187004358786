<template>
  <div class="flex w-full flex-grow flex-col">
    <!-- main content -->
    <template v-if="section == null">
      <div class="flex h-full flex-col">
        <!-- scene list -->
        <div class="flex flex-grow flex-col overflow-auto">
          <div
            v-if="orderedScenes.length == 0 && search == null"
            class="flex flex-1 select-none justify-center text-grey-400"
          >
            <div v-if="!['rehearsal', 'live'].includes(event.eventState)">
              <div class="text-grey-400">
                Scenes can be created when the event is in Rehearsal or Live
              </div>
            </div>
            <div v-else-if="sources.length" class="text-center">
              Create your first scene using the button below
            </div>
            <div v-else class="text-center">
              Add sources to create your first scene
            </div>
          </div>
          <div v-else>
            <!-- search filter -->
            <div class="mb-3 flex items-center justify-between space-x-2">
              <input
                type="text"
                v-model="search"
                placeholder="Search scene..."
                ref="search"
                class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
              />
              <button @click="setSearchFocus">
                <i class="material-icons-outlined flex justify-center"
                  >search</i
                >
              </button>

              <!-- reorder controls -->
              <div class="relative flex items-center justify-end">
                <button
                  v-if="!isBlockReorder"
                  @click="toggleBlockReorder"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined text-3xl">toc</i>
                </button>
                <div
                  v-else-if="isBlockReorderSaving"
                  class="flex h-10 w-10 items-center justify-center"
                >
                  <i class="material-icons-outlined spin text-3xl">sync</i>
                </div>
                <div
                  v-else-if="isBlockReorder && !isBlockReorderSaving"
                  class="flex items-center justify-between"
                >
                  <button
                    @click="isBlockReorder = false"
                    class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">close</i>
                  </button>
                  <button
                    v-if="!isBlockReorderSaving"
                    @click="toggleBlockReorder"
                    class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                  >
                    <i class="material-icons-outlined">check</i>
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="orderedScenes.length == 0"
              class="flex flex-1 select-none justify-center text-grey-400"
            >
              No search results found
            </div>
          </div>

          <div
            v-for="scene in getBlocks"
            :key="scene.id"
            :id="scene.id"
            @click.self="handleSelectScene(scene)"
            class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
            :class="{ 'bg-grey-100': selectedScene == scene }"
            :draggable="isBlockReorder"
            @dragstart="handleStartDragBlock(scene)"
            @dragover="handleDragOverBlock"
            @dragend="handleStopDragBlock"
          >
            <!-- show drop target -->
            <div
              v-if="
                isBlockReorder &&
                isBlockDragging &&
                selectedBlock &&
                selectedBlock.id == scene.id
              "
              class="flex w-full flex-1 items-center justify-center rounded border border-dashed border-grey-400 py-4"
            >
              <i class="material-icons-outlined mx-2 text-grey-400"
                >vertical_align_bottom</i
              >
              Drop here
            </div>

            <!-- show block -->
            <div
              v-else
              @click="handleSelectScene(scene)"
              class="flex flex-1 items-center justify-between space-x-2"
            >
              <div class="flex-1 font-medium">{{ scene.name }}</div>
              <div class="flex space-x-2">
                <div
                  v-if="scene.id == lastSceneId"
                  class="rounded-full bg-green-500 px-2 py-1 text-xs font-bold text-white"
                >
                  Last used
                </div>
                <div
                  v-if="scene.shortcut"
                  class="rounded border border-grey-400 px-1 text-sm uppercase text-grey-400"
                >
                  {{ 'ALT + ' + scene.shortcut }}
                </div>
              </div>
            </div>

            <!-- show action menu when not in reorder mode -->
            <DropdownMenu v-if="!isBlockReorder">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click="slotProps.toggle()"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </template>

              <template slot="menu">
                <button
                  @click="navigateToRenameScene(scene)"
                  class="dropdownListItem"
                >
                  Rename
                </button>

                <button
                  @click="navigateToOverrideScene(scene)"
                  class="dropdownListItem"
                >
                  Override
                </button>

                <button
                  v-if="scene.shortcut"
                  @click="navigateToRemoveShortcut(scene)"
                  class="dropdownListItem"
                >
                  Remove keyboard shortcut
                </button>

                <button
                  v-if="!scene.shortcut"
                  @click="navigateToAddShortcut(scene)"
                  class="dropdownListItem"
                >
                  Add keyboard shortcut
                </button>

                <button
                  @click="navigateToSceneRemove(scene)"
                  class="dropdownListItem"
                >
                  Delete
                </button>
              </template>
            </DropdownMenu>

            <!-- show reorder controls -->
            <div v-else class="flex h-10 w-10 items-center rounded-full">
              <i class="material-icons-outlined text-blue-500">unfold_more</i>
            </div>
          </div>
        </div>

        <!-- selected scene preview -->
        <div class="w-full border-t border-grey-200 pt-3">
          <div v-if="selectedScene != null">
            <div class="flex py-3">
              <h3 class="flex w-full items-center text-xl font-bold">
                {{ selectedScene.name }}
              </h3>
              <button
                class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                @click="$store.commit('SELECT_SCENE', null)"
              >
                <i class="material-icons-outlined">close</i>
              </button>
            </div>

            <div class="flex flex-col">
              <div
                class="grid grid-flow-row grid-cols-12 items-center justify-between"
              >
                <div v-if="liveEvent" class="col-span-5">Current sources</div>
                <div v-if="liveEvent" class="col-span-2"></div>
                <div class="col-span-5">Scene sources</div>
              </div>
              <ul class="flex-1">
                <div
                  v-if="!getPreviewSources.length"
                  class="pt-2 text-center text-sm text-grey-400"
                >
                  This scene contains no sources.
                </div>
                <li
                  v-else
                  v-for="(item, index) in getPreviewSources"
                  :key="index"
                  class="my-1 grid grid-flow-row grid-cols-12"
                >
                  <div
                    v-if="liveEvent"
                    class="col-span-5 flex items-center border border-grey-200 py-1 pr-1 text-sm"
                    :class="{
                      'border-dashed border-grey-200': item.mode == 'add',
                      'bg-grey-100': item.mode != 'add',
                    }"
                  >
                    <div
                      v-if="item.mode == 'remain' || item.mode == 'remove'"
                      class="flex w-full flex-col text-xs"
                    >
                      <div class="flex items-center">
                        <div
                          class="flex w-5 flex-col items-center justify-between"
                        >
                          <TooltipModal
                            v-if="item.isCurrentPreview"
                            triggerType="hover"
                          >
                            <template slot="control">
                              <div
                                class="mb-1 h-2 w-2 rounded-full bg-green-500"
                              ></div>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                This source is included in the Next display.
                              </div>
                            </template>
                          </TooltipModal>
                          <TooltipModal
                            v-if="item.isCurrentProgram"
                            triggerType="hover"
                          >
                            <template slot="control">
                              <div
                                class="mb-1 h-2 w-2 rounded-full bg-blue-500"
                              ></div>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                This source is included in the On Air display.
                              </div>
                            </template>
                          </TooltipModal>
                          <TooltipModal
                            v-if="item.isCurrentOverlay"
                            triggerType="hover"
                          >
                            <template slot="control">
                              <i
                                class="material-icons-outlined text-xs text-blue-500"
                                >call_to_action</i
                              >
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                This source is included in the On Air display.
                                Overlay is ON.
                              </div>
                            </template>
                          </TooltipModal>
                          <TooltipModal
                            v-if="item.isCurrentAudioLatch"
                            triggerType="hover"
                          >
                            <template slot="control">
                              <i
                                class="material-icons-outlined -mt-1 text-xs text-blue-500"
                                >lock</i
                              >
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                This source is included in the On Air display.
                                Audio latch is ON.
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div class="flex items-center">
                          <TooltipModal triggerType="hover">
                            <template slot="control">
                              <div>
                                <i
                                  class="material-icons-outlined mr-1 text-xs"
                                  v-if="item.type == 'source_presenter'"
                                  :class="{
                                    'text-green-500': [
                                      'Background',
                                      'PresenterFallback',
                                    ].includes(item.title),
                                  }"
                                  >person</i
                                >
                                <i
                                  class="material-icons-outlined mr-1 text-xs"
                                  v-if="item.type == 'source_image'"
                                  :class="{
                                    'text-green-500': [
                                      'Background',
                                      'PresenterFallback',
                                    ].includes(item.title),
                                  }"
                                  >photo</i
                                >
                                <i
                                  class="material-icons-outlined mr-1 text-xs"
                                  v-if="item.type == 'source_video'"
                                  :class="{
                                    'text-green-500': [
                                      'Background',
                                      'PresenterFallback',
                                    ].includes(item.title),
                                  }"
                                  >videocam</i
                                >
                                <i
                                  class="material-icons-outlined mr-1 text-xs"
                                  v-if="item.type == 'source_playlist'"
                                  :class="{
                                    'text-green-500': [
                                      'Background',
                                      'PresenterFallback',
                                    ].includes(item.title),
                                  }"
                                  >auto_awesome_motion</i
                                >
                                <i
                                  class="material-icons-outlined mr-1 text-xs"
                                  v-if="item.type == 'source_html'"
                                  :class="{
                                    'text-green-500': [
                                      'Background',
                                      'PresenterFallback',
                                    ].includes(item.title),
                                  }"
                                  >public</i
                                >
                              </div>
                            </template>

                            <template slot="content">
                              <div class="flex flex-col space-y-4">
                                Type: {{ item.title }}
                              </div>
                            </template>
                          </TooltipModal>
                        </div>
                        <div class="flex-1 truncate">{{ item.name }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="liveEvent"
                    class="col-span-2 flex items-center justify-center"
                  >
                    <span
                      v-if="item.mode == 'remove'"
                      class="text-xs text-red-500"
                    >
                      Remove
                    </span>
                    <span
                      v-if="item.mode == 'add'"
                      class="text-xs text-blue-500"
                    >
                      Add
                    </span>
                    <span
                      v-if="
                        item.mode == 'remain' &&
                        (item.isCurrentAudioLatch == !item.isAudioLatch ||
                          item.isCurrentOverlay == !item.isOverlay)
                      "
                      class="text-xs text-orange-500"
                    >
                      Modify
                    </span>
                    <span
                      v-else-if="item.mode == 'remain'"
                      class="text-xs text-grey-400"
                    >
                      No change
                    </span>
                  </div>

                  <div
                    class="col-span-5 flex items-center border py-1 pr-1 text-sm"
                    :class="{
                      'border-dashed border-red-500 bg-white':
                        item.mode == 'remove',
                      'border-grey-200 bg-grey-100': item.mode != 'remove',
                      'w-full': !liveEvent,
                    }"
                  >
                    <div
                      v-if="item.mode == 'remove'"
                      class="flex flex-1 items-center justify-center"
                    >
                      <i class="material-icons-outlined text-sm text-red-500"
                        >delete_forever</i
                      >
                    </div>
                    <div
                      v-if="item.mode == 'remain' || item.mode == 'add'"
                      class="flex w-full items-center text-xs"
                    >
                      <div
                        v-if="item.mode != 'remove'"
                        class="flex w-5 flex-col items-center justify-center"
                      >
                        <TooltipModal v-if="item.isPreview" triggerType="hover">
                          <template slot="control">
                            <div
                              class="mb-1 h-2 w-2 rounded-full bg-green-500"
                            ></div>
                          </template>

                          <template slot="content">
                            <div class="flex flex-col space-y-4">
                              This source is included in the Next display.
                            </div>
                          </template>
                        </TooltipModal>
                        <TooltipModal v-if="item.isProgram" triggerType="hover">
                          <template slot="control">
                            <div
                              class="mb-1 h-2 w-2 rounded-full bg-blue-500"
                            ></div>
                          </template>

                          <template slot="content">
                            <div class="flex flex-col space-y-4">
                              This source is included in the On Air display.
                            </div>
                          </template>
                        </TooltipModal>
                        <TooltipModal v-if="item.isOverlay" triggerType="hover">
                          <template slot="control">
                            <i
                              class="material-icons-outlined -mt-1 text-xs text-blue-500"
                              >call_to_action</i
                            >
                          </template>

                          <template slot="content">
                            <div class="flex flex-col space-y-4">
                              This source is included in the On Air display.
                              Overlay is ON.
                            </div>
                          </template>
                        </TooltipModal>
                        <TooltipModal
                          v-if="item.isAudioLatch"
                          triggerType="hover"
                        >
                          <template slot="control">
                            <i
                              class="material-icons-outlined -mt-1 text-xs text-blue-500"
                              >lock</i
                            >
                          </template>

                          <template slot="content">
                            <div class="flex flex-col space-y-4">
                              This source is included in the On Air display.
                              Audio latch is ON.
                            </div>
                          </template>
                        </TooltipModal>
                      </div>
                      <div class="flex items-center">
                        <TooltipModal triggerType="hover">
                          <template slot="control">
                            <div>
                              <i
                                class="material-icons-outlined mr-1 text-xs"
                                v-if="item.type == 'source_presenter'"
                                :class="{
                                  'text-green-500': [
                                    'Background',
                                    'PresenterFallback',
                                  ].includes(item.title),
                                }"
                                >person</i
                              >
                              <i
                                class="material-icons-outlined mr-1 text-xs"
                                v-if="item.type == 'source_image'"
                                :class="{
                                  'text-green-500': [
                                    'Background',
                                    'PresenterFallback',
                                  ].includes(item.title),
                                }"
                                >photo</i
                              >
                              <i
                                class="material-icons-outlined mr-1 text-xs"
                                v-if="item.type == 'source_video'"
                                :class="{
                                  'text-green-500': [
                                    'Background',
                                    'PresenterFallback',
                                  ].includes(item.title),
                                }"
                                >videocam</i
                              >
                              <i
                                class="material-icons-outlined mr-1 text-xs"
                                v-if="item.type == 'source_playlist'"
                                :class="{
                                  'text-green-500': [
                                    'Background',
                                    'PresenterFallback',
                                  ].includes(item.title),
                                }"
                                >auto_awesome_motion</i
                              >
                              <i
                                class="material-icons-outlined mr-1 text-xs"
                                v-if="item.type == 'source_html'"
                                :class="{
                                  'text-green-500': [
                                    'Background',
                                    'PresenterFallback',
                                  ].includes(item.title),
                                }"
                                >public</i
                              >
                            </div>
                          </template>

                          <template slot="content">
                            <div class="flex flex-col space-y-4">
                              Type: {{ item.title }}
                            </div>
                          </template>
                        </TooltipModal>
                      </div>
                      <div class="flex-1 truncate">{{ item.name }}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              v-if="validateScene && liveEvent"
              class="mt-4 rounded border p-2 text-sm text-red-500"
            >
              Previewing this scene will
              <strong>change the On Air</strong> sources. Sources will either be
              removed or altered live. This may disrupt the On Air video.
              <a
                href="https://support.joinin.live/portal/en/kb/articles/scenes#Warnings"
                target="_blank"
                class="text-blue-500"
              >
                Learn more.
              </a>
            </div>
            <button
              v-if="
                !validateScene &&
                ['rehearsal', 'live'].includes(event.eventState)
              "
              class="btn-blue mt-4"
              @click="handleSendScene"
            >
              Preview on 'Next'
            </button>
            <button
              v-else-if="['rehearsal', 'live'].includes(event.eventState)"
              class="btn-red mt-4"
              @click="handleSendScene"
            >
              Preview on 'Next'
            </button>
          </div>

          <button
            v-if="!selectedScene && sources.length"
            class="btn-blue"
            @click="navigateToSaveScene"
          >
            Save scene
          </button>
        </div>
      </div>
    </template>
    <!-- end of main -->

    <!-- sub content -->
    <template v-else>
      <!-- sub content navigation -->
      <div class="flex py-3">
        <button
          class="z-10 flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          @click="navigateBack"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </button>
        <h3
          class="-ml-10 flex w-full items-center justify-center text-xl font-bold"
        >
          {{ upperFirst(section) }}
        </h3>
      </div>
      <!-- sub content navigation -->

      <!-- save scene -->
      <template v-if="section == 'save_scene'">
        <form class="flex flex-col px-3" @submit.prevent="handleSaveScene">
          <TextField
            name="scene_name"
            label="Scene name"
            v-model="name"
            :required="true"
            :errors="errors"
            :autofocus="true"
          />

          <div
            class="flex rounded-full border border-grey-200 bg-white p-3 focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2"
          >
            <div class="mr-2 rounded-full border border-grey-200 px-4">
              ALT +
            </div>

            <input
              name="shortcut"
              v-model="shortcut"
              :required="false"
              :errors="errors"
              placeholder="Optional shortcut key (0-9)"
              :autofocus="true"
              class="peer flex-1 text-center"
              maxlength="1"
              type="text"
              @keypress="validateInput"
            />
          </div>

          <div class="mt-10 text-center">
            Your 'Next' display and all active sources will be stored in the
            scene. This includes latched audio and overlays.
          </div>
          <div class="mt-4 text-center">
            If you set a shortcut key (0-9) the scene can be recalled with
            shortcut ALT + your key.
          </div>

          <button class="btn-blue mt-10" @click="handleSaveScene">
            Save scene
          </button>
        </form>
      </template>
      <!-- end of save scene -->

      <!-- rename scene -->
      <template v-if="section == 'rename_scene'">
        <div class="px-3">
          <TextField
            name="scene_name"
            label="Scene name"
            v-model="name"
            :required="true"
            :errors="errors"
            @keypress-enter="handleRenameScene"
            :autofocus="true"
          />

          <!--<TextField name="scene_description"
                        label="Scene description"
                        v-model="description"
                        :errors="errors" />-->

          <button class="btn-blue" @click="handleRenameScene">Update</button>
        </div>
      </template>
      <!-- end of rename scene -->

      <!-- override scene -->
      <template v-if="section == 'override_scene'">
        <div class="px-6">
          <h4 class="text-center text-lg font-bold">
            {{ selectedScene.name }}
          </h4>
          <div class="mt-10 text-center">
            Overriding will save over the selected scene with the current Studio
            state. Are you sure you want to override this scene?
          </div>

          <button class="btn-blue mt-10" @click="handleOverrideScene">
            Save over current scene
          </button>
        </div>
      </template>
      <!-- end of override scene -->

      <!-- add scene shortcut -->
      <template v-if="section == 'add_shortcut'">
        <div class="px-3">
          <h4 class="text-center text-lg font-bold">
            {{ selectedScene.name }}
          </h4>
          <form class="flex flex-col" @submit.prevent="handleAddShortcut">
            <div
              class="my-6 flex rounded-full border border-grey-200 bg-white p-3 focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2"
            >
              <div class="mr-2 rounded-full border border-grey-200 px-4">
                ALT +
              </div>
              <input
                name="shortcut"
                ref="shortcut_input"
                v-model="shortcut"
                :required="false"
                :errors="errors"
                placeholder="Optional shortcut key (0-9)"
                :autofocus="true"
                class="flex-1 text-center"
                maxlength="1"
                @keypress="validateInput"
              />
            </div>
            <div class="mt-4 text-center">
              If you set a shortcut key (0-9) the scene can be recalled with
              shortcut ALT + your key.
            </div>

            <button class="btn-blue mt-10" @click="handleAddShortcut">
              Add keyboard shortcut
            </button>
          </form>
        </div>
      </template>
      <!-- end of save scene -->

      <!-- delete scene shortcut -->
      <template v-if="section == 'delete_shortcut'">
        <div class="px-6">
          <h4 class="text-center text-lg font-bold">
            {{ selectedScene.name }}
          </h4>
          <div class="my-6 flex items-center justify-center">
            <span
              class="rounded border border-grey-400 px-1 text-sm uppercase text-grey-400"
              >ALT + {{ selectedScene.shortcut }}</span
            >
          </div>
          <div class="mt-10 text-center">
            Are you sure you want to remove the shortcut for the scene?
          </div>

          <button class="btn-red mt-10" @click="handleRemoveShortcut">
            Delete scene shortcut
          </button>
        </div>
      </template>
      <!-- end of delete scene shortcut -->

      <!-- delete scene -->
      <template v-if="section == 'delete_scene'">
        <div class="px-6">
          <h4 class="text-center text-lg font-bold">
            {{ selectedScene.name }}
          </h4>
          <div class="mt-10 text-center">
            Are you sure you want to delete this scene?
          </div>

          <button class="btn-red mt-10" @click="handleRemoveScene">
            Delete scene
          </button>
        </div>
      </template>
      <!-- end of delete scene -->
    </template>
    <!-- end of sub content -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DropdownMenu from '@/components/DropdownMenu';
import TooltipModal from '@/components/TooltipModal';
import TextField from '@/components/TextField';
import upperFirst from 'lodash/upperFirst';
import errors from '@/mixins/errors';

export default {
  name: 'StudioPanelScenes',
  mixins: [errors],
  components: {
    DropdownMenu,
    TextField,
    TooltipModal,
  },
  data: () => ({
    search: null,
    sceneName: null,
    sceneDescription: null,
    isSearch: false,
    section: null,
    name: null,
    description: null,
    shortcut: null,
    inputPattern: /[a-zA-Z0-9]/g,
    isBlockReorder: false, // reorder mode initialized...
    isBlockReorderSaving: false, // reorder mode savng...
    isBlockDragging: false,
    reorderBlocks: [],
    selectedBlock: null,
    selectedBlockIndex: null,
  }),
  computed: {
    ...mapState([
      'scenes',
      'modes',
      'sources',
      'trackSlots',
      'presenters',
      'files',
      'overlayId',
      'event',
      'playlists',
      'lastSceneId',
      'selectedScene',
      'htmlSources',
    ]),
    ...mapGetters(['validateScene']),

    getBlocks() {
      if (this.isBlockReorder) {
        return this.reorderBlocks;
      } else {
        return this.orderedScenes;
      }
    },

    orderedScenes() {
      return this.scenes
        .filter((scene) => {
          if (this.search) {
            return (
              scene.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
            );
          } else {
            return scene;
          }
        })
        .sort((a, b) => {
          return +a.sortId - +b.sortId > 0 ? 1 : -1;
        });
    },
    liveEvent() {
      return ['rehearsal', 'live'].includes(this.event.eventState);
    },
    selectedSources() {
      if (this.selectedScene && this.selectedScene.sources.length > 0) {
        var latchPresenters = [],
          latchMedias = [],
          overlays = [],
          presenters = [],
          medias = [],
          fallbacks = [],
          backgrounds = [],
          playlists = [],
          htmls = [];

        var isBackgroundExist = this.files.some(
          (f) => f.mediaRole == 'Background'
        );
        var isPresenterFallbackExist = this.files.some(
          (f) => f.mediaRole == 'PresenterFallback'
        );
        var isAudioLatchExist = this.sources.some((s) => s.audioLatch == true);
        var isOverlayExist = this.overlayId ? true : false;

        //audio latch
        this.selectedScene.audioLatch.map((ss) => {
          switch (ss.type) {
            case 'Presenter': {
              const cp = this.presenters.find((p) => p.pid == ss.srcObjId);
              const es = this.sources.find((s) => s.presenterId == ss.srcObjId);
              if (cp) {
                //changeProgram
                //when scene has audio latch &
                // 1. program has no audio latch
                // 2. program has different audio latch
                latchPresenters.push({
                  id: cp.pid,
                  title: 'Audio latch',
                  type: 'audio_latch',
                  name: cp.name,
                  isCurrent: es ? true : false,
                  isPreview: true,
                  isProgram: true,
                  changeProgram: !isAudioLatchExist
                    ? true
                    : this.sources.find(
                        (s) => s.audioLatch == true && s.presenterId == cp.pid
                      )
                    ? false
                    : true,
                });
              }
              break;
            }
            case 'Media': {
              const cm = this.files.find((f) => f.fid == ss.srcObjId);
              const es = this.sources.find((s) => s.mediaId == ss.srcObjId);
              if (cm) {
                //changeProgram
                //when scene has audio latch &
                // 1. program has no audio latch
                // 2. program has different audio latch
                latchMedias.push({
                  id: cm.fid,
                  title: 'Audio latch',
                  type: 'audio_latch',
                  name: cm.title,
                  isCurrent: es ? true : false,
                  isPreview: true,
                  isProgram: true,
                  changeProgram: !isAudioLatchExist
                    ? true
                    : this.sources.find(
                        (s) => s.audioLatch == true && s.mediaId == cm.fid
                      )
                    ? false
                    : true,
                });
              }
              break;
            }
            default:
              break;
          }
        });

        //overlay
        if (this.selectedScene.overlay) {
          switch (this.selectedScene.overlay.type) {
            case 'Media': {
              const co = this.files.find(
                (f) => f.fid == this.selectedScene.overlay.srcObjId
              );
              const es = this.sources.find(
                (s) => s.mediaId == this.selectedScene.overlay.srcObjId
              );
              if (co) {
                //changeProgram
                //when scene has overlay &
                // 1. program has no overlay
                // 2. program has different overlay
                overlays.push({
                  id: co.fid,
                  title: 'Overlay',
                  type: 'overlay',
                  name: co.title,
                  isCurrent: es ? true : false,
                  isPreview: true,
                  isProgram: true,
                  changeProgram: !isOverlayExist
                    ? true
                    : this.sources.find(
                        (s) => s.sid == this.overlayId && s.mediaId == co.fid
                      )
                    ? false
                    : true,
                });
              }
              break;
            }
            case 'Playlist': {
              const cp = this.playlists.find(
                (pl) => pl.id == this.selectedScene.overlay.srcObjId
              );
              const es = this.sources.find(
                (s) => s.playlistId == this.selectedScene.overlay.srcObjId
              );
              if (cp) {
                //changeProgram
                //when scene has overlay &
                // 1. program has no overlay
                // 2. program has different overlay
                overlays.push({
                  id: cp.id,
                  title: 'Overlay',
                  type: 'overlay',
                  name: cp.name,
                  isCurrent: es ? true : false,
                  isPreview: true,
                  isProgram: true,
                  changeProgram: !isOverlayExist
                    ? true
                    : this.sources.find(
                        (s) => s.sid == this.overlayId && s.playlistId == cp.id
                      )
                    ? false
                    : true,
                });
              }
              break;
            }
          }
        }

        //sources
        // type: presenter
        // type: media - standard media file / presenter fallback / background
        this.selectedScene.sources.map((ss) => {
          switch (ss.type) {
            case 'Presenter': {
              const cp = this.presenters.find((p) => p.pid == ss.srcObjId);
              const es = this.sources.find((s) => s.presenterId == ss.srcObjId);
              if (cp) {
                presenters.push({
                  id: cp.pid,
                  title: 'Presenter',
                  type: 'source_presenter',
                  name: cp.name,
                  isCurrent: es ? true : false,
                  isPreview: this.selectedScene.mixer.find(
                    (sm) => sm.srcObjId == ss.srcObjId
                  )
                    ? true
                    : false,
                  isProgram: false,
                  changeProgram: false,
                });
              }
              break;
            }
            case 'Media': {
              const cm = this.files.find((f) => f.fid == ss.srcObjId);
              const es = this.sources.find((s) => s.mediaId == ss.srcObjId);
              if (cm) {
                if (ss.role) {
                  switch (ss.role) {
                    case 'PresenterFallback':
                      //changeProgram
                      //when scene has presenterfallback
                      // 1. program has no presenterfallback
                      // 2. program has different presenterfallback
                      fallbacks.push({
                        id: cm.fid,
                        title: 'PresenterFallback',
                        type: 'source_' + cm.type,
                        name: cm.title,
                        isCurrent: cm
                          ? cm.mediaRole == 'PresenterFallback'
                          : false,
                        isPreview: true,
                        isProgram: true,
                        changeProgram: !isPresenterFallbackExist
                          ? true
                          : cm.mediaRole == 'PresenterFallback'
                          ? false
                          : true,
                      });

                      break;
                    case 'Background':
                      //changeProgram
                      //when scene has background
                      // 1. program has no background
                      // 2. program has different background
                      backgrounds.push({
                        id: cm.fid,
                        title: 'Background',
                        type: 'source_' + cm.type,
                        name: cm.title,
                        isCurrent: cm ? cm.mediaRole == 'Background' : false,
                        isPreview: true,
                        isProgram: true,
                        changeProgram: !isBackgroundExist
                          ? true
                          : cm.mediaRole == 'Background'
                          ? false
                          : true,
                      });

                      break;
                    default:
                      break;
                  }
                } else {
                  //changeProgram
                  //add new source should never disrupt program
                  medias.push({
                    id: cm.fid,
                    title: 'Media',
                    type: 'source_' + cm.type,
                    name: cm.title,
                    isCurrent: es ? true : false,
                    isPreview: this.selectedScene.mixer.find(
                      (sm) => sm.srcObjId == ss.srcObjId
                    )
                      ? true
                      : false,
                    isProgram: false,
                    changeProgram: false,
                  });
                }
              }
              break;
            }
            case 'Playlist': {
              const cp = this.playlists.find((pl) => pl.id == ss.srcObjId);
              const es = this.sources.find((s) => s.playlistId == ss.srcObjId);
              playlists.push({
                id: cp.id,
                title: 'Playlist',
                type: 'source_playlist',
                name: cp.name,
                isCurrent: es ? true : false,
                isPreview: this.selectedScene.mixer.find(
                  (sm) => sm.srcObjId == ss.srcObjId
                )
                  ? true
                  : false,
                isProgram: false,
                changeProgram: false,
              });

              break;
            }
            case 'HTML': {
              const ch = this.htmlSources.find((hs) => hs.id == ss.srcObjId);
              const es = this.sources.find((s) => s.htmlId == ss.srcObjId);
              //console.log(ch, es);
              htmls.push({
                id: ch.id,
                title: 'HTML',
                type: 'source_html',
                name: ch.name,
                isCurrent: es ? true : false,
                isPreview: this.selectedScene.mixer.find(
                  (sm) => sm.srcObjId == ss.srcObjId
                )
                  ? true
                  : false,
                isProgram: false,
                changeProgram: false,
              });

              break;
            }
            default:
              break;
          }
        });

        return [
          ...presenters,
          ...medias,
          ...playlists,
          ...latchPresenters,
          ...latchMedias,
          ...overlays,
          ...fallbacks,
          ...backgrounds,
          ...htmls,
        ];
      } else {
        return [];
      }
    },
    getPreviewSources() {
      var list = [];
      this.sources.map((s) => {
        switch (s.type) {
          case 'Media':
            var cs = this.selectedSources.find((ss) => ss.id == s.mediaId);
            var f = this.files.find((f) => f.fid == s.mediaId);

            list.push({
              mode: cs ? 'remain' : 'remove',
              id: cs ? cs.id : null,
              name: f.title,
              title: 'Media',
              type: cs ? cs.type : 'source_' + f.type,
              isPreview: cs ? cs.isPreview : null,
              isProgram: cs ? cs.isProgram : null,
              isCurrentPreview: Object.values(this.trackSlots.preview).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentProgram: Object.values(this.trackSlots.program).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentAudioLatch: s.audioLatch ? s.audioLatch : false,
              isCurrentOverlay:
                this.overlayId && this.overlayId == s.sid ? true : false,
            });

            break;
          case 'Presenter': {
            const cp = this.selectedSources.find(
              (ss) => ss.id == s.presenterId
            );
            const p = this.presenters.find((_p) => _p.pid == s.presenterId);

            list.push({
              mode: cp ? 'remain' : 'remove',
              id: cp ? cp.id : null,
              name: p.name,
              title: 'Presenter',
              type: cp ? cp.type : 'source_presenter',
              isPreview: cp ? cp.isPreview : null,
              isProgram: cp ? cp.isProgram : null,
              isCurrentPreview: Object.values(this.trackSlots.preview).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentProgram: Object.values(this.trackSlots.program).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentAudioLatch: s.audioLatch ? s.audioLatch : false,
              isCurrentOverlay:
                this.overlayId && this.overlayId == s.sid ? true : false,
            });

            break;
          }
          case 'Playlist': {
            const cp = this.selectedSources.find((ss) => ss.id == s.playlistId);
            const p = this.playlists.find((_p) => _p.id == s.playlistId);

            list.push({
              mode: cp ? 'remain' : 'remove',
              id: cp ? cp.id : null,
              name: p.name,
              title: 'Playlist',
              type: cp ? cp.type : 'source_playlist',
              isPreview: cp ? cp.isPreview : null,
              isProgram: cp ? cp.isProgram : null,
              isCurrentPreview: Object.values(this.trackSlots.preview).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentProgram: Object.values(this.trackSlots.program).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentAudioLatch: s.audioLatch ? s.audioLatch : false,
              isCurrentOverlay:
                this.overlayId && this.overlayId == s.sid ? true : false,
            });

            break;
          }
          case 'HTML':
            var ch = this.selectedSources.find((ss) => ss.id == s.htmlId);
            var h = this.htmlSources.find((hs) => hs.id == s.htmlId);

            list.push({
              mode: ch ? 'remain' : 'remove',
              id: ch ? ch.id : null,
              name: h.name,
              title: 'HTML',
              type: ch ? ch.type : 'source_html',
              isPreview: ch ? ch.isPreview : null,
              isProgram: ch ? ch.isProgram : null,
              isCurrentPreview: Object.values(this.trackSlots.preview).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentProgram: Object.values(this.trackSlots.program).find(
                (track) => track.sourceId == s.sid
              )
                ? true
                : false,
              isCurrentAudioLatch: s.audioLatch ? s.audioLatch : false,
              isCurrentOverlay:
                this.overlayId && this.overlayId == s.sid ? true : false,
            });

            break;
          default:
            break;
          //more types to come
        }
      });

      this.files
        .filter((f) => f.mediaRole != null)
        .map((f) => {
          var cm = this.selectedSources.find((ss) => ss.title == f.mediaRole);

          list.push({
            mode: !cm || cm.id == f.fid ? 'remain' : 'remove',
            id: f.fid,
            name: f.title,
            title: f.mediaRole,
            type: 'source_' + f.type,
            isPreview: !cm || cm.id == f.fid ? true : false,
            isProgram: !cm || cm.id == f.fid ? true : false,
            isCurrentPreview: true,
            isCurrentProgram: true,
            isCurrentAudioLatch: false,
            isCurrentOverlay: false,
          });
        });

      this.selectedSources.map((ss) => {
        if (ss.type.includes('source') && ss.isCurrent == false) {
          list.push({
            mode: 'add',
            id: ss.id,
            name: ss.name,
            title: ss.title,
            type: ss.type,
            isPreview: ss.isPreview,
            isProgram: ss.isProgram,
            isCurrentPreview: false,
            isCurrentProgram: false,
            isCurrentAudioLatch: false,
            isCurrentOverlay: false,
          });
        }
      });

      //overlay
      this.selectedSources
        .filter((ss) => ss.type == 'overlay')
        .map((ss) => {
          var r = list.find((r) => r.id == ss.id);
          if (r) {
            r.isOverlay = true;
          }
        });

      //audio latch
      this.selectedSources
        .filter((ss) => ss.type == 'audio_latch')
        .map((ss) => {
          var r = list.find((r) => r.id == ss.id);
          if (r) {
            r.isAudioLatch = true;
          }
        });

      //console.log('Next', this.selectedSources);
      //console.log('List', list);
      return list;
    },
    /*warnProgramChange() {
            var warning = false;

            //check program sources
            //current program has source
            //scene doesn't
            Object.values(this.trackSlots.program).map(p => {
               var source = this.sources.find(s => s.sid == p.sourceId);
               if(source) {
                   switch(source.type) {
                        case 'Presenter':
                            var newSource = this.selectedSources.find(ss => ss.id == source.presenterId);
                            if(!newSource) {
                                warning = true;
                            }
                            break;
                        case 'Media':
                            var newSource = this.selectedSources.find(ss => ss.id == source.mediaId);
                            if(!newSource) {
                                warning = true;
                            }
                        case 'Playlist':
                            var newSource = this.selectedSources.find(ss => ss.id == source.playlistId);
                            if(!newSource) {
                                warning = true;
                            }
                            break;
                   }
                }
            });

            //check audio latch
            //current program has audio latch
            //scene doesn't
            if(this.selectedScene.audioLatch.length == 0 && this.sources.some(s => s.audioLatch == true)) {
                warning = true;
            }

            //check overlay
            //current program has overlay
            //scene doesn't
            if(!this.selectedScene.overlay && this.overlayId) {
                warning = true;
            }

            //check component changes
            this.selectedSources.map(ss => {
                if(ss.changeProgram == true) {
                    warning = true;
                }
            });

            return warning;
        },*/
  },
  methods: {
    navigateBack() {
      this.section = null;
      this.$store.commit('SELECT_SCENE', null);
      this.clearInputFields();
    },
    handleSelectScene(scene) {
      this.$store.commit('SELECT_SCENE', scene);
    },
    handleRemoveSelectedScene() {
      this.$store.commit('SELECT_SCENE', null);
    },
    handleSendScene() {
      this.$store.dispatch('SEND_SCENE', this.selectedScene.id);
      this.$store.commit('SELECT_SCENE', null);
    },
    navigateToRenameScene(scene) {
      this.$store.commit('SELECT_SCENE', scene);

      this.name = this.selectedScene.name;
      this.section = 'rename_scene';
    },
    navigateToOverrideScene(scene) {
      this.$store.commit('SELECT_SCENE', scene);

      this.name = this.selectedScene.name;
      this.section = 'override_scene';
    },
    handleRenameScene() {
      this.$store.dispatch('RENAME_SCENE', {
        id: this.selectedScene.id,
        name: this.name,
      });
      this.$store.commit('SELECT_SCENE', null);
      this.section = null;
    },
    navigateToSaveScene() {
      this.name = null;
      this.section = 'save_scene';
    },
    navigateToSceneRemove(scene) {
      this.$store.commit('SELECT_SCENE', scene);

      this.section = 'delete_scene';
    },
    handleOverrideScene() {
      this.$store.dispatch('OVERRIDE_SCENE', {
        id: this.selectedScene.id,
        name: this.selectedScene.name,
        shortcut: this.selectedScene.shortcut,
      });
      this.$store.commit('SELECT_SCENE', null);
      this.section = null;
    },
    navigateToAddShortcut(scene) {
      this.shortcut = null;
      this.$store.commit('SELECT_SCENE', scene);

      this.section = 'add_shortcut';
      this.$nextTick(() => this.$refs.shortcut_input.focus());
    },
    navigateToRemoveShortcut(scene) {
      this.$store.commit('SELECT_SCENE', scene);

      this.section = 'delete_shortcut';
    },
    handleAddShortcut() {
      this.$store.dispatch('UPDATE_SCENE_SHORTCUT', {
        id: this.selectedScene.id,
        shortcut: this.shortcut.toLowerCase(),
      });

      this.section = null;
      this.$store.commit('SELECT_SCENE', null);
    },
    handleRemoveShortcut() {
      this.$store.dispatch('UPDATE_SCENE_SHORTCUT', {
        id: this.selectedScene.id,
        shortcut: null,
      });

      this.section = null;
      this.$store.commit('SELECT_SCENE', null);
    },
    handleRemoveScene() {
      this.$store.dispatch('REMOVE_SCENE', this.selectedScene.id);
      this.$store.commit('SELECT_SCENE', null);

      this.section = null;
      this.$store.commit('SELECT_SCENE', null);
    },
    handleSaveScene() {
      this.$store.dispatch('SAVE_SCENE', {
        name: this.name,
        shortcut: this.shortcut?.toLowerCase(),
      });

      this.section = null;
      this.clearInputFields();
    },
    clearInputFields() {
      this.name = null;
      this.shortcut = null;
    },
    setSearchFocus() {
      this.$refs.search.focus();
    },
    upperFirst(value) {
      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },
    validateInput(e) {
      var found = e.key.match(/[0-9]/g);
      if (!found) e.preventDefault();
    },

    handleStartDragBlock(block) {
      this.selectedBlock = block;

      setTimeout(() => {
        this.isBlockDragging = true;
      }, 200);
    },

    handleDragOverBlock(e) {
      e.preventDefault();

      if (
        e.target &&
        e.target.id &&
        e.target.id !== '' &&
        +e.target.id != this.selectedBlock.id &&
        +e.target.id != this.currentOrderBlockTargetId
      ) {
        this.currentOrderBlockTargetId = +e.target.id;

        const cIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.selectedBlock.id
        );
        const tIndex = this.reorderBlocks.findIndex(
          (block) => block.id == this.currentOrderBlockTargetId
        );

        this.reorderBlocks.splice(
          tIndex,
          0,
          this.reorderBlocks.splice(cIndex, 1)[0]
        );
      }
    },

    handleStopDragBlock() {
      this.isBlockDragging = false;
    },

    toggleBlockReorder() {
      this.isBlockReorder = !this.isBlockReorder;

      if (this.isBlockReorder) {
        // load blocks to reorder array
        this.search = null;
        this.reorderBlocks = [...this.orderedScenes];
      } else {
        // save order
        const list = this.reorderBlocks.map((b) => b.id);

        this.$store.commit('SET_SCENES', this.reorderBlocks);
        this.$store.dispatch('REORDER_SCENES', list);
      }
    },
  },
};
</script>
