<template>
  <div>
    <div v-if="['edit', 'add'].includes(section)">
      <div class="flex flex-1 flex-col">
        <div class="flex items-center px-4 py-4 md:px-0 md:pt-0">
          <i
            @click="section = 'listview'"
            class="material-icons-outlined mx-4 cursor-pointer rounded px-1 hover:bg-grey-200 md:ml-auto"
            >navigate_before</i
          >
          <div class="flex flex-1 flex-col items-start justify-center">
            <div class="text-center text-2xl font-medium">
              {{ section == 'add' ? 'New' : 'Update' }} client
            </div>
          </div>
        </div>
        <div
          class="content flex flex-1 flex-col overflow-y-auto rounded bg-white"
        >
          <div class="flex">
            <slot></slot>
          </div>
        </div>
      </div>
      <form @submit.prevent="submitEditClient" class="mt-4">
        <MdTextField
          name="client"
          label="Name"
          v-model="form.name"
          :errors="errors"
          :required="true"
        />

        <div class="flex items-center space-x-4">
          <div class="flex-1">
            <MdSelectField
              name="type"
              label="Client type"
              v-model="form.ioDirection"
              :errors="errors"
              :options="typeOptions"
            />
          </div>

          <div class="mb-6">
            <TooltipModal>
              <template slot="control">
                <button class="flex items-center space-x-1 text-sm">
                  <i class="material-icons-outlined">info</i>
                </button>
              </template>

              <template slot="content">
                <div class="flex flex-col space-y-4">
                  <div>
                    <span class="font-bold">Input:</span> the client will
                    <span class="font-bold text-green-500">send</span>
                    video/audio signals to Joinin.
                  </div>
                  <div>
                    <span class="font-bold">Output:</span> the client will
                    <span class="font-bold text-green-500">receive</span>
                    video/audio signals from Joinin.
                  </div>
                  <div>
                    <span class="font-bold">Input/Output:</span> The client will
                    <span class="font-bold text-green-500"
                      >send and receive</span
                    >
                    video/audio signals to and from Joinin.
                  </div>
                </div>
              </template>
            </TooltipModal>
          </div>
        </div>

        <MdSelectField
          v-if="['input', 'both'].includes(form.ioDirection)"
          name="bitrate"
          label="Maximum bitrate"
          v-model="form.maxBitrate"
          :errors="errors"
          :options="bitrateOptions"
        />

        <SubmitButton
          :saving="form.saving"
          :label="(section == 'add' ? 'Add new' : 'Update') + ' client'"
        />
      </form>
    </div>

    <ListView
      v-if="section == 'listview'"
      :items="displayClients"
      :search="true"
      :filterOptions="[
        { id: 'Input', label: 'Input' },
        { id: 'Output', label: 'Output' },
        { id: 'Input/Output', label: 'Input/Output' },
      ]"
      @onNewItem="handleAddClient"
    >
      <!-- template for tags which position under title -->
      <template #tags="{ item: client }">
        <div class="flex space-x-1">
          <div class="rounded bg-grey-500 px-1 text-xs uppercase text-white">
            {{ client.type }}
          </div>
          <div
            v-if="
              ['input', 'both'].includes(client.ioDirection) &&
              client.mixerMuted
            "
            class="rounded bg-orange-500 px-1 text-xs uppercase text-white"
          >
            Input Muted
          </div>
          <div
            v-if="
              ['input', 'both'].includes(client.ioDirection) &&
              client.outputMuted
            "
            class="rounded bg-orange-500 px-1 text-xs uppercase text-white"
          >
            Output Muted
          </div>
        </div>
      </template>
      <!-- end of tags template -->

      <!-- template for item title prefix-->
      <template #itemPrefix="{ item: client }">
        <div
          class="mr-4 flex w-48 flex-col items-center justify-center space-y-1 pt-2"
        >
          <div
            class="flex h-4 w-full justify-center rounded text-xs uppercase text-white"
            :class="{
              'bg-green-500': client.isOnline,
              'bg-red-500': !client.isOnline,
            }"
          >
            Signed {{ client.isOnline ? 'in' : 'out' }}
          </div>
          <div
            v-if="
              ['input', 'both'].includes(client.ioDirection) &&
              !client.webrtcStats?.length
            "
            class="flex h-4 w-full justify-center rounded text-xs uppercase text-white"
            :class="{
              'bg-green-500': client.isPublishing,
              'bg-red-500': !client.isPublishing,
            }"
          >
            {{ client.isPublishing ? '' : 'Not' }} Publishing
          </div>
          <div
            v-for="(stat, index) in client.webrtcStats"
            :key="index"
            class="flex h-4 w-full justify-center rounded bg-blue-400 text-xs uppercase text-white"
          >
            {{
              index === 0 ? 'L' : index === 1 ? 'M' : index === 2 ? 'H' : index
            }}: {{ stat.width }}x{{ stat.height }}@{{ stat.fps }}
          </div>
        </div>
      </template>
      <!-- end of item title prefix template-->

      <!-- template for main -->
      <template #body="{ item: client }">
        <div class="flex space-x-4">
          <div
            class="cursor-pointer py-2 text-sm text-grey-400"
            @click.prevent="copyText(client.password)"
          >
            Password: {{ client.password }}
          </div>
          <div
            v-if="['input', 'both'].includes(client.ioDirection)"
            class="py-2 text-sm text-grey-400"
          >
            Input max bitrate: {{ client.bitrateDisplay }}
          </div>
        </div>
      </template>
      <!-- end of main template -->

      <!-- template for dropdown menu -->
      <!-- manage custom function in current component -->
      <template #item="{ item: client }">
        <button
          @click="copyText(client.URL)"
          type="button"
          class="block flex w-full cursor-pointer items-center justify-between rounded p-2 hover:bg-grey-100"
        >
          Copy URL
          <i class="material-icons-outlined pl-1 text-icon-sm text-grey-400"
            >link</i
          >
        </button>

        <button
          @click="editClient(client.id)"
          type="button"
          style="text-align: left"
          class="block w-full cursor-pointer rounded p-2 hover:bg-grey-100"
        >
          Edit
        </button>

        <DropdownInlineConfirmButton
          @callback="deleteClient(client)"
          confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
        >
          Delete
          <i class="material-icons-outlined pl-1 text-grey-400">delete</i>
        </DropdownInlineConfirmButton>
      </template>
      <!-- end of dropdown menu template-->
    </ListView>
  </div>
</template>

<script>
import api from '@/services/api.js';

import errors from '@/mixins/errors.js';
import ListView from '@/components/ui/ListView.vue';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';
import MdTextField from '@/components/MdTextField.vue';
import MdSelectField from '@/components/MdSelectField.vue';
import TooltipModal from '@/components/TooltipModal.vue';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
  mixins: [errors],
  name: 'IoRouterClients',
  props: {
    event: { type: Object, required: true },
    presenceMembers: { type: Array, required: true },
    ioClients: { type: Array, required: true },
    janusPublishersArray: { type: Array, required: true },
    clientWebrtcStats: { type: Array },
  },
  data: () => ({
    section: 'listview',
    editClientId: null,
    form: {
      saving: false,
      name: null,
      ioDirection: null,
      maxBitrate: null,
    },
  }),
  components: {
    ListView,
    DropdownInlineConfirmButton,
    MdTextField,
    MdSelectField,
    TooltipModal,
    SubmitButton,
  },
  computed: {
    typeOptions() {
      return [
        { id: 'input', label: 'Input' },
        { id: 'output', label: 'Output' },
        { id: 'both', label: 'Input/Output' },
      ];
    },
    bitrateOptions() {
      return [
        { label: 'Max 125 kbps (l)', id: 125000 },
        { label: 'Max 200 kbps (l)', id: 250000 },
        { label: 'Max 500 kbps (l)', id: 500000 },
        { label: 'Max 750 kbps (l)', id: 750000 },
        { label: 'Max 1 Mbps (l/m)', id: 1000000 },
        { label: 'Max 1.5 Mbps (l/m)', id: 1500000 },
        { label: 'Max 2 Mbps (l/m)', id: 2000000 },
        { label: 'Max 2.5 Mbps (l/m)', id: 2500000 },
        { label: 'Max 3 Mbps (l/m)', id: 3000000 },
        { label: 'Max 3.5 Mbps (l/m/h)', id: 3500000 },
        { label: 'Max 4 Mbps', id: 4000000 },
        { label: 'Max 5 Mbps', id: 5000000 },
        { label: 'Max 6 Mbps', id: 6000000 },
        { label: 'Max 7 Mbps', id: 7000000 },
        { label: 'Max 8 Mbps', id: 8000000 },
        { label: 'Max 9 Mbps', id: 9000000 },
        { label: 'Max 10 Mbps', id: 10000000 },
        { label: 'Max 15 Mbps', id: 15000000 },
        { label: 'Max 20 Mbps', id: 20000000 },
        { label: 'Max 30 Mbps', id: 30000000 },
      ];
    },
    displayClients: function () {
      const ioDirectionMap = {
        input: 'Input',
        output: 'Output',
        both: 'Input/Output',
      };
      return this.ioClients
        .map((c) => {
          let bitrateDisplay = 'Uncapped';
          const bitrateOption = this.bitrateOptions.find(
            (bo) => bo.id == c.publisherBitrateCap
          );
          if (bitrateOption) {
            bitrateDisplay = bitrateOption.label;
          }
          return {
            id: c.id,
            name: c.name,
            password: c.password,
            ioDirection: c.ioDirection,
            type: ioDirectionMap[c.ioDirection],
            URL:
              process.env.VUE_APP_API_URL +
              `/ioclient/connect?id=${this.event.jobId}`,
            isOnline: this.presenceMembers.some(
              (m) => m.id == 'ioClient-' + c.id
            ),
            isPublishing: this.janusPublishersArray.some((p) =>
              p.id.startsWith(`IoClient-${c.id}-`)
            ),
            mixerMuted: c.mixerMuted,
            outputMuted: c.outputMuted,
            bitrateDisplay,
            webrtcStats:
              this.clientWebrtcStats
                .find((s) => c.id == s.id)
                ?.stats.sort((a, b) => a.width - b.width) ?? [],
          };
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text);

      this.$store.commit('MESSAGE', `Copied to the clipboard.`);
    },
    deleteClient(client) {
      api
        .delete(`/io/${this.event.jobId}/client/${client.id}`)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$store.commit(
              'MESSAGE',
              `Client ${client.name} has been deleted.`
            );
          }
        });
    },
    handleAddClient() {
      this.form = {
        saving: false,
        name: null,
        ioDirection: 'input',
        maxBitrate: 5000000,
      };
      this.section = 'add';
    },
    editClient(id) {
      this.editClientId = id;
      const client = this.ioClients.find((ioc) => ioc.id == id);
      this.form = {
        saving: false,
        name: client.name,
        ioDirection: client.ioDirection,
        maxBitrate: client.publisherBitrateCap || 5000000,
      };
      this.section = 'edit';
    },
    submitEditClient() {
      this.form.saving = true;
      let endpoint = `/io/${this.event.jobId}/`,
        msg = 'Client ';
      if (this.section == 'add') {
        endpoint += 'addClient';
        msg += 'created';
      } else {
        endpoint += 'editClient/' + this.editClientId;
        msg += 'updated';
      }
      const body = [
        'name=' + encodeURIComponent(this.form.name),
        'ioDirection=' + encodeURIComponent(this.form.ioDirection),
        'maxBitrate=' + encodeURIComponent(this.form.maxBitrate),
      ].join('&');
      api
        .post(endpoint, body, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(() => {
          this.notify('success', msg);
          this.section = 'listview';
        })
        .catch((error) => {
          this.form.saving = false;
          this.handleErrorStatus(error);
        });
    },
  },
};
</script>
