<template>
  <div>
    <div class="flex w-full flex-1 justify-start border-b border-grey-100">
      <RouterLink
        v-if="allow('settings-general')"
        :to="'/events/' + event.eventShortcode + '/registration'"
        class="block flex flex-1 cursor-pointer items-center justify-center p-4 hover:bg-grey-100"
        exact-active-class="border-b border-blue-800 bg-grey-100"
        >Attendees
      </RouterLink>
      <RouterLink
        v-if="allow('settings-registration')"
        :to="'/events/' + event.eventShortcode + '/registration/import'"
        class="block flex flex-1 cursor-pointer items-center justify-center p-4 hover:bg-grey-100"
        exact-active-class="border-b border-blue-800 bg-grey-100"
        >Bulk import
      </RouterLink>
    </div>

    <div class="p-4 md:p-8">
      <RouterView :event="event" :loadEvent="loadEvent" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
    loadEvent: Function,
  },
  computed: {
    completeEvent() {
      return ['complete'].includes(this.event.eventState);
    },
  },
};
</script>
