<template>
  <div
    class="input-shadow flex items-center rounded-full border bg-white px-4 py-1"
    :class="focused ? 'border-blue-500' : 'border-grey-200'"
  >
    <i class="material-icons-outlined">sort</i>
    <select
      v-model="localValue"
      class="flex-1 truncate pr-2 text-grey-800 group-focus:border-blue-500"
      :style="{ width: '100%' }"
      @change="$emit('input', localValue)"
      @focus="focused = true"
      @blur="focused = false"
    >
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['options', 'value'],

  data() {
    return {
      localValue: null,
      focused: false,
    };
  },

  watch: {
    value() {
      this.localValue = this.value;
    },
  },

  mounted() {
    this.localValue = this.value;
  },
};
</script>
