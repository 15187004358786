<template>
  <div class="flex flex-1 items-start justify-center">
    <div class="rounded bg-grey-200 px-24 py-6 text-grey-500">
      Access denied
    </div>
  </div>
</template>

<script>
export default {};
</script>
