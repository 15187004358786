<template>
  <div class="p-4 md:p-8">
    <div class="flex justify-between space-x-4">
      <div class="flex flex-col justify-center">
        <RouterLink
          :to="backLink()"
          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </RouterLink>
      </div>
      <div class="flex-1">
        <div class="text-2xl font-medium">Attendee details</div>
      </div>
    </div>

    <div v-if="attendee" class="py-8">
      <div class="mb-6 flex items-center justify-between">
        <div class="text-xl font-bold">{{ getAttendeeName(attendee) }}</div>

        <!-- action buttons -->
        <div
          v-if="registrationEvent && !eventComplete"
          class="flex items-center"
        >
          <RouterLink
            :to="
              '/events/' +
              event.eventShortcode +
              '/attendee/edit/' +
              this.attendeeId
            "
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">edit</i>
          </RouterLink>

          <button
            v-if="attendee.status != 'accepted'"
            @click.prevent="setAttendeeStatus('accepted')"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">check_circle_outline</i>
          </button>

          <button
            v-if="attendee.status != 'declined'"
            @click.prevent="setAttendeeStatus('declined')"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">block</i>
          </button>

          <button
            v-if="attendee.status != 'deleted'"
            @click.prevent="confirmDeleteModal(attendee)"
            class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
          >
            <i class="material-icons-outlined">delete</i>
          </button>
        </div>
      </div>

      <div v-if="registrationEvent" class="mb-6">
        <div class="text-sm text-grey-400">Registration status</div>
        <div v-if="attendee.status == 'accepted'" class="text-green-500">
          Accepted
        </div>

        <div v-if="attendee.status == 'declined'" class="text-red-700">
          Declined
        </div>

        <div v-if="attendee.status == 'deleted'" class="text-grey-400">
          Deleted
        </div>

        <div v-if="attendee.status == 'pending'" class="text-grey-400">
          Pending
        </div>
      </div>

      <div v-if="attendee.email" class="mb-6">
        <div class="text-sm text-grey-400">Email</div>
        <div class="">{{ attendee.email }}</div>
      </div>

      <!-- log -->
      <div class="mt-8 border-t border-grey-600 py-4">
        <div class="mb-4 font-semibold">Event log</div>

        <!-- registration or login -->
        <div
          v-if="registrationEvent"
          class="flex justify-between text-sm text-grey-400"
        >
          <div>Registration submitted</div>
          <div>{{ created }}</div>
        </div>

        <div v-else class="flex justify-between text-sm text-grey-400">
          <div>Login</div>
          <div>{{ created }}</div>
        </div>

        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <div
          v-for="i in getAttendeeIntervals"
          class="flex flex-col text-sm text-grey-400"
        >
          <div v-if="i.type == 'email'" class="flex justify-between">
            <div>{{ i.log }}</div>
            <div>{{ formatDate(i.createdAt) }}</div>
          </div>
          <div v-else class="flex justify-between">
            <div>
              Attended
              {{ i.sessionType == 'stream' ? 'live stream' : 'on demand' }}
            </div>
            <div>
              {{ formatDate(i.from) }} - {{ formatDate(i.to) }} [{{
                formatDuration(i)
              }}]
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete confirmation -->
    <Modal v-if="attendee" width="96" ref="confirmDelete">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Delete attendee</h1>
        <div class="mb-6">
          Are you sure you want to delete
          <strong>{{ getAttendeeName(attendee) }}</strong
          >? They will not have access to your event after deletion.
        </div>

        <button class="btn-red" @click="setAttendeeStatus('deleted')">
          Delete attendee
        </button>
        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmDelete.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import includes from 'lodash/includes';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

import api from '@/services/api';
import Modal from '@/components/Modal';

export default {
  props: ['event'],

  components: {
    Modal,
  },

  data() {
    return {
      attendeeId: this.$route.params.attendeeId,
      attendee: null,
      loading: false,
      fromPage: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // store previous route for backLink
    next((vm) => {
      vm.fromPage = from.name;
    });
  },
  mounted() {
    this.load();
  },

  computed: {
    registrationEvent() {
      return includes(
        ['automatic_registration', 'manual_registration', 'pre_registration'],
        this.event.registrationType
      );
    },
    eventComplete() {
      // TODO: not available at page load sometimes
      return ['complete'].includes(this.event.eventState);
    },

    created() {
      return this.formatDate(this.attendee.createdAt);
    },

    getAttendeeIntervals() {
      //sort by createdAt ASC
      return this.attendee.intervals
        .map((r) => {
          if (r.from) {
            r.createdAt = r.from;
          }
          return r;
        })
        .sort((a, b) => {
          return dayjs(a.createdAt) - dayjs(b.createdAt);
        });
    },
  },

  methods: {
    load() {
      this.loading = true;
      api
        .get('/jobs/' + this.event.id + '/attendees/' + this.attendeeId)
        .then((response) => {
          this.loading = false;
          this.attendee = response.data;
        });
    },
    confirmDeleteModal(attendee) {
      this.selectedForAction = attendee;
      this.$refs.confirmDelete.show();
    },

    getAttendeeName(attendee) {
      var firstName = attendee.firstName ? attendee.firstName : '';
      var lastName = attendee.lastName ? attendee.lastName : '';

      if (!firstName && !lastName) {
        return 'Unknown';
      }

      return firstName + ' ' + lastName;
    },

    setAttendeeStatus(status) {
      this.attendee.status = status;

      api.post(
        '/jobs/' + this.event.id + '/attendees/' + this.attendee.id + '/status',
        {
          status,
        }
      );
      this.$refs.confirmDelete.close();
    },
    backLink() {
      if (this.fromPage == 'logins') {
        return '/events/' + this.event.eventShortcode + '/analytics/logins';
      } else {
        return '/events/' + this.event.eventShortcode + '/registration';
      }
    },
    formatDate(time) {
      if (time) {
        if (dayjs(time).isToday()) {
          return dayjs(time).format('h:mma');
        } else {
          return dayjs(time).format('DD MMM YYYY h:mma');
        }
      } else {
        return 'now';
      }
    },

    formatDuration({ from, to }) {
      let hours = 0,
        minutes = 0;

      const d = (dayjs(to) || this.now) - dayjs(from);
      minutes = Math.floor(d / 60_000) % 60;
      hours = Math.floor(d / 3_600_000);

      return hours ? `${hours}hr ${minutes}min` : `${minutes}min`;
    },
  },
};
</script>
