<template>
  <div class="flex flex-grow flex-col">
    <!-- main content -->
    <template v-if="section == null">
      <div class="flex h-full flex-col">
        <!-- search filter -->
        <div class="my-3 flex items-center justify-between space-x-2">
          <input
            type="text"
            v-model="search"
            placeholder="Search playlists..."
            class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
          />

          <button>
            <i class="material-icons-outlined flex justify-center">search</i>
          </button>
        </div>

        <!-- playlist -->
        <div class="flex flex-col overflow-auto lg:flex-grow">
          <div
            v-if="playlists.length == 0"
            class="mt-12 flex flex-1 justify-center text-grey-400"
          >
            Add new playlist to continue.
          </div>
          <div
            v-for="(playlist, index) in getPlaylists"
            :key="index"
            :id="'playlist_' + index"
            @click.self="handleSelectPlaylist(playlist)"
            @dblclick.prevent="addPlaylistToSource(playlist)"
            class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
            :class="{ 'bg-grey-100': selectedPlaylist == playlist }"
          >
            <div class="flex flex-shrink-0 justify-center">
              <i class="material-icons-outlined">subscriptions</i>
            </div>
            <div
              class="flex flex-1 items-center space-x-2"
              @click="handleSelectPlaylist(playlist)"
            >
              <div class="font-medium">{{ playlist.name }}</div>
            </div>
            <div
              v-if="playlist.isActive"
              class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
            >
              Active
            </div>

            <DropdownMenu ref="navigationDropdown">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.stop="handleDropdown(slotProps)"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </template>

              <template slot="menu">
                <button
                  @click.prevent="navigateToView(playlist)"
                  class="dropdownListItem"
                >
                  View
                </button>

                <button
                  @click.prevent="addPlaylistToSource(playlist)"
                  :disabled="
                    !['rehearsal', 'live'].includes(event.eventState) ||
                    playlist.isActive
                  "
                  class="dropdownListItem"
                >
                  Add to sources
                </button>

                <button
                  @click.prevent="navigateToAddMedia(playlist)"
                  class="dropdownListItem"
                >
                  Add media
                </button>

                <button
                  @click.prevent="navigateToRenamePlaylist(playlist)"
                  class="dropdownListItem"
                >
                  Rename
                </button>

                <button
                  @click.prevent="navigateToRemovePlaylist(playlist)"
                  :disabled="playlist.isActive"
                  class="dropdownListItem"
                >
                  Delete
                </button>
              </template>
            </DropdownMenu>
          </div>
        </div>
        <button
          v-if="selectedPlaylist"
          @click.prevent="handleAddToSource"
          :disabled="!isAllowToAddToSource"
          class="btn-blue"
        >
          Add to sources
        </button>
        <button v-else @click.prevent="navigateToNewPlaylist" class="btn-blue">
          New playlist
        </button>
      </div>
    </template>
    <!-- end of main -->

    <!-- sub content -->
    <template v-else>
      <!-- sub content navigation -->
      <div class="relative flex items-center justify-center py-3">
        <button
          class="z-10 mr-auto flex h-10 w-10 cursor-pointer items-center justify-center rounded-full px-2 hover:bg-grey-200"
          @click.prevent="navigateBack"
        >
          <i class="material-icons-outlined text-3xl text-black">arrow_back</i>
        </button>

        <h3
          v-if="usingPlaylistNameTitle"
          class="absolute truncate text-xl font-bold"
        >
          {{ selectedPlaylist.name }}
        </h3>
        <h3 v-else class="absolute truncate text-xl font-bold">
          {{ upperFirst(section) }}
        </h3>

        <div v-if="section == 'view_playlist'" class="ml-auto">
          <DropdownMenu v-if="!isReorder">
            <template slot="control" slot-scope="slotProps">
              <button
                @click.stop="handleDropdown(slotProps)"
                class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
              >
                <i class="material-icons-outlined">more_vert</i>
              </button>
            </template>

            <template slot="menu">
              <button
                @click.prevent="navigateToAddMedia"
                class="dropdownListItem"
              >
                Add new media
              </button>
              <button
                @click.prevent="navigateToPlaylistReorder"
                class="dropdownListItem"
              >
                Reorder
              </button>

              <!--<div class="divider border-b border-grey-100"></div>

                            <button @click="console.log('hit')"
                                class="w-full text-left p-3 block hover:bg-grey-100 rounded cursor-pointer">Delete
                            </button>-->
            </template>
          </DropdownMenu>
          <div v-if="isReorder" class="flex items-center justify-between">
            <button
              @click="isReorder = false"
              class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">close</i>
            </button>
            <button
              v-if="!isReorderSaving"
              @click="toggleReorder"
              class="ml-auto flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">check</i>
            </button>
          </div>
        </div>
      </div>
      <!-- sub content navigation -->

      <!-- new playlist -->
      <template v-if="section == 'new_playlist'">
        <form
          class="flex flex-1 flex-col overflow-hidden"
          @submit.prevent="handleCreatePlaylist"
        >
          <TextField
            name="playlist_name"
            ref="newPlaylistName"
            class="py-3"
            label="Playlist name"
            v-model="playlistName"
            :required="true"
            :errors="errors"
            :autofocus="true"
          />

          <div
            class="flex items-center justify-between space-x-2 border-t border-grey-200 pt-3"
          >
            <input
              type="text"
              v-model="fileSearch"
              placeholder="Search file..."
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button>
              <i class="material-icons-outlined flex justify-center">search</i>
            </button>
          </div>

          <div class="my-3 flex-1 overflow-auto">
            <div
              v-for="(file, index) in getFiles"
              :key="index"
              :id="index"
              @click="handleSelectFile(file.fid)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
            >
              <div class="flex flex-shrink-0 justify-center">
                <i v-if="file.type == 'image'" class="material-icons-outlined"
                  >photo</i
                >

                <i
                  v-else-if="file.type == 'video'"
                  class="material-icons-outlined"
                  >videocam</i
                >

                <i
                  v-else-if="file.type == 'presentation'"
                  class="material-icons-outlined"
                  >collections</i
                >

                <i v-else class="material-icons-outlined">insert_drive_file</i>
              </div>
              <div class="flex flex-1 items-center space-x-2">
                <div class="font-medium">{{ file.title }}</div>
              </div>
              <div class="flex h-10 items-center justify-between">
                <i
                  v-if="selectedFiles.indexOf(file.fid) === -1"
                  class="material-icons-outlined text-grey-400"
                  >radio_button_unchecked</i
                >
                <i
                  v-if="selectedFiles.indexOf(file.fid) !== -1"
                  class="material-icons-outlined text-green-500"
                  >task_alt</i
                >
              </div>
            </div>
          </div>

          <button class="btn-blue" @click="handleCreatePlaylist">Create</button>
        </form>
      </template>
      <!-- end of new playlist -->

      <!-- rename playlist -->
      <template v-if="section == 'rename_playlist'">
        <form class="px-3" @submit.prevent="handleUpdatePlaylist">
          <TextField
            name="playlist_name"
            label="Playlist name"
            v-model="selectedPlaylist.name"
            :required="true"
            :errors="errors"
            :autofocus="true"
          />

          <button class="btn-blue" @click="handleUpdatePlaylist">Rename</button>
        </form>
      </template>
      <!-- end of rename playlist -->

      <!-- remove playlist -->
      <template v-if="section == 'delete_playlist'">
        <div class="px-3">
          <div class="py-4 text-center text-xl">
            {{ selectedPlaylist.name }}
          </div>
          <div class="text-center text-grey-400">
            Are you sure you want to delete this playlist? Individual media
            files used in this playlist will not be deleted.
          </div>
          <button class="btn-red mt-12" @click="handleRemovePlaylist">
            Delete playlist
          </button>
        </div>
      </template>
      <!-- end of rename playlist -->

      <!-- view playlist -->
      <template v-if="section == 'view_playlist'">
        <div class="flex flex-grow flex-col overflow-hidden">
          <div class="pb-3 text-center text-grey-400">Playlist media</div>
          <div class="-mr-3 mb-3 flex flex-1 flex-grow flex-col overflow-auto">
            <div
              v-if="getListFiles.length == 0"
              class="flex items-center justify-center space-x-2 px-1 py-6 text-grey-400"
            >
              Please
              <button
                @click="navigateToAddMedia"
                class="mx-1 cursor-pointer text-blue-500 hover:text-blue-800"
              >
                add media
              </button>
              to the playlist
            </div>
            <div v-else>
              <div
                v-for="(file, index) in getListFiles"
                :key="index"
                :id="file.fid"
                class="flex items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
                :class="{
                  'border border-dashed border-blue-500': file.isOrder == true,
                }"
              >
                <div
                  class="flex flex-1 cursor-pointer items-center justify-between px-2"
                >
                  <div class="flex pr-1 text-grey-400">
                    {{ index + 1 + '.' }}
                  </div>
                  <div class="mr-2 flex flex-shrink-0 justify-center">
                    <i
                      v-if="file.type == 'image'"
                      class="material-icons-outlined"
                      >photo</i
                    >

                    <i
                      v-else-if="file.type == 'video'"
                      class="material-icons-outlined"
                      >videocam</i
                    >

                    <i
                      v-else-if="file.type == 'presentation'"
                      class="material-icons-outlined"
                      >collections</i
                    >

                    <i v-else class="material-icons-outlined"
                      >insert_drive_file</i
                    >
                  </div>
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="font-medium">{{ file.title }}</div>
                  </div>

                  <div
                    v-if="isPlaylistItemActive(index)"
                    class="rounded-full bg-blue-500 px-2 py-1 text-xs font-bold text-white"
                  >
                    Active
                  </div>
                  <DropdownMenu ref="playlistItemDropdown">
                    <template slot="control" slot-scope="slotProps">
                      <button
                        @click.stop="handleDropdown(slotProps)"
                        class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                      >
                        <i class="material-icons-outlined">more_vert</i>
                      </button>
                    </template>

                    <template slot="menu">
                      <button
                        @click.prevent="handleFileDuplicate(file, index)"
                        class="dropdownListItem"
                      >
                        Duplicate
                      </button>

                      <DropdownInlineConfirmButton
                        :disabled="file.isDeleteDisabled"
                        @callback="handleFileRemove(index)"
                        confirmClass="text-red-500 hover:text-red-800 hover:bg-red-100"
                      >
                        Delete
                      </DropdownInlineConfirmButton>
                    </template>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </div>
          <button
            @click.prevent="handleAddToSource"
            :disabled="!isAllowToAddToSource"
            class="btn-blue"
          >
            Add to sources
          </button>
        </div>
      </template>
      <!-- end of edit playlist -->

      <!-- reorder playlist -->
      <template v-if="section == 'reorder_playlist'">
        <div class="flex flex-grow flex-col overflow-hidden px-3">
          <div class="pb-3 text-center text-grey-400">
            Reorder playlist
            <p class="text-xs text-grey-300">* Drag&drop file to reorder</p>
          </div>
          <div class="-mr-3 mb-3 flex flex-1 flex-grow flex-col overflow-auto">
            <div
              v-if="getListFiles.length == 0"
              class="flex items-center justify-center space-x-2 px-1 py-6 text-grey-400"
            >
              Please
              <button
                @click="navigateToAddMedia"
                class="mx-1 cursor-pointer text-blue-500 hover:text-blue-800"
              >
                add media
              </button>
              to the playlist
            </div>
            <div v-else>
              <div
                v-for="(file, index) in duplicatedFiles"
                :key="index"
                :id="file.key"
                draggable
                @dragstart="onDragStart(file)"
                @dragover="onDragOver"
                @drop="onDrop"
                class="flex items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
                :class="{
                  'border border-dashed border-blue-500': file.isOrder == true,
                }"
              >
                <div
                  v-if="
                    isReorderDragging &&
                    selectedFile &&
                    selectedFile.key == file.key
                  "
                  class="flex flex-1 items-center justify-center rounded border border-dashed border-grey-400 py-4"
                >
                  <i class="material-icons-outlined mx-2 text-grey-400"
                    >vertical_align_bottom</i
                  >
                  Drop here
                </div>
                <div
                  v-else
                  class="flex flex-1 cursor-pointer items-start justify-between"
                >
                  <div class="mr-2 flex flex-shrink-0 justify-center">
                    <i
                      v-if="file.type == 'image'"
                      class="material-icons-outlined"
                      >photo</i
                    >

                    <i
                      v-else-if="file.type == 'video'"
                      class="material-icons-outlined"
                      >videocam</i
                    >

                    <i
                      v-else-if="file.type == 'presentation'"
                      class="material-icons-outlined"
                      >collections</i
                    >

                    <i v-else class="material-icons-outlined"
                      >insert_drive_file</i
                    >
                  </div>
                  <div class="flex flex-1 items-center space-x-2">
                    <div class="font-medium">{{ file.title }}</div>
                  </div>

                  <div class="flex h-10 w-10 items-center rounded-full">
                    <i class="material-icons-outlined text-blue-500"
                      >unfold_more</i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button @click="handleSavePlaylist" class="btn-blue">Save</button>
        </div>
      </template>
      <!-- end of reorder playlist -->

      <!-- add files -->
      <template v-if="section == 'add_media'">
        <div class="flex flex-1 flex-col overflow-hidden px-3">
          <div class="pb-3 text-center text-grey-400">
            Add media to playlist
          </div>
          <!-- search filter -->
          <div class="my-3 flex items-center justify-between space-x-2">
            <input
              type="text"
              v-model="fileSearch"
              placeholder="Search file..."
              class="flex-1 rounded-full border border-grey-200 bg-white px-3 py-1"
            />

            <button>
              <i class="material-icons-outlined flex justify-center">search</i>
            </button>
          </div>
          <div class="-mr-3 mb-3 flex flex-1 flex-grow flex-col overflow-auto">
            <div
              v-for="(file, index) in getFiles"
              :key="index"
              :id="index"
              @click="handleSelectFile(file.fid)"
              class="flex cursor-pointer items-center space-x-2 border-b border-grey-100 px-1 py-3 hover:bg-grey-100"
            >
              <div class="flex flex-shrink-0 justify-center">
                <i v-if="file.type == 'image'" class="material-icons-outlined"
                  >photo</i
                >

                <i
                  v-else-if="file.type == 'video'"
                  class="material-icons-outlined"
                  >videocam</i
                >

                <i
                  v-else-if="file.type == 'presentation'"
                  class="material-icons-outlined"
                  >collections</i
                >

                <i v-else class="material-icons-outlined">insert_drive_file</i>
              </div>
              <div class="flex flex-1 items-center space-x-2">
                <div class="font-medium">{{ file.title }}</div>
              </div>
              <div class="flex h-10 items-center justify-between">
                <i
                  v-if="selectedFiles.indexOf(file.fid) === -1"
                  class="material-icons-outlined text-grey-400"
                  >radio_button_unchecked</i
                >
                <i
                  v-if="selectedFiles.indexOf(file.fid) !== -1"
                  class="material-icons-outlined text-green-500"
                  >task_alt</i
                >
              </div>
            </div>
          </div>
          <button @click="handleAddMediaToPlaylist" class="btn-blue">
            Add
            <span v-if="selectedFiles.length > 0"
              >{{ selectedFiles.length }} file(s)</span
            >
          </button>
        </div>
      </template>
      <!-- end of add files -->
    </template>
    <!-- end of sub content -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownMenu from '@/components/DropdownMenu';
import TextField from '@/components/TextField';
import DropdownInlineConfirmButton from '@/components/DropdownInlineConfirmButton';
import errors from '@/mixins/errors';

import upperFirst from 'lodash/upperFirst';

export default {
  name: 'StudioPanelPlaylists',
  mixins: [errors],
  components: {
    DropdownMenu,
    TextField,
    DropdownInlineConfirmButton,
  },
  data: () => ({
    search: null,
    section: null,
    selectedPlaylist: null,
    playlistName: null,
    fileSearch: null,
    selectedFiles: [],
    selectedOrderFile: null,
    currentOrderTargetId: null,
    isEdit: false,
    isReorder: false,
    duplicatedFiles: [],
    currentActiveMedia: null,
    isReorderSaving: false,
    isReorderDragging: false,
    selectedFile: null,
    selectedFileIndex: null,
  }),
  computed: {
    ...mapState(['isAllowAddSource', 'playlists', 'files', 'sources', 'event']),

    getPlaylists() {
      return this.playlists
        .filter((pl) => {
          return pl.type != 'fixed';
        })
        .map((pl) => {
          if (this.sources.find((s) => s.playlistId && s.playlistId == pl.id)) {
            pl.isActive = true;
          } else {
            pl.isActive = false;
          }

          return pl;
        })
        .filter((pl) => {
          if (this.search) {
            return (
              pl.name.toLowerCase().match(this.search.toLowerCase()) != null
            );
          } else {
            return true;
          }
        });
    },
    getListFiles() {
      const source = this.sources.find(
        (source) => source.playlistId == this.selectedPlaylist.id
      );
      return this.selectedPlaylist.list
        .map((l) => {
          const file = this.files.find((f) => f.fid == l);

          file.isDeleteDisabled = source ? true : false;
          return file;
        })
        .sort((a, b) => {
          return b.order - a.order;
        });
    },
    getFiles() {
      return this.files
        .filter((file) => {
          if (file.section == 'fixed_playlist') return false;
          else return true;
        })
        .map((file) => {
          file.imageUrl = file.filePath;
          return file;
        })
        .filter((file) => {
          if (this.fileSearch) {
            return (
              file.title
                .toLowerCase()
                .indexOf(this.fileSearch.toLowerCase()) !== -1
            );
          } else {
            return file;
          }
        })
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
    },
    usingPlaylistNameTitle() {
      if (this.section) {
        return [
          'edit_playlist',
          'add_media',
          'view_playlist',
          'reorder_playlist',
        ].includes(this.section);
      }
      return false;
    },
    isAllowToAddToSource() {
      return (
        ['rehearsal', 'live'].includes(this.event.eventState) &&
        !this.selectedPlaylist.isActive
      );
    },
  },
  methods: {
    handleDropdown(slotProps) {
      //close prev one if available
      if (this.prevDropdown) {
        this.prevDropdown.close();
      }

      slotProps.toggle();
      this.prevDropdown = slotProps;
    },
    navigateBack() {
      switch (this.section) {
        case 'add_media':
          this.section = 'view_playlist';
          break;
        default:
          this.section = null;
          break;
      }
    },
    navigateToNewPlaylist() {
      this.selectedFiles = [];
      this.playlistName = null;
      this.section = 'new_playlist';

      this.$nextTick(() => {
        this.$refs.newPlaylistName.focus();
      });
    },
    navigateToView(playlist) {
      this.selectedPlaylist = playlist;

      this.section = 'view_playlist';
    },
    navigateToAddMedia() {
      //init selectedFiles
      this.selectedFiles = [];
      this.section = 'add_media';
    },
    navigateToEditPlaylist() {
      this.section = 'edit_playlist';
    },
    navigateToRenamePlaylist(playlist) {
      this.selectedPlaylist = playlist;
      this.section = 'rename_playlist';
    },
    navigateToRemovePlaylist(playlist) {
      this.selectedPlaylist = playlist;
      this.section = 'delete_playlist';
    },
    handleSelectPlaylist(playlist) {
      if (this.selectedPlaylist != playlist) {
        this.selectedPlaylist = playlist;
      } else {
        this.selectedPlaylist = null;
      }
    },
    handleRemovePlaylist() {
      this.$store.dispatch('REMOVE_PLAYLIST', { id: this.selectedPlaylist.id });
      this.selectedPlaylist = null;
      this.section = null;
    },
    handleCreatePlaylist() {
      this.$store.dispatch('ADD_PLAYLIST', {
        name: this.playlistName,
        list: this.selectedFiles,
      });

      this.section = null;
    },
    handleUpdatePlaylist() {
      this.$store.dispatch('RENAME_PLAYLIST', {
        id: this.selectedPlaylist.id,
        name: this.selectedPlaylist.name,
      });
      this.section = null;
    },
    handleAddMediaToPlaylist() {
      const list = [...this.selectedPlaylist.list, ...this.selectedFiles];

      this.$store.dispatch('UPDATE_PLAYLIST', {
        type: 'add_files',
        pid: this.selectedPlaylist.id,
        list: list,
      });
      this.section = 'view_playlist';
    },
    handleSelectFile(fid) {
      var index = this.selectedFiles.indexOf(fid);
      if (index === -1) {
        this.selectedFiles.push(fid);
      } else {
        this.selectedFiles.splice(index, 1);
      }
    },
    handleFileDuplicate(file, index) {
      this.$refs.playlistItemDropdown.map((d) => d.close());

      const list = this.getListFiles.map((f) => f.fid);
      list.splice(index, 0, file.fid);

      this.$store.dispatch('UPDATE_PLAYLIST', {
        pid: this.selectedPlaylist.id,
        list: list,
      });
    },
    handleFileRemove(index) {
      this.$refs.playlistItemDropdown.map((d) => d.close());

      const list = this.getListFiles.map((f) => f.fid);
      list.splice(index, 1);

      this.$store.dispatch('UPDATE_PLAYLIST', {
        pid: this.selectedPlaylist.id,
        list: list,
      });
    },
    upperFirst(value) {
      let regex = /_/gi;
      return upperFirst(value.replace(regex, ' '));
    },
    onDragStart(file) {
      this.selectedFile = file;
      setTimeout(() => {
        this.isReorderDragging = true;
      }, 200);
    },

    onDragOver(e) {
      e.preventDefault();

      if (
        e.target &&
        e.target.id &&
        e.target.id !== '' &&
        e.target.id != this.selectedFile.key &&
        e.target.id != this.currentOrderTargetId
      ) {
        this.currentOrderTargetId = e.target.id;

        const cIndex = this.duplicatedFiles.findIndex(
          (f) => f.key == this.selectedFile.key
        );
        const tIndex = this.duplicatedFiles.findIndex(
          (f) => f.key == e.target.id
        );

        this.duplicatedFiles.splice(
          tIndex,
          0,
          this.duplicatedFiles.splice(cIndex, 1)[0]
        );
      }
    },

    onDrop() {
      this.isReorderDragging = false;
    },

    navigateToPlaylistReorder() {
      this.duplicatedFiles = this.getListFiles.map((f, i) => {
        return { ...f, key: f.fid + '_' + i };
      });

      const source = this.sources.find(
        (s) => s.playlistId == this.selectedPlaylist.id
      );
      if (source) {
        this.currentActiveMedia = this.duplicatedFiles[source.playlistPosition];
      } else {
        this.currentActiveMedia = null;
      }

      this.section = 'reorder_playlist';
    },

    handleSavePlaylist() {
      var activePlaylistPosition = null;
      if (this.currentActiveMedia) {
        activePlaylistPosition = this.duplicatedFiles.findIndex(
          (f) => f.key == this.currentActiveMedia.key
        );
      }

      const list = this.duplicatedFiles.map((f) => f.fid);

      this.$store.dispatch('UPDATE_PLAYLIST', {
        pid: this.selectedPlaylist.id,
        list,
        activePlaylistPosition,
      });

      this.section = 'view_playlist';
    },

    addPlaylistToSource(playlist) {
      if (this.isAllowAddSource) {
        this.selectedPlaylist = playlist;
        this.handleAddToSource();

        this.$refs.navigationDropdown.map((dd) => dd.close());
      }
    },
    handleAddToSource() {
      this.$store.dispatch('ADD_SOURCE', {
        name: this.selectedPlaylist.name,
        type: 'Playlist',
        hasPreview: true,
        playlistId: this.selectedPlaylist.id,
      });
    },

    isPlaylistItemActive(index) {
      const source = this.sources.find(
        (s) => s.playlistId == this.selectedPlaylist.id
      );
      return source && source.playlistPosition == index;
    },
  },
};
</script>
