<template>
  <div>
    <div class="flex-1 pb-8">
      <div class="flex-1 text-grey-400">Manage event downloads</div>
    </div>

    <div v-if="hasRegistration" class="pb-8">
      <div class="text-bold py-4 font-bold">Registration</div>
      <div class="text-grey-400">
        Download a list of all registered attendees.
      </div>

      <form @submit.prevent="download_registration()">
        <div class="flex justify-center pt-8">
          <button class="btn-blue-alt" id="btn_download_registration">
            Download registration list
          </button>
        </div>
      </form>
    </div>

    <div class="flex flex-1 flex-col pb-8">
      <div class="text-bold py-4 font-bold">Event recording</div>

      <div v-if="!completeEvent" class="text-grey-400">
        Event recordings are available for download once your event is complete.
      </div>
      <div
        v-else-if="completeEvent && programRecordings.length == 0"
        class="text-grey-400"
      >
        Your recording is being finalised and will be ready shortly.
      </div>
      <div v-else-if="completeEvent && programRecordings.length > 0">
        <ul v-for="item in programRecordings" :key="item.id">
          <li>
            <a
              class="flex flex-row items-center text-blue-500"
              :href="item.url"
              target="_blank"
              ><i class="material-icons-outlined mr-3">videocam</i>
              {{ fileName(item) }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="text-bold py-4 font-bold">Statistics</div>
    <div v-if="!completeEvent" class="text-grey-400">
      Statistics can be downloaded once your event is complete.
    </div>

    <div v-else>
      <form @submit.prevent="download_statistics()">
        <div class="flex flex-1 flex-col" style="align-items: flex-start">
          <div class="pb-8 text-grey-400">
            Select the elements for download.
          </div>

          <MaterialIconsCheckbox
            name="download_statistics"
            class="mb-6"
            label="Statistics"
            v-model="form.downloadStatistics"
          />

          <MaterialIconsCheckbox
            name="download_logins"
            class="mb-6"
            label="Logins"
            v-model="form.downloadLogins"
          />

          <MaterialIconsCheckbox
            name="download_chat"
            class="mb-6"
            label="Chat messages"
            v-model="form.downloadChat"
          />

          <MaterialIconsCheckbox
            name="download_qa"
            class="mb-6"
            label="Questions"
            v-model="form.downloadQa"
          />

          <MaterialIconsCheckbox
            name="download_polls"
            class="mb-6"
            label="Poll results"
            v-model="form.downloadPollResults"
          />

          <MaterialIconsCheckbox
            name="download_poll_votes"
            class="mb-6"
            label="Poll votes"
            v-model="form.downloadPollVotes"
          />
        </div>

        <div class="flex justify-center pt-8">
          <button class="btn-blue-alt" id="btn_download_files">
            Download files
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';
import dayjs from 'dayjs';

import api from '@/services/api';

export default {
  components: {
    MaterialIconsCheckbox,
  },

  mounted() {
    this.loadProgramRecordings();
  },

  data() {
    return {
      form: {
        downloadStatistics: true,
        downloadChat: true,
        downloadQa: true,
        downloadPollResults: true,
        downloadPollVotes: true,
        downloadLogins: true,
        downloadAttendees: true,
      },
      programRecordings: [],
    };
  },

  computed: {
    ...mapState(['event']),

    jobId: (s) => s.event?.config?.id,

    // TODO: Calculated field somewhere in the store for this?
    hasRegistration: (s) =>
      [
        'automatic_registration',
        'manual_registration',
        'pre_registration',
      ].includes(s.event?.config?.registrationType),

    // TODO: Calculated field somewhere in the store for this?
    completeEvent: (s) =>
      ['complete', 'vod_open', 'vod_closed'].includes(
        s.event?.config?.eventState
      ),
  },

  methods: {
    loadProgramRecordings() {
      api.get(`/jobs/${this.jobId}/analytics/programRecordings`).then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.length > 0) {
          this.programRecordings = res.data;
        }
      });
    },
    fileName(item) {
      var title = 'Live_recording';

      if (item.recordedAt) {
        title += '_' + dayjs(item.recordedAt).format('YYYYMMDD_HH_mm');
      }

      return title;
    },

    download_registration() {
      const query = [
        this.form.downloadAttendees && this.hasRegistration && 'attendees=1',
      ]
        .filter((x) => x)
        .join('&');
      console.log({ query });
      if (query == '') {
        return;
      }
      // TODO: See what happens on failure
      const url =
        process.env.VUE_APP_API_URL +
        `/jobs/${this.jobId}/analytics/download?${query}`;
      window.open(url, '_blank');
    },

    download_statistics() {
      const query = [
        this.form.downloadStatistics && 'statistics=1',
        this.form.downloadChat && 'chat=1',
        this.form.downloadQa && 'qa=1',
        this.form.downloadPollResults && 'pollResults=1',
        this.form.downloadPollVotes && 'pollVotes=1',
        this.form.downloadLogins && 'logins=1',
      ]
        .filter((x) => x)
        .join('&');
      console.log({ query });
      if (query == '') {
        return;
      }
      // TODO: See what happens on failure
      const url =
        process.env.VUE_APP_API_URL +
        `/jobs/${this.jobId}/analytics/download?${query}`;
      window.open(url, '_blank');
    },
  },
};
</script>
