<template>
  <div>
    <LoadingIcon v-if="isLoading" />
    <div v-else>
      <div class="flex items-center justify-between pb-8">
        <div class="flex w-full flex-col items-center md:flex-row md:space-x-4">
          <div
            class="relative mb-4 flex w-full flex-1 items-center md:mb-auto md:w-auto"
          >
            <i class="material-icons-outlined mr-4">search</i>
            <input
              type="text"
              class="w-full rounded-full border border-grey-200 px-4 py-2 focus:ring-1 focus:ring-blue-500"
              v-model="s"
              placeholder="Search..."
            />
            <i
              v-if="s != ''"
              class="material-icons-outlined absolute right-6 cursor-pointer"
              @click="s = ''"
              >close</i
            >
          </div>

          <div class="flex w-full flex-1 items-center justify-start">
            <DropdownMenu ref="filterDropdownMenu">
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.prevent="slotProps.toggle()"
                  class="flex min-w-min items-center justify-center rounded-full px-4 py-2 hover:bg-grey-200"
                >
                  <i class="material-icons-outlined mr-2">sort</i>
                  {{ selectedOption && selectedOption.label }}
                </button>
              </template>

              <template slot="menu">
                <button
                  v-for="option in filterOptions"
                  :key="option.id"
                  class="dropdownListItem"
                  @click="selectOption(option)"
                >
                  {{ option.label }}
                </button>
              </template>
            </DropdownMenu>

            <template>
              <button
                @click="handleAddNewItem"
                class="group ml-auto flex items-center justify-center space-x-1 rounded-full border border-grey-200 px-4 py-2 hover:border-blue-500"
              >
                <i class="material-icons-outlined group-hover:text-blue-500"
                  >add</i
                >
                <span class="text-sm text-grey-800 group-hover:text-blue-500"
                  >New</span
                >
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="nonDeletedAttendees.length">
      <!-- attendee list -->
      <!-- bulk action tools -->
      <div class="flex items-center justify-start py-4">
        <div>
          <MaterialIconsCheckbox
            v-if="selectedAttendees.length"
            v-model="checkAll"
          />
        </div>

        <div class="h-10">
          <div v-if="selectedAttendees.length" class="flex items-center">
            <button
              @click.prevent="confirmBulkModal('accepted')"
              class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">check_circle_outline</i>
            </button>

            <button
              @click.prevent="confirmBulkModal('declined')"
              class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">block</i>
            </button>

            <button
              @click.prevent="confirmBulkModal('deleted')"
              class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
            >
              <i class="material-icons-outlined">delete</i>
            </button>
          </div>
        </div>

        <div class="text-grey-400">
          <div v-if="selectedAttendees.length">
            Update {{ selectedAttendees.length }} selected
          </div>
          <div v-else-if="selectedOption.id != 'all'">
            {{ filteredAttendees.length }} {{ selectedOption.label }}
          </div>
          <div v-else>{{ filteredAttendees.length }} attendees.</div>
        </div>

        <RouterLink
          :to="'/events/' + event.eventShortcode + '/analytics/download'"
          class="ml-auto text-blue-500"
        >
          Download registration list
        </RouterLink>
      </div>

      <div v-if="event">
        <div
          v-for="attendee in filteredAttendees"
          :key="attendee.id"
          class="flex space-x-4 border-b border-grey-200 px-1 py-4 first:border-t hover:bg-grey-100"
        >
          <div class="flex items-start">
            <MaterialIconsCheckbox v-model="attendee.selected" />
          </div>

          <div class="flex-1 overflow-hidden">
            <div
              class="overflow-hidden overflow-ellipsis text-lg font-semibold"
            >
              {{ getAttendeeName(attendee) }}
            </div>
            <div
              class="overflow-hidden overflow-ellipsis text-sm text-grey-400 md:text-base"
            >
              {{ attendee.email }}
            </div>
            <div class="text-sm text-grey-400 md:text-base">
              {{ attendee.createdAt | dateTime }} &bull; {{ attendee.company }}
            </div>

            <!-- action buttons -->
            <div class="flex space-x-6 py-2">
              <button
                @click.prevent="setAttendeeStatus(attendee, 'accepted')"
                class="text-grey-400 hover:text-green-800"
                :class="{ 'text-green-500': attendee.status == 'accepted' }"
              >
                Accepted
              </button>

              <button
                @click.prevent="setAttendeeStatus(attendee, 'declined')"
                class="text-grey-400 hover:text-red-700"
                :class="{ 'text-red-700': attendee.status == 'declined' }"
              >
                Declined
              </button>
            </div>
          </div>

          <div class="flex flex-col">
            <DropdownMenu>
              <template slot="control" slot-scope="slotProps">
                <button
                  @click.prevent="slotProps.toggle()"
                  class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-grey-200"
                >
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </template>

              <template slot="menu">
                <!-- links -->
                <RouterLink
                  :to="
                    '/events/' +
                    event.eventShortcode +
                    '/attendee/' +
                    attendee.id
                  "
                  class="dropdownListItem"
                  >View details
                </RouterLink>

                <RouterLink
                  :to="
                    '/events/' +
                    event.eventShortcode +
                    '/attendee/edit/' +
                    attendee.id
                  "
                  class="dropdownListItem"
                  >Edit
                </RouterLink>

                <button
                  @click.prevent="confirmResendInvitationModal(attendee)"
                  :disabled="attendee.status == 'declined'"
                  class="dropdownListItem"
                >
                  Resend invite email
                </button>

                <!-- actions -->
                <div
                  v-if="!eventComplete"
                  class="my-4 border border-grey-100"
                ></div>

                <a
                  v-if="!eventComplete"
                  @click.prevent="confirmDeleteModal(attendee)"
                  class="block cursor-pointer rounded p-2 hover:bg-grey-100"
                  >Delete
                </a>
              </template>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isLoading && !nonDeletedAttendees.length"
      class="pt-8 text-center text-grey-400"
    >
      There are no registered attendees.
    </div>

    <!-- Bulk confirmation -->
    <Modal width="96" ref="confirmBulk">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">
          Change attendee status
        </h1>
        <div v-if="pendingBulkAction == 'deleted'">
          <div class="mb-6">
            Are you sure you want to delete {{ selectedAttendees.length }}
            {{ selectedAttendees.length > 1 ? 'attendees' : 'attendee' }}? They
            will not have access to your event after deletion.
          </div>

          <button class="btn-red" @click="setSelectedStatus(pendingBulkAction)">
            Delete attendees
          </button>
        </div>

        <div v-else>
          <div class="mb-6">
            Are you sure you want to change the status of
            {{ selectedAttendees.length }}
            {{ selectedAttendees.length > 1 ? 'attendees' : 'attendee' }} to
            {{ pendingBulkAction }}?
          </div>
          <button
            class="btn-blue"
            @click="setSelectedStatus(pendingBulkAction)"
          >
            Update attendees
          </button>
        </div>

        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmBulk.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>

    <!-- Delete confirmation -->
    <Modal width="96" ref="confirmDelete">
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Delete attendee</h1>
        <div class="mb-6">
          Are you sure you want to delete
          <strong>{{ getAttendeeName(this.selectedForAction) }}</strong
          >? They will not have access to your event after deletion.
        </div>

        <button class="btn-red" @click="deleteAttendee()">
          Delete attendee
        </button>
        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmDelete.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>

    <!-- resend confirmation email -->
    <Modal
      width="96"
      ref="confirmResendInvitation"
      v-on:close="accessLinkVal = ''"
    >
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">
          Resend invitation email
        </h1>
        <div class="mb-6">
          Are you sure you want to resend the invitation email to
          <strong>{{ getAttendeeName(this.selectedForAction) }}</strong
          >? The existing access link will be emailed immediately.
        </div>
        <div class="mb-6">
          You will be able to view the access link temporarily.
        </div>

        <button class="btn-blue" @click="resendInvitation">Resend email</button>
        <button
          class="mt-6 w-full text-center text-blue-500"
          @click="$refs.confirmResendInvitation.close()"
        >
          Cancel
        </button>
      </template>
    </Modal>

    <!-- resend confirmation new details -->
    <Modal
      width="96"
      ref="resendInvitationNewDetails"
      v-on:close="(accessLinkVal = ''), (selectedForAction = '')"
    >
      <template slot="content">
        <h1 class="mb-6 text-center text-2xl font-bold">Invite sent</h1>
        <div class="mb-6">
          An invitation email has been sent to
          <strong>{{ this.selectedForAction.email }}</strong
          >. The attendee's unique login link can also be seen below
          temporarily. Once you close this alert you will no longer have access
          to this link.
        </div>
        <div class="text-center">Unique access link:</div>

        <div class="flex">
          <TextField
            name="first_name"
            class="flex flex-1"
            v-model="accessLinkVal"
            :errors="errors"
          />

          <button
            @click.prevent="handleCopyLink(accessLinkVal)"
            class="flex cursor-pointer p-3 hover:text-blue-800"
          >
            <i class="material-icons-outlined pt-1">copy</i>
          </button>
        </div>

        <button
          class="btn-blue mt-6"
          @click="$refs.resendInvitationNewDetails.close()"
        >
          Close
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import each from 'lodash/each';
import dayjs from 'dayjs';

import api from '@/services/api';
import { EventBus } from '@/services/event-bus';
import errors from '@/mixins/errors';
import MaterialIconsCheckbox from '@/components/MaterialIconsCheckbox';
import TextField from '@/components/TextField';
import Modal from '@/components/Modal';
import DropdownMenu from '@/components/DropdownMenu';
import LoadingIcon from '@/components/ui/LoadingIcon';

export default {
  mixins: [errors],

  props: ['event'],

  components: {
    MaterialIconsCheckbox,
    TextField,
    Modal,
    DropdownMenu,
    LoadingIcon,
  },

  data() {
    return {
      attendees: [],
      isLoading: false,
      s: '',
      selectedOption: { id: 'all', label: 'All' },
      filterOptions: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'pending',
          label: 'Pending',
        },
        {
          id: 'accepted',
          label: 'Accepted',
        },
        {
          id: 'declined',
          label: 'Declined',
        },
        {
          id: 'recent',
          label: 'Last 24h',
        },
      ],
      checkAll: false,
      selectedForAction: '',
      accessLinkVal: '',
      pendingBulkAction: '',
    };
  },

  computed: {
    selectedAttendees() {
      return this.attendees.filter((attendee) => {
        return attendee.selected;
      });
    },

    nonDeletedAttendees() {
      return this.attendees.filter((attendee) => {
        return attendee.status != 'deleted';
      });
    },

    filteredAttendees() {
      var searchString = this.s.toLowerCase();

      return this.nonDeletedAttendees
        .filter((attendee) => {
          if (searchString == '') return true;

          if (attendee.firstName) {
            if (attendee.firstName.toLowerCase().indexOf(searchString) > -1) {
              return true;
            }
          }

          if (attendee.lastName) {
            if (attendee.lastName.toLowerCase().indexOf(searchString) > -1) {
              return true;
            }
          }

          if (attendee.company) {
            if (attendee.company.toLowerCase().indexOf(searchString) > -1) {
              return true;
            }
          }

          if (attendee.email) {
            if (attendee.email.toLowerCase().indexOf(searchString) > -1) {
              return true;
            }
          }

          return false;
        })
        .filter((attendee) => {
          switch (this.selectedOption.id) {
            case 'all':
              return true;
            case 'recent':
              return dayjs(attendee.createdAt).isAfter(
                dayjs().subtract(1, 'day')
              );
            case 'pending':
              return !['accepted', 'declined'].includes(attendee.status);
            case 'accepted':
              return attendee.status == 'accepted';
            case 'declined':
              return attendee.status == 'declined';
          }
        });
    },
    eventComplete() {
      // TODO: not available at page load sometimes
      return !this.event || ['complete'].includes(this.event.eventState);
    },
  },

  watch: {
    checkAll() {
      each(this.attendees, (attendee) => {
        if (attendee.status != 'deleted') {
          attendee.selected = this.checkAll;
        }
      });
    },
  },

  mounted() {
    EventBus.$on('attendee-created', this.onAttendeeCreatedEvent);

    this.load();
  },

  beforeDestroy() {
    EventBus.$off('attendee-created', this.onAttendeeCreatedEvent);
  },

  methods: {
    onAttendeeCreatedEvent() {
      this.load();
    },

    load() {
      this.isLoading = true;

      if (
        ![
          'automatic_registration',
          'manual_registration',
          'pre_registration',
        ].includes(this.event.registrationType)
      ) {
        this.$router.replace('/dashboard');
        return;
      }

      api.get('/jobs/' + this.event.id + '/attendees').then((response) => {
        this.isLoading = false;
        this.attendees = response.data;
        each(this.attendees, (attendee) => {
          this.$set(attendee, 'selected', false);
        });
      });
    },

    getAttendeeName(attendee) {
      var firstName = attendee.firstName ? attendee.firstName : '';
      var lastName = attendee.lastName ? attendee.lastName : '';

      if (!firstName && !lastName) {
        return 'Unknown';
      }

      return firstName + ' ' + lastName;
    },

    setAttendeeStatus(attendee, status) {
      attendee.status = status;

      api.post(
        '/jobs/' + this.event.id + '/attendees/' + attendee.id + '/status',
        {
          status,
        }
      );
      if (status == 'accepted') {
        this.notify('success', 'Attendee updated and confirmation email sent');
      } else {
        this.notify('success', 'Attendee updated');
      }
    },

    setSelectedStatus(status) {
      each(this.selectedAttendees, (attendee) => {
        attendee.status = status;

        api.post(
          '/jobs/' + this.event.id + '/attendees/' + attendee.id + '/status',
          {
            status,
          }
        );

        this.checkAll = false;
        attendee.selected = false;
      });
      this.pendingBulkAction = '';
      this.$refs.confirmBulk.close();
    },
    deleteAttendee() {
      const status = 'deleted';
      // set local data
      this.selectedForAction.status = status;

      // set server data
      api.post(
        '/jobs/' +
          this.event.id +
          '/attendees/' +
          this.selectedForAction.id +
          '/status',
        {
          status,
        }
      );
      this.notify('success', 'Attendee deleted');
      this.selectedForAction = '';
      this.$refs.confirmDelete.close();
    },
    confirmDeleteModal(attendee) {
      this.selectedForAction = attendee;
      this.$refs.confirmDelete.show();
    },
    confirmBulkModal(action) {
      this.pendingBulkAction = action;
      this.$refs.confirmBulk.show();
    },
    resendInvitation() {
      // set server data
      api
        .post(
          '/jobs/' +
            this.event.id +
            '/attendees/' +
            this.selectedForAction.id +
            '/resendConfirmation'
        )
        .then((response) => {
          this.$refs.confirmResendInvitation.close();
          this.accessLinkVal = response.data;
          this.$refs.resendInvitationNewDetails.show();
        });
    },
    confirmResendInvitationModal(attendee) {
      this.selectedForAction = attendee;
      this.$store.commit('dropdownOpen', false);
      this.$refs.confirmResendInvitation.show();
    },
    handleCopyLink(link) {
      navigator.clipboard.writeText(link);

      this.$store.commit(
        'MESSAGE',
        `Attendee unique access link has been copied to the clipboard.`
      );
    },
    selectOption(option) {
      this.selectedOption = option;
      this.$refs.filterDropdownMenu.close();
    },
    handleAddNewItem() {
      this.$router.replace(
        '/events/' + this.event.eventShortcode + '/attendee/edit/new'
      );
    },
  },
};
</script>
