<template>
  <div
    class="page flex h-full w-full items-center justify-center bg-black text-white"
  >
    <div v-if="activePoll" class="flex h-full w-full flex-1 flex-col p-12">
      <VClamp
        :max-lines="3"
        :autoresize="true"
        class="scaleText h-full whitespace-pre-line font-bold"
        >{{ activePoll.content }}</VClamp
      >
      <div
        v-for="opt in activePoll.options"
        :key="opt.id"
        class="mt-2 flex flex-col border-b-4 border-grey-100"
      >
        <div class="flex justify-between">
          <div class="flex-1 text-6xl">{{ opt.content }}</div>
          <div class="flex text-6xl">
            {{ opt.voteCount }} {{ opt.voteCount == 1 ? 'vote' : 'votes' }} -
            {{ opt.percentage.toFixed(2) }}%
          </div>
        </div>
        <!-- bar -->
        <div
          class="my-2 flex h-6 rounded-full bg-blue-500"
          :style="`width: ${opt.percentage || 0}%`"
        ></div>
      </div>
      <div class="mt-2 flex justify-end">
        <div class="text-6xl text-grey-300">
          {{ activePoll.totalVoteCount }} total
          {{ activePoll.totalVoteCount == 1 ? 'vote' : 'votes' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VClamp from 'vue-clamp';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export default {
  name: 'EventOutputPoll',
  components: {
    VClamp,
  },
  computed: {
    ...mapState(['event']),
    ...mapState({
      polls: (s) =>
        s.polls.map((p) => ({
          ...p,
          options: p.options.map((o) => ({
            ...o,
            percentage: (100 * o.voteCount) / (p.totalVoteCount || 1),
          })),
        })),
    }),
    activePoll() {
      return this.polls.find((p) => p.isLive);
    },
  },
};
</script>

<style scoped>
.scaleText {
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1920 - 300)));
  line-height: 1.2;
}
</style>
